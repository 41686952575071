export const formatMoney = (
  number: any,
  decPlaces = 2,
  decSep = '.',
  thouSep = ',',
  zeroEnd = true,
) => {
  ;(decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === 'undefined' ? '.' : decSep)
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep
  const sign = number < 0 ? '-' : ''
  const i: any = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))),
  )
  let j: any
  j = (j = i.length) > 3 ? j % 3 : 0

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
        (zeroEnd
          ? Math.abs(number - i)
              .toFixed(decPlaces)
              .slice(2)
          : parseFloat(Math.abs(number - i).toFixed(decPlaces))
              .toString()
              .slice(2))
      : '')
  )
}

export const formatCurrency = (
  amount: any,
  currency = '$',
  dec = 2,
  zeroEnd = true,
) => {
  const denominator = 100
  switch (currency) {
    case 'usd':
      currency = '$'
      break
    case 'gbp':
      currency = '£'
      break
    default:
      break
  }

  return currency + formatMoney(amount / denominator, dec, '.', ',', zeroEnd)
}
