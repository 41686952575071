import { observer } from 'mobx-react-lite'
import type { HTMLAttributes } from 'react'

import { routerPaths } from '../../../context/router/routerPaths'
import { S } from '../../../context/store'

export const WarmnessGraySpan = observer(
  (props: HTMLAttributes<HTMLSpanElement>) => {
    const { style, children, ...prop } = props
    const { warmnessColorGray } = S.local
    const isStudio = location.pathname === routerPaths.STUDIO

    return (
      <span
        style={{ background: isStudio ? warmnessColorGray : '', ...style }}
        {...prop}
      >
        {children}
      </span>
    )
  },
)
