import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { clsx } from 'clsx'

import css from './style.module.scss'

import { Icon } from '../../base/Icon'

export const AppSelect = (props: SelectProps) => {
  const { className, dropdownClassName, ...sProps } = props
  return (
    <Select
      suffixIcon={<Icon icon='Dropdown' size={14} className={css.ArrowIcon} />}
      dropdownClassName={clsx(css.DropDownClassName, dropdownClassName)}
      className={clsx(css.Select, className)}
      {...sProps}
    />
  )
}
AppSelect.Option = Select.Option
