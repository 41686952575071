import { ulid } from 'ulidx'

// try fix cors cache issue on some current images
const imgUrlsMap: { [k: string]: string } = {}
export const getUncachedUrl = (url: string) => {
  if (!imgUrlsMap[url]) {
    imgUrlsMap[url] = url + (url.indexOf('?') < 0 ? '?' : '&') + 'q=' + ulid()
  }
  return imgUrlsMap[url]
}

const createImage = (
  url: string,
  alreadyRetry = false,
): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', err => {
      if (alreadyRetry) {
        reject(err)
        return
      }
      url = getUncachedUrl(url)
      resolve(createImage(url, true))
    })
    image.crossOrigin = 'anonymous'
    image.src = url
  })

export const getRadianAngle = (degreeValue: any) =>
  (degreeValue * Math.PI) / 180

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This const was= adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<any> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop?.x || 0,
    pixelCrop?.y || 0,
    pixelCrop?.width || image.width,
    pixelCrop?.height || image.height,
  )

  // set canvas width to final desired crop size - this will clear existing context
  ;(canvas.width = pixelCrop?.width ?? image.width),
    (canvas.height = pixelCrop?.height ?? image.height),
    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve(file)
    })
  })
}

export const getRotatedImage = async (imageSrc: string, rotation = 0) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const orientationChanged =
    rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
  } else {
    canvas.width = image.width
    canvas.height = image.height
  }

  ctx?.translate(canvas.width / 2, canvas.height / 2)
  ctx?.rotate((rotation * Math.PI) / 180)
  ctx?.drawImage(image, -image.width / 2, -image.height / 2)

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file as any))
    }, 'image/png')
  })
}

export const pickFile = (
  accept = '',
  multiple = false,
): Promise<FileList | null> =>
  new Promise((rs, rj) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = multiple
    input.accept = accept
    input.onchange = () => {
      rs(input.files)
    }
    input.click()
  })
