import { reactServerOrigin } from '../../config'
import { gqlOperations } from './codegen'
import { createUrqlClient } from './urql'

const gqlUrqlClient = createUrqlClient(reactServerOrigin)
const gql = gqlOperations(gqlUrqlClient)

export const gqlCtx = {
  gql,
  gqlUrqlClient,
}
