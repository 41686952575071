import type { MyOrgInvitationsQuery } from '../../../context/gql/codegen'

export type Invitation = MyOrgInvitationsQuery['myOrgInvitations'][0] & {
  accepted?: boolean
}
export type InvitationData = {
  countMyOrgInvitations: number
  myOrgInvitations: Invitation[]
}
const invitationDefault: InvitationData = {
  countMyOrgInvitations: 0,
  myOrgInvitations: [],
}
export class InvitationStore {
  invitationData: InvitationData = invitationDefault
  loading: boolean
}
