import type { SessionRoleName } from '../../../context/gql/codegen'
import { getResourcesInsightEngine } from '../../../pages/InsightEngine/actions/getResourcesInsightEngine'
import { initInsightEngineSocket } from '../../../pages/InsightEngine/actions/initInsightEngineSocket'
import { searchRecordBotName } from '../../../pages/InsightEngine/actions/searchRecordBotName'
import { getSessionPlan } from '../../../pages/Studio/actions/getSessionPlan'
import { getDeviceId } from '../../../pages/Studio/utils/getDeviceId'
import { showError } from '../../../utils/showError'
import { toBoolean } from '../../../utils/toBoolean'
import { reduxStore } from '../../redux'
import { S } from '../../store'
import { getOutputInSession } from '../../store/studio/WebrtcStore'
import { checkBlurSupport } from './checkBlurSupport'
import { getTrack } from './getTrack'
import { getWaitingUsers } from './getWaitingUsers'
import { loadTFLiteModel } from './loadTFLiteModel'
import { onPreviewFinish } from './onPreviewFinish'

export const onStudioEnter = async () => {
  const $ = S.webrtc

  // Get studio setting from profile
  const studioSettings = S.profile.profile.studioSettings

  const params = new URLSearchParams(location.search)
  const id = params.get('r')

  // handle linkedin destination login
  const state = params.get('state')
  const code = params.get('code')
  if (state?.includes('_linkedin') && code) {
    localStorage.setItem('linkedin', code)
    window.close()
  } else if (state?.includes('_facebook') && code) {
    localStorage.setItem('facebook', code)
    window.close()
  }

  const isInvite = toBoolean(params.get('invite'))
  if (!id) {
    showError('Empty id in url')
    return
  }
  if (isInvite) {
    const invited = await reduxStore.context.gql.checkUserInSession(
      { id },
      { requestPolicy: 'network-only' },
    )
    $.isInvited = invited.data?.checkUserInSession as boolean
    const role = params.get('role') as SessionRoleName
    const sessionRole = role || 'Host'
    if (!$.isInvited && $.socketInfo.id) {
      reduxStore.context.gql.requestJoin({
        socketId: $.socketInfo.id,
        sessionId: id,
        sessionRole,
      })
      return
    }
    console.log('User is in session')
  }

  const d = await reduxStore.context.gql.onStudioEnter({ id })
  if (!d.data?.sessionRole) {
    showError('You dont have permission to join this session')
    return
  }
  if (d.data.detailSession.insightEngineId) {
    initInsightEngineSocket(d.data.detailSession.insightEngineId)
    getResourcesInsightEngine({
      insightEngineId: d.data.detailSession.insightEngineId,
    })
  }
  await searchRecordBotName()
  await getSessionPlan(id, d.data.sessionRole.name)

  $.role = d.data.sessionRole
  $.detail = d.data.detailSession

  if ($.role.name !== 'Participant') {
    const noteData = await reduxStore.context.gql.searchNote({
      sessionId: id,
      filter: { createdById: S.profile.profile.id },
    })
    if (noteData.data?.searchNote[0]) {
      $.note = noteData.data.searchNote[0]
    }
    await getOutputInSession()
  }

  $.isBlurSupported = checkBlurSupport()

  if ($.isBlurSupported && $.camBlurred) {
    try {
      $.tFLiteModel = await loadTFLiteModel()
    } catch (e) {
      console.log("Can't load TFLite")
      $.isBlurSupported = false
    }
  }

  const v =
    $.role.name === 'Host'
      ? 'rhost'
      : $.role.name === 'Observer'
        ? 'robserver'
        : $.role.name === 'Participant'
          ? 'rparticipant'
          : ''
  if (!v) {
    throw new Error('Invalid viewmode')
  }
  $.setViewmode(v)

  const settings =
    v === 'robserver'
      ? d.data.detailSession.settings?.observer
      : d.data.detailSession.settings?.mixer
  $.updateDataOfStore({
    ...studioSettings,
    ...settings,
    roomTitle: d.data.detailSession.title,
  })

  $.devices = await navigator.mediaDevices.enumerateDevices()
  if (v === 'robserver') {
    getWaitingUsers({ sessionRole: 'Observer' })
    onPreviewFinish()
    return
  }

  const microphoneTrack = await getTrack('audio', $.mics, $.micId)
  if (!microphoneTrack) {
    // microphone track is required
    // camera track can be optional
    return
  }

  $.micId = getDeviceId($.mics, microphoneTrack)

  let cameraTrack: MediaStreamTrack | undefined
  if (!$.camDisabled) {
    cameraTrack = await getTrack('video', $.cams, $.camId)
    $.camId = getDeviceId($.cams, cameraTrack)
  }

  $.speakerId = $.speakers.some(s => s.deviceId === $.speakerId)
    ? $.speakerId
    : $.speakers[0]?.deviceId

  if (
    $.isBlurSupported &&
    $.camBlurred &&
    cameraTrack &&
    $.role.name !== 'Host'
  ) {
    const { newTrack, cleanUpFunc } = await $.blurCamera(cameraTrack)
    cameraTrack = newTrack
    $.blurCleanUp = cleanUpFunc
  }
  $.preview = {
    micTrack: microphoneTrack,
    camTrack: cameraTrack,
  }

  // if user role in this session is not a host, show preview screen
  if ($.role.name !== 'Host') {
    return
  }
  $.state = 'connecting'

  getWaitingUsers()

  onPreviewFinish()
}
