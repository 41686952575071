import { getTrack } from '../../../context/actions/studio/getTrack'
import { S } from '../../../context/store'

export const onPreviewDeviceToggle = async (device: 'mic' | 'cam') => {
  const $ = S.webrtc
  if (!$.preview) {
    return
  }

  if (device === 'mic') {
    $.micMuted = !$.micMuted
    return
  }

  $.camDisabled = !$.camDisabled
  $.preview.camTrack?.stop()
  if ($.camDisabled) {
    delete $.preview.camTrack
    $.disableBlurLoop()
    return
  }
  let track = await getTrack('video', $.cams, $.camId)
  if ($.isBlurSupported && $.camBlurred && track) {
    const { newTrack, cleanUpFunc } = await $.blurCamera(track)
    track = newTrack
    $.blurCleanUp = cleanUpFunc
  }
  $.preview.camTrack = track
}
