import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_GRAPHIC_LOGO } from '../../../context/store/studio/dragTypes'
import type {
  TKeyAny,
  TMediaItem,
} from '../../../context/store/studio/WebrtcStore'
import { GraphicSortContainer } from './GraphicSort'

export const Logo = observer(() => {
  const {
    updateEmitAndSaveSettings,
    removeGraphicLogo,
    updateOrderGraphicLogo,
    statusUpdateSettings,
    graphicLogo,
    graphicSettings,
    logoUrl,
    addGraphicLogo,
  } = S.webrtc
  const onToggleStatus = async (url: string) => {
    handleSendData({
      logoUrl: url === logoUrl ? '' : url,
    })
  }
  const onUploadFile = async (files: TMediaItem[]) => {
    if (!files || files.length === 0) {
      return
    }

    addGraphicLogo(files)
  }
  const onSortEnd = async (dropIndex: number, overIndex: number) => {
    updateOrderGraphicLogo(dropIndex, overIndex)
    await callApiUpdateResourceReOrder(
      S.webrtc.graphicLogo,
      dropIndex,
      overIndex,
    )
  }
  const onRemoveLogo = async (item: TMediaItem) => {
    const arr = [
      item.id,
      S.webrtc.graphicLogo.find(l => l.resourceId === item.originalResourceId)
        ?.id,
    ].filter(id => id)
    arr.forEach(id => removeGraphicLogo(id as any))
    await reduxStore.context.gql.deleteResourceInSession({ ids: arr as any })
    // refetchSub({ requestPolicy: 'network-only' })
    // if (logoUrl === item.value) {
    //   handleSendData({ logoUrl: '' })
    // }
  }

  const handleSendData = (data: TKeyAny | undefined = {}) => {
    updateEmitAndSaveSettings(data, 'logo')
  }

  useEffect(() => {
    if (statusUpdateSettings.type === 'logo') {
      if (statusUpdateSettings.status === 'error') {
        ToastService.error({ content: 'Failed to update logo' })
      }
    }
  }, [statusUpdateSettings.type])

  return (
    <GraphicSortContainer
      list={graphicLogo}
      onUploadFile={onUploadFile}
      onToggleStatus={onToggleStatus}
      selectedItem={logoUrl}
      isHideDefault={!graphicSettings.defaultGraphics}
      onRemove={onRemoveLogo}
      dragType={DRAG_GRAPHIC_LOGO}
      onSortEnd={onSortEnd}
      large={false}
      typeResource='logo'
    />
  )
})
