import { observer } from 'mobx-react-lite'

import { S } from '../../../context/store'
import { BeamInput } from '../Input'
import type { InputProps } from './type'

export const WarmnessInput: React.FC<InputProps> = observer(props => {
  const { warmnessColor } = S.local

  const { style, ...propsInput } = props
  return (
    <BeamInput
      style={{ background: warmnessColor, ...style }}
      {...propsInput}
    />
  )
})
