import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useFullscreen } from 'react-use'

import css from './Room.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import { volume0 } from '#rn-shared/mediasoup'
import { filterSmallPeers } from '#rn-shared/mediasoup/lib/filterSmallPeers'

import { Icon } from '../../../components/base/Icon'
import { BeamLayout } from '../../../components/base/Layout'
import { onPreviewFinish } from '../../../context/actions/studio/onPreviewFinish'
import { onStudioEnter } from '../../../context/actions/studio/onStudioEnter'
import { onStudioLeave } from '../../../context/actions/studio/onStudioLeave'
import { ReSizeOutSize } from '../../../context/shared/components/CustomLayoutReszieOutside'
import { InviteButton } from '../../../context/shared/components/InviteButton'
import { NotificationRoom } from '../../../context/shared/components/NotificationRoom'
import { S } from '../../../context/store'
import { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import {
  amsMediaPrefix,
  buildScreenshareMediaId,
} from '../../../context/store/studio/WebrtcStore'
import { BlurListBottom } from '../../InsightEngine/components/BlurListBottom'
import { InsightEngineNote } from '../../InsightEngine/components/InsightEngineNote'
import { TranscriptComponent } from '../../InsightEngine/components/MainComponent'
import { MediaBottomPlayer } from '../../InsightEngine/components/MediaBottomPlayer'
import { MediaSeekBar } from '../../InsightEngine/components/MediaSeekBar'
import { VideoSection } from '../../InsightEngine/components/VideoSection'
import { createSocketInstance } from '../actions/createSessionSocketInstance'
import { pasteSlot } from '../actions/pasteSlot'
import { BottomListControl } from './BottomListControl'
import { JoinSession } from './JoinSession'
import { RoomLeftActions } from './LeftActions'
import { LiveStreamContent } from './LiveStreamContent'
import { LiveStreamLayouts } from './LiveStreamLayouts'
import { MediaControllerClient } from './MediaControllerClient'
import { RightSider } from './RightSidebar'
import { Slot } from './Slot'
import { SlotSmall } from './SlotSmall'
import { SubtitleClient } from './SubtitleClient'
import { UploadTranscript } from './UploadTranscript'

export const Mixer = observer(() => {
  useEffect(() => {
    onPreviewFinish()
  }, [])

  const {
    mixerData,
    peers,
    mixerGroup,
    inputs,
    isMediaControllerClient,
    isSubtitleClient,
  } = S.webrtc
  if (isSubtitleClient) {
    return <SubtitleClient />
  }
  if (isMediaControllerClient) {
    return <MediaControllerClient />
  }
  if (mixerGroup && mixerData) {
    const isAms = mixerData?.peerId
      ? mixerData?.peerId?.includes(amsMediaPrefix)
      : false
    const ps = isAms
      ? peers.find(i => i.data.viewmode === 'ams-client')
      : peers.find(
          p =>
            (mixerData.userId && p.data.userId === mixerData?.userId) ||
            p.id === mixerData?.peerId,
        )
    const input = inputs.find(i => i.id === mixerData?.peerId)
    if (!ps && !input) {
      return <LiveStreamContent isFullScreen />
    }
    return (
      <Slot
        fullscreen
        peerId={
          mixerData?.isInput
            ? `input-${mixerData.peerId}`
            : mixerData.stream === 'screenshareVideo'
              ? buildScreenshareMediaId(ps?.id ?? '')
              : ps?.id
        }
        mediaData={isAms ? { id: mixerData?.peerId } : undefined}
        position={-1}
        totalSlots={[{ id: -1 }]}
        forceMuted={false}
      />
    )
  }

  return <LiveStreamContent isFullScreen />
})

export const Room = observer(() => {
  const [local, ...peers] = S.webrtc.peers
  const containerRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<'left' | 'right' | 'all' | undefined>(
    undefined,
  )
  const showLeftButton = show === 'left'
  const showRightButton = show === 'right'
  const shouldShowButtons = show === 'all'

  const checkIfShouldShowButtons = () => {
    if (containerRef.current) {
      const itemCount = containerRef.current.children.length
      const shouldShow = itemCount > 5
      if (shouldShow) {
        const shouldLeftShow = containerRef.current.scrollLeft > 0
        const shouldRightShow =
          containerRef.current.scrollLeft + containerRef.current.clientWidth <
          containerRef.current.scrollWidth
        const s: typeof show =
          shouldLeftShow && shouldRightShow
            ? 'all'
            : shouldLeftShow
              ? 'left'
              : shouldRightShow
                ? 'right'
                : undefined
        setShow(s)
      }
    }
  }

  useEffect(() => {
    checkIfShouldShowButtons()
  }, [local, peers])

  useEffect(() => {
    window.addEventListener('resize', checkIfShouldShowButtons)
    return () => window.removeEventListener('resize', checkIfShouldShowButtons)
  }, [])

  const smoothScroll = (distance: number) => {
    const duration = 300
    const stepTime = 10
    const steps = duration / stepTime
    const stepSize = distance / steps

    let currentStep = 0

    const step = () => {
      if (containerRef.current) {
        containerRef.current.scrollLeft += stepSize
        currentStep++
        if (currentStep < steps) {
          window.requestAnimationFrame(step)
        } else {
          checkIfShouldShowButtons()
        }
      }
    }

    step()
  }

  const scrollLeft = () => {
    smoothScroll(-200)
  }

  const scrollRight = () => {
    smoothScroll(200)
  }

  const {
    socketInfo,
    state,
    updateDataOfStore,
    onWindowKeyPress,
    openTranscriptView,
    mainLayoutWidth,
    sessionMode,
  } = S.webrtc
  const { mode } = S.insightEngine

  useEffect(() => {
    if (socketInfo.connected && !state) {
      onStudioEnter()
    }
  }, [socketInfo.connected, state])
  useEffect(() => {
    S.webrtc.initFirstData()
    createSocketInstance()
    return () => {
      onStudioLeave()
      S.insightEngine.reset()
    }
  }, [])

  const { isViewmodeHost, isViewmodeObserver } = S.webrtc
  const { isFullScreenSite, mediaVolumes } = S.webrtc
  const videoProducer = local.video
  const shareProducer = local.screenshareVideo
  const refFullScreen = useRef(null)

  useFullscreen(refFullScreen, isFullScreenSite, {
    onClose: () => {
      updateDataOfStore({
        isFullScreenSite: false,
      })
    },
  })

  const { isLightTheme, warmnessColor } = S.local

  const renderLiveStreamContent = () => (
    <LiveStreamContent
      refFullScreen={refFullScreen}
      isEnableMic={
        !(local.audio?.muted || mediaVolumes[local.id]?.current === 0)
      }
      isEnableVideo={!!videoProducer}
      isFullScreen={isFullScreenSite}
      isEnableShareScreen={!!shareProducer}
    />
  )
  const handleKeyDown = (event: KeyboardEvent) => {
    onWindowKeyPress(event)
    if (
      (event.ctrlKey && event.keyCode === 86) ||
      (event.metaKey && event.key === 'v')
    ) {
      pasteSlot()
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  if (!state) {
    return <JoinSession handleFinish={onPreviewFinish} />
  }

  const arrSlotsEdit = Object.keys(S.layout.slotsEdit).map(
    key => S.layout.slotsEdit[Number(key)],
  )
  const slotEditing = arrSlotsEdit.find(s => s.find(s1 => s1.enableEdit))
  const subTittleEditing = S.layout.subtitleSlot?.enableEdit ?? false
  const multipleEdit = arrSlotsEdit.reduce((pre, cur) => {
    const mn = cur.filter(s2 => s2.inGroup)
    return pre + mn.length
  }, 0)
  const amsPeer = S.webrtc.peers.find(p => p.data.viewmode === 'ams-client')

  return (
    <BeamLayout
      style={{ background: isLightTheme ? warmnessColor : undefined }}
      className={css.AppLayout}
    >
      {S.insightEngine.mode === InsightEngineMode.Edit ||
      (S.layout.enableEditLayout &&
        (slotEditing || multipleEdit > 1 || subTittleEditing)) ? (
        <ReSizeOutSize />
      ) : null}
      <NotificationRoom />
      <RoomLeftActions />
      <div className={css.Bg}>
        <BeamLayout.Content className={css.AppLayoutContent}>
          <div
            style={{
              background: isLightTheme ? warmnessColor : undefined,
            }}
            className={css.MainContent}
          >
            <div className={css.ContentWrapper}>
              {openTranscriptView && mode === 'Cursor' && (
                <div className={css.TranscriptInsightEngine}>
                  <TranscriptComponent />
                </div>
              )}

              <div className={css.ContentContainer}>
                <div
                  className={clsx(css.MainContentPeers, {
                    [css.MainContentNotHeader]: true,
                  })}
                >
                  <div className={css.ContainerPeers}>
                    <WarmnessGrayButton
                      className={css.IconLeft}
                      onClick={scrollLeft}
                      style={{
                        visibility:
                          shouldShowButtons || showLeftButton
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      <Icon icon='icon_arrow_left' size={12} />
                    </WarmnessGrayButton>
                    <div className={css.ScrollPeersSlot}>
                      <div
                        className={css.ContainerPeersSlot}
                        ref={containerRef}
                        onScroll={checkIfShouldShowButtons}
                      >
                        <Fragment key={local.id}>
                          <div className={css.MainContentPeersSlot}>
                            <SlotSmall peerId={local.id} />
                          </div>
                          {!!shareProducer?.id && (
                            <div className={css.MainContentPeersSlot}>
                              <SlotSmall
                                peerId={buildScreenshareMediaId(local.id)}
                              />
                            </div>
                          )}
                        </Fragment>
                        {filterSmallPeers(local.data.viewmode, peers).map(p => (
                          <Fragment key={p.id}>
                            <div className={css.MainContentPeersSlot}>
                              <SlotSmall
                                peerId={p.id}
                                isMainCanvas={p.data.name === 'rcanvas'}
                              />
                            </div>
                            {p.screenshareVideo && (
                              <div className={css.MainContentPeersSlot}>
                                <SlotSmall
                                  peerId={buildScreenshareMediaId(p.id)}
                                />
                              </div>
                            )}
                          </Fragment>
                        ))}
                        {S.webrtc.inputs?.map(i => (
                          <div key={i.id} className={css.MainContentPeersSlot}>
                            <SlotSmall peerId={`input-${i.id}`} />
                          </div>
                        ))}
                        {amsPeer
                          ? Object.keys(amsPeer?.medias ?? {}).map(m => (
                              <div key={m} className={css.MainContentPeersSlot}>
                                <SlotSmall peerId={amsPeer.id} mediaId={m} />
                              </div>
                            ))
                          : null}
                      </div>
                      {(isViewmodeHost || isViewmodeObserver) && (
                        <InviteButton>
                          <Icon icon='icon_plus' size={11} />
                        </InviteButton>
                      )}
                    </div>
                    <WarmnessGrayButton
                      className={css.IconRight}
                      onClick={scrollRight}
                      style={{
                        visibility:
                          shouldShowButtons || showRightButton
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      <Icon icon='icon_arrow_right' size={12} />
                    </WarmnessGrayButton>
                  </div>
                </div>
                <UploadTranscript />
                <div
                  className={clsx(css.InsightEngineNote, {
                    [css.HideContent]:
                      sessionMode !== 'note' || mode !== 'Cursor',
                  })}
                >
                  <div style={{ width: mainLayoutWidth, minWidth: '600px' }}>
                    <InsightEngineNote />
                  </div>
                </div>
                <div
                  className={clsx(css.InsightEngineNote, {
                    [css.HideContent]:
                      sessionMode !== 'note' || mode !== 'Edit',
                  })}
                >
                  <div style={{ width: mainLayoutWidth }}>
                    <VideoSection
                      type={InsightEngineMode.Edit}
                      key={InsightEngineMode.Edit}
                      playing={mode === InsightEngineMode.Edit}
                      muted={mode !== InsightEngineMode.Edit}
                    />
                  </div>
                </div>
                <div
                  className={clsx(css.Content, {
                    [css.HideContent]: sessionMode !== 'session',
                  })}
                >
                  {renderLiveStreamContent()}

                  {(isViewmodeHost || isViewmodeObserver) && (
                    <LiveStreamLayouts />
                  )}
                </div>
              </div>
            </div>

            {(openTranscriptView || sessionMode === 'note') && (
              <div className={css.MediaController}>
                <MediaSeekBar />
                {S.insightEngine.isBlurMode &&
                  S.webrtc.sessionMode === 'note' && <BlurListBottom />}
                <MediaBottomPlayer />
              </div>
            )}
          </div>
        </BeamLayout.Content>
        <RightSider />

        <BottomListControl
          isEnableMic={
            !(
              local.audio?.muted ||
              (typeof mediaVolumes[local.id]?.current === 'number' &&
                mediaVolumes[local.id].current <= volume0)
            )
          }
          isEnableVideo={!!videoProducer}
          isEnableShareScreen={!!shareProducer}
        />
      </div>
    </BeamLayout>
  )
})
