import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { getLocationData } from './getLocationData'
import { getResourcesInsightEngine } from './getResourcesInsightEngine'

export const reLoadRecordMeetingLink = async (id: string) => {
  const $S = S.insightEngine
  const { insightEngineId } = getLocationData()
  const resourceReuploading = $S.resources.find(r => r.isReUploading)
  const rs = $S.resources.find(r => r.id === id)
  if (!rs || resourceReuploading) {
    return
  }
  rs.isReUploading = true
  const data = await reduxStore.context.gql.reLoadRecordMeetingLink({
    resourceInInsightEngineId: id,
  })
  if (!data.data?.reLoadRecordMeetingLink) {
    rs.isReUploading = false
    return
  }
  if (data.data.reLoadRecordMeetingLink !== rs.resource?.url) {
    const data1 = await getResourcesInsightEngine({ insightEngineId })
    const rs1 = data1.find(d => d.id === $S.resourceSelected?.id)
    if (rs1) {
      $S.resourceSelected = rs1
    }
  }
  ToastService.success({ content: 'Refresh data success' })
  rs.isReUploading = false
}
