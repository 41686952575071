import { Button } from 'antd'

import css from './NotificationOutStorage.module.scss'

import { ModalBuyMoreStorage } from '#HACK_FOR_RN_ONLY/src/pages/SubscriptionPlan/components/ModalBuyMoreStorage'

import { ModalService } from '../../../components/Modal/Modal'
import { ModalChangePlan } from '../../../pages/SubscriptionPlan/components/ModalChangePlan'
import { S } from '../../store'
import { formatFileName } from '../../store/studio/utils'

type Props = {
  files?: string[]
}
export const NotificationOutStorage = ({ files = [] }: Props) => {
  const handleBuyMore = () => {
    ModalService.hide()
    setTimeout(() => {
      ModalService.show(ModalBuyMoreStorage)
    }, 300)
  }
  const handleUpgradePlan = () => {
    ModalService.hide()

    setTimeout(() => {
      ModalService.show(ModalChangePlan)
    }, 300)
  }
  const isTeamAdmin = S.profile.isTeamAdmin
  return (
    <div>
      {files?.length > 0 && (
        <div className={css.list}>
          <p className={css.txtNote}>These files have exceeded storage: </p>
          {files?.map(item => <div>{formatFileName(item, 40)}</div>)}
        </div>
      )}
      <div className={css.content}>
        Please buy more storage or upgrade plan to upload your file
      </div>
      <div className={css.footer}>
        <Button disabled={!isTeamAdmin} onClick={handleBuyMore}>
          Buy More
        </Button>
        <Button disabled={!isTeamAdmin} onClick={handleUpgradePlan}>
          Upgrade Plan
        </Button>
      </div>
    </div>
  )
}
