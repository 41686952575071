import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './LiveStreamPeer.module.scss'

import { S } from '../../../context/store'
import { PeerView } from './MediaView'

export const LiveStreamPeer = observer(() => {
  const { backgroundPeerId } = S.webrtc
  if (backgroundPeerId === '') {
    return null
  }
  return (
    <div className={clsx(css.ContainerPeer)}>
      <PeerView peerId={backgroundPeerId} forceMuted />
    </div>
  )
})
