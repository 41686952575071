import clsx from 'clsx'

import css from './style.module.scss'

import type { Props } from './type'

export const IconButton = ({
  icon,
  type = 'radius',
  variant = 'primary',
  className,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
}: Props) => (
  <div
    className={clsx(
      css.Button,
      {
        [css.Radius]: type === 'radius',
        [css.Circle]: type === 'circle',
        [css.Primary]: variant === 'primary',
        [css.Dark]: variant === 'dark',
        [css.Grey]: variant === 'grey',
      },
      className,
    )}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onMouseLeave={onMouseLeave}
  >
    {icon}
  </div>
)
