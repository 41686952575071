import type { FormInstance } from 'antd/es/form/Form'
import copy from 'copy-to-clipboard'
import type { FC } from 'react'

import IconSuccess from '../../../assets/icons/ic_success.svg'
import css from './ModalUserListHost.module.scss'

import { Form } from '#HACK_FOR_RN_ONLY/src/components/base/Form'
import { FormItem } from '#HACK_FOR_RN_ONLY/src/components/base/FormItem'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { WarmnessInput } from '../../../components/widget/WarmnessBeamInput'
import type { SessionRoleName } from '../../../context/gql/codegen'
import { S } from '../../store'
import { ModalInviteUserList } from './ModalInviteUserList'

type Props = {
  data: PeerUI[]
  handleInviteEmail: (type: 'emailObserver' | 'emailMainRoom') => void
  form: FormInstance<any>
  disableInputHost?: boolean
  disableInputObserver?: boolean
}
export const ModalUserListHost: FC<Props> = ({
  data,
  handleInviteEmail,
  form,
  disableInputHost,
  disableInputObserver,
}) => {
  const { id: userId } = S.profile.profile

  const observersData = data.filter(item => item.data.viewmode === 'robserver')

  const hostData = data.filter(item => item.data.viewmode !== 'robserver')
  const handleCopy = (role: SessionRoleName) => {
    const params = new URLSearchParams(location.search)
    copy(
      location.origin.replace(/#.*/, '') +
        `/?r=${params.get('r')}&invite=true&role=${role}`,
    )
    ToastService.success({
      content: 'Copied',
      icon: <img src={IconSuccess} />,
      style: {
        bottom: 0,
      },
    })
  }
  return (
    <Form
      form={form}
      onFinish={handleInviteEmail}
      requiredMark
      layout='vertical'
    >
      <div className={css.ModalUserListHost}>
        <div
          className={css.MainRoom}
          style={{ background: S.local.warmnessColor }}
        >
          <div className={css.UserListTitle}>Main Session</div>
          <div className={css.ModalFieldEmailHost}>
            <FormItem
              name='emailMainRoom'
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: 'Not type of email',
                },
              ]}
              className={css.ModalFormItemHost}
              validateTrigger='onSubmit'
            >
              <WarmnessInput
                disabled={disableInputHost}
                placeholder='Invite by email to Participant'
                className={css.ModalFormItemHost}
              />
            </FormItem>
            <WarmnessGrayButton
              onClick={() => handleInviteEmail('emailMainRoom')}
              className={css.ActionBtn}
            >
              <Icon icon='icon_chat' size={16} />
              Send
            </WarmnessGrayButton>
            <WarmnessGrayButton
              onClick={() => handleCopy('Participant')}
              className={css.ActionBtn}
            >
              <Icon icon='icon_share_link' size={16} />
              Copy
            </WarmnessGrayButton>
          </div>
          <ModalInviteUserList data={hostData} userId={userId} />
        </div>
        <div
          className={css.ObserverRoom}
          style={{ background: S.local.warmnessColor }}
        >
          <div className={css.UserListTitle}>Observer Session</div>
          <div className={css.ModalFieldEmailHost}>
            <FormItem
              name='emailObserver'
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: 'Not type of email',
                },
              ]}
              className={css.ModalFormItemHost}
              validateTrigger='onSubmit'
            >
              <WarmnessInput
                placeholder='Invite by email'
                className={css.ModalFormItemHost}
                autoFocus
                disabled={disableInputObserver}
              />
            </FormItem>
            <WarmnessGrayButton
              onClick={() => handleInviteEmail('emailObserver')}
              className={css.ActionBtn}
            >
              <Icon icon='icon_chat' size={16} />
              Send
            </WarmnessGrayButton>
            <WarmnessGrayButton
              onClick={() => handleCopy('Observer')}
              className={css.ActionBtn}
            >
              <Icon icon='icon_share_link' size={16} />
              Copy
            </WarmnessGrayButton>
          </div>
          <ModalInviteUserList data={observersData} userId={userId} />
        </div>
      </div>
    </Form>
  )
}
