import type { TooltipPlacement } from 'antd/lib/tooltip'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import css from './PopoverKickOut.module.scss'

import { CustomPopover } from '#HACK_FOR_RN_ONLY/src/components/widget/CustomPopover'

import { Button } from '../../../components/base/Button'

type TProps = {
  children: ReactNode
  visible: boolean
  placement?: TooltipPlacement | undefined
  onVisibleKickOut: (status: boolean) => void
  onYes?: () => void
  isInput?: boolean
}
export const PopoverKickOut = ({
  children,
  visible,
  onVisibleKickOut,
  onYes,
  placement = 'left',
  isInput = false,
}: TProps) => {
  const [visibleKickOut, setVisibleKickOut] = useState(visible)

  const handleYes = () => {
    handleClose()
    onYes && onYes()
  }
  const handleClose = () => {
    handleVisibleChange(false)
  }
  const handleVisibleChange = (status: boolean) => {
    setVisibleKickOut(status)
    onVisibleKickOut(status)
  }
  const content = () => (
    <div className={css.ContentPopoverKickOut}>
      <div className={css.TitlePopover}>
        Are you sure you want to{' '}
        {isInput ? 'remove this input' : 'kick this user out'}?
      </div>
      <div className={css.TextPopover}>Action can't be undone</div>
      <div className={css.ActionPopover}>
        <Button
          containerType='grey'
          className={css.CancelPopover}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          containerType='danger'
          className={css.OkPopover}
          onClick={handleYes}
        >
          Yes
        </Button>
      </div>
    </div>
  )
  useEffect(() => {
    if (visibleKickOut !== visible) {
      setVisibleKickOut(visible)
    }
  }, [visible])
  return (
    <CustomPopover
      visible={visibleKickOut}
      placement={placement}
      onVisibleChange={handleVisibleChange}
      content={content()}
      overlayClassName='popover-custom-arrow'
    >
      {children}
    </CustomPopover>
  )
}
