import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const handleTranscribe = async (rId: string) => {
  const $ = S.webrtc
  const $1 = S.insightEngine
  if (!$.detail?.insightEngineId) {
    return
  }
  S.local.appLoading = true
  const res = await reduxStore.context.gql.createResourceInInsightEngine({
    data: { insightEngineId: $.detail.insightEngineId, resourceId: rId },
  })
  if (!res.data?.createResourceInInsightEngine) {
    ToastService.error({ content: 'Transcribe failed.' })
    S.local.appLoading = false
    return
  }
  $1.resources.push(res.data.createResourceInInsightEngine)
  $1.resourceSelected = res.data.createResourceInInsightEngine
  S.local.appLoading = false
}
