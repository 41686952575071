import { observer } from 'mobx-react-lite'

import css from './HeaderRoom.module.scss'

import { routerPaths } from '../../../context/router/routerPaths'
import { SessionTitle } from '../../../context/shared/components/SessionTitle'
import { S } from '../../../context/store'

export const HeaderRoom = observer(() => {
  const {
    webrtc: { isViewmodeParticipant },
  } = S

  return (
    <div className={css.MainContentTitle}>
      <SessionTitle
        showEditTitle={!isViewmodeParticipant}
        backHref={routerPaths.SESSION_MANAGEMENT}
      />
    </div>
  )
})
