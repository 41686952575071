import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import css from './MediaSeekbar.module.scss'

import { BeamSlider as Slider } from '../../../components/base/Slider'
import { S } from '../../../context/store'
import { convertDurationToHours } from '../../../context/store/studio/utils'

export const MediaSeekBar = observer(() => {
  const { mediaPlayer, setMediaPlayer, setAllMediaPlayer, isBlurMode, mode } =
    S.insightEngine
  const { currentTime, isPlaying, duration } = mediaPlayer
  const isDisabled = !S.insightEngine.resourceSelected

  const handleAfterChange = (value: number) => {
    if (mediaPlayer.videoRef?.currentTime !== undefined) {
      mediaPlayer.videoRef.currentTime = value
    }
    if (isPlaying) {
      setMediaPlayer({ isPlaying }, mode)
    }
  }

  const handleChange = (value: number) => {
    setMediaPlayer({ currentTime: value, isPlaying: false }, mode)
  }

  const handleSpacePress = (e: KeyboardEvent) => {
    const el = document.activeElement

    if (
      el &&
      (el.tagName.toLowerCase() == 'input' ||
        el.tagName.toLowerCase() == 'textarea' ||
        el.hasAttribute('contenteditable'))
    ) {
      return
    }
    if (e.code == 'Space') {
      if (S.insightEngine.mediaLoading || !S.insightEngine.resourceSelected) {
        return
      }

      setAllMediaPlayer({
        isPlaying: !S.insightEngine.mediaPlayer.isPlaying,
        currentTime:
          S.insightEngine.mediaPlayer.currentTime === duration
            ? 0
            : S.insightEngine.mediaPlayer.currentTime,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleSpacePress)

    return () => {
      window.removeEventListener('keydown', handleSpacePress)
    }
  }, [])
  return (
    <div className={css.SeekBarSliderContainer}>
      {isBlurMode && <div className={css.FakeTrack} />}
      <Slider
        disabled={isDisabled}
        className={clsx(css.SeekBarSlider, {
          [css.SeekBarSliderCursorMode]: !isBlurMode,
        })}
        trackStyle={{
          backgroundColor: '#2656C9',
          borderTopLeftRadius: isBlurMode ? 0 : undefined,
          borderBottomLeftRadius: isBlurMode ? 0 : undefined,
        }}
        handleStyle={{
          top: '50%',
          transform: 'translate(-50%,-50%)',
          marginTop: 0,
          width: 16,
          height: 16,
          boxShadow:
            '0px 1px 4px 0px rgba(1, 2, 11, 0.32), 0px 4px 16px 0px rgba(1, 2, 11, 0.16), 0px 4px 8px 0px rgba(1, 2, 11, 0.12)',
          border: 'none',
        }}
        value={currentTime}
        tipFormatter={v => <>{convertDurationToHours(v)}</>}
        max={duration}
        onChange={handleChange}
        step={0.3}
        onAfterChange={handleAfterChange}
      />
    </div>
  )
})
