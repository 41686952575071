import { InfoCircleOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import { useState } from 'react'

import css from './ItemAccordion.module.scss'

import { BeamTooltip } from '#HACK_FOR_RN_ONLY/src/components/base/Tooltip'

import { Icon } from '../../../components/base/Icon'

export const ItemAccordion = ({
  label,
  children,
  toolTip = '',
  disableCollapse = false,
}: {
  label?: string
  children: ReactNode
  toolTip?: string
  disableCollapse?: boolean
}) => {
  const [isToggle, setToggle] = useState(false)

  const onToggle = () => {
    setToggle(!isToggle)
  }
  const toggle = isToggle ? 'close' : 'open'
  return (
    <div className={css.ItemAccordionContainer}>
      {label && (
        <div className={css.ItemAccordionHeader}>
          <label className={css.ItemAccordionHeaderLabel}>
            {label}
            {toolTip !== '' && (
              <BeamTooltip className={css.LabelIconTooltip} title={toolTip}>
                <InfoCircleOutlined />
              </BeamTooltip>
            )}
          </label>
          {!disableCollapse && (
            <Icon
              icon='icon_arrow_right'
              size={14}
              className={clsx({
                [css.ItemAccordionHeaderIcon]: true,
                [css.itemAccordionHeaderIconToggle]: toggle === 'close',
              })}
              onClick={onToggle}
            />
          )}
        </div>
      )}
      <div
        className={clsx({
          [css.ItemAccordionContents]: true,
          [css.itemAccordionContentsToggle]: toggle === 'close',
        })}
        // toggle={isToggle ? 'close' : 'open'}
      >
        {children}
      </div>
    </div>
  )
}
