import { Modal } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useState } from 'react'

import css from './ModalInviteUserNew.module.scss'

import { Form } from '#HACK_FOR_RN_ONLY/src/components/base/Form'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { Show } from '#HACK_FOR_RN_ONLY/src/components/base/Show'
import { WarmnessDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessDiv'
import { WarmnessGraySpan } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessGraySpan'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { ModalService } from '../../../components/Modal/Modal'
import { CONSTANTS } from '../../../styles/Constants'
import { reduxStore } from '../../redux'
import { S } from '../../store'
import { UseRTMPorSRT } from './ModalRTMPorSRT'
import { ModalUserListHost } from './ModalUserListHost'
import { ModalUserListObserver } from './ModalUserListObserver'

interface Props {
  visible: boolean
  handleClose: () => void
  onSuccessInvite?: (total: number) => void
  role: string
}

export const ModalInviteNew = observer(
  ({ visible, handleClose, role }: Props) => {
    const sessionId = S.webrtc.sessionId
    const [state, setState] = useState<{
      disableInputHost: boolean
      disableInputObserver: boolean
    }>({ disableInputHost: false, disableInputObserver: false })
    const [form] = Form.useForm()

    const onCloseModal = () => {
      form.resetFields()
      handleClose()
    }

    const handleInviteEmail = async (
      type: 'emailObserver' | 'emailMainRoom',
    ) => {
      try {
        const result = await form.getFieldsValue([type])
        if (!result.emailMainRoom && !result.emailObserver) {
          throw new Error()
        }
        if (result.emailObserver) {
          setState(s => ({ ...s, disableInputObserver: true }))
          await reduxStore.context.gql.inviteUserInSession({
            sessionId,
            observers: result.emailObserver,
          })
          form.resetFields(['emailObserver'])

          setState(s => ({ ...s, disableInputObserver: false }))
        }
        if (result.emailMainRoom) {
          setState(s => ({ ...s, disableInputHost: true }))
          await reduxStore.context.gql.inviteUserInSession({
            sessionId,
            participants: result.emailMainRoom,
          })
          form.resetFields(['emailMainRoom'])
          setState(s => ({ ...s, disableInputHost: false }))
        }
        ToastService.success({ content: 'Send invite success' })
      } catch (e) {
        setState(s => ({
          ...s,
          disableInputHost: false,
          disableInputObserver: false,
        }))
        ToastService.error({ content: 'Send invite failed' })
        console.log(e)
      }
    }

    const users = S.webrtc.peers
    const usersHost = users.filter(user => user.data.viewmode === 'rhost')

    const usersParticipant = users.filter(
      user => user.data.viewmode === 'rparticipant',
    )

    const usersObserver = users.filter(
      user => user.data.viewmode === 'robserver',
    )

    const usersMainRoom = [...usersHost, ...usersParticipant, ...usersObserver]

    return (
      <Modal
        visible={visible}
        title={''}
        className={css.WrapperModal}
        onCancel={onCloseModal}
        closeIcon={
          <Icon
            icon='icon_close_popup'
            size={12}
            className={css.ModalInviteClose}
          />
        }
        footer={null}
        centered
        maskStyle={{ background: CONSTANTS.basicColor.maskBackgroundModal }}
      >
        <div
          className={css.ModalContainer}
          style={{ background: S.local.warmnessColor }}
        >
          <WarmnessDiv
            className={clsx(css.ModalFooter, {
              [css.ModalFooterDarkMode]: !S.local.isLightTheme,
            })}
            onMouseDown={(e: MouseEvent) => e.stopPropagation()}
          >
            <div className={css.ModalTitle}>
              <WarmnessGraySpan
                className={clsx(css.IconTitle, css.iconTitleUser)}
              >
                <Icon icon='icon_invite_user' size={16} />
              </WarmnessGraySpan>
              <div className={css.Title}>Invite</div>
            </div>
            <Show>
              <Show.When isTrue={role === 'Observer'}>
                <ModalUserListObserver
                  data={usersObserver}
                  handleInviteEmail={handleInviteEmail}
                  form={form}
                  disableInput={state.disableInputObserver}
                />
              </Show.When>
              <Show.When isTrue={role !== 'Observer'}>
                <ModalUserListHost
                  data={usersMainRoom}
                  handleInviteEmail={handleInviteEmail}
                  disableInputHost={state.disableInputHost}
                  disableInputObserver={state.disableInputObserver}
                  form={form}
                />
              </Show.When>
            </Show>

            <div className={css.ModalSubmit}>
              <div className={css.ModalSubmitContent}>
                <WarmnessGrayButton
                  className={css.BtnInvite}
                  onClick={() =>
                    ModalService.show(UseRTMPorSRT, { zIndex: 1200 })
                  }
                >
                  Set up RTMP/SRT
                </WarmnessGrayButton>
                <WarmnessGrayButton
                  className={css.BtnInvite}
                  onClick={onCloseModal}
                >
                  Close
                </WarmnessGrayButton>
              </div>
            </div>
          </WarmnessDiv>
        </div>
      </Modal>
    )
  },
)
