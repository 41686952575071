import type { CreateChatbotMsg } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { getLocationData } from './getLocationData'

export const createChatbotMsg = async (message: CreateChatbotMsg) => {
  const { updateMessagesChatbot, searchChatbotMsg, resourceSelected } =
    S.insightEngine
  const { insightEngineId } = getLocationData()

  const { data: { createChatbotMsg: messageRes } = {} } =
    await reduxStore.context.gql.createChatbotMsg({
      data: {
        ...message,
        insightEngineId,
        resourceInInsightEngineId:
          resourceSelected?.status === 'Complete'
            ? resourceSelected?.id
            : undefined,
      },
    })
  messageRes && updateMessagesChatbot(messageRes)
  searchChatbotMsg.isAnswering = true
}
