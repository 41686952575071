import { observer } from 'mobx-react-lite'
import { QRCodeCanvas } from 'qrcode.react'
import qs from 'qs'
import type { MouseEvent } from 'react'
import { useState } from 'react'

import css from './QrScannerModal.module.scss'

import { qsIdSecret } from '##/shared/token'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { reactServerOrigin } from '../../../config'
import type { SessionRoleName } from '../../../context/gql/codegen'
import { useGqlGenerateUserToken } from '../../../context/gql/codegen'
import { S } from '../../store'

export const QrScannerModal = observer(() => {
  const { sessionId, role } = S.webrtc
  const [value, setValue] = useState('')
  const [result, generateToken] = useGqlGenerateUserToken()

  const { currentOrg } = S.profile
  const copyImg = () => {
    const img = document.getElementById('imageQr') as HTMLImageElement
    const canvas = document.createElement('canvas') as HTMLCanvasElement
    canvas.width = img.width
    canvas.height = img.height
    canvas?.getContext('2d')?.drawImage(img, 0, 0, img.width, img.height)
    canvas.toBlob(blob => {
      navigator.clipboard.write([
        new ClipboardItem({ 'image/png': blob ?? '' }),
      ])
    }, 'image/png')
    ToastService.success({ content: 'Copied' })
  }
  const handleGenToken = async () => {
    if (!value) {
      const r = await generateToken()
      if (r.data?.generateUserToken) {
        setValue(qsIdSecret(r.data.generateUserToken))
      }
    } else {
      setValue('')
    }
  }
  const getQrValue = () => {
    const params: {
      r: string
      token?: string
      role?: SessionRoleName
    } = {
      r: sessionId,
      role: role?.name,
    }
    if (value) {
      params.token = value
    }
    const q = qs.stringify(params)
    return `${reactServerOrigin}/studio?${q}`
  }
  return (
    <div
      className={css.Wrapper}
      onMouseDown={(e: MouseEvent) => e.stopPropagation()}
    >
      <div className={css.Title}>Join with your device</div>
      <div className={css.SubTitle}>
        Scan the QR code using your camera to join the session
      </div>

      <QRCodeCanvas
        id='imageQr'
        includeMargin
        size={200}
        className={css.QRCode}
        value={getQrValue()}
        imageSettings={{
          src: currentOrg.systemLogoResource?.url ?? '',
          height: 24,
          width: 24,
          excavate: true,
        }}
      />

      <Button containerType='dark' onClick={copyImg} className={css.ButtonCopy}>
        <Icon icon='Copy' size={16} className={css.CopyIcon} />
        Copy
      </Button>

      <Button
        containerType='dark'
        onClick={handleGenToken}
        className={css.ButtonToken}
        loading={result.fetching}
      >
        {value ? 'QR without access token' : 'QR with access token'}
      </Button>
    </div>
  )
})
