import { routerPaths } from '../../../context/router/routerPaths'
import { S } from '../../../context/store'
import { getLocationData } from './getLocationData'

export const checkAndUploadResource = () => {
  const { mediaId, searchParams, url, pathName } = getLocationData()
  if (!pathName.includes(routerPaths.INSIGHT_ENGINE_ORIGIN)) {
    return
  }
  const $ = S.insightEngine
  const riies = $.resources
  if (!riies.length) {
    return
  }
  if (!riies.length) {
    return
  }
  const rs = riies.find(r => r.resourceId === mediaId)
  if (!rs) {
    $.resourceSelected = riies[0]
    searchParams.set('mediaId', riies[0].resourceId)
    history.replaceState({}, document.title, url.href)
    return
  }
  if (!$.resourceSelected) {
    $.resourceSelected = rs
    return
  }
}
