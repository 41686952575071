import clsx from 'clsx'
import type { DropTargetMonitor } from 'react-dnd'
import { useDrop } from 'react-dnd'

import css from './MediaDropPlaylist.module.scss'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import { DRAG_MEDIA_ADD_PLAYLIST } from '../../../context/store/studio/dragTypes'
import type { TItemSort } from './RightContent'

export const DropPlaylist = ({ selectedTab }: { selectedTab: string }) => {
  const {
    mediaSelectionId,
    removeItemPlaylist,
    updateDataOfStore,
    addMediaToPlaylist,
    updateEmitAndSaveSettings,
  } = S.webrtc
  const [{ isOver }, drop] = useDrop({
    accept: DRAG_MEDIA_ADD_PLAYLIST,
    drop: (item: TItemSort) => {
      const isTabPlayList = selectedTab === 'playlist'
      let mediaPlaylists: string[] = isTabPlayList
        ? [item.playlistId]
        : [item.id]

      if (mediaSelectionId.length > 0) {
        mediaPlaylists = [...mediaSelectionId]
      }
      if (isTabPlayList) {
        removeItemPlaylist(mediaPlaylists)
      } else {
        addMediaToPlaylist(mediaPlaylists)
      }
      updateDataOfStore({
        mediaSelectionId: [],
        typeDraggingItem: '',
      })
      updateEmitAndSaveSettings()
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
  })
  return (
    <div
      className={clsx({
        [css.DropPlaylistContainer]: true,
        [css.dropPlaylistContainerHover]: isOver,
      })}
      ref={drop}
      // hover={isOver}
    >
      <Icon
        icon={selectedTab === 'playlist' ? 'icon_warning' : 'icon_plus'}
        size={14}
      />
      {selectedTab === 'playlist'
        ? 'Drop here to remove from playlist'
        : 'Drop here to add to playlist'}
    </div>
  )
}
