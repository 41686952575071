import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { IBlurItem } from '../../../context/store/insight-engine/InsightEngineStore'

export const updateBlurBlock = (data: Partial<IBlurItem>) => {
  const $ = S.insightEngine

  $.blurBlocks = $.blurBlocks.map(m => {
    if (data.id === m.id) {
      return {
        ...m,
        ...data,
      }
    }
    return m
  })
}

export const updateBlurBlockApi = (id: string) => {
  const { blurBlocks } = S.insightEngine

  const data = blurBlocks.find(b => b.id === id)
  if (data) {
    reduxStore.context.gql.updateBlurBlock({
      id: id ?? '',
      data: {
        blockWidth: data.width,
        blockHeight: data.height,
        borderRadius: data.radius,
        top: data.top,
        left: data.left,
        startTime: data.startTime,
        endTime: data.endTime,
      },
    })
  }
}
