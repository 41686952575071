import { message, Progress } from 'antd'
import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import clsx from 'clsx'
import { toArray } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { ulid } from 'ulidx'

import IconError from '../../../assets/icons/ic_error.svg'
import IconSuccess from '../../../assets/icons/ic_success.svg'
import IconWarning from '../../../assets/icons/icon_warning.svg'
import css from './MediaHeader.module.scss'

import { Popover } from '#HACK_FOR_RN_ONLY/src/components/base/Popover'
import { CustomPopover } from '#HACK_FOR_RN_ONLY/src/components/widget/CustomPopover'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { ModalService } from '../../../components/Modal/Modal'
import { NotificationService } from '../../../components/widget/Notification'
import { ToastService } from '../../../components/widget/Toast'
import { ModalOutStorage } from '../../../context/shared/components/ModalOutStorage'
import { NotificationOutStorage } from '../../../context/shared/components/NotificationOutStorage'
import { S } from '../../../context/store'
import { Helper } from '../../../context/store/studio/helper'
import {
  covertGBtoBytes,
  formatBytes,
  formatFileName,
  getFileTypeOfStudio,
  getTotalSizeOfFiles,
  groupFileByType,
  modifyFiles,
  pluralText,
} from '../../../context/store/studio/utils'
import { CONSTANTS } from '../../../styles/Constants'
import { isOutOfStorage } from '../../../utils/plan'
import type { TFileUploadHook } from '../../../utils/useCustom'
import { useFileUpload } from '../../../utils/useCustom'
import { ModalChangePlan } from '../../SubscriptionPlan/components/ModalChangePlan'
import type { TModifyFiles, TUploadedFile, TUploadFile } from './RightContent'

export type TFileEdit = {
  progress?: number
  status?: number
  cancelSource?: CancelTokenSource
}
export type TProgressFile = {
  filesUpload: TUploadFile[]
  statusFileUpload: TFileUploadHook
  onCancelUpload: () => void
  onCloseUpload: () => void
  onRetry: (id: number) => void
  isShowSummary: boolean
}
export type TButtonUpload = {
  onUploadFile: (file: FileList) => void
}
export type TPopoverFile = {
  filesUpload: TUploadFile[]
  isShowPopover: boolean
  statusFileUpload: TFileUploadHook
  onRetry: (id: number) => void
}

export const Header = observer(() => {
  const currentSub = S.webrtc.plan
  const [filesUpload, setFilesUpload] = useState<TModifyFiles>({})
  const [isStartUpload, setStart] = useState<boolean>(false)
  const [listFilesUploaded, setFilesUploaded] = useState<TUploadedFile[]>([])
  const [listFilesUploadedandShow, setFilesShow] = useState<TUploadedFile[]>([])
  const [isShowSummary, setShowSumary] = useState<boolean>(false)
  const [isCompleteUpload, setComplete] = useState<boolean>(false)
  const statusFileUpload: TFileUploadHook = useFileUpload(filesUpload)
  const isEnterprise = currentSub?.plan === 'Enterprise'
  const storageInUse = Number(currentSub?.totalStorageUsed) || 0
  const storageTotal = Number(currentSub?.totalStorage) || 0

  const handleCheckStorage = (files: FileList) => {
    const storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
    const totalSize = getTotalSizeOfFiles(files)

    const modifyFile: TModifyFiles = modifyFiles(filesUpload, files)

    if (totalSize >= storageAvailable && !isEnterprise) {
      ModalService.show(ModalOutStorage)
      return
    }
    onUploadFile(modifyFile)
  }
  const handleSetStatusFile = (id: number, data: TFileEdit) => {
    setFilesUpload(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...data,
      },
    }))
  }
  const handleUploadFile = async (files: TUploadFile[]) => {
    if (files.length > 0) {
      let storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
      const exceedFiles: string[] = []
      for (let index = 0; index < files.length; index++) {
        const id = Helper.generateGuid()
        S.webrtc.addFileMedia({
          video: [
            {
              id,
              mediaType: 'video',
              value: 'loading',
            },
          ],
        })
        S.webrtc.isVideoUploading = true
        const file = files[index]
        const item = file.file
        if (storageAvailable > item.size || isEnterprise) {
          storageAvailable = storageAvailable - item.size
          const formData = new FormData()
          formData.append('file', item)
          try {
            const result = await S.webrtc.callApiUpload(
              formData,
              getFileTypeOfStudio(file.type),
              {
                cancelToken: file.cancelSource.token,
                onUploadProgress: (progress: ProgressEvent) =>
                  countProgress(progress, file.id),
              },
            )
            if (!axios.isCancel(result)) {
              if (result instanceof Error) {
                handleSetStatusFile(file.id, {
                  progress: 100,
                  status: 0,
                })
              }
            } else {
              handleSetStatusFile(file.id, {
                progress: 100,
                status: -1,
              })
            }
            const list = {
              ...result,
              id: result.id || ulid(),
              value:
                typeof result?.resource?.url === 'string'
                  ? result?.resource?.url
                  : '',
              mediaType: getFileTypeOfStudio(file.type),
              name: file.file.name,
              size: result?.resource?.fileSize || 0,
              position: (result?.position || 1000) + '',
            }
            S.webrtc.deleteFileMedia(id, 'video')

            setFilesUploaded(prev => [...prev, list])
            S.webrtc.isVideoUploading = false
          } catch (err) {
            console.error('Header.handleUploadFile error:')
            console.error(err)
            handleSetStatusFile(file.id, {
              progress: 100,
              status: 0,
            })
            setFilesUploaded(prev => [
              ...prev,
              {
                id: ulid(),
                value: '',
                mediaType: getFileTypeOfStudio(file.type),
                name: item.name,
                size: item.size,
                position: '',
              },
            ])
          }
        } else {
          exceedFiles.push(file.file.name)
        }
      }
      if (exceedFiles.length > 0) {
        onClearUpload()
        NotificationService.error({
          message: 'Failed to upload file',
          description: <NotificationOutStorage files={exceedFiles} />,
        })
      }
    }
  }
  const onClearUpload = () => {
    setFilesUpload({})
    setStart(false)
    setFilesUploaded([])
    setFilesShow([])
    setComplete(false)
  }
  const onCloseUpload = () => {
    const groupFile = groupFileByType(listFilesUploaded)
    S.webrtc.addFileMedia(groupFile)
    onClearUpload()
  }
  const onUploadFile = (files: TModifyFiles) => {
    // const t = currentSub?.storageTotal || 0
    // const u = currentSub?.storageInUse || 0
    const storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
    const a = storageAvailable || 0
    if (a <= 0 && !isEnterprise) {
      ModalService.show(ModalOutStorage)
      return
    }

    setFilesUpload(prev => ({
      ...prev,
      ...files,
    }))
    if (!isStartUpload) {
      setStart(true)
    }
  }
  const onCancelUpload = () => {
    try {
      toArray(filesUpload).forEach(item => {
        if (item.progress !== 100 && item.status !== 1) {
          item.cancelSource.cancel('Canceled')
        }
      })
    } catch (err) {
      ToastService.error({ content: 'Failed to cancel upload' })
      console.error(err)
    }
  }
  const onRetry = (id: number) => {
    const fileRetry = toArray(filesUpload).find(file => file.id === id)
    if (fileRetry) {
      handleUploadFile([
        {
          ...fileRetry,
          progress: 0,
          status: 0,
        },
      ])
    }
  }
  const countProgress = (progress: ProgressEvent, id: number) => {
    const { loaded, total } = progress
    const percentageProgress = Math.floor((loaded / total) * 100)

    handleSetStatusFile(id, {
      progress: percentageProgress,
      status: percentageProgress === 100 ? 1 : 0,
    })
  }
  const renderPopoverOutOfStorage = () => (
    <div className={css.ContentPopoverOut}>
      <h5 className={css.TitlePopoverOut}>You're out of storage.</h5>
      <p className={css.DescPopoverOut}>
        New media will be saved to temporary storage and be deleted after you
        exit this session
      </p>
      <Button onClick={() => ModalService.show(ModalChangePlan)}>
        Upgrade
      </Button>
    </div>
  )
  useEffect(() => {
    if (isStartUpload) {
      const fileToUpload = toArray(filesUpload).filter(
        file => file.progress === 0,
      )
      handleUploadFile(fileToUpload)
    }
  }, [isStartUpload, statusFileUpload.totalFile])

  useEffect(() => {
    const filterFile = listFilesUploaded.filter(
      item => !listFilesUploadedandShow.some(f => f.id === item.id),
    )
    setFilesShow(prev => [...prev, ...filterFile])
    // store.webrtc.updateEmitAndSaveSettings()
  }, [listFilesUploaded])

  useEffect(() => {
    if (
      listFilesUploaded.length === statusFileUpload.totalFile &&
      listFilesUploaded.length > 0 &&
      !isCompleteUpload
    ) {
      setComplete(true)
      if (statusFileUpload.totalFileCanceled !== 0) {
        onClearUpload()
        return
      }
      if (statusFileUpload.totalFileSuccess === statusFileUpload.totalFile) {
        ToastService.success({ content: 'Media uploaded successfully' })
        onClearUpload()
      } else {
        renderMessageError(statusFileUpload, () => setShowSumary(true))
      }
    }
  }, [listFilesUploaded, statusFileUpload.totalFile])

  const outOfStorage = isOutOfStorage(storageTotal, storageInUse)
  return (
    <div className={css.MediaHeader}>
      <div className={css.MediaHeaderTop}>
        <div className={css.MediaHeaderTitle}>Media</div>
        <div
          className={clsx([
            css.MediaHeaderStorage,
            outOfStorage && !isEnterprise && css.MediaHeaderOutOfStorage,
          ])}
        >
          {outOfStorage && !isEnterprise && (
            <Popover
              content={renderPopoverOutOfStorage()}
              overlayClassName={css.MeaderHeaderPopover}
            >
              <img src={IconWarning} className={css.MediaHeaderIconWarning} />
            </Popover>
          )}
          <span>
            {storageInUse.toFixed(2)}GB used of{' '}
            {isEnterprise ? 'Unlimited ' : storageTotal}GB
          </span>
          {outOfStorage && (
            <span
              className={css.MediaHeaderLinkUpgrade}
              onClick={() => ModalService.show(ModalChangePlan)}
            >
              Upgrade
            </span>
          )}
        </div>
      </div>
      <ButtonUpload onUploadFile={handleCheckStorage} />
      {statusFileUpload.totalFile > 0 &&
        statusFileUpload.totalFileSuccess !== statusFileUpload.totalFile && (
          <UploadProgress
            filesUpload={toArray(filesUpload)}
            statusFileUpload={{
              ...statusFileUpload,
              isCompleteUpload,
            }}
            onCancelUpload={onCancelUpload}
            onRetry={onRetry}
            isShowSummary={isShowSummary}
            onCloseUpload={onCloseUpload}
          />
        )}
    </div>
  )
})

export const ButtonUpload = ({ onUploadFile }: TButtonUpload) => {
  const uploadFile = useRef<null | HTMLInputElement>(null)
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (!files) {
      return
    }
    onUploadFile(files)
  }
  const onSelectFile = () => {
    if (uploadFile?.current) {
      uploadFile.current.click()
    }
  }

  return (
    <div className={css.MediaUpload}>
      <WarmnessGrayButton
        onClick={onSelectFile}
        className={css.MediaUploadButton}
      >
        <input
          className={css.MediaUploadInput}
          accept='image/*,video/*,audio/*,.mkv'
          type='file'
          value=''
          onChange={handleChange}
          ref={uploadFile}
          multiple
        />
        <Icon icon='icon_upload' size={14} className={css.IconUpload} />
        Upload
      </WarmnessGrayButton>
    </div>
  )
}

export const UploadProgress = ({
  filesUpload,
  statusFileUpload,
  onCancelUpload,
  onRetry,
  isShowSummary,
  onCloseUpload,
}: TProgressFile) => {
  const [isShowPopover, setTogglePopover] = useState(isShowSummary)

  const onShowSumary = () => {
    setTogglePopover(true)
  }
  useEffect(() => {
    if (isShowSummary !== isShowPopover) {
      setTogglePopover(isShowSummary)
    }
  }, [isShowSummary])
  return (
    <div className={css.MediaUploadStatus}>
      <PopoverFileSummary
        filesUpload={filesUpload}
        isShowPopover={isShowPopover}
        statusFileUpload={statusFileUpload}
        onRetry={onRetry}
      />
      {statusFileUpload.isCompleteUpload &&
      statusFileUpload.totalFileError > 0 ? (
        <BlockErrorProgress
          statusFileUpload={statusFileUpload}
          onShowSumary={onShowSumary}
          onCloseUpload={onCloseUpload}
        />
      ) : (
        <BlockUploadingProgress
          statusFileUpload={statusFileUpload}
          onShowSumary={onShowSumary}
          onCancelUpload={onCancelUpload}
        />
      )}
    </div>
  )
}

export const PopoverFileSummary = observer(
  ({ filesUpload, isShowPopover, statusFileUpload, onRetry }: TPopoverFile) => {
    const { isLightTheme } = S.local
    const contentList = () => (
      <div className={css.ProgressPopover}>
        <h5 className={css.ProgressPopoverTitle}>{`${
          statusFileUpload.totalFileSuccess
        }/${statusFileUpload.totalFile} ${pluralText(
          'file',
          statusFileUpload.totalFileSuccess,
        )} uploaded`}</h5>
        {filesUpload.map((item, index) => (
          <div className={css.ProgressPopoverItem} key={index}>
            <Icon
              icon={(() => {
                switch (item.type) {
                  case 'image':
                    return 'icon_media_image'
                  case 'gif':
                    return 'icon_media_gif'
                  case 'video':
                    return 'icon_media_video'
                  case 'audio':
                    return 'icon_media_music'
                  default:
                    return 'icon_warning'
                }
              })()}
              size={14}
              className={css.ProgressPopoverType}
              // type={item.type}
            />
            <div>{formatFileName(item.file.name, 30)}</div>
            <div className={css.ProgressPopoverStatus}>
              {item.progress >= 0 && item.progress < 100 ? (
                <Progress
                  type='circle'
                  percent={item.progress}
                  width={24}
                  trailColor={CONSTANTS.level.dark1}
                  strokeWidth={16}
                  strokeLinecap='square'
                  strokeColor={isLightTheme ? '#2656C9' : '#4098FF'}
                  format={() => ''}
                />
              ) : item.status === 1 ? (
                <img src={IconSuccess} />
              ) : (
                <>
                  {item.status === 0 && (
                    <span
                      className={css.ProgressStatusRetry}
                      onClick={() => onRetry(item.id)}
                    >
                      Retry
                    </span>
                  )}
                  <img src={IconError} />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    )
    return (
      <CustomPopover
        isLightTheme={isLightTheme}
        placement='leftTop'
        content={contentList}
        visible={isShowPopover}
      />
    )
  },
)

export const BlockErrorProgress = ({
  statusFileUpload,
  onCloseUpload,
  onShowSumary,
}: {
  statusFileUpload: TFileUploadHook
  onCloseUpload: () => void
  onShowSumary: () => void
}) => (
  <>
    <Progress
      type='circle'
      percent={0}
      width={42}
      trailColor={CONSTANTS.level.dark1}
      strokeWidth={10}
      strokeLinecap='square'
      strokeColor='transparent'
      format={() => <img src={IconError} />}
    />
    <div className={css.ProgressStatus}>
      {statusFileUpload.totalFileSuccess}/{statusFileUpload.totalFile}{' '}
      {pluralText('file', statusFileUpload.totalFileSuccess)} uploaded
      <p className={css.ProgressStatusError}>
        {statusFileUpload.totalFileError}{' '}
        {pluralText('file', statusFileUpload.totalFileError)} got error
      </p>
    </div>
    <div className={css.ProgressAction}>
      <p className={css.MediaUploadCancel} onClick={onCloseUpload}>
        Close
      </p>
      <p className={css.MediaUploadView} onClick={onShowSumary}>
        View
      </p>
    </div>
  </>
)

export const BlockUploadingProgress = observer(
  ({
    statusFileUpload,
    onCancelUpload,
    onShowSumary,
  }: {
    statusFileUpload: TFileUploadHook
    onCancelUpload: () => void
    onShowSumary: () => void
  }) => {
    const { isLightTheme } = S.local
    return (
      <>
        <Progress
          type='circle'
          percent={statusFileUpload.totalPercentUploaded}
          width={42}
          trailColor={isLightTheme ? '#f3f2f2' : CONSTANTS.level.dark1}
          strokeWidth={10}
          strokeLinecap='square'
          strokeColor={isLightTheme ? '#2656C9' : '#4098FF'}
          format={() => (
            <span className={css.ProgressPercent}>
              {statusFileUpload.totalPercentUploaded}%
            </span>
          )}
        />
        <div className={css.ProgressStatus}>
          <p className={css.ProgressStatusLabel}>Uploading</p>
          <p className={css.ProgressStatusSize}>
            {formatBytes(statusFileUpload.totalSizeUploaded)} of{' '}
            {formatBytes(statusFileUpload.totalSize)}
          </p>
        </div>
        <div className={css.ProgressAction}>
          <p className={css.MediaUploadCancel} onClick={onCancelUpload}>
            Cancel
          </p>
          <p className={css.MediaUploadView} onClick={onShowSumary}>
            View
          </p>
        </div>
      </>
    )
  },
)

export const renderMessageError = (
  statusFileUpload: TFileUploadHook,
  onShowSumary: () => void,
) => {
  message.error({
    content: (
      <>
        {statusFileUpload.totalFileSuccess}/{statusFileUpload.totalFile} media
        uploaded,
        {statusFileUpload.totalFileError} media got error{' '}
        <span className={css.MessageToView} onClick={onShowSumary}>
          Click to view
        </span>
      </>
    ),
    icon: <img src={IconError} />,
    style: {
      marginTop: 'calc(100vh - 150px)',
    },
  })
}
