export const getStyled = (size?: string, type?: string) => {
  if (!type || type === 'hasText') {
    if (size === 'L14') {
      return {
        padding: '13.5px 16px ',
        fontSize: '14px',
        borderRadius: '32px',
      }
    }
    if (size === 'L13') {
      return {
        padding: '14px 16px',
        fontSize: '13px',
        borderRadius: '32px',
      }
    }
    if (size === 'M13') {
      return {
        padding: '10px 16px',
        fontSize: '13px',
        borderRadius: '32px',
      }
    }
    if (size === 'M14') {
      return {
        padding: '10px 16px',
        fontSize: '14px',
        borderRadius: '32px',
      }
    }
    if (size === 'S') {
      return {
        padding: '6px 16px',
        fontSize: '13px',
        borderRadius: '32px',
      }
    }
    if (size === '1XS' || size === '2XS') {
      return {
        padding: '3px 12px',
        fontSize: '12px',
        borderRadius: '32px',
      }
    }
    if (size === '3XS') {
      return {
        padding: '3px 12px',
        fontSize: '10px',
        borderRadius: '32px',
      }
    }
  }
  if (type === 'onlyIconRound') {
    if (size === 'L13' || size === 'L14') {
      return {
        padding: '12px',
        borderRadius: '50%',
        fontSize: '20px',
      }
    }
    if (size === 'M13' || size === 'M14') {
      return {
        padding: '12px',
        borderRadius: '50%',
        fontSize: '16px',
      }
    }
    if (size === 'S') {
      return {
        padding: '8px',
        borderRadius: '50%',
        fontSize: '16px',
      }
    }
    if (size === '1XS') {
      return {
        padding: '6px',
        borderRadius: '50%',
        fontSize: '16px',
      }
    }
    if (size === '2XS') {
      return {
        padding: '6px',
        borderRadius: '50%',
        fontSize: '12px',
      }
    }
    if (size === '3XS') {
      return {
        padding: '4px',
        borderRadius: '50%',
        fontSize: '12px',
      }
    }
  }
  if (type === 'onlyIconSquare') {
    if (size === 'L13' || size === 'L14') {
      return {
        padding: '12px',
        borderRadius: '8px',
        fontSize: '20px',
      }
    }
    if (size === 'M13' || size === 'M14') {
      return {
        padding: '12px',
        borderRadius: '8px',
        fontSize: '16px',
      }
    }
    if (size === 'S') {
      return {
        padding: '8px',
        borderRadius: '8px',
        fontSize: '16px',
      }
    }
    if (size === '1XS') {
      return {
        padding: '6px',
        borderRadius: '8px',
        fontSize: '16px',
      }
    }
    if (size === '2XS') {
      return {
        padding: '6px',
        borderRadius: '8px',
        fontSize: '12px',
      }
    }
    if (size === '3XS') {
      return {
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px',
      }
    }
  }
  return {
    padding: '10px 16px',
    fontSize: '13px',
    borderRadius: '32px',
  }
}
