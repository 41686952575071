import type { ReactElement } from 'react'
import { useEffect } from 'react'

import { separateLogic } from '##/reactjs/redux'

import { waitTimeout } from '../../../../../../#/shared/waitTimeout'
import type { Props } from '../../../../components/base/Icon/type'
import { ModalService } from '../../../../components/Modal/Modal'
import { ToastService } from '../../../../components/widget/Toast'
import {
  onStartRunDestination,
  onStopRecord,
} from '../../../../context/actions/mixerActions'
import { useGqlSearchOutput } from '../../../../context/gql/codegen'
import { reduxStore } from '../../../../context/redux'
import { S } from '../../../../context/store'
import type { WebrtcStoreOis } from '../../../../context/store/studio/WebrtcStore'
import { getOutputInSession } from '../../../../context/store/studio/WebrtcStore'
import type { ICountDownProps } from '../CountDown'
import { CountDown } from '../CountDown'
import { OutputInSessionStatus } from '../DestinationAction/type'
import type { TOutputs } from './type'

export const createComponent = separateLogic((props: Props) => {
  const session = S.webrtc.detail
  const { outputInSession, mainRecordingOis, viewmode, isPublishing } = S.webrtc
  if (!session) {
    return null
  }
  const isRecord = !!mainRecordingOis

  const { isLightTheme, warmnessColor } = S.local
  const [searchOutput, refetchSearchOutput] = useGqlSearchOutput({
    variables: {
      sessionId: session.id,
    },
  })

  const outputs = searchOutput.data?.searchOutput ?? []

  useEffect(() => {
    if (outputs) {
      S.webrtc.outputs = outputs
    }
  }, [outputs])

  const allSelectedOis = outputInSession.filter(
    o => o.output?.type !== 'Recording',
  )

  const handleStart = async (r: boolean) => {
    if (!allSelectedOis.length) {
      // This should not happen since we already disabled the button
      ToastService.error({ content: 'Please select destination' })
      return
    }
    ModalService.hide('ModalDestination')

    await waitTimeout(300)
    ModalService.show<ICountDownProps>(
      CountDown,
      {
        description: 'Your session will start automatically in',
        buttonLabel: 'Cancel',
        date: Date.now() + 3000,
        isLightTheme,
        onSubmit: () => {
          ModalService.hide()
          onStartRunDestination(session.id, Boolean(r))
        },
        onClickButton: () => {
          ModalService.hide()
        },
      },
      { maskClosable: false },
    )
  }

  const handleCheckRecord = async () => {
    const r = S.webrtc.role?.name
    if (!r || r === 'Participant') {
      return
    }
    if (mainRecordingOis) {
      await reduxStore.context.gql.deleteOutputInSession({
        id: mainRecordingOis.id,
      })
    } else {
      const q = r === 'Observer' ? 'mixer=mixerobserver' : 'mixer=mixerhost'
      await reduxStore.context.gql.createRecordingOutputInSession({
        sessionId: session.id,
        mixerQuery: q,
        roomType: viewmode,
      })
    }
    refetchData()
  }
  const refetchData = () => {
    refetchSearchOutput({ requestPolicy: 'network-only' })
    getOutputInSession()
  }
  const handleStopLive = async () => {
    try {
      await reduxStore.context.gql.stopOutputs({
        sessionId: S.webrtc.sessionId,
      })
      ToastService.success({ content: 'Publishing stopped' })
    } catch (err) {
      ToastService.error({ content: 'Failed to stop publishing' })
      console.error(err)
    }
    if (S.webrtc.isRecording) {
      onStopRecord(S.webrtc.sessionId)
    }
  }
  const renderOutputList = (
    r: TOutputs,
    renderElement: (props: {
      expired: boolean
      waiting: boolean
      publishing: boolean
      oisSelected?: WebrtcStoreOis
    }) => ReactElement,
  ) => {
    const outputInSessionSelected = outputInSession.find(
      ois => ois.outputId === r.id,
    )
    const isOutputExpired =
      outputInSessionSelected?.status === OutputInSessionStatus.Expired ||
      new Date(r.expiredAt ?? '').getTime() < new Date().getTime()
    const isWaiting =
      outputInSessionSelected?.status === OutputInSessionStatus.Waiting
    const isSelectedPublishing =
      outputInSessionSelected?.status === OutputInSessionStatus.Publishing
    return renderElement({
      oisSelected: outputInSessionSelected,
      expired: isOutputExpired,
      waiting: isWaiting,
      publishing: isSelectedPublishing,
    })
  }
  const isAnyExpired = outputInSession.some(
    o => o.status === OutputInSessionStatus.Expired && o.output,
  )
  const isWaiting = outputInSession.some(
    o => o.status === OutputInSessionStatus.Waiting && o.output,
  )
  return {
    isPublishing,
    handleStopLive,
    handleCheckRecord,
    warmnessColor,
    outputs,
    handleStart,
    isLightTheme,
    isRecord,
    searchOutput,
    outputInSession,
    refetchData,
    session,
    isAnyExpired,
    renderOutputList,
    isWaiting,
  }
})
