import type { RtpEncodingParameters } from 'mediasoup/node/lib/types'
import type { ProducerCodecOptions } from 'mediasoup-client/lib/Producer'
import type { RtpCodecCapability } from 'mediasoup-client/lib/RtpParameters'
import { Platform } from 'react-native'

// import type { ScreenshareQuality } from '../../../rn/src/context/persisted/persistedReducer'
import type { Mediasoup, SetMediasoupState } from '../type-ui'

export type ScreenshareQuality = '240p' | '360p' | '480p' | '720p' | 'unlimited'

export const _enableScreenshare = async (
  m: Mediasoup,
  set: SetMediasoupState,
  quality?: Exclude<ScreenshareQuality, 'unlimited'>,
  onEnd?: () => void,
) => {
  const o =
    Platform.OS === 'web'
      ? {
          audio: true,
          video: {
            displaySurface: 'monitor',
            // @ts-ignore
            logicalSurface: true,
            cursor: true,
          },
        }
      : undefined

  try {
    const stream = await navigator.mediaDevices.getDisplayMedia(o)
    const ds =
      Platform.OS === 'android'
        ? await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            },
          })
        : stream
    const tracks = stream.getTracks()
    const videoTrack = stream.getVideoTracks()[0]
    const audioTrack = ds?.getAudioTracks()[0]
    if (!videoTrack) {
      // stop all tracks if can not find video track
      tracks.forEach(t => t.stop())
      return
    }

    videoTrack.onended = () => {
      if (onEnd) {
        onEnd()
      }
    }

    // stop unrelevant tracks
    tracks.filter(t => t.id !== videoTrack.id).forEach(t => t.stop())

    // prepare encodings simulcast
    let encodings: RtpEncodingParameters[] | undefined = undefined
    const codecOptions: ProducerCodecOptions = {
      videoGoogleStartBitrate: 1000,
    }
    const codec: RtpCodecCapability | undefined = undefined
    if (Platform.OS === 'android' && quality) {
      let sizes: number[] = []
      // TODO adaptive has been removed
      sizes.push(parseInt(quality.replace(/\D/g, '')))
      const vs = videoTrack.getSettings()
      const w = Math.min(vs.width || 1080, vs.height || 1080)
      sizes = sizes.filter(v => v <= w)
      if (!sizes.length) {
        sizes.push(w)
      }
      const l = sizes.length
      encodings = sizes.map((size, i) => ({
        rid: 'high',
        dtx: true,
        scaleResolutionDownBy: w / size,
        // scalabilityMode: 'S1T3',
        // @ts-ignore
        priority: i === l - 1 ? 'high' : i === l - 2 ? 'medium' : 'low',
        networkPriority: i === 0 ? 'high' : i === 1 ? 'medium' : 'low',
        adaptivePtime: true,
      }))
    }

    // create producer
    const p1 = await m.send.transport?.produce({
      track: videoTrack,
      encodings,
      codecOptions,
      codec,
      appData: {
        type: 'shareVideo',
        share: true,
      },
    })
    if (!p1) {
      return
    }

    set(s => {
      s.peers[0].screenshareVideo = {
        id: p1.id,
        track: videoTrack,
      }
    })
    p1.on('transportclose', () => {
      delete m.send.producers[p1.id]
    })
    m.send.producers[p1.id] = p1

    if (!audioTrack) {
      console.log('!audioTrack')
      return
    }

    const p2 = await m.send.transport?.produce({
      track: audioTrack,
      codecOptions: {
        opusStereo: true,
        opusDtx: true,
      },
      appData: {
        type: 'shareAudio',
        share: true,
      },
    })
    if (!p2) {
      return
    }

    set(s => {
      s.peers[0].screenshareAudio = {
        id: p2.id,
        track: audioTrack,
      }
    })
    p2.on('transportclose', () => {
      delete m.send.producers[p2.id]
    })
    m.send.producers[p2.id] = p2

    return
  } catch (error) {
    return false
  }
}
