import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { IDownloadingResource } from '../type'

export const getDownloadingResources: () => Promise<
  IDownloadingResource[]
> = async () => {
  const rIds = S.insightEngine.resources.map(r => r.resourceId)
  const { data } = await reduxStore.context.gql.searchUserInResource({
    filter: {
      resourceId_in: rIds,
    },
  })
  if (!data?.searchUserInResource) {
    return []
  }

  const downloadingResources =
    data.searchUserInResource.reduce<IDownloadingResource[]>((acc, r) => {
      if (r.isDownloading && r) {
        acc.push(r)
      }

      return acc
    }, []) ?? []

  return downloadingResources
}
