import { Popover } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './style.module.scss'

import { BeamMenu } from '#HACK_FOR_RN_ONLY/src/components/base/Menu'

import { Icon } from '../../../../components/base/Icon'
import { BeamText } from '../../../../components/base/Text'
import { ModalService } from '../../../../components/Modal/Modal'
import type { Output } from '../../../../context/gql/codegen'
import { deleteOutputInSession } from '../../actions/deleteOutputInSession'
import { startDestination } from '../../actions/startDestination'
import { stopDestination } from '../../actions/stopDestination'
import { OutputType } from '../DestinationEnum'
import { DestinationItem } from '../DestinationItem'
import { InstagramFormDestination } from '../InstagramFormDestination'
import { RTMPDestinationForm } from '../RTMPDestinationForm'
import { SelectDestination } from '../SelectDestination'
import { createComponent } from './logic'
import { OutputInSessionStatus } from './type'

export const DestinationAction = observer(
  createComponent(
    ({
      visible,
      destinationInSession,
      refreshDestination,
      onEditItem,
      onRemoveItem,
      isChecked,
      isPublishing,
      handleClickDestination,
      handleVisibleChange,
      setVisible,
      destination,
      componentProps,
      warning,
      isWaiting,
    }) => {
      const menu = () => (
        <div className={css.MenuWrapper}>
          <BeamMenu className={css.MenuContainer}>
            {destinationInSession &&
              destinationInSession.status === 'Publishing' && (
                <BeamMenu.Item
                  className={css.MenuItem}
                  onClick={() =>
                    stopDestination(destinationInSession.id, refreshDestination)
                  }
                  icon={<Icon icon='Stop' className={css.IconStop} size={16} />}
                  key='stop'
                >
                  <BeamText className={clsx(css.MenuText, css.ColorRed)}>
                    Stop stream
                  </BeamText>
                </BeamMenu.Item>
              )}
            {destinationInSession &&
              ![
                OutputInSessionStatus.Expired,
                OutputInSessionStatus.Publishing,
              ].includes(
                destinationInSession.status as OutputInSessionStatus,
              ) && (
                <BeamMenu.Item
                  className={css.MenuItem}
                  onClick={() =>
                    startDestination(
                      destinationInSession.id,
                      refreshDestination,
                    )
                  }
                  icon={<Icon icon='Play' size={16} />}
                  key='start'
                >
                  <BeamText className={css.MenuText}>Start stream</BeamText>
                </BeamMenu.Item>
              )}
            {destinationInSession &&
              !(
                (destinationInSession.status ===
                  OutputInSessionStatus.Publishing &&
                  (
                    [
                      OutputType.Facebook,
                      OutputType.Linkedin,
                      //  OutputType.Instagram,
                    ] as string[]
                  ).includes(destinationInSession.output?.type ?? '')) ||
                (destinationInSession.status ===
                  OutputInSessionStatus.Expired &&
                  destinationInSession.output?.type === OutputType.Instagram)
              ) && (
                <BeamMenu.Item
                  onClick={() => onEditItem(destination?.type as OutputType)}
                  className={css.MenuItem}
                  icon={<Icon icon='icon_pencil' size={16} />}
                  key='edit'
                >
                  <BeamText className={css.MenuText}>Edit</BeamText>
                </BeamMenu.Item>
              )}
            {destinationInSession &&
              destinationInSession?.status !== 'Publishing' &&
              !(
                destinationInSession.status === OutputInSessionStatus.Expired &&
                destinationInSession.output?.type === OutputType.Instagram
              ) && (
                <BeamMenu.Item
                  onClick={() =>
                    deleteOutputInSession(
                      destinationInSession.id,
                      refreshDestination,
                    )
                  }
                  className={css.MenuItem}
                  icon={<Icon icon='icon_warning' size={16} />}
                  key='deselect'
                >
                  <BeamText className={css.MenuText}>Deselect</BeamText>
                </BeamMenu.Item>
              )}
            {destinationInSession?.status !== 'Publishing' && (
              <BeamMenu.Item
                onClick={onRemoveItem}
                icon={<Icon icon='icon_delete' size={16} />}
                key='remove'
              >
                <BeamText className={css.MenuText}>Remove</BeamText>
              </BeamMenu.Item>
            )}
          </BeamMenu>
        </div>
      )

      const _renderDestinationItem = (d: Output) => (
        <DestinationItem
          name={d.name}
          actions={menu}
          selected={isChecked}
          isPublishing={isPublishing}
          avatar={d.avatarUrl ?? ''}
          onClick={e => {
            e.stopPropagation()
            if (!visible && !isPublishing) {
              handleClickDestination(d)
            }
          }}
          type={d.type as OutputType}
          warning={warning}
          isWaiting={isWaiting}
        />
      )
      return (
        <Popover
          visible={visible}
          trigger='click'
          placement='right'
          onVisibleChange={handleVisibleChange}
          overlayClassName={css.SelectDestinationPopoverWrapper}
          content={
            <SelectDestination
              refreshDestination={refreshDestination}
              onClick={d => {
                if (d === OutputType.Custom) {
                  ModalService.show(RTMPDestinationForm, {
                    ...componentProps,
                  })
                }
                if (d === OutputType.Instagram) {
                  ModalService.show(InstagramFormDestination, {
                    ...componentProps,
                  })
                }
                setVisible(false)
              }}
            />
          }
        >
          {destination ? (
            _renderDestinationItem(destination)
          ) : (
            <div className={css.AddRecent}>
              <Icon icon='Plus' className={css.IconPlus} size={13} />
            </div>
          )}
        </Popover>
      )
    },
  ),
)
