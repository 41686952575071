import clsx from 'clsx'

import IconMail from '../../../assets/icons/ic_mail.png'
import css from './ContactUs.module.scss'

import { Button } from '../../../components/base/Button'
import { Form } from '../../../components/base/Form'
import { FormItem } from '../../../components/base/FormItem'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { BeamInput } from '../../../components/widget/Input'
import { BeamTextArea } from '../../../components/widget/TextArea'

export const ContactUs = () => {
  const [form] = Form.useForm()

  // const [stateMutationContactUs, executeMutationContactUs] = useGqlContactUs()

  // const contactUs = () => {
  //   form.validateFields().then(async values => {
  //     await executeMutationContactUs({
  //       data: {
  //         name: values.fullName,
  //         email: values.emailAddress,
  //         message: values.issue,
  //       },
  //     }).then(response => {
  //       if (response.data?.contactUs?.id) {
  //         ToastService.success({
  //           content:
  //             'Beam is working on your message and will respond within 24 hours',
  //         })
  //         ModalService.hide()
  //       }
  //     })
  //   })
  // }

  return (
    <ModalContent containerClassName={css.ContactContainer}>
      <div className={clsx(css.Left, css.Wrapper)}>
        <div className={css.ContentLeft}>
          <div className={css.Title}>Contact Us</div>
          <p className={css.Subtitle}>
            We will get back to you within 24 hours
          </p>
          <p className={css.Mail}>
            <span>
              <img src={IconMail} />
            </span>
            <span className={css.EmailLabel}>Email:</span>
            <span className={css.Email}>contact@beings.com</span>
          </p>
        </div>
      </div>
      <div className={css.Right}>
        <div className={css.ContentRight}>
          <Form form={form} layout='vertical' autoComplete='off'>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              label='What is your name?'
              name='fullName'
            >
              <BeamInput placeholder='Enter your name' />
            </FormItem>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'Please enter your email',
                },
                {
                  type: 'email',
                  message: 'Please enter valid email',
                },
              ]}
              label='What is your email?'
              name='emailAddress'
            >
              <BeamInput autoComplete='off' placeholder='Enter your email' />
            </FormItem>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'Please tell us the issue you met',
                },
              ]}
              label='Please tell us your issue/request'
              name='issue'
            >
              <BeamTextArea
                rows={8}
                className='issue'
                placeholder='Write your issue/request here'
              />
            </FormItem>
          </Form>
        </div>
        <div className={css.Footer}>
          <Button
            containerType='grey'
            className={css.BtnCancel}
            onClick={() => ModalService.hide()}
            // disabled={stateMutationContactUs.fetching}
          >
            Cancel
          </Button>
          <Button
            className={css.BtnSend}
            // onClick={contactUs}
            // disabled={stateMutationContactUs.fetching}
          >
            Send
          </Button>
        </div>
      </div>
    </ModalContent>
  )
}
