import { Switch } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import type { AppTextProps } from './type'

export const BeamSwitch: FC<AppTextProps> = props => {
  const { containerProps, isLightTheme, ...switchProps } = props

  return (
    <div
      className={clsx({
        [css.SwitchContainer]: !isLightTheme,
        [css.SwitchContainerLight]: isLightTheme,
      })}
      {...containerProps}
    >
      <Switch {...switchProps}></Switch>
    </div>
  )
}
