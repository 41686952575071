import { getLayoutRatio } from '#HACK_FOR_RN_ONLY/src/pages/Studio/actions/getLayoutRatio'
import { calculateUpdateSlot } from '#HACK_FOR_RN_ONLY/src/pages/Studio/components/UploadTranscript/calculateSlot'

import type { ILayoutItem } from '../../store/shared/LayoutStore'

export const calculateCrop = (
  layout: ILayoutItem | null,
  state: ILayoutItem,
  maxLeft?: number,
  maxTop?: number,
  type?: string,
) => {
  if (!layout) {
    return
  }
  const { ratioTH, ratioLW } = getLayoutRatio()
  const { width, height, top, left, cropData } = layout
  if (!cropData) {
    return
  }
  const {
    width: widthCrop,
    height: heightCrop,
    top: topCrop,
    left: leftCrop,
  } = cropData

  let newLeft = state.left
  let newTop = state.top

  if (maxLeft && maxTop) {
    newLeft = left
    const right = left + state.width
    newTop = top
    const bottom = top + state.height
    if (right > maxLeft) {
      newLeft = newLeft - (right - maxLeft)
    }
    if (bottom > maxTop) {
      newTop = newTop - (bottom - maxTop)
    }
  }

  const x = width / state.width
  const y = height / state.height
  const O1 = leftCrop - left
  const O2 = left + width - leftCrop
  const newO1 = (O1 * width) / x / (O1 + O2)
  const O3 = topCrop - top
  const O4 = top + height - topCrop
  const newO3 = (O3 * height) / y / (O3 + O4)

  if (type) {
    return {
      cropData: {
        height: heightCrop / y / ratioTH,
        width: widthCrop / x / ratioLW,
        top: (newTop + newO3) / ratioTH,
        left: (newLeft + newO1) / ratioLW,
      },
    }
  }

  return {
    ...calculateUpdateSlot({
      height: state.height,
      width: state.width,
      top: newTop,
      left: newLeft,
    }),
    radius: state.radius,
    cropData: {
      ...calculateUpdateSlot({
        height: heightCrop / y,
        width: widthCrop / x,
        top: newTop + newO3,
        left: newLeft + newO1,
      }),
    },
  }
}
