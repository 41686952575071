import { qsStableStringify } from '##/shared/qs'

import { ToastService } from '../../components/widget/Toast'
import { getSessionPlan } from '../../pages/Studio/actions/getSessionPlan'
import { reduxStore } from '../redux'
import { S } from '../store'

type MixerQueryData = { userId?: string; id: string; isInput?: boolean }
export const onBottomTitleMouseOver = () => {
  S.webrtc.updateDataOfStore({ isHoverTitleBottom: true })
}
export const onBottomTitleMouseOut = () => {
  S.webrtc.updateDataOfStore({ isHoverTitleBottom: false })
}

// should already checked role observer
const buildObserverQueries = (selectedRecords?: object[]) => {
  if (!selectedRecords) {
    // main canvas for observer
    selectedRecords = [{}]
  }
  if (!selectedRecords?.length) {
    // multiple records for observer, but nothing selected
    throw new Error('Must select at least 1 stream for multiple recording')
  }

  return selectedRecords.map(r =>
    qsStableStringify({ ...r, mixer: 'mixerobserver' }),
  )
}
// should already checked role host
const buildHostQueries = (selectedRecords?: object[]) => {
  if (!selectedRecords) {
    // main canvas for observer
    selectedRecords = [{}]
  }
  if (!selectedRecords?.length) {
    // multiple records for observer, but nothing selected
    throw new Error('Must select at least 1 stream for multiple recording')
  }

  return selectedRecords.map(r =>
    qsStableStringify({ ...r, mixer: 'mixerhost' }),
  )
}

export const buildMixerQueryObject = (
  p: MixerQueryData,
  screenshare: boolean,
) => {
  if (p.userId) {
    return {
      userId: p.userId,
      stream: screenshare ? 'screenshareVideo' : 'video',
      isInput: p.isInput,
    }
  }
  return {
    peerId: p.id,
    isInput: p.isInput,
  }
}

export const buildMixerQuery = (p: MixerQueryData, screenshare: boolean) => {
  const d = buildMixerQueryObject(p, screenshare)
  return S.webrtc.isViewmodeHost
    ? buildHostQueries([d])[0]
    : buildObserverQueries([d])[0]
}

export const onStartRecord = async (
  sessionId: string,
  selectedRecords?: object[],
) => {
  try {
    const queries =
      S.webrtc.role?.name === 'Observer'
        ? buildObserverQueries(selectedRecords)
        : S.webrtc.role?.name === 'Host'
          ? buildHostQueries(selectedRecords)
          : undefined
    await reduxStore.context.gql.startRecording({
      sessionId,
      queries,
      roomType: S.webrtc.viewmode,
    })
    ToastService.info({ content: 'Recording initializing...', duration: 2 })
  } catch (err) {
    ToastService.error({ content: 'Failed to start recording' })
    console.error(err)
  }
}
export const onStopRecord = async (
  sessionId: string,
  selectedRecords?: object[],
) => {
  try {
    const queries =
      S.webrtc.role?.name === 'Observer'
        ? buildObserverQueries(selectedRecords)
        : S.webrtc.role?.name === 'Host'
          ? buildHostQueries(selectedRecords)
          : undefined
    await reduxStore.context.gql.stopRecording({
      sessionId,
      queries,
      roomType: S.webrtc.viewmode,
    })
    S.webrtc.videosRecordSelected = []
    ToastService.success({ content: 'Recording stopped' })
  } catch (err) {
    ToastService.error({ content: 'Failed to stop recording' })
    console.error(err)
  } finally {
    const { detail, role } = S.webrtc
    if (!detail || !role) {
      return
    }
    getSessionPlan(detail.id, role.name)
  }
}

export const onStartRunDestination = async (sessionId: string, r: boolean) => {
  try {
    await reduxStore.context.gql.startOutputs({ sessionId })
    if (r) {
      await onStartRecord(sessionId)
    }
    ToastService.success({ content: 'Live stream is started' })
  } catch (err) {
    ToastService.error({ content: 'Failed to publish live stream' })
    console.error(err)
  }
}
