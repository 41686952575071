import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import css from './AmsClient.module.scss'

import type { AmsClientVideo } from '#rn-shared/mediasoup/type'

import { WebrtcStore } from '../../../context/store/studio/WebrtcStore'

export const AmsClient = observer(() => {
  const s = useWebrtcStore()
  return (
    <>
      {s.amsData.videos
        .filter(i => i.onAir)
        .map(v => {
          if (!v?.url || typeof v.position !== 'number') {
            return null
          }
          return <Video key={v.position} {...v} controller={s} />
        })}
    </>
  )
})

type VideoProps = AmsClientVideo & {
  controller: WebrtcStore
}
const Video = observer(
  ({
    position,
    url,
    repeat,
    controller,
    mediaId,
    enableOnRoom,
  }: VideoProps) => {
    // key by position so we need to use url to track if new video rendered
    const [metaUrl, setMetaUrl] = useState('')
    const r = useRef<HTMLVideoElement>(null)
    const streamR = useRef<MediaStream>()
    const urlR = useRef(url)
    const findSyncRoot = () => {
      if (!controller.amsData.sync) {
        return
      }
      return controller.amsData.videos.find(v => !v.position)
    }
    const loop = () => {
      const v = findSyncRoot()
      return v ? v.repeat : repeat
    }
    useEffect(() => {
      controller.amsControllerVideos[position] = r.current
      return () => {
        controller.amsOffStream(mediaId)
        delete controller.amsControllerVideos[position]
      }
    }, [])

    useEffect(() => {
      if (!r.current || !metaUrl || controller.state !== 'connected') {
        return
      }
      // try stop existing stream?
      const existingStream = streamR.current
      if (existingStream) {
        try {
          existingStream.getTracks().forEach(t => t.stop())
        } catch (err) {}
      }
      // try handle play pause on change?
      try {
        const urlChanged = urlR.current !== metaUrl
        urlR.current = metaUrl
        if (!existingStream || urlChanged) {
          // auto play on load
          r.current.play()
        } else if (!controller.amsData.sync || !position) {
          // check if paused from videos instead of state
          // this is from controller action
          const vPaused = controller.amsData.videos.find(
            v => v.position === position,
          )?.paused
          if (vPaused) {
            r.current.pause()
          } else {
            r.current.play()
          }
        }
      } catch (err) {}
      // capture stream and send to server
      streamR.current = r.current.captureStream()
      controller.amsOnStream(streamR.current, mediaId)
      controller.amsOnStateChange()
    }, [metaUrl, controller.state, mediaId])
    const onTimeUpdate = () => {
      if (!r.current) {
        return
      }
      controller.amsOnStateChange()
    }
    const onPause = () => {
      if (!r.current) {
        return
      }
      controller.amsOnStateChange()
    }
    const onPlay = () => {
      if (!r.current) {
        return
      }
      controller.amsOnStateChange()
    }
    return (
      <video
        crossOrigin='anonymous'
        ref={r}
        key={mediaId}
        className={css.video}
        src={url}
        onLoadedMetadata={() => setMetaUrl(url)}
        onTimeUpdate={onTimeUpdate}
        onPause={onPause}
        onPlay={onPlay}
        loop={loop()}
      />
    )
  },
)

const useWebrtcStore = (position?: number) => {
  const r = useRef<WebrtcStore>()
  if (!r.current) {
    const q = {
      consume: 'false',
      viewmode: 'ams-client',
    }
    r.current = new WebrtcStore(q, { amsPosition: position })
    makeAutoObservable(r.current)
    r.current.join()
  }
  useEffect(
    () => () => {
      r.current?.close()
      r.current = undefined
    },
    [],
  )
  return r.current
}

declare global {
  interface HTMLMediaElement {
    captureStream(fps?: number): MediaStream
  }
}
