import type { CheckboxProps } from 'antd'

export enum ECheckboxSize {
  M = 'M',
  S = 'S',
}
export type TCheckboxContainerProps = {
  size?: ECheckboxSize
  className?: string
}
export type AppTextProps = {
  containerProps?: TCheckboxContainerProps
  isLightTheme?: boolean
} & CheckboxProps
