import { reduxStore } from '../../../context/redux'

export const createInsightEngine = async ({
  name,
  resourceId,
}: {
  name?: string
  resourceId?: string
}) => {
  const result = await reduxStore.context.gql.createInsightEngine({
    data: { name, resourceId },
  })
  return result
}
