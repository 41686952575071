import { observer } from 'mobx-react-lite'

import css from './LiveStreamOverlay.module.scss'

import { S } from '../../../context/store'

export const LiveStreamOverlay = observer(() => {
  const { overlayUrl } = S.webrtc
  if (!overlayUrl || overlayUrl === '') {
    return null
  }
  return (
    <div
      className={css.ContainerOverlay}
      style={{ backgroundImage: `url(${overlayUrl})` }}
    />
  )
})
