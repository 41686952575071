export const checkBlurSupport = () => {
  if (!WebGL2RenderingContext) {
    return false
  }

  const canvasEl = document.createElement('canvas')
  const gl = canvasEl.getContext('webgl2', {
    failIfMajorPerformanceCaveat: true,
  })
  canvasEl.remove()
  if (
    typeof gl?.getContextAttributes()?.failIfMajorPerformanceCaveat ===
    undefined
  ) {
    return false
  }
  if (!gl) {
    return false
  }

  return true
}
