import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

export const useContainerDimensions = (myRef?: RefObject<HTMLDivElement>) => {
  const defaultState = {
    offsetWidth: 0,
    offsetHeight: 0,
    clientWidth: 0,
    clientHeight: 0,
  }
  if (!myRef) {
    return defaultState
  }
  const [dimensions, setDimensions] = useState(defaultState)

  useEffect(() => {
    const getDimensions = () => ({
      offsetWidth: myRef.current?.offsetWidth || 0,
      offsetHeight: myRef.current?.offsetHeight || 0,
      clientWidth: myRef.current?.clientWidth || 0,
      clientHeight: myRef.current?.clientHeight || 0,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [
    myRef.current?.offsetWidth,
    myRef.current?.offsetHeight,
    myRef.current?.clientWidth,
    myRef.current?.clientHeight,
  ])

  return dimensions
}
