import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { CheckboxValueType } from 'antd/lib/checkbox/Group'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type React from 'react'
import { useMemo } from 'react'

import css from './ModalRecordVideos.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import { filterSmallPeers } from '#rn-shared/mediasoup/lib/filterSmallPeers'

import { Button } from '../../../components/base/Button'
import { BeamCheckbox } from '../../../components/base/Checkbox'
import { BeamCheckboxGroup } from '../../../components/base/CheckboxGroup'
import { Icon } from '../../../components/base/Icon'
import { BeamText } from '../../../components/base/Text'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { WarmnessGraySpan } from '../../../components/composites/WarmnessGraySpan'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import {
  buildMixerQueryObject,
  onStartRecord,
} from '../../../context/actions/mixerActions'
import { S } from '../../../context/store'
import {
  amsMediaPrefix,
  buildScreenshareMediaId,
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { isInputPeer } from '../utils/isInputPeer'
import { RecordView } from './RecordView'

export const ModalRecordVideos: React.FC = observer(() => {
  const { isLightTheme } = S.local
  const {
    peers,
    detail,
    getPeer,
    videosRecordSelected,
    setVideosRecordSelected,
    inputs,
  } = S.webrtc
  const inputIds = inputs
    .filter(i => i.status === 'Publishing')
    .map(i1 => `input-${i1.id}`)
  const amsMedias = Object.keys(
    peers.find(p => p.data.viewmode === 'ams-client')?.medias ?? {},
  )
  const showingPeerIds: string[] = useMemo(() => {
    let ids: string[] = []
    filterSmallPeers(peers[0].data.viewmode, peers).forEach(p => {
      ids.push(p.id)
      if (p.screenshareVideo) {
        ids.push(buildScreenshareMediaId(p.id))
      }
    })

    ids = [...ids, ...inputIds, ...amsMedias]
    return ids
  }, [peers.length, peers, inputIds.length])
  const checkAll = showingPeerIds.length === videosRecordSelected.length
  const changeHandler = (list: CheckboxValueType[]) => {
    setVideosRecordSelected(list as string[])
  }
  const changeAllHandler = (e: CheckboxChangeEvent) => {
    setVideosRecordSelected(
      e.target.checked
        ? [
            ...showingPeerIds.reduce((acc: string[], id) => {
              acc.push(id)

              return acc
            }, []),
          ]
        : [],
    )
  }
  const handleRecord = () => {
    ModalService.hide()
    const selectedRecords = videosRecordSelected.map(id => {
      const screenshare = isScreenshareMedia(id)
      const isAms = id.includes(amsMediaPrefix)
      const mediaPeerId = getMediaPeerId(id)
      const peer = getPeer(mediaPeerId)
      const input = inputs.find(i => i.id === getNormalId(id))
      if (!peer && !input && !isAms) {
        return null as any
      }
      return buildMixerQueryObject(
        {
          id: (isAms ? id : isInputPeer(id) ? getNormalId(id) : peer?.id) ?? '',
          userId: peer?.data.userId,
          isInput: isInputPeer(id),
        },
        screenshare,
      )
    })
    onStartRecord(detail?.id ?? '', selectedRecords)
  }
  return (
    <ModalContent
      isLightTheme={isLightTheme}
      containerClassName={css.ModalContainer}
    >
      <WarmnessDiv
        className={clsx({
          [css.RecordVideosWrapper]: true,
          [css.RecordVideosWrapperDark]: !isLightTheme,
        })}
        style={{ background: S.local.warmnessColor }}
      >
        <div className={css.ModalHeader}>
          <WarmnessGraySpan className={css.IconRecord}>
            <Icon icon='icon_menu_record' size={16} />
          </WarmnessGraySpan>
          <BeamText size='large1'>Select videos to record</BeamText>
        </div>
        <BeamCheckboxGroup
          value={videosRecordSelected}
          onChange={changeHandler}
          className={css.VideoInputsList}
        >
          {!showingPeerIds.length ? (
            <div className={css.NotData}>No Data.</div>
          ) : (
            <>
              {showingPeerIds.map(id => (
                <div key={id} className={css.MainContentPeersSlot}>
                  <RecordView
                    peerId={id}
                    isChecked={videosRecordSelected.includes(id)}
                  />
                </div>
              ))}
            </>
          )}
        </BeamCheckboxGroup>
        <div className={css.RecordActions}>
          <WarmnessGrayButton
            onClick={() => ModalService.hide()}
            className={css.CancelButton}
          >
            Cancel
          </WarmnessGrayButton>
          {showingPeerIds.length ? (
            <BeamCheckbox checked={checkAll} onChange={changeAllHandler}>
              <BeamText>Select all</BeamText>
            </BeamCheckbox>
          ) : null}
          {showingPeerIds.length ? (
            <Button onClick={handleRecord}>Record</Button>
          ) : null}
        </div>
      </WarmnessDiv>
    </ModalContent>
  )
})
