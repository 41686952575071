import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useMemo, useRef } from 'react'
import { useDragLayer } from 'react-dnd'

import IndicatorIcon from '../../../assets/icons/Recording.svg'
import css from './SlotSmall.module.scss'

import { arrToMap } from '##/shared/arrToMap'

import { buildMixerQuery } from '../../../context/actions/mixerActions'
import { S } from '../../../context/store'
import {
  DRAG_MEDIA_VIDEO,
  DRAG_USER_SMALL,
} from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import {
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { useDragSlotSmall } from '../../../utils/useDrag'
import { useDropSmallSlot } from '../../../utils/useDrop'
import { getTotalSlots } from '../utils/getTotalSlots'
import { isInputPeer } from '../utils/isInputPeer'
import { PeerView } from './MediaView'
import type { TDragPreview } from './RightContent'
import { VideoPlayback } from './VideoPlayback'

export const SlotSmall: FC<SmartSlotProps> = observer(
  ({ peerId, position, isMainCanvas, mediaId }) => {
    const { computedSelectedLayout, selectedIndexLayout, layoutPeers } =
      S.webrtc
    const { isViewmodeMixer, outputInSession, getPeer, isViewmodeParticipant } =
      S.webrtc
    const { inputs } = S.webrtc
    const input = isInputPeer(peerId)
      ? inputs.find(i => i.id === getNormalId(peerId ?? ''))
      : undefined
    const totalSlots = getTotalSlots(computedSelectedLayout.defaultId)

    const screenshare = peerId ? isScreenshareMedia(peerId) : false
    const invisible = position !== undefined && position >= totalSlots

    const videoPlaybackMediaId =
      position !== undefined && S.webrtc.computedVideoPlaybackMediaIds[position]
    const mediaId2 = videoPlaybackMediaId || peerId

    const dref = useRef<HTMLDivElement>(null)
    const { drag } = useDragSlotSmall({
      id: mediaId,
      peerId: mediaId2,
      position,
      type: mediaId ? DRAG_MEDIA_VIDEO : DRAG_USER_SMALL,
    })
    const [, drop] = useDropSmallSlot({ peerId: mediaId2, position })

    const layout = layoutPeers[selectedIndexLayout]
    let isOnAir = false
    if (layout && Object.values(layout).length > 0 && mediaId2) {
      isOnAir = Object.values(layout).includes(mediaId2)
    }
    isViewmodeParticipant ? undefined : drag(drop(dref))
    const map = arrToMap(outputInSession, o => o.id)
    const isRecording = useMemo(() => {
      if (!peerId) {
        return false
      }
      const mediaPeerId = getMediaPeerId(peerId)
      const peer = getPeer(mediaPeerId)
      if (peer) {
        const thisMixerQuery = buildMixerQuery(
          {
            id: getNormalId(peerId),
            userId: isInputPeer(peerId) ? undefined : peer.data.userId,
            isInput: isInputPeer(peerId),
          },
          screenshare,
        )
        const rs = outputInSession
          .filter(
            o =>
              o.output?.type === 'Recording' &&
              o.status === 'Publishing' &&
              o.mixerQuery,
          )
          .find(o2 => o2.mixerQuery === thisMixerQuery)
        return Boolean(rs)
      }
      return false
    }, [map, peerId])
    return (
      <>
        <div
          ref={isViewmodeMixer ? undefined : dref}
          className={clsx({
            [css.StyledSlot]: true,
            [css.styleSlotInvisible]: invisible,
            [css.styledSlotWithOtherTransparent]: !screenshare,
            [css.styledSlotWithLayoutOther1]: !screenshare,
            [css.styledSlotLayoutStyleRounded]:
              S.webrtc.layoutStyle === 'rounded' && !screenshare,
            [css.styledSlotLayoutStyleModern]:
              S.webrtc.layoutStyle === 'modern' && !screenshare,
            [css.styledSlotOnAir]: isOnAir,
            [css.styledSlotSmall]: true,
            [css.styledSlotEmptyOtherViewAndDragging]: true,
          })}
        >
          {input && input.status !== 'Publishing' && (
            <span
              style={{
                // @ts-ignore
                '--label-status-background':
                  input.status === 'Loading'
                    ? 'rgba(239, 239, 240, 1)'
                    : input.status === 'Ready'
                      ? 'rgba(181, 255, 155, 1)'
                      : 'rgba(255, 171, 181, 1)',
                '--label-status':
                  input.status === 'Loading'
                    ? 'rgba(48, 48, 48, 1)'
                    : input.status === 'Ready'
                      ? 'rgba(0, 89, 22, 1)'
                      : 'rgba(175, 12, 12, 1)',
              }}
              className={css.Label}
            >
              {input?.status === 'Loading' ? 'Loading...' : input.status}
            </span>
          )}
          {isRecording && (
            <div className={css.Indicator}>
              <img src={IndicatorIcon} />
            </div>
          )}

          {peerId && (
            <PeerView
              peerId={peerId}
              mediaId={mediaId}
              position={position}
              isSmallPeer
              isMainCanvas={isMainCanvas}
              forceMuted={false}
            />
          )}
          {videoPlaybackMediaId && S.webrtc.computedShouldPlayVideoPlayback && (
            <VideoPlayback peerId={videoPlaybackMediaId} />
          )}
        </div>
        {S.webrtc.typeDraggingItem === DRAG_USER_SMALL && <StyleSlotPreview />}
      </>
    )
  },
)

const StyleSlotPreview = () => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging) {
    return null
  }
  return (
    <div
      className={css.SlotSmallPreview}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div
        className={clsx({
          [css.StyledSlot]: true,
          [css.styledSlotWithOtherTransparent]: true,
          [css.styledSlotWithLayoutOther1]: true,
          [css.styledSlotLayoutStyleRounded]:
            S.webrtc.layoutStyle === 'rounded',
          [css.styledSlotLayoutStyleModern]: S.webrtc.layoutStyle === 'modern',
        })}
      >
        {item.peerId && (
          <PeerView
            mediaId={item.id}
            peerId={item.peerId}
            position={undefined}
            forceMuted={false}
          />
        )}
      </div>
    </div>
  )
}
export type SmartSlotProps = {
  peerId?: string
  position?: number
  isMainCanvas?: boolean
  mediaId?: string
}
