import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'
import { useAudio } from 'react-use'

import css from './MediaAudios.module.scss'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_MEDIA_AUDIO } from '../../../context/store/studio/dragTypes'
import {
  covertDurationToMinutes,
  formatFileName,
  getItemPreviewStyles,
  getNameOfUrl,
} from '../../../context/store/studio/utils'
import type {
  TMediaItem,
  TMediaItemDetail,
} from '../../../context/store/studio/WebrtcStore'
import { controlMediaPrefix } from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { AudioPlayer } from './AudioPlayer'
import { AudioPlayerOnline } from './AudioPlayerOnline'
import { PopoverDelete } from './MediaPopover'
import type { TDragPreview } from './RightContent'

type TAudio = TMediaItemDetail & {
  idAudioPlaying: string
  audioDefaultVolume?: number
  onChangeDefaultVolume?: (volume: number) => void
}

export const MediaAudios = observer(
  ({ onUpdateSetting }: { onUpdateSetting: (type?: string) => void }) => {
    if (S.webrtc.mediaStudio.audio.length === 0) {
      return <p className={css.MediaListNotFound}>There is no audio yet</p>
    }

    return (
      <>
        <p className={css.MediaAudioNote}>
          Audio preview is played in off-air mode
        </p>
        <Audios onUpdateSetting={onUpdateSetting} />
      </>
    )
  },
)

const Audios = observer(
  ({ onUpdateSetting }: { onUpdateSetting: (type?: string) => void }) => {
    const [audioPlaying, setPlaying] = useState('')
    const {
      mediaSelectionId,
      updateOrderMedia,
      deleteFileMedia,
      updateAndEmit,
      audioUrl,
      updateDataOfStore,
      toggleMediaSelectionId,
      mediaStudio,
      typeDraggingItem,
      mediaControlData,
      removeMediaControlData,
      viewmode,
      backgroundMediaData,
    } = S.webrtc
    const onSortEnd = async (dropIndex: number, overIndex: number) => {
      if (mediaSelectionId.length <= 1) {
        updateOrderMedia('audio', dropIndex, overIndex)
        await callApiUpdateResourceReOrder(
          mediaStudio.audio,
          dropIndex,
          overIndex,
        )
      }
    }

    const onDragEnd = () => {
      if (mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }
    const onDelete = async (item: TMediaItem) => {
      const videoBackGroundId = mediaControlData.find(
        m =>
          m.mediaId.replace(controlMediaPrefix[viewmode], '') === item.id &&
          m.type === 'audio',
      )?.id
      if (backgroundMediaData.url === item.value) {
        S.webrtc.backgroundMediaData = {
          id: '',
          url: '',
        }
        S.webrtc.updateEmitAndSaveSettings({
          backgroundMediaData: { id: '', url: '' },
        })
      }
      if (videoBackGroundId) {
        removeMediaControlData(videoBackGroundId)
      }
      try {
        deleteFileMedia(item.id, 'audio')
        if (item.value === audioUrl) {
          updateAndEmit({
            audioUrl: '',
          })
          onUpdateSetting?.()
        }
        await reduxStore.context.gql.deleteResourceInSession({ ids: [item.id] })
      } catch (err) {
        ToastService.error({ content: 'Failed to delete audio' })
        console.error(err)
      }
    }
    const onTurnOffAir = (item: TMediaItem) => {
      S.webrtc.backgroundMediaData = {
        id: '',
        url: '',
      }
      S.webrtc.updateEmitAndSaveSettings({
        backgroundMediaData: { id: '', url: '' },
      })
      const videoBackGroundId = mediaControlData.find(
        m =>
          m.mediaId.replace(controlMediaPrefix[viewmode], '') === item.id &&
          m.type === 'audio',
      )?.id
      if (videoBackGroundId) {
        removeMediaControlData(videoBackGroundId)
      }
      updateAndEmit({
        audioUrl: '',
      })
      onUpdateSetting?.()
    }
    const onOpenAudio = (e: MouseEvent, item: TMediaItem) => {
      if (e.shiftKey) {
        toggleMediaSelectionId(item.id)
        return
      }
      setPlaying(item.id === audioPlaying ? '' : item.id)
      updateDataOfStore({
        mediaSelectionId: [],
      })
    }
    useEffect(() => {
      const detailId = mediaStudio.audio.find(item => item.value === audioUrl)
      if (detailId) {
        setPlaying(detailId.value)
      } else {
        if (audioPlaying !== '') {
          setPlaying('')
          updateDataOfStore({
            isAuthorAudioUrl: false,
          })
        }
      }
    }, [audioUrl])

    return (
      <>
        {mediaStudio.audio.map((item, index) => (
          <Audio
            media={item}
            key={index}
            index={index.toString()}
            onAir={backgroundMediaData.url === item.value}
            idAudioPlaying={audioPlaying}
            onSelectMedia={(e: MouseEvent) => onOpenAudio(e, item)}
            onDelete={onDelete}
            onSortEnd={onSortEnd}
            onDragEnd={onDragEnd}
            onTurnOffAir={() => onTurnOffAir(item)}
            dragSelected={mediaSelectionId.includes(item.id)}
            isDraggingSelect={mediaSelectionId.length > 1}
            draggingSelected={
              mediaSelectionId.includes(item.id) &&
              typeDraggingItem === DRAG_MEDIA_AUDIO
            }
          />
        ))}
        {typeDraggingItem === DRAG_MEDIA_AUDIO && (
          <AudioPreview
            selectedAudio={audioPlaying}
            totalItem={mediaSelectionId.length}
          />
        )}
      </>
    )
  },
)

const Audio = ({
  media: { id, value, mediaType, name = '', isDefault, duration },
  index,
  onDelete,
  onSelectMedia,
  idAudioPlaying,
  audioDefaultVolume,
  onChangeDefaultVolume,
  onSortEnd,
  dragSelected,
  draggingSelected,
  isDraggingSelect,
  onAir = false,
  onTurnOffAir,
  onDragEnd,
}: TAudio) => {
  const dref = useRef<HTMLDivElement>(null)
  const [audio, state] = useAudio(<audio src={value} />)
  const [visiblePopover, setPopover] = useState(false)
  const { isDragging, drag } = useDragDefault({
    item: { id, index, value, mediaType, name },
    dragType: DRAG_MEDIA_AUDIO,
    onDragEnd,
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: DRAG_MEDIA_AUDIO,
    index,
    dref,
    onSortEnd,
  })
  const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setPopover(!visiblePopover)
  }
  const onChangeVolume = (volume: number) => {
    onChangeDefaultVolume && onChangeDefaultVolume(volume)
  }
  const isOpenAudio = idAudioPlaying === id
  drag(drop(dref))
  duration = duration || state.duration
  const isAudioOnline = onAir
  return (
    <div
      ref={dref}
      data-selection={id}
      onClick={onSelectMedia}
      className={clsx({
        ['media']: true,
        [css.MediaAudioItemContainer]: true,
        [css.mediaItemVerticalContainerDrophover]: isOver && !isDraggingSelect,
        [css.mediaItemVerticalContainerDragSelected]:
          dragSelected && !draggingSelected,
        [css.mediaAudioItemContainerPlaying]: isOpenAudio,
        [css.mediaAudioItemContainerDropbottom]: isBottom,
      })}
    >
      <PopoverDelete
        visible={visiblePopover}
        onVisibleChange={(status: boolean) => {
          setTimeout(() => {
            setPopover(status)
          }, 100)
        }}
        onCancel={() => setPopover(false)}
        onDelete={() => {
          setPopover(false)
          onDelete({
            id,
            value,
            mediaType,
            isDefault,
          })
        }}
      />
      <WarmnessGrayButton
        isButton={false}
        className={clsx({
          [css.MediaAudioItemWrapper]: true,
          [css.mediaItemWrapperOnAir]: onAir,
          [css.mediaItemWrapperDragging]: isDragging,
        })}
      >
        {isOpenAudio ? (
          isAudioOnline ? (
            <AudioPlayerOnline
              defaultVolume={audioDefaultVolume}
              url={value}
              name={name}
              onChangeVolume={onChangeVolume}
              onDelete={handleDelete}
              onAir={onAir}
              onTurnOffAir={onTurnOffAir}
              idAudioPlaying={idAudioPlaying}
            />
          ) : (
            <AudioPlayer
              defaultVolume={audioDefaultVolume}
              url={value}
              name={name}
              onChangeVolume={onChangeVolume}
              onDelete={handleDelete}
              onAir={onAir}
              onTurnOffAir={onTurnOffAir}
            />
          )
        ) : (
          <>
            {audio}
            <div className={css.MediaAudioTop}>
              <span className={css.MediaAudioName}>
                <Icon
                  icon='icon_media_music'
                  size={13}
                  className={css.MediaAudioTopIcon}
                />
                {name
                  ? formatFileName(name)
                  : formatFileName(getNameOfUrl(value))}
              </span>
              {onAir ? (
                <div className={css.MediaButtonOnAir} onClick={onTurnOffAir}>
                  <Icon icon='icon_triangle' size={13} />
                  On Air
                </div>
              ) : (
                <span className={css.MediaAudioDuration}>
                  {covertDurationToMinutes(duration)}
                </span>
              )}
              {!onAir && (
                <div
                  className={css.MediaAudioTextDelete}
                  onClick={handleDelete}
                >
                  Delete
                </div>
              )}
            </div>
          </>
        )}
        {(isDragging || draggingSelected) && (
          <WarmnessGrayButton
            isButton={false}
            className={clsx({
              [css.MediaAudioDragging]: true,
              [css.mediaItemDraggingOnAir]: onAir,
            })}
          />
        )}
      </WarmnessGrayButton>
    </div>
  )
}

const AudioPreview = observer(
  ({
    selectedAudio,
    totalItem = 0,
  }: {
    selectedAudio: string
    totalItem?: number
  }) => {
    const { isDragging, item, initialOffset, currentOffset } =
      useDragLayer<TDragPreview>(monitor => ({
        item: monitor.getItem(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
      }))
    if (!isDragging) {
      return null
    }
    const isOpenAudio = selectedAudio === item.value
    const onAir = S.webrtc.audioUrl === item.value
    return (
      <div
        className={css.MediaAudioPreview}
        style={getItemPreviewStyles(initialOffset, currentOffset)}
      >
        <div className={css.MediaAudioItemContainer}>
          <WarmnessGrayButton
            isButton={false}
            className={clsx({
              [css.MediaAudioItemWrapper]: true,
              [css.MediaAudioItemWrapperPreview]: true,
              [css.mediaItemWrapperOnAir]: onAir,
            })}
          >
            {isOpenAudio ? (
              <AudioPlayer url={item.value} autoPlay={false} onAir={onAir} />
            ) : (
              <div className={css.MediaAudioTop}>
                <span className={css.MediaAudioName}>
                  <span className={css.MediaAudioTopIcon} />
                  {item?.name !== ''
                    ? formatFileName(item.name)
                    : formatFileName(getNameOfUrl(item.value))}
                </span>
                {onAir && (
                  <div className={css.MediaButtonOnAir}>
                    <Icon icon='icon_triangle' size={13} />
                    On Air
                  </div>
                )}
              </div>
            )}
          </WarmnessGrayButton>
        </div>
        {totalItem > 0 && (
          <div className={css.MediaPreviewTotalItem}>{totalItem}</div>
        )}
      </div>
    )
  },
)
