import type {
  CreateSliceOptions,
  PayloadAction,
  Slice,
  SliceCaseReducers,
  SliceSelectors,
} from '@reduxjs/toolkit'
import { createSlice as originalCreateSlice } from '@reduxjs/toolkit'
import { upperFirst } from 'lodash'

import { arrToMap } from '##/shared/arrToMap'
import type { UpperFirst } from '##/shared/ts'

import type { Id, IdMap } from './IdMap'
import { resetReduxStore } from './resetReduxStore'

export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string,
  Selectors extends SliceSelectors<State>,
  ReducerPath extends string = Name,
  A extends readonly string[] = [],
  I = A extends readonly [infer K1 extends string]
    ? Im<State, K1>
    : A extends readonly [infer K1 extends string, infer K2 extends string]
      ? Im<State, K1> & Im<State, K2>
      : A extends readonly [
            infer K1 extends string,
            infer K2 extends string,
            infer K3 extends string,
          ]
        ? Im<State, K1> & Im<State, K2> & Im<State, K3>
        : A extends readonly [
              infer K1 extends string,
              infer K2 extends string,
              infer K3 extends string,
              infer K4 extends string,
            ]
          ? Im<State, K1> & Im<State, K2> & Im<State, K3> & Im<State, K4>
          : A extends readonly [
                infer K1 extends string,
                infer K2 extends string,
                infer K3 extends string,
                infer K4 extends string,
                infer K5 extends string,
              ]
            ? Im<State, K1> &
                Im<State, K2> &
                Im<State, K3> &
                Im<State, K4> &
                Im<State, K5>
            : A extends readonly [
                  infer K1 extends string,
                  infer K2 extends string,
                  infer K3 extends string,
                  infer K4 extends string,
                  infer K5 extends string,
                  infer K6 extends string,
                ]
              ? Im<State, K1> &
                  Im<State, K2> &
                  Im<State, K3> &
                  Im<State, K4> &
                  Im<State, K5> &
                  Im<State, K6>
              : A extends readonly [
                    infer K1 extends string,
                    infer K2 extends string,
                    infer K3 extends string,
                    infer K4 extends string,
                    infer K5 extends string,
                    infer K6 extends string,
                    infer K7 extends string,
                  ]
                ? Im<State, K1> &
                    Im<State, K2> &
                    Im<State, K3> &
                    Im<State, K4> &
                    Im<State, K5> &
                    Im<State, K6> &
                    Im<State, K7>
                : A extends readonly [
                      infer K1 extends string,
                      infer K2 extends string,
                      infer K3 extends string,
                      infer K4 extends string,
                      infer K5 extends string,
                      infer K6 extends string,
                      infer K7 extends string,
                      infer K8 extends string,
                    ]
                  ? Im<State, K1> &
                      Im<State, K2> &
                      Im<State, K3> &
                      Im<State, K4> &
                      Im<State, K5> &
                      Im<State, K6> &
                      Im<State, K7> &
                      Im<State, K8>
                  : A extends readonly [
                        infer K1 extends string,
                        infer K2 extends string,
                        infer K3 extends string,
                        infer K4 extends string,
                        infer K5 extends string,
                        infer K6 extends string,
                        infer K7 extends string,
                        infer K8 extends string,
                        infer K9 extends string,
                      ]
                    ? Im<State, K1> &
                        Im<State, K2> &
                        Im<State, K3> &
                        Im<State, K4> &
                        Im<State, K5> &
                        Im<State, K6> &
                        Im<State, K7> &
                        Im<State, K8> &
                        Im<State, K9>
                    : {},
>(
  options: Omit<
    CreateSliceOptions<State, CaseReducers, Name, ReducerPath, Selectors>,
    'reducers'
  > &
    Pick<
      Partial<
        CreateSliceOptions<State, CaseReducers, Name, ReducerPath, Selectors>
      >,
      'reducers'
    > & {
      im?: A
    },
): Slice<
  State,
  CaseReducers &
    ImP<I, 'reducers'> & {
      [k in `set${UpperFirst<Name>}State`]: (
        s: State,
        a: PayloadAction<(s: State) => void>,
      ) => void
    },
  Name,
  ReducerPath,
  Selectors & ImP<I, 'selectors'>
> => {
  const r: any = options.reducers || {}
  const s: any = options.selectors || {}
  options.reducers = r
  options.selectors = s
  // add crud for IdMap
  options.im?.forEach(k => im(k, r, s))
  // add set state
  r[`set${upperFirst(options.name)}State`] = (
    state: State,
    action: PayloadAction<(state: State) => void>,
  ) => {
    action.payload(state)
  }
  // add reset to initial state
  const e = options.extraReducers
  options.extraReducers = b => {
    e?.(b)
    b.addCase(resetReduxStore, (_, a) => {
      if (options.name === 'persisted' && !a.payload?.includePersisted) {
        return
      }
      if (a.payload?.exclude?.includes(options.name)) {
        return
      }
      return options.initialState as any
    })
  }
  // return
  return originalCreateSlice(options as any)
}

type Im<
  S = any,
  T extends string = string,
  V extends Id = S extends { [k in `${T}Im`]: unknown }
    ? S[`${T}Im`] extends IdMap<infer _ extends Id>
      ? _
      : never
    : never,
> = {
  reducers: V extends never
    ? never
    : {} & {
        [k in `set${UpperFirst<T>}Im`]: (
          // setTIm
          s: S,
          a: PayloadAction<V[]>,
        ) => void
      } & {
        [k in `clear${UpperFirst<T>}Im`]: (
          // clearTIm
          s: S,
        ) => void
      } & {
        [k in `upsert${UpperFirst<T>}`]: (
          // bulkUpsertT
          s: S,
          a: PayloadAction<V[]>,
        ) => void
      }
  selectors: V extends never
    ? never
    : {} & {
        [k in T]: (
          // T
          s: S,
          id: string,
        ) => V | undefined
      }
}
type ImP<I, K extends 'reducers' | 'selectors'> = I extends {
  [k in K]: unknown
}
  ? I[K]
  : {}

const im = <
  T extends string,
  V extends Id,
  S extends { [k in `${T}Im`]: IdMap<V> },
>(
  name: T,
  reducers: { [k in keyof Im['reducers']]: Function },
  selectors: { [k in keyof Im['selectors']]: Function },
) => {
  const uname = upperFirst(name) as UpperFirst<T>
  const mname = `${name}Im` as const
  reducers[`set${uname}Im`] = (
    // setTIm
    s: S,
    a: PayloadAction<V[]>,
  ) => {
    s[mname] = arrToMap(a.payload, 'id', v => v) as any
  }
  reducers[`clear${uname}Im`] = (
    // clearTIm
    s: S,
  ) => {
    s[mname] = {} as any
  }
  reducers[`upsert${uname}`] = (
    // bulkUpsertT
    s: S,
    a: PayloadAction<V[]>,
  ) => {
    const map = s[mname] as any as IdMap<V>
    a.payload.forEach(v => {
      const v0 = map[v.id]
      if (v0) {
        Object.assign(v0, v)
        return
      }
      map[v.id] = v
    })
  }
  selectors[name] = (
    // T
    s: S,
    id: string,
  ) => {
    const map = s[mname]
    return map[id]
  }
}
