export const layoutNew1 = {
  defaultId: 1,
  slots: [
    {
      id: 1,
      left: 287.11,
      top: 169.46,
      width: 1345.79,
      height: 741.06,
      radius: 3.903708523096942,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew2 = {
  defaultId: 2,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 904.17,
      height: 741.06,
      radius: 3.903708523096942,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 967.98,
      top: 169.46,
      width: 904.17,
      height: 741.06,
      radius: 3.903708523096942,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}

export const layoutNew3 = {
  defaultId: 3,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 597.47,
      height: 741.06,
      radius: 4.841904553472299,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 661.27,
      top: 169.46,
      width: 597.47,
      height: 741.06,
      radius: 4.841904553472299,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 1274.68,
      top: 169.46,
      width: 597.47,
      height: 741.06,
      radius: 4.841904553472299,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew4 = {
  defaultId: 4,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 507.91,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 967.98,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 4,
      left: 1428.04,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 4,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew5 = {
  defaultId: 5,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 896.2,
      height: 741.06,
      radius: 3.903708523096942,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 960,
      top: 169.46,
      width: 448.1,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 1424.05,
      top: 169.46,
      width: 448.1,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 4,
      left: 960,
      top: 547.97,
      width: 448.1,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 4,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 5,
      left: 1424.05,
      top: 547.97,
      width: 448.1,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 5,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew6 = {
  defaultId: 6,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 507.91,
      top: 169.46,
      width: 444.11,
      height: 741.06,
      radius: 6.513838269429503,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 967.98,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 4,
      left: 1428.04,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 4,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 5,
      left: 967.98,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 5,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 6,
      left: 1428.04,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 6,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew7 = {
  defaultId: 7,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 507.91,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 967.98,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 4,
      left: 1428.04,
      top: 169.46,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 4,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 5,
      left: 47.85,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 5,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 6,
      left: 507.91,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 6,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 7,
      left: 967.98,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 7,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 8,
      left: 1428.04,
      top: 547.97,
      width: 444.11,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 8,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew8 = {
  defaultId: 8,
  slots: [
    {
      id: 1,
      left: 47.85,
      top: 169.46,
      width: 444.13,
      height: 741.06,
      radius: 6.513597134017261,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 507.93,
      top: 169.46,
      width: 1364.22,
      height: 741.06,
      radius: 3.903708523096942,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}
export const layoutNew9 = {
  defaultId: 9,
  slots: [
    {
      id: 1,
      left: 305.44,
      top: 169.46,
      width: 653.89,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 1,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 2,
      left: 975.28,
      top: 169.46,
      width: 653.89,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 2,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 3,
      left: 305.44,
      top: 547.97,
      width: 653.89,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 3,
      screenshare: false,
      cropMode: 'Fill',
    },
    {
      id: 4,
      left: 975.28,
      top: 547.97,
      width: 653.89,
      height: 362.55,
      radius: 7.979157377043513,
      zIndex: 4,
      screenshare: false,
      cropMode: 'Fill',
    },
  ],
}

export const layoutNewAll = [
  layoutNew1,
  layoutNew2,
  layoutNew3,
  layoutNew4,
  layoutNew5,
  layoutNew6,
  layoutNew7,
  layoutNew8,
  layoutNew9,
]
