import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

import buble from '../../../assets/images/AIda-bubble.png'
import css from './ChatItem.module.scss'

import { Popover } from '#HACK_FOR_RN_ONLY/src/components/base/Popover'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import type { MessageLocal } from '../../../context/store/insight-engine/InsightEngineStore'

const copyPopup = <div className={css.CopyPopup}>Copied</div>

const CursorSVG = (
  <svg
    viewBox='8 4 8 16'
    xmlns='http://www.w3.org/2000/svg'
    className={css.CursorTyping}
  >
    <rect x='10' y='6' width='4' height='12' fill='#fff' />
  </svg>
)

const formatTime = (time: Date) => moment(time).format('hh:mm A')

export const ChatItem = observer(({ item }: { item: MessageLocal }) => {
  const [visible, setVisible] = useState(false)

  const [isTyping, setIsTyping] = useState(false)
  const [displayRes, setDisplayRes] = useState('')
  const refScroll = useRef<HTMLDivElement | null>(null)

  const messages = S.insightEngine.searchChatbotMsg.chatbotMessages

  const lastUser = messages?.filter(msg => !msg.isChatBot)?.at(-1)
  const lastBot = messages?.at(-1)

  useEffect(() => {
    if (
      !item.isChatBot ||
      !(lastBot?.id === item.id) ||
      !(lastUser?.status === 'answered') ||
      !lastBot.isSocket
    ) {
      return
    }
    setIsTyping(false)
    let i = 0
    const stringResponse = item.content
    const intervalId = setInterval(() => {
      setDisplayRes(stringResponse.slice(0, i))
      i++
      if (i > stringResponse.length) {
        clearInterval(intervalId)
        setIsTyping(true)
      }
      refScroll.current?.scrollIntoView({
        block: 'end',
        inline: 'end',
      })
    }, 20)
    return () => clearInterval(intervalId)
  }, [item.isChatBot, lastUser])

  const copyHandler = (text: string, id: string) => {
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
    }, 1000)
    navigator.clipboard.writeText(text)
  }

  return (
    <div key={item.id} className={css.SingleUserContent} ref={refScroll}>
      <img
        className={css.Avatar}
        src={item.isChatBot ? buble : item?.owner?.avatarUrl}
        alt=''
      />
      <div className={css.Content}>
        <div className={css.Title}>
          {item.isChatBot ? 'Aida' : item?.owner?.name}
          <div className={css.TimeStamp}>{formatTime(item.createdAt)}</div>
        </div>
        {item.isSocket ? (
          <div className={css.Text}>
            {displayRes}
            {!isTyping && CursorSVG}
          </div>
        ) : (
          <div className={css.Text}>{item.content}</div>
        )}
      </div>
      {item.isChatBot && (
        <Popover
          overlayClassName={css.PopoverWrapper}
          showArrow={false}
          content={copyPopup}
          trigger={'click'}
          placement='topRight'
          align={{ offset: [0, 10] }}
          visible={visible}
        >
          <span
            onClick={() => copyHandler(item.content, item.id)}
            className={css.IconCopy}
          >
            <Icon icon='icon_copy' size={12} />
          </span>
        </Popover>
      )}
    </div>
  )
})

export const ChatItemLoading = () => (
  <div className={css.SingleUserContent}>
    <img className={css.Avatar} src={buble} alt='' />
    <div className={css.Content}>
      <div className={css.Title}>{'Aida'}</div>
      <div className={css.Text}>{CursorSVG}</div>
    </div>
  </div>
)
