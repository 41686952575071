import { S } from '../../../context/store'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { changeTitleItem } from './changeTitleItem'

export const handleChangeFont = (title: TitleItem, font: string) => {
  const $ = S.webrtc
  if (!title.style?.font) {
    return
  }
  if (!$.recentFonts.includes(font)) {
    $.recentFonts.push(font)
    if ($.recentFonts.length > 3) {
      $.recentFonts.shift()
    }
  }

  changeTitleItem(title.id, { style: { ...title.style, font } })
}
