interface ILayoutSize {
  width: number
  height: number
}

export const calculateRatio = (
  width: number,
  height: number,
  originVideoSize: ILayoutSize,
) => {
  const { width: widthOrigin, height: heightOrigin } = originVideoSize

  const ratioW = !isFinite(width / widthOrigin) ? 0 : width / widthOrigin
  const ratioH = !isFinite(height / heightOrigin) ? 0 : height / heightOrigin

  return {
    ratioW,
    ratioH,
  }
}
