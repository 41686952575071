import type { VideoNaturalSize } from '../../../context/store/insight-engine/InsightEngineStore'

interface IArg {
  width: number
  height: number
}

export const calculateRealSizeVideo = (
  videoSize: VideoNaturalSize,
  containerSize: IArg,
) => {
  const { width: x1, height: y1 } = videoSize

  const { width: x2, height: y2 } = containerSize
  let x3 = 0
  let y3 = 0
  let l3 = 0
  let t3 = 0
  const videRatio = x1 / y1
  const elRatio = x2 / y2
  if (elRatio > videRatio) {
    x3 = y2 * videRatio
    y3 = y2
  } else {
    y3 = x2 / videRatio
    x3 = x2
  }

  if (x3 < x2) {
    l3 = (x2 - x3) / 2
  }
  if (y3 < y2) {
    t3 = (y2 - y3) / 2
  }
  return { widthR: x3, heightR: y3, topR: t3, leftR: l3 }
}
