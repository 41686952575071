import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import css from './ScheduleSession.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import { BeamCheckbox } from '#HACK_FOR_RN_ONLY/src/components/base/Checkbox'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { BeamText } from '#HACK_FOR_RN_ONLY/src/components/base/Text'
import { WarmnessDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessDiv'
import { WarmnessGraySpan } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessGraySpan'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { HIDDEN_FOR_NEXT_FEATURES } from '../../../config'
import {
  useGqlCountSession,
  useGqlUpdateSession,
} from '../../../context/gql/codegen'
import { routerPaths } from '../../../context/router/routerPaths'
import { SessionTime } from '../../../context/shared/components/SessionTime'
import { S } from '../../../context/store'
import { setSessionIsRunning } from '../../Studio/actions/setSessionIsRunning'
import type { IScheduleTimeState } from './Schedule'

export const ScheduleSession: FC = observer(() => {
  const [stateMutationUpdate, executeMutationUpdate] = useGqlUpdateSession()
  const { isLightTheme } = S.local
  const sessionSelected = S.webrtc.detail

  if (!sessionSelected) {
    return null
  }

  const currentDate = new Date(sessionSelected?.startTime)
  const defaultStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    0,
    0,
  )
  const defaultEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes() + 30,
    0,
    0,
  )
  const [timeState, setTimeState] = useState<IScheduleTimeState>({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    startTimeError: '',
  })

  const { endDate, startDate } = timeState

  const [countSession, refetchCount] = useGqlCountSession({
    variables: {
      filter: {
        AND: [
          {
            OR: [
              {
                startTime_gte: startDate,
                startTime_lt: endDate,
              },
              {
                endTime_gt: startDate,
                endTime_lte: endDate,
              },
              {
                startTime_lte: startDate,
                endTime_gte: endDate,
              },
            ],
          },
          {
            id_ne: sessionSelected.id,
          },
        ],
      },
    },
    pause: true,
  })
  useEffect(() => {
    refetchCount()
  }, [sessionSelected])
  const onUpdate = () => {
    executeMutationUpdate({
      id: sessionSelected.id,
      data: {
        endTime: endDate,
        startTime: startDate,
      },
    })
      .then(() => {
        setSessionIsRunning({
          ...sessionSelected,
          startTime: startDate as Date,
          endTime: endDate as Date,
        })
        const startDateMoment = moment(startDate)
        ToastService.success({
          content: `Schedule Updated. Your session will start at ${startDateMoment.format(
            'hh:mm A',
          )} , ${
            startDateMoment.isSame('day')
              ? 'today'
              : startDateMoment.format('MM/DD/YYYY')
          }`,
        })
        ModalService.hide()
      })
      .catch((err: Error) => {
        ToastService.error({ content: 'Failed to update schedule' })
      })
  }

  return (
    <ModalContent
      isLightTheme={isLightTheme}
      containerClassName={css.ModalContainer}
    >
      <WarmnessDiv
        className={clsx({
          [css.ScheduleSessionWrapper]: true,
          [css.ScheduleSessionWrapperDark]: !isLightTheme,
        })}
        style={{ background: S.local.warmnessColor }}
      >
        <div className={css.ScheduleSessionContainer}>
          <div className={css.Title}>
            <WarmnessGraySpan className={css.TimeIcon}>
              <Icon icon='Clock' size={20} />
            </WarmnessGraySpan>
            <BeamText className={css.scheduleSessionBeamText}>
              Edit Schedule
            </BeamText>
          </div>
          <SessionTime
            startDate={startDate}
            endDate={endDate}
            setTime={data => setTimeState(pre => ({ ...pre, ...data }))}
            startTimeError={timeState.startTimeError}
          />
          {!countSession.fetching &&
          !countSession.error &&
          countSession.data?.countSession ? (
            <div className={css.Warning}>
              {isLightTheme ? (
                <Icon icon='Light' size={16} disableFill={false} />
              ) : (
                <Icon icon='Dark' size={16} disableFill={false} />
              )}
              <BeamText
                containerProps={{ style: { marginLeft: 8 } }}
                type='warning'
              >
                You have 2 scheduled sessions at this time.{'  '}
              </BeamText>
              <Link
                onClick={() => {
                  ModalService.hide()
                }}
                to={routerPaths.SESSION_MANAGEMENT}
              >
                <a className={css.WarningLink}>View My Session Management</a>
              </Link>
            </div>
          ) : (
            <div className={css.Warning}></div>
          )}

          {!HIDDEN_FOR_NEXT_FEATURES && (
            <div className={css.CheckAutoStart}>
              <BeamCheckbox
                checked={timeState.autoStart}
                isLightTheme={isLightTheme}
                onChange={() => {
                  setTimeState({
                    ...timeState,
                    autoStart: !timeState.autoStart,
                  })
                }}
                className={css.scheduleSessionBeamCheckbox}
              ></BeamCheckbox>
              <BeamText
                className={
                  isLightTheme
                    ? css.scheduleSessionBeamTextAutoStartLight
                    : css.scheduleSessionBeamTextAutoStartDark
                }
              >
                Auto start the session at the scheduled time
              </BeamText>
            </div>
          )}
          <div className={css.Actions}>
            <WarmnessGrayButton
              className={css.BtnDelete}
              onClick={() => ModalService.hide()}
            >
              Delete
            </WarmnessGrayButton>

            <Button
              loading={stateMutationUpdate.fetching}
              onClick={onUpdate}
              disabled={timeState.startTimeError ? true : false}
              className={css.BtnSave}
            >
              Save
            </Button>
          </div>
        </div>
      </WarmnessDiv>
    </ModalContent>
  )
})
