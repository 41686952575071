import css from './TranscriptLoading.module.scss'

import { Icon } from '../../../components/base/Icon'

export const TranscriptLoading = () => (
  <div className={css.Wrapper}>
    <span className={css.LightIcon}>
      <Icon icon='Star-Filled' size={24} />
    </span>
    <div className={css.Description}>Aida is analyzing your transcript...</div>
    <div className={css.TextWaiting}>Please wait for a moment</div>
  </div>
)
