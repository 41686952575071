import { observer } from 'mobx-react-lite'

import css from './ModalOutStorage.module.scss'

import { Button } from '../../../components/base/Button'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { ModalBuyMoreStorage } from '../../../pages/SubscriptionPlan/components/ModalBuyMoreStorage'
import { ModalChangePlan } from '../../../pages/SubscriptionPlan/components/ModalChangePlan'
import { S } from '../../store'

export const ModalOutStorage = observer(
  ({ visible, onCancel }: { visible: boolean; onCancel?: () => void }) => {
    const handleBuyMore = () => {
      ModalService.hide()
      setTimeout(() => {
        ModalService.show(ModalBuyMoreStorage)
      }, 300)
    }
    const handleUpgradePlan = () => {
      ModalService.hide()
      setTimeout(() => {
        ModalService.show(ModalChangePlan)
      }, 300)
    }
    const isTeamAdmin = S.profile.isTeamAdmin
    return (
      <ModalContent containerClassName={css.ModalOutContent}>
        <div className={css.ModalOutTitle}>Failed to upload file</div>
        <div className={css.ModalOutText}>
          Please buy more storage to upload your file
        </div>
        <div className={css.Footer}>
          <Button onClick={handleBuyMore}>Buy More</Button>
          <Button disabled={!isTeamAdmin} onClick={handleUpgradePlan}>
            Upgrade Plan
          </Button>
        </div>
      </ModalContent>
    )
  },
)
