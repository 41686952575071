import { useEffect, useState } from 'react'

import type { INotificationRoomProps } from './NotificationRoom'

export const createNotificationRoomHandler = () => {
  let subscriberSetProps: ((data: INotificationRoomProps[]) => void) | undefined
  const intiProps: INotificationRoomProps[] = []
  let outSideProps: INotificationRoomProps[] = intiProps
  const setPropsRef = (
    previousStateCallback: (
      data: INotificationRoomProps[],
    ) => INotificationRoomProps[],
  ) => {
    outSideProps = previousStateCallback(outSideProps)
    subscriberSetProps && subscriberSetProps(outSideProps)
  }
  const show = (data: INotificationRoomProps) => {
    setPropsRef((prev: INotificationRoomProps[]) => [
      ...prev,
      {
        type: 'MESSAGE_MENTION',
        ...data,
      },
    ])
  }
  const hide = (index: number) => {
    setPropsRef((prev: INotificationRoomProps[]) => {
      const newProps = prev.filter((i, idx) => index !== idx)
      return newProps
    })
  }
  const use = () => {
    const [props, setProps] = useState<INotificationRoomProps[]>(intiProps)
    const handleChangeProps = (data: INotificationRoomProps[]) => {
      setProps(data)
    }

    useEffect(() => {
      subscriberSetProps = handleChangeProps

      return () => {
        subscriberSetProps = undefined
      }
    }, [props])

    return { data: props }
  }
  return {
    api: {
      show,
      hide,
    },
    use,
  }
}
const NotificationRoomHandler = createNotificationRoomHandler()
export const NotificationRoomApi = NotificationRoomHandler.api
export const useNotificationRoomHandler = NotificationRoomHandler.use
