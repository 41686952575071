import type { SearchNoteQuery } from '../../../context/gql/codegen'
import { S } from '../../store'

const $ = S.webrtc
export const updateNote = (data: Partial<SearchNoteQuery['searchNote'][0]>) => {
  $.note = { ...$.note, ...data }
}
export const addNotes = (deltas: any[]) => {
  $.note = { ...$.note, content: JSON.stringify(deltas) }
}
