import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type React from 'react'
import { useState } from 'react'

import css from './MediaVideoCard.module.scss'

import { Icon } from '../../../components/base/Icon'
import { AppSpin } from '../../../components/base/Spin'
import { BeamText } from '../../../components/base/Text'
import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import { S } from '../../../context/store'
import { covertDurationToMinutes } from '../../../context/store/studio/utils'
import { deleteResourceInInsightEngine } from '../actions/deleteResourceInInsightEngine'
import { getDownloadLink } from '../actions/getDownloadLink'
import { reLoadRecordMeetingLink } from '../actions/reLoadRecordMeetingLink'
import type { IVideoCard } from '../type'
import { ModalDeleteVideoIE } from './ModalDeleteVideoIE'

export const MediaVideoCard: React.FC<IVideoCard> = observer(
  ({ name, thumbnailSrc, duration, selected = false, onClick, resource }) => {
    const [hoveredVideo, setHoveredVideo] = useState<boolean>(false)
    const { resources } = S.insightEngine
    const rsUploading = resources.find(r => r.isReUploading)
    const handleDeleteVideo = (e: React.MouseEvent) => {
      e.stopPropagation()
      ModalService.show(ModalDeleteVideoIE, {
        onDelete: async () => {
          await deleteResourceInInsightEngine(resource.id)
        },
      })
    }
    const downloadHandler = (e: React.MouseEvent) => {
      e.stopPropagation()
      if (resource.isDownloading) {
        return
      }
      getDownloadLink(resource.id)
    }
    return (
      <div
        className={clsx({
          [css.Wrapper]: true,
          [css.SelectedCard]: selected,
        })}
        onClick={onClick}
        onMouseOver={() => setHoveredVideo(true)}
        onMouseOut={() => setHoveredVideo(false)}
      >
        <div className={css.VideoThumbnailWrapper}>
          <img src={thumbnailSrc?.url} alt='' className={css.VideoThumbnail} />
        </div>
        <BeamText
          className={css.VideoName}
          containerProps={{ classNameContainer: css.VideoNameWrapper }}
        >
          {name}
        </BeamText>
        {hoveredVideo ? (
          <div className={css.HoverIcon}>
            {resource.isFromBot && (
              <div
                onClick={e => {
                  e.stopPropagation()
                  if (rsUploading) {
                    ToastService.warning({
                      content: 'There is another process running.',
                    })
                    return
                  }
                  reLoadRecordMeetingLink(resource.id)
                }}
                className={css.IconDefault}
              >
                <span
                  className={clsx(css.RefreshIcon, {
                    ['animation-spin']: resource.isReUploading,
                  })}
                />
              </div>
            )}

            {resource.isDownloading ? (
              <div className={css.LoadingIcon}>
                <AppSpin
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
              </div>
            ) : (
              <Icon
                icon='Download1'
                size={16}
                onClick={downloadHandler}
                color='#808080'
              />
            )}
            <Icon
              onClick={e => handleDeleteVideo(e)}
              icon='Delete'
              size={16}
              color='#ea4335'
            />
          </div>
        ) : (
          <BeamText size='small' className={css.VideoDuration}>
            {covertDurationToMinutes(duration)}
          </BeamText>
        )}
      </div>
    )
  },
)
