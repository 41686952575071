import '../../MentionEditorModule/mention'
import '../AddImageModule/addImage'

import { forwardRef } from 'react'
import type { ReactQuillProps } from 'react-quill'
import ReactQuill, { Quill } from 'react-quill'

import { allFonts } from '../../../pages/Studio/components/LiveStreamCustomTitleData'

const Size = Quill.import('attributors/style/size')
const Font = Quill.import('attributors/style/font')
const arraySize = Array.from(Array(500).keys()).map(i => `${i + 1}px`)
Size.whitelist = arraySize
Font.whitelist = allFonts
Quill.register(Size, true)
Quill.register(Font, true)
export const QuillEditor = forwardRef((props: ReactQuillProps, ref: any) => (
  <ReactQuill {...props} ref={ref} />
))
