import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './LiveStreamBackground.module.scss'

import { S } from '../../../context/store'

export const LiveStreamBackgroundColor = observer(() => {
  const { backgroundColor } = S.webrtc
  if (!backgroundColor || backgroundColor === '') {
    return null
  }
  return (
    <div
      className={clsx(css.ContainerBackgroundColor)}
      id='LiveStreamBackgroundColor'
      style={{
        background: backgroundColor !== '' ? backgroundColor : 'transparent',
      }}
    />
  )
})

export const LiveStreamBackgroundImage = observer(() => {
  const { backgroundUrl } = S.webrtc
  if (!backgroundUrl || backgroundUrl === '') {
    return null
  }
  return (
    <div
      className={css.ContainerBackgroundImage}
      style={{
        backgroundImage:
          backgroundUrl !== '' ? `url(${backgroundUrl})` : 'none',
      }}
    />
  )
})
