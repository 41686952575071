import type {
  ProtooClientNotifyHandlers,
  ProtooClientPeer,
  ProtooClientRequestHandlers,
} from '../type'

export const _addProtooHandlers = (
  protoo: ProtooClientPeer,
  requestHandlers: ProtooClientRequestHandlers,
  notifyHandlers: ProtooClientNotifyHandlers,
) => {
  protoo.on('request', (r, cb, eb) => {
    const k = r.method as keyof ProtooClientRequestHandlers
    const h = requestHandlers[k]
    if (!h) {
      return
    }
    h(r.data)
      .then(cb as any)
      .catch((err: Error) => {
        console.error(err)
        eb(err)
      })
  })
  protoo.on('notification', n => {
    const k = n.method as keyof ProtooClientNotifyHandlers
    const h = notifyHandlers[k]
    if (!h) {
      return
    }
    h(n.data).catch((err: Error) => {
      console.error(err)
    })
  })
}
