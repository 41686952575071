import clsx from 'clsx'
import type { FC } from 'react'

import css from './MediaControl.module.scss'

import type { MediaVolume } from '#rn-shared/mediasoup/type-ui'

import { OnOffAirDropdown } from './OnOffAirDropdown'

type MediaControlProps = {
  videoEnabled: boolean
  onToggleVideo?(): void
  volume: MediaVolume
  onVolumeChange(v: Partial<MediaVolume>): void
  onKickOut?: () => void
  show: boolean
  screenshare: boolean
  peerId?: string
  isMainCanvas?: boolean
}

export const MediaControl: FC<MediaControlProps> = ({
  videoEnabled,
  onToggleVideo,
  volume,
  onVolumeChange,
  onKickOut,
  show,
  screenshare,
  peerId,
  isMainCanvas,
}) => (
  <div
    className={clsx({
      [css.MediaControlContainer]: true,
      [css.mediaControlContainerShow]: show,
    })}
  >
    <OnOffAirDropdown
      volume={volume}
      onVolumeChange={onVolumeChange}
      isTriggerHover
      onToggleVideo={onToggleVideo}
      peerId={peerId}
      videoEnabled={videoEnabled}
      onKickOut={onKickOut}
      isScreenShare={screenshare}
      isMainCanvas={isMainCanvas}
    />
  </div>
)
