import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC, ReactElement, ReactNode } from 'react'
import { useLocation } from 'react-router'

import LogoColored from '../../../assets/images/bt_colored.svg'
import Logo from '../../../assets/logo-white.svg'
import css from './PublicLayout.module.scss'

import { RouterLink } from '#HACK_FOR_RN_ONLY/src/components/base/RouterLink'
import { BeamText } from '#HACK_FOR_RN_ONLY/src/components/base/Text'

import { CONSTANTS } from '../../../styles/Constants'
import { routerPaths } from '../../router/routerPaths'
import { S } from '../../store'

interface IPublicLayoutProps {
  toolBar?: ReactElement
  footer?: ReactElement
  toolBarRight?: ReactElement
  children: ReactNode
}
export const PublicLayout: FC<IPublicLayoutProps> = observer(props => {
  const { isLightTheme } = S.local
  const { isAuthenticate } = S.profile

  const location = useLocation()
  const isLogin = location.pathname === '/login'
  const renderToolbarRightDefault = () => (
    <div className={css.RightContent}>
      <BeamText
        containerProps={{ classNameContainer: css.RightItem }}
        color={CONSTANTS.text.level2}
      >
        Term
      </BeamText>
      <BeamText
        containerProps={{ classNameContainer: css.RightItem }}
        color={CONSTANTS.text.level2}
      >
        Privacy
      </BeamText>
      <BeamText
        containerProps={{ classNameContainer: css.RightItem }}
        color={CONSTANTS.text.level2}
      >
        Support
      </BeamText>
      {!isAuthenticate && (
        <RouterLink
          className={clsx(css.BtnLogin, css.BtnActive)}
          to={isLogin ? routerPaths.SIGNUP : routerPaths.LOGIN}
        >
          {isLogin ? 'Sign Up' : 'Login'}
        </RouterLink>
      )}
    </div>
  )
  const renderToolbarPublicLayout = () => (
    <div className={css.ToolbarContainer}>
      <div className={css.LeftContent}>
        <img src={isLightTheme ? LogoColored : Logo} className={css.logoIcon} />
      </div>
      {props.toolBarRight ? props.toolBarRight : renderToolbarRightDefault()}
    </div>
  )
  return (
    <div className={css.PublicLayoutContainer}>
      <div className={css.PublicLayoutToolbarContainer}>
        {renderToolbarPublicLayout()}
      </div>
      <div className={css.PublicLayoutContentContainer}>
        <div className={css.Wrapper}> {props.children}</div>
      </div>
      {props.footer}
    </div>
  )
})
