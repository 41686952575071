import { observer } from 'mobx-react-lite'

import css from './FullScreenButton.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { toggleFullScreen } from '../actions/toggleFullscreen'

export const FullScreenButton = observer(() => (
  <WarmnessGrayButton onClick={toggleFullScreen} className={css.ActionRounded}>
    <Icon icon='icon_menu_fullscreen' size={14} />
  </WarmnessGrayButton>
))
