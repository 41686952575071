import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'

import IndicatorIcon from '../../../assets/icons/Recording.svg'
import css from './RecordView.module.scss'

import { BeamCheckbox } from '#HACK_FOR_RN_ONLY/src/components/base/Checkbox'

import { buildMixerQuery } from '../../../context/actions/mixerActions'
import { S } from '../../../context/store'
import {
  amsMediaPrefix,
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { isInputPeer } from '../utils/isInputPeer'
import { renderAvatar } from './ChatItem'
import { Player } from './Player'

export type TRecordViewProps = {
  peerId: string
  isChecked?: boolean
}

export const RecordView: FC<TRecordViewProps> = observer(
  ({ peerId, isChecked }) => {
    const { inputs, peers, amsVideoState } = S.webrtc
    const isAms = peerId.includes(amsMediaPrefix)
    const screenshare = isScreenshareMedia(peerId)
    const mediaPeerId = getMediaPeerId(peerId)
    const peer = isAms
      ? peers.find(p => p.data.viewmode === 'ams-client')
      : S.webrtc.getPeer(mediaPeerId)
    const input = inputs.find(i => i.id === getNormalId(peerId))
    if (!peer && !input) {
      return null
    }
    const { outputInSession } = S.webrtc
    const videoTrack = isAms
      ? peer?.medias?.[peerId]?.video?.track
      : screenshare
        ? peer?.screenshareVideo?.track
        : peer?.video?.track

    const videoEnabled = isAms
      ? amsVideoState[peerId].enableOnRoom
      : isInputPeer(peerId)
        ? input?.status === 'Publishing'
        : !!videoTrack
    // should already checked role observer
    const thisMixerQuery = buildMixerQuery(
      {
        id: getNormalId(peerId),
        userId: isInputPeer(peerId) ? undefined : peer?.data.userId,
        isInput: isInputPeer(peerId),
      },
      screenshare,
    )
    const rs = outputInSession
      .filter(
        o =>
          o.output?.type === 'Recording' &&
          o.status === 'Publishing' &&
          o.mixerQuery,
      )
      .find(o2 => o2.mixerQuery === thisMixerQuery)
    return (
      <>
        {rs && (
          <div className={css.Indicator}>
            <img src={IndicatorIcon} />
          </div>
        )}
        <span className={css.MediaViewPeerName}>
          {isInputPeer(peerId) ? `Input ${input?.name}` : peer?.data.name}
        </span>
        <div
          className={clsx({
            [css.MediaView]: true,
            [css.mediaScreenshareLayoutStyle]: screenshare,
            [css.CheckedVideo]: isChecked && !rs,
          })}
        >
          {!rs && (
            <BeamCheckbox value={peerId} className={css.RecordCheckbox} />
          )}
          <div className={css.MediaMask} />
          <Player
            muted={true}
            id={peerId}
            videoTrack={videoEnabled ? videoTrack : undefined}
            screenshare={screenshare}
            isSlotSmall
            videoStyle={{ cursor: 'pointer' }}
          />
        </div>

        {!videoEnabled &&
          renderAvatar(
            {
              id: (isInputPeer(peerId) ? input?.id : peer?.id) ?? '',
              name: isInputPeer(peerId) ? input?.name : peer?.data.name,
              avatar: isInputPeer(peerId) ? '' : peer?.data.avatar || '',
            },
            {
              width: '40px',
              height: '40px',
              lineHeight: '40px',
              fontSize: '24px',
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          )}
      </>
    )
  },
)
