import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import css from './CustomLayoutResizeMultiple.module.scss'

import { getLayoutRatio } from '#HACK_FOR_RN_ONLY/src/pages/Studio/actions/getLayoutRatio'
import { calculateUpdateSlot } from '#HACK_FOR_RN_ONLY/src/pages/Studio/components/UploadTranscript/calculateSlot'

import { Icon } from '../../../components/base/Icon'
import { setIsDragging } from '../../actions/layout/setIsDragging'
import { setIsResizing } from '../../actions/layout/setIsResizing'
import { updateSlotsToEdit } from '../../actions/layout/updateSlotsToEdit'
import { S } from '../../store'
import type {
  ILayout,
  ILayoutItem,
  ILayoutSize,
} from '../../store/shared/LayoutStore'
import { ECropMode } from '../../store/shared/LayoutStore'
import { getSizeChildWhenResize } from '../utils/CustomLayoutResizeUtils'
import type {
  IActionOutsize,
  IOptionChangeResize,
} from '../utils/CustomLayoutType'
import { EDirection } from '../utils/CustomLayoutType'
import { Resizeable } from './CustomLayoutResizeable'

const multipleId = 1234
export const ResizeMultiple: React.FC<{
  layout: ILayout

  containerProps: ILayoutSize
}> = observer(({ layout, containerProps }) => {
  const { isDragging, isResizing } = S.layout
  const { customRatio } = S.webrtc
  const layouts = layout.slots ?? []
  const slotsOfGroup = layouts.filter(s => s.inGroup)
  const [useAlignAction, setUseAlignAction] = useState(false)
  const slotGroupOrigin = useRef<ILayoutItem[]>([])
  const layoutPropsRef = useRef<ILayoutItem | null>(null)
  const { ratioLW, ratioTH } = getLayoutRatio()

  useEffect(() => {
    if (!isDragging || useAlignAction || !isResizing) {
      slotGroupOrigin.current = slotsOfGroup
      setUseAlignAction(false)
    }
  }, [isDragging, slotsOfGroup.length, useAlignAction, isResizing])

  const slotsWithRatio = slotsOfGroup.map(i => ({
    ...i,
    ...calculateUpdateSlot({
      left: i.left,
      width: i.width,
      height: i.height,
      top: i.top,
    }),
  }))
  const computeCropDataWithRatio = (
    s: ILayoutItem,
    additionnal?: Partial<ILayoutItem['cropData']>,
  ) =>
    s.cropMode === ECropMode.Crop
      ? {
          cropData: {
            ...calculateUpdateSlot({
              top: s.cropData?.top ?? 0,
              left: s.cropData?.left ?? 0,
              width: s.cropData?.width ?? 0,
              height: s.cropData?.height ?? 0,
            }),
            ...additionnal,
          },
        }
      : {}
  const [layoutState, setLayoutState] = useState<ILayoutItem>({
    height: 0,
    id: multipleId,
    left: 0,
    top: 0,
    width: 0,
    zIndex: 100,
    radius: 0,
    children: null,
  })
  const { height: lH, width: lW, left: lL, top: lT } = layoutState
  useEffect(() => {
    const maxLeft = containerProps.width
    const maxTop = containerProps.height
    let top = Math.min(...slotsOfGroup.map(s1 => s1.top))
    let left = Math.min(...slotsOfGroup.map(s2 => s2.left))
    const right = Math.max(...slotsOfGroup.map(s3 => s3.left + s3.width))
    const bottom = Math.max(...slotsOfGroup.map(s4 => s4.top + s4.height))
    if (right > maxLeft) {
      left = left - (right - maxLeft)
    }
    if (bottom > maxTop) {
      top = top - (bottom - maxTop)
    }
    setLayoutState({
      height: bottom - top,
      id: multipleId,
      left,
      top,
      width: right - left,
      zIndex: 100,
      radius: 0,
      children: null,
    })
  }, [slotsOfGroup.length])

  useEffect(() => {
    if (layoutState && !isDragging && !isResizing) {
      layoutPropsRef.current = layoutState
    }
  }, [layoutState, isDragging, isResizing])

  const onChangeSize = (
    state: ILayoutItem,
    options?: IOptionChangeResize,
    fromKeyBoard?: boolean,
  ) => {
    if (fromKeyBoard) {
      slotGroupOrigin.current = slotsOfGroup
      layoutPropsRef.current = layoutState
    }
    const layoutOrigin = layoutPropsRef.current ?? layoutState
    const sls = slotGroupOrigin.current
    const scaleX = state.width / layoutOrigin.width
    const scaleY = state.height / layoutOrigin.height
    const topDiff =
      options?.direction === EDirection.TOP_LEFT ||
      options?.direction === EDirection.TOP ||
      options?.direction === EDirection.TOP_RIGHT ||
      options?.direction === EDirection.LEFT
        ? 0
        : state.top - layoutOrigin.top
    const leftDiff =
      options?.direction === EDirection.TOP_LEFT ||
      options?.direction === EDirection.TOP ||
      options?.direction === EDirection.TOP_RIGHT ||
      options?.direction === EDirection.LEFT ||
      options?.direction === EDirection.BOTTOM_LEFT
        ? 0
        : state.left - layoutOrigin.left
    let newData: ILayoutItem[] = sls
    const xOrigin =
      options?.direction === EDirection.LEFT ||
      options?.direction === EDirection.BOTTOM_LEFT ||
      options?.direction === EDirection.TOP_LEFT
        ? Math.max(...sls.map(s1 => s1.left + s1.width))
        : Math.min(...sls.map(s1 => s1.left))
    const yOrigin =
      options?.direction === EDirection.TOP ||
      options?.direction === EDirection.TOP_RIGHT ||
      options?.direction === EDirection.TOP_LEFT ||
      options?.direction === EDirection.RIGHT ||
      options?.direction === EDirection.LEFT
        ? Math.max(...sls.map(s1 => s1.height + s1.top))
        : Math.min(...sls.map(s1 => s1.top))
    const optionsDefault: IOptionChangeResize = {
      switchLR: false,
      switchTB: false,
      direction: undefined,
    }
    if (scaleX !== 1 || scaleY !== 1) {
      newData = sls.map(s1 =>
        getSizeChildWhenResize(
          s1,
          { cX: xOrigin, cY: yOrigin },
          options ?? optionsDefault,
          { sX: scaleX, sY: scaleY },
          { rX: ratioLW, rY: ratioTH },
          layoutOrigin,
          { top: topDiff, left: leftDiff },
        ),
      )
    } else if (topDiff !== 0 || leftDiff !== 0) {
      newData = sls.map(s2 => {
        const stateToUpdate = {
          ...calculateUpdateSlot({
            width: s2.width * scaleX,
            height: s2.height * scaleY,
            top: s2.top + topDiff,
            left: s2.left + leftDiff,
          }),
        }
        if (s2.cropMode === ECropMode.Crop) {
          return {
            ...s2,
            ...stateToUpdate,
            cropData: {
              ...calculateUpdateSlot({
                top: (s2.cropData?.top ?? 0) - yOrigin + state.top,
                left: (s2.cropData?.left ?? 0) - xOrigin + state.left,
                width: s2.cropData?.width ?? 0,
                height: s2.cropData?.height ?? 0,
              }),
            },
          }
        }
        return {
          ...s2,
          ...stateToUpdate,
        }
      })
    } else {
      return
    }
    if (
      layoutState.width === state.width &&
      layoutState.height === state.height &&
      layoutState.left === state.left &&
      layoutState.top === state.top
    ) {
      return
    }
    updateSlotsToEdit(newData, layout.id)

    setLayoutState(state)
  }
  const handleAlignLeft = () => {
    const slotsUpdated = slotsWithRatio.map(s3 => ({
      ...s3,
      left: customRatio >= 1 ? lL / ratioLW : s3.left,
      top: customRatio >= 1 ? s3.top : lL / ratioTH / customRatio,
      ...computeCropDataWithRatio(
        s3,
        customRatio >= 1
          ? {
              left: (lL + (s3.cropData?.left ?? 0)) / ratioLW - s3.left,
            }
          : {
              top:
                (lL + (s3.cropData?.left ?? 0)) / ratioTH / customRatio -
                s3.top,
            },
      ),
    }))
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLW = Math.max(...slotsOfGroup.map(i1 => i1.width))
    setLayoutState({
      ...layoutState,
      width: newLW,
    })
    setUseAlignAction(true)
  }
  const handleAlignCenterV = () => {
    const xCenter = lL + lW / 2
    const slotsUpdated = slotsOfGroup.map(s4 => {
      const slotLeft = xCenter - s4.width / 2

      return {
        ...s4,
        ...calculateUpdateSlot({
          left: slotLeft,
          top: s4.top,
          width: s4.width,
          height: s4.height,
        }),
        ...computeCropDataWithRatio(
          s4,
          customRatio >= 1
            ? {
                left: (slotLeft + (s4.cropData?.left ?? 0) - s4.left) / ratioLW,
              }
            : {
                top:
                  (slotLeft + (s4.cropData?.left ?? 0) - s4.left) /
                  ratioTH /
                  customRatio,
              },
        ),
      }
    })
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLW = Math.max(...slotsOfGroup.map(i1 => i1.width))
    const newLL = Math.min(
      ...slotsUpdated.map(i1 =>
        customRatio >= 1 ? i1.left * ratioLW : ratioTH * i1.top * customRatio,
      ),
    )
    setLayoutState({
      ...layoutState,
      width: newLW,
      left: newLL,
    })
    setUseAlignAction(true)
  }
  const handleAlignRight = () => {
    const xRight = lL + lW
    const slotsUpdated = slotsOfGroup.map(s4 => {
      const slotLeft = xRight - s4.width
      return {
        ...s4,
        ...calculateUpdateSlot({
          left: slotLeft,
          top: s4.top,
          width: s4.width,
          height: s4.height,
        }),
        ...computeCropDataWithRatio(
          s4,
          customRatio >= 1
            ? {
                left: (slotLeft + (s4.cropData?.left ?? 0) - s4.left) / ratioLW,
              }
            : {
                top:
                  (slotLeft + (s4.cropData?.left ?? 0) - s4.left) /
                  ratioTH /
                  customRatio,
              },
        ),
      }
    })
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLW = Math.max(...slotsOfGroup.map(i1 => i1.width))
    const newLL = Math.min(
      ...slotsUpdated.map(i1 =>
        customRatio >= 1 ? i1.left * ratioLW : ratioTH * i1.top * customRatio,
      ),
    )
    setLayoutState({
      ...layoutState,
      width: newLW,
      left: newLL,
    })
    setUseAlignAction(true)
  }
  const handleAlignBottom = () => {
    const xBottom = lT + lH
    const slotsUpdated = slotsOfGroup.map(s4 => {
      const slotTop = xBottom - s4.height
      return {
        ...s4,
        ...calculateUpdateSlot({
          left: s4.left,
          top: slotTop,
          width: s4.width,
          height: s4.height,
        }),
        ...computeCropDataWithRatio(
          s4,
          customRatio >= 1
            ? {
                top: (slotTop + (s4.cropData?.top ?? 0) - s4.top) / ratioTH,
              }
            : {
                left:
                  ((slotTop + (s4.cropData?.top ?? 0) - s4.top) / ratioLW) *
                  customRatio,
              },
        ),
      }
    })
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLH = Math.max(...slotsOfGroup.map(i1 => i1.height))
    const newLT = Math.min(
      ...slotsUpdated.map(i1 =>
        customRatio >= 1 ? i1.top * ratioTH : (ratioLW * i1.left) / customRatio,
      ),
    )
    setLayoutState({
      ...layoutState,
      height: newLH,
      top: newLT,
    })
    setUseAlignAction(true)
  }
  const handleAlignCenterH = () => {
    const yCenter = lT + lH / 2
    const slotsUpdated = slotsOfGroup.map(s4 => {
      const slotTop = yCenter - s4.height / 2
      return {
        ...s4,
        ...calculateUpdateSlot({
          left: s4.left,
          top: slotTop,
          width: s4.width,
          height: s4.height,
        }),
        ...computeCropDataWithRatio(
          s4,
          customRatio >= 1
            ? {
                top: (slotTop + (s4.cropData?.top ?? 0) - s4.top) / ratioTH,
              }
            : {
                left:
                  ((slotTop + (s4.cropData?.top ?? 0) - s4.top) / ratioLW) *
                  customRatio,
              },
        ),
      }
    })
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLH = Math.max(...slotsOfGroup.map(i1 => i1.height))
    const newLT = Math.min(
      ...slotsUpdated.map(i1 =>
        customRatio >= 1 ? i1.top * ratioTH : (ratioLW * i1.left) / customRatio,
      ),
    )
    setLayoutState({
      ...layoutState,
      height: newLH,
      top: newLT,
    })
    setUseAlignAction(true)
  }
  const handleAlignTop = () => {
    const slotsUpdated = slotsWithRatio.map(s3 => ({
      ...s3,
      top: customRatio >= 1 ? lT / ratioTH : s3.top,
      left: customRatio >= 1 ? s3.left : (lT / ratioLW) * customRatio,
      ...computeCropDataWithRatio(
        s3,
        customRatio >= 1
          ? {
              top: (lT + (s3.cropData?.top ?? 0)) / ratioTH - s3.top,
            }
          : {
              left:
                ((lT + (s3.cropData?.top ?? 0)) / ratioLW) * customRatio -
                s3.left,
            },
      ),
    }))
    updateSlotsToEdit(slotsUpdated, layout.id)
    const newLH = Math.max(...slotsOfGroup.map(i1 => i1.height))
    setLayoutState({
      ...layoutState,
      height: newLH,
    })
    setUseAlignAction(true)
  }

  const handleAlignDistributeH = () => {
    const totalWidthOfSlots = slotsOfGroup.reduce(
      (pre, cur) => pre + cur.width,
      0,
    )

    const paddingW = (lW - totalWidthOfSlots) / (slotsOfGroup.length - 1)
    const slotArrSortByLeft = [...slotsOfGroup]
    slotArrSortByLeft.sort((a, b) => a.left - b.left)
    const slotsUpdated: ILayoutItem[] = []
    slotArrSortByLeft.forEach((s4, index) => {
      if (index !== 0) {
        const preSlot = slotsUpdated[index - 1]

        const slotLeft = preSlot.left + preSlot.width + paddingW
        slotsUpdated.push({
          ...s4,
          left: slotLeft,
          ...computeCropDataWithRatio(s4, {
            left: (slotLeft + (s4.cropData?.left ?? 0) - s4.left) / ratioLW,
          }),
        })
      } else {
        slotsUpdated.push({
          ...s4,
          ...computeCropDataWithRatio(s4),
        })
      }
    })
    updateSlotsToEdit(
      slotsUpdated.map(s5 => ({
        ...s5,
        ...calculateUpdateSlot({
          top: s5.top,
          left: s5.left,
          width: s5.width,
          height: s5.height,
        }),
      })),
      layout.id,
    )
    const newLL = Math.min(...slotsUpdated.map(i1 => i1.left))
    const right = Math.max(...slotsUpdated.map(s8 => s8.left + s8.width))
    setLayoutState({
      ...layoutState,
      width: right - newLL,
      left: newLL,
    })
    setUseAlignAction(true)
  }
  const handleAlignDistributeV = () => {
    const totalHeightOfSlots = slotsOfGroup.reduce(
      (pre, cur) => pre + cur.height,
      0,
    )

    const paddingH = (lH - totalHeightOfSlots) / (slotsOfGroup.length - 1)
    const slotArrSortByTop = [...slotsOfGroup]
    slotArrSortByTop.sort((a, b) => {
      if (a.top - b.top !== 0) {
        return a.top - b.top
      } else {
        return a.left - b.left
      }
    })
    const slotsUpdated: ILayoutItem[] = []
    slotArrSortByTop.forEach((s6, index) => {
      if (index !== 0) {
        const preSlot = slotsUpdated[index - 1]
        const slotTop = preSlot.top + preSlot.height + paddingH
        slotsUpdated.push({
          ...s6,
          top: slotTop,
          ...computeCropDataWithRatio(s6, {
            top: (slotTop + (s6.cropData?.top ?? 0) - s6.top) / ratioTH,
          }),
        })
      } else {
        slotsUpdated.push({
          ...s6,
          ...computeCropDataWithRatio(s6),
        })
      }
    })
    updateSlotsToEdit(
      slotsUpdated.map(s7 => ({
        ...s7,
        ...calculateUpdateSlot({
          top: s7.top,
          left: s7.left,
          width: s7.width,
          height: s7.height,
        }),
      })),
      layout.id,
    )
    const newLT = Math.min(...slotsUpdated.map(i1 => i1.top))
    const bottom = Math.max(...slotsUpdated.map(s8 => s8.top + s8.height))
    setLayoutState({
      ...layoutState,
      height: bottom - newLT,
      top: newLT,
    })
    setUseAlignAction(true)
  }

  const actionsTop: IActionOutsize[] = [
    {
      onAction: handleAlignLeft,
      element: <Icon icon='Align-Left' size={16} className={css.SlotTopIcon} />,
    },
    {
      onAction: handleAlignCenterV,
      element: (
        <Icon icon='Align-CenterV' size={16} className={css.SlotTopIcon} />
      ),
    },
    {
      onAction: handleAlignRight,
      element: (
        <Icon icon='Align-Right' size={16} className={css.SlotTopIcon} />
      ),
    },
    {
      onAction: handleAlignTop,
      element: <Icon icon='Align-Top' size={16} className={css.SlotTopIcon} />,
    },

    {
      onAction: handleAlignCenterH,
      element: (
        <Icon icon='Align-CenterH' size={16} className={css.SlotTopIcon} />
      ),
    },
    {
      onAction: handleAlignBottom,
      element: (
        <Icon icon='Align-Bottom' size={16} className={css.SlotTopIcon} />
      ),
    },
    {
      onAction: handleAlignDistributeV,
      element: (
        <Icon icon='Align-DistributeV' size={16} className={css.SlotTopIcon} />
      ),
    },
    {
      onAction: handleAlignDistributeH,
      element: (
        <Icon icon='Align-DistributeH' size={16} className={css.SlotTopIcon} />
      ),
    },
  ]
  const handleOnChangeSize = (w: number, h: number) => {
    if (
      w !== layoutPropsRef.current?.width ||
      h !== layoutPropsRef.current?.height
    ) {
      slotGroupOrigin.current = slotsOfGroup
      layoutPropsRef.current = layoutState
      const { left: rL, top: rT } = layoutPropsRef.current
      let left = rL
      let top = rT
      const maxLeft = containerProps.width
      const maxTop = containerProps.height
      const right = rL + w
      const bottom = rT + h
      if (right > maxLeft) {
        left = rL - (right - maxLeft)
      }
      if (bottom > maxTop) {
        top = top - (bottom - maxTop)
      }
      onChangeSize({ ...layoutState, width: w, height: h, left, top })
    }
  }
  return (
    <Resizeable
      id='SlotCustom'
      isEdit={true}
      layout={layoutState}
      isMulti
      slotsOtherEditing={[]}
      index={100}
      actionsTop={actionsTop}
      enableEditLayout
      changeDragging={setIsDragging}
      changeResizing={setIsResizing}
      onChange={onChangeSize}
      ratio={ratioLW}
      onKeyDown={() => {}}
      onKeyUp={() => {}}
      onChangeSize={handleOnChangeSize}
      containerProps={containerProps}
    />
  )
})
