import type { UpdateInsightEngine } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const updateNameInsightEngine = async (
  id: string,
  data: UpdateInsightEngine,
) => {
  const $ = S.insightEngine
  const prevName = $.detailInsightEngine.name
  $.detailInsightEngine.name = data.name || 'Unknown name'
  const res = await reduxStore.context.gql.updateInsightEngine({ id, data })

  if ($.detailInsightEngine.name !== res.data?.updateInsightEngine.name) {
    if ($.detailInsightEngine.name !== 'Unknown name') {
      $.detailInsightEngine.name = prevName
    }
  }
}
