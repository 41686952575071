import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'

import css from './GraphicSort.module.scss'

import { arrToMap } from '##/shared/arrToMap'
import { waitTimeout } from '##/shared/waitTimeout'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type { TMediaItem } from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import type { TDragPreview } from './RightContent'
import { UploadButton } from './UploadButton'

type TPropsMethod = {
  onToggleStatus?: (url: string) => void
  onUploadFile?: (files: TMediaItem[]) => void
  onSortEnd: (dropIndex: number, overIndex: number) => void
  onRemove?: (item: TMediaItem) => void
  large: boolean
  dragType: string
  isHideDefault?: boolean
  typeResource: string
}
type TContainer = TPropsMethod & {
  list: ReadonlyArray<TMediaItem>
  selectedItem: string
}
type TItem = TPropsMethod & {
  item: TMediaItem
  isSelected: boolean
  index: string
  isDraggingItem: boolean
  isHasCloneItem?: boolean
}

type TToggleGraphic = {
  onRemove?: (item: TMediaItem) => void
  item: TMediaItem
  onToggleStatus?: (url: string) => void
  isSelected?: boolean
  large?: boolean
  isDraggingItem: boolean
}

export const GraphicSortContainer = observer((props: TContainer) => {
  const hasCloneMap = arrToMap(
    props.list.map(g => g.originalResourceId as string).filter(id => id),
  )
  return (
    <>
      <div className={css.GraphicListItems}>
        {props.list.map((item, index) => (
          <Item
            {...props}
            key={index + item.value}
            item={item}
            index={index.toString()}
            isSelected={props.selectedItem === item.value}
            isDraggingItem={S.webrtc.typeDraggingItem === props.dragType}
            isHideDefault={props.isHideDefault && item.isDefault}
            isHasCloneItem={hasCloneMap[item.resourceId || '']}
            onRemove={props.onRemove}
          />
        ))}

        <div
          className={clsx({
            [css.GraphicItemWrapper]: true,
            [css.graphicItemWrapperLarge]: props.large,
          })}
        >
          <div
            className={clsx({
              [css.GraphicItemUploadContent]: true,
              [css.graphicItemContentLarge]: props.large,
            })}
          >
            <UploadButton
              onUploadFile={props.onUploadFile}
              typeResource={props.typeResource}
            />
          </div>
        </div>
      </div>
      {S.webrtc.typeDraggingItem === props.dragType && (
        <ItemPreview large={props.large} dragType={props.dragType} />
      )}
    </>
  )
})

const Item = observer((props: TItem) => {
  const { item, index, dragType, onSortEnd, isHideDefault } = props
  const dref = useRef<HTMLDivElement>(null)
  const { isDragging, drag } = useDragDefault({
    item: { id: item.id, value: item.value, type: dragType, index },
    dragType,
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: dragType,
    index,
    dref,
    onSortEnd,
    direction: 'x',
  })
  drag(drop(dref))

  return (
    <div
      className={clsx({
        [css.GraphicItemWrapper]: true,
        [css.graphicItemWrapperHide]: isHideDefault || props.isHasCloneItem,
        [css.graphicItemWrapperLarge]: props.large,
        [css.graphicItemWrapperDropover]: isOver,
        [css.graphicItemWrapperDropoverDropbottom]: isOver && isBottom,
      })}
      ref={dref}
    >
      <div
        className={clsx({
          [css.GraphicItemContent]: true,
          [css.graphicItemContentSelected]: props.isSelected,
          [css.graphicItemContentLarge]: props.large,
          [css.graphicItemContentDragging]: isDragging,
        })}
      >
        <ItemButton {...props} item={item} />
      </div>
    </div>
  )
})

export const AutoReloadImg = (props: { src: string }) => {
  const totalError = useRef(0)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (error && totalError.current < 5) {
      totalError.current++
      waitTimeout(1000).then(() => setError(false))
    }
  }, [error])
  const onImageError = () => setError(true)

  const [loading, setLoading] = useState(true)
  const onImageLoad = () => {
    setLoading(false)
    setError(false)
  }
  const { src } = props
  return (
    <>
      {!error && <img src={src} onLoad={onImageLoad} onError={onImageError} />}
      {loading && <LoadingOutlined className={css.LoadingSocial} />}
    </>
  )
}

const ItemButton = ({
  item,
  onToggleStatus,
  large = false,
  isSelected = false,
  onRemove,
  isDraggingItem,
}: TToggleGraphic) => (
  <div className={css.ItemToggleButton}>
    <div
      className={clsx({
        [css.ImageButtonGraphic]: true,
        [css.imageButtonGraphicLarge]: large,
      })}
    >
      <AutoReloadImg src={item.value} />
    </div>
    {isSelected && <div className={css.BlockOnAir}>On Air</div>}
    <div
      className={clsx({
        [css.BlockAction]: true,
        [css.blockActionSelected]: isSelected,
        [css.blockActionDragging]: isDraggingItem,
      })}
    >
      {onToggleStatus && (
        <button
          className={css.ButtonToggle}
          onClick={() => onToggleStatus(item.value)}
        >
          <span>
            {isSelected ? (
              <>
                <EyeInvisibleOutlined /> Hide
              </>
            ) : (
              <>
                <EyeOutlined /> Show
              </>
            )}
          </span>
        </button>
      )}
      {onRemove && (
        <div
          className={clsx(css.BlockRemove, {
            [css.BlockRemoveSelected]: isSelected,
          })}
          onClick={() => onRemove(item)}
        >
          <Icon icon='icon_delete' size={11} />
        </div>
      )}
    </div>
  </div>
)

const ItemPreview = ({
  large,
  dragType,
}: {
  large: boolean
  dragType: string
}) => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging || item.type !== dragType) {
    return null
  }
  return (
    <div
      className={clsx({
        [css.GraphicItemPreview]: true,
        [css.graphicItemPreviewLarge]: large,
      })}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div
        className={clsx({
          [css.GraphicItemWrapper]: true,
          [css.graphicItemWrapperLarge]: large,
        })}
      >
        <div
          className={clsx({
            [css.GraphicItemContent]: true,
            [css.graphicItemContentLarge]: large,
            [css.GraphicItemContentPreview]: true,
          })}
        >
          <ItemButton
            isDraggingItem={false}
            item={{
              id: item.id,
              value: item.value,
              isDefault: item.isDefault,
              mediaType: '',
            }}
            large={large}
          />
        </div>
      </div>
    </div>
  )
}
