import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'

import css from './BottomPopoverVideo.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { S } from '../../../context/store'
import { onStudioBlurCam } from '../actions/onBlurCam'

export const BottomPopoverVideo = observer(() => {
  const { isLightTheme } = S.local
  return (
    <WarmnessDiv
      onClick={(e: MouseEvent) => e.stopPropagation()}
      onMouseDown={(e: MouseEvent) => e.stopPropagation()}
      className={css.WrapperPopoverContent}
    >
      <div>
        <h5
          className={clsx(
            css.WrapperPopoverTitle,
            isLightTheme
              ? css.bottomPopoverVideoh5Light
              : css.bottomPopoverVideoh5Dark,
          )}
        >
          Effect
        </h5>
        <div className={css.WrapperPopoverList}>
          <div
            className={clsx({
              [css.WrapperPopoverItem]: true,
              [css.Selected]: S.webrtc.isBlurSupported && S.webrtc.camBlurred,
              [css.Dark]: !isLightTheme,
            })}
            onClick={onStudioBlurCam}
          >
            Background blur
            <div className={css.IconCheck}>
              <Icon icon='icon_check' size={16} color='#085AE1' />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h5
          className={clsx(
            css.WrapperPopoverTitle,
            isLightTheme
              ? css.bottomPopoverVideoh5Light
              : css.bottomPopoverVideoh5Dark,
          )}
        >
          Select a Camera
        </h5>
        <div className={css.WrapperPopoverList}>
          {S.webrtc.cams.map(c => (
            <div
              className={clsx({
                [css.WrapperPopoverItem]: true,
                [css.Selected]: S.webrtc.camId === c.deviceId,
                [css.Dark]: !isLightTheme,
              })}
              key={c.deviceId}
              onClick={() => {
                if (S.webrtc.camId === c.deviceId) {
                  return
                }
                S.webrtc.camId = c.deviceId
                S.webrtc.onSelectedCameraChange()
              }}
            >
              {c.label || c.name || c.deviceId}
              <div className={css.IconCheck}>
                <Icon icon='icon_check' size={16} color='#085AE1' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </WarmnessDiv>
  )
})
