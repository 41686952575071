import type { TextProps as AntdTextProps } from 'antd/lib/typography/Text'
import type { CSSProperties } from 'react'

export enum EFontSizeText {
  SMALL1 = 'small1',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGE2 = 'large2',
  LARGE1 = 'large1',
  LARGE3 = 'large3',
}
export type TTextContainerProps = {
  successColor?: string
  warningColor?: string
  dangerColor?: string
  secondaryColor?: string
  disabledCode?: string
  classNameContainer?: string
  style?: CSSProperties
}
export type TextProps = AntdTextProps & {
  size?:
    | EFontSizeText
    | 'small1'
    | 'small'
    | 'medium'
    | 'large'
    | 'large1'
    | 'large2'
  color?: string
}

export type AppTextProps = {
  containerProps?: TTextContainerProps
} & TextProps
