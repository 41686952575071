import { S } from '../../../context/store'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'

export const changeTitleItem = (titleId: string, item: Partial<TitleItem>) => {
  const $ = S.layout
  $.titlesOnEdit = $.titlesOnEdit.map(t => {
    if (t.id === titleId) {
      return {
        ...t,
        ...item,
      }
    }
    return t
  })
}
