import { Platform } from 'react-native'

import type { Viewmode } from '#rn-shared/mediasoup/type'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

// filter to show on the small list on web
// on mobile we need to exclude the canvas since it is displayed separately
export const filterSmallPeers = (
  currentViewmode: Viewmode,
  peers: PeerUI[],
) => {
  const filtered = peers.filter(p => {
    if (!p?.id || p.data.controlMedia) {
      return false
    }
    const v = p.data.viewmode
    const b =
      !!p.id &&
      v !== 'mixer' &&
      v !== 'ams' &&
      v !== 'ams-client-controller' &&
      v !== 'ams-client'
    if (currentViewmode === 'robserver') {
      return b
    }

    return b && v !== 'robserver' && v !== 'rcanvas'
  })
  if (Platform.OS === 'web') {
    return filtered
  }
  return filtered.filter(p => p.data.viewmode !== 'rcanvas')
}
