import { createAction } from '@reduxjs/toolkit'

export type ResetReduxStore = {
  includePersisted?: boolean
  exclude?: string[]
}
export const resetReduxStore = createAction(
  'resetReduxStore',
  (payload?: ResetReduxStore) => ({ payload }),
)
