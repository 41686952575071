import { useCallback, useRef, useState } from 'react'

export const useLongPress = (
  onLongPress: (e: React.MouseEvent) => void,
  onClick?: () => void,
  onMouseLeave?: (e: React.MouseEvent) => void,
  { shouldPreventDefault = true, delay = 300, stopPropagation = true } = {},
  onMouseUp?: (e: React.MouseEvent) => void,
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false)
  const timeout = useRef<any>(null)
  const target = useRef<any>(null)
  const start = useCallback(
    (event: React.MouseEvent) => {
      stopPropagation && event.stopPropagation()
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault],
  )

  const clear = useCallback(
    (event: React.MouseEvent, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick?.()
      setLongPressTriggered(false)
      if (shouldPreventDefault && target.current) {
        target?.current?.removeEventListener('touchend', preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  )

  return {
    onMouseDown: (e: any) => {
      start(e)
    },
    onTouchStart: (e: any) => start(e),
    onMouseUp: (e: any) => {
      onMouseUp?.(e)
      clear(e)
    },
    onMouseLeave: (e: any) => {
      onMouseLeave?.(e)
      clear(e, false)
    },
    onTouchEnd: (e: any) => clear(e),
  }
}

const isTouchEvent = (event: React.MouseEvent) => 'touches' in event

const preventDefault = (event: any) => {
  if (!isTouchEvent(event)) {
    return
  }

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}
