import type { FormInstance } from 'antd/es/form/Form'
import copy from 'copy-to-clipboard'
import type { FC } from 'react'

import IconSuccess from '../../../assets/icons/ic_success.svg'
import css from './ModalUserListObserver.module.scss'

import { Form } from '#HACK_FOR_RN_ONLY/src/components/base/Form'
import { FormItem } from '#HACK_FOR_RN_ONLY/src/components/base/FormItem'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { WarmnessInput } from '../../../components/widget/WarmnessBeamInput'
import type { SessionRoleName } from '../../../context/gql/codegen'
import { S } from '../../store'
import { ModalInviteUserList } from './ModalInviteUserList'

type Props = {
  data: PeerUI[]
  handleInviteEmail: (type: 'emailObserver' | 'emailMainRoom') => void
  form: FormInstance<any>
  disableInput?: boolean
}
export const ModalUserListObserver: FC<Props> = ({
  data,
  handleInviteEmail,
  form,
  disableInput,
}) => {
  const { id: userId } = S.profile.profile
  const handleCopy = (role: SessionRoleName) => {
    const params = new URLSearchParams(location.search)
    copy(
      location.origin.replace(/#.*/, '') +
        `/?r=${params.get('r')}&invite=true&role=${role}`,
    )
    ToastService.success({
      content: 'Copied',
      icon: <img src={IconSuccess} />,
      style: {
        bottom: 0,
      },
    })
  }
  return (
    <>
      <div
        className={css.ModalFieldEmail}
        style={{ background: S.local.warmnessColor }}
      >
        <Form
          form={form}
          onFinish={handleInviteEmail}
          requiredMark
          layout='vertical'
          className={css.FormInviteObserver}
        >
          <FormItem
            name='emailObserver'
            rules={[
              {
                pattern:
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                message: 'Not type of email',
              },
              {
                required: true,
                message: 'Please enter the invitee email',
              },
            ]}
            className={css.ModalFormItem}
            validateTrigger='onSubmit'
          >
            <WarmnessInput
              placeholder='Invite by email'
              className={css.ModalFormItem}
              autoFocus
              disabled={disableInput}
            />
          </FormItem>
        </Form>
        <WarmnessGrayButton
          className={css.ActionBtn}
          onClick={() => handleInviteEmail('emailObserver')}
        >
          <Icon icon='icon_chat' size={16} />
          Send
        </WarmnessGrayButton>
        <WarmnessGrayButton
          onClick={() => handleCopy('Observer')}
          className={css.ActionBtn}
        >
          <Icon icon='icon_share_link' size={16} />
          Copy
        </WarmnessGrayButton>
      </div>
      <div className={css.ModalUserListWrapper}>
        <ModalInviteUserList data={data} userId={userId} />
      </div>
    </>
  )
}
