import { io } from 'socket.io-client'

import { reactServerOrigin } from '../../../config'
import { getWaitingUsers } from '../../../context/actions/studio/getWaitingUsers'
import { onStudioEnter } from '../../../context/actions/studio/onStudioEnter'
import { S } from '../../../context/store'
import { onKickout } from '../../../context/store/studio/utils2.web'

enum SessionListenerEvent {
  UPDATE_WAITING_USERS = 'updateWaitingUsers',
  ANSWER_JOIN_REQUEST = 'answerJoinRequest',
}

type AnswerJoinRequestRes = {
  isAccepted: boolean
}

export const createSocketInstance = () => {
  const $ = S.webrtc
  const params = new URLSearchParams(location.search)
  const id = params.get('r')
  const sessionSocket = io(`${reactServerOrigin}/session`, {
    path: '/api/socket-io',
    query: {
      sessionId: id,
      userId: S.profile.profile.id,
    },
    forceNew: true,
  })

  sessionSocket.on('connect', () => {
    $.socketInfo.connected = true
    $.socketInfo.id = sessionSocket.id
  })
  sessionSocket.on('disconnect', () => {
    $.socketInfo.connected = false
  })
  sessionSocket.on('connect_error', err => {})
  sessionSocket.on(SessionListenerEvent.UPDATE_WAITING_USERS, () => {
    getWaitingUsers()
  })
  sessionSocket.on(
    SessionListenerEvent.ANSWER_JOIN_REQUEST,
    (data: AnswerJoinRequestRes) => {
      const { isAccepted } = data
      if (isAccepted) {
        const currentUrl = new URL(location.href)
        currentUrl.searchParams.delete('invite')
        currentUrl.searchParams.delete('role')
        history.replaceState({}, document.title, currentUrl.href)
        onStudioEnter()
        return
      }
      onKickout()
    },
  )
}
