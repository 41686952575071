import { notification } from 'antd'
import type { ArgsProps } from 'antd/lib/notification'

import css from './style.module.scss'

import { Icon } from '../../base/Icon'

const initConfig: Partial<ArgsProps> = {
  duration: 0,
  placement: 'bottomLeft',
  className: css.container,
  closeIcon: <Icon icon='icon_close_popup' size={12} className={css.close} />,
}
class NotificationService {
  static success = (config: Partial<ArgsProps>) => {
    notification.success({
      type: 'success',
      ...initConfig,
      ...config,
      message: <h4 className={css.message}>{config.message}</h4>,
    })
  }
  static error = (config: Omit<ArgsProps, 'type'>) => {
    notification.error({
      type: 'error',
      ...initConfig,
      ...config,
      message: <h4 className={css.message}>{config.message}</h4>,
    })
  }
}

export { NotificationService }
