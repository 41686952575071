import { Modal } from 'antd'
import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import clsx from 'clsx'
import { toArray } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { ulid } from 'ulidx'

import IconError from '../../../assets/icons/ic_error.svg'
import LoadingAttachment from '../../../assets/images/loading.png'
import css from './Composer.module.scss'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import {
  getExtOfFile,
  getTotalSizeOfFiles,
  modifyFiles,
} from '../../../context/store/studio/utils'
import { CONSTANTS } from '../../../styles/Constants'
import type { TFileUploadHook } from '../../../utils/useCustom'
import { useFileUpload } from '../../../utils/useCustom'
import { ChatMentionsInput } from './MentionInput'
import type { TModifyFiles, TUploadedFile, TUploadFile } from './RightContent'

type TFileEdit = {
  progress?: number
  status?: number
  cancelSource?: CancelTokenSource
}

export const Composer = observer(
  ({
    onChange,
    onSendClick,
    value,
    onAttachment,
    isSentMessage,
  }: {
    onChange(v: string): void
    onSendClick(value: string): void
    value: string
    onAttachment: (files: TUploadedFile[]) => void
    isSentMessage: boolean
  }) => {
    const refInput = useRef<HTMLInputElement>(null)
    const [isOutFile, setOutFile] = useState(false)
    const [filesUpload, setFilesUpload] = useState<TModifyFiles>({})
    const uploadFile = useRef<HTMLInputElement>(null)
    const [listFilesUploaded, setFilesUploaded] = useState<TUploadedFile[]>([])
    const [isStartUpload, setStartUpload] = useState<boolean>(false)
    const [isCompleteUpload, setComplete] = useState<boolean>(false)
    const filesUploadRef = useRef<TUploadedFile[]>([])
    const statusFileUpload: TFileUploadHook = useFileUpload(filesUpload)
    const checkSizeAndTotalFile = (files: FileList) => {
      const currentTotal = toArray(filesUpload).length + files.length
      if (currentTotal > 5) {
        return false
      }
      const currentSizeUploaded = toArray(filesUpload).reduce(
        (total, item) => total + item.file.size,
        0,
      )
      const totalSize = currentSizeUploaded + getTotalSizeOfFiles(files)
      if (totalSize >= 1024 * 1024 * 25) {
        return false
      }
      return true
    }
    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files) {
        return
      }

      if (!checkSizeAndTotalFile(files)) {
        setOutFile(true)
        return
      }

      onUploadFile(files)
      e.target.value = ''
    }
    const isSvg = Boolean(
      Object.values(filesUpload).find(i => i.type === 'svg'),
    )

    const onUploadFile = (files: FileList) => {
      const modifyFile: TModifyFiles = modifyFiles(filesUpload, files)
      setFilesUpload(prev => ({
        ...prev,
        ...modifyFile,
      }))
      if (!isStartUpload) {
        setComplete(false)
        setStartUpload(true)
      }
    }
    const handleSetStatusFile = (id: number, data: TFileEdit) => {
      setFilesUpload(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          ...data,
        },
      }))
    }
    const countProgress = (progress: ProgressEvent, id: number) => {
      const { loaded, total } = progress
      const percentageProgress = Math.floor((loaded / total) * 100)
      handleSetStatusFile(id, {
        progress: percentageProgress,
        status: percentageProgress === 100 ? 1 : 0,
      })
    }
    const handleUploadFile = async (files: TUploadFile[]) => {
      if (files.length > 0) {
        files.forEach(async (file: TUploadFile) => {
          const item = file.file
          const formData = new FormData()
          formData.append('file', item)
          try {
            const result = await S.webrtc.callApiUpload(formData, 'chat', {
              cancelToken: file.cancelSource.token,
              onUploadProgress: (progress: ProgressEvent) =>
                countProgress(progress, file.id),
            })
            if (!result) {
              handleSetStatusFile(file.id, {
                progress: 100,
                status: 0,
              })
              return
            }
            if (!axios.isCancel(result)) {
              if (result instanceof Error) {
                handleSetStatusFile(file.id, {
                  progress: 100,
                  status: 0,
                })
              }
            } else {
              handleSetStatusFile(file.id, {
                progress: 100,
                status: -1,
              })
            }

            const list = {
              id: result.id,
              value:
                typeof result?.resource.url === 'string'
                  ? result?.resource.url
                  : '',
              mediaType: file.type,
              name: item.name,
              size: result?.resource.fileSize || 0,
            }
            filesUploadRef.current = [...filesUploadRef.current, list]
            setFilesUploaded(filesUploadRef.current)
          } catch (err) {
            console.error('handleUploadFile error:')
            console.error(err)
            handleSetStatusFile(file.id, {
              progress: 100,
              status: 0,
            })
            filesUploadRef.current = [
              ...filesUploadRef.current,
              {
                id: ulid(),
                value: '',
                mediaType: file.type,
                name: item.name,
                size: item.size,
              },
            ]
            setFilesUploaded(filesUploadRef.current)
          }
        })
      }
    }
    const handleRetry = (id: number) => {
      const fileRetry = toArray(filesUpload).find(file => file.id === id)
      if (fileRetry) {
        handleUploadFile([
          {
            ...fileRetry,
            progress: 0,
            status: 0,
          },
        ])
      }
    }
    const handleDeleteFile = (id: number, index: number) => {
      const newFileUpload = filesUpload
      const fileName = newFileUpload[id].file.name
      delete newFileUpload[id]
      const newFileUploaded = listFilesUploaded.filter(
        item => item.name !== fileName,
      )

      setFilesUpload(newFileUpload)
      setFilesUploaded(newFileUploaded)
      onAttachment(newFileUploaded)
    }
    const handleClearFile = () => {
      setFilesUpload({})
      filesUploadRef.current = []
      setFilesUploaded([])
    }
    const handleSelectFile = () => {
      if (uploadFile?.current) {
        uploadFile.current.click()
      }
    }
    const itemIsLoadingExit = toArray(filesUpload).find(
      i => i.progress < 100 && i.progress >= 0,
    )
    const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        if (!itemIsLoadingExit && !isSvg) {
          onSendClick(value)
        }
      }
    }

    useEffect(() => {
      if (isStartUpload) {
        const fileToUpload = toArray(filesUpload).filter(
          file => file.progress === 0,
        )
        handleUploadFile(fileToUpload)
      }
    }, [isStartUpload])

    useEffect(() => {
      if (
        listFilesUploaded.length === statusFileUpload.totalFile &&
        listFilesUploaded.length > 0 &&
        !isCompleteUpload
      ) {
        setComplete(true)
        setStartUpload(false)
        onAttachment(listFilesUploaded)
      }
    }, [listFilesUploaded, statusFileUpload.totalFile, isCompleteUpload])

    useEffect(() => {
      if (isSentMessage) {
        handleClearFile()
      }
    }, [isSentMessage])

    useEffect(() => {
      if (refInput.current && S.webrtc.selectedRightTabBarKey === '5') {
        refInput.current?.focus()
      }
    }, [S.webrtc.selectedRightTabBarKey])

    return (
      <div className={css.ComposerContainer}>
        <div className={css.ComposerAttachments}>
          {toArray(filesUpload).map((item, index) => (
            <AttachmentItem
              item={item}
              onRetry={handleRetry}
              key={index}
              onDelete={(filedId: number) => handleDeleteFile(filedId, index)}
            />
          ))}
        </div>
        {statusFileUpload.totalFileError > 0 && (
          <span className={css.AttachmentTxtError}>
            Some of your attachment got error
          </span>
        )}
        {isSvg && (
          <span className={css.AttachmentTxtError}>Can't upload svg file</span>
        )}
        <div className={css.ComposerContainerInput}>
          <div className={css.ComposerContainerInputRight}>
            <div className={css.ComposerInputContainer}>
              <ChatMentionsInput
                value={value}
                onKeyDown={onKeyDown}
                onChange={onChange}
              />
            </div>
            <input
              className={css.ComposerInputFile}
              accept='image/*'
              type='file'
              value=''
              onChange={handleChange}
              ref={uploadFile}
              multiple
            />
            <span
              className={css.ComposerAttachmentIcon}
              onClick={handleSelectFile}
            >
              <Icon icon='icon_attachment' size={18} />
            </span>
          </div>

          <span
            className={css.ComposerSendIcon}
            onClick={() =>
              itemIsLoadingExit || isSvg ? undefined : onSendClick(value)
            }
          >
            <Icon icon='icon_chat' size={18} />
          </span>
        </div>

        {
          <ModalOutFile
            visible={isOutFile}
            onCancel={() => setOutFile(false)}
          />
        }
      </div>
    )
  },
)

const AttachmentItem = ({
  item,
  onRetry,
  onDelete,
}: {
  item: TUploadFile
  onRetry?: (fileId: number) => void
  onDelete?: (fileId: number) => void
}) => {
  const handleRetry = () => {
    onRetry && onRetry(item.id)
  }
  const handleDelete = () => {
    onDelete && onDelete(item.id)
  }
  const isLoading = item.progress < 100 && item.progress >= 0
  const isFinish = !isLoading && item.status === 1
  const isFail = !isLoading && item.status === 0
  return (
    <div
      className={clsx({
        [css.ComposerAttachmentItem]: true,
        [css.composerAttachmentItemLoading]: !isFinish,
      })}
    >
      {isLoading && (
        <img
          className={css.ComposerLoadingAttachment}
          src={LoadingAttachment}
        />
      )}
      {isFail && (
        <>
          <span className={css.ComposerRetry} onClick={handleRetry}>
            Retry
          </span>
          <img className={css.ComposerErrorAttachment} src={IconError} />
        </>
      )}
      <span className={css.ComposerDeleteAttachment} onClick={handleDelete}>
        <Icon icon='icon_close_popup' size={10} />
      </span>
      {item.type === 'image' ? (
        <img
          className={css.ComposerAttachmentImg}
          src={URL.createObjectURL(item.file)}
        />
      ) : (
        <div className={css.ComposerAttachmentFile}>
          <Icon icon='icon_attachment' size={14} />
          <span className={css.ComposerAttachmentFileExt}>
            {getExtOfFile(item.file.name)}
          </span>
        </div>
      )}
    </div>
  )
}

const ModalOutFile = observer(
  ({ visible, onCancel }: { visible: boolean; onCancel: () => void }) => (
    <Modal
      className={css.WrapperModalOutFile}
      width={350}
      visible={visible}
      centered
      maskStyle={{ background: CONSTANTS.basicColor.maskBackgroundModal }}
      footer={null}
      onCancel={onCancel}
      closeIcon={
        <Icon icon='icon_close_popup' size={11} className={css.ModalOutClose} />
      }
      bodyStyle={{
        backgroundColor: 'transparent',
        padding: 0,
      }}
    >
      <div className={css.ModalContainer}>
        <div className={css.ModalOutContent}>
          <div className={css.ModalOutTitle}>Failed to upload file</div>
          <div className={css.ModalOutText}>
            Sorry for your inconvenience, we're currently supporting uploading{' '}
            <strong>5 files</strong> with maximum <strong>25MB</strong> at once
          </div>
          <button className={css.ModalOutBtn} onClick={onCancel}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  ),
)
