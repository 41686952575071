import clsx from 'clsx'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './BottomItemControl.module.scss'

import { Icon } from '../../../components/base/Icon'
import { Duration } from '../../../components/composites/Duration'
import { ModalService } from '../../../components/Modal/Modal'
import { CustomPopover } from '../../../components/widget/CustomPopover'
import { ToastService } from '../../../components/widget/Toast'
import {
  onStartRecord,
  onStopRecord,
} from '../../../context/actions/mixerActions'
import { ModalOutStorage } from '../../../context/shared/components/ModalOutStorage'
import { S } from '../../../context/store'
import { formatBytes } from '../../../context/store/studio/utils'
import { isOutOfStorage } from '../../../utils/plan'

interface Props {
  label: string
  onClick: () => void
  icon: ReactNode
  containerDropdown?: ReactNode
  badgeNumber?: number
  isLightTheme?: boolean
}

export const BottomItemControl = observer(
  ({
    icon,
    containerDropdown,
    label,
    onClick,
    badgeNumber = 0,
    isLightTheme,
  }: Props) => {
    const { warmnessColorHover, warmnessColorGray } = S.local
    return (
      <div
        style={{
          alignItems: badgeNumber ? 'start' : 'baseline',
          // @ts-ignore
          '--background12': warmnessColorHover,
          '--grey5': warmnessColorGray,
        }}
        onClick={onClick}
        className={clsx({
          [css.WrapperItemControl]: true,
          [css.wrapperItemControlBadge]: badgeNumber > 0,
        })}
      >
        <div className={css.ItemControlIcon}>
          <div className={css.IconMain}>{icon}</div>{' '}
          <div className={css.ItemControlText}>{label}</div>
        </div>
        {badgeNumber > 0 && (
          <span className={css.ItemBadegeNumber}>
            {badgeNumber > 99 ? '99+' : badgeNumber}
          </span>
        )}
        {containerDropdown && (
          <CustomPopover
            isLightTheme={isLightTheme}
            placement='bottomRight'
            content={containerDropdown}
          >
            <div onClick={e => e.stopPropagation()} className={css.IconArrow}>
              <Icon icon='icon_arrow_left' size={8} />
            </div>
          </CustomPopover>
        )}
      </div>
    )
  },
)

export const BottomItemRecord = observer(() => {
  const [labelTime, setLabelTime] = useState('Record')
  const [isHover, setHover] = useState(false)
  const currentSub = S.webrtc.plan
  const { isRecording, isOnlyViewStream, mainRecordingOis } = S.webrtc
  const [loadingStopRecord, setLoadingStopRecord] = useState(false)
  const isFirstRecord = useRef(false)
  const recordingProgress = mainRecordingOis?.recording
  const { warmnessColorHover } = S.local
  const outOfStorage = isOutOfStorage(
    Number(currentSub?.totalStorage || 0),
    Number(currentSub?.totalStorageUsed || 0),
  )
  const isEnterprise = currentSub?.plan === 'Enterprise'
  const { detail } = S.webrtc
  if (!detail) {
    return null
  }

  const handleToggleRecord = async () => {
    if (loadingStopRecord) {
      return
    }
    if (!isRecording) {
      if (outOfStorage && !isEnterprise) {
        ModalService.show(ModalOutStorage)
        return
      }
      await onStartRecord(detail.id)
    } else {
      await onStopRecord(detail.id)
      // already show recording finish event in WebrtcStore
      // no need to toast here
    }
  }

  const handleKeyPressToggleRecord = (e: KeyboardEvent) => {
    if (isOnlyViewStream) {
      return
    }
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      return
    }
    let tagName: string = get(e.target, 'tagName')
    if (typeof tagName !== 'string') {
      tagName = ''
    }
    tagName = tagName.toLowerCase()
    if (['input', 'textarea'].includes(tagName)) {
      return
    }
    if (e.key === ' ' && ['select', 'button'].includes(tagName)) {
      return
    }
    const keyShortCut = e.key.toLowerCase()
    e.preventDefault()
    if (keyShortCut === 'r') {
      handleToggleRecord()
    }
  }

  const renderLabelTime = async () => {
    if (!recordingProgress) {
      setLabelTime(' / Loading...')
      return
    }
    const storageTotal = recordingProgress.totalStorage
    const totalStorageUsed = recordingProgress.totalStorageUsed
    if (!isOutOfStorage(storageTotal, totalStorageUsed) || isEnterprise) {
      setLabelTime(` / ${formatBytes(recordingProgress.fileSize)}`)
    } else {
      await onStopRecord(detail.id)
      // already show recording finish event in WebrtcStore
      // no need to toast here
      ModalService.show(ModalOutStorage)
    }
  }
  useEffect(() => {
    if (isRecording && !isFirstRecord.current) {
      isFirstRecord.current = true
    }
    if (!isRecording && isFirstRecord.current) {
      setLoadingStopRecord(true)
      setTimeout(() => {
        setLoadingStopRecord(false)
      }, 5000)
    }
  }, [isRecording])
  useEffect(() => {
    if (isRecording) {
      renderLabelTime()
    }
  }, [recordingProgress, isRecording])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressToggleRecord)
    return () => {
      window.removeEventListener('keydown', handleKeyPressToggleRecord)
    }
  }, [isRecording])
  useEffect(() => {
    if (mainRecordingOis?.publishingAt) {
      ToastService.success({ content: 'Recording started' })
    }
  }, [mainRecordingOis?.publishingAt])
  return (
    <div
      style={{
        // @ts-ignore
        '--background12': warmnessColorHover,
      }}
      className={clsx({
        [css.WrapperItemControl]: true,
      })}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <div
        className={css.ItemControlIconOverlap}
        onClick={handleToggleRecord}
      />
      <div className={css.ItemControlIcon}>
        <div className={css.IconMain}>
          <Icon
            icon='icon_menu_record'
            className={clsx({
              [css.IconRecord]: true,
              [css.iconRecordActive]: isRecording,
              [css.iconBottomFontActive]: isRecording,
            })}
            size={20}
          />
        </div>
        <div
          className={css.ItemControlChildOverLap}
          onClick={handleToggleRecord}
        />
        <div className={css.ItemControlText}>
          {isRecording ? (
            isHover ? (
              'Stop Record'
            ) : (
              <>
                <Duration from={mainRecordingOis?.publishingAt} />
                {labelTime}
              </>
            )
          ) : loadingStopRecord ? (
            'Loading...'
          ) : (
            'Record'
          )}
        </div>
      </div>
    </div>
  )
})
