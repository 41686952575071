import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import clsx from 'clsx'
import { clamp } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { RgbColor } from 'react-colorful'
import { HexColorInput, HexColorPicker } from 'react-colorful'

import css from './GraphicSolidPicker.module.scss'

import { BeamCheckbox } from '../../../components/base/Checkbox'
import { BeamText } from '../../../components/base/Text'
import { S } from '../../../context/store'
import { hexToRgb, rgbToHex } from '../../../context/store/studio/color'
import { CONSTANTS } from '../../../styles/Constants'

type TProps = {
  selectedColor: string
  onChangeColor: (color: string) => void
  hasTransparent?: boolean
  isLightTheme?: boolean
}

export const SolidPicker = observer(
  ({
    selectedColor,
    onChangeColor,
    hasTransparent = false,
    isLightTheme,
  }: TProps) => {
    const [isTransparent, setTransparent] = useState(
      selectedColor === 'transparent',
    )
    const [prevColor, setPrevColor] = useState(selectedColor)
    const { warmnessColor } = S.local
    const handleChangeColor = (color: string) => {
      if (!isTransparent) {
        onChangeColor(color)
      }
    }

    const handleToggleTransparent = (e: CheckboxChangeEvent) => {
      setTransparent(e.target.checked)
      setPrevColor(selectedColor)
      onChangeColor(e.target.checked ? 'transparent' : prevColor)
    }

    return (
      <div data-theme={isLightTheme ? 'light' : 'dark'}>
        {hasTransparent && (
          <div className={css.optionTransparent}>
            <BeamCheckbox
              checked={isTransparent}
              onChange={handleToggleTransparent}
            ></BeamCheckbox>
            <BeamText color={CONSTANTS.text.level2}>Transparent</BeamText>
          </div>
        )}
        <div className={css.ColorPickerContent}>
          <HexColorPicker
            color={selectedColor}
            onChange={handleChangeColor}
            className={clsx({
              [css.ColorPickerContentDisabled]: selectedColor === 'transparent',
            })}
          />
        </div>
        <div
          style={{
            // @ts-ignore
            '--background31': isLightTheme ? warmnessColor : undefined,
          }}
          className={css.ColorPickerInput}
        >
          <ColorInputRGB
            onChangeColor={handleChangeColor}
            selectedColor={selectedColor}
            disabled={selectedColor === 'transparent'}
            isLightTheme={isLightTheme}
          />
        </div>
      </div>
    )
  },
)

const ColorInputRGB = ({
  selectedColor,
  onChangeColor,
  disabled,
  isLightTheme,
}: {
  selectedColor: string
  onChangeColor: (color: string) => void
  disabled: boolean
  isLightTheme?: boolean
}) => {
  const [rgbColor, setRgbColor] = useState<RgbColor>(hexToRgb(selectedColor))

  const onChangeRGBColor = (e: ChangeEvent<HTMLInputElement>) => {
    const format = e.target.value.replace(/([^0-9]+)/gi, '')
    let value: number = format === '' ? 0 : +format
    value = clamp(value, 0, 255)
    const newRBG = {
      ...rgbColor,
      [e.target.name]: value,
    }
    setRgbColor(newRBG)
    onChangeColor(rgbToHex(newRBG))
  }

  useEffect(() => {
    setRgbColor(hexToRgb(selectedColor))
  }, [selectedColor])

  return (
    <>
      <div className={css.ColorPickerHexInput}>
        <label
          className={clsx(css.LabelPicker, {
            [css.LabelPickerLight]: isLightTheme,
          })}
        >
          Hex
        </label>
        <HexColorInput
          prefixed
          onChange={onChangeColor}
          color={disabled ? '' : selectedColor}
          disabled={disabled}
        />
      </div>
      <div className={css.ColorPickerItemInput}>
        <InputRGB
          type='r'
          isLightTheme={isLightTheme}
          value={rgbColor.r}
          onChange={onChangeRGBColor}
          disabled={disabled}
        />
      </div>
      <div className={css.ColorPickerItemInput}>
        <InputRGB
          type='g'
          isLightTheme={isLightTheme}
          value={rgbColor.g}
          onChange={onChangeRGBColor}
          disabled={disabled}
        />
      </div>
      <div className={css.ColorPickerItemInput}>
        <InputRGB
          type='b'
          isLightTheme={isLightTheme}
          value={rgbColor.b}
          onChange={onChangeRGBColor}
          disabled={disabled}
        />
      </div>
    </>
  )
}
const InputRGB = ({
  value,
  onChange,
  type,
  disabled = false,
  isLightTheme,
}: {
  value: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  type: string
  disabled?: boolean
  isLightTheme?: boolean
}) => (
  <>
    <label
      className={clsx(css.LabelPicker, {
        [css.LabelPickerLight]: isLightTheme,
      })}
    >
      {type}
    </label>
    <input
      className={css.InputColor}
      maxLength={3}
      name={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  </>
)
