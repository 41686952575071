import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import { S } from '../../../context/store'
import { playTrack } from '../utils/playTrack'

export const LiveStreamAudio = observer(() => (
  //  const { audioUrl } = S.webrtc
  //  if (!audioUrl) {
  //    return null
  //  }
  <AudioPlayer />
))

const AudioPlayer = observer(() => {
  const { getMediaControlTracks, backgroundMediaData, volumeBackground } =
    S.webrtc
  const vref = useRef<HTMLVideoElement>(null)
  const audioTrack = getMediaControlTracks(
    backgroundMediaData?.id ?? '',
  ).audioTrack
  useEffect(() => {
    if (!audioTrack) {
      return
    }
    playTrack(vref.current, audioTrack)
  }, [audioTrack])

  useEffect(() => {
    if (!vref.current) {
      return
    }
    vref.current.volume = volumeBackground / 100
  }, [volumeBackground])

  const audio = (
    <audio ref={vref} onLoadedData={() => {}} onLoadStart={() => {}} />
  )
  if (!audio || !backgroundMediaData.id) {
    return null
  }

  return <div className='check audio'>{audio}</div>
})
