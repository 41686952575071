import { observer } from 'mobx-react-lite'

import 'react-circular-progressbar/dist/styles.css'
import css from './ModalRTMPorSRT.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import { Form } from '#HACK_FOR_RN_ONLY/src/components/base/Form'
import { FormItem } from '#HACK_FOR_RN_ONLY/src/components/base/FormItem'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { WarmnessDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessDiv'
import { AppSelect } from '#HACK_FOR_RN_ONLY/src/components/widget/Select'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessInput } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessBeamInput'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { useGqlCreateInput } from '../../../context/gql/codegen'
import { S } from '../../store'

export enum InputType {
  RTMP = 'RTMP',
  SRT = 'SRT',
}
const inputTypes = [
  { label: ' SRT(Listener)', value: InputType.SRT },
  { label: ' RTMP(Push)', value: InputType.RTMP },
]
export const UseRTMPorSRT = observer(
  ({
    isWarmTheme = true,
  }: {
    onCancel: () => void
    context: string
    isWarmTheme?: boolean
  }) => {
    const { warmnessColor, warmnessColorHover } = S.local
    const { detail } = S.webrtc
    const [state, createInput] = useGqlCreateInput()
    const [form] = Form.useForm<{ streamType: string; name: string }>()
    const onClose = () => {
      ModalService.hide()
    }

    const handleSubmit = async () => {
      try {
        if (!detail?.id) {
          return
        }
        const result = await form.validateFields()
        await createInput({
          data: {
            name: result.name,
            type: result.streamType,
            sessionId: detail?.id,
          },
        })
        ToastService.success({ content: 'Link is being initiated...' })
        ModalService.hide()
      } catch (e) {}
    }

    return (
      <ModalContent
        style={{
          // @ts-ignore
          '--background-modal': isWarmTheme ? warmnessColor : undefined,
        }}
        containerClassName={css.ModalContainer}
      >
        <WarmnessDiv className={css.container}>
          <div className={css.TitleBox}>
            <div className={css.IconWifi}>
              <Icon icon='icon_menu_live' size={20} />
            </div>
            <div>
              <h6 className={css.Title}>Connect by RTMP/SRT</h6>
            </div>
          </div>
          <div className={css.Mt24}>
            <Form
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
              layout='vertical'
            >
              <FormItem
                className={css.FormItem}
                name='streamType'
                initialValue={InputType.RTMP}
                label={<p className={css.FormLabel}>Stream Type</p>}
                rules={[
                  {
                    required: true,
                    message: <div>This field is missing</div>,
                  },
                ]}
              >
                <AppSelect
                  style={{
                    // @ts-ignore
                    '--background2': warmnessColor,
                  }}
                  className={css.formSelect}
                  getPopupContainer={trigger =>
                    trigger.parentElement ?? trigger
                  }
                >
                  {inputTypes.map(i => (
                    <AppSelect.Option
                      key={i.value}
                      style={{
                        // @ts-ignore
                        '--background40': warmnessColor,
                        '--background39': warmnessColorHover,
                      }}
                      value={i.value}
                      className={css.formOption}
                    >
                      {i.label}
                    </AppSelect.Option>
                  ))}
                </AppSelect>
              </FormItem>
              <FormItem
                className={css.FormItem}
                name='name'
                label={<p className={css.FormLabel}>Name</p>}
                rules={[
                  {
                    required: true,
                    message: <div>This field is missing</div>,
                  },
                ]}
              >
                <WarmnessInput className={css.Input} />
              </FormItem>
              <div className={css.Action}>
                <WarmnessGrayButton onClick={onClose} className={css.BtnClose}>
                  Cancel
                </WarmnessGrayButton>
                <Button loading={state.fetching} htmlType='submit'>
                  Connect
                </Button>
              </div>
            </Form>
          </div>
        </WarmnessDiv>
      </ModalContent>
    )
  },
)
