// import { ToastService } from '../../../components/widget/Toast'
import type { SearchResourceInInsightEngineQueryVariables } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { checkAndUploadResource } from './checkAndUpdateResource'
import { getDownloadingResources } from './getDownloadingResources'
// import { checkAndUploadResource } from './checkAndUpdateResource'

export const getResourcesInsightEngine = async ({
  order,
  insightEngineId,
  filter,
  page,
  isUpdateStore = true,
}: SearchResourceInInsightEngineQueryVariables & {
  isUpdateStore?: boolean
}) => {
  if (isUpdateStore) {
    S.insightEngine.mediaLoading = true
  }
  const { data } = await reduxStore.context.gql.searchResourceInInsightEngine(
    {
      order,
      insightEngineId,
      filter,
      page,
    },
    { requestPolicy: 'network-only' },
  )
  const { searchResourceInInsightEngine } = data ?? {}
  if (isUpdateStore) {
    if (searchResourceInInsightEngine) {
      S.insightEngine.resources = searchResourceInInsightEngine
      checkAndUploadResource()
    }
  }
  const downloadingResources = await getDownloadingResources()
  if (downloadingResources) {
    downloadingResources.forEach(dr => {
      const selectedResourceIndex = S.insightEngine.resources.findIndex(
        r => r.resourceId === dr.resourceId,
      )
      Object.assign(S.insightEngine.resources[selectedResourceIndex], {
        isDownloading: true,
      })
    })
  }
  if (isUpdateStore) {
    S.insightEngine.mediaLoading = false
  }

  return searchResourceInInsightEngine ?? []
}
