import { Quill } from 'react-quill'

import { reduxStore } from '../../../context/redux'
import { getAvatarColor } from '../../../utils/avatar'

const Embed = Quill.import('blots/embed')

class AddImage extends Embed {
  hoverHandler: any

  constructor(scroll: any, node: any) {
    super(scroll, node)
    this.clickHandler = null
    this.hoverHandler = null
    this.mounted = false
  }

  static create = (data: any) => {
    const node = super.create()
    const image = document.createElement('img')
    const avatarContainer = document.createElement('div')
    const avatar = document.createElement('div')
    const name = document.createElement('label')
    node.id = data.id
    image.src = data.imgSrc
    const haveOnlyImage =
      typeof data.isOnlyImg === 'string'
        ? data.isOnlyImg === 'true'
        : data.isOnlyImg
    if (haveOnlyImage) {
      if (data.imgSrc) {
        image.className = 'image-custom-only'
        node.appendChild(image)
      } else {
        const nameFirstChar = data.title ? data.title[0] : '.'
        avatarContainer.className = 'avatar-container'
        avatar.className = 'avatar'
        name.className = 'name'
        name.textContent = nameFirstChar
        avatar.style.background = getAvatarColor(nameFirstChar)
        avatar.appendChild(name)
        avatarContainer.appendChild(avatar)
        node.appendChild(avatarContainer)
      }

      node.style.padding = '0px'
      node.className = 'addImage-only'
    } else {
      if (data.imgSrc) {
        image.className = 'image-custom-only'
        node.appendChild(image)
      } else {
        const nameFirstChar = data.title ? data.title[0] : '.'
        avatarContainer.className = 'avatar-container-small'
        avatar.className = 'avatar-small'
        name.className = 'name-small'
        name.textContent = nameFirstChar
        avatar.style.background = getAvatarColor(nameFirstChar)
        avatar.appendChild(name)
        avatarContainer.appendChild(avatar)
        node.appendChild(avatarContainer)
      }
      image.className = 'image-custom'
      const content = document.createElement('div')
      content.className = 'custom-image-content'
      const title = document.createElement('span')
      title.innerHTML = data.title
      title.className = 'custom-image-title'
      const time = document.createElement('div')
      time.className = 'custom-image-time'
      const timeIcon = document.createElement('span')
      timeIcon.className = 'custom-image-time-icon'
      const timeTitle = document.createElement('span')
      time.appendChild(timeIcon)
      time.appendChild(timeTitle)
      timeTitle.innerHTML = data.time
      content.appendChild(title)
      content.appendChild(time)
      node.appendChild(content)
    }

    const removeButton = document.createElement('div')
    removeButton.className = 'remove-button'
    removeButton.onclick = e => {
      e.stopPropagation()
      if (data.imgSrc) {
        reduxStore.context.gql.deleteResourceInSession({ ids: [data.id] })
      }

      document?.getElementById(data.id)?.remove()
    }
    node.appendChild(removeButton)
    return AddImage.setDataValues(node, data)
  }

  static setDataValues = (element: any, data: any) => {
    const domNode = element
    Object.keys(data).forEach(key => {
      domNode.dataset[key] = data[key]
    })
    return domNode
  }

  static value = (domNode: any) => domNode.dataset

  attach = () => {
    super.attach()

    if (!this.mounted) {
      this.mounted = true
      this.clickHandler = this.getClickHandler()
      this.hoverHandler = this.getHoverHandler()

      this.domNode.addEventListener('click', this.clickHandler, false)
      this.domNode.addEventListener('mouseenter', this.hoverHandler, false)
    }
  }

  detach = () => {
    super.detach()
    this.mounted = false
    if (this.clickHandler) {
      this.domNode.removeEventListener('click', this.clickHandler)
      this.clickHandler = null
    }
  }
  // deleteAt() {
  //   return true
  // }

  getClickHandler = () => (e: any) => {
    const event = this.buildEvent('addImage-clicked', e)
    window.dispatchEvent(event)
    e.preventDefault()
  }

  getHoverHandler = () => (e: any) => {
    const event = this.buildEvent('addImage-hovered', e)
    window.dispatchEvent(event)
    e.preventDefault()
  }

  buildEvent = (name: any, e: any) => {
    const event = new Event(name, {
      bubbles: true,
      cancelable: true,
    }) as any
    event.value = Object.assign({}, this.domNode.dataset)
    event.event = e
    return event
  }
  // @ts-ignore
  hoverHandler
}

AddImage.blotName = 'addImage'
AddImage.tagName = 'div'
AddImage.className = 'addImage'

Quill.register(AddImage)
