const alphabetPosition = (text: string) => {
  let result = ''
  for (let i = 0; i < text.length; i++) {
    var code = text.toUpperCase().charCodeAt(i)
    if (code > 64 && code < 91) {
      result += code - 64 + ' '
    }
  }

  return result.slice(0, result.length - 1)
}
export const getAvatarColor = (char: string) => {
  const avatarColors = [
    '#3FBE5B',
    '#1ABC9C',
    '#3498DB',
    '#315EFF',
    '#9B59B6',
    '#EC87BF',
    '#9BA37E',
    '#B49255',
    '#E74C3C',
    '#8F4128',
  ]
  const position = alphabetPosition(char)
  if (!position) {
    return avatarColors[0]
  }
  return avatarColors[Number(position) % avatarColors.length]
}
