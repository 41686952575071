import { Form as AntForm } from 'antd'
import clsx from 'clsx'
import type { PropsWithChildren } from 'react'

import css from './style.module.scss'

import type { FormProps } from './type'

export const Form = (props: PropsWithChildren<FormProps>) => {
  const { children, className, ...formProps } = props
  return (
    <>
      <AntForm className={clsx(css.Form, className)} {...formProps}>
        {children}
      </AntForm>{' '}
    </>
  )
}

Form.useForm = AntForm.useForm
Form.Item = AntForm.Item
Form.useWatch = AntForm.useWatch
