import { SyncOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useAudio } from 'react-use'
import { saveSync } from 'save-file'

import css from './AudioPlayer.module.scss'

import { BeamSlider } from '#HACK_FOR_RN_ONLY/src/components/base/Slider'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { S } from '../../../context/store'
import {
  covertDurationToMinutes,
  formatFileName,
  getNameOfUrl,
} from '../../../context/store/studio/utils'
import { controlMediaPrefix } from '../../../context/store/studio/WebrtcStore'
import { CONSTANTS } from '../../../styles/Constants'
import { updateLocalMediaControlData } from '../actions/updateMediaControlDataClient'
import { playTrack } from '../utils/playTrack'

type TAudioPlayer = {
  url: string
  autoPlay?: boolean
  name?: string
  defaultVolume?: number
  onChangeVolume?: (volume: number) => void
  onDelete?: (e: React.MouseEvent<HTMLDivElement>) => void
  onAir?: boolean
  onTurnOffAir?: () => void
  idAudioPlaying: string
}
export const AudioPlayerOnline = observer(
  ({
    url,
    autoPlay = false,
    defaultVolume = 1,
    // onChangeVolume,
    onDelete,
    onAir = false,
    name = '',
    onTurnOffAir,
    idAudioPlaying,
  }: TAudioPlayer) => {
    const aref = useRef<HTMLVideoElement>(null)
    const [audio, state] = useAudio(<audio ref={aref} />)
    const {
      mediaControlData,
      viewmode,
      getMediaControlTracks,
      mediaControlOnAction,
      mediaControlOnStateChange,
    } = S.webrtc

    const audioControl = mediaControlData.find(
      item =>
        item.mediaId.replace(controlMediaPrefix[viewmode], '') ===
          idAudioPlaying && item.from === viewmode,
    )
    const [volumeSeeking, setVolumeSeeking] = useState(false)
    const [currentVolume, setCurrentVolume] = useState(audioControl?.volume)

    const [seeking, setSeeking] = useState(false)

    const [currentTime, setCurrentTime] = useState(audioControl?.currentTime)

    const audioTrack = getMediaControlTracks(idAudioPlaying).audioTrack
    useEffect(() => {
      if (!audioTrack) {
        return
      }
      playTrack(aref.current, audioTrack)
    }, [audioTrack])
    const audioCurrentTime =
      audioControl?.seeking || seeking ? currentTime : audioControl?.currentTime
    const durationValue = audioControl?.duration ?? 0
    const volumeValue = audioControl?.muted
      ? 0
      : (audioControl?.volumeSeeking || volumeSeeking
          ? currentVolume
          : audioControl?.volume) ?? 0

    const [downloading, setDownloading] = useState(false)
    const onChangeMuted = () => {
      if (audioControl?.id) {
        mediaControlOnAction(audioControl?.id, {
          type: 'volume',
          muted: !audioControl?.muted,
        })
      }
    }
    useEffect(() => {
      S.webrtc.volumeBackground = volumeValue
      S.webrtc.updateEmitAndSaveSettings({
        volumeBackground: volumeValue,
      })
    }, [volumeValue])

    const onChangeVolume = (v: number) => {
      setVolumeSeeking(true)
      setCurrentVolume(v)
      if (!audioControl?.id) {
        return
      }
      mediaControlOnStateChange(audioControl?.id, { volumeSeeking: true })
      updateLocalMediaControlData(audioControl?.id, {
        volumeSeeking: true,
        volume: v,
      })
    }

    const handleAfterChangeVolume = (v: number) => {
      setVolumeSeeking(false)
      if (audioControl?.id) {
        mediaControlOnAction(audioControl?.id, { type: 'volume', volume: v })
      }
    }

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
      onDelete && onDelete(e)
    }

    const handleDownload = async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      try {
        setDownloading(true)
        await saveSync(url, `${name}.${url.split('.').pop()}`)
        setDownloading(false)
      } catch {
        setDownloading(false)
        ToastService.error({ content: 'Save file failed', duration: 2 })
      }
    }

    const onToggleAudio = e => {
      e.stopPropagation()
      if (audioControl?.id) {
        mediaControlOnAction(audioControl.id, {
          type: audioControl.paused ? 'play' : 'pause',
        })
      }
    }

    const onChangeRepeat = e => {
      e.stopPropagation()
      const newRepeat = !audioControl?.repeat

      mediaControlOnAction(audioControl?.id ?? '', {
        type: 'repeat',
        repeat: newRepeat,
      })
    }

    const onChangeSeek = (v: number) => {
      setSeeking(true)
      setCurrentTime(v)
    }
    const handleAfterChangeSeek = (v: number) => {
      setSeeking(false)
      if (audioControl?.duration) {
        const newValuePercent = (v / audioControl?.duration) * 100
        mediaControlOnAction(audioControl?.id ?? '', {
          type: 'seek',
          seek: newValuePercent,
        })
      }
    }
    const audioIconType =
      audioControl?.muted || volumeValue <= 0.01
        ? 'icon_volumn_silent'
        : volumeValue < 50
          ? 'icon_volumn_medium'
          : 'icon_volumn_max'
    const { isLightTheme } = S.local
    return (
      <>
        {!onAir && audio}
        <div className={css.AudioPlayerItemLink} />
        <div className={css.AudioPlayerTop}>
          <span className={css.AudioPlayerName}>
            <Icon
              icon='icon_media_music'
              size={13}
              className={css.AudioPlayerTopIcon}
            />
            {name !== ''
              ? formatFileName(name, 15)
              : formatFileName(getNameOfUrl(url), 15)}
          </span>
          {!onAir && (
            <span className={css.AudioPlayerDuration}>
              {`${covertDurationToMinutes(
                state.time,
              )}/${covertDurationToMinutes(durationValue)}`}
            </span>
          )}
          {onAir && (
            <div
              className={css.ButtonOnAir}
              onClick={() => onTurnOffAir && onTurnOffAir()}
            >
              <Icon icon='icon_triangle' size={13} />
              OnAir
            </div>
          )}
        </div>
        <div
          onClick={e => e.stopPropagation()}
          className={css.AudioPlayerMainProgress}
        >
          <BeamSlider
            className={clsx({
              [css.AudioPlayerProgress]: true,
              [css.audioPlayerProgressOnAir]: onAir,
            })}
            min={0}
            max={Math.floor(durationValue)}
            step={1}
            value={audioCurrentTime}
            tooltipVisible={false}
            onChange={onChangeSeek}
            onAfterChange={handleAfterChangeSeek}
            trackStyle={{
              backgroundColor: isLightTheme ? '#2656C9' : '#085AE1',
              borderRadius: 2,
            }}
            handleStyle={{
              backgroundColor: '#4098FF',
              boxShadow: isLightTheme
                ? '0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                : 'none',
              border: 'none',
              width: 16,
              height: 16,
            }}
            // onAir={onAir}
          />
          {onAir && (
            <span className={css.AudioPlayerDuration}>
              {`${covertDurationToMinutes(audioCurrentTime)}/${covertDurationToMinutes(
                durationValue,
              )}`}
            </span>
          )}
        </div>
        <div className={css.AudioPlayerBottom}>
          <div
            onClick={e => e.stopPropagation()}
            className={css.AudioPlayerVolumeInfo}
          >
            <Icon
              className={css.AudioPlayerMute}
              onClick={onChangeMuted}
              icon={audioIconType}
              size={14}
            />
            <BeamSlider
              min={0}
              max={100}
              className={css.AudioPlayerVolumeSwitch}
              step={1}
              value={volumeValue}
              tooltipVisible={false}
              onChange={onChangeVolume}
              onAfterChange={handleAfterChangeVolume}
              trackStyle={{
                backgroundColor: S.local.isLightTheme
                  ? '#2656C9'
                  : CONSTANTS.level.lighter1,
                borderRadius: 4,
              }}
              handleStyle={{
                backgroundColor: S.local.isLightTheme
                  ? '#ffffff'
                  : CONSTANTS.text.level1,
                border: 'none',
                boxShadow: S.local.isLightTheme
                  ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                  : 'none',
                width: 14,
                height: 14,
              }}
            />
          </div>
          <span
            className={css.AudioPlayerPlay}
            // playing={!state.paused}
            onClick={onToggleAudio}
          >
            <Icon
              icon={audioControl?.paused ? 'icon_play' : 'icon_pause'}
              size={18}
            />
          </span>
          <div className={css.AudioPlayerCustomAction}>
            <span className={css.AudioPlayerIcon} onClick={handleDelete}>
              <Icon icon='icon_delete' size={13} />
            </span>
            {downloading ? (
              <SyncOutlined
                className={clsx(['animation-spin', css.AudioPlayerIcon])}
              />
            ) : (
              <span className={css.AudioPlayerIcon} onClick={handleDownload}>
                <Icon icon='icon_download' size={13} />
              </span>
            )}
            <span
              className={clsx({
                [css.AudioPlayerRepeat]: true,
                [css.audioPlayerRepeated]: audioControl?.repeat,
              })}
              // repeated={isRepeat}
              onClick={onChangeRepeat}
            >
              <Icon icon='icon_repeat' size={18} />
            </span>
          </div>
        </div>
      </>
    )
  },
)
