import { createComponent } from './logic'

export const Duration = createComponent(({ mmStr, ssStr }) => {
  if (!mmStr || !ssStr) {
    return <>00:00</>
  }
  return (
    <>
      {mmStr}:{ssStr}
    </>
  )
})
