import { observer } from 'mobx-react-lite'

import css from './ModalDowngradePlan.module.scss'

import { Button } from '../../../components/base/Button'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import { useGqlCancelSubscription } from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { reloadCurrentPlan } from '../../../context/store/shared/reloadCurrentPlan'
import { catchPromiseError } from '../../../utils/catchPromiseError'
import { getDateTime } from '../utils/getDateTime'

export const ModalDowngradePlan = observer(() => {
  const {
    profile: { currentOrgId },
    currentPlan,
  } = S.profile
  const { plan, subscription } = currentPlan || {}
  const date = getDateTime(subscription?.nextInvoiceAt)

  const [{ fetching }, cancelPlan] = useGqlCancelSubscription()

  const handleCancel = () => {
    catchPromiseError(cancelPlan({ orgId: currentOrgId }), () => {
      ModalService.hide()
      ToastService.success({
        content: `Plan cancelled. You can still use Beam until ${date}`,
      })
      reloadCurrentPlan()
    })
  }
  return (
    <ModalContent containerClassName={css.CancelPlan}>
      <div className={css.Main}>
        <span className={css.Title}>Downgrade Plan</span>
        <div className={css.Content}>
          <span className={css.Text}>
            You're using{' '}
            <span className={css.Highlight}>
              {subscription?.interval} {plan} Subscription License
            </span>{' '}
            which will
            <br />
            expire on {date}
          </span>
          <span className={css.Text}>
            Your downgrade request will take place at the beginning of the next
            billing circle. Are you sure?
          </span>
        </div>
        <div className={css.Actions}>
          <Button
            className={css.ButtonYes}
            containerType='grey'
            onClick={() => ModalService.hide()}
          >
            No
          </Button>
          <Button
            containerType='primary'
            className={css.ButtonClose}
            onClick={handleCancel}
            loading={fetching}
          >
            Downgrade
          </Button>
        </div>
      </div>
    </ModalContent>
  )
})
