import { Checkbox } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import { getSizeCheckbox } from './getSizeCheckbox'
import type { AppTextProps, TCheckboxContainerProps } from './type'
import { ECheckboxSize } from './type'

export const BeamCheckbox: FC<AppTextProps> = props => {
  const { isLightTheme, containerProps, ...checkboxProps } = props
  const defaultContainerProps: TCheckboxContainerProps = {
    size: containerProps?.size ?? ECheckboxSize.M,
  }
  return (
    <div
      {...defaultContainerProps}
      style={{
        // @ts-ignore
        '--wAntCheckboxInner': getSizeCheckbox(defaultContainerProps.size),
        '--hAntCheckboxInner': getSizeCheckbox(defaultContainerProps.size),
        '--lAntCheckboxInnerAfter': `${
          defaultContainerProps.size === ECheckboxSize.S ? 4 : 6
        }px`,
      }}
      className={clsx(css.CheckboxContainer, containerProps?.className, {
        [css.CheckboxContainerLight]: isLightTheme,
      })}
    >
      <Checkbox {...checkboxProps}></Checkbox>
    </div>
  )
}
