import { Radio } from 'antd'
import { forwardRef } from 'react'

import type { AppRadioGroupProps } from './type'

export const BeamRadiGroup = forwardRef<HTMLDivElement, AppRadioGroupProps>(
  (props: AppRadioGroupProps, ref) => {
    const { containerProps, ...radiGroupProps } = props
    return (
      <div {...containerProps}>
        <Radio.Group ref={ref} {...radiGroupProps}></Radio.Group>
      </div>
    )
  },
)
