import { S } from '../../../context/store'

export const removeTitle = (id: string) => {
  const $ = S.webrtc
  const listData = S.webrtc.titles
  const index = listData.findIndex(item => item.id === id)
  if (index >= 0) {
    const newListData = [
      ...listData.slice(0, index),
      ...listData.slice(index + 1),
    ]
    const sls = $.selectedListLayouts.map(l => ({
      ...l,
      slots: l.slots.filter(i => !i.titleId || i.titleId !== id),
    }))
    const titlesByLayout = $.titlesOnAir[$.selectedIndexLayout]
    $.titlesOnAir[$.selectedIndexLayout] =
      titlesByLayout?.filter(t => t !== id) ?? []
    S.layout.titlesOnEdit = newListData
    S.webrtc.updateEmitAndSaveSettings({
      titles: newListData,
      selectedListLayouts: sls,
    })
  }
}
