import type { ColumnsType } from 'antd/lib/table'
import { useState } from 'react'

import css from './SystemFlags.module.scss'

import { BeamText } from '../../../components/base/Text'
import { AdminPanelListView } from '../../../components/templates/AdminPanel'
import { DEBOUNCE_TIME, LIMIT } from '../../../config'
import { useGqlSearchSystemFlag } from '../../../context/gql/codegen'
import { CONSTANTS } from '../../../styles/Constants'
import { useDebounceValue } from '../../../utils/useDebounceValue'
import { SystemFlagValueItem } from './SystemFlagValueItem'

export enum SystemFlagKey {
  // plan
  FreemiumFreeMinutes = 'FreemiumFreeMinutes',
  FreemiumFreeStorage = 'FreemiumFreeStorage',
  FreemiumMaxParticipants = 'FreemiumMaxParticipants',
  ProfessionalFreeMinutes = 'ProfessionalFreeMinutes',
  ProfessionalFreeStorage = 'ProfessionalFreeStorage',
  ProfessionalMaxParticipants = 'ProfessionalMaxParticipants',
  // referral
  ReferralRegisterBonusMinutes = 'ReferralRegisterBonusMinutes',
  ReferralRegisterBonusStorage = 'ReferralRegisterBonusStorage',
  ReferralPaymentBonusMinutes = 'ReferralPaymentBonusMinutes',
  ReferralPaymentBonusStorage = 'ReferralPaymentBonusStorage',
  // lb
  MediasoupCpu = 'MediasoupCpu',
  MediasoupMemory = 'MediasoupMemory',
  MixerCpu = 'MixerCpu',
  MixerMemory = 'MixerMemory',
  AmsCpu = 'AmsCpu',
  AmsMemory = 'AmsMemory',
  // ffmpeg
  MixerAudioDelay = 'MixerAudioDelay',
  // recall ai
  MeetingBotName = 'MeetingBotName',
  MeetingBotAvatar = 'MeetingBotAvatar',
}

export const SystemFlags = () => {
  const [searchTerm, setSearchTerm, searchDebounce] = useDebounceValue<string>(
    DEBOUNCE_TIME,
    '',
  )
  const [query, setQuery] = useState<{
    page: { limit: number; offset: number }
  }>({
    page: {
      limit: 10,
      offset: 0,
    },
  })
  const [systemFlagsData, refetchSystemFlags] = useGqlSearchSystemFlag({
    variables: {
      ...query,
      filter: {
        key_iLike: searchDebounce ? `%${searchDebounce}%` : undefined,
        key_notIn: [
          SystemFlagKey.FreemiumFreeMinutes,
          SystemFlagKey.FreemiumFreeStorage,
          SystemFlagKey.ProfessionalFreeMinutes,
          SystemFlagKey.ProfessionalFreeStorage,
          SystemFlagKey.MeetingBotName,
          SystemFlagKey.MeetingBotAvatar,
        ],
      },
    },
  })
  const data = systemFlagsData.data?.searchSystemFlag ?? []
  const count = systemFlagsData.data?.countSystemFlag ?? 0

  const onRefresh = () => {
    refetchSystemFlags({ requestPolicy: 'network-only' })
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Flag',
      dataIndex: 'key',
      key: 'key',
      render: v => (
        <BeamText
          color={CONSTANTS.text.level2}
          size={'small'}
          className={css.FlagText}
        >
          {v}
        </BeamText>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (v, r) => (
        <SystemFlagValueItem value={v} submitCallback={onRefresh} item={r} />
      ),
    },
  ]

  return (
    <AdminPanelListView
      tableProps={{
        columns,
        dataSource: data,
        pagination: false,
        rowKey: 'id',
        loading: systemFlagsData.fetching,
      }}
      paginationProps={{
        total: count,
        pageSize: LIMIT,
        current: Math.floor((query.page.offset + 10) / 10),
        onChange: page =>
          setQuery({
            ...query,
            page: { ...query.page, offset: (page - 1) * 10 },
          }),
      }}
      searchTerm={searchTerm}
      title='System Flags'
      refreshing={systemFlagsData.fetching}
      onChangeSearch={value => setSearchTerm(value)}
      onRefresh={onRefresh}
    />
  )
}
