import { toArray } from 'lodash'
import type { ChangeEvent } from 'react'
import { useMemo, useRef, useState } from 'react'

import { ModalService } from '../components/Modal/Modal'
import { NotificationService } from '../components/widget/Notification'
import { ModalOutStorage } from '../context/shared/components/ModalOutStorage'
import { NotificationOutStorage } from '../context/shared/components/NotificationOutStorage'
import { S } from '../context/store'
import {
  covertGBtoBytes,
  getTotalSizeOfFiles,
  modifyFiles,
} from '../context/store/studio/utils'
import type { TMediaItem } from '../context/store/studio/WebrtcStore'
import type {
  TModifyFiles,
  TUploadFile,
} from '../pages/Studio/components/RightContent'

type Process = {
  [key: string]: number
}

export const useUploadFile = ({
  onHandleUploadFile,
  onUploadSuccess,
  ref,
}: {
  onHandleUploadFile?: (
    file: TUploadFile,
  ) => Promise<TMediaItem | null | undefined>
  onUploadSuccess?: (items: TMediaItem[]) => void
  ref: React.MutableRefObject<HTMLInputElement | null>
}) => {
  const totalFileSize = useRef(0)

  const [totalFiles, setTotalFiles] = useState(0)
  const [statusUpload, setStatusUpload] = useState(false)
  const [percentProgress, setProgress] = useState<Process | null>(null)

  const onOpenUpload = () => {
    ref.current?.click()
  }

  const caculatePercent = useMemo(() => {
    let value = 0
    if (!percentProgress) {
      return value
    }

    for (const key in percentProgress) {
      value += percentProgress[key]
    }

    return parseInt((value / totalFileSize.current) * 100 + '')
  }, [percentProgress])

  const handleCheckUploadStorage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) {
      return
    }
    const currentSub = S.webrtc.plan || S.insightEngine.plan
    const storageInUse = Number(currentSub?.totalStorageUsed || '0')
    const storageTotal = Number(currentSub?.totalStorage || '0')
    const storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
    const totalSize = getTotalSizeOfFiles(files)
    const isEnterprise = currentSub?.plan === 'Enterprise'
    const modifyFile: TModifyFiles = modifyFiles({}, files)
    const listArray = toArray(modifyFile)

    if (totalSize >= storageAvailable && !isEnterprise) {
      ModalService.show(ModalOutStorage)
      return
    }
    handleChange(listArray)
  }

  const handleResetUpload = () => {
    setProgress(null)
    setStatusUpload(false)

    totalFileSize.current = 0
    setTotalFiles(0)
  }

  const handleChange = async (files: TUploadFile[]) => {
    if (!files || files.length === 0) {
      return
    }
    const currentSub = S.webrtc.plan || S.insightEngine.plan
    const isEnterprise = currentSub?.plan === 'Enterprise'
    const storageInUse = Number(currentSub?.totalStorageUsed || '0')
    const storageTotal = Number(currentSub?.totalStorage || '0')
    let storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
    const exceedFiles: string[] = []

    setTotalFiles(files.length)
    setStatusUpload(true)
    let resultUploaded: any = []

    files.forEach(f => (totalFileSize.current += f.file.size))
    for (let index = 0; index < files.length; index++) {
      const file = files[index]

      if (storageAvailable > file.file.size || isEnterprise) {
        const result = await onHandleUploadFile?.(file)
        storageAvailable = storageAvailable - file.file.size
        if (result) {
          resultUploaded = [...resultUploaded, result]
        }
      } else {
        exceedFiles.push(file.file.name)
      }
    }
    if (exceedFiles.length > 0) {
      handleResetUpload()
      NotificationService.error({
        message: 'Failed to upload file',
        description: <NotificationOutStorage files={exceedFiles} />,
      })
    }

    if (resultUploaded.length === files.length && !!files.length) {
      onUploadSuccess?.(resultUploaded)
      handleResetUpload()
    }
  }

  const countProgress = (progressEvent: ProgressEvent, id: number) => {
    setProgress(prev => ({
      ...prev,
      ...{ [id]: progressEvent.loaded },
    }))
  }

  return {
    statusUpload,
    setStatusUpload,
    onOpenUpload,
    totalFiles,
    countProgress,
    percentProgress: caculatePercent,
    handleCheckUploadStorage,
  }
}
