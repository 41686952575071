import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import css from './Media.module.scss'

import { ToastService } from '../../../components/widget/Toast'
import { DragSelection } from '../../../context/shared/components/DragSelection'
import { S } from '../../../context/store'
import { DRAG_MEDIA_ADD_PLAYLIST } from '../../../context/store/studio/dragTypes'
import { useDragScroll } from '../../../utils/useDrag'
import { MediaAudios } from './MediaAudios'
import { DropPlaylist } from './MediaDropPlaylist'
import { Header } from './MediaHeader'
import { MediaImages } from './MediaImages'
import { MediaPlaylists } from './MediaPlaylists'
import { MediaRecords } from './MediaRecords'
import { TabBarMedia } from './MediaTabbar'
import { MediaVideos } from './MediaVideos'

export const Media = observer(() => {
  const refScroll = useRef<HTMLDivElement>(null)
  const { onScrollStart, onScrollStop } = useDragScroll(refScroll)
  const selectedTab = S.webrtc.selectedMediaTab

  const onSelectionChange = (selected: string[]) => {
    S.webrtc.updateDataOfStore({
      mediaSelectionId: selected,
    })
  }

  const handleChangeMediaTab = (tab: string) => {
    S.webrtc.updateDataOfStore({
      selectedMediaTab: tab,
    })
  }

  const handleUpdateSettings = (type?: string) => {
    S.webrtc.updateEmitAndSaveSettings(undefined, type)
  }
  const isDraggingItem = DRAG_MEDIA_ADD_PLAYLIST.includes(
    S.webrtc.typeDraggingItem,
  )

  const showMessage = (status: string, type: string) => {
    if (status === 'success') {
      ToastService.success({ content: `Deleted ${type}` })
    } else {
      ToastService.error({ content: `Failed to delete ${type}` })
    }
  }
  const handleShowMessageRemoveMedia = () => {
    switch (S.webrtc.statusUpdateSettings.type) {
      case 'deleteVideo':
        showMessage(S.webrtc.statusUpdateSettings.status, 'video')
        break
      case 'deleteImage':
        showMessage(S.webrtc.statusUpdateSettings.status, 'image')
        break
      case 'deleteAudio':
        showMessage(S.webrtc.statusUpdateSettings.status, 'audio')
        break
      case 'deleteRecord':
        showMessage(S.webrtc.statusUpdateSettings.status, 'record')
        break
      default:
        break
    }
  }
  useEffect(() => {
    handleShowMessageRemoveMedia()
  }, [S.webrtc.statusUpdateSettings])
  useEffect(() => {
    if (isDraggingItem) {
      onScrollStart()
    } else {
      onScrollStop()
    }
  }, [S.webrtc.typeDraggingItem])

  useEffect(() => {
    S.webrtc.updateDataOfStore({
      mediaSelectionId: [],
    })
  }, [selectedTab])

  return (
    <>
      <div className={css.MediaContainer}>
        <div className={css.MediaHeaderFixTop}>
          <Header />
          {isDraggingItem && <DropPlaylist selectedTab={selectedTab} />}
          <TabBarMedia
            selectedTab={selectedTab}
            onSelectTab={handleChangeMediaTab}
            className={clsx({ [css.HideTabBarMedia]: isDraggingItem })}
          />
        </div>
        <div className={css.MediaListContainer} ref={refScroll}>
          <div
            className={clsx({
              [css.MediaListContent]: true,
              [css.mediaListContentSelected]: selectedTab === 'playlist',
            })}
          >
            <MediaPlaylists onUpdateSetting={handleUpdateSettings} />
          </div>
          <div
            className={clsx({
              [css.MediaListContent]: true,
              [css.mediaListContentSelected]: selectedTab === 'video',
            })}
          >
            <MediaVideos onUpdateSetting={handleUpdateSettings} />
          </div>
          <div
            className={clsx({
              [css.MediaListContent]: true,
              [css.mediaListContentSelected]: selectedTab === 'image',
            })}
          >
            <MediaImages onUpdateSetting={handleUpdateSettings} />
          </div>
          <div
            className={clsx({
              [css.MediaListContent]: true,
              [css.mediaListContentSelected]: selectedTab === 'audio',
            })}
          >
            <MediaAudios onUpdateSetting={handleUpdateSettings} />
          </div>
          <div
            className={clsx({
              [css.MediaListContent]: true,
              [css.mediaListContentSelected]: selectedTab === 'record',
            })}
          >
            <MediaRecords onUpdateSetting={handleUpdateSettings} />
          </div>
        </div>
      </div>
      {S.webrtc.typeDraggingItem === '' && (
        <DragSelection
          refScroll={refScroll}
          onSelection={onSelectionChange}
          dragContainerSelector='.right-sidebar'
          selectableTargets={['.media']}
        />
      )}
    </>
  )
})
