import type { Moment } from 'moment'

import type { IconName } from '#HACK_FOR_RN_ONLY/src/components/base/Icon/type'

import type {
  SearchSessionQuery,
  SearchTagQuery,
  SearchUserFilterQuery,
} from '../../gql/codegen'
import { serverTime } from './serverTime'
import React from 'react'

type TCalendarItem = {
  icon: IconName
  name: string
  type: ECalendarType
  disableFill?: boolean
  isSelected: boolean
}

export enum ECalendarType {
  ALL = 'all',
  BEINGS = 'Default',
  GOOGLE = 'google_calendar',
  MICROSOFT_TEAMS = 'microsoft_outlook',
}
export type SessionInfo =
  SearchSessionQuery['searchSession'] extends Array<infer T> ? T : never

export class SessionManagementStore {
  isLoadingAddTag: boolean
  loading: boolean = false
  sessionList: SearchSessionQuery['searchSession'] = []
  currentCalendar: Moment = serverTime.moment()
  refs: { [key in string]: React.RefObject<any> | undefined }
  calendarData: TCalendarItem[] = [
    {
      icon: 'Empty',
      name: 'All Calendar',
      type: ECalendarType.ALL,
      disableFill: true,
      isSelected: true,
    },
    {
      icon: 'Colored',
      name: 'Beings Calendar',
      type: ECalendarType.BEINGS,
      isSelected: true,
    },
    {
      icon: 'Google',
      name: 'Google Calendar',
      type: ECalendarType.GOOGLE,
      isSelected: true,
    },
    {
      icon: 'MicrosoftTeams',
      name: 'Microsoft Teams ',
      type: ECalendarType.MICROSOFT_TEAMS,
      isSelected: true,
    },
  ]

  tagsFilter: {
    __typename?: 'Tag' | undefined
    name: string
    id: string
    type: string
    isCheckbox?: boolean
    isSelected?: boolean
  }[] = []

  tags: SearchTagQuery['searchTag'] = []

  userFilter: SearchUserFilterQuery['searchUserFilter'] = []

  userFilterInUse: {
    id: string
    name?: string
    email: string
    avatarUrl?: string
    isSelected: boolean
  }[] = []

  userFilterInUseRecent: {
    id: string
    name?: string
    email: string
    avatarUrl?: string
    isSelected: boolean
  }[] = []

  tagsSelectedId: string[] = []
  filtersSelectedId: string[] = []

  prevRecallBotId: string = ''

  onSelectCalendarFilter = (type: ECalendarType) => {
    if (type === ECalendarType.ALL) {
      const allValue = !this.calendarData.find(
        item => item.type === ECalendarType.ALL,
      )?.isSelected
      this.calendarData = this.calendarData.map(item => ({
        ...item,
        isSelected: allValue,
      }))
      return
    }
    this.calendarData = this.calendarData.map(calendar => {
      if (calendar.type === type) {
        return {
          ...calendar,
          isSelected: !calendar.isSelected,
        }
      }
      return calendar
    })

    if (this.calendarData.filter(cal => cal.isSelected).length < 4) {
      this.calendarData = this.calendarData.map(cal => {
        if (cal.type === ECalendarType.ALL) {
          return {
            ...cal,
            isSelected: false,
          }
        }
        return cal
      })
    }
  }

  onSelectTagFilter = (id: string) => {
    if (id === 'all') {
      const value = !this.tagsFilter.find(tag => tag.id === 'all')?.isSelected
      this.tagsFilter = this.tagsFilter.map(tag => ({
        ...tag,
        isSelected: value,
      }))
      this.setTagsSelectedId()
      return
    }
    this.tagsFilter = this.tagsFilter.map(tag => {
      if (tag.id === id) {
        return {
          ...tag,
          isSelected: !tag.isSelected,
        }
      }
      return tag
    })
    this.setTagsSelectedId()
  }

  onSelectParticipantFilter = (id: string) => {
    this.userFilterInUse = this.userFilterInUse.map(user => {
      if (user.id === id) {
        return {
          ...user,
          isSelected: !user.isSelected,
        }
      }
      return user
    })
    this.setFilterSelectedId()
    this.setFilterSelectedRecent()
  }

  setFilterSelectedId = () => {
    this.filtersSelectedId = this.userFilterInUse
      .filter(user => user.isSelected)
      .map(user => user.id)
  }

  setFilterSelectedRecent = () => {
    this.userFilterInUse.map(user => {
      const hadItem = this.userFilterInUseRecent?.find(
        item => item.id === user.id,
      )
      if (user.isSelected && !hadItem) {
        if (this.userFilterInUseRecent.length < 3) {
          this.userFilterInUseRecent.push(user)
        } else {
          this.userFilterInUseRecent.shift()
          this.userFilterInUseRecent.push(user)
        }
      }
    })
  }

  setTagsSelectedId = () =>
    (this.tagsSelectedId = this.tagsFilter
      ?.filter(tag => tag.id !== 'all' && tag.isSelected)
      .map(tag => tag.id))

  onDeleteSession = (id: string) => {
    this.sessionList = this.sessionList.filter(session => session.id !== id)
  }

  onAddSession = (session: SessionInfo) => {
    this.sessionList.push(session)
  }

  onUpdateSession = (session: SessionInfo) => {
    const sessionExist = this.sessionList.find(s => s.id === session.id)
    if (sessionExist) {
      Object.assign(sessionExist, session)
    }
  }

  onToggleAidaRecord = (sessionId: string, recallBotId: string | null) => {
    const sessionExist = this.sessionList.find(s => s.id === sessionId)
    if (!sessionExist) {
      return
    }
    Object.assign(sessionExist, { recallBotId })
  }

  onClearAllFilter = () => {
    this.tagsFilter = this.tagsFilter.map(tag => ({
      ...tag,
      isSelected: false,
    }))
    this.setTagsSelectedId()

    this.userFilterInUse = this.userFilterInUse.map(user => ({
      ...user,
      isSelected: false,
    }))
    this.setFilterSelectedId()

    this.calendarData = this.calendarData.map(cal => ({
      ...cal,
      isSelected: true,
    }))
  }
}
