import { InfoCircleOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import css from './LiveStreamLayouts.module.scss'

import { arrToMap } from '##/shared/arrToMap'
import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import type { IconName } from '#HACK_FOR_RN_ONLY/src/components/base/Icon/type'
import { BeamTooltip } from '#HACK_FOR_RN_ONLY/src/components/base/Tooltip'
import { BeamInput } from '#HACK_FOR_RN_ONLY/src/components/widget/Input'
import { setEnableEditLayout } from '#HACK_FOR_RN_ONLY/src/context/actions/layout/setEnableEditLayout'
import { subTitle } from '#HACK_FOR_RN_ONLY/src/context/actions/layout/subtitle'
import { ResizeOutsideApi } from '#HACK_FOR_RN_ONLY/src/context/shared/components/CustomLayoutReszieOutside'

import { Icon } from '../../../components/base/Icon'
import { BeamSwitch } from '../../../components/base/Switch'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { CustomPopover } from '../../../components/widget/CustomPopover'
import { ToastService } from '../../../components/widget/Toast'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { changeSlotEditing } from '../../../context/actions/layout/changeSlotEditing'
import { clearGroupSlot } from '../../../context/actions/layout/clearGroupSlot'
import {
  setSlotsEdit,
  setSlotsForLayoutEdit,
} from '../../../context/actions/layout/setSlotsToEdit'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'
import { getIdNumber, Helper } from '../../../context/store/studio/helper'
import { layoutNewAll } from '../../../context/store/studio/layoutNew'
import { vh, vw } from '../../../context/store/studio/WebrtcStore'
import { showError } from '../../../utils/showError'
import { useCheckClick } from '../../../utils/useCheckClick'
import { changeRatio } from '../actions/changeRatio'
import { updateLocalMediaControlData } from '../actions/updateMediaControlDataClient'
import { CustomLayout } from './LiveStreamCustomLayout'
import { EditLayoutButton } from './LiveStreamEditLayoutButton'

export const LiveStreamLayouts = observer(() => {
  const {
    selectedTitles,
    layoutSlotsShow,
    layoutMedias,
    selectedListLayouts,
    layoutFullStream,
    updateEmitAndSaveSettings,
    statusUpdateSettings,
    selectedLayoutByIndex,
    layoutTemplate,
    selectedIndexLayouts,
    mediaControlData,
    mediaControlOnAction,
  } = S.webrtc
  const {
    isLightTheme,
    warmnessColorThumbnail,
    warmnessColor,
    warmnessColorGray,
    warmnessColorHover,
    warmnessColorPressed,
  } = S.local
  const { enableEditLayout, slotsEdit } = S.layout
  const [showSetting, setShowSetting] = useState(false)

  const handleChangeLayout = (index: number) => {
    updateEmitAndSaveSettings(
      {
        selectedIndexLayout: index,
      },
      'livestreamLayout',
    )
    clearGroupSlot(selectedLayoutByIndex.defaultId + '')
    changeSlotEditing(-1, selectedLayoutByIndex.defaultId)
    const selectedLayoutByIndex1 = selectedListLayouts[index]
    const slots = slotsEdit[selectedLayoutByIndex1.defaultId]
      ? slotsEdit[selectedLayoutByIndex1.defaultId]
      : selectedLayoutByIndex1.slots
    const slotsE: ILayoutToEdit[] = slots.map(
      (s: any, i: number): ILayoutToEdit => ({
        height: s.height,
        width: s.width,
        left: s.left,
        top: s.top,
        radius: s.radius,
        zIndex: s.zIndex,
        id: s.id,
        enableEdit: false,
        parentId: selectedLayoutByIndex1.defaultId,
        inGroup: false,
        cropMode: s.cropMode || 'Fill',
        prevCropMode: s.prevCropMode || 'Fill',
        cropData: s.cropData,
        layoutIndex: index,
        titleId: s.titleId,
      }),
    )
    setSlotsForLayoutEdit(slotsE, selectedLayoutByIndex1.defaultId)
    mediaControlData.forEach(m => {
      const onAir = S.webrtc.isOnAirItemOfLayout(
        S.webrtc.layoutMedias,
        m.mediaId,
      )

      if (onAir && m?.currentTime !== 0 && m?.autoToBeginning) {
        mediaControlOnAction(m.id ?? '', { type: 'seek', seek: 0 })
        updateLocalMediaControlData(m.id, { currentTime: 0 })
      }
    })
  }
  const handleSelectListLayout = async (index: number, id: number) => {
    const idExit = selectedListLayouts.find(l => l.defaultId === id)
    const ids = Array.from(Array(20).keys())
    const newLayout = {
      ...layoutTemplate[id - 1],
    }
    newLayout.defaultId = idExit ? getIdNumber(id, ids) : id
    const newListLayout = [
      ...selectedListLayouts.slice(0, index),
      newLayout,
      ...selectedListLayouts.slice(index + 1),
    ]
    updateEmitAndSaveSettings(
      {
        selectedIndexLayout: index,
        selectedIndexLayouts: {
          ...selectedIndexLayouts,
          [index]: layoutTemplate[id - 1].defaultId,
        },
        selectedListLayouts: newListLayout,
        layoutMedias: {
          ...layoutMedias,
          [index]: {},
        },
        selectedTitles: {
          ...selectedTitles,
          [index]: {},
        },
        layoutSlotsShow: {
          ...layoutSlotsShow,
          [index]: newListLayout[index].slots.map((i: any, j: number) => j),
        },
        layoutFullStream: {
          ...layoutFullStream,
          [index]: -1,
        },
      },
      'livestreamLayout',
    )

    if (enableEditLayout) {
      const selectedLayoutByIndex2 = newListLayout[index]
      const slots = selectedLayoutByIndex2.slots
      const slotsE: ILayoutToEdit[] = slots.map(
        (s: any): ILayoutToEdit => ({
          height: s.height,
          width: s.width,
          left: s.left,
          top: s.top,
          radius: s.radius,
          zIndex: s.zIndex,
          id: s.id,
          enableEdit: false,
          parentId: selectedLayoutByIndex2.defaultId,
          inGroup: false,
          cropMode: s.cropMode || 'Fill',
          prevCropMode: s.prevCropMode || 'Fill',
          cropData: s.cropData,
          layoutIndex: index,
          titleId: s.titleId,
        }),
      )

      clearGroupSlot(selectedLayoutByIndex.defaultId + '')
      changeSlotEditing(-1, selectedLayoutByIndex.defaultId)
      setSlotsForLayoutEdit(slotsE, selectedLayoutByIndex2.defaultId)
    }
  }

  const handleChangeSettings = async (
    type: string,
    value: boolean | string,
  ) => {
    updateEmitAndSaveSettings({ [type]: value }, 'livestreamLayout')
  }

  useEffect(() => {
    if (statusUpdateSettings.type === 'livestreamLayout') {
      if (statusUpdateSettings.status === 'error') {
        showError('Failed to update layout of session')
      }
    }
  }, [statusUpdateSettings])

  return (
    <div className={css.LayoutList}>
      <div className={css.PopoverSetting}>
        <CustomPopover
          placement='top'
          visible={showSetting}
          isLightTheme={isLightTheme}
          content={
            <PopoverLayoutSetting
              isLightTheme={isLightTheme}
              onChangeSettings={handleChangeSettings}
              onClosePopover={() => setShowSetting(false)}
            />
          }
          onVisibleChange={(status: boolean) => setShowSetting(status)}
        >
          <div
            style={{
              // @ts-ignore
              '--background21': warmnessColorHover,
              '--background22': warmnessColorPressed,
            }}
            className={clsx({
              [css.PopoverSettingContent]: true,
              [css.popoverSettingContentSelected]: showSetting,
            })}
            // selected={showSetting}
          >
            <Icon
              className={css.PopoverIconSetting}
              icon='icon_setting'
              size={14}
            />
          </div>
        </CustomPopover>
      </div>
      {S.webrtc.selectedListLayouts
        .filter(l => typeof l === 'object' && l)
        .map((layout, idx) => (
          <LayoutItem
            isLightTheme={isLightTheme}
            layoutId={layout.defaultId}
            index={idx}
            key={`${layout.defaultId}-${idx}-${layout?.slots?.length}`}
            layout={layout}
            warmnessColorGray={warmnessColorGray}
            onSelectLayout={handleChangeLayout}
            warmnessColorThumbnail={warmnessColorThumbnail}
            warmnessColor={warmnessColor}
            warmnessColorHover={warmnessColorHover}
            warmnessColorPressed={warmnessColorPressed}
            onSelectListLayout={handleSelectListLayout}
            selected={idx === S.webrtc.selectedIndexLayout}
          />
        ))}
      <EditLayoutButton />
    </div>
  )
})

const LayoutItem = observer(
  ({
    layoutId,
    index,
    onSelectLayout,
    onSelectListLayout,
    selected,
    preview = false,
    isLightTheme,
    warmnessColorThumbnail,
    warmnessColor,
    warmnessColorGray,
    warmnessColorHover,
    warmnessColorPressed,
    layout,
  }: {
    layoutId: number
    index: number
    onSelectLayout?: (index: number, id: number) => void
    onSelectListLayout?: (index: number, id: number) => void
    selected: boolean
    preview?: boolean
    isLightTheme: boolean
    warmnessColorThumbnail?: string
    warmnessColor?: string
    warmnessColorGray?: string
    warmnessColorHover?: string
    warmnessColorPressed?: string
    layout: any
  }) => {
    const [statusLayout, setStatus] = useState(selected ? 'selected' : '')
    const [visibleLayout, setVisibileLayout] = useState(false)
    const { customRatio } = S.webrtc
    const eId = `layout-preview-${layoutId}`
    useCheckClick([eId], isInside => {
      if (isInside) {
        handleSelectLayout()
      }
    })
    const handleSelectLayout = () => {
      onSelectLayout && onSelectLayout(index, layoutId)
    }
    useEffect(() => {
      setStatus(selected ? 'selected' : '')
    }, [selected])
    const { slotsEdit } = S.layout
    const { titlesOnAir } = S.webrtc
    const layoutSelected = slotsEdit[layoutId]
    const slots =
      layoutSelected && layoutSelected.length === layout?.slots?.length
        ? layoutSelected
        : layout.slots
    return (
      <div
        className={clsx({
          [css.LayoutListItem]: true,
          [css.layoutListItemOpenSetting]: visibleLayout,
          [css.layoutListItemPreview]: preview,
        })}
        id={eId}
        // isOpenSetting={visibleLayout}
        // preview={preview}
        onMouseOver={() => {
          if (selected) {
            return
          }
          setStatus('hover')
        }}
        onMouseLeave={() => {
          if (selected) {
            setStatus('selected')
          } else {
            setStatus('')
          }
        }}
      >
        <div
          style={{
            // @ts-ignore
            '--background-layout-small': warmnessColorThumbnail,
          }}
          className={css.PopoverLayoutItem}
          key={index}
        >
          <CustomLayout
            isSmall
            layout={{
              id: layoutId + '',
              width: 49.5 * customRatio,
              height: 49.5,
              className: clsx({
                [css.LayoutContainer]: true,
                [css.LayoutContainerSelected]: statusLayout === 'selected',
              }),
              slots: slots
                .filter(s =>
                  s.titleId
                    ? titlesOnAir[index]?.some(id => id === s.titleId)
                    : true,
                )
                .map((v: any, j: number) => {
                  const isShow =
                    v.titleId &&
                    titlesOnAir[index]?.some(id => id === v.titleId)
                  return {
                    id: `${index}-${j}`,
                    left:
                      customRatio >= 1 || v.titleId
                        ? (v.left * 49.5 * customRatio) / vw
                        : ((v.top * 49.5) / vh) * customRatio,
                    top:
                      customRatio >= 1 || v.titleId
                        ? (v.top * 49.5) / vh
                        : (v.left * 49.5 * customRatio) / vw / customRatio,
                    width:
                      customRatio >= 1 || v.titleId
                        ? (v.width * 49.5 * customRatio) / vw
                        : ((v.height * 49.5) / vh) * customRatio,
                    height:
                      customRatio >= 1 || v.titleId
                        ? (v.height * 49.5) / vh
                        : (v.width * 49.5 * customRatio) / vw / customRatio,
                    radius: v.radius,
                    key: Helper.generateGuid(),
                    showControl: false,
                    children:
                      isShow || !v.titleId ? (
                        <div className={css.layoutIcon} />
                      ) : null,
                  }
                }),
            }}
          />
        </div>
        {!preview && (
          <CustomPopover
            placement='top'
            visible={visibleLayout}
            style={isLightTheme ? { background: warmnessColor } : undefined}
            isLightTheme={isLightTheme}
            content={
              <PopoverListLayout
                index={index}
                isLightTheme={isLightTheme}
                warmnessColorThumbnail={warmnessColorThumbnail}
                currentIdLayout={layoutId}
                onSelectListLayout={(id: number) =>
                  onSelectListLayout?.(index, id)
                }
              />
            }
            onVisibleChange={(status: boolean) => setVisibileLayout(status)}
          >
            <span
              style={{
                background: warmnessColorGray,
                // @ts-ignore
                '--background20': warmnessColorHover,
                '--background19': warmnessColorPressed,
              }}
              onClick={e => {
                e.stopPropagation()
              }}
              className={css.LayoutIconEdit}
            >
              <Icon icon='icon_pencil' size={9} />
            </span>
          </CustomPopover>
        )}
      </div>
    )
  },
)

const PopoverListLayout = observer(
  ({
    index,
    currentIdLayout,
    onSelectListLayout,
    isLightTheme,
    warmnessColorThumbnail,
  }: {
    index: number
    currentIdLayout: number
    onSelectListLayout?: (id: number) => void
    isLightTheme: boolean
    warmnessColorThumbnail?: string
  }) => {
    const handleChangeLayout = (layoutId: number) => {
      onSelectListLayout && onSelectListLayout(layoutId)
    }
    const {
      layoutTemplate,
      updateLayoutTemplate,
      selectedListLayouts,
      selectedIndexLayouts,
    } = S.webrtc
    const { studioSettings } = S.profile.profile
    const layoutTemplateOrigin = [...layoutTemplate]
    const { customRatio } = S.webrtc
    const addLayout = async () => {
      try {
        const ids = layoutTemplate.map(l => l.defaultId)
        const newId = Math.floor(Math.random() * (layoutTemplate.length + 2))
        const newLayout = {
          ...selectedListLayouts[index],
          defaultId: getIdNumber(newId, ids),
        }
        const ls = [...layoutTemplate, newLayout]
        const studioSettingUpdated = {
          ...studioSettings,
          layoutTemplate: ls,
        }
        updateLayoutTemplate(ls as any)
        const res = await reduxStore.context.gql.updateMyProfile({
          data: { studioSettings: studioSettingUpdated },
        })
        if (res.error) {
          updateLayoutTemplate(layoutTemplateOrigin as any)
          ToastService.error({ content: 'Add layout failed.' })
        }
      } catch (e) {
        updateLayoutTemplate(layoutTemplateOrigin as any)
        ToastService.error({ content: 'Add layout failed.' })
      }
    }
    const removeLayout = async (id: number) => {
      try {
        const ls = layoutTemplate.filter(l => l.defaultId !== id)
        const lsToUpdate = ls.length > 0 ? ls : layoutNewAll
        const studioSettingUpdated = {
          ...studioSettings,
          layoutTemplate: lsToUpdate,
        }
        updateLayoutTemplate(lsToUpdate as any)
        const res = await reduxStore.context.gql.updateMyProfile({
          data: { studioSettings: studioSettingUpdated },
        })
        if (res.error) {
          updateLayoutTemplate(layoutTemplateOrigin as any)
          ToastService.error({ content: 'Remove layout failed.' })
        }
      } catch (e) {
        updateLayoutTemplate(layoutTemplateOrigin as any)
        ToastService.error({ content: 'Remove layout failed.' })
      }
    }
    const currentLayoutSelected = selectedListLayouts[index]
    const currentSlotsObj = arrToMap(
      currentLayoutSelected.slots,
      (sl, index1) => index1,
      item1 => item1,
    )
    return (
      <div id='layoutTemplate' className={css.PopoverListLayoutContent}>
        <div className={css.PopoverHeader}>
          <h5
            className={clsx(
              css.PopoverTitle,
              isLightTheme
                ? css.liveStreamLayoutsh5Light
                : css.liveStreamLayoutsh5Dark,
            )}
          >
            Layout Template
          </h5>
          <span className={css.PlusIcon} onClick={addLayout}>
            <Icon icon='Plus' size={14} />
          </span>
        </div>

        <div className={css.divider} />
        <div
          className={clsx(
            css.PopoverList,
            layoutTemplate.length > 9
              ? css.liveStreamLayoutTemplateHeight
              : undefined,
          )}
        >
          {layoutTemplate.map((item, idx) => {
            const slotsObj = arrToMap(
              item.slots,
              (sl1, index1) => index1,
              item1 => item1,
            )
            const isDiff = _.isEqual(currentSlotsObj, slotsObj)

            return (
              <div
                style={{
                  // @ts-ignore
                  '--background-layout-small': warmnessColorThumbnail,
                }}
                className={css.PopoverLayoutItem}
                key={idx + ''}
              >
                <CustomLayout
                  onLayoutClick={() => {
                    handleChangeLayout(idx + 1)
                  }}
                  isSmall
                  layout={{
                    id: item.defaultId + '',
                    width: 49.5 * customRatio,
                    height: 49.5,
                    className: clsx({
                      [css.LayoutContainer]: true,
                      [css.LayoutContainerSelected]:
                        item.defaultId === (selectedIndexLayouts[index] ?? 1) &&
                        item.slots.length ===
                          currentLayoutSelected.slots.length &&
                        isDiff,
                    }),
                    slots: item.slots.map((v: any, j: number) => ({
                      id: v.id,
                      left:
                        customRatio >= 1
                          ? (v.left * 49.5 * customRatio) / vw
                          : ((v.top * 49.5) / vh) * customRatio,
                      top:
                        customRatio >= 1
                          ? (v.top * 49.5) / vh
                          : (v.left * 49.5 * customRatio) / vw / customRatio,
                      width:
                        customRatio >= 1
                          ? (v.width * 49.5 * customRatio) / vw
                          : ((v.height * 49.5) / vh) * customRatio,
                      height:
                        customRatio >= 1
                          ? (v.height * 49.5) / vh
                          : (v.width * 49.5 * customRatio) / vw / customRatio,
                      radius: v.radius,
                      zIndex: v.zIndex,
                      key: Helper.generateGuid(),
                      children: <div className={css.layoutIcon} />,
                    })),
                  }}
                >
                  <WarmnessGrayButton
                    onClick={e => {
                      e.stopPropagation()
                      removeLayout(item.defaultId)
                    }}
                    className={css.RemoveLayoutButton}
                  >
                    <Icon
                      className={css.RemoveIcon}
                      icon='icon_delete'
                      size={10}
                    />
                  </WarmnessGrayButton>
                </CustomLayout>
              </div>
            )
          })}
        </div>
      </div>
    )
  },
)

const PopoverLayoutSetting = observer(
  ({
    onChangeSettings,
    onClosePopover,
    isLightTheme,
  }: {
    onChangeSettings: (type: string, value: boolean | string) => void
    onClosePopover: () => void
    isLightTheme: boolean
  }) => {
    const [showChangeRatio, setShowChangeRatio] = useState(false)
    const { warmnessColor } = S.local
    const {
      onAutoShareScreenshareToggle,
      toggleShowSubtitle,
      selectedLayoutByIndex,
      layoutMedias,
      selectedIndexLayout,
      removeMediasOfLayoutSlot,
      computedSelectedMediaIds,
      removePeersOnSlot,
      selectedListLayouts,
    } = S.webrtc

    const closeEditLayout = () => {
      if (!S.layout.enableEditLayout) {
        return
      }
      const dataLayoutMedia = layoutMedias[selectedIndexLayout]
      const slots = selectedLayoutByIndex.slots
      S.layout.subtitleSlot = { ...S.webrtc.subtitleSlot }
      if (dataLayoutMedia) {
        const listMediaNotSave: string[] = []
        for (const p in dataLayoutMedia) {
          const mediaExit = slots.find((s: any) => s.id === Number(p))
          if (!mediaExit) {
            listMediaNotSave.push(dataLayoutMedia[p].id)
          }
        }
        removeMediasOfLayoutSlot(listMediaNotSave, selectedIndexLayout)
      }
      if (computedSelectedMediaIds) {
        const listPeerNotSave: number[] = []
        for (const p in computedSelectedMediaIds) {
          const mediaExit = slots.find((s: any) => s.id === Number(p))
          if (!mediaExit) {
            listPeerNotSave.push(Number(p))
          }
        }
        removePeersOnSlot(listPeerNotSave)
      }
      const layoutsEdit: { [key: number]: ILayoutToEdit[] } = {}
      selectedListLayouts.forEach((i, index) => {
        layoutsEdit[i.defaultId] = (i.slots as ILayoutItem[]).map(s => ({
          ...s,
          layoutIndex: index,
          parentId: i.defaultId,
        }))
      })
      setSlotsEdit(layoutsEdit)
      setEnableEditLayout(false)
      ResizeOutsideApi.removeItemByIds()
    }

    const handleChangeSwitch = (value: boolean, type: string) => {
      if (type === 'autoShareScreen') {
        onAutoShareScreenshareToggle(value)
      }
      if (type === 'showSubtitles') {
        toggleShowSubtitle(value)
        subTitle(value)
      }
      onChangeSettings(type, value)
    }

    return (
      <div
        id='OthersSettings'
        onMouseDown={e => e.stopPropagation()}
        className={css.PopoverWrapperSetting}
      >
        <WarmnessDiv className={css.PopoverContainerSetting}>
          <div className={css.PopoverSettingBlock}>
            <div
              className={clsx(
                css.PopoverSettingTitle,
                isLightTheme
                  ? css.popOverSettingTitleLight
                  : css.popOverSettingTitleDark,
              )}
            >
              Others
            </div>
            <div
              className={clsx({
                [css.PopoverSettingItems]: true,
                [css.PopovercolorDark]: !isLightTheme,
                [css.PopovercolorLight]: isLightTheme,
              })}
            >
              <div className={css.PopoverSettingSwitch}>
                <BeamSwitch
                  style={{ background: warmnessColor }}
                  isLightTheme={isLightTheme}
                  className={css.PopoverItemBeamSwitch}
                  checked={S.webrtc.autoShareScreen}
                  onChange={(status: boolean) =>
                    handleChangeSwitch(status, 'autoShareScreen')
                  }
                />
                Automatically share screen
                <BeamTooltip
                  overlayStyle={{ zIndex: 99999 }}
                  className={css.LabelIconTooltip}
                  title='Automatically add shared screens to stream. Disable this if you want to turn it manually'
                >
                  <InfoCircleOutlined
                    className={clsx({
                      [css.PopoverIconTooltipDark]: !isLightTheme,
                      [css.PopoverIconTooltipLight]: isLightTheme,
                    })}
                  />
                </BeamTooltip>
              </div>
              <div className={css.PopoverSettingSwitch}>
                <BeamSwitch
                  style={{ background: warmnessColor }}
                  isLightTheme={isLightTheme}
                  className={css.PopoverItemBeamSwitch}
                  checked={S.webrtc.showParticipantName}
                  onChange={(status: boolean) =>
                    handleChangeSwitch(status, 'showParticipantName')
                  }
                />
                Show participants name
                <BeamTooltip
                  overlayStyle={{ zIndex: 99999 }}
                  className={css.LabelIconTooltip}
                  title='Show participants names on the overlay'
                >
                  <InfoCircleOutlined
                    className={clsx({
                      [css.PopoverIconTooltipDark]: !isLightTheme,
                      [css.PopoverIconTooltipLight]: isLightTheme,
                    })}
                  />
                </BeamTooltip>
              </div>
              <div className={css.PopoverSettingSwitch}>
                <BeamSwitch
                  style={{ background: warmnessColor }}
                  isLightTheme={isLightTheme}
                  className={css.PopoverItemBeamSwitch}
                  checked={S.webrtc.isShowSubtitles}
                  onChange={(status: boolean) => {
                    handleChangeSwitch(status, 'showSubtitles')
                  }}
                />
                Show Subtitles
                <BeamTooltip
                  overlayStyle={{ zIndex: 99999 }}
                  className={css.LabelIconTooltip}
                  title='Show Subtitles By Rev.ai'
                >
                  <InfoCircleOutlined
                    className={clsx({
                      [css.PopoverIconTooltipDark]: !isLightTheme,
                      [css.PopoverIconTooltipLight]: isLightTheme,
                    })}
                  />
                </BeamTooltip>
              </div>
              <CustomPopover
                placement='right'
                align={{ offset: [10, -121] }}
                visible={showChangeRatio}
                isLightTheme={isLightTheme}
                content={<ChooseAspectRatio />}
                onVisibleChange={(status: boolean) =>
                  setShowChangeRatio(status)
                }
              >
                <div
                  onClick={() => closeEditLayout()}
                  className={css.PopoverSettingRatio}
                >
                  <Icon icon='ratio1' size={24} className={css.RatioIcon} />
                  Choose Aspect Ratio
                  <BeamTooltip
                    overlayStyle={{ zIndex: 99999 }}
                    className={css.LabelIconTooltip}
                    title='Select and customise aspect ratios for your layout'
                  >
                    <InfoCircleOutlined
                      className={clsx({
                        [css.PopoverIconTooltipDark]: !isLightTheme,
                        [css.PopoverIconTooltipLight]: isLightTheme,
                      })}
                    />
                  </BeamTooltip>
                </div>
              </CustomPopover>
            </div>
          </div>
        </WarmnessDiv>
      </div>
    )
  },
)

type Ratio = {
  name: string
  value: number
  des?: string
  icon: IconName
}

const ChooseAspectRatio = observer(() => {
  const { isLightTheme } = S.local
  const { fraction, customRatio } = S.webrtc
  const customFraction = fraction.w / fraction.h
  const ratios1: Ratio[] = [
    {
      name: '16:9',
      value: 16 / 9,
      des: 'YouTube ads',
      icon: 'RoundedRectangle',
    },
    {
      name: '4:3',
      value: 4 / 3,
      des: 'LinkedIn ads, Facebook ads',
      icon: 'RoundedRectangle',
    },
    { name: '2:1', value: 2 / 1, icon: 'ratio2' },
  ]
  const ratios2: Ratio[] = [
    { name: '9:16', value: 9 / 16, des: 'TikTok, TikTok ads', icon: 'ratio3' },
    { name: '1:1', value: 1 / 1, des: 'Instagram posts', icon: 'ratio5' },
    { name: '3:4', value: 3 / 4, icon: 'ratio3' },
  ]

  const handleSelectRatio = (ratio?: number) => {
    if (ratio) {
      changeRatio(ratio)
    } else if (customFraction >= 0.5 && customFraction <= 3) {
      changeRatio(customFraction)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || customFraction > 3 || customFraction < 0.5) {
      handleSelectRatio()
    }
  }

  const handleSetFraction = (type: string, value: number) => {
    if (type === 'w') {
      S.webrtc.fraction.w = Math.abs(Number(value.toFixed(2)))
    }
    if (type === 'h') {
      S.webrtc.fraction.h = Math.abs(Number(value.toFixed(2)))
    }
  }

  return (
    <div
      onMouseDown={e => e.stopPropagation()}
      className={css.AspectRatioContainer}
    >
      <div className={css.AspectRatioList}>
        {ratios1.map(ratio => (
          <div
            key={ratio.value}
            className={clsx(css.AspectRatioItem, {
              [css.AspectRatioItemSelected]:
                ratio.value === S.webrtc.customRatio &&
                ratio.value !== customFraction,
              [css.Dark]: !isLightTheme,
            })}
            onClick={() => handleSelectRatio(ratio.value)}
          >
            <div className={css.RatioCustomLeft}>
              <Icon
                icon={ratio.icon}
                size={24}
                className={css.RatioIconSelect}
              />
              <div className={css.RatioCustomLeftDescription}>
                <span className={css.RatioName}>{ratio.name}</span>
                <span className={css.RatioDescription}>{ratio.des}</span>
              </div>
            </div>

            <div className={css.IconCheck}>
              <Icon icon='icon_check' size={16} color='#085AE1' />
            </div>
          </div>
        ))}
        <div className={css.DividerRatio} />
        {ratios2.map(ratio => (
          <div
            key={ratio.value}
            className={clsx(css.AspectRatioItem, {
              [css.AspectRatioItemSelected]:
                ratio.value === S.webrtc.customRatio &&
                ratio.value !== customFraction,
              [css.Dark]: !isLightTheme,
            })}
            onClick={() => handleSelectRatio(ratio.value)}
          >
            <div className={css.RatioCustomLeft}>
              <Icon
                icon={ratio.icon}
                size={24}
                className={css.RatioIconSelect}
              />
              <div className={css.RatioCustomLeftDescription}>
                <span className={css.RatioName}>{ratio.name}</span>
                <span className={css.RatioDescription}>{ratio.des}</span>
              </div>
            </div>
            <div className={css.IconCheck}>
              <Icon icon='icon_check' size={16} color='#085AE1' />
            </div>
          </div>
        ))}
        <div className={css.DividerRatio} />
        <div className={css.CustomRatioTitle}>
          {' '}
          Custom Ratio
          <div className={css.IconCheck}>
            {customFraction === customRatio && (
              <Icon icon='icon_check' size={16} color='#085AE1' />
            )}
          </div>
        </div>

        <div className={css.CustomRatio}>
          <BeamInput
            className={css.CustomRatioInput}
            type='number'
            value={fraction.w}
            onChange={e => handleSetFraction('w', Number(e.target.value))}
            onKeyDown={handleKeyDown}
          />
          /
          <BeamInput
            className={css.CustomRatioInput}
            type='number'
            onChange={e => handleSetFraction('h', Number(e.target.value))}
            value={fraction.h}
            onKeyDown={handleKeyDown}
          />
          <Button sizecustom='S' onClick={() => handleSelectRatio()}>
            Change
          </Button>
        </div>
        {(customFraction > 3 || customFraction < 0.5) && (
          <div className={css.Error}>Please choose another ratio</div>
        )}
      </div>
    </div>
  )
})
