import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'

export const useCheckClick = (
  elementChecks: string[],
  cb?: (isInside: boolean) => void,
) => {
  const [isClickInSide, setIsClickInside] = useState<boolean>(false)

  const checkInElement = (names: string[], event: MouseEvent) => {
    const query = names.reduce((acc, curr, index) => {
      const v =
        index === names.length - 1 ? `${acc}#${curr}` : `${acc}#${curr},`
      return v
    }, '')
    const elements = document?.querySelectorAll(query) ?? []
    let check = false
    for (var i = 0; i < elements.length; i++) {
      const e = elements[i]
      const position = e.getBoundingClientRect()
      if (
        event.clientX >= position.left &&
        event.clientX <= position.right &&
        event.clientY >= position.top &&
        event.clientY <= position.bottom
      ) {
        check = true
        if (check) {
          break
        }
      }
    }

    return check
  }
  const handleMouseDown = (event: any) => {
    const isInElement = checkInElement(elementChecks, event)
    cb?.(isInElement)
    setIsClickInside(isInElement)
  }
  useEffect(
    () => {
      addEventListener('mousedown', handleMouseDown)
      return () => {
        removeEventListener('mousedown', handleMouseDown)
      }
    },
    [elementChecks], // Only re-call effect if value or delay changes
  )
  return isClickInSide
}
