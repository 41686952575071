import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect } from 'react'

import 'react-circular-progressbar/dist/styles.css'
import css from './ModalUpdateInput.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import { Form } from '#HACK_FOR_RN_ONLY/src/components/base/Form'
import { FormItem } from '#HACK_FOR_RN_ONLY/src/components/base/FormItem'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { WarmnessDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessDiv'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import { WarmnessInput } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessBeamInput'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import {
  useGqlDeleteInput,
  useGqlUpdateInput,
} from '../../../context/gql/codegen'
import { S } from '../../store'
import { getNormalId } from '../../store/studio/WebrtcStore'

export enum InputType {
  RTMP = 'RTMP',
  SRT = 'SRT',
}
type Props = {
  peerId: string
}

export const ModalUpdateInput: FC<Props> = observer(({ peerId }) => {
  const [form] = useForm<{ streamType: string; name: string }>()
  const [stateDelete, deleteInput] = useGqlDeleteInput()
  const [stateUpdate, updateInput] = useGqlUpdateInput()
  const { inputs } = S.webrtc

  const input = inputs.find(i => i.id === getNormalId(peerId))
  const onClose = () => {
    ModalService.hide()
  }
  const onCopy = () => {
    navigator.clipboard.writeText(input?.url ?? '')
    ToastService.success({ content: 'Copied' })
  }

  const onDelete = async () => {
    if (!input) {
      return
    }
    await deleteInput({ id: input.id })
    ToastService.success({ content: 'Deleted input success' })
    ModalService.hide()
  }
  const handleSubmit = async () => {
    if (!input) {
      return
    }
    try {
      const res = await updateInput({
        id: input.id,
        data: { name: form.getFieldValue('name') },
      })
      if (res.error) {
        ToastService.error({ content: 'Update failed' })
        return
      }
      ModalService.hide()
    } catch (e) {}
  }
  useEffect(() => {
    form.setFieldsValue({ name: input?.name, streamType: input?.type })
  }, [input])

  return (
    <ModalContent containerClassName={css.ModalContainer}>
      <WarmnessDiv className={css.container}>
        <div className={css.TitleBox}>
          <div className={css.IconWifi}>
            <Icon icon='icon_menu_live' size={16} />
          </div>
          <div>
            <h6 className={css.Title}>Edit RTMP/SRT</h6>
          </div>
        </div>
        <div className={css.Mt24}>
          <Form
            onFinish={handleSubmit}
            form={form}
            requiredMark
            layout='vertical'
          >
            <FormItem
              className={css.FormItem}
              name='streamType'
              initialValue={InputType.RTMP}
              label={<p className={css.FormLabel}>Stream Type</p>}
            >
              <WarmnessInput
                disabled
                value={input?.type}
                className={css.Input}
              />
            </FormItem>
            <FormItem
              className={css.FormItem}
              name='name'
              label={<p className={css.FormLabel}>Url</p>}
            >
              <div className={css.Row}>
                <WarmnessInput
                  containerProps={{ className: css.Flex1 }}
                  disabled
                  value={input?.url}
                  className={css.Input}
                />
                <WarmnessGrayButton onClick={onCopy} className={css.BtnClose}>
                  Copy
                </WarmnessGrayButton>
              </div>
            </FormItem>
            <FormItem
              className={css.FormItem}
              name='name'
              initialValue={input?.name}
              label={<p className={css.FormLabel}>Name</p>}
              rules={[
                {
                  required: true,
                  message: <div>This field is missing</div>,
                },
              ]}
            >
              <WarmnessInput
                disabled={input?.status === 'Loading'}
                className={css.Input}
              />
            </FormItem>
            <div className={css.Action}>
              <WarmnessGrayButton
                onClick={stateDelete.fetching ? undefined : onDelete}
                className={css.BtnDelete}
              >
                Delete
              </WarmnessGrayButton>
              <div className={css.ButtonRightBottom}>
                <WarmnessGrayButton onClick={onClose} className={css.BtnClose}>
                  Close
                </WarmnessGrayButton>
                <Button loading={stateUpdate.fetching} htmlType='submit'>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </WarmnessDiv>
    </ModalContent>
  )
})
