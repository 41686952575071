import { Input } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import css from './Titles.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Map } from '../../../components/base/Map'
import { Show } from '../../../components/base/Show'
import { WarmnessGrayDiv } from '../../../components/composites/WarmnessGrayDiv'
import { WarmnessInput } from '../../../components/widget/WarmnessBeamInput'
import { S } from '../../../context/store'
import { DRAG_TITLE } from '../../../context/store/studio/dragTypes'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { addTitle } from '../actions/addTitle'
import { pasteSlot } from '../actions/pasteSlot'
import { removeTitle } from '../actions/removeTitle'
import { toggleHideAllTitle } from '../actions/toggleHideAllTitle'
import { Title, TitlePreview } from './TitleItem'

type TAddTitle = {
  content: string
  author: string
}

interface PropsFormAdd {
  onAddTitle: (data: TAddTitle) => void
}

export const Titles = observer(() => {
  const { selectedIndexLayout, titlesOnAir, selectedLayoutByIndex, titles } =
    S.webrtc
  const { slotCopy } = S.layout
  const onRemoveTitle = (id: string) => {
    removeTitle(id)
  }
  const onAddTitle = () => {
    addTitle()
  }

  const onSortEnd = (dropIndex: number, overIndex: number) => {
    S.webrtc.sortAndUpdateListTitle(dropIndex, overIndex)
    S.webrtc.updateEmitAndSaveSettings()
  }
  const onAir = (titlesOnAir[selectedIndexLayout] ?? []).length > 0
  const list = titles.filter(t =>
    (selectedLayoutByIndex?.slots ?? []).some(t1 => t1.titleId === t.id),
  )
  return (
    <div className={css.ContainerTitles}>
      <div className={css.ContainerTitlesHeader}>
        Text
        <div className={css.Buttons}>
          {list.length > 0 && (
            <WarmnessGrayButton
              onClick={toggleHideAllTitle}
              className={css.TitleAction}
            >
              <Icon icon={onAir ? 'icon_on_air' : 'icon_off_air'} size={16} />
            </WarmnessGrayButton>
          )}
          <WarmnessGrayButton onClick={onAddTitle} className={css.TitleAction}>
            <Icon icon='Plus' size={16} />
          </WarmnessGrayButton>
          {slotCopy && (
            <WarmnessGrayButton onClick={pasteSlot} className={css.TitleAction}>
              <Icon icon='paste' size={18} />
            </WarmnessGrayButton>
          )}
        </div>
      </div>
      <div className={css.SubHeader}>
        <span className={css.LayoutInfo}>
          Layout {selectedIndexLayout + 1}{' '}
        </span>
      </div>
      <div className={css.ContainerContent}>
        <div className={css.ListTitle}>
          <Show>
            <Show.When isTrue={Boolean(S.webrtc.titles.length)}>
              <Map<TitleItem>
                list={list}
                renderItem={(item, index) => (
                  <Title
                    title={item}
                    key={item.id}
                    onSortEnd={onSortEnd}
                    onRemoveTitle={onRemoveTitle}
                    index={index.toString()}
                  />
                )}
              />
            </Show.When>

            <Show.Else>
              <span className={css.NotitleText}>There are no titles yet</span>
            </Show.Else>
          </Show>
          <Show>
            <Show.When isTrue={S.webrtc.typeDraggingItem === DRAG_TITLE}>
              <TitlePreview />
            </Show.When>
          </Show>
        </div>
      </div>
    </div>
  )
})

export const FormAddTitle = observer(({ onAddTitle }: PropsFormAdd) => {
  const [title, setTitle] = useState({ content: '', author: '' })
  const [error, setError] = useState(false)
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTitle({
      ...title,
      [e.target.name]: e.target.value,
    })
  }
  const onChangeContent = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle({
      ...title,
      [e.target.name]: e.target.value,
    })
  }
  // const onCancel = () => {
  //   setTitle({ content: '', author: '' })
  // }
  const onAdd = () => {
    if (title.content === '') {
      // showError('Title is required')
      setError(true)
      return
    }
    onAddTitle(title)
    setTitle({ content: '', author: '' })
  }
  useEffect(() => {
    if (title.content) {
      setError(false)
    }
  }, [title.content])
  return (
    <div className={css.FormAddTitleContent}>
      <WarmnessGrayDiv
        style={{ background: S.local.warmnessColorGray }}
        className={css.ContentFormAdd}
      >
        <WarmnessInput
          className={clsx({
            [css.FieldAddTitle]: true,
            [css.FieldAddTitleError]: error,
          })}
          status={error ? 'error' : undefined}
          name='content'
          placeholder='Title'
          onChange={onChangeContent}
          value={title.content}
          maxLength={60}
        />
        <div className={css.FieldTitleBottomWrapper}>
          {error && <span className={css.TextError}>Please enter text</span>}
          <span className={css.FieldTitleItemCount}>
            {60 - title.content.length}
          </span>
        </div>

        <Input.TextArea
          style={{ background: S.local.warmnessColor }}
          className={css.FieldAddTitle}
          value={title.author}
          name='author'
          onChange={onChange}
          placeholder='Name (optional)'
          autoSize={{ maxRows: 2 }}
          maxLength={60}
        />
        <span className={css.FieldTitleItemCount}>
          {60 - title.author.length}
        </span>
        <Button className={css.BtnAddFormTitle} type='primary' onClick={onAdd}>
          Add
        </Button>
      </WarmnessGrayDiv>
    </div>
  )
})
