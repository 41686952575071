import { get } from 'lodash'

import { showError } from './showError'

export const catchPromiseError = <T>(
  p: Promise<T>,
  onSuccess?: (v: T) => void,
) =>
  p
    .then(r => {
      if (checkAndShowError(r)) {
        return
      }
      onSuccess?.(r)
    })
    .catch((err: Error) => {
      checkAndShowError(err)
    })

export const checkAndShowError = (err: unknown) => {
  // rest api error returned from express errorHandler using axios
  const message: string = get(err, 'error') || get(err, 'data.error')
  if (message) {
    const detail: string =
      get(err, 'errorDetail') || get(err, 'data.errorDetail')
    showError(message, detail)
    return true
  }
  // urql CombinedError
  if (message) {
    err = message
  }
  const networkError: Error = get(err, 'networkError')
  if (networkError) {
    showError('Failed to connect to server', networkError)
    return true
  }
  const e: { message: string }[] =
    get(err, 'graphQLErrors') ||
    get(err, 'errors') ||
    get(err, 'response.errors')
  if (Array.isArray(e)) {
    const arr = (e[0]?.message || `${e[0]}`).split('\n')
    const m1 = arr.shift() as string
    const m2 = arr.join('\n')
    showError(m1, m2)
    return true
  }
  // error directly from catch
  if (err instanceof Error) {
    showError('An error occured', err)
    return true
  }
  // not an error
  return false
}

export const showErrorEmptyResponse = () =>
  showError('An error occured', 'Empty response from server')
