import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'

import css from './MediaImages.module.scss'

import { waitTimeout } from '##/shared/waitTimeout'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_MEDIA_IMAGE } from '../../../context/store/studio/dragTypes'
import {
  getExtOfFile,
  getItemPreviewStyles,
  getListPosOfIdValue,
} from '../../../context/store/studio/utils'
import type {
  TMediaItem,
  TMediaItemDetail,
} from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { PopoverActionMedia, PopoverDelete } from './MediaPopover'
import type { TDragPreview } from './RightContent'

type TImages = {
  images: ReadonlyArray<TMediaItem>
  onSelectMedia?: (e: MouseEvent, item: TMediaItem) => void
  onUpdateSetting?: (type?: string) => void
}
type TImage = TMediaItemDetail

export const MediaImages = observer(
  ({ onUpdateSetting }: { onUpdateSetting: (type?: string) => void }) => {
    const onSelectMedia = (e: MouseEvent, item: TMediaItem) => {
      if (e.shiftKey) {
        S.webrtc.toggleMediaSelectionId(item.id)
        return
      }
      S.webrtc.updateDataOfStore({
        mediaSelectionId: [],
      })
    }
    if (S.webrtc.mediaStudio.image.length === 0) {
      return <p className={css.MediaListNotFound}>There is no image yet</p>
    }
    return (
      <Images
        images={S.webrtc.mediaStudio.image}
        onSelectMedia={onSelectMedia}
        onUpdateSetting={onUpdateSetting}
      />
    )
  },
)
const Images = observer(
  ({ images, onSelectMedia, onUpdateSetting }: TImages) => {
    const {
      deleteFileMedia,
      removeMediasOfLayoutSlot,
      updateAndEmit,
      updateOrderMedia,
      mediaSelectionId,
      updateDataOfStore,
      backgroundUrl,
      selectedIndexLayout,
      typeDraggingItem,
      isOnAirItemOfLayout,
      layoutMedias,
    } = S.webrtc
    const onDelete = async (item: TMediaItem) => {
      try {
        let isUpdateSettings = false
        deleteFileMedia(item.id, 'image')
        if (backgroundUrl === item.value) {
          isUpdateSettings = true
          updateAndEmit({
            backgroundUrl: '',
          })
        }
        const posList = getListPosOfIdValue(item.id, layoutMedias)
        if (posList.length > 0) {
          isUpdateSettings = true
          removeMediasOfLayoutSlot([item.id])
        }
        if (isUpdateSettings) {
          onUpdateSetting?.()
        }
        await reduxStore.context.gql.deleteResourceInSession({ ids: [item.id] })
      } catch (err) {
        ToastService.error({ content: 'Failed to delete image' })
        console.error(err)
      }
    }

    const onSortEnd = async (dropIndex: number, overIndex: number) => {
      if (mediaSelectionId.length <= 1) {
        updateOrderMedia('image', dropIndex, overIndex)
        await callApiUpdateResourceReOrder(
          S.webrtc.mediaStudio.image,
          dropIndex,
          overIndex,
        )
      }
    }

    const onDragEnd = () => {
      if (mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }

    const onTurnOffAir = (item: TMediaItem) => {
      removeMediasOfLayoutSlot([item.id], selectedIndexLayout)
      if (item.value === backgroundUrl) {
        updateAndEmit({
          backgroundUrl: '',
        })
      }
      onUpdateSetting?.()
    }

    return (
      <>
        {images.map((item, index) => (
          <Image
            media={item}
            onDelete={onDelete}
            key={index}
            index={index.toString()}
            onSortEnd={onSortEnd}
            onDragEnd={onDragEnd}
            dragSelected={mediaSelectionId.includes(item.id)}
            isDraggingSelect={mediaSelectionId.length > 1}
            onSelectMedia={(e: MouseEvent) =>
              onSelectMedia && onSelectMedia(e, item)
            }
            draggingSelected={
              mediaSelectionId.includes(item.id) &&
              typeDraggingItem === DRAG_MEDIA_IMAGE
            }
            onAir={
              isOnAirItemOfLayout(layoutMedias, item.id) ||
              item.value === backgroundUrl
            }
            onTurnOffAir={() => onTurnOffAir(item)}
          />
        ))}
        {typeDraggingItem === DRAG_MEDIA_IMAGE && (
          <ImagePreview totalItem={mediaSelectionId.length} />
        )}
      </>
    )
  },
)
const Image = ({
  media: { value, mediaType, id, isDefault, name },
  onDelete,
  onSortEnd,
  onSelectMedia,
  index,
  dragSelected,
  draggingSelected,
  isDraggingSelect,
  onAir = false,
  onTurnOffAir,
  onDragEnd,
}: TImage) => {
  const [visiblePopover, setPopover] = useState(false)

  const dref = useRef<HTMLDivElement>(null)
  const { isDragging, drag } = useDragDefault({
    item: { id, index, value, mediaType, name },
    dragType: DRAG_MEDIA_IMAGE,
    onDragEnd,
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: DRAG_MEDIA_IMAGE,
    index,
    dref,
    onSortEnd,
    direction: 'x',
  })

  const handleDelete = () => {
    if (!visiblePopover) {
      setPopover(true)
    }
  }

  drag(drop(dref))

  const totalError = useRef(0)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (error && totalError.current < 5) {
      totalError.current++
      waitTimeout().then(() => setError(false))
    }
  }, [error])
  const onImageError = () => setError(true)

  const [loading, setLoading] = useState(true)
  const onImageLoad = () => {
    setLoading(false)
    setError(false)
  }

  return (
    <div
      ref={dref}
      // drophover={isOver && !isDraggingSelect}
      // dropbottom={isBottom}
      // dragSelected={dragSelected && !draggingSelected}
      data-selection={id}
      className={clsx({
        ['media']: true,
        [css.MediaImageItemContainer]: true,
        [css.mediaItemHorizontalContainerDrophover]:
          isOver && !isDraggingSelect,
        [css.mediaItemHorizontalContainerDropbottom]: isBottom,
        [css.mediaItemHorizontalContainerDragSelected]:
          dragSelected && !draggingSelected,
      })}
    >
      <PopoverDelete
        visible={visiblePopover}
        onVisibleChange={(status: boolean) => setPopover(status)}
        onCancel={() => setPopover(false)}
        onDelete={() => {
          setPopover(false)
          onDelete({ value, mediaType, id, isDefault })
        }}
      />
      <div
        className={clsx({
          [css.MediaItemWrapper]: true,
          [css.mediaItemWrapperOnAir]: onAir,
          [css.mediaItemWrapperDragging]: isDragging,
        })}
        onClick={onSelectMedia}
        // onair={onAir}
        // dragging={isDragging}
      >
        {!error && (
          <img
            className={css.MediaImageTag}
            src={value}
            onLoad={onImageLoad}
            onError={onImageError}
          />
        )}
        {loading && <LoadingOutlined className={css.LoadingSocial} />}
        {(mediaType === 'gif' || getExtOfFile(value) === 'gif') && (
          <span className={css.MediaImageIconGIF}>
            <Icon icon='icon_media_gif' size={40} />
          </span>
        )}
      </div>
      <div className={css.MediaImageItemInfo}>
        <div className={css.MediaImageItemInfoTitle}>{name}</div>
      </div>
      <PopoverActionMedia
        onDownload={() => saveAs(value)}
        onDelete={handleDelete}
      />
      {onAir && (
        <div className={css.MediaImageButtonOnAir} onClick={onTurnOffAir}>
          On Air
        </div>
      )}
      {(isDragging || draggingSelected) && (
        <WarmnessGrayButton
          isButton={false}
          className={css.MediaImageDragging}
        />
      )}
    </div>
  )
}

const ImagePreview = observer(({ totalItem = 0 }: { totalItem?: number }) => {
  const { isDragging, item, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging) {
    return null
  }
  const onAir = S.webrtc.isOnAirItemOfLayout(S.webrtc.layoutMedias, item.id)
  return (
    <div
      className={css.MediaImagePreview}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div
        className={clsx({
          [css.MediaItemWrapper]: true,
          [css.mediaItemWrapperOnAir]: onAir,
          [css.MediaItemWrapperPreview]: true,
        })}
        // onair={onAir}
      >
        <img className={css.MediaImageTag} src={item.value} />
        {item.type === 'gif' && <span className={css.MediaImageIconGIF} />}
      </div>
      <div className={css.MediaImageItemInfo}>
        <div className={css.MediaImageItemInfoTitle}>{item.name} </div>
      </div>
      {onAir && <div className={css.MediaImageButtonOnAir}>On Air</div>}
      {totalItem > 0 && (
        <div className={css.MediaPreviewTotalItem}>{totalItem}</div>
      )}
    </div>
  )
})
