import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const deleteMuteTimeline = async (id: string) => {
  const $ = S.insightEngine
  const newDeletedMute = $.muteTimelines.filter(block => block.id != id)
  $.muteTimelines = [...newDeletedMute]

  reduxStore.context.gql.deleteMute({ id })
}
