import { observer } from 'mobx-react-lite'

import css from './JoinSession.module.scss'

import { AppSpin } from '../../../components/base/Spin'
import { PublicLayout } from '../../../context/shared/components/PublicLayout'
import { S } from '../../../context/store'
import { ParticipantJoinSession } from './ParticipantJoinSession'

const $ = S.webrtc

type Props = {
  handleFinish: () => void
}

export const JoinSession = observer(({ handleFinish }: Props) => (
  <PublicLayout>
    {!$.role ? (
      <div className={css.LoadingCheckHost}>
        <AppSpin />
      </div>
    ) : $.preview ? (
      <ParticipantJoinSession handleFinish={handleFinish} />
    ) : null}
  </PublicLayout>
))
