import { cacheExchange, createClient, fetchExchange } from 'urql'

import type { GqlOperations } from './codegen'

export const createUrqlClient = (origin: string) =>
  createClient({
    url: `${origin}/api/graphql`,
    fetchOptions: () => ({
      headers: t ? { authorization: `Bearer ${t}` } : undefined,
    }),
    exchanges: [cacheExchange, fetchExchange],
  })
export type GqlCtx = {
  gqlUrqlClient: Client
  gql: GqlOperations
}

let t = ''
export const setUrqlAuthToken = (token: string) => {
  t = token
}
