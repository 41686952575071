import clsx from 'clsx'

import css from './MediaTabbar.module.scss'

import { AppTabPane } from '../../../components/base/TabPane'
import { AppTabs } from '../../../components/base/Tabs'

export const TabBarMedia = ({
  selectedTab,
  onSelectTab,
  className,
}: {
  selectedTab: string
  onSelectTab: (tab: string) => void
  className?: string
}) => (
  <div className={clsx(css.MediaTabBarContainer, className)}>
    <AppTabs
      activeKey={selectedTab}
      defaultActiveKey='video'
      onChange={v => {
        if (selectedTab === v) {
          return
        }
        onSelectTab(v)
      }}
    >
      {['playlist', 'video', 'image', 'audio', 'record'].map(item => (
        <AppTabPane key={item} tab={item} />
      ))}
    </AppTabs>
  </div>
)
