import { memo, useEffect } from 'react'
import type { Path } from 'react-router'
import { useNavigate } from 'react-router'

import { REFERRAL_CODE } from '../../config'

export class RouterHistory {
  static navigate: ReturnType<typeof useNavigate>
  static init = (navigate: any) => {
    RouterHistory.navigate = navigate
  }

  static push = (routeName: string | Partial<Path>, state?: any) => {
    if (this.navigate) {
      this.navigate(routeName, state)
    }
  }
  static pushAndKeepSearch = (routeName: string) => {
    if (this.navigate) {
      this.navigate({
        pathname: routeName,
        search: window.location.search,
      })
    }
  }
  static goBack = () => {
    if (this.navigate) {
      this.navigate(-1)
    }
  }
}

export const History = memo(() => {
  const navigate = useNavigate()

  useEffect(() => {
    RouterHistory.init(navigate)
  }, [history])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const share = params.get('share') ?? ''
    if (share) {
      localStorage.setItem(REFERRAL_CODE, share)
    }
  }, [window.location.search])

  return null
})
