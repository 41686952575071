import type { PopoverProps } from 'antd'
import { Popover as AntdPopover } from 'antd'

import './style.scss'

export const Popover = (props: PopoverProps) => {
  const { overlayClassName, ...propsPopover } = props
  return (
    <AntdPopover
      {...propsPopover}
      overlayClassName={`${overlayClassName} popover-custom`}
    />
  )
}
