import { proxy, ref } from 'valtio'

import { serverTime } from '../../store/shared/serverTime'
import { lazyUseSnapshot } from './lazyUseSnapshot'

const timer = proxy({
  now: ref(serverTime.moment()),
})
const onEachSecond = () => {
  timer.now = ref(serverTime.moment())
}

let intervalId = 0
export const useTimer = lazyUseSnapshot(timer, {
  onInit: () => {
    onEachSecond()
    intervalId = window.setInterval(onEachSecond, 1000)
  },
  onCleanup: () => {
    window.clearInterval(intervalId)
    intervalId = 0
  },
})
