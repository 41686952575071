import { S } from '../../store'
import type { ILayoutToEdit } from '../../store/shared/LayoutStore'

export const updateSlotToEdit = (
  id: number,
  slot: Partial<ILayoutToEdit>,
  layoutId: number,
) => {
  const $ = S.layout
  const layout = $.slotsEdit[layoutId]
  const sl = layout?.find(s => s.id === id)
  if (sl) {
    Object.assign(sl, slot)
  }
}
