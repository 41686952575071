import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent, MouseEvent } from 'react'
import { useEffect, useState } from 'react'

import css from './ModalUpdateRoom.module.scss'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { WarmnessInput } from '../../../components/widget/WarmnessBeamInput'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { CONSTANTS } from '../../../styles/Constants'
import { S } from '../../store'
import type { TUpdateRoom } from '../../store/studio/WebrtcStore'

export const ModalUpdateInfo = observer(
  ({
    visible,
    onCancel,
    initValue,
    onSave,
    context,
    isWarmTheme = true,
  }: {
    visible: boolean
    onCancel: () => void
    initValue: TUpdateRoom
    onSave: (value: TUpdateRoom) => void
    context: string
    isWarmTheme?: boolean
  }) => {
    const [title, setTitle] = useState(initValue.title)
    useEffect(() => {
      setTitle(initValue.title)
    }, [initValue.title])

    const [description, setDescription] = useState(initValue.description)
    const { warmnessColor } = S.local
    const handleOnCancel = () => {
      onCancel()
      setTitle(initValue.title)
    }
    return (
      <Modal
        width={500}
        visible={visible}
        centered
        style={{
          // @ts-ignore
          '--background-modal': isWarmTheme ? warmnessColor : undefined,
        }}
        footer={null}
        title={
          context === 'Insight Engine'
            ? `Edit ${context} Name`
            : `Edit ${context} Name & Description`
        }
        onCancel={handleOnCancel}
        className={css.WrapperModalEdit}
        closeIcon={
          <Icon
            icon='icon_close_popup'
            size={11}
            className={css.ModalOutClose}
          />
        }
        maskStyle={{ background: CONSTANTS.basicColor.maskBackgroundModal }}
        bodyStyle={{
          backgroundColor: 'transparent',
          padding: 0,
        }}
      >
        <div
          className={css.ModalFormContentContainer}
          onMouseDown={(e: MouseEvent) => e.stopPropagation()}
        >
          <WarmnessDiv isWarmTheme={false} className={css.ModalFormContent}>
            <div className={css.ModalFormItem}>
              <div className={css.ModalFormItemLabel}>{context} Name</div>
              <div>
                <WarmnessInput
                  maxLength={40}
                  className={css.ModalFormItemInput}
                  placeholder={`Enter your ${context.toLowerCase()} name`}
                  value={title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setTitle(e.target.value)
                  }
                />
                <div className={css.ModalFormItemCount}>
                  {40 - title.length}
                </div>
              </div>
            </div>
            {context === 'Insight Engine' ? (
              ''
            ) : (
              <div className={css.ModalFormItem}>
                <div className={css.ModalFormItemLabel}>
                  {context} Description
                </div>
                <div>
                  <WarmnessInput
                    maxLength={40}
                    placeholder={`Enter your ${context.toLowerCase()} description`}
                    value={description}
                    className={css.ModalFormItemInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setDescription(e.target.value)
                    }
                  />
                  <div className={css.ModalFormItemCount}>
                    {40 - (description?.length ?? 0)}
                  </div>
                </div>
              </div>
            )}
            <div className={css.ModalFormAction}>
              <WarmnessGrayButton
                className={css.ModalFormBtnCancel}
                onClick={handleOnCancel}
              >
                Cancel
              </WarmnessGrayButton>
              <Button
                sizecustom='M13'
                disabled={title === ''}
                className={css.modalFormBtnSubmit}
                onClick={() => onSave({ title, description })}
              >
                Save
              </Button>
            </div>
          </WarmnessDiv>
        </div>
      </Modal>
    )
  },
)
