import { observer } from 'mobx-react-lite'

// eslint-disable-next-line css-modules/no-unused-class
import css from './LiveItemCommon.module.scss'

import { S } from '../../../context/store'
import { DRAG_CHAT } from '../../../context/store/studio/dragTypes'
import { ItemChat, ItemChatPreview } from './ChatItem'
import { ItemAccordion } from './ItemAccordion'

export const LiveChat = observer(() => {
  const listFilterChat = S.webrtc.chatMessages
    .filter(item => item.message.chatId !== S.webrtc.selectedChat)
    .slice(0, 2)

  return (
    <>
      <div className={css.LiveItemContainer}>
        <ItemAccordion label='Live Chat'>
          <div className={css.LiveItemAccordionContent}>
            {listFilterChat.length > 0 ? (
              <div className={css.ListItemLive}>
                {listFilterChat.map((item, index) => (
                  <ItemChat chat={item} key={index} />
                ))}
              </div>
            ) : (
              <span className={css.NotYetText}>There are no message yet</span>
            )}
          </div>
        </ItemAccordion>
      </div>
      {S.webrtc.typeDraggingItem === DRAG_CHAT && <ItemChatPreview />}
    </>
  )
})
