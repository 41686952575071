import { useCallback, useEffect, useState } from 'react'

import { qs } from '../../../#/shared/qs'

interface IFacebookLoginHook {
  client_id: string
  redirect_uri: string
  state: string
  scope: string
  loginHandler: (code: string) => Promise<void>
}

export const useFacebookLogin = ({
  loginHandler,
  client_id,
  redirect_uri,
  scope,
  state,
}: IFacebookLoginHook) => {
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(
    () => () =>
      window.removeEventListener('storage', onChangeLocalStorage, false),
    [],
  )

  const getUrl = () => {
    const query = qs.stringify({
      client_id,
      redirect_uri,
      state: state + '_facebook',
      response_type: 'code',
      scope,
    })
    const facebookAuthLink =
      'https://www.facebook.com/v20.0/dialog/oauth?' + query
    return facebookAuthLink
  }

  const onChangeLocalStorage = useCallback(() => {
    const code = localStorage.getItem('facebook')
    if (code) {
      handleLoginFacebook(code)
      localStorage.removeItem('facebook')
    } else {
      setIsProcessing(false)
    }
  }, [])

  const handleLoginFacebook = async (code: string) => {
    setIsProcessing(true)
    await loginHandler(code)
    setIsProcessing(false)
  }

  const startLogin = () => {
    const oauthUrl = getUrl()
    const width = 1000
    const height = 500
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2
    window.open(
      oauthUrl,
      'Facebook',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    )
  }

  const facebookLogin = useCallback(() => {
    if (!isProcessing) {
      window.addEventListener('storage', onChangeLocalStorage, false)
      startLogin()
    }
  }, [isProcessing])

  return facebookLogin
}
