import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './MediaView.module.scss'

import { volume0 } from '#rn-shared/mediasoup'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import {
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { getTotalSlots } from '../utils/getTotalSlots'
import { isInputPeer } from '../utils/isInputPeer'
import { renderAvatar } from './ChatItem'
import { MediaControl } from './MediaControl'
import { Player } from './Player'
import { PopoverKickOut } from './PopoverKickOut'
import { SlotIconAction } from './SlotIconAction'
// import { SlotIconAction } from './SlotIconAction'

export type TPeerViewProps = {
  peerId: string
  mediaId?: string
  position?: number
  isSmallPeer?: boolean
  isHoverSlot?: boolean
  videoStyle?: React.CSSProperties
  cropMode?: ECropMode
  isMainCanvas?: boolean
  layoutIndex?: number
  isShow?: boolean
  forceMuted: boolean
}
export const PeerView: FC<TPeerViewProps> = observer(
  ({
    peerId,
    mediaId,
    position,
    isSmallPeer = false,
    isHoverSlot,
    videoStyle,
    cropMode,
    isMainCanvas = false,
    isShow,
    forceMuted,
  }) => {
    const [control, setControl] = useState(false)
    const [visibleKickOut, setVisibleKickOut] = useState(false)

    const screenshare = isScreenshareMedia(peerId)
    const mediaPeerId = getMediaPeerId(peerId)
    const peer = S.webrtc.getPeer(mediaPeerId)
    const input = S.webrtc.inputs.find(i => i.id === getNormalId(peerId))
    const {
      fullscreenMediaId,
      onAir,
      ratioScaleLayout,
      overlayUrl,
      amsVideoState,
      mediaVolumesOriginal,
      isViewmodeMixer,
      role,
      isViewmodeParticipant,
      computedSelectedLayout,
      updateAmsClientVideo,
    } = S.webrtc

    const totalSlots = getTotalSlots(computedSelectedLayout.defaultId)
    const invisible =
      (position !== undefined && position > totalSlots) || !isShow
    const small = isSmallPeer
    const markRef = useRef<HTMLDivElement | null>(null)
    const [dimension, setDimension] = useState<{
      width: number
      height: number
    }>({ height: 0, width: 0 })
    const isLocal = peer?.id === S.webrtc.peers[0].id
    const audio = screenshare ? peer?.screenshareAudio : peer?.audio
    const audioTrack = mediaId
      ? peer?.medias?.[mediaId]?.audio?.track
      : audio?.track
    const videoTrack = mediaId
      ? peer?.medias?.[mediaId]?.video?.track
      : screenshare
        ? peer?.screenshareVideo?.track
        : peer?.video?.track

    const videoEnabled =
      mediaId && peer?.data.viewmode === 'ams-client'
        ? amsVideoState[mediaId]?.enableOnRoom
        : isInputPeer(peerId)
          ? input?.status === 'Publishing'
          : !!videoTrack
    let volume: number | undefined =
      S.webrtc.mediaVolumes[mediaId || peerId]?.current

    if (volume === undefined) {
      volume = 1
    }
    if (audio?.muted) {
      volume = 0
    }
    useEffect(() => {
      const obs = new ResizeObserver(entries => {
        entries.forEach(entry => {
          const { width, height } = entry.contentRect
          setDimension({
            width,
            height,
          })
        })
      })

      // Observe the target element
      if (markRef.current) {
        obs.observe(markRef.current)
      }

      // Clean up the observer when the component unmounts
      return () => {
        obs.disconnect()
      }
    }, [])
    const computeVolume = () => {
      const r = { v: volume ?? 1, m: false }
      if (isLocal) {
        r.m = true
        return r
      }
      if (forceMuted) {
        r.m = true
        return r
      }
      if (mediaId) {
        const av = amsVideoState[mediaId]
        if (av) {
          if (av.muted) {
            r.m = true
            return r
          }
          r.v = (r.v * av.volume) / 100
        }
      }
      if (role?.name === 'Observer') {
        const ov = mediaVolumesOriginal[peerId]
        if (ov) {
          r.v = (ov.current ?? 1) * r.v
        }
      }
      if (r.v <= volume0) {
        r.m = true
        return r
      }

      if (small) {
        return r
      }
      if (isViewmodeMixer) {
        // always mute if not on air yet
        if (!onAir) {
          r.m = true
          return r
        }

        if (isShow) {
          r.m = false
          return r
        }

        // mute if stream has overlay - TODO: need apply for role viewer
        if (overlayUrl !== '') {
          r.m = true
          return r
        }
        // mute if invisible (out of layout) or has fullscreen media
        void (invisible || !!fullscreenMediaId)
        r.m = true
        return r // always output audio to mixer no matter what
      }
      // mute if no viewmode - the audio is already added in the small list

      r.m = true
      return r
    }
    const cv = computeVolume()
    // console.log(cv.m,cv.v)
    const handleDeleteInput = async (id: string) => {
      try {
        const result = await reduxStore.context.gql.deleteInput({
          id,
        })
        if (result.error) {
          ToastService.error({ content: 'Failed to delete ' })
          return
        }
      } catch (err) {
        ToastService.error({ content: 'Failed to delete' })
        console.error(err)
      } finally {
        setVisibleKickOut(false)
      }
    }
    const handleKickOut = async () => {
      if (mediaId && peer?.data.viewmode === 'ams-client') {
        updateAmsClientVideo({ onAir: false, mediaId })
        return
      }
      if (peerId === '') {
        return
      }
      if (isInputPeer(peerId)) {
        handleDeleteInput(getNormalId(peerId))
        return
      }
      try {
        const result = await reduxStore.context.gql.deleteUserInSession({
          userId: peer?.data.userId || '',
          sessionId: S.webrtc.sessionId,
        })
        if (result.error) {
          ToastService.error({ content: 'Failed to kickout user' })
          return
        }
        S.webrtc.removePeer(peerId)
        S.webrtc.mediasoup?.protoo.request('kickout', { peerId })
        ToastService.success({
          content: 'User has been kicked out of session',
        })
      } catch (err) {
        ToastService.error({ content: 'Failed to kickout user' })
        console.error(err)
      } finally {
        setVisibleKickOut(false)
      }
    }

    const computeRatio = (px: number) => px * ratioScaleLayout
    const avatarWidth =
      dimension.width > dimension.height
        ? dimension.width / 4
        : dimension.height / 4
    const fontSize = avatarWidth > 128 ? 60 : dimension.width / 8

    if (!peer && !input) {
      return null
    }
    if (isInputPeer(peerId) && input?.status === 'Loading') {
      return (
        <div className={css.Loading}>
          <LoadingOutlined spin />
        </div>
      )
    }
    const copyInputUrl = (e: React.MouseEvent<HTMLElement>) => {
      navigator.clipboard.writeText(input?.url ?? '')
      ToastService.success({ content: 'Copied!' })
    }
    const handleToggleVideo = () => {
      if (mediaId && peer?.data.viewmode === 'ams-client') {
        updateAmsClientVideo({ mediaId, enableOnRoom: !videoEnabled })
        return
      }
      if (isInputPeer(peerId) && !videoTrack) {
        return
      }
      if (screenshare && videoTrack) {
        if (isLocal) {
          S.webrtc.disableScreenshare
          return
        }
        S.webrtc.updateRemotePeer({
          peerId: mediaPeerId,
          command: 'disableScreenshare',
        })
        return
      } else {
        // no need to enable screenshare, the slot is not available when disabled
      }
      if (videoTrack) {
        if (isLocal) {
          S.webrtc.disableCamera()
          return
        }
        S.webrtc.updateRemotePeer({
          peerId: mediaPeerId,
          command: 'disableCamera',
        })
        return
      }
      if (!videoTrack) {
        if (isLocal) {
          S.webrtc.enableCamera()
          return
        }
        S.webrtc.updateRemotePeer({
          peerId: mediaPeerId,
          command: 'enableCamera',
        })
        return
      }
    }
    return (
      <>
        {S.webrtc.showParticipantName && cropMode !== ECropMode.Crop && (
          <span
            className={clsx({
              [css.MediaViewPeerName]: true,
              [css.mediaViewPeerNameSmall]: small,
            })}
            style={{
              fontSize: small ? undefined : S.webrtc.ratioScaleLayout * 12,
              bottom: small ? undefined : S.webrtc.ratioScaleLayout * 12,
              right: small ? undefined : S.webrtc.ratioScaleLayout * 12,
              left: small ? undefined : S.webrtc.ratioScaleLayout * 8,
            }}
          >
            {isInputPeer(peerId) ? `Input ${input?.name}` : peer?.data.name}
          </span>
        )}
        <div
          ref={markRef}
          onMouseOver={small ? () => setControl(true) : undefined}
          onMouseLeave={small ? () => setControl(false) : undefined}
          className={clsx({
            [css.MediaView]: true,
            [css.mediaViewMouseOver]: small && control,
            [css.mediaScreenshare]: screenshare,
            [css.mediaScreenshareLayoutStyle]:
              screenshare && S.webrtc.layoutStyle === 'rounded',
          })}
        >
          {input && input.status === 'Ready' && (
            <span onClick={copyInputUrl} className={css.CopyInput}>
              <Icon icon='Copy' size={12} />
            </span>
          )}
          <div
            className={clsx(css.MediaMask, { [css.MediaMaskNotSmall]: !small })}
          >
            {small && (!isViewmodeParticipant || isLocal) && (
              <MediaControl
                videoEnabled={videoEnabled}
                show={control}
                peerId={peerId}
                screenshare={screenshare}
                onKickOut={
                  S.webrtc.peers[0].id !== peerId
                    ? () => setVisibleKickOut(true)
                    : undefined
                }
                onToggleVideo={handleToggleVideo}
                volume={{
                  ...S.webrtc.mediaVolumes[peerId],
                  current: volume,
                  isLocal,
                }}
                onVolumeChange={v =>
                  S.webrtc.updateMediaVolume(mediaId || peerId, v)
                }
                isMainCanvas={isMainCanvas}
              />
            )}

            <PopoverKickOut
              placement='bottom'
              visible={visibleKickOut}
              onVisibleKickOut={(status: boolean) => setVisibleKickOut(status)}
              onYes={handleKickOut}
              isInput={isInputPeer(peerId)}
            >
              <span className={css.MediaViewPopoverKickOut} />
            </PopoverKickOut>
          </div>
          <Player
            key={peerId}
            audioTrack={audioTrack}
            muted={cv.m}
            id={peerId}
            showVolumeLevel={small}
            volume={cv.v}
            videoTrack={videoEnabled ? videoTrack : undefined}
            screenshare={screenshare}
            videoStyle={videoStyle}
            isSlotSmall={small}
          />
        </div>

        {!videoEnabled &&
          !isInputPeer(peerId) &&
          renderAvatar(
            {
              id: (isInputPeer(peerId) ? input?.id : peer?.id) ?? '',
              name: isInputPeer(peerId) ? input?.name : peer?.data.name,
              avatar: isInputPeer(peerId) ? '' : peer?.data.avatar || '',
            },
            {
              width: small ? '40px' : avatarWidth > 128 ? 128 : avatarWidth,
              height: small ? '40px' : avatarWidth > 128 ? 128 : avatarWidth,
              lineHeight: small ? '40px' : `${computeRatio(128)}px`,
              fontSize: small ? '24px' : fontSize,
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          )}
        {!small &&
          isHoverSlot &&
          (cropMode !== ECropMode.Crop || !videoEnabled) && (
            <SlotIconAction
              position={position || 0}
              slotType='peer'
              slotPeerId={peerId}
            />
          )}
      </>
    )
  },
)
