import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { CSSProperties } from 'react'

import LogoWaterMark from '../../../assets/images/Beam_Watermark.png'
import css from './LiveStreamWaterMark.module.scss'

import { ModalService } from '../../../components/Modal/Modal'
import { S } from '../../../context/store'
import { cropboxH, cropboxW } from '../../../context/store/studio/WebrtcStore'
import { ModalChangePlan } from '../../SubscriptionPlan/components/ModalChangePlan'

export const LiveStreamWatermark = observer(
  ({ type = 'default', style }: { type?: string; style?: CSSProperties }) => {
    const { ratioScaleLayout, isHoverTitleBottom, plan, isFullScreenSite } =
      S.webrtc
    const { showOverlayBox } = S.layout
    const isEnterprise = plan?.plan === 'Enterprise'
    const havePay =
      plan?.plan !== 'Freemium' || plan.boughtMinutes || plan.boughtStorage
    if (isHoverTitleBottom || havePay || isEnterprise) {
      return null
    }

    const gotoUpgradePlan = () => {
      if (isFullScreenSite) {
        return
      }
      ModalService.show(ModalChangePlan)
    }
    return (
      <div
        id='LiveStreamWatermark'
        className={clsx({
          [css.ContainerWaterMark]: true,
          [css.ContainerWaterMarkBeforeHide]: showOverlayBox,
        })}
        onClick={gotoUpgradePlan}
        style={{
          bottom: type === 'slot' ? 0 : `${ratioScaleLayout * 6}px`,
          right: type === 'slot' ? 0 : `${ratioScaleLayout * 8}px`,
          width: `${cropboxW * ratioScaleLayout * 0.5}px`,
          height: `${cropboxH * ratioScaleLayout * 0.5}px`,
          ...style,
        }}
      >
        <img className={css.ImageWatermark} src={LogoWaterMark} />
        {!showOverlayBox && (
          <span className={css.ItemIconDelete} onClick={gotoUpgradePlan} />
        )}
      </div>
    )
  },
)
