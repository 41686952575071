import FacebookImg from '../../../assets//images/destination/facebook.svg'
import InstagramImg from '../../../assets//images/destination/instagram.svg'
import CustomDestination from '../../../assets/images/destination/custom.svg'
import GoogleMeetImg from '../../../assets/images/destination/GoogleMeet.png'
import IbmImg from '../../../assets/images/destination/ibm.png'
import KalturaImg from '../../../assets/images/destination/Kaltura.png'
import LinkedInImg from '../../../assets/images/destination/linkedin.svg'
import MSTeamImg from '../../../assets/images/destination/MicrosoftTeams.png'
import On24Img from '../../../assets/images/destination/ON24.png'
import TwitchImg from '../../../assets/images/destination/Twitch.png'
import VimeoImg from '../../../assets/images/destination/Vimeo.png'
import Webex from '../../../assets/images/destination/Webex.svg'
import YoutubeImg from '../../../assets/images/destination/youtube.svg'
import ZoomImg from '../../../assets/images/destination/Zoom.png'

import { OutputType } from './DestinationEnum'

export interface IDestinationChanel {
  name: string
  icon: string
}
export const DestinationDataChannel: IDestinationChanel[] = [
  {
    name: 'youtube',
    icon: YoutubeImg,
  },
  {
    name: 'linkedin',
    icon: LinkedInImg,
  },
  {
    name: 'facebook',
    icon: FacebookImg,
  },
  {
    name: 'vimeo',
    icon: VimeoImg,
  },
  {
    name: 'ibm',
    icon: IbmImg,
  },
  {
    name: 'kaltura',
    icon: KalturaImg,
  },
  {
    name: 'on24',
    icon: On24Img,
  },
  {
    name: 'twitch',
    icon: TwitchImg,
  },
  {
    name: 'zoom',
    icon: ZoomImg,
  },
  {
    name: 'teams',
    icon: MSTeamImg,
  },
  {
    name: 'meet',
    icon: GoogleMeetImg,
  },
  {
    name: 'webex',
    icon: Webex,
  },
]
export const rtmpsSupport = [
  {
    name: 'Facebook',
    icon: FacebookImg,
    type: OutputType.Facebook,
  },
  {
    name: 'Linkedin',
    icon: LinkedInImg,
    type: OutputType.Linkedin,
  },
  {
    name: 'Youtube',
    icon: YoutubeImg,
    type: OutputType.Youtube,
  },
  {
    name: 'Instagram',
    icon: InstagramImg,
    type: OutputType.Instagram,
  },
  {
    name: 'Custom',
    icon: CustomDestination,
    type: OutputType.Custom,
  },
]
export const rtmpsCustomSupport = [
  {
    name: 'RTMP',
    icon: null,
    type: OutputType.RTMP,
  },
  {
    name: 'SRT',
    icon: null,
    type: OutputType.SRT,
  },
]
