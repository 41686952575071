import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import css from './CustomLayoutOverlay.module.scss'

import { addSlotsToGroup } from '../../actions/layout/addSlotsToGroup'
import { changeOverlayBox } from '../../actions/layout/changeOverlayBox'
import { changeSlotEditing } from '../../actions/layout/changeSlotEditing'
import { clearGroupSlot } from '../../actions/layout/clearGroupSlot'
import { S } from '../../store'
import type { ILayoutItem } from '../../store/shared/LayoutStore'

interface IOverLayState {
  width: number
  height: number
  top: number
  left: number
  bottom: number
  right: number
}
interface IProps {
  containerProps: {
    width: number
    height: number
    x: number
    y: number
  }
  layoutId: string
  slots: ILayoutItem[]
}
const initState = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
}
export const ResizeMultipleOverlay: React.FC<IProps> = observer(
  ({ containerProps, slots, layoutId }) => {
    const { height, width, x, y } = containerProps
    const { showOverlayBox, slotsEdit } = S.layout
    const elementReft = useRef<HTMLDivElement | null>(null)
    const [overlayStyle, setOverlayStyle] = useState<IOverLayState>(initState)
    const isMove = useRef(false)
    const initialPositionRef = useRef({ x: 0, y: 0 })
    const layoutSelected = slotsEdit[Number(layoutId)] ?? []
    const slotEditing = layoutSelected.find(s => s.enableEdit)
    const [canHandle, setCanHandle] = useState(false)
    const initialOverlayStyleRef = useRef<IOverLayState>(overlayStyle)

    const handleMouseMove = (e: globalThis.MouseEvent) => {
      e.stopPropagation()
      const { left, top } = initialOverlayStyleRef.current
      const dx = e.clientX - initialPositionRef.current.x
      if (Math.abs(dx) < 50) {
        setCanHandle(false)
      } else {
        setCanHandle(true)
      }
      const dy = e.clientY - initialPositionRef.current.y
      const topD = dy < 0 ? 0 : top
      const bottomD = dy < 0 ? height + y - (top + y) : 0
      const rightD = dx < 0 ? width + x - (left + x) : 0
      const leftD = dx < 0 ? 0 : left
      if (isMove.current) {
        setOverlayStyle({
          ...initialOverlayStyleRef.current,
          width: Math.abs(dx),
          height: Math.abs(dy),
          top: topD,
          bottom: bottomD,
          left: leftD,
          right: rightD,
        })
      }
    }
    useEffect(() => {
      if (isMove.current && overlayStyle.width > 0 && elementReft.current) {
        const layoutsToEdit = slots.filter(item => {
          const { height: iHeight, left, top, width: iWidth } = item
          const oL = elementReft.current?.offsetLeft ?? 0
          const oT = elementReft.current?.offsetTop ?? 0
          const { width: oW, height: oH } = overlayStyle
          if (
            oT + oH > top &&
            oL + oW > left &&
            top + iHeight > oT &&
            left + iWidth > oL
          ) {
            return true
          } else {
            return false
          }
        })

        if (layoutsToEdit.length === 1) {
          clearGroupSlot(layoutId)
          changeSlotEditing(layoutsToEdit[0].id, Number(layoutId))
        } else if (layoutsToEdit.length > 1) {
          changeSlotEditing(-1, Number(layoutId))
          addSlotsToGroup(
            layoutsToEdit.map(l => l.id),
            Number(layoutId),
            true,
          )
        } else {
          if (canHandle) {
            changeSlotEditing(-1, Number(layoutId))
          }
        }
      }
    }, [overlayStyle, slotEditing, canHandle])
    const handleMouseUp = (e: globalThis.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      isMove.current = false
      changeOverlayBox(false)
      initialOverlayStyleRef.current = initState
      setOverlayStyle(initState)
    }
    const handleMouseDown = (e: globalThis.MouseEvent) => {
      e.stopPropagation()
      if (e?.target === document.getElementById('LiveStreamBackgroundColor')) {
        e.preventDefault()
      }
      isMove.current = true
      changeOverlayBox(true)
      initialPositionRef.current = { x: e.clientX, y: e.clientY }
      initialOverlayStyleRef.current = {
        ...initialOverlayStyleRef.current,
        top: e.clientY - y,
        left: e.clientX - x,
      }
      setOverlayStyle({
        ...overlayStyle,
        top: e.clientY - y,
        left: e.clientX - x,
      })
    }

    useEffect(() => {
      if (x !== 0 && y !== 0) {
        addEventListener('mousedown', handleMouseDown)
        addEventListener('mouseup', handleMouseUp)
        addEventListener('mousemove', handleMouseMove)
      }

      return () => {
        removeEventListener('mousedown', handleMouseDown)
        removeEventListener('mouseup', handleMouseUp)
        removeEventListener('mousemove', handleMouseMove)
      }
    }, [x, y])
    return (
      <div
        ref={showOverlayBox ? elementReft : undefined}
        style={{
          top: overlayStyle.top !== 0 ? overlayStyle.top : undefined,
          left: overlayStyle.left !== 0 ? overlayStyle.left : undefined,
          right: overlayStyle.right,
          bottom: overlayStyle.bottom,
          width: overlayStyle.width > width ? width : overlayStyle.width,
          height: overlayStyle.height > height ? height : overlayStyle.height,
          pointerEvents: 'none',
        }}
        className={showOverlayBox ? css.OverlayMultiple : undefined}
      ></div>
    )
  },
)
