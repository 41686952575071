import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import type { Range } from 'react-quill'
import type ReactQuill from 'react-quill'

import css from './LiveStreamTitle.module.scss'

import { QuillEditor } from '#HACK_FOR_RN_ONLY/src/components/composites/Editor'

import { S } from '../../../context/store'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { changeTitleItem } from '../actions/changeTitleItem'
import { toggleEnableEditTitle } from '../actions/toggleDisableEditTitle'

type Props = {
  item: TitleItem
  layoutId?: number
  readOnly?: boolean
  focus?: boolean
}

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'color',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'div',
  'script',
  'blockquote',
  'code',
  'formula',
  'image-blank',
  'addImage',
  'addImageCustom',
]

const modules = {
  toolbar: false,
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  addImageCustom: {},
}

export const LiveStreamTitle: FC<Props> = observer(
  ({ item, readOnly, focus, layoutId }) => {
    const quillRef = useRef<ReactQuill | null>(null)
    const colorRef = useRef(item.style?.color)
    const styleRef = useRef(item.style)
    const focusRef = useRef(true)
    const willFormat = useRef(false)
    const selectionSelected = useRef<Range | undefined>(undefined)
    const { ratioScaleLayout } = S.webrtc

    useEffect(() => {
      if (selectionSelected.current && selectionSelected.current.length > 0) {
        quillRef.current?.editor?.formatText(
          selectionSelected.current?.index,
          selectionSelected.current?.length,
          'bold',
          item.style?.isBold ? true : false,
        )
      }

      if (focus && item.style?.isBold !== styleRef.current?.isBold) {
        quillRef.current?.editor?.format(
          'bold',
          item.style?.isBold ? true : false,
        )
        styleRef.current = { ...styleRef.current, isBold: item.style?.isBold }
      }
    }, [item.style?.isBold, focus])
    useEffect(() => {
      if (selectionSelected.current && selectionSelected.current.length > 0) {
        quillRef.current?.editor?.formatText(
          selectionSelected.current?.index,
          selectionSelected.current?.length,
          'italic',
          item.style?.isItalic ? true : false,
        )
      }

      if (focus && item.style?.isItalic !== styleRef.current?.isBold) {
        quillRef.current?.editor?.format(
          'italic',
          item.style?.isItalic ? true : false,
        )
      }
    }, [item.style?.isItalic, focus])
    useEffect(() => {
      if (item.style?.color !== styleRef.current?.color) {
        quillRef.current?.editor?.format('color', item.style?.color)
        styleRef.current = { ...styleRef.current, color: item.style?.color }
      }
    }, [item.style?.color])
    useEffect(() => {
      if (!readOnly) {
        styleRef.current = item.style
      }
    }, [readOnly])

    useEffect(() => {
      if (selectionSelected.current && selectionSelected.current.length > 0) {
        quillRef.current?.editor?.formatText(
          selectionSelected.current?.index,
          selectionSelected.current?.length,
          'size',
          `${item.style?.fontSize}px`,
        )
      }
      if (focus && item.style?.fontSize !== styleRef.current?.fontSize) {
        quillRef.current?.editor?.format(
          'size',
          `${Math.round((item.style?.fontSize ?? 1) * ratioScaleLayout)}px`,
        )
        styleRef.current = {
          ...styleRef.current,
          fontSize: item.style?.fontSize,
        }
      }
    }, [item.style?.fontSize, focus])

    useEffect(() => {
      if (selectionSelected.current && selectionSelected.current.length > 0) {
        quillRef.current?.editor?.formatText(
          selectionSelected.current?.index,
          selectionSelected.current.length,
          'font',
          item.style?.font,
        )
      }
      if (focus && item.style?.font !== styleRef.current?.font) {
        quillRef.current?.editor?.format('font', item.style?.font)
        styleRef.current = { ...styleRef.current, font: item.style?.font }
      }
    }, [item.style?.font, focus])

    useEffect(() => {
      if (
        (colorRef.current !== item.style?.color && focus) ||
        willFormat.current ||
        !item.content.trim().length
      ) {
        quillRef.current?.editor?.format('color', item.style?.color)
        colorRef.current = item.style?.color
        willFormat.current = false
      }
    }, [item.style?.color, focus, readOnly, item.content])

    useEffect(() => {
      if (!quillRef.current) {
        return
      }
      quillRef.current.editor?.on(
        'selection-change',
        (
          range: { index: number; length: number },
          oldRange: { index: number; length: number },
          source: string,
        ) => {
          if (!quillRef.current || !range) {
            return
          }
          const c = quillRef.current.editor?.getContents(
            range.index,
            range.length + 1,
          )
          const c1 = quillRef.current.editor?.getContents(
            range.index - 1,
            range.length + 1,
          )
          if (c?.ops?.[0].insert !== ' ' || c1?.ops?.[0].insert !== ' ') {
            handleSelectionChange(range)
          }
        },
      )
    }, [])
    useEffect(() => {
      if (!readOnly) {
        quillRef.current?.focus()
      }
    }, [readOnly])

    useEffect(() => {
      selectionSelected.current = quillRef.current?.getEditorSelection()
    }, [focus])
    useEffect(() => {
      if (readOnly) {
        willFormat.current = false
      } else {
        willFormat.current = true
      }
    }, [readOnly])
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && focusRef.current) {
        toggleEnableEditTitle(false)
      }
    }
    useEffect(() => {
      quillRef.current?.editor?.root.addEventListener('keydown', handleKeyDown)
      return () =>
        quillRef.current?.editor?.root.removeEventListener(
          'keydown',
          handleKeyDown,
        )
    }, [focus])
    const handleSelectionChange = (s?: { index: number; length: number }) => {
      if (!s || s.length > 0) {
        return
      }
      const f = quillRef.current?.editor?.getFormat(s)

      selectionSelected.current = s
      if (f?.color !== styleRef.current?.color) {
        styleRef.current = { ...styleRef.current, color: f?.color }
        changeTitleItem(item.id, {
          style: { ...styleRef.current, color: f?.color },
        })
      }
      if (f?.size && f?.size !== `${styleRef.current?.fontSize}px`) {
        const size =
          f?.size && typeof f?.size === 'string'
            ? Number(f?.size?.replace(/px/g, '')) / S.webrtc.ratioScaleLayout
            : 24

        styleRef.current = { ...styleRef.current, fontSize: size }

        changeTitleItem(item.id, {
          style: {
            ...styleRef.current,
            fontSize: size,
          },
        })
      } else if (!f?.size && styleRef.current?.fontSize) {
        quillRef.current?.editor?.format(
          'size',
          `${styleRef.current?.fontSize}px`,
        )
      }
      if (f?.font && f?.font !== styleRef.current?.font) {
        styleRef.current = { ...styleRef.current, font: f?.font }
        changeTitleItem(item.id, {
          style: {
            ...styleRef.current,
            font: f?.font ?? 'Poppins',
          },
        })
      } else if (!f?.font && styleRef.current?.font) {
        quillRef.current?.editor?.format('font', styleRef.current.font)
      }
      if (f?.bold !== styleRef.current?.isBold) {
        styleRef.current = { ...styleRef.current, isBold: f?.bold }
        changeTitleItem(item.id, {
          style: {
            ...styleRef.current,
            isBold: f?.bold,
          },
        })
      }
      if (f?.italic !== styleRef.current?.isItalic) {
        changeTitleItem(item.id, {
          style: {
            ...styleRef.current,
            isItalic: f?.italic,
          },
        })
      }
    }

    const computedValue = (data: any, isInput?: boolean) => {
      const ops = (data?.ops ?? []).map(o => {
        const attrs = { ...o.attributes }
        if (attrs?.indent) {
          attrs.indent = Math.round(
            isInput
              ? attrs.indent * ratioScaleLayout
              : attrs.indent / ratioScaleLayout,
          )
        }
        if (attrs?.size) {
          const s =
            typeof attrs.size === 'string'
              ? Number(attrs.size?.replace(/px/g, ''))
              : 0
          attrs.size = `${Math.round(isInput ? s * ratioScaleLayout : s / ratioScaleLayout)}px`
        }
        const newO = {
          ...o,
        }
        const attrsMap = {
          ...o.attributes,
          ...attrs,
        }
        if (!isEmpty(attrsMap)) {
          newO.attributes = attrsMap
        }
        return newO
      })
      return {
        ...data,
        ops,
      }
    }
    const v = useMemo(
      () => computedValue(item.contentOnAir, true),
      [item.contentOnAir, ratioScaleLayout],
    )

    return (
      <div
        style={{
          borderWidth: item.style?.borderWidth ?? 0,
          borderColor: item.style?.borderColor ?? 'rgba(38, 86, 201, 1)',
          borderStyle: 'solid',
          background: item.style?.backgroundColor,
          padding: 8,
        }}
        className={css.TitleOnCanvas}
      >
        <QuillEditor
          formats={formats}
          readOnly={readOnly || !focus}
          modules={modules}
          onKeyDown={e => {
            e.stopPropagation()
          }}
          value={v}
          onChange={(_, delta, source, editor) => {
            if (S.webrtc.isViewmodeMixer) {
              return
            }
            const s = editor.getSelection()
            handleSelectionChange(s)
            const content = editor.getText()
            const cv = computedValue(editor.getContents())
            changeTitleItem(item.id, {
              content: content !== undefined ? content : item.content,
              contentOnAir: cv,
            })
          }}
          ref={quillRef}
          className={css.QuillEditor}
          theme='snow'
        />
      </div>
    )
  },
)
