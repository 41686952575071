import type { TGradientColors } from './WebrtcStore'

interface RgbColor {
  r: number
  g: number
  b: number
}
interface RgbaColor extends RgbColor {
  a: number
}
const matcherHexColor = /^#?([0-9A-F]{3,8})$/i

const formatStringColor = (number: number) => {
  const hex = number.toString(16)
  return hex.length < 2 ? '0' + hex : hex
}

export const validHex = (value: string) => {
  const match = matcherHexColor.exec(value)
  const length = match ? match[1].length : 0

  return (
    length === 3 || // '#rgb' format
    length === 6 // '#rrggbb' format
  )
}

export const rgbToHex = ({ r, g, b }: RgbColor): string =>
  '#' + formatStringColor(r) + formatStringColor(g) + formatStringColor(b)

export const rgbaToString = ({ r, g, b, a }: RgbaColor): string =>
  `rgba(${r},${g},${b},${a})`

export const hexToRgb = (hex: string) => {
  if (hex === 'transparent') {
    return {
      r: 0,
      g: 0,
      b: 0,
    }
  }
  if (hex[0] === '#') {
    hex = hex.substr(1)
  }

  if (hex.length < 6) {
    return {
      r: parseInt(hex[0] + hex[0], 16),
      g: parseInt(hex[1] + hex[1], 16),
      b: parseInt(hex[2] + hex[2], 16),
    }
  }

  return {
    r: parseInt(hex.substr(0, 2), 16),
    g: parseInt(hex.substr(2, 2), 16),
    b: parseInt(hex.substr(4, 2), 16),
  }
}

export const hexToRgbString = (hex: string) => {
  const { r, g, b } = hexToRgb(hex)
  return `rgb(${r},${g},${b})`
}

export const rgbaStringToObject = (color: string) => {
  if (color === 'transparent') {
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    }
  }
  if (color.includes('#')) {
    return {
      ...hexToRgb(color),
      a: 1,
    }
  }
  if (!color.includes('rgba')) {
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    }
  }
  const rgba = color.replace(/[rgba)( ]/g, '').split(',')
  if (rgba.length === 4) {
    return {
      r: +rgba[0],
      g: +rgba[1],
      b: +rgba[2],
      a: +rgba[3],
    }
  }

  return {
    r: 0,
    g: 0,
    b: 0,
    a: 0,
  }
}

export const rgbaToHex = (color: string) => {
  const { r, g, b } = rgbaStringToObject(color)
  const rgb = { r, g, b }
  return rgbToHex(rgb)
}

export const parseStrGradientToObject = (gradientColor: string) => {
  if (
    gradientColor === '' ||
    !gradientColor.includes('linear-gradient') ||
    gradientColor.includes('rgb')
  ) {
    return null
  }
  const t = gradientColor.replace('linear-gradient(', '').replace(')', '')
  const r = t.split(',')
  let rotate = 90
  const colors: TGradientColors[] = []
  if (r.length >= 3) {
    rotate = parseFloat(r[0].replace('deg', ''))
    r.shift()
    r.forEach((item, index) => {
      const parseColor = item.trim().split(' ')
      colors.push({
        id: index + 1,
        color: parseColor[0],
        offset: parseFloat(parseColor[1].replace('%', '')) / 100,
      })
    })
  }
  return {
    rotate,
    colors,
  }
}

const compareGradientColor = (first: string, second: string): boolean => {
  if (
    !first.includes('linear-gradient') ||
    !second.includes('linear-gradient')
  ) {
    return false
  }
  const gradientFirst = parseStrGradientToObject(first) || null
  const gradientSecond = parseStrGradientToObject(second) || null
  const colorsFirst = gradientFirst?.colors || null
  const colorsSecond = gradientSecond?.colors || null
  const degFirst = gradientFirst?.rotate || null
  const degSecond = gradientSecond?.rotate || null
  if (
    colorsFirst === null ||
    colorsSecond === null ||
    colorsFirst.length !== colorsSecond.length ||
    degFirst === null ||
    degSecond === null ||
    degFirst !== degSecond
  ) {
    return false
  }
  let isSame = true
  colorsFirst.forEach((_, index) => {
    if (
      !compareColors(colorsFirst[index].color, colorsSecond[index].color) ||
      colorsFirst[index].offset !== colorsSecond[index].offset
    ) {
      isSame = false
    }
  })
  return isSame
}
export const compareColors = (first: string, second: string): boolean => {
  if (first === '' || second === '') {
    return false
  }
  if (first.includes('linear-gradient') || second.includes('linear-gradient')) {
    return compareGradientColor(first, second)
  }
  const firstHexToRgb = validHex(first)
    ? hexToRgb(first)
    : rgbStringToRgb(first)
  const secondHexToRgb = validHex(second)
    ? hexToRgb(second)
    : rgbStringToRgb(second)

  return equalColorObjects(firstHexToRgb, secondHexToRgb)
}

export const equalColorObjects = (first: RgbColor, second: RgbColor) =>
  first.r === second.r && first.g === second.g && first.b === second.b

export const rgbStringToRgb = (rgbaString: string) => {
  const matcher =
    /rgba?\(?\s*(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?,?\s*[/\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i
  const match = matcher.exec(rgbaString)

  if (!match) {
    return { r: 0, g: 0, b: 0 }
  }

  return {
    r: Number(match[1]) / (match[2] ? 100 / 255 : 1),
    g: Number(match[3]) / (match[4] ? 100 / 255 : 1),
    b: Number(match[5]) / (match[6] ? 100 / 255 : 1),
  }
}
