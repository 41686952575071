const getEnvConfig = (key: string, defaultValue?: string) => {
  const value = process.env[key]
  if (value !== undefined) {
    return value
  }
  return window.appConfig?.[key] || defaultValue

}

export const reactServerOrigin = getEnvConfig('REACT_APP_SERVER_ORIGIN', 'https://dev.beings.com');

export const reactAppVersion = 'beam-0.1.49'.replace('beam-', 'v')

export const reactDeploymentEnv = (function () {
  let environment;
  if (reactServerOrigin.includes('app.beings.com')) {
    environment = 'beta'; // TODO - add prod env and update this
  }
  else if (reactServerOrigin.includes('dev')) {
    environment = 'dev';
  } else if (reactServerOrigin.includes('alpha')) {
    environment = 'alpha';
  } else if (reactServerOrigin.includes('beta') || reactServerOrigin.includes('go.')) {
    environment = 'beta';
  } else {
    environment = 'dev';
  }
  return environment;
})();

export const reactRunningOnAlpha = reactDeploymentEnv === 'alpha'
export const reactRunningOnBeta = reactDeploymentEnv === 'beta'
export const reactRunningOnDev = reactDeploymentEnv === 'dev'

export const hiddenFeatures = true

export const googleFontKey = 'AIzaSyCJk1EX6faPb_Sa0HJqLsPI0ux8iYpJCvI'

declare global {
  interface MediaDeviceInfo {
    name?: string
  }
  interface HTMLMediaElement {
    setSinkId(id: string): Promise<void>
  }

  interface Window {
    appConfig: {
      REACT_APP_SERVER_ORIGIN?: string
    }
    fbAsyncInit: () => void
  }
}

export const REFERRAL_CODE = 'referral-code'
export const LIMIT = 10
export const LIMIT_AVATAR_SIZE = 5
export const SUPPORT_FILE_TYPE_AVATAR = ['image/jpeg', 'image/png', 'image/jpg']
export const MAILFORMAT =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const SUCCESS_CHANGE_SUBSCRIPTION_KEY = 'successChangePlan'
export const STRIPE_PRICES = {
  premium: {
    month: {
      gbp: 1200,
      usd: 1600,
    },
    year: {
      gbp: 12240,
      usd: 16320,
    },
    professional: {
      month: {
        gbp: 35,
        usd: 47,
      },
      year: {
        gbp: 357,
        usd: 476,
      },
    },
  },
}
export const WARMNESS_SLIDER_HEIGHT = 120
export const GRADIENTS_WARMNESS_COLOR_WHITE: any[][] = [
  [0, [225, 248, 255]],
  [30, [255, 255, 255]],
  [100, [242, 171, 60]],
]
export const GRADIENTS_WARMNESS_COLOR_GRAY: any[][] = [
  [0, [211, 243, 252]],
  [30, [243, 242, 242]],
  [100, [232, 155, 56]],
]

export const GRADIENTS_WARMNESS_COLOR_THUMBNAIL: any[][] = [
  [0, [202, 238, 248]],
  [30, [243, 242, 242]],
  [100, [231, 154, 55]],
]
export const GRADIENTS_WARMNESS_PRESSED: any[][] = [
  [0, [187, 221, 233]],
  [30, [224, 224, 227]],
  [100, [214, 144, 55]],
]
export const GRADIENTS_WARMNESS_HOVER: any[][] = [
  [0, [195, 230, 241]],
  [30, [234, 233, 234]],
  [100, [223, 149, 56]],
]
export const LAYOUT_WIDTH_BASE = 760
export const RATIO_SCALE_LAYOUT_SMALL = 88 / LAYOUT_WIDTH_BASE
export const DEBOUNCE_TIME = 300
export const HIDDEN_FOR_NEXT_FEATURES = true
export const BUY_MORE_MIN = 0.5

export const GOOGLE_CLIENT_ID = getEnvConfig('REACT_APP_GOOGLE_CLIENT_ID', '1071128661214-rhrp5qmh0j55fnfa1sdq2rj2ujvmtmbt.apps.googleusercontent.com');
export const GCP_RECAPTCHA_SITEKEY = getEnvConfig('REACT_APP_GCP_RECAPTCHA_SITEKEY', '6LfTcVwqAAAAADMDgsS_X0QxwoEQekv8dirRjxpy')

export const REACT_APP_PUBLIC_POSTHOG_KEY = "phc_7RV4sgO0Hzw6NrLZeajusaqP0NSJ3algsCJ6whs7lOb"
export const REACT_APP_PUBLIC_POSTHOG_HOST = "https://us.i.posthog.com"

export const REVAI_CONFIG = {
  baseUrl: 'wss://api.rev.ai/speechtotext/v1alpha/stream',
}
export const LINKED_LIVE_STATE_SCOPE =
  'r_member_live w_member_live r_liteprofile w_organization_live r_organization_live r_organization_admin'
export const LINKEDIN_LIVE_CLIENT_ID = '786679koqyu27t'
export const LINKEDIN_LIVE_STATE_CODE = 'pmGfSvVuCC4acZu4Mx9o'

export const LINKEDIN_AUTH_CLIENT_ID = getEnvConfig('REACT_APP_LINKEDIN_AUTH_CLIENT_ID', '86lpgndhwmghpb');
export const LINKEDIN_AUTH_STATE_CODE = getEnvConfig('REACT_APP_LINKEDIN_AUTH_STATE_CODE', 'zYvZwUZUAJYab8fP4qrn');
export const LINKED_AUTH_STATE_SCOPE = 'openid profile email'

export const FACEBOOK_AUTH_CLIENT_ID = getEnvConfig('REACT_APP_FACEBOOK_AUTH_CLIENT_ID', '1530736947516541');
export const FACEBOOK_AUTH_STATE_CODE = getEnvConfig('REACT_APP_FACEBOOK_AUTH_STATE_CODE', 'hXozAWoCVXc8te');
export const FACEBOOK_AUTH_STATE_SCOPE = 'publish_video,email'

export const MICROSOFT_CLIENT_ID = getEnvConfig('REACT_APP_MICROSOFT_CLIENT_ID', 'a3f26c0b-bd6b-430d-a188-eb11164468ad');
export const MICROSOFT_STATE_CODE = getEnvConfig('REACT_APP_MICROSOFT_STATE_CODE', '0V6JzfjYp7mlQi');
export const MICROSOFT_STATE_SCOPE = [
  'Calendars.ReadWrite',
  'openid',
  'offline_access',
  'User.Read',
]

console.log('REACT_APP_ENV: ', getEnvConfig('REACT_APP_ENV') + '-' + getEnvConfig('REACT_APP_LOG_VER'))