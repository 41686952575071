import Input from 'antd/lib/input'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import { forwardRef, memo, useState } from 'react'

import css from './style.module.scss'

import { CONSTANTS } from '../../../styles/Constants'
import { Icon } from '../../base/Icon'
import { Space } from '../../base/Space'
import { BeamText } from '../../base/Text'
import type { InputProps } from './type'

const getStatusColor = (status: 'normal' | 'error' | undefined) => {
  if (status === 'normal') {
    return 'rgba(255, 255, 255, 0.16)'
  }
  if (status === 'error') {
    return CONSTANTS.statusColor.danger
  }
  return 'rgba(255, 255, 255, 0.16)'
}
const getStatusEventColor = (status: 'normal' | 'error' | undefined) => {
  if (status === 'normal') {
    return 'rgba(4, 16, 27, 0.16)'
  }
  if (status === 'error') {
    return CONSTANTS.statusColor.danger
  }
  return 'rgba(4, 16, 27, 0.16)'
}

export const BeamInput = memo(
  forwardRef<any, InputProps>((props: InputProps, ref) => {
    const { containerProps, type, ...inputProps } = props
    const [visiblePassword, setVisiblePassword] = useState(false)

    const getSuffix = () => (
      <Space size={4}>
        <span
          className={clsx('iconInput', {
            none: props.status !== 'error',
          })}
        >
          <Icon icon='Shape1' size={16} disableFill={false} />
        </span>

        {type === 'password' ? (
          <span
            onClick={() => setVisiblePassword(!visiblePassword)}
            className={clsx('toggle-password')}
          >
            <Icon
              icon={visiblePassword ? 'icon_view' : 'icon_unview'}
              size={14}
              disableFill={false}
            />
          </span>
        ) : null}
      </Space>
    )

    return (
      <div
        style={
          {
            '--bdclAntInputAffixWrapperHover': getStatusColor(
              inputProps.status,
            ),
            '--bsdntInputAffixWrapperFocus': getStatusEventColor(
              inputProps.status,
            ),
            '--bdAntInputAffixWrapperFocus': getStatusEventColor(
              inputProps.status,
            ),
          } as CSSProperties
        }
        data-after-content={containerProps?.rightHelperText ?? ''}
        className={clsx(
          css.InputContainer,
          { [css.InputWithPostfixContainer]: containerProps?.postfix },
          containerProps?.className,
        )}
        // @ts-ignore
        status={inputProps.status}
      >
        {type === 'password' ? (
          <Input
            {...inputProps}
            className={clsx(css.InputStyled, inputProps.className)}
            style={
              {
                '--bdclInputStyled': getStatusColor(inputProps.status),
                '--bsdInputStyledFocus': getStatusEventColor(inputProps.status),
                '--bdclInputStyledFocus': getStatusEventColor(
                  inputProps.status,
                ),
                '--bdclInputStyledHover': getStatusEventColor(
                  inputProps.status,
                ),
                '--hInputStyled': (inputProps.height ?? 40) + 'px',
                ...inputProps.style,
              } as CSSProperties
            }
            ref={ref}
            suffix={getSuffix()}
            type={visiblePassword ? 'text' : 'password'}
            autoComplete='off'
          />
        ) : (
          <>
            <Input
              ref={ref}
              {...inputProps}
              className={clsx(
                css.InputStyled,
                { [css.InputWithPostFix]: containerProps?.postfix },
                inputProps.className,
              )}
              style={
                {
                  background: inputProps.style?.background,
                  '--bdclInputStyled': getStatusColor(inputProps.status),
                  '--bsdInputStyledFocus': getStatusEventColor(
                    inputProps.status,
                  ),
                  '--bdclInputStyledFocus': getStatusEventColor(
                    inputProps.status,
                  ),
                  '--bdclInputStyledHover': getStatusEventColor(
                    inputProps.status,
                  ),
                  '--hInputStyled': (inputProps.height ?? 40) + 'px',
                  ...inputProps.style,
                } as CSSProperties
              }
              suffix={getSuffix()}
              type={type}
              autoComplete='off'
            />
            {containerProps?.postfix && (
              <div
                className={clsx(
                  css.PostFixContainer,
                  containerProps?.postfixClassName,
                )}
              >
                <BeamText>{containerProps.postfix}</BeamText>
              </div>
            )}
          </>
        )}
      </div>
    )
  }),
)
