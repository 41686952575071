import type { MediaControl } from '#rn-shared/mediasoup/type'

import { S } from '../../../context/store'

export const updateLocalMediaControlData = (
  id,
  data: Partial<MediaControl>,
) => {
  const $ = S.webrtc
  $.mediaControlData = $.mediaControlData.map(m =>
    m.id === id ? { ...m, ...data } : m,
  )
}
