const attachDataValues = (element: any, data: any, dataAttributes: any) => {
  const mention = element
  Object.keys(data).forEach(key => {
    if (dataAttributes.indexOf(key) > -1) {
      mention.dataset[key] = data[key]
    } else {
      delete mention.dataset[key]
    }
  })
  return mention
}

const getMentionCharIndex = (text: any, mentionDenotationChars: any) =>
  mentionDenotationChars.reduce(
    (prev: any, mentionChar: any) => {
      const mentionCharIndex = text.lastIndexOf(mentionChar)

      if (mentionCharIndex > prev.mentionCharIndex) {
        return {
          mentionChar,
          mentionCharIndex,
        }
      }
      return {
        mentionChar: prev.mentionChar,
        mentionCharIndex: prev.mentionCharIndex,
      }
    },
    { mentionChar: null, mentionCharIndex: -1 },
  )

const hasValidChars = (text: any, allowedChars: any) => allowedChars.test(text)

const hasValidMentionCharIndex = (
  mentionCharIndex: any,
  text: any,
  isolateChar: any,
) => {
  if (mentionCharIndex > -1) {
    if (
      isolateChar &&
      !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
    ) {
      return false
    }
    return true
  }
  return false
}

export {
  attachDataValues,
  getMentionCharIndex,
  hasValidChars,
  hasValidMentionCharIndex,
}
