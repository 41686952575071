import type { Sync } from '..'

// Local state which doesnt need to sync between peers
export class LocalStore {
  static sync: Sync<LocalStore> = {
    // if anonymous it will use this field in local storage: login/register... screens
    // if authenticated it will use the data saved on user
    isLightTheme: 'boolean',
    isOpenMenu: 'boolean',
  }

  isLightTheme = true
  isOpenMenu = false
  warmnessColor?: string = undefined
  warmnessColorGray?: string = undefined
  warmnessColorThumbnail?: string = undefined
  warmnessColorPressed?: string = undefined
  warmnessColorHover?: string = undefined
  warmnessColorValue: number = 0
  appLoading?: boolean = false
}
