import FormItem from 'antd/es/form/FormItem'
import { memo } from 'react'

import css from './style.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'

import { Form } from '../../../../components/base/Form'
import { PopoverContent } from '../../../../components/composites/PopoverContent'
import { ModalService } from '../../../../components/Modal/Modal'
import { AppSelect } from '../../../../components/widget/Select'
import { WarmnessInput } from '../../../../components/widget/WarmnessBeamInput'
import { S } from '../../../../context/store'
import { rtmpsCustomSupport } from '../DestinationData'
import { OutputType } from '../DestinationEnum'
import { createComponent } from './logic'

export const RTMPDestinationForm = memo(
  createComponent(
    ({
      createDestinationState,
      onSubmit,
      form,
      destinationType,
      containerProps,
      updateDestinationState,
      destination,
    }) => {
      const renderForm = () => (
        <div className={css.Content}>
          <Form
            initialValues={{ type: OutputType.RTMP }}
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
            form={form}
            className={css.Form}
          >
            <FormItem
              required
              rules={[
                {
                  required: true,
                  message: 'Please select your destination type',
                },
              ]}
              key='type'
              name='type'
              label={<p className={css.FormLabel}>Select Destination</p>}
            >
              <AppSelect dropdownStyle={{ zIndex: 9999 }}>
                {rtmpsCustomSupport.map(op => (
                  <AppSelect.Option value={op.type} key={op.type}>
                    {op.name}
                  </AppSelect.Option>
                ))}
              </AppSelect>
            </FormItem>
            <FormItem
              required
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please name your destination',
                },
              ]}
              name='destinationName'
              label={<p className={css.FormLabel}>Destination Name</p>}
            >
              <WarmnessInput />
            </FormItem>
            <FormItem
              required
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter URL',
                },
              ]}
              name='url'
              key='url'
              label={<p className={css.FormLabel}>URL</p>}
            >
              <WarmnessInput />
            </FormItem>
            {destinationType !== OutputType.SRT && (
              <FormItem
                required
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Key',
                  },
                ]}
                name='key'
                key='key'
                label={<p className={css.FormLabel}>Key</p>}
              >
                <WarmnessInput type='password' />
              </FormItem>
            )}
          </Form>
        </div>
      )
      return (
        <PopoverContent
          className={css.DestinationActionContainer}
          style={{
            left: containerProps?.left,
            bottom: containerProps?.bottom,
            // @ts-ignore
            '--background-modal1': S.local.warmnessColorGray,
          }}
        >
          <span className={css.DesTitle}>
            {!destination ? 'Add Destination' : 'Edit Destination'}
          </span>

          <div className={css.ContentWrapper}>
            {renderForm()}
            <div className={css.Actions}>
              <Button
                onClick={() => ModalService.hide()}
                containerType='grey'
                style={{
                  // @ts-ignore
                  '--grey8': S.local.warmnessColor,
                  '--background12': S.local.warmnessColorHover,
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                loading={
                  createDestinationState.fetching ||
                  updateDestinationState.fetching
                }
                className={css.BtnSave}
              >
                Save
              </Button>
            </div>
          </div>
        </PopoverContent>
      )
    },
  ),
)
