import { S } from '../../store'
import type { ILayoutToEdit } from '../../store/shared/LayoutStore'

export const addSlot = (slot: ILayoutToEdit, layoutId: number) => {
  const $ = S.layout
  const layout = $.slotsEdit[layoutId]
  const slotEditExit = layout?.find(s => s.enableEdit)
  if (slotEditExit) {
    slotEditExit.enableEdit = false
  }
  if (layout) {
    layout.push({ ...slot, enableEdit: true })
  }
}
