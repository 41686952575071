import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './style.module.scss'

import { Button } from '../../../../components/base/Button'
import { Form } from '../../../../components/base/Form'
import { FormItem } from '../../../../components/base/FormItem'
import { Icon } from '../../../../components/base/Icon'
import { BeamText } from '../../../../components/base/Text'
import { WarmnessDiv } from '../../../../components/composites/WarmnessDiv'
import { ModalContent, ModalService } from '../../../../components/Modal/Modal'
import { BeamInput } from '../../../../components/widget/Input'
import { WarmnessGrayButton } from '../../../../components/widget/WarmnessGrayButton'
import { hiddenFeatures } from '../../../../config'
import { S } from '../../../../context/store'
import { OutputType } from '../DestinationEnum'
import { DestinationItem } from '../DestinationItem'
import { createComponent } from './logic'

export const EventModal = observer(
  createComponent(
    ({
      form,
      output,
      titleWatch,
      descriptionWatch,
      handleRePickImage,
      thumbnailWatch,
      loading,
      handleSave,
      data,
    }) => {
      const renderSelected = () => (
        <div className={css.SelectedContainer}>
          <BeamText className={css.TitleItem}>Selected destination</BeamText>
          <DestinationItem
            className={css.DestinationItem}
            name={output?.name ?? ''}
            avatar={output?.avatarUrl}
            type={(output?.type as OutputType) ?? OutputType.Youtube}
          />
        </div>
      )
      const renderTitle = () => (
        <FormItem
          required
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter your title',
            },
            {},
          ]}
          name={'title'}
          label={
            <div className={css.Label}>
              <BeamText
                containerProps={{
                  classNameContainer: clsx(css.TitleItem),
                }}
              >
                Title
              </BeamText>
              <BeamText className={css.MaxLength}>
                {titleWatch?.length ?? 0}/100
              </BeamText>
            </div>
          }
        >
          <BeamInput />
        </FormItem>
      )
      const renderSelectImage = () => (
        <FormItem
          label={
            <>
              <BeamText className={css.TitleItem}>Thumbnail</BeamText>
              <BeamText className={css.MaxLength}>(optional)</BeamText>
            </>
          }
          name='thumbnail'
        >
          <div className={css.SelectThumbnail}>
            <WarmnessDiv className={css.SelectThumbnailLeft}>
              {thumbnailWatch ? (
                <img src={thumbnailWatch} title='thumbnail' />
              ) : (
                <>
                  <Icon icon='Image-Outline' size={24} className={css.Icon} />
                  <BeamText className={css.Text}>No thumbnail</BeamText>
                </>
              )}
            </WarmnessDiv>
            <div className={css.SelectThumbnailRight}>
              <WarmnessGrayButton
                onClick={handleRePickImage}
                className={css.BtnUpload}
              >
                <Icon icon='icon_upload' size={14} />
                Upload
              </WarmnessGrayButton>
              <BeamText>
                Please ensure the thumbnail is in PNG or JPEG under 3MB
              </BeamText>
            </div>
          </div>
        </FormItem>
      )
      const renderDescription = () => (
        <FormItem
          required
          name={'description'}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter your description',
            },
          ]}
          label={
            <div className={css.Label}>
              <BeamText
                containerProps={{
                  classNameContainer: clsx(css.TitleItem),
                }}
              >
                Description
              </BeamText>
              <BeamText className={css.MaxLength}>
                {descriptionWatch?.length ?? 0}/5000
              </BeamText>
            </div>
          }
        >
          <textarea />
        </FormItem>
      )
      const renderBottom = () => (
        <div className={css.Bottom}>
          <Button onClick={() => ModalService.hide()} containerType='grey'>
            Close
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            htmlType='submit'
            containerType='primary'
          >
            Save
          </Button>
        </div>
      )
      const handleSubmit = async () => {
        await form.validateFields()
        handleSave()
      }
      return (
        <ModalContent
          style={{ background: S.local.warmnessColor }}
          className={css.CreateEvent}
        >
          <BeamText className={css.Title}>
            {data ? 'Update' : 'Create'} Event
          </BeamText>
          <div className={css.Content}>
            {renderSelected()}
            <Form layout='vertical' form={form} onFinish={handleSubmit}>
              {renderTitle()}
              {!hiddenFeatures && renderSelectImage()}
              {renderDescription()}
              {renderBottom()}
            </Form>
          </div>
        </ModalContent>
      )
    },
  ),
)
