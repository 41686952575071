import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import css from './BottomListControl.module.scss'

import { Icon } from '../../../components/base/Icon'
import { ModalService } from '../../../components/Modal/Modal'
import { Clock } from '../../../components/widget/Clock'
import { ToastService } from '../../../components/widget/Toast'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { onStopRecord } from '../../../context/actions/mixerActions'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { InsightEngineButtonsList } from '../../InsightEngine/components/BottomElement'
import { BottomItemControl, BottomItemRecord } from './BottomItemControl'
import { BottomPopoverAudio } from './BottomPopoverAudio'
import { BottomPopoverSchedule } from './BottomPopoverSchedule'
import { BottomPopoverVideo } from './BottomPopoverVideo'
import { HeaderRoom } from './HeaderRoom'
import { ModalDestination } from './ModalDestination'

interface Props {
  isEnableShareScreen: boolean
  isEnableMic: boolean
  isEnableVideo: boolean
}

export const BottomListControl = observer(
  ({ isEnableShareScreen, isEnableMic, isEnableVideo }: Props) => {
    const [visibleSchedule, setVisibileSchedule] = useState(false)
    const { isLightTheme, warmnessColor } = S.local
    const { isViewmodeParticipant } = S.webrtc
    const { detail } = S.webrtc
    if (!detail) {
      return null
    }

    const handleToggleLive = () => {
      if (!S.webrtc.isPublishing) {
        ModalService.show(ModalDestination, {}, { name: 'ModalDestination' })
      } else {
        handleStopLive()
      }
    }
    const handleStopLive = async () => {
      try {
        await reduxStore.context.gql.stopOutputs({ sessionId: detail.id })
        ToastService.success({ content: 'Publishing stopped' })
      } catch (err) {
        ToastService.error({ content: 'Failed to stop publishing' })
        console.error(err)
      }
      if (S.webrtc.isRecording) {
        onStopRecord(detail.id)
      }
    }
    const _renderMediaControlsBottom = () => (
      <>
        <BottomItemControl
          isLightTheme={isLightTheme}
          label={!isEnableMic ? 'Unmute' : 'Mute'}
          onClick={S.webrtc.toggleMicrophone}
          containerDropdown={<BottomPopoverAudio />}
          icon={
            <Icon
              icon={isEnableMic ? 'icon_mic' : 'icon_mic_off'}
              className={css.IconAudioMain}
              size={20}
            />
          }
        />
        <BottomItemControl
          isLightTheme={isLightTheme}
          label={!isEnableVideo ? 'Start Video' : 'Stop Video'}
          onClick={S.webrtc.toggleWebcam}
          containerDropdown={<BottomPopoverVideo />}
          icon={
            <Icon
              icon={
                isEnableVideo
                  ? 'videocam_FILL1_wght400_GRAD0_opsz48-1'
                  : 'Vector11'
              }
              className={css.IconCameraMain}
              size={20}
            />
          }
        />
        <BottomItemControl
          label={isEnableShareScreen ? 'Stop Sharing' : 'Share Screen'}
          icon={
            <Icon
              icon='share'
              className={clsx({
                [css.IconShareScreen]: true,
                [css.iconBottomFontActive]: isEnableShareScreen,
                [css.iconShareScreenActive]: isEnableShareScreen,
              })}
              size={20}
            />
          }
          onClick={S.webrtc.toggleShareScreen}
        />
        {!isViewmodeParticipant && <BottomItemRecord />}
        {isViewmodeParticipant && (
          <BottomItemControl
            icon={
              <Icon
                icon='chat'
                className={clsx({
                  [css.IconChat]: true,
                  [css.iconBottomFontActive]:
                    S.webrtc.selectedRightTabBarKey === '5',
                })}
                size={20}
              />
            }
            badgeNumber={
              S.webrtc.messages.chatPublic.length +
              S.webrtc.messages.chatInternal.length
            }
            label='Chat'
            onClick={S.webrtc.toggleChat}
          />
        )}
      </>
    )

    return (
      <div className={css.Wrapper}>
        <div className={css.Container}>
          <div
            className={css.Content}
            style={{
              background: isLightTheme ? warmnessColor : undefined,
            }}
          >
            <div className={css.LeftControl}>
              <HeaderRoom />
            </div>
            <div className={css.MainControl}>
              {S.webrtc.sessionMode === 'note' ? (
                <InsightEngineButtonsList />
              ) : (
                _renderMediaControlsBottom()
              )}
            </div>

            <div className={css.ItemSchedule}>
              {!isViewmodeParticipant ? (
                <>
                  <Clock />
                  <div
                    className={clsx({
                      [css.ItemScheduleButton]: true,
                      [css.ItemScheduleButtonStop]: S.webrtc.isPublishing,
                    })}
                    onClick={handleToggleLive}
                  >
                    {S.webrtc.isPublishing ? 'Stop Live' : 'Start Live'}
                  </div>
                  <WarmnessCustomPopOver
                    placement='topRight'
                    isLightTheme={isLightTheme}
                    content={
                      <BottomPopoverSchedule
                        onClose={() => setVisibileSchedule(false)}
                      />
                    }
                    visible={visibleSchedule}
                    onVisibleChange={s => setVisibileSchedule(s)}
                  >
                    <div
                      className={clsx({
                        [css.ItemScheduleDropdown]: true,
                        [css.ItemScheduleButtonStop]: S.webrtc.isPublishing,
                      })}
                    />
                  </WarmnessCustomPopOver>
                </>
              ) : (
                <Clock />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
