import type { NPromise } from '##/shared/ts'

import { setUrqlAuthToken } from '../../gql/urql'
import type { Sync } from '..'

/**
 * State that are shared between multiple screens.
 * For example global user info on nav bar, side bar toggle state...
 */
export class SharedStore {
  static sync: Sync<SharedStore> = {
    authToken: 'string',
  }

  storage: {
    loading: boolean
    promise?: NPromise
  } = {
    loading: false,
  }

  private _authToken = ''
  get authToken() {
    return this._authToken
  }
  set authToken(t: string) {
    this._authToken = t
    setUrqlAuthToken(t)
  }
}
