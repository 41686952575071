import type { TextAreaProps } from 'antd/lib/input'
import Input from 'antd/lib/input'
import clsx from 'clsx'

import css from './style.module.scss'

import { Icon } from '../../base/Icon'

export const BeamTextArea = (props: TextAreaProps) => {
  const { className } = props
  const getSuffix = () => (
    <span className={'iconInput textarea-icon-error none'}>
      <Icon icon={'Shape'} size={16} disableFill={false} />
    </span>
  )
  return (
    <>
      <Input.TextArea
        {...props}
        className={clsx(css.TextAreaContainer, className)}
      />{' '}
      {getSuffix()}
    </>
  )
}
