import { addSlot } from '../../../context/actions/layout/addSlot'
import { setEnableEditLayout } from '../../../context/actions/layout/setEnableEditLayout'
import { S } from '../../../context/store'
import type { ILayoutItem } from '../../../context/store/shared/LayoutStore'
import { getIdNumber } from '../../../context/store/studio/helper'
import { calculateUpdateSlot } from './../components/UploadTranscript/calculateSlot'

export const dropTitleToLayout = (
  id: string,
  offsetClient: { x: number; y: number } | null,
) => {
  const $ = S.webrtc
  const $1 = S.layout
  const isExit = $.selectedLayoutByIndex.slots.find(s => s.titleId === id)
  const layoutSelected = $1.slotsEdit[$.selectedLayoutByIndex.defaultId] ?? []
  if (isExit) {
    const ts = $.titlesOnAir[$.selectedIndexLayout] ?? []
    if (!ts.includes(id)) {
      $.titlesOnAir[$.selectedIndexLayout] = [...ts, id]
    }
    return
  }
  const ids = layoutSelected.map(l => l.id)
  const newId = Math.floor(Math.random() * (layoutSelected.length + 2))
  const layoutElement = document.getElementById('customLayout')
  const rect = layoutElement?.getBoundingClientRect()
  if (!offsetClient || !rect) {
    return
  }
  const slot: ILayoutItem = {
    id: getIdNumber(newId, ids),
    ...calculateUpdateSlot({
      width: 100,
      height: 36,
      top: offsetClient.y - rect.y,
      left: offsetClient.x - rect.x,
    }),
    radius: 0,
    zIndex: layoutSelected.length + 2,
    titleId: id,
    enableEdit: true,
    inGroup: false,
    parentId: $.selectedLayoutByIndex.defaultId,
    layoutIndex: $.selectedIndexLayout,
  }
  addSlot(slot, $.selectedLayoutByIndex.defaultId)
  const layoutsToEdit = $.selectedListLayouts.map(l => {
    const ls = $1.slotsEdit[l.defaultId]
    if (ls) {
      return {
        ...l,
        slots: ls.map(s => ({
          id: s.id,
          height: s.height,
          width: s.width,
          left: s.left,
          top: s.top,
          radius: s.radius,
          zIndex: s.zIndex,
          cropMode: s.cropMode,
          cropData: s.cropData,
          titleId: s.titleId,
        })),
      }
    } else {
      return l
    }
  })
  setEnableEditLayout(true)
  const titlesByLayout = $.titlesOnAir[$.selectedIndexLayout] ?? []
  $.titlesOnAir[$.selectedIndexLayout] = [...titlesByLayout, id]
  $.selectedListLayouts = layoutsToEdit
}
