import { debounce } from 'lodash'
import { useCallback, useState } from 'react'

import { separateLogic } from '##/reactjs/redux'
import { reduxStore } from '#HACK_FOR_RN_ONLY/src/context/redux'

import { updateUserProfile } from '../../../context/shared/actions/updateUserProfile'
import { S } from '../../../context/store'

export const createComponent = separateLogic(p => {
  const [show, setShow] = useState(false)
  const { profile } = S.profile

  const handleDebounceFn = (inputValue: number) => {
    reduxStore.context.gql.updateMyProfile({
      data: { themeWarmness: inputValue },
    })
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])

  const updateWarmnessValue = (value: number) => {
    updateUserProfile({ ...profile, themeWarmness: value })
    debounceFn(value)
  }
  return { show, setShow, updateWarmnessValue, profile }
})
