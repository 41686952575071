import { Form } from 'antd'
import { useEffect } from 'react'

import { separateLogic } from '##/reactjs/redux'

import { ModalService } from '../../../../components/Modal/Modal'
import { ToastService } from '../../../../components/widget/Toast'
import {
  useGqlCreateOutput,
  useGqlUpdateOutput,
} from '../../../../context/gql/codegen'
import { OutputType } from '../DestinationEnum'
import type { ContentProps } from './type'

export const createComponent = separateLogic((props: ContentProps) => {
  const { destination, sessionId, refreshDestination } = props
  const [form] = Form.useForm<{
    destinationName: string
    url: string
    key: string
    type: string
  }>()

  const destinationType = Form.useWatch('type', form)

  const [createDestinationState, executeCreateDestination] =
    useGqlCreateOutput()
  const [updateDestinationState, executeUpdateDestination] =
    useGqlUpdateOutput()

  useEffect(() => {
    if (destination) {
      const data = destination?.data

      form.setFieldsValue({
        destinationName: destination?.name ?? '',
        url:
          destination.type === OutputType.SRT
            ? data?.srt?.url
            : data?.rtmp?.url ?? '',
        key: data?.rtmp?.key ?? '',
        type: destination?.type ?? '',
      })
    }
  }, [destination])
  const updateDestination = async () => {
    const des =
      destinationType === OutputType.SRT
        ? { srt: { url: form.getFieldValue('url') } }
        : {
            rtmp: {
              url: form.getFieldValue('url'),
              key: form.getFieldValue('key'),
            },
          }
    const response = await executeUpdateDestination({
      id: destination?.id || '',
      data: des,
      sessionId,
      name: form.getFieldValue('destinationName'),
    })
    if (response.data?.updateOutput) {
      ToastService.success({ content: 'Update success' })
      ModalService.hide()
    } else if (response.error) {
      ToastService.error({ content: response.error?.message })
    }
  }
  const addDestination = async () => {
    const type = form.getFieldValue('type')
    if (!type) {
      return
    }
    const des =
      type === OutputType.SRT
        ? {
            srt: {
              url: form.getFieldValue('url'),
            },
          }
        : {
            rtmp: {
              url: form.getFieldValue('url'),
              key: form.getFieldValue('key'),
            },
          }
    const response = await executeCreateDestination({
      data: {
        data: { ...des, name: form.getFieldValue('destinationName') },
        sessionId,
        type,
      },
    })
    if (response.data?.createOutput) {
      ToastService.success({ content: 'Add destination success' })
      refreshDestination()
      ModalService.hide()
    } else if (response.error) {
      ToastService.success({ content: response.error?.message })
    }
  }

  const onSubmit = async () => {
    try {
      await form.validateFields()
      if (destination) {
        updateDestination()
      } else {
        addDestination()
      }
    } catch (e) {}
  }
  return {
    onSubmit,
    createDestinationState,
    updateDestinationState,
    form,
    destinationType,
  }
})
