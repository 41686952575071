import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { RefObject } from 'react'
import { useEffect } from 'react'

import css from './LiveStreamContent.module.scss'

import { Icon } from '../../../components/base/Icon'
import { Show } from '../../../components/base/Show'
import { S } from '../../../context/store'
import { DRAG_ALLOW_ONLY_LAYOUT } from '../../../context/store/studio/dragTypes'
import { useCheckClick } from '../../../utils/useCheckClick'
import { useCheckHover } from '../../../utils/useCheckHover'
import { useDropLayout, useDropNotAllowMulti } from '../../../utils/useDrop'
import { Peers } from './LiveStreamPeers'
import { LiveStreamStatistics } from './LiveStreamStatistics'
import { LiveStreamVisualElement } from './LiveStreamVisualElement'
import { LiveStreamWatermark } from './LiveStreamWaterMark'

interface Props {
  isEnableShareScreen?: boolean
  isEnableMic?: boolean
  isEnableVideo?: boolean
  refFullScreen?: RefObject<HTMLDivElement>
  isFullScreen: boolean
}

export const LiveStreamContent = observer(
  ({ refFullScreen, isFullScreen }: Props) => {
    const {
      webrtc: {
        computedIsNotProducing,
        mediaSelectionId,
        overlayUrl,
        onAir,
        updateEmitAndSaveSettings,
        isSelectedLayoutFullStream,
        openTranscriptView,
        reCalculateMainLayoutWidth,
      },
      webrtc: { isViewmodeMixer, customRatio },
    } = S
    const {
      layout: { enableEditLayout },
    } = S
    const [{ isOver, itemType }, drop] = useDropLayout({
      onDropEnd: updateEmitAndSaveSettings,
    })
    useEffect(() => {
      reCalculateMainLayoutWidth()
    }, [openTranscriptView, customRatio])
    useCheckClick(['deleteBg'], isInside => {
      if (isInside) {
        handleOnclickRemove()
      }
    })

    const handleOnclickRemove = () => {
      S.webrtc.backgroundMediaData = {
        id: '',
        url: '',
      }
      S.webrtc.backgroundUrl = ''
      if (overlayUrl) {
        updateEmitAndSaveSettings({
          overlayUrl: '',
        })
        return
      }
      if (backgroundVideoUrl || backgroundPeerId || backgroundVideoUrl) {
        updateEmitAndSaveSettings({
          backgroundVideoUrl: '',
          backgroundUrl: '',
          backgroundPeerId: '',
          backgroundMediaData: {
            id: '',
            url: '',
          },
        })
        return
      }
    }

    const {
      backgroundColor,
      backgroundVideoUrl,
      backgroundUrl,
      backgroundPeerId,
      isViewmodeParticipant,
    } = S.webrtc
    const [{ isOver: isOverMulti }, dropMulti] = useDropNotAllowMulti()
    const isNotShowVisual = isViewmodeMixer && !onAir
    const r = S.webrtc.ratioScaleLayout || 1
    const isHover = useCheckHover('liveContainer', [
      'slotContainer',
      'titleContainer',
      'logoContainer',
      'titleSlot',
      'liveTitleSlot',
      'liveTitleSlotItem',
      'liveTitleTop',
      'liveTitleBottom',
      'colorPicker',
      'peersWithCustomlayout',
      'SlotCustom',
      'OthersSettings',
      'LiveStreamWatermark',
    ])
    const isHoverButtonDelete = useCheckHover('deleteBg', [
      'slotContainer',
      'titleContainer',
      'logoContainer',
      'titleSlot',
      'liveTitleSlot',
      'liveTitleSlotItem',
      'liveTitleTop',
      'liveTitleBottom',
      'colorPicker',
      'peersWithCustomlayout',
      'SlotCustom',
    ])

    const isHoverLiveStreamContainer =
      backgroundVideoUrl || backgroundUrl || backgroundPeerId
        ? !isNotShowVisual && isHover
        : !isNotShowVisual && isHover && !backgroundColor
    const isHoverOverlay = isHover && overlayUrl
    return (
      <div
        className={clsx({
          [css.LiveStreamWrapper]: true,
          [css.liveStreamMaxWidthFullScreen]: isFullScreen || isViewmodeMixer,
          [css.liveStreamFullScreen]: isFullScreen,
          [css.liveStreamFullScreenHoverDrop]:
            isOver &&
            DRAG_ALLOW_ONLY_LAYOUT.includes(itemType) &&
            overlayUrl === '',
          [css.liveStreamParticipant]: isViewmodeParticipant,
        })}
        ref={refFullScreen}
        style={isViewmodeMixer ? undefined : computeWidthHeight()}
      >
        <Show>
          <Show.When isTrue={mediaSelectionId.length > 1 && overlayUrl === ''}>
            <div
              className={clsx({
                [css.LiveStreamOverlayMulti]: true,
                [css.liveStreamOverlayMultiOver]: isOverMulti,
              })}
              ref={dropMulti}
            >
              {isOverMulti && (
                <div className={css.LiveStreamNotAllowDropTxt}>
                  Please drag & drop 1 media at a time
                </div>
              )}
            </div>
          </Show.When>
        </Show>

        <Show>
          <Show.When
            isTrue={
              DRAG_ALLOW_ONLY_LAYOUT.includes(itemType) && overlayUrl === ''
            }
          >
            <div className={css.LiveStreamWrapperDrop} ref={drop} />
          </Show.When>
        </Show>
        <div
          id='liveContainer'
          className={clsx({
            [css.LiveStreamContainer]: true,
            [css.liveStreamContainerNotProducing]: computedIsNotProducing,
            [css.containerRoundedTheme]:
              !computedIsNotProducing && S.webrtc.layoutStyle === 'rounded',
            [css.containerRoundedThemeHidden]:
              !computedIsNotProducing && S.webrtc.layoutStyle === 'rounded',
          })}
          style={{
            // @ts-ignore
            '--wSlotIconAction': r * 32 + 'px',
            '--hSlotIconAction': r * 32 + 'px',
            '--lhSlotIconAction': r * 32 + 'px',
          }}
        >
          <Peers />

          {false && <LiveStreamStatistics />}
          <LiveStreamWatermark
            style={{
              pointerEvents:
                false && isSelectedLayoutFullStream ? 'none' : 'auto',
            }}
          />

          <Show>
            <Show.When isTrue={!isNotShowVisual}>
              <LiveStreamVisualElement />
            </Show.When>
          </Show>

          <Show>
            <Show.When
              isTrue={Boolean(
                (isHoverLiveStreamContainer ||
                  isHoverButtonDelete ||
                  isHoverOverlay) &&
                  !isNotShowVisual &&
                  !enableEditLayout &&
                  !isViewmodeParticipant &&
                  !isOver,
              )}
            >
              <div
                className={clsx(css.showDeleteBackground, {
                  [css.containerRoundedTheme]:
                    S.webrtc.layoutStyle === 'rounded',
                })}
              >
                <span
                  id='deleteBg'
                  className={css.ItemIconDelete}
                  onClick={e => {
                    e.stopPropagation()
                    handleOnclickRemove()
                  }}
                >
                  <Icon icon='icon_close_popup' size={8} />
                </span>
              </div>
            </Show.When>
          </Show>
        </div>
      </div>
    )
  },
)

const computeWidthHeight = () => {
  const { mainLayoutWidth, customRatio } = S.webrtc
  return {
    width: mainLayoutWidth,
    height: mainLayoutWidth / customRatio,
  }
}
