import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import css from './LiveStreamCustomLayoutCrop.module.scss'

import { setIsDragging } from '../../../context/actions/layout/setIsDragging'
import { setIsResizing } from '../../../context/actions/layout/setIsResizing'
import { updateSlotToEdit } from '../../../context/actions/layout/updateSlotToEdit'
import { calculateCrop } from '../../../context/shared/actions/calculateCrop'
import { Resizeable } from '../../../context/shared/components/CustomLayoutResizeable'
import { ResizeOutsideApi } from '../../../context/shared/components/CustomLayoutReszieOutside'
import type { IActionOutsize } from '../../../context/shared/utils/CustomLayoutType'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutSize,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import {
  DRAG_MEDIA_SLOT,
  DRAG_USER_MAIN,
} from '../../../context/store/studio/dragTypes'
import type {
  TKeyString,
  TMediaDragDropData,
} from '../../../context/store/studio/WebrtcStore'
import { getMediaPeerId } from '../../../context/store/studio/WebrtcStore'
import { useCheckHover } from '../../../utils/useCheckHover'
import { useDragSlotMedia } from '../../../utils/useDrag'
import { useDropSlot } from '../../../utils/useDrop'
import { getLayoutRatio } from '../actions/getLayoutRatio'
import { isInputPeer } from '../utils/isInputPeer'
import { SlotIconAction } from './SlotIconAction'
import { calculateUpdateSlot } from './UploadTranscript/calculateSlot'

type Props = {
  layout: ILayoutItem
  index: number
  containerProps: ILayoutSize
  isFullScreen?: boolean
  parentId: number
  onLongPressLayout: (e: any) => void
  actionsTop?: IActionOutsize[]
  actionsLeft?: IActionOutsize[]
  isEdit?: boolean
  mediaData: TKeyString | null
  ratio?: number
  onKeyDown?(e: KeyboardEvent): void
  onKeyUp?(e: KeyboardEvent): void
  onMouseEnter?(e: React.MouseEvent): void
  onMouseLeave?(e: React.MouseEvent): void
}

export const CustomLayoutCropMode: FC<Props> = observer(props => {
  const {
    layout,
    index,
    containerProps,
    parentId,
    isFullScreen,
    onLongPressLayout,
    actionsLeft,
    actionsTop,
    isEdit,
    mediaData,
    ratio,
    onKeyDown,
    onKeyUp,
    onMouseEnter,
    onMouseLeave,
  } = props

  const {
    selectedIndexLayout,
    selectedLayoutByIndex,
    updateEmitAndSaveSettings,
    updateDataOfStore,
    updateStateVideoPreview,
    isViewmodeMixer,
    stateVideoPreviewId,
    isViewmodeParticipant,
    toggleSlotFullStream,
    isOnlyViewStream,
    computedVideoPlaybackMediaIds,
  } = S.webrtc

  const { slotsEdit, enableEditLayout } = S.layout
  const isHoverSlot = useCheckHover(`cropSlot${layout.id}`, [])
  const isCropping = layout.isShowCropMode
  const layoutRef = useRef<ILayoutItem | null>(null)
  const refDrop = useRef<HTMLDivElement>(null)
  const dref = useRef<HTMLDivElement>(null)

  const onDropEnd = (item: TMediaDragDropData) => {
    if (!!mediaData && mediaData.mediaType === 'video' && stateVideoPreviewId) {
      const { value: url, id: mediaId } = item
      if (isViewmodeMixer) {
        return
      }

      updateDataOfStore({
        stateVideoPreviewId: mediaId,
        videoPreview: {
          mediaId,
          url,
        },
      })

      updateStateVideoPreview(mediaId ?? '', {
        url,
        mediaId,
        volume: 1,
        paused: true,
        time: 0,
      })
    }
    updateEmitAndSaveSettings()
  }
  const { drag } = useDragSlotMedia({
    item: mediaData
      ? { ...mediaData, index: '0' }
      : { peerId: layout.peerId, position: layout.id, selectedIndexLayout },
    dragType: mediaData ? DRAG_MEDIA_SLOT : DRAG_USER_MAIN,
    refDrop,
    disableDrag: enableEditLayout,
  })
  const [{ isOver }, drop] = useDropSlot({
    peerId: layout.peerId,
    position: layout.id,
    selectedLayoutByIndex: selectedLayoutByIndex.defaultId,
    onDropEnd,
  })

  const mediaPeerId = getMediaPeerId(layout?.peerId ?? '')
  const peer = S.webrtc.getPeer(mediaPeerId)

  const layoutSelected = slotsEdit[parentId] ?? []
  const slotEditing = layoutSelected.find(item => item.enableEdit)
  const { ratioTH, ratioLW } = getLayoutRatio()

  const mediaPostion: Partial<ILayoutToEdit> = useMemo(() => {
    const videoElement = document.querySelector(
      `#slot${layout.id}-${layout.layoutIndex} video`,
    ) as HTMLVideoElement
    const imageElement = document.querySelector(
      `#slot${layout.id}-${layout.layoutIndex} img`,
    ) as HTMLImageElement
    let x1: number
    let y1: number
    if (videoElement) {
      x1 = videoElement.videoWidth
      y1 = videoElement.videoHeight
    } else if (imageElement) {
      x1 = imageElement.naturalWidth
      y1 = imageElement.naturalHeight
    } else {
      x1 = 0
      y1 = 0
    }
    const x2 = layout.width ?? 0
    const y2 = layout.height ?? 0
    let x3 = 0
    let y3 = 0
    const s = (x1 * y2) / x2

    if (layout.prevCropMode === ECropMode.Fit) {
      if (y1 > s) {
        y3 = y2
        x3 = (y2 * x1) / y1
      } else if (y1 < s) {
        x3 = x2
        y3 = (x2 * y1) / x1
      } else {
        x3 = x2
        y3 = y2
      }
    } else if (layout.prevCropMode === ECropMode.Fill) {
      if (y1 > s) {
        x3 = x2
        y3 = (x2 * y1) / x1
      } else if (y1 < s) {
        y3 = y2
        x3 = (y2 * x1) / y1
      } else {
        x3 = x2
        y3 = y2
      }
    } else {
      return {
        cropData: {
          ...calculateUpdateSlot({
            top: layout.cropData?.top ?? 0,
            left: layout.cropData?.left ?? 0,
            width: layout.cropData?.width ?? 0,
            height: layout.cropData?.height ?? 0,
          }),
        },
      }
    }

    const left =
      (x2 - x3) / 2 + layout.left + x3 - containerProps.width > 0
        ? containerProps.width - x3
        : (x2 - x3) / 2 + layout.left < 0
          ? 0
          : (x2 - x3) / 2 + layout.left
    const top =
      (y2 - y3) / 2 + layout.top + y3 - containerProps.height > 0
        ? containerProps.height - y3
        : (y2 - y3) / 2 + layout.top < 0
          ? 0
          : (y2 - y3) / 2 + layout.top

    return {
      cropData: {
        ...calculateUpdateSlot({
          top,
          left,
          width: x3,
          height: y3,
        }),
      },
    }
  }, [layout])

  const onCropModeExit = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      updateSlotToEdit(
        layout.id,
        {
          isShowCropMode: false,
        },
        parentId,
      )
    }
  }

  const onCropModeBlurExit = (e: MouseEvent) => {
    if (
      !(e.target as HTMLElement).closest('#SlotCustom') ||
      (e.target as HTMLElement).closest('#liveContainer')
    ) {
      updateSlotToEdit(
        layout.id,
        {
          isShowCropMode: false,
        },
        parentId,
      )
    }
  }

  useEffect(() => {
    updateSlotToEdit(layout.id, mediaPostion, parentId)
  }, [])
  useEffect(() => {
    addEventListener('keydown', onCropModeExit)
    addEventListener('click', onCropModeBlurExit, true)
    return () => {
      removeEventListener('keydown', onCropModeExit)
      removeEventListener('click', onCropModeBlurExit)
    }
  }, [isCropping])

  const setSlotStateOverlay = (state: ILayoutItem) => {
    const newCrop = {
      ...calculateUpdateSlot({
        height: state?.height ?? 0,
        width: state?.width ?? 0,
        top: state?.top ?? 0,
        left: state?.left ?? 0,
      }),
    }
    updateSlotToEdit(
      state.id,
      {
        cropData: newCrop,
      },
      state.parentId as number,
    )
  }

  const setSlotStateFrame = (state: ILayoutItem) => {
    const newLayout = {
      ...layout,
      ...calculateUpdateSlot({
        height: state.height,
        width: state.width,
        top: state.top,
        left: state.left,
      }),
      cropData: {
        ...calculateUpdateSlot({
          height: state.cropData?.height ?? 0,
          width: state.cropData?.width ?? 0,
          top: state.cropData?.top ?? 0,
          left: state.cropData?.left ?? 0,
        }),
      },
    }
    updateSlotToEdit(layout.id, newLayout, parentId)
  }

  const moveCrop = (state: ILayoutItem) => {
    layoutRef.current = layout
    updateSlotToEdit(
      state.id,
      {
        ...layoutRef.current,
        ...calculateCrop(layout, state),
        isShowCropMode: false,
      },
      state.parentId as number,
    )
  }

  const onChangeSizeCustom = (w: number, h: number) => {
    const state = {
      ...layout,
      width: w,
      height: h,
    }
    updateSlotToEdit(
      layout.id,
      {
        ...layoutRef.current,
        ...calculateCrop(
          layout,
          state,
          containerProps.width,
          containerProps.height,
        ),
        enableEdit: true,
      },
      layout.parentId as number,
    )
  }

  const masks = useMemo(() => {
    const { width, height, top, left, cropData } = layout
    if (!cropData) {
      return []
    }
    const {
      width: widthCrop,
      height: heightCrop,
      top: topCrop,
      left: leftCrop,
    } = cropData
    const isTop = top > topCrop
    const isOverTop = top > topCrop + heightCrop
    const isLeft = left > leftCrop
    const isOverLeft = left > leftCrop + widthCrop
    const isRight = leftCrop + widthCrop > left + width
    const isOverRight = left + width < leftCrop
    const isBottom = topCrop + heightCrop > top + height
    const isOverBottom = topCrop > top + height

    const topMask = {
      type: 'top',
      top: topCrop - 2,
      left: leftCrop - 2,
      width: widthCrop + 4,
      height: isTop ? (isOverTop ? heightCrop + 4 : top - topCrop) : 0,
    }
    const leftMask = {
      type: 'left',
      top: isTop ? top - 2 : topCrop,
      left: leftCrop - 2,
      width: isLeft ? (isOverLeft ? widthCrop + 4 : left - leftCrop + 2) : 0,
      height: isTop
        ? isOverTop
          ? 0
          : topCrop + heightCrop - top + 4
        : isBottom
          ? top + height - topCrop
          : heightCrop,
    }
    const bottomMask = {
      type: 'bottom',
      top: isOverBottom ? topCrop : top + height,
      left: leftCrop - 2,
      width: widthCrop + 4,
      height: isBottom
        ? isOverBottom
          ? heightCrop
          : topCrop + heightCrop - top - height + 2
        : 0,
    }
    const rightMask = {
      type: 'right',
      top: isTop ? top - 2 : topCrop,
      left: isRight ? (isOverRight ? leftCrop - 2 : left + width) : 0,
      width: isRight
        ? isOverRight
          ? widthCrop + 4
          : leftCrop + widthCrop - left - width + 2
        : 0,
      height: isTop
        ? isOverTop
          ? 0
          : topCrop + heightCrop - top + 4
        : isBottom
          ? top + height - topCrop
          : heightCrop,
    }
    return [topMask, leftMask, bottomMask, rightMask]
  }, [
    layout.cropData?.top,
    layout.cropData?.left,
    layout.height,
    layout.width,
    layout.top,
    layout.left,
  ])

  const [layoutFullScreen, setLayoutFullScreen] = useState<any>({
    ...layout,
    cropData: calculateCrop(layout, {
      ...layout,
      width: containerProps.width * ratioLW,
      height: containerProps.height * ratioTH,
      top: 0,
      left: 0,
    })?.cropData,
    top: 0,
    left: 0,
    width: containerProps.width,
    height: containerProps.height,
  })
  const calculateOverlayLayout = {
    ...layout,
    ...layout.cropData,
    ...(isCropping
      ? {}
      : {
          top: (layout.cropData?.top ?? 0) - layout.top,
          left: (layout.cropData?.left ?? 0) - layout.left,
        }),
    radius: 0,
  }

  const [
    calculateOverlayLayoutFullScreen,
    setCalculateOverlayLayoutFullScreen,
  ] = useState<any>({
    ...layoutFullScreen,
    ...layoutFullScreen.cropData,
    ...(isCropping
      ? {}
      : {
          top: layoutFullScreen.cropData?.top ?? 0,
          left: layoutFullScreen.cropData?.left ?? 0,
        }),
    radius: 0,
  })

  useEffect(() => {
    if (layoutRef.current === null) {
      layoutRef.current = layout
    }
    if (isFullScreen) {
      setLayoutFullScreen({
        ...layout,
        cropData: calculateCrop(
          layout,
          {
            ...layout,
            width: containerProps.width * ratioLW,
            height: containerProps.height * ratioTH,
            top: 0,
            left: 0,
          },
          undefined,
          undefined,
          'fullscreen',
        )?.cropData,
        top: 0,
        left: 0,
        width: containerProps.width,
        height: containerProps.height,
      })
    }
  }, [isFullScreen])
  useEffect(() => {
    setCalculateOverlayLayoutFullScreen({
      ...layoutFullScreen,
      ...layoutFullScreen.cropData,
      ...(isCropping
        ? {}
        : {
            top: (layoutFullScreen.cropData?.top ?? 0) - layoutFullScreen.top,
            left:
              (layoutFullScreen.cropData?.left ?? 0) - layoutFullScreen.left,
          }),
      radius: 0,
    })
  }, [layoutFullScreen])
  const renderMask = () =>
    masks.map(m => (
      <div
        key={m.type}
        id='maskedLayout'
        style={{
          opacity: 0.6,
          backgroundColor: 'black',
          position: 'absolute',
          zIndex: 9999,
          ...m,
        }}
      />
    ))

  const handleOnResizeStart = () => {
    layoutRef.current = layout
  }

  const ComponentWrapper = isCropping ? 'div' : (Resizeable as any)
  const mainId = `${layout.key}-main`
  const outSizeId = `${layout.key}-absolute`
  const insideId = `${layout.key}-inside`
  const wrapperProps = isCropping
    ? {
        id: 'SlotCustom',
        className: css.Wrapper,
        style: { zIndex: index },
      }
    : {
        id: 'SlotCustom',
        layout,
        slotsOtherEditing: [],
        index,
        containerProps,
        style: { overflow: 'hidden' },
        containerStyle: { overflow: 'hidden', position: 'static' },
        onLongPresLayout: onLongPressLayout,
        isFullScreen,
        actionsTop,
        actionsLeft,
        onChange: moveCrop,
        isEdit,
        disableDrag: false,
        outSizeId: mainId,
        showControl: layout.showControl,
        ratio,
        handleOnResizeStart,
        onChangeSize: onChangeSizeCustom,
        onKeyDown,
        onKeyUp,
        onMouseEnter,
        onMouseLeave,
      }

  const videoPlaybackMediaId =
    layout.id !== undefined && computedVideoPlaybackMediaIds[layout.id]

  const mediaId2 = videoPlaybackMediaId || layout.peerId

  const layoutEditing =
    `${layout.id}-${layout.parentId}-${layout.layoutIndex}` ===
    `${slotEditing?.id}-${slotEditing?.parentId}-${slotEditing?.layoutIndex}`
  useEffect(() => {
    if (!isCropping) {
      ResizeOutsideApi.removeItemByIds([mainId, insideId, outSizeId])
    }
  }, [isCropping])
  return (
    <ComponentWrapper {...wrapperProps}>
      <div
        ref={
          isViewmodeParticipant
            ? undefined
            : layout.peerId || mediaData
              ? (drag(drop(dref)) as any)
              : drop
        }
        className={css.Container}
        onDoubleClick={() => {
          if (!isViewmodeParticipant && mediaId2 && !isOnlyViewStream) {
            toggleSlotFullStream(layout.id || 0)
          }
        }}
      >
        <div
          className={clsx({
            [css.DropArea]: true,
            [css.dropAreaOver]: isOver,
          })}
          ref={refDrop}
          id={`cropSlot${layout.id}`}
        />
        <Resizeable
          layout={
            isFullScreen
              ? calculateOverlayLayoutFullScreen
              : calculateOverlayLayout
          }
          slotsOtherEditing={[]}
          outSizeId={insideId}
          showControl={layout.showControl}
          index={1}
          containerProps={{
            height: containerProps.height * 2,
            width: containerProps.width * 2,
          }}
          isEdit={isCropping}
          onChange={setSlotStateOverlay}
          isEditMode
          isVideoOverlay
          onLongPresLayout={onLongPressLayout}
          canToOutside={true}
          disableDrag={!isCropping && !enableEditLayout}
        >
          {layout.children}
        </Resizeable>

        {isCropping && layoutEditing && (
          <div className={css.CropContainer}>
            <Resizeable
              layout={layout}
              outSizeId={outSizeId}
              slotsOtherEditing={[]}
              index={1}
              showControl={layout.showControl}
              containerProps={containerProps}
              isEdit
              disableDrag
              isEditMode
              changeDragging={setIsDragging}
              changeResizing={setIsResizing}
              onChange={setSlotStateFrame}
              actionsTop={actionsTop}
            />
          </div>
        )}
        {isCropping && renderMask()}
      </div>
      {S.webrtc.showParticipantName && (
        <span
          className={clsx({
            [css.MediaViewPeerName]: true,
          })}
          style={{
            fontSize: S.webrtc.ratioScaleLayout * 12,
            bottom: S.webrtc.ratioScaleLayout * 12,
            right: S.webrtc.ratioScaleLayout * 12,
            left: S.webrtc.ratioScaleLayout * 8,
          }}
        >
          {isInputPeer(layout.peerId)
            ? `Input ${peer?.data.name}`
            : peer?.data.name}
        </span>
      )}
      {isHoverSlot && !!layout.peerId && (
        <SlotIconAction
          position={layout.id || 0}
          slotType='peer'
          slotPeerId={layout.peerId}
          isShowFullScreen
        />
      )}
      {isHoverSlot && mediaData && (
        <SlotIconAction
          position={layout.id || 0}
          slotType='media'
          slotMediaId={mediaData.id}
          isShowFullScreen
        />
      )}
    </ComponentWrapper>
  )
})
