import FormItem from 'antd/es/form/FormItem'
import { memo } from 'react'

import css from './style.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import { BeamText } from '#HACK_FOR_RN_ONLY/src/components/base/Text'

import { Form } from '../../../../components/base/Form'
import { PopoverContent } from '../../../../components/composites/PopoverContent'
import { ModalService } from '../../../../components/Modal/Modal'
import { WarmnessInput } from '../../../../components/widget/WarmnessBeamInput'
import { S } from '../../../../context/store'
import { createComponent } from './logic'

const Intructions = [
  {
    text: '1. You must have an Instagram Professional Account.',
  },
  {
    text: '2. You will need to copy/paste your Stream key for every show.',
  },
  {
    text: "3. Comments from Instagram won't show up in the studio",
  },
  {
    text: '4. Instagram deletes streams after they end. You can enable Live Archive on Instagram, but only you can see archived streams.',
  },
]

export const InstagramFormDestination = memo(
  createComponent(({ onSubmit, form, containerProps }) => {
    const renderForm = () => (
      <div className={css.Content}>
        <Form
          onFinish={onSubmit}
          layout='vertical'
          autoComplete='off'
          form={form}
          className={css.Form}
        >
          <FormItem
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
            name='InstagramName'
            key='InstagramName'
            label='Instagram Name'
          >
            <WarmnessInput />
          </FormItem>
          <FormItem
            rules={[
              {
                required: true,
                message: 'Please enter stream key',
              },
            ]}
            name='InstagramKey'
            key='InstagramKey'
            label='Instagram Stream key'
          >
            <WarmnessInput />
          </FormItem>
        </Form>
      </div>
    )
    return (
      <PopoverContent
        className={css.DestinationActionContainer}
        style={{
          left: containerProps?.left,
          bottom: containerProps?.bottom,
          // @ts-ignore
          '--background-modal1': S.local.warmnessColorGray,
        }}
      >
        <span className={css.DesTitle}>Add Instagram Live</span>
        <div className={css.ContentWrapper}>
          <div className={css.Instruction}>
            <BeamText>Instagram Live has a few limitations:</BeamText>
            <div className={css.InstructionContent}>
              {Intructions.map(item => (
                <BeamText className={css.Text}>{item.text}</BeamText>
              ))}
            </div>
          </div>
          {renderForm()}
          <div className={css.Actions}>
            <Button
              onClick={() => ModalService.hide()}
              containerType='grey'
              style={{
                // @ts-ignore
                '--grey8': S.local.warmnessColor,
                '--background12': S.local.warmnessColorHover,
              }}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} className={css.BtnSave}>
              Save
            </Button>
          </div>
        </div>
      </PopoverContent>
    )
  }),
)
