import type { SubtitleItem, TitleItem } from '../studio/WebrtcStore'
import { modalObserver } from './ModalStore'

export interface ILayout {
  id: string
  slots: ILayoutItem[]
  width: number
  height: number
  className?: string
}

export interface ILayoutSize {
  width: number
  height: number
}

export enum ECropMode {
  Fill = 'Fill',
  Fit = 'Fit',
  Crop = 'Crop',
}

export interface ILayoutItem {
  id: number
  width: number
  height: number
  top: number
  left: number
  radius: number
  zIndex: number
  inGroup?: boolean
  invisible?: boolean
  children?: React.ReactNode
  enableEdit?: boolean
  parentId?: number
  peerId?: string
  titleId?: string
  type?: 'title' | 'subTitle' | 'slot' | 'Blur' | 'Mute'
  cropMode?: ECropMode
  prevCropMode?: ECropMode
  isShowCropMode?: boolean
  isCropping?: boolean
  cropData?: {
    width: number
    height: number
    top: number
    left: number
  }
  key?: string
  layoutIndex?: number
  screenshare?: boolean
  showControl?: boolean
}
export enum AlignmentLineType {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  CENTER_H = 'center_h',
  CENTER_V = 'center_v',
  CENTER_AH = 'center_ah',
  CENTER_AV = 'center_av',
}

export interface IAlignmentIndicatorLine {
  type: AlignmentLineType
  layoutMappingId: number
  positions: IPosition[]
}
export interface IPosition {
  top: number
  left: number
}
export enum EMeasureLineType {
  DOT = 'dot',
  TENSE = 'tense',
}
export interface IMeasureLine extends IPosition {
  width: number
  height: number
  typeLine: EMeasureLineType
  direction: 'horizontal' | 'vertical'
}
export type AlignmentIndicatorLines = {
  [key: string]: IAlignmentIndicatorLine[]
}
export interface ILayoutToEdit extends ILayoutItem {}
export class LayoutStore {
  layouts: ILayout[] = []
  enableEditLayout: boolean = false
  slotAllowEdit: string = ''
  alignIndicatorLines: AlignmentIndicatorLines = {}
  isDragging: boolean = false
  isResizing: boolean = false
  enableEditTitle: boolean = false
  slotsEdit: { [key: number]: ILayoutToEdit[] } = {}
  subtitleSlot: ILayoutItem
  showOverlayBox: boolean
  measureLines: IMeasureLine[] = []
  cropDropdownShow = false
  titlesOnEdit: TitleItem[] = []
  subtitleOnEdit: SubtitleItem
  slotCopy?: ILayoutItem
  get hasAnyModalOpen() {
    return modalObserver.hasAnyModal
  }
  get slotEditing() {
    let s: ILayoutToEdit | undefined = undefined
    const keys = Object.keys(this.slotsEdit)
    for (let i = 0; i <= keys.length; i++) {
      const sls: ILayoutToEdit[] = this.slotsEdit[keys[i]]
      if (sls) {
        s = sls.find(s1 => s1.enableEdit)
        if (s) {
          break
        }
      }
    }
    return s
  }
}
