import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { map } from 'lodash'

import { googleFontKey, reactServerOrigin } from '../../config'
import { showError } from '../../utils/showError'
import { reduxStore } from '../redux'
import { S } from '../store'
import { getNewListResourceOrder } from '../store/studio/utils'
import type { UploadResponse } from '../store/studio/WebrtcStore'

export type ApiResponse = {
  success?: boolean
}
export type TApiResponse<T> = T
export type TResultApiUpload = {
  id: string
  filesize: number
  url: string
}

export const callApiAuth = async (param: string, data: object) => {
  try {
    const result = await fetch(`${reactServerOrigin}/api/auth/${param}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (result) {
      const json: ApiResponse = await result.json()
      return json
    }
    return
  } catch (err: any) {
    return err as ApiResponse
  }
}

export const callApiGoogleFont = async () => {
  try {
    const result = await fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${googleFontKey}&sort=popularity`,
      {
        method: 'GET',
      },
    )
    const json = await result.json()
    if (json.items) {
      const listFonts: string[] = map(
        json.items.slice(0, 100),
        item => item.family,
      )
      return listFonts as string[]
    }
    return []
  } catch (err) {
    console.error(err)
    return []
  }
}

export const callApiUpdateResourceReOrder = async (
  resource: ReadonlyArray<{
    [k: string]: any
  }>,
  dropIndex: number,
  overIndex: number,
) => {
  const res = getNewListResourceOrder(resource, dropIndex, overIndex)
  if (res) {
    return await reduxStore.context.gql.updateResourceInSession({
      ids: res.idsList as any,
      positions: res.listSorted,
    })
  }
  return null
}
export const callApiUpload = async (
  file: FormData,
  type: string,
  config?: AxiosRequestConfig<unknown>,
  headerConfig?: {
    [key: string]: string
  },
  sessionId?: string,
) => {
  try {
    const result: UploadResponse = await axios({
      method: 'post',
      url: `${reactServerOrigin}/api/upload`,
      data: file,
      headers: {
        authorization: `Bearer ${S.shared.authToken}`,
        'x-session-id': sessionId ?? '',
        'x-resource-type': type,
        ...headerConfig,
      },
      ...config,
    })
    return result.data?.data
  } catch (err: any) {
    showError('Failed to upload file', err as Error)
    console.error(err)
    return err
  }
}
