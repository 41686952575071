import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './LiveStreamLogo.module.scss'

import { arrToMap } from '##/shared/arrToMap'
import { waitTimeout } from '##/shared/waitTimeout'

import { Icon } from '../../../components/base/Icon'
import type { ICropState } from '../../../components/ImageEditor/ImageCropper'
import { ImageCropper } from '../../../components/ImageEditor/ImageCropper'
import { ModalService } from '../../../components/Modal/Modal'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { TMediaItem } from '../../../context/store/studio/WebrtcStore'
import { cropboxH, cropboxW } from '../../../context/store/studio/WebrtcStore'

export const LiveStreamLogo = observer(() => {
  const { logoUrl, ratioScaleLayout, graphicLogo, isViewmodeParticipant } =
    S.webrtc
  const { isViewmodeMixer } = S.webrtc
  const onSave = async (blobFile: any, state: ICropState) => {
    if (!detailLogo || typeof detailLogo === 'string') {
      return true
    }

    const originalResourceId =
      detailLogo.originalResourceId || detailLogo.resourceId
    if (!originalResourceId) {
      return true
    }
    try {
      // store old positions before upload
      const map = arrToMap(S.webrtc.graphicLogo, 'resourceId', l => l)
      const hasClone = arrToMap(
        S.webrtc.graphicLogo
          .map(l => l.originalResourceId as string)
          .filter(id => id),
      )
      const alreadyPushed: { [k: string]: boolean } = {}
      const arr: TMediaItem[] = []
      S.webrtc.graphicLogo
        .filter(l => !hasClone[l.resourceId!])
        .forEach(l => {
          if (alreadyPushed[l.id]) {
            return
          }
          const o = map[l.originalResourceId!]
          if (o && !alreadyPushed[o.id]) {
            arr.push(o)
            alreadyPushed[o.id] = true
          }
          arr.push(l)
          alreadyPushed[l.id] = true
        })
      const positions = arr.map((l, i) => ({
        id: `${l.id}`,
        position: i,
      }))
      const originalIndex = positions.findIndex(
        p => p.id === map[originalResourceId]?.id,
      )
      const tobeDeleted = S.webrtc.graphicLogo
        .filter(l => l.originalResourceId === originalResourceId)
        .map(l => l.id)
      // start upload
      let name = ''
      if (detailLogo.name) {
        const oldNameArr = detailLogo.name.split('.')
        oldNameArr.pop()
        name = oldNameArr.join('.')
      }
      const type = blobFile.type.split('/')[1]
      name = `${name || 'new-logo'}-cropped.${type}`
      const newFile = new File([blobFile], name)
      const newFormData = new FormData()
      newFormData.append('file', newFile)
      const response = await S.webrtc.callApiUpload(newFormData, 'logo')
      // upload done
      const newResourceId = response?.resourceId
      if (!newResourceId) {
        console.error('CropLogo error: empty resourceId in response')
        return
      }
      // if current logo is on air, set new logo on air
      S.webrtc.updateEmitAndSaveSettings({
        logoUrl: response.resource.url,
      })
      // get crop state to save into new uploaded resource

      await reduxStore.context.gql.updateOriginalResource({
        id: newResourceId,
        data: {
          sessionId: S.webrtc.sessionId,
          originalResourceId,
          originalResourceState: state,
        },
      })
      // reorder positions
      if (originalIndex >= 0) {
        const newPositions = [
          ...positions.slice(0, originalIndex + 1),
          {
            id: `${response.id}`,
            position: originalIndex + 1,
          },
          ...positions.slice(originalIndex + 1).map(p => ({
            id: p.id,
            position: p.position + 1,
          })),
        ]
        await reduxStore.context.gql.updateResourceInSession({
          ids: newPositions.map(p => p.id),
          positions: newPositions.map(p => p.position),
        })
      }
      // delete already edited items
      if (tobeDeleted.length) {
        await reduxStore.context.gql.deleteResourceInSession({
          ids: tobeDeleted,
        })
      }
      await waitTimeout()
      S.webrtc.updateGraphicLogo(response.id, {
        originalResourceId,
        originalResourceState: state,
      })
      ModalService.hide()
      return true
    } catch (err) {
      console.error('CropLogo.handleSubmit error:')
      console.error(err)
      return false
    }
  }
  const showModal = () => {
    if (!isViewmodeParticipant && typeof detailLogo !== 'string') {
      const originalLogo = graphicLogo.find(
        l =>
          l.resourceId ===
          (detailLogo?.originalResourceId || detailLogo?.resourceId),
      )
      ModalService.show(
        ImageCropper,
        {
          originalUrl: originalLogo?.value,
          onSaveImage: onSave,
          originalState: detailLogo?.originalResourceState,
          showChangeFile: false,
        },
        {
          maskClosable: false,
        },
      )
    }
  }
  if (!logoUrl) {
    return null
  }
  const detailLogo = isViewmodeMixer
    ? logoUrl
    : graphicLogo.find(item => item.value === logoUrl)
  if (!detailLogo) {
    return null
  }

  return (
    <div
      id='logoContainer'
      className={clsx({
        [css.ContainerLogo]: true,
        [css.LogoNotParticipant]: !isViewmodeParticipant,
      })}
      style={{
        top: `${ratioScaleLayout * 8}px`,
        right: `${ratioScaleLayout * 8}px`,
        width: `${ratioScaleLayout * cropboxW * 0.5}px`,
        height: `${ratioScaleLayout * cropboxH * 0.5}px`,
      }}
      onClick={showModal}
    >
      <img className={css.ImageLogo} src={logoUrl} draggable={false} />
      {!isViewmodeParticipant && (
        <span className={css.ImageLogoCrop} onClick={showModal}>
          <Icon icon='icon_pencil' size={9} />
        </span>
      )}
    </div>
  )
})
