import { S } from '../../../context/store'
import { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import type { SessionMode } from '../../../context/store/studio/WebrtcStore'

export const switchSessionMode = (mode: SessionMode) => {
  const $ = S.webrtc
  const $1 = S.insightEngine
  $1.mode = InsightEngineMode.Cursor
  $.sessionMode = mode
}
