import type { DatePickerProps as AntdDatePickerProps } from 'antd'
import { DatePicker as AntdDatePicker } from 'antd'
import clsx from 'clsx'
import type { CSSProperties, ElementType } from 'react'
import { memo, useRef, useState } from 'react'

import css from './datePickerContainer.module.scss'

import { Icon } from '../base/Icon'
import { BeamCalendar } from '../composites/Calendar'
import type { BeamCalendarProps } from '../composites/Calendar/type'

export type DatePickerContainerProps = {
  textDisable?: boolean
  prefixInput?: { content: string }
  inputStyle?: CSSProperties
  warmnessColor?: string
  warmnessColorGray?: string
}

type DatePickerProps = {
  componentCalendar?: ElementType<any>
  calenderProps?: BeamCalendarProps
} & DatePickerContainerProps &
  AntdDatePickerProps

export const DatePicker = memo((props: DatePickerProps) => {
  const {
    textDisable,
    calenderProps,
    prefixInput,
    warmnessColor,
    warmnessColorGray,
    ...propsDatePicker
  } = props
  const [open, setOpen] = useState(false)
  const onChangeFromPanel = useRef(false)
  const ComponentCalendar = props.componentCalendar

  const onPanelChange = (date: any, mode: any) => {
    setOpen(true)
    onChangeFromPanel.current = true
    calenderProps?.onPanelChange?.(date, mode)
  }
  const onSelect = (v: any) => {
    calenderProps?.onSelect?.(v)
    if (onChangeFromPanel.current) {
      setOpen(true)
      onChangeFromPanel.current = false
    } else {
      setOpen(false)
    }
  }
  const customFormat = (value: any) => {
    const dateString = `${value.format('ddd')}, ${value.format(
      'MMM',
    )} ${value.format('DD')}, ${value.format('YYYY')}`
    return prefixInput?.content ? `(${dateString})` : dateString
  }

  return (
    <div
      className={clsx({
        [css.DatePickerContainer]: true,
        [css.datePickerContainerDisableInput]: textDisable,
      })}
      style={{
        // @ts-ignore
        '--dataBefore': `'${prefixInput?.content}'`,
      }}
    >
      <AntdDatePicker
        {...propsDatePicker}
        clearIcon
        open={open}
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
        className={clsx('custom-datepicker', propsDatePicker.className)}
        format={customFormat}
        dropdownClassName={css.CustomDatePickerPanel}
        panelRender={() => {
          if (ComponentCalendar) {
            return (
              <ComponentCalendar
                containerProps={{
                  className: css.ComponentCalendar,
                }}
                {...calenderProps}
                warmnessColor={warmnessColor}
                warmnessColorGray={warmnessColorGray}
                fullscreen={false}
                disabledDate={props.disabledDate}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
                monthFormat='long'
              />
            )
          } else {
            return (
              <BeamCalendar
                containerProps={{
                  className: css.ComponentCalendar,
                }}
                {...calenderProps}
                fullscreen={false}
                disabledDate={props.disabledDate}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
                monthFormat='long'
              />
            )
          }
        }}
        suffixIcon={
          <Icon
            icon='icon_calendar_schedule'
            size={18}
            className={css.IconColor}
          />
        }
      />
    </div>
  )
})
