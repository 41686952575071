import clsx from 'clsx'
import { clamp, sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import type {
  ChangeEvent,
  MouseEvent as ReactMouseEvent,
  RefObject,
} from 'react'
import { useEffect, useRef, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'

import css from './GraphicGradientPicker.module.scss'

import { S } from '../../../context/store'
import { parseStrGradientToObject } from '../../../context/store/studio/color'
import type { TGradientColors } from '../../../context/store/studio/WebrtcStore'
import { COLOR_GRADIENT_DEFAULT } from '../../../context/store/studio/WebrtcStore'

type TProps = {
  defaultColor: string
  onSelectColor: (color: string) => void
  isLightTheme?: boolean
}
type TGrandientPicker = {
  listColors: TGradientColors[]
  rotate: number
  selectedColor: string
  onSelectColor: (color: string) => void
  onChangeColor: (color: string) => void
}

type TColorPickers = {
  palette: TGradientColors[]
  activeColorId: number
  onSelectPicker: (id: number) => void
  onAddPicker: (offset: number) => void
  onChangePosPicker: (id: number, offset: string) => void
}
type TPicker = {
  item: TGradientColors
  isActive: boolean
  refPickers: RefObject<HTMLDivElement>
  onSelectPicker: (id: number) => void
  onChangePosPicker: (id: number, offset: string) => void
}

export const WIDTH_GRANDIENT_PICKER = 254

export const GrandientPickerMain = observer(
  ({ onSelectColor, defaultColor, isLightTheme }: TProps) => {
    const { warmnessColor } = S.local
    const parseGradient = parseStrGradientToObject(defaultColor)
    const listColors = parseGradient?.colors || COLOR_GRADIENT_DEFAULT
    const defaultRotate = parseGradient?.rotate || 90
    const [rotate, setRotate] = useState(defaultRotate)
    const [selectedColor, setColor] = useState(listColors[0].color)
    const onSelectGradientColor = (color: string) => {
      onSelectColor(color)
    }

    const onChangeColor = (color: string) => {
      setColor(color)
    }

    const onChangeRotate = (value: number) => {
      setRotate(value)
    }
    return (
      <>
        <div className={css.ColorPickerRange}>
          <GradientPicker
            rotate={rotate}
            listColors={listColors}
            selectedColor={selectedColor}
            onSelectColor={onSelectGradientColor}
            onChangeColor={onChangeColor}
          />
        </div>
        <div className={css.ColorPickerContent}>
          <HexColorPicker color={selectedColor} onChange={onChangeColor} />
        </div>
        <div
          style={{
            // @ts-ignore
            '--background31': warmnessColor,
          }}
          className={css.ColorPickerInput}
        >
          <div
            className={clsx({
              [css.ColorPickerHexInput]: true,
              [css.colorPickerHexInputGradient]: true,
            })}
            // isgradient
          >
            <label
              className={clsx(css.LabelPicker, {
                [css.LabelPickerLight]: isLightTheme,
              })}
            >
              Hex
            </label>
            <HexColorInput
              prefixed
              onChange={onChangeColor}
              color={selectedColor}
            />
          </div>
          <div className={css.ColorPickerRotateInput}>
            <InputRotate
              rotate={rotate}
              isLightTheme={isLightTheme}
              onChangeRotate={onChangeRotate}
            />
          </div>
        </div>
      </>
    )
  },
)
export const GradientPicker = ({
  listColors,
  onSelectColor,
  rotate,
  selectedColor,
  onChangeColor,
}: TGrandientPicker) => {
  const [activeColorId, setActiveColorId] = useState(1)
  const [palette, setPalette] = useState<TGradientColors[]>(listColors)

  const findAndUpdateActiveColor = (activeId: number, listUpdates: object) => {
    const indexItem = palette.findIndex(item => item.id === activeId)
    if (indexItem >= 0) {
      const newItem = {
        ...palette[indexItem],
        offset: Number(palette[indexItem].offset),
        ...listUpdates,
      }
      const newPalete = [
        ...palette.slice(0, indexItem),
        newItem,
        ...palette.slice(indexItem + 1),
      ]
      return newPalete
    }
    return palette
  }
  const handleAddPicker = (offset: number) => {
    const newOffset = (offset / WIDTH_GRANDIENT_PICKER).toFixed(2)
    const newId = Math.round(Math.random() * 1000)
    const entry = {
      id: newId,
      offset: parseFloat(newOffset),
      color: selectedColor,
    }

    const updatedPalette: TGradientColors[] = [...palette, entry]

    setActiveColorId(newId)
    onUpdatePalette(updatedPalette)
  }

  const onSelectPicker = (id: number) => {
    setActiveColorId(id === activeColorId ? 0 : id)
    if (id !== activeColorId) {
      const detailColor = palette.find(item => item.id === id)
      onChangeColor && onChangeColor(detailColor?.color || '#ffffff')
    }
  }
  const handleKeydownPicker = (e: KeyboardEvent) => {
    if (activeColorId !== 0) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if (palette.length > 2) {
          const newPalette = palette.filter(item => item.id !== activeColorId)
          onUpdatePalette(newPalette)
          setActiveColorId(0)
        }
      }
      if (e.key === 'Escape') {
        setActiveColorId(0)
      }
    }
  }
  const renderStyle = (value: TGradientColors[], deg: number) => {
    const sortValue = sortBy(value, ['offset'])
    let str = `linear-gradient(${deg}deg`
    sortValue.forEach((item: TGradientColors) => {
      str += `,${item.color} ${Math.round(item.offset * 100)}%`
    })
    str += ')'
    return str
  }
  const onChangePosPicker = (id: number, newOffset: string) => {
    const newPalette = findAndUpdateActiveColor(id, { offset: newOffset })
    onUpdatePalette(newPalette)
  }
  const onUpdatePalette = (newPalette: TGradientColors[]) => {
    setPalette(newPalette)
    onSelectColor && onSelectColor(renderStyle(newPalette, rotate))
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeydownPicker)
  }, [activeColorId])

  useEffect(() => {
    onSelectColor && onSelectColor(renderStyle(listColors, rotate))
    setPalette(listColors)
  }, [])

  useEffect(() => {
    onSelectColor && onSelectColor(renderStyle(palette, rotate))
  }, [rotate])

  useEffect(() => {
    if (activeColorId !== 0) {
      const newPalette = findAndUpdateActiveColor(activeColorId, {
        color: selectedColor,
      })
      onUpdatePalette(newPalette)
    }
  }, [selectedColor])
  return (
    <>
      <ColorPickers
        palette={palette}
        onSelectPicker={onSelectPicker}
        onChangePosPicker={onChangePosPicker}
        onAddPicker={handleAddPicker}
        activeColorId={activeColorId}
      />
      <div
        className={css.GrandientPickerPalette}
        style={{
          background: renderStyle(palette, rotate),
          width: WIDTH_GRANDIENT_PICKER,
        }}
      />
    </>
  )
}

const ColorPickers = ({
  onAddPicker,
  palette,
  onSelectPicker,
  activeColorId,
  onChangePosPicker,
}: TColorPickers) => {
  const refPickers = useRef<HTMLDivElement>(null)
  const handleAddPicker = (e: ReactMouseEvent) => {
    const offset =
      e.clientX - (e.currentTarget?.getBoundingClientRect().left || 0)
    onAddPicker(offset)
  }
  return (
    <div className={css.GrandientListPickers} ref={refPickers}>
      <div
        className={css.GrandientPickerOverlay}
        onMouseDown={handleAddPicker}
      ></div>
      {palette.map((item: TGradientColors) => (
        <Picker
          key={item.id}
          item={item}
          isActive={item.id === activeColorId}
          onSelectPicker={onSelectPicker}
          onChangePosPicker={onChangePosPicker}
          refPickers={refPickers}
        />
      ))}
    </div>
  )
}

const Picker = ({
  item,
  isActive,
  onSelectPicker,
  onChangePosPicker,
  refPickers,
}: TPicker) => {
  const [offset, setOffset] = useState<number>(item.offset)
  const handleMouseDown = () => {
    window.addEventListener('mousemove', handleMovePicker)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMovePicker)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMovePicker = (e: MouseEvent) => {
    if (refPickers?.current) {
      const data = refPickers.current.getBoundingClientRect()
      const endX = data.x + data.width
      setOffset(prev =>
        e.clientX > endX || e.clientX < data.x
          ? prev
          : clamp(prev, 0, 1) + e.movementX / WIDTH_GRANDIENT_PICKER,
      )
    }
  }
  useEffect(() => {
    if (offset >= 0 && offset <= 1) {
      onChangePosPicker(item.id, offset.toFixed(2).toString())
    }
  }, [offset])
  return (
    <div
      className={clsx({
        [css.GradientPickerItem]: true,
        [css.gradientPickerItemActive]: isActive,
      })}
      onClick={() => onSelectPicker(item.id)}
      style={{ left: WIDTH_GRANDIENT_PICKER * item.offset - 13 }}
      onMouseDown={handleMouseDown}
    >
      <div
        className={css.GradientPickerItemContent}
        style={{ backgroundColor: item.color }}
      />
    </div>
  )
}

const InputRotate = observer(
  ({
    rotate,
    onChangeRotate,
    isLightTheme,
  }: {
    rotate: number
    onChangeRotate: (value: number) => void
    isLightTheme?: boolean
  }) => {
    const [value, setValue] = useState<number>(rotate)
    const { warmnessColorGray } = S.local
    const handleMouseDown = () => {
      window.addEventListener('mousemove', handleDrag)
      window.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleDrag)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    const handleDrag = (e: MouseEvent) => {
      e.preventDefault()
      setValue(prev => (prev >= 360 || prev <= -360 ? 0 : prev + e.movementX))
    }
    const handleChangeRotate = (e: ChangeEvent<HTMLInputElement>) => {
      const format = e.target.value.replace(/([^0-9,-]+)/gi, '')
      let newRotate: number = format === '' ? 0 : +format
      newRotate = clamp(newRotate, -360, 360)
      setValue(newRotate)
    }

    useEffect(() => {
      onChangeRotate(value)
    }, [value])
    return (
      <>
        <label
          className={clsx(css.LabelPicker, {
            [css.LabelPickerLight]: isLightTheme,
          })}
        >
          Rotate
        </label>
        <input
          className={css.InputColorRotate}
          value={value}
          onChange={handleChangeRotate}
        />
        <label
          style={{ background: warmnessColorGray }}
          className={clsx(css.InputCircleRotate, {
            [css.inputCircleRotateLight]: isLightTheme,
          })}
          onMouseDown={handleMouseDown}
          spellCheck={false}
        >
          <span
            className={clsx(css.LabelCircleRotate, {
              [css.labelCircleRotateLight]: isLightTheme,
            })}
            style={{ transform: `rotate(${value}deg)` }}
          />
        </label>
      </>
    )
  },
)
