import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './MediaControllerClient.module.scss'

import type { MediaControl } from '#rn-shared/mediasoup/type'

import { Map } from '../../../components/base/Map'
import { S } from '../../../context/store'
import { updateLocalMediaControlData } from '../actions/updateMediaControlDataClient'

type MediaProps = {
  data: MediaControl
}

export const MediaControllerClient = observer(() => {
  const { mediaControlData } = S.webrtc
  return (
    <Map<MediaControl>
      list={mediaControlData}
      renderItem={item => <MediaController key={item.id} data={item} />}
    />
  )
})

export const MediaController: FC<MediaProps> = observer(({ data }) => {
  const {
    mediaControlOnStateChange,
    mediaControlOnStream,
    removeProducerMediaControl,
  } = S.webrtc
  const { id, mediaId, muted, url, paused, repeat, seeking, from } = data
  const ref = useRef<HTMLVideoElement | null>(null)
  const [metaUrl, setMetaUrl] = useState('')
  const streamR = useRef<MediaStream>()
  const urlR = useRef(url)
  const interval = useRef<any>(null)
  const pausedRef = useRef<Boolean | undefined>(false)
  const seekingRef = useRef<Boolean | undefined>(false)
  useEffect(() => {
    S.webrtc.mediaController[id] = ref.current
    return () => {
      delete S.webrtc.mediaController[id]
    }
  }, [])
  const videoOnStream = () => {
    if (!ref.current || !metaUrl) {
      return
    }
    // try stop existing stream?
    const existingStream = streamR.current
    if (existingStream) {
      try {
        existingStream.getTracks().forEach(t => t.stop())
      } catch (err) {}
    }
    // try handle play pause on change?
    try {
      const urlChanged = urlR.current !== metaUrl
      urlR.current = metaUrl
      if (!existingStream || urlChanged) {
        // auto play on load
        ref.current.play()
      }
    } catch (err) {}
    // capture stream and send to server

    streamR.current = ref.current.captureStream()
    mediaControlOnStream(streamR.current, mediaId, from)
    mediaControlOnStateChange(id, {
      duration: ref.current.duration,
      currentTime: ref.current.currentTime,
      paused: ref.current.paused,
    })
  }
  useEffect(() => {
    interval.current = setInterval(() => {
      if (!ref.current || pausedRef.current || seekingRef.current) {
        return
      }
      mediaControlOnStateChange(id, {
        currentTime: ref.current.currentTime,
      })
    }, 500)

    return () => {
      interval.current && clearInterval(interval.current)
      interval.current = null
    }
  }, [])
  useEffect(() => {
    pausedRef.current = paused
  }, [paused])
  useEffect(() => {
    seekingRef.current = seeking
  }, [seeking])
  useEffect(() => {
    videoOnStream()
    return () => {
      removeProducerMediaControl(data)
    }
  }, [metaUrl])
  const handleOnPause = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (!ref.current) {
      return
    }
    mediaControlOnStateChange(id, {
      paused: true,
    })
  }
  const handleOnPlay = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (!ref.current) {
      return
    }
    mediaControlOnStateChange(id, {
      paused: false,
    })
    updateLocalMediaControlData(id, {
      paused: false,
      currentTime: e.currentTarget.currentTime,
    })
  }
  const handleSeeked = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (!ref.current) {
      return
    }
    mediaControlOnStateChange(id, {
      seeking: false,
      currentTime: e.currentTarget.currentTime,
    })
    updateLocalMediaControlData(id, {
      seeking: false,
      currentTime: e.currentTarget.currentTime,
    })
  }
  const handleTimeUpdate = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => {
    if (e.currentTarget.currentTime === 0 && !repeat) {
      ref.current?.pause()
      updateLocalMediaControlData(id, {
        paused: true,
      })
    }
  }
  const handleVolumeChangeCapture = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => {
    if (!ref.current) {
      return
    }
    mediaControlOnStateChange(id, {
      volumeSeeking: false,
      volume: e.currentTarget.volume * 100,
      muted,
    })
    updateLocalMediaControlData(id, {
      volumeSeeking: false,
      volume: e.currentTarget.volume * 100,
    })
  }
  return data.type === 'video' ? (
    <video
      crossOrigin='anonymous'
      src={url}
      ref={ref}
      onPause={handleOnPause}
      onPlay={handleOnPlay}
      className={css.video}
      onSeeked={handleSeeked}
      onVolumeChangeCapture={handleVolumeChangeCapture}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={() => setMetaUrl(url)}
      loop={true}
      muted={muted}
    />
  ) : (
    <audio
      crossOrigin='anonymous'
      src={url}
      ref={ref}
      onPause={handleOnPause}
      onPlay={handleOnPlay}
      className={css.video}
      onSeeked={handleSeeked}
      onVolumeChangeCapture={handleVolumeChangeCapture}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={() => setMetaUrl(url)}
      loop={true}
      muted={muted}
    />
  )
})
