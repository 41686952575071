import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import css from './Transcript.module.scss'

import { Icon } from '../../../components/base/Icon'
import { AppSpin } from '../../../components/base/Spin'
import { WarmnessGraySpan } from '../../../components/composites/WarmnessGraySpan'
import { BeamInput } from '../../../components/widget/Input'
import type { SearchTranscriptQueryVariables } from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { TranscriptStatus } from '../../../context/store/insight-engine/InsightEngineStore'
import { useDebounceValue } from '../../../utils/useDebounceValue'
import { getResourcesInsightEngine } from '../actions/getResourcesInsightEngine'
import { getTranscript } from '../actions/getTranscript'
import { loadmoreTranscript } from '../actions/loadmoreTranscript'
import { startTranscript } from '../actions/startTranscript'
import { updateResourceSelected } from '../actions/updateResource'
import { TranscriptLoading } from './TranscriptLoading'
import { TranscriptSingleUser } from './TranscriptSingleUser'

export const Transcript = observer(() => {
  const {
    resourceSelected,
    transcripts = [],
    paramsTranscript: { loadingTranscript, moreTranscript },
    mediaPlayer: { currentTime },
    transcriptCount,
    isBlurMode,
  } = S.insightEngine
  const timer = useRef<any>(null)
  const itemRef = useRef<HTMLDivElement>(null)
  const [searchTerm, setSearchTerm, debounceSearch] = useDebounceValue<string>(
    300,
    '',
  )

  const refIsSearch = useRef<boolean>(false)
  const refPrevHightlight = useRef<string>('')
  const refBody = useRef<any>()
  const paramsGetTranscript: SearchTranscriptQueryVariables = {
    resourceInInsightEngineId: resourceSelected?.id ?? '',
    filter: debounceSearch
      ? { content_like: `%${debounceSearch}%` }
      : undefined,
    order: 'startTime_asc',
    page: {
      limit: 1000,
    },
  }

  const handleScrollView = (idScroll: string) => {
    const el = document.getElementById(`trans-body-${idScroll}`)
    if (el) {
      el.scrollIntoView({
        block:
          el.clientHeight > refBody.current?.scrollHeight ? 'start' : 'center',
        behavior: 'smooth',
      })
      refPrevHightlight.current = idScroll
    }
  }

  useEffect(() => {
    resourceSelected &&
      refIsSearch.current &&
      getTranscript(paramsGetTranscript)
  }, [debounceSearch])

  useEffect(() => {
    if (currentTime) {
      const matchTimeBw = transcripts.find(
        item => item.startTime <= currentTime && currentTime <= item.endTime,
      )
      if (matchTimeBw) {
        if (matchTimeBw.id === refPrevHightlight.current) {
          return
        }
        handleScrollView(String(matchTimeBw.id))
        return
      }
      const matchTimeStart = transcripts.find(
        item => currentTime < item.startTime,
      )
      matchTimeStart && handleScrollView(String(matchTimeStart.id))
    }
  }, [currentTime, moreTranscript])

  useEffect(() => {
    const lastItem = transcripts.at(-1)
    if (lastItem && currentTime && lastItem.endTime < currentTime) {
      handleScrollView(String(lastItem.id))
    }
  }, [transcripts.at(-1), currentTime])

  const handleTranscript = () => {
    if (!resourceSelected) {
      return
    }
    const willGet = [TranscriptStatus.InProcess, TranscriptStatus.UnTranscript]
    const { status } = resourceSelected
    if (willGet.includes(status as TranscriptStatus)) {
      if (status === TranscriptStatus.UnTranscript) {
        startTranscript(resourceSelected.id)
      }
      timer.current = setInterval(async () => {
        const [r] = await getResourcesInsightEngine({
          insightEngineId: resourceSelected.insightEngineId,
          filter: { id: resourceSelected.id },
          isUpdateStore: false,
        })
        if (!r) {
          return
        }
        if (r.status !== resourceSelected.status) {
          updateResourceSelected({ status: r.status })
        }
      }, 10000)
    } else {
      if (timer.current) {
        clearInterval(timer.current)
      }
      getTranscript(paramsGetTranscript)
    }
  }
  useEffect(() => {
    handleTranscript()
    return () => timer.current && clearInterval(timer.current)
  }, [resourceSelected?.status, resourceSelected])

  const handleLoadmore = async () => {
    if (transcripts.length === transcriptCount) {
      return
    }
    await loadmoreTranscript(paramsGetTranscript)
  }
  const handleScroll = () => {
    const container = itemRef.current
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container
      if (scrollHeight - scrollTop <= clientHeight * 1.5) {
        handleLoadmore()
      }
    }
  }
  const renderTranscript = () => {
    if (loadingTranscript) {
      return (
        <div className={css.Spin}>
          <AppSpin size='small' />
        </div>
      )
    }
    if (resourceSelected?.status === TranscriptStatus.Failed) {
      return <span className={css.Spin}>Video not supported.</span>
    }
    if (!transcripts.length) {
      return <span className={css.Spin}>Transcript is empty</span>
    }

    return (
      <div className={css.Transcripts}>
        {transcripts.map(t => (
          <TranscriptSingleUser data={t} key={t.id} />
        ))}
        {moreTranscript && (
          <div className={css.MoreTranscript}>
            <AppSpin size='small' />
          </div>
        )}
      </div>
    )
  }
  if (isBlurMode) {
    return null
  }
  return (
    <div className={css.Wrapper} ref={refBody}>
      <div className={css.Header}>
        <div className={css.Text}>Transcript</div>
        <BeamInput
          className={css.Search}
          placeholder='Search in video'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onFocus={() => {
            refIsSearch.current = true
          }}
          onBlur={() => {
            refIsSearch.current = false
          }}
        />
        <span className={css.searchIcon}>
          <Icon icon='icon_search' size={16} className={css.IconColor} />
        </span>
        <WarmnessGraySpan
          className={clsx(css.LightIcon, { [css.iconDisabled]: true })}
        >
          <Icon icon='Star-Filled' size={16} className={css.IconColor} />
        </WarmnessGraySpan>
      </div>
      <div ref={itemRef} onScroll={handleScroll} className={css.Body}>
        {[TranscriptStatus.UnTranscript, TranscriptStatus.InProcess].includes(
          resourceSelected?.status as TranscriptStatus,
        ) ? (
          <TranscriptLoading />
        ) : (
          renderTranscript()
        )}
      </div>
    </div>
  )
})
