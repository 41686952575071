import isEqual from 'fast-deep-equal'
import { useSelector, useStore } from 'react-redux'

type InS<R, T> = {
  [k: string]: T extends 'get'
    ? (s: R, ...a: any[]) => unknown
    : (s: R) => unknown
}
type OutS<R, T, S extends InS<R, T>> = T extends 'get'
  ? {
      [k in keyof S]: S[k] extends (s: R, ...a: infer A) => infer V
        ? (...a: A) => V
        : never
    }
  : {
      [k in keyof S]: S[k] extends (s: R) => infer V
        ? V
        : S[k] extends () => infer V
          ? V
          : never
    }

type InA = {
  [k: string]: (...a: any[]) => unknown
}
type OutA<A extends InA> = {
  [k in keyof A]: (...a: Parameters<A[k]>) => void
}

type InH = {
  [k: string]: (...a: any[]) => unknown
}
type OutH<H extends InH> = {
  [k in keyof H]: (
    ...a: Parameters<H[k]> extends [GetReduxContext, ...infer P] ? P : never
  ) => Promise<ReturnType<H[k]> extends Promise<infer R> ? R : ReturnType<H[k]>>
}

type In1<S, A, H> = {
  selectors: S
  actions: A
  asyncActions: H
}
type Out1<R, T, S extends InS<R, T>, A extends InA, H extends InH> = {} & OutS<
  R,
  T,
  S
> &
  OutA<A> &
  OutH<H>

type In2<S, A> = {
  selectors: S
  actions: A
}
type Out2<R, T, S extends InS<R, T>, A extends InA> = {} & OutS<R, T, S> &
  OutA<A>

type In3<S, H> = {
  selectors: S
  asyncActions: H
}
type Out3<R, T, S extends InS<R, T>, H extends InH> = {} & OutS<R, T, S> &
  OutH<H>

type In4<A, H> = {
  actions: A
  asyncActions: H
}
type Out4<A extends InA, H extends InH> = {} & OutA<A> & OutH<H>

type In5<S> = {
  selectors: S
}
type Out5<R, T, S extends InS<R, T>> = OutS<R, T, S>

type In6<A> = {
  actions: A
}
type Out6<A extends InA> = OutA<A>

type In7<H> = {
  asyncActions: H
}
type Out7<H extends InH> = OutH<H>

type UseOrGetRedux<R = any, T extends 'use' | 'get' = 'use'> = {
  <S extends InS<R, T>, A extends InA, H extends InH>(
    p: In1<S, A, H>,
  ): Out1<R, T, S, A, H>
  <S extends InS<R, T>, A extends InA>(p: In2<S, A>): Out2<R, T, S, A>
  <S extends InS<R, T>, H extends InH>(p: In3<S, H>): Out3<R, T, S, H>
  <A extends InA, H extends InH>(p: In4<A, H>): Out4<A, H>
  <S extends InS<R, T>>(p: In5<S>): Out5<R, T, S>
  <A extends InA>(p: In6<A>): Out6<A>
  <H extends InH>(p: In7<H>): Out7<H>
}
export type UseReduxUntyped<R = any> = UseOrGetRedux<R>
export type GetReduxUntyped<
  C extends GetReduxContext = any,
  R = ReturnType<C['store']['getState']>,
> = UseOrGetRedux<R, 'get'>

export const useReduxUntyped: UseReduxUntyped = (p: {
  selectors?: FuncM
  actions?: FuncM
  asyncActions?: FuncM
}) => {
  let s = useSelector(
    r =>
      p.selectors
        ? Object.entries(p.selectors).reduce<AnyM>((m, [k, v]) => {
            m[k] = v(r)
            return m
          }, {})
        : {},
    isEqual,
  )
  // clone s to have isEqual working
  // below we will assign actions and asyncActions into s
  // which will make isEqual always return false if not clone
  s = { ...s }

  const store = useStore()
  _wrapActions(s, store.dispatch, p.actions)

  if (p.asyncActions) {
    if (!('context' in store) || !store.context) {
      throw new Error('Require store.context to use asyncActions')
    }
    _wrapAsyncActions(s, store.context as any, p.asyncActions)
  }

  return s
}

type GetReduxContext = {
  store: {
    getState: () => any
    dispatch: Function
  }
}
export const createGetRedux = <C extends GetReduxContext>(ctx: C) =>
  ((p: { selectors?: FuncM; actions?: FuncM; asyncActions?: FuncM }) => {
    const {
      store: { getState, dispatch },
    } = ctx

    const s = p.selectors
      ? Object.entries(p.selectors).reduce<AnyM>((m, [k, v]) => {
          m[k] = (...a: any[]) => v(getState(), ...a)
          return m
        }, {})
      : {}

    _wrapActions(s, dispatch, p.actions)
    _wrapAsyncActions(s, ctx, p.asyncActions)

    return s
  }) as GetReduxUntyped<C>

const _wrapActions = (s: AnyM, dispatch: Function, actions?: FuncM) => {
  if (!actions) {
    return
  }
  Object.keys(actions).forEach(k => {
    s[k] = (...a: any[]) => dispatch(actions[k](...a))
  })
}
export const wrapActions = <T extends InA>(
  dispatch: Function,
  actions: T,
): OutA<T> => {
  const s: AnyM = {}
  _wrapActions(s, dispatch, actions)
  return s as any
}

const _wrapAsyncActions = (
  s: AnyM,
  ctx?: GetReduxContext,
  asyncActions?: FuncM,
) => {
  if (!ctx || !asyncActions) {
    return
  }
  Object.keys(asyncActions).forEach(k => {
    s[k] = async (...a: any[]) => asyncActions[k](ctx, ...a)
  })
}
export const wrapAsyncActions = <T extends InH>(
  ctx: GetReduxContext,
  asyncActions: T,
): OutH<T> => {
  const s: AnyM = {}
  _wrapAsyncActions(s, ctx, asyncActions)
  return s as any
}

type AnyM = { [k: string]: any }
type FuncM = { [k: string]: Function }
