import type { ICropData } from '../../../components/ImageEditor/ImageCropper'

export type CustomizeUIData = {
  logoData?: {
    url: string
    originalUrl: string
    zoom?: number
    cropData?: ICropData
    cropBoxData?: ICropData
    rotation?: number
  }
  title?: string
  subTitle?: string
  textCoverPhotoColor?: string
  coverPhoto?: string
}
export class CustomizeUIStore {
  isCustomize: boolean = false
  customizeData: CustomizeUIData = {
    title: '',
    subTitle: '',
    coverPhoto: '',
    textCoverPhotoColor: '',
  }
  theme: 'dark' | 'light' = 'dark'
  loadingSave?: boolean
}
