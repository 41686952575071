import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useRef, useState } from 'react'
import type { CustomArrowProps } from 'react-slick'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// eslint-disable-next-line css-modules/no-unused-class
import css from './LiveStreamSelectedChat.module.scss'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import type {
  TAttachments,
  TChatMessage,
} from '../../../context/store/studio/WebrtcStore'
import { ItemModalAttachment } from './ChatAttachments'
import { renderAvatar } from './ChatItem'

export const LiveStreamSelectedChat = observer(() => {
  const handleDeleteChat = () => {
    if (S.webrtc.isOnlyViewStream) {
      return
    }
    S.webrtc.updateAndEmit({
      selectedChat: '',
    })
  }

  if (S.webrtc.selectedChat === '') {
    return null
  }
  const detailChat = S.webrtc.chatMessages.find(
    item => item.message.chatId === S.webrtc.selectedChat,
  )
  if (!detailChat) {
    return null
  }
  const ratioScale = S.webrtc.ratioScaleLayout
  return (
    <div
      className={clsx({
        [css.LiveStreamSelectedChatContent]: true,
        [css.liveStreamSelectedChatContentShowDelete]:
          !S.webrtc.isOnlyViewStream,
      })}
      style={{
        bottom: (ratioScale || 0) * 16,
        left: (ratioScale || 0) * 16,
        borderRadius: ratioScale * 16,
        padding: `${ratioScale * 16}px ${ratioScale * 12}px`,
        minWidth: `${ratioScale * 250}px`,
        maxWidth: `${ratioScale * 320}px`,
        transition: 'border-radius 1s',
        // @ts-ignore
        '--mtItemChatAttachment': `${ratioScale * 12}px`,
        '--hItemChatAttachment': `${ratioScale * 220}px`,
        '--mtItemAttachmentContent': `${ratioScale * 12}px`,
        '--mhItemAttachmentDetailImage': `${ratioScale * 220}px`,
        '--hItemChatAttachmentImg': `${ratioScale * 220}px`,
        '--wContentSliderArrow': `${ratioScale * 32}px`,
        '--hContentSliderArrow': `${ratioScale * 32}px`,
        '--fContentSliderArrow': `${ratioScale * 14}px`,
        '--lhContentSliderArrow': `${ratioScale * 32}px`,
        '--rContentSliderNextArrow': `${ratioScale * 15}px`,
        '--lContentSliderPrevArrow': `${ratioScale * 15}px`,
      }}
      // ratioScale={store.webrtc.ratioScaleLayout}
      // isCanShowDelete={!store.webrtc.isOnlyViewStream}
    >
      <ItemChat chat={detailChat} />
      {!S.webrtc.isViewmodeParticipant && !S.webrtc.isOnlyViewStream && (
        <span className={css.ItemIconDelete} onClick={handleDeleteChat}>
          <Icon icon='Close1' size={12} />
        </span>
      )}
    </div>
  )
})

const ItemChat = observer(({ chat }: { chat: TChatMessage }) => {
  const handleDeleteAttachment = (attachmentId: string) => {
    S.webrtc.deleteAttachment(chat.message.chatId, attachmentId)
  }
  const ratioScale = S.webrtc.ratioScaleLayout
  return (
    <>
      <div
        className={clsx({
          [css.ItemChatContainer]: true,
          [css.itemChatContainerOnliveStream]: true,
        })}
        style={{
          // @ts-ignore
          '--mbItemChatContainerOnLiveStream': `${ratioScale * 12}px`,
          '--wIChatAvatar': `${ratioScale * 28}px`,
          '--hIChatAvatar': `${ratioScale * 28}px`,
          '--fIChatAvatar': `${ratioScale * 14}px`,
          '--wItemChatContent': `${ratioScale * 35}px`,
          '--mlItemChatContent': `${ratioScale * 7}px`,
          '--pItemChatContent': `${ratioScale * 12}px`,
          '--fItemChatContent': `${ratioScale * 16}px`,
          '--mlItemAttachmentContent': `${ratioScale * 35}px`,
          '--fItemChatTime': `${ratioScale * 10}px`,
          '--mtItemChatOnAir': `${ratioScale * 10}px`,
          '--pItemChatOnAir': `${2 * ratioScale}px ${4 * ratioScale}px`,
          '--mItemAttachmentList': `0 -${2 * ratioScale}px`,
          '--mbItemAttachmentDetailImage': `${ratioScale * 14}px`,
          '--pxItemAttachmentDetailImage': `0 ${2 * ratioScale}px`,
          '--hItemAttachmentDetailImage': `${ratioScale * 66}px`,
        }}
      >
        {renderAvatar(chat.user, {
          width: `${ratioScale * 36}px`,
          height: `${ratioScale * 36}px`,
          fontSize: `${ratioScale * 18}px`,
        })}
        {chat.message.content !== '' && (
          <div className={css.ItemChatContent}>
            <div className={css.ItemChatInfo}>
              <span
                className={clsx(css.ItemChatName, css.selectedChatItemChatInfo)}
              >
                {chat.user.name}
              </span>
              <span
                className={clsx(css.ItemChatTime, css.selectedChatItemChatInfo)}
              >
                {getTimeFormat(chat.message.time)}
              </span>
            </div>
            <div
              className={clsx(
                css.ItemChatMessage,
                css.selectedChatItemChatMessage,
              )}
              dangerouslySetInnerHTML={{ __html: ` ${chat.message.content}` }}
            ></div>
          </div>
        )}
      </div>
      {chat.message.attachments.length > 0 && (
        <div className={css.ItemChatAttachment}>
          <SlideAttachment
            attachments={chat.message.attachments}
            onDeleteAttachment={handleDeleteAttachment}
          />
        </div>
      )}
    </>
  )
})

const initAttachment = {
  id: '',
  mediaType: '',
  value: '',
  name: '',
  size: 0,
}
const SlideAttachment = ({
  attachments,
  onDeleteAttachment,
}: {
  attachments: ReadonlyArray<TAttachments>
  onDeleteAttachment: (id: string) => void
}) => {
  const silderRef = useRef<any>()
  const [showModal, setShowModal] = useState<TAttachments>(initAttachment)
  if (attachments.length === 0) {
    return null
  }
  const onlyImage = attachments.every(item => item.mediaType === 'image')
  if (!onlyImage) {
    return null
  }
  if (attachments.length === 1) {
    return (
      <div className={css.containerSliderSelectedChat}>
        <img className={css.ItemChatAttachmentImg} src={attachments[0].value} />
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    dotsClass: css.Dots,
  }

  const next = () => {
    silderRef?.current?.slickNext()
  }
  const pre = () => {
    silderRef?.current?.slickPrev()
  }
  return (
    <>
      <div className={css.Arrows}>
        <CustomPrevArrow onClick={pre} />

        <CustomNextArrow onClick={next} />
      </div>
      <div className={css.containerSliderSelectedChat}>
        <Slider ref={r => (silderRef.current = r)} {...settings}>
          {attachments.map((item, index) => (
            <img
              className={css.ItemChatAttachmentImg}
              src={item.value}
              onClick={() => setShowModal(item)}
              key={index}
            />
          ))}
        </Slider>

        {showModal.id !== '' && (
          <ItemModalAttachment
            item={showModal}
            visible
            onCancel={() => setShowModal(initAttachment)}
            onDelete={() => onDeleteAttachment(showModal.id)}
          />
        )}
      </div>
    </>
  )
}

const CustomNextArrow = (props: CustomArrowProps) => (
  <div className={css.ContentSliderNextArrow} {...props}>
    <Icon icon='icon_arrow_right' size={15} />
  </div>
)
const CustomPrevArrow = (props: CustomArrowProps) => (
  <div className={css.ContentSliderPrevArrow} {...props}>
    <Icon icon='icon_arrow_left' size={15} />
  </div>
)
const getTimeFormat = (time: Date) => moment(time, 'HH:mm').format('HH:mm')
