import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import css from './VideoSection.module.scss'

import { AppSpin } from '../../../components/base/Spin'
import { ResizeOutsideApi } from '../../../context/shared/components/CustomLayoutReszieOutside'
import { S } from '../../../context/store'
import { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import { useContainerDimensions } from '../../../utils/useContainerDimension'
import { calculateRealSizeVideo } from '../actions/calculateRealSizeVideo'
import { BlurBlocks } from './BlurBlocks'

type Props = {
  type: InsightEngineMode
  muted?: boolean
  playing?: boolean
}

export const VideoSection: FC<Props> = observer(
  ({ muted = false, playing = false, type }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const {
      mediaPlayer,
      setMediaPlayer,
      setAllMediaPlayer,
      isBlurMode,
      mode,
      muteTimelines,
    } = S.insightEngine
    const { isPlaying, mediaLoading, currentTime, isMuted } = mediaPlayer
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { clientHeight, clientWidth } = useContainerDimensions(containerRef)
    const containerSizeProps = {
      width: clientWidth,
      height: clientHeight,
    }

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = currentTime ?? 0
      }
    }, [mode])

    useEffect(() => {
      if (!mediaLoading) {
        isPlaying && playing
          ? videoRef.current?.play()
          : videoRef.current?.pause()
      }
    }, [isPlaying, mediaLoading, playing, videoRef])

    useEffect(() => {
      setMediaPlayer(
        {
          videoRef: videoRef.current,
        },
        type,
      )
    }, [S.insightEngine.resourceSelected, type])
    useEffect(() => {
      if (
        isBlurMode &&
        mediaPlayer.videoRef &&
        type === InsightEngineMode.Edit
      ) {
        const originVideoSize = {
          width: mediaPlayer.videoRef?.videoWidth ?? 0,
          height: mediaPlayer.videoRef?.videoHeight ?? 0,
        }
        const { heightR, widthR } = calculateRealSizeVideo(
          originVideoSize,
          containerSizeProps,
        )
        ResizeOutsideApi.changeRatio({
          w: mediaPlayer.videoRef.videoWidth / widthR,
          h: mediaPlayer.videoRef.videoHeight / heightR,
        })
      }
    }, [containerSizeProps, mediaPlayer.videoRef, isBlurMode])

    useEffect(() => {
      const handleWindowResize = () => {
        setMediaPlayer(
          {
            videoRef: videoRef.current,
          },
          type,
        )
      }
      window.addEventListener('resize', handleWindowResize)
      return () => {
        window.removeEventListener('resize', handleWindowResize)
      }
    })

    const combineMute =
      muteTimelines.some(
        t =>
          (t.startTime ?? 0) <= (mediaPlayer.currentTime ?? 0) &&
          (t.endTime ?? 0) >= (mediaPlayer.currentTime ?? 0),
      ) || isMuted

    if (!S.insightEngine.resourceSelected) {
      return (
        <div className={css.Wrapper}>
          <div className={css.LoadingNoVideo}>No video</div>
        </div>
      )
    }

    return (
      <div ref={containerRef} className={css.Wrapper}>
        <BlurBlocks type={type} containerSize={containerSizeProps} />
        {mediaLoading && (
          <div className={css.Loading}>
            <AppSpin />
          </div>
        )}
        <video
          ref={videoRef}
          id={type}
          key={type}
          src={S.insightEngine.resourceSelected.resource?.url}
          onLoadStart={() => setMediaPlayer({ mediaLoading: true }, type)}
          onSeeking={() => {
            setMediaPlayer({ mediaLoading: true }, type)
          }}
          onSeeked={() => {
            setMediaPlayer({ mediaLoading: false }, type)
          }}
          onLoadedData={e => {
            setMediaPlayer(
              {
                mediaLoading: false,
                duration: e.currentTarget.duration,
              },
              type,
            )
          }}
          onEnded={() => {
            setMediaPlayer(
              {
                isPlaying: false,
              },
              type,
            )
          }}
          playsInline
          onTimeUpdate={e =>
            setAllMediaPlayer({ currentTime: e.currentTarget.currentTime })
          }
          muted={combineMute}
        />
      </div>
    )
  },
)
