import clsx from 'clsx'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import buble from '../../../assets/images/AIda-bubble.svg'
import css from './ChatBoxBtn.module.scss'

import { Popover } from '#HACK_FOR_RN_ONLY/src/components/base/Popover'

import { Icon } from '../../../components/base/Icon'
import { createChatbotMsg } from '../../../pages/InsightEngine/actions/createChatbotMsg'
import { searchChatbotMsg } from '../../../pages/InsightEngine/actions/searchChatbotMsg'
import {
  ChatBoxContent,
  NewChatBox,
} from '../../../pages/InsightEngine/components/ChatBoxContent'
import { S } from '../../store'

interface IProps {
  x?: number
  y?: number
  width?: number
  height?: number
}

export const ChatBoxBtn = observer(
  ({
    style: { x, y, width, height },
    className,
  }: {
    style: IProps
    className?: string
  }) => {
    const [visible, setVisible] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const { chatbotContexts } = S.insightEngine
    const { chatbotMessages = [], isAnswering } =
      S.insightEngine.searchChatbotMsg

    useEffect(() => {
      searchChatbotMsg()
    }, [])

    const type =
      chatbotContexts.find(r => r.name === 'insight-engine')?.name || 'main'

    const hide = () => {
      setVisible(false)
    }

    const handleVisibleChange = (value: boolean) => {
      setVisible(value)
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        debounceSendMessage()
      }
    }

    const handleSendMessage = () => {
      if (inputRef.current && inputRef.current.value !== '' && !isAnswering) {
        createChatbotMsg({
          type,
          content: inputRef.current.value ?? '',
        })
        inputRef.current.value = ''
      }
    }

    const debounceSendMessage = debounce(handleSendMessage, 50)

    const styled = {
      '--right': (x ?? 16) + 'px',
      '--bottom': (y ?? 16) + 'px',
      '--width': (width ?? 40) + 'px',
      '--height': (height ?? 40) + 'px',
    } as React.CSSProperties

    const menu = (
      <div className={css.Menu}>
        <div className={css.MenuHeader}>
          Aida
          <span onClick={hide} className={css.IconClose}>
            <Icon icon='Close1' size={12} />
          </span>
        </div>

        {!chatbotMessages.length ? (
          <NewChatBox />
        ) : (
          <ChatBoxContent data={chatbotMessages} isVisible={visible} />
        )}
        <div className={css.InputText}>
          <input
            ref={inputRef}
            onKeyDown={handleEnter}
            placeholder='Ask me anything'
            className={css.Input}
          />
          <span
            onClick={handleSendMessage}
            className={clsx(css.SendIcon, {
              [css.SendIconDisable]: isAnswering,
            })}
          >
            <Icon icon='Resend' size={24} />
          </span>
        </div>
      </div>
    )

    return (
      <div style={styled} className={css.Wrapper}>
        <Popover
          trigger={['click']}
          align={{ offset: [10, -260] }}
          placement='left'
          content={menu}
          overlayClassName={css.PopoverWrapper}
          showArrow={false}
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <div className={clsx(css.Button, className)}>
            <img className={css.AidaImage} src={buble} alt='' />
          </div>
        </Popover>
      </div>
    )
  },
)
