import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import css from './style.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { Button } from '../../../../components/base/Button'
import { Map } from '../../../../components/base/Map'
import { AppSpin } from '../../../../components/base/Spin'
import { BeamSwitch } from '../../../../components/base/Switch'
import { BeamText } from '../../../../components/base/Text'
import { ModalService } from '../../../../components/Modal/Modal'
import { HIDDEN_FOR_NEXT_FEATURES } from '../../../../config'
import { S } from '../../../../context/store'
import { DestinationAction } from '../DestinationAction'
import { createComponent } from './logic'
import type { TOutputs } from './type'

export const ModalDestination = observer(
  createComponent(
    ({
      warmnessColor,
      isLightTheme,
      handleCheckRecord,
      isRecord,
      searchOutput,
      outputs,
      outputInSession,
      refetchData,
      session,
      isPublishing,
      handleStart,
      handleStopLive,
      isAnyExpired,
      renderOutputList,
      isWaiting,
    }) => {
      const [isSelected, setIsSelected] = useState<{
        status: boolean
        id: string
      }>()
      const renderModalContent = () => (
        <div className={css.ModalContent}>
          <div
            style={{ background: S.local.warmnessColor }}
            className={css.SelectDestinationWrapper}
            onMouseMove={e => e.stopPropagation()}
          >
            <div className={css.DesHeader}>
              <span className={css.DesTitle}>Selected destination</span>
            </div>
            <div className={css.SwRecord}>
              <BeamSwitch
                style={{ background: warmnessColor }}
                isLightTheme={isLightTheme}
                checked={isRecord}
                onChange={handleCheckRecord}
              />
              <BeamText
                className={css.DesRecordCheckTitle}
                containerProps={{
                  classNameContainer: css.SwRecordText,
                }}
              >
                Record this session
              </BeamText>
            </div>
            <div className={css.DesContent}>
              {!HIDDEN_FOR_NEXT_FEATURES && (
                <div className={css.RecentWrapper}>
                  <span className={css.DesTitle}>Recent</span>
                  <div className={css.Recents}></div>
                </div>
              )}
              <div className={css.RecentWrapper}>
                {searchOutput.fetching ? (
                  <div className={css.Loading}>
                    <AppSpin />
                  </div>
                ) : (
                  <div className={css.Recents}>
                    <Map<TOutputs>
                      list={outputs}
                      renderItem={r =>
                        renderOutputList(
                          r,
                          ({ oisSelected, expired, waiting, publishing }) => {
                            if (
                              isSelected?.status !== true &&
                              oisSelected !== undefined &&
                              !expired
                            ) {
                              setIsSelected({
                                status: true,
                                id: r.id,
                              })
                            } else if (
                              isSelected?.status === true &&
                              isSelected?.id === r.id &&
                              (oisSelected === undefined || expired)
                            ) {
                              setIsSelected({
                                status: false,
                                id: '',
                              })
                            }
                            return (
                              <DestinationAction
                                refreshDestination={refetchData}
                                destinationInSession={oisSelected as any}
                                destination={r}
                                key={r.id}
                                isPublishing={publishing}
                                isChecked={oisSelected ? true : false}
                                sessionId={session.id}
                                isExpired={expired}
                                isWaiting={waiting}
                              />
                            )
                          },
                        )
                      }
                    />
                    <DestinationAction
                      refreshDestination={refetchData}
                      sessionId={session.id}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={css.DesFooter}>
              <WarmnessGrayButton
                onClick={() => ModalService.hide()}
                className={css.BtnClose}
              >
                Close
              </WarmnessGrayButton>
              {!isPublishing ? (
                <Button
                  onClick={() => handleStart(isRecord)}
                  className={css.BtnStart}
                  containerType={'primary'}
                  disabled={
                    (!isRecord && !outputInSession.length) ||
                    isAnyExpired ||
                    !isSelected?.status ||
                    isWaiting
                  }
                >
                  Start Live
                </Button>
              ) : (
                <Button
                  onClick={() => handleStopLive()}
                  className={css.BtnStart}
                  containerType={'danger'}
                >
                  Stop Live
                </Button>
              )}
            </div>
          </div>
        </div>
      )
      return renderModalContent()
    },
  ),
)
