import type { ProtooClientRequestHandler } from '#rn-shared/mediasoup/type'
import type {
  GetMediasoupState,
  SetMediasoupState,
} from '#rn-shared/mediasoup/type-ui'

export const _onRequestNewConsumer = (
  get: GetMediasoupState,
  set: SetMediasoupState,
) => {
  const handler: ProtooClientRequestHandler<'newConsumer'> = async d => {
    const { mediasoup } = get()
    if (!mediasoup) {
      return
    }
    const {
      recv: { transport, consumers },
    } = mediasoup

    const c = await transport?.consume({
      id: d.id,
      producerId: d.producerId,
      kind: d.kind,
      rtpParameters: d.rtpParameters,
      appData: {
        ...d.appData,
        peerId: d.peerId,
      },
    })
    if (!c) {
      return
    }
    consumers[c.id] = c
    c.on('transportclose', () => {
      delete consumers[c.id]
    })

    set(s => {
      const peer = s.peers.find(p => p.id === d.peerId)
      if (!peer) {
        return
      }
      const k = d.appData?.share
        ? c.track.kind === 'audio'
          ? 'screenshareAudio'
          : 'screenshareVideo'
        : c.track.kind === 'audio'
          ? 'audio'
          : 'video'
      const media = {
        ...peer[k],
        id: c.id,
        track: c.track,
      }
      if (!d.appData.mediaId) {
        peer[k] = media
        return
      }
      peer.medias = {
        ...peer.medias,
        [d.appData.mediaId]: {
          ...peer.medias?.[d.appData.mediaId],
          [k]: media,
          to: d.appData.to,
        },
      }
    })
  }

  return handler
}
