import clsx from 'clsx'
import { findIndex } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Fragment, useMemo, useState } from 'react'

import css from './ModalChangePlan.module.scss'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { AppTabPane } from '../../../components/base/TabPane'
import { AppTabs } from '../../../components/base/Tabs'
import { BeamTooltip } from '../../../components/base/Tooltip'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import type {
  PlanCurrency,
  PlanInterval,
  PlanName,
} from '../../../context/gql/codegen'
import { useGqlUpgradePlan } from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { reloadCurrentPlan } from '../../../context/store/shared/reloadCurrentPlan'
import { formatCurrency } from '../../../utils/formatCurrency'
import { featuresGroups, planFeatureByKey, plans } from '../Plan'
import { parseUnlimited } from '../utils/parseUnlimited'
import { ContactUs } from './ContactUs'
import { ModalDowngradePlan } from './ModalDowngradePlan'

const CurrencyType = {
  GBP: '£',
  USD: '$',
}

export const ModalChangePlan = observer(() => {
  const {
    plans: plansData,
    profile: { currentOrgId },
    currentPlan,
  } = S.profile
  const { isLightTheme } = S.local
  const { subscription } = currentPlan || {}
  const [interval, setInterval] = useState<PlanInterval>(
    subscription?.interval ?? 'Monthly',
  )
  const [currency, setCurrency] = useState(subscription?.currency ?? 'USD')
  const [currentPlanCheckout, setCurrentPlanCheckout] = useState('')
  const [{ fetching }, upgradePlan] = useGqlUpgradePlan()

  const timeOption = useMemo(() => {
    if (subscription && subscription?.interval === 'Yearly') {
      return [
        {
          children: <span>Yearly</span>,
          switchValue: 'Yearly',
        },
      ]
    }
    return [
      {
        children: <span>Monthly</span>,
        switchValue: 'Monthly',
      },
      {
        children: <span>Yearly</span>,
        switchValue: 'Yearly',
      },
    ]
  }, [])

  const currencyTabs = useMemo(() => {
    if (subscription) {
      return []
    }
    return [
      {
        children: '$',
        switchValue: 'USD',
        className: css.CurrencyType,
      },
      {
        children: '£',
        switchValue: 'GBP',
        className: css.CurrencyType,
      },
    ]
  }, [])

  const renderValue = (value: string) => {
    if (value === 'yes') {
      return <Icon icon='icon_check' size={16} className={css.IconCheck} />
    }
    if (value === 'no') {
      return <Icon icon='Close1' size={16} className={css.IconClose} />
    }
    return value
  }

  const checkout = async (plan: PlanName) => {
    if (plan === 'Freemium') {
      ModalService.show(() => <ModalDowngradePlan />)
      return
    }
    setCurrentPlanCheckout(plan)

    const res = await upgradePlan({
      data: {
        currency,
        interval,
        orgId: currentOrgId,
        plan,
      },
    })

    if (res.data?.upgradePlan) {
      window.location.href = res.data?.upgradePlan
    } else {
      setCurrentPlanCheckout('')
      reloadCurrentPlan()
      ModalService.hide()
    }
  }

  const renderButtonSubscription = (plan: any) => {
    if (currentPlan?.plan === plan.id) {
      return (
        <Button containerType='grey' className={css.BtnCurrent}>
          Current Plan
        </Button>
      )
    }

    const currentPlanIndex = findIndex(plans, { id: currentPlan?.plan })
    const planIndex = findIndex(plans, { id: plan.id })

    return (
      <Button
        loading={fetching && currentPlanCheckout === plan.id}
        onClick={() => checkout(plan.id)}
      >
        {currentPlanIndex < planIndex ? 'Buy Professional' : 'Down to Basic'}
      </Button>
    )
  }

  const renderPlans = () => (
    <>
      {plansData.map((plan, i) => {
        const planLocal = {
          ...plan,
          ...plans.find(item => item.name === plan.name),
        }

        let price: any = null

        const getPrice = (
          typeCurrency: PlanCurrency,
          typeInterval: PlanInterval,
        ) => {
          const planFound = planLocal.prices.find(
            item =>
              item.interval === typeInterval && item.currency === typeCurrency,
          )
          if (planFound?.interval === 'Yearly') {
            return (planFound?.price ?? 0) / 12
          }
          return planFound?.price ?? 0
        }

        price = getPrice(currency, interval)

        return (
          <div
            key={i}
            className={clsx(css.Cell, css.bgLv2, {
              [css.Bdr]: i < plans.length - 1,
            })}
          >
            <div className={css.PlanInfo}>
              <div className={css.InfoText}>
                <div className={css.Name}>{plan.name}</div>
                <div className={css.Description}>{planLocal.description}</div>
                {planLocal.oldPrice && (
                  <div
                    className={clsx({
                      [css.OldPrice]: true,
                      [css.Opacity0]: interval === 'Monthly',
                    })}
                  >
                    {planLocal.oldPrice}
                  </div>
                )}
              </div>
              <div className={clsx(css.PriceAction, css.Center)}>
                {(price || price === 0) && (
                  <div className={css.Price}>
                    {typeof price === 'string'
                      ? price
                      : formatCurrency(price, CurrencyType[currency])}
                    {typeof price === 'number' && (
                      <span className={css.PriceMonth}>/mon</span>
                    )}
                  </div>
                )}
                {renderButtonSubscription(planLocal)}
              </div>
            </div>
          </div>
        )
      })}
      <div key={3} className={clsx(css.Cell, css.bgLv2)}>
        <div className={css.PlanInfo}>
          <div className={css.InfoText}>
            <div className={css.Name}>Enterprise</div>
            <div className={css.Description}>For Large Business</div>
          </div>
          <div className={clsx(css.PriceAction, css.Center)}>
            <Button onClick={() => ModalService.show(ContactUs)}>
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </>
  )

  const renderFeatures = useMemo(() => {
    const renderT = (dataKey: any, featureItem: any) => {
      const defaultValue = renderValue(dataKey.features[featureItem.key])
      const { freeMinutesPerMonth, freeStoragePerMonth, maxParticipants } =
        plansData.find(item => item.name === dataKey.name) || {}

      const isEnterprise = dataKey.name === 'Enterprise'

      switch (featureItem.key) {
        case 'minutes':
          return parseUnlimited(
            freeMinutesPerMonth,
            freeMinutesPerMonth + 'mins/mo',
            isEnterprise,
          )
        case 'storage':
          return parseUnlimited(
            freeStoragePerMonth,
            freeStoragePerMonth + 'GB',
            isEnterprise,
          )
        case 'participant':
          return parseUnlimited(maxParticipants, null, isEnterprise)
        default:
          return defaultValue
      }
    }

    return featuresGroups.map((featuresGroup, featuresGroupIndex) => (
      <Fragment key={featuresGroupIndex}>
        <div
          className={clsx(
            css.Row,
            css.Bdl,
            css.Bdr,
            css.Ph,
            css.Bdb,
            css.FeatureName,
          )}
        >
          {featuresGroup.name}
        </div>
        {featuresGroup.items.map((featureItem, featureItemIndex) => (
          <div
            className={clsx(
              css.Row,
              css.FeatureInfo,
              css.Bdl,
              css.Bdr,
              css.Bdb,
            )}
            key={featureItemIndex}
          >
            <div className={clsx(css.Cell, css.Bdr, css.Flex2)}>
              <div
                className={clsx(
                  css.Flex,
                  css.Flex1,
                  css.Ph,
                  css.VerticalCenter,
                  css.SpaceBetween,
                )}
              >
                {featureItem.name}
                {featureItem.description && (
                  <BeamTooltip placement='top' title={featureItem.description}>
                    <Icon
                      icon='Close1'
                      size={16}
                      color={isLightTheme ? '#A1A1A1' : '#E1E1E940'}
                    />
                  </BeamTooltip>
                )}
              </div>
            </div>

            {planFeatureByKey.map((plan, index) => (
              <div
                key={index}
                className={clsx(css.Cell, css.Center, {
                  [css.Bdr]: index < planFeatureByKey.length - 1,
                })}
              >
                {renderT(plan, featureItem)}
              </div>
            ))}
          </div>
        ))}
      </Fragment>
    ))
  }, [plansData])

  return (
    <ModalContent
      className={css.ChangePlanWrapper}
      containerClassName={css.ChangePlanContainer}
      isLightTheme={isLightTheme}
      useCloseDefault={false}
    >
      <div className={clsx(css.Title, css.modelContentTitle)}>
        Select your plan
        <span className={css.ButtonClose} onClick={() => ModalService.hide()}>
          <Icon icon='Close1' size={24} />
        </span>
      </div>

      <div className={css.ActionBox}>
        <AppTabs
          type='card'
          sizeLevel='M13'
          onChange={v => setInterval(v as PlanInterval)}
          activeKey={interval}
          className={css.AppTab}
        >
          {timeOption.map(i => (
            <AppTabPane key={i.switchValue} tab={i.children} />
          ))}
        </AppTabs>
        {currencyTabs.length ? (
          <>
            <div className={css.Divider}></div>
            <AppTabs
              type='card'
              sizeLevel='M13'
              onChange={v => setCurrency(v as PlanCurrency)}
              activeKey={currency}
              className={clsx(css.AppTab, css.Min)}
            >
              {currencyTabs.map(i => (
                <AppTabPane
                  key={i.switchValue}
                  tab={i.children}
                  className={css.PanelCurrency}
                />
              ))}
            </AppTabs>
          </>
        ) : (
          ''
        )}
      </div>
      <div className={css.TablePlanInfo}>
        <div
          className={clsx(
            css.Header,
            css.Bdl,
            css.Bdr,
            css.Bdt,
            css.Bdb,
            css.Row,
          )}
        >
          <div
            className={clsx(
              css.Cell,
              css.Bdr,
              css.Flex2,

              css.CellTitle,
              css.Center,
              css.bgLv2,
            )}
          >
            Compare Features
          </div>
          {renderPlans()}
        </div>
        {renderFeatures}
      </div>
    </ModalContent>
  )
})
