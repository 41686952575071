import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useMemo, useRef } from 'react'

import css from './LiveStreamCustomLayout.module.scss'

import { changeAlignIndicatorLines } from '../../../context/actions/layout/changeAlignIndicatorLines'
import { changeMeasureLines } from '../../../context/actions/layout/changeMeasureLines'
import { changeSlotEditing } from '../../../context/actions/layout/changeSlotEditing'
import { clearAlignIndicatorLines } from '../../../context/actions/layout/clearAlignIndicatorLines'
import { clearGroupSlot } from '../../../context/actions/layout/clearGroupSlot'
import { updateSlotToEdit } from '../../../context/actions/layout/updateSlotToEdit'
import { ResizeMultipleOverlay } from '../../../context/shared/components/CustomLayoutOverlay'
import { ResizeMultiple } from '../../../context/shared/components/CustomLayoutResizeMultiple'
import { ResizeOutsideApi } from '../../../context/shared/components/CustomLayoutReszieOutside'
import { getLinesMeasure } from '../../../context/shared/utils/CustomLayoutResizeUtils'
import { S } from '../../../context/store'
import type {
  IAlignmentIndicatorLine,
  ILayout,
  ILayoutItem,
  IMeasureLine,
} from '../../../context/store/shared/LayoutStore'
import { AlignmentLineType } from '../../../context/store/shared/LayoutStore'
import { useCheckClick } from '../../../utils/useCheckClick'
import { useCheckHover } from '../../../utils/useCheckHover'
import { SlotCustom } from './LiveStreamCustomSlot'
import { LiveStreamCustomTitle } from './LiveStreamCustomTitle'
import { calculateUpdateSlot } from './UploadTranscript/calculateSlot'

export const CustomLayout: FC<{
  layout: ILayout
  isEdit?: boolean
  isSmall?: boolean
  onLayoutClick?: () => void
  children?: React.ReactNode
}> = observer(({ layout, isEdit, onLayoutClick, isSmall, children }) => {
  const { selectedLayoutByIndex, typeDraggingItem, selectedIndexLayout } =
    S.webrtc
  const { isDragging, enableEditLayout, isResizing, enableEditTitle } = S.layout
  const layoutRef = useRef<HTMLDivElement | null>(null)
  const containerProps = {
    height: layout.height,
    width: layout.width,
  }
  const { mainLayoutWidth } = S.webrtc

  const layoutSelected =
    layout.id === selectedLayoutByIndex.defaultId + '' ? layout.slots : []

  const layoutIdRef = useRef(selectedLayoutByIndex.defaultId)
  const ls = layoutSelected.find(i => i.enableEdit)
  const lsRef = useRef<ILayoutItem>({
    height: 0,
    id: -1,
    left: 0,
    radius: 0,
    top: 0,
    width: 0,
    zIndex: 0,
  })
  useEffect(() => {
    if (ls) {
      lsRef.current = ls
    }
  }, [ls])

  const slotsOtherEditing = useMemo(
    () => layout.slots.filter(l => l.id !== ls?.id && !l.invisible),
    [layout, ls],
  )
  useCheckClick(
    [
      'SlotCustom',
      'customTitleColorPicker',
      'layoutToolbar',
      'customTitleSelectFont',
    ],
    isInSide => {
      if (!isInSide) {
        clearGroupSlot(layoutIdRef.current + '')
        changeSlotEditing(-1, Number(layoutIdRef.current))
      }
    },
  )
  const isHaveHoverSlot = useCheckHover('SlotCustom', [])
  const handleKeyUp = () => {
    handleMouseHover(null)
  }
  const handleKeyDown = (e: KeyboardEvent) => {
    if (ls && !isHaveHoverSlot && e.key === 'Alt') {
      handleMouseHover(ls)
    }
  }
  const calculateSize = () => {
    const { customRatio } = S.webrtc
    if (customRatio === 16 / 9) {
      return {
        width: 1920,
        height: 1080,
      }
    } else if (customRatio > 16 / 9) {
      return {
        width: 1920,
        height: 1920 / customRatio,
      }
    } else if (customRatio < 16 / 9) {
      return {
        width: 1080 * customRatio,
        height: 1080,
      }
    }
    return {
      width: 1920,
      height: 1080,
    }
  }

  useEffect(() => {
    ResizeOutsideApi.changeRatio({
      w: calculateSize().width / mainLayoutWidth,
      h: calculateSize().height / (mainLayoutWidth / S.webrtc.customRatio),
    })
  }, [mainLayoutWidth, S.webrtc.customRatio, calculateSize()])
  useEffect(() => {
    if (!isHaveHoverSlot && ls) {
      addEventListener('keydown', handleKeyDown)
      addEventListener('keyup', handleKeyUp)
    } else {
      removeEventListener('keydown', handleKeyDown)
      removeEventListener('keyup', handleKeyUp)
    }

    return () => {
      removeEventListener('keydown', handleKeyDown)
      removeEventListener('keyup', handleKeyUp)
    }
  }, [ls, isHaveHoverSlot])
  const handleBlurPage = () => {
    changeMeasureLines([])
  }
  useEffect(() => {
    addEventListener('blur', handleBlurPage)

    return () => {
      removeEventListener('blur', handleBlurPage)
    }
  }, [])
  useEffect(() => {
    if (isDragging) {
      handleBlurPage()
    }
  }, [isDragging])

  useEffect(() => {
    layoutIdRef.current = selectedLayoutByIndex.defaultId
  }, [selectedLayoutByIndex])
  const handleAddAlignIndicatorLines = () => {
    if (ls) {
      let lines = getAlignIndicatorLines(ls as any)
      const layoutWidth1 = layoutRef.current?.getBoundingClientRect().width ?? 0
      const layoutHeight =
        layoutRef.current?.getBoundingClientRect().height ?? 0
      if (
        Math.round(ls.height / 2 + ls.top) - Math.round(layoutHeight / 2) >=
          0 &&
        Math.round(ls.height / 2 + ls.top) - Math.round(layoutHeight / 2) <= 1.5
      ) {
        const linesVCenter: IAlignmentIndicatorLine[] = [
          {
            layoutMappingId: 1000,
            positions: [
              {
                top: layoutHeight / 2,
                left: 0,
              },
              { left: ls.left, top: layoutHeight / 2 },
            ],
            type: AlignmentLineType.CENTER_AH,
          },
          {
            layoutMappingId: 1002,
            positions: [
              {
                top: layoutHeight / 2,
                left: ls.left + ls.width,
              },
              { left: layoutWidth1, top: layoutHeight / 2 },
            ],
            type: AlignmentLineType.CENTER_AH,
          },
        ]
        lines = [...lines, ...linesVCenter]
      }
      if (
        Math.round(ls.width / 2 + ls.left) - Math.round(layoutWidth1 / 2) >=
          0 &&
        Math.round(ls.width / 2 + ls.left) - Math.round(layoutWidth1 / 2) <= 0.5
      ) {
        const linesHCenter = [
          {
            layoutMappingId: 1003,
            positions: [
              {
                top: ls.top + ls.height,
                left: layoutWidth1 / 2,
              },
              { left: layoutWidth1 / 2, top: layoutHeight },
            ],
            type: AlignmentLineType.CENTER_AV,
          },
          {
            layoutMappingId: 1001,
            positions: [
              {
                top: 0,
                left: layoutWidth1 / 2,
              },
              { left: layoutWidth1 / 2, top: ls.top },
            ],
            type: AlignmentLineType.CENTER_AV,
          },
        ]
        lines = [...lines, ...linesHCenter]
      }

      changeAlignIndicatorLines({ [selectedLayoutByIndex.defaultId]: lines })
    }
  }
  const getAlignIndicatorLines = (slotToGet: ILayoutItem) => {
    const sTop = slotToGet?.top ?? 0
    const sLeft = slotToGet?.left ?? 0
    const sWidth = slotToGet?.width ?? 0
    const sHeight = slotToGet?.height ?? 0
    const lines: IAlignmentIndicatorLine[] = []
    slotsOtherEditing.forEach(i => {
      const top = i.top
      const left = i.left
      const width = i.width
      const height = i.height
      let newLineTop: IAlignmentIndicatorLine | null = null
      let newLineLeft: IAlignmentIndicatorLine | null = null
      let newLineBottom: IAlignmentIndicatorLine | null = null
      let newLineRight: IAlignmentIndicatorLine | null = null
      let newLineCenterH: IAlignmentIndicatorLine | null = null
      let newLineCenterV: IAlignmentIndicatorLine | null = null

      if (Math.round(top) === Math.round(sTop)) {
        newLineTop = {
          type: AlignmentLineType.TOP,
          layoutMappingId: i.id,
          positions: [
            { top, left },
            { top, left: left + width },
            { top: sTop, left: sLeft },
            { top: sTop, left: sLeft + sWidth },
          ],
        }

        newLineTop.positions.sort((a, b) => a.left - b.left)
      }
      if (Math.round(left) === Math.round(sLeft)) {
        newLineLeft = {
          type: AlignmentLineType.LEFT,
          layoutMappingId: i.id,
          positions: [
            { top, left },
            { top: top + height, left },
            { top: sTop, left: sLeft },
            { top: sTop + sHeight, left: sLeft },
          ],
        }
        newLineLeft.positions.sort((a, b) => a.top - b.top)
      }
      if (Math.round(top + height) === Math.round(sTop + sHeight)) {
        const nTop = top + height
        const nsTop = sTop + sHeight
        newLineBottom = {
          type: AlignmentLineType.BOTTOM,
          layoutMappingId: i.id,
          positions: [
            { top: nTop, left },
            { top: nTop, left: left + width },
            { top: nsTop, left: sLeft },
            { top: nsTop, left: sLeft + sWidth },
          ],
        }
        newLineBottom.positions.sort((a, b) => a.left - b.left)
      }
      if (
        Math.round(left + width) - Math.round(sLeft + sWidth) >= 0 &&
        Math.round(left + width) - Math.round(sLeft + sWidth) <= 0.5
      ) {
        const nLeft = left + width
        const nsLeft = sLeft + sWidth
        newLineRight = {
          type: AlignmentLineType.RIGHT,
          layoutMappingId: i.id,
          positions: [
            { top, left: nLeft },
            { top: top + height, left: nLeft },
            { top: sTop, left: nsLeft },
            { top: sTop + sHeight, left: nsLeft },
          ],
        }
        newLineRight.positions.sort((a, b) => a.top - b.top)
      }
      if (Math.round(left + width / 2) === Math.round(sLeft + sWidth / 2)) {
        const cLeft = left + width / 2
        const sCLeft = sLeft + sWidth / 2
        newLineCenterH = {
          type: AlignmentLineType.CENTER_H,
          layoutMappingId: i.id,
          positions: [
            { top, left: cLeft },
            { top: top + height, left: cLeft },
            { top: sTop + sHeight / 2, left: sCLeft },
          ],
        }
        newLineCenterH.positions.sort((a, b) => a.top - b.top)
      }
      if (Math.round(top + height / 2) === Math.round(sTop + sHeight / 2)) {
        const cTop = top + height / 2
        const sCTop = sTop + sHeight / 2
        newLineCenterV = {
          type: AlignmentLineType.CENTER_V,
          layoutMappingId: i.id,
          positions: [
            { top: cTop, left },
            { top: cTop, left: left + width },
            { top: sCTop, left: sLeft + sWidth / 2 },
          ],
        }
        newLineCenterV.positions.sort((a, b) => a.left - b.left)
      }
      if (newLineTop) {
        lines.push(newLineTop)
      }
      if (newLineLeft) {
        lines.push(newLineLeft)
      }
      if (newLineBottom) {
        lines.push(newLineBottom)
      }
      if (newLineRight) {
        lines.push(newLineRight)
      }
      if (newLineCenterH) {
        lines.push(newLineCenterH)
      }
      if (newLineCenterV) {
        lines.push(newLineCenterV)
      }
    })

    return lines
  }
  useEffect(() => {
    if (!isDragging && !isResizing) {
      clearAlignIndicatorLines()
    }
  }, [isDragging, isResizing])
  useEffect(() => {
    if (
      enableEditLayout &&
      selectedLayoutByIndex.defaultId + '' === layout.id
    ) {
      handleAddAlignIndicatorLines()
    }
  }, [layout, enableEditLayout, selectedLayoutByIndex.defaultId, layout.id, ls])
  const handleMouseHover = (layout1: ILayoutItem | null) => {
    if (layout1) {
      const lW = layoutRef.current?.getBoundingClientRect().width ?? 0
      const lH = layoutRef.current?.getBoundingClientRect().height ?? 0
      const lT = layoutRef.current?.getBoundingClientRect().top ?? 0
      const lL = layoutRef.current?.getBoundingClientRect().left ?? 0
      const lines: IMeasureLine[] = getLinesMeasure(
        layout1,
        { top: lT, left: lL, width: lW, height: lH },
        ls ?? {
          height: 0,
          id: -1,
          left: 0,
          radius: 0,
          top: 0,
          width: 0,
          zIndex: 0,
        },
      )
      changeMeasureLines(lines)
    } else {
      changeMeasureLines([])
    }
  }
  const handleChangeSize = (w: number, h: number) => {
    const maxLeft = containerProps.width
    const maxTop = containerProps.height
    let left = lsRef.current.left
    const right = lsRef.current.left + w
    let top = lsRef.current.top
    const bottom = lsRef.current.top + h
    if (right > maxLeft) {
      left = left - (right - maxLeft)
    }

    if (bottom > maxTop) {
      top = top - (bottom - maxTop)
    }

    updateSlotToEdit(
      lsRef.current.id,
      {
        ...lsRef.current,
        ...calculateUpdateSlot({
          height: h,
          width: w,
          top,
          left,
        }),
        enableEdit: true,
      },
      Number(layout.id),
    )
  }
  useEffect(() => {
    if (!enableEditLayout) {
      ResizeOutsideApi.clearItems(selectedIndexLayout)
    }
  }, [ls, enableEditLayout])

  return (
    <div
      id='customLayout'
      onClick={e => {
        if (onLayoutClick) {
          e.stopPropagation()
          e.preventDefault()
          onLayoutClick()
        }
      }}
      style={{
        width: layout.width,
        height: layout.height,
      }}
      ref={r => {
        layoutRef.current = r
      }}
      className={clsx(css.Wrapper, layout.className)}
    >
      {!isSmall &&
        isEdit &&
        !isDragging &&
        !typeDraggingItem &&
        !enableEditTitle && (
          <ResizeMultipleOverlay
            containerProps={{
              height: layout.height,
              width: layout.width,
              x: layoutRef.current?.getBoundingClientRect().x ?? 0,
              y: layoutRef.current?.getBoundingClientRect().y ?? 0,
            }}
            layoutId={layout.id}
            slots={layout.slots}
          />
        )}

      {layout.slots.filter(l => l.inGroup).length > 1 && (
        <ResizeMultiple layout={layout} containerProps={containerProps} />
      )}

      {layout.slots.map((sl, i) =>
        sl.titleId || sl.type === 'title' || sl.type === 'subTitle' ? (
          <LiveStreamCustomTitle
            containerProps={containerProps}
            layout={sl}
            key={sl.key}
            isEdit={isEdit}
            onChangeSize={handleChangeSize}
            slotsOtherEditing={slotsOtherEditing}
            parentId={Number(layout.id)}
            handleMouseHover={isSmall ? undefined : handleMouseHover}
            isBelongEditing={true}
          />
        ) : (
          <SlotCustom
            containerProps={containerProps}
            isSmall={isSmall}
            key={sl.key}
            handleMouseHover={isSmall ? undefined : handleMouseHover}
            layout={sl}
            index={1000 + i}
            onChangeSize={handleChangeSize}
            isBelongEditing={true}
            isEdit={isEdit}
            slotsOtherEditing={slotsOtherEditing}
            parentId={Number(layout.id)}
            peerId={sl.peerId}
          />
        ),
      )}
      {children}
    </div>
  )
})
