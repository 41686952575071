import saveAs from 'file-saver'
import * as quillToWord from 'quill-to-word'

import { showError } from './showError'

export const downloadNote = async (note: string, fileName: string) => {
  try {
    if (note) {
      const deltas = JSON.parse(note)
      const quillToWordConfig: quillToWord.Config = {
        exportAs: 'blob',
      }

      if (deltas) {
        const docAsBlob = await quillToWord.generateWord(
          { ops: deltas } as any,
          quillToWordConfig,
        )
        saveAs(docAsBlob as any, fileName)
      }
    }
  } catch (err) {
    showError('Failed to download file', err as Error)
    console.error(err)
  }
}
