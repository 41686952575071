import { reduxStore } from '../../../context/redux'

export const searchUserInSection = async (
  sessionId: string,
  role?: string,
  userId?: string,
) => {
  const res = await reduxStore.context.gql.searchUserInSession(
    {
      sessionId,
      filter: {
        role,
        userId,
      },
    },
    { requestPolicy: 'network-only' },
  )
  return res
}
