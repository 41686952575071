import type { CreateMute } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { IMuteItem } from '../../../context/store/insight-engine/InsightEngineStore'
import { InsightEngineEditType } from '../../../context/store/insight-engine/InsightEngineStore'
import { selectBlurBlock } from './selectBlurBlock'

export const createMute = async (data: CreateMute) => {
  const res = await reduxStore.context.gql.createMute({ data })
  if (res.data?.createMute.id) {
    const $ = S.insightEngine
    const newMuteTimeline: IMuteItem = {
      id: res.data.createMute.id,
      startTime: data?.startTime ?? 0,
      endTime: data?.endTime ?? 0,
      type: InsightEngineEditType.Mute,
    }
    $.muteTimelines = [...$.muteTimelines, newMuteTimeline]
    selectBlurBlock(newMuteTimeline.id)
  }
  if (res.error) {
    throw new Error(res.error.message)
  }
}
