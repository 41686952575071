import { useId, useRef, useState } from 'react'
import { ulid } from 'ulidx'

// eslint-disable-next-line css-modules/no-unused-class
import css from './style.module.scss'

import { separateLogic } from '../../../../../../#/reactjs/redux'
import { Form } from '../../../../components/base/Form'
import { BeamText } from '../../../../components/base/Text'
import { ToastService } from '../../../../components/widget/Toast'
import { S } from '../../../../context/store'
import type { TMediaItem } from '../../../../context/store/studio/WebrtcStore'
import { showError } from '../../../../utils/showError'
import { useUploadFile } from '../../../../utils/useUploadFile'
import { addRecordMeeting } from '../../../InsightEngine/actions/addRecordMeeting'
import { getResourcesInsightEngine } from '../../../InsightEngine/actions/getResourcesInsightEngine'
import { insightEngineUploadFiles } from '../../../InsightEngine/actions/uploadFile'
import type { TUploadFile } from '../RightContent'

export const createComponent = separateLogic(() => {
  const { sessionMode, detail } = S.webrtc
  const { recordBotName } = S.insightEngine
  const [form] = Form.useForm<{ meetingUrl: string }>()
  const uploadFile = useRef<null | HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const inputId = useId()
  const handleUploadFile = async (file: TUploadFile) => {
    if (!detail) {
      return
    }
    try {
      const formData = new FormData()
      formData.append('file', file.file)
      const result = await insightEngineUploadFiles(
        formData,
        detail.insightEngineId,
        {
          onUploadProgress: (progress: ProgressEvent) =>
            countProgress(progress, file.id),
        },
      )
      if (result) {
        return {
          id: result.id || ulid(),
          value:
            typeof result?.resource?.url === 'string'
              ? result?.resource?.url
              : '',
          mediaType: 'image',
          name: file.file.name,
          size: result?.resource?.fileSize || 0,
          position: Number(result?.position || 1000),
          resourceId: result.resourceId,
        } as TMediaItem
      }
      return null
    } catch (err: any) {
      setStatusUpload(false)
      showError('Failed to upload file', err as Error)
      return
    }
  }
  const ToastContent = (
    <div className={css.Toast}>
      <BeamText className={css.mainDescription}>
        Please admit {recordBotName} when they join your meeting
      </BeamText>
      <BeamText className={css.subDescription}>
        We will add the record of your meeting after its end
      </BeamText>
    </div>
  )
  const addHandler = async () => {
    if (!detail?.insightEngineId) {
      return
    }
    try {
      setLoading(true)
      const formRes = await form.validateFields()
      await addRecordMeeting(detail?.insightEngineId, formRes.meetingUrl)
      ToastService.success({
        content: ToastContent,
      })
      form.setFieldValue('meetingUrl', '')
    } catch (e) {
      ToastService.error({
        content: 'Failed to record meeting',
      })
    }
    setLoading(false)
  }
  const handleUploadSuccess = (filesUploaded: TMediaItem[] = []) => {
    if (!detail?.insightEngineId) {
      return
    }
    ToastService.success({
      content: `File${filesUploaded.length > 1 ? 's' : ''} uploaded. `,
    })

    getResourcesInsightEngine({ insightEngineId: detail?.insightEngineId })
  }

  const {
    statusUpload,
    countProgress,
    setStatusUpload,
    onOpenUpload,
    percentProgress,
    handleCheckUploadStorage,
  } = useUploadFile({
    onHandleUploadFile: handleUploadFile,
    onUploadSuccess: handleUploadSuccess,
    ref: uploadFile,
  })
  const selectFile = () => {
    onOpenUpload()
  }
  return {
    sessionMode,
    selectFile,
    uploadFile,
    statusUpload,
    percentProgress,
    handleCheckUploadStorage,
    inputId,
    recordBotName,
    form,
    addHandler,
    loading,
  }
})
