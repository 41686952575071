import type { Socket } from 'socket.io-client'

import type { Message } from '../../../pages/InsightEngine/type'
import type {
  InsightEnginePlanQuery,
  SearchChatbotContextQuery,
  SearchChatbotMsgQuery,
  SearchResourceInInsightEngineQuery,
  SearchTranscriptQuery,
} from '../../gql/codegen'
import type { ILayoutItem } from '../shared/LayoutStore'

export type Resource =
  (SearchResourceInInsightEngineQuery['searchResourceInInsightEngine'] extends Array<
    infer T
  >
    ? T
    : never) & { isReUploading?: boolean }

export type MessageLocal = SearchChatbotMsgQuery['searchChatbotMsg'][0] & {
  isSocket?: boolean
}

export type Transcript = SearchTranscriptQuery['searchTranscript'][0]

export enum TranscriptStatus {
  UnTranscript = 'UnTranscript',
  InProcess = 'InpProcess',
  Failed = 'Failed',
  Complete = 'Complete',
}

export type MediaPlayer = {
  currentTime?: number
  duration?: number
  isPlaying?: boolean
  mediaLoading?: boolean
  isMuted?: boolean
  videoRef?: HTMLVideoElement | null
}

export type ParamsTranscript = {
  loadingTranscript?: boolean
  moreTranscript?: boolean
  offset: number
  limit?: number
}

export type DetailInsightEngine = {
  name: string
}

export type ChatbotMsg = {
  chatbotMessages?: MessageLocal[]
  isAnswering?: boolean
}
export enum InsightEngineMode {
  Cursor = 'Cursor',
  Mark = 'Mark',
  Trim = 'Trim',
  Edit = 'Edit',
}

export enum InsightEngineEditType {
  Blur = 'Blur',
  Mute = 'Mute',
}

export type VideoSection = {
  top: number
  left: number
  width: number
  height: number
}
export interface IBlurItem extends Omit<ILayoutItem, 'id'> {
  id: string
  startTime?: number
  endTime?: number
}

export interface IMuteItem {
  id: string
  startTime?: number
  endTime?: number
  isMute?: boolean
  type?: string
}

export interface IMuteTimeline {
  startTime: number
  endTime: number
  __typename?: string
}

export type VideoNaturalSize = {
  width: number
  height: number
}

export type NewBlurBlockPosition = {
  top: number
  left: number
}

export class InsightEngineStore {
  detailInsightEngine: DetailInsightEngine = {
    name: 'init name',
  }
  videoSection: VideoSection = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  }
  videoNaturalSize: VideoNaturalSize = {
    width: 0,
    height: 0,
  }
  blurBlocks: IBlurItem[] = []
  muteTimelines: IMuteItem[] = []
  newBlurBlockPosition: NewBlurBlockPosition = {
    top: 0,
    left: 0,
  }

  selectedBlockId: string
  searchChatbotMsg: ChatbotMsg = {
    isAnswering: false,
  }
  paramsTranscript: ParamsTranscript = {
    offset: 0,
    limit: 100,
  }
  transcriptCount: number = 0
  resources: Resource[] = []
  loading: boolean
  mediaLoading: boolean
  resourceSelected: Resource | undefined
  transcripts: Transcript[]

  plan?: InsightEnginePlanQuery['insightEnginePlan'] = undefined
  mediaPlayerOnCursor: MediaPlayer = {
    isPlaying: false,
    mediaLoading: false,
    isMuted: false,
    currentTime: 0,
  }
  mediaPlayerOnBlur: MediaPlayer = {
    isPlaying: false,
    mediaLoading: false,
    isMuted: false,
    currentTime: 0,
  }
  recallBotSocketInstance: Socket | null
  chatbotContexts: SearchChatbotContextQuery['searchChatbotContext'] = []
  recordBotName: string
  mode: InsightEngineMode = InsightEngineMode.Cursor

  setMediaPlayer = (d: Partial<MediaPlayer>, t?: InsightEngineMode) => {
    if (t === InsightEngineMode.Edit) {
      this.mediaPlayerOnBlur = Object.assign(this.mediaPlayerOnBlur, d)
      return
    }
    this.mediaPlayerOnCursor = Object.assign(this.mediaPlayerOnCursor, d)
  }
  setAllMediaPlayer = (d: Partial<MediaPlayer>) => {
    this.mediaPlayerOnBlur = Object.assign(this.mediaPlayerOnBlur, d)
    this.mediaPlayerOnCursor = Object.assign(this.mediaPlayerOnCursor, d)
  }
  get mediaPlayer() {
    return this.mode === InsightEngineMode.Edit
      ? { ...this.mediaPlayerOnBlur }
      : { ...this.mediaPlayerOnCursor }
  }

  get isBlurMode() {
    return this.mode === InsightEngineMode.Edit
  }

  selectResource = (r: Resource) => {
    this.resourceSelected = r
  }

  updateMessagesChatbot = (message: MessageLocal) => {
    this.searchChatbotMsg.chatbotMessages?.push(message)
  }

  editMessageChatbox = (message: Message) => {
    this.searchChatbotMsg.chatbotMessages =
      this.searchChatbotMsg.chatbotMessages?.map(item => {
        if (item.id === message.id) {
          return { ...item, status: message.status }
        }
        return item
      }) ?? []
  }
  setMode = (mode: InsightEngineMode) => {
    this.mode = mode
  }

  updateMediaInsigtEngine = (id: string) => {
    this.resources = this.resources.filter(r => r.id !== id)
  }

  reset = () => {
    this.mediaPlayerOnBlur = {
      isPlaying: false,
      mediaLoading: false,
      isMuted: false,
      currentTime: 0,
    }
    this.mediaPlayerOnCursor = {
      isPlaying: false,
      mediaLoading: false,
      isMuted: false,
      currentTime: 0,
    }
    this.transcriptCount = 0
    this.paramsTranscript = {
      offset: 0,
      limit: 100,
    }
    this.resourceSelected = undefined
    this.resources = []
    this.transcripts = []
    this.plan = undefined
    this.mode = InsightEngineMode.Cursor
    this.blurBlocks = []
  }
}
