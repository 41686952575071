import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useState } from 'react'

import css from './MediaRecords.module.scss'

import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_MEDIA_RECORD } from '../../../context/store/studio/dragTypes'
import { getListPosOfIdValue } from '../../../context/store/studio/utils'
import type { TMediaItem } from '../../../context/store/studio/WebrtcStore'
import { Video, VideoPreview } from './MediaVideos'
import { VideoPlayerControlOffline } from './VideoPlayerControlOffline'
import { VideoPlayerControlOnline } from './VideoPlayerControlOnline'

type TRecords = {
  records: ReadonlyArray<TMediaItem>
  onOpenRecord: (e: MouseEvent, item: TMediaItem) => void
  idVideoPlaying: string
  onUpdateSetting?: (type?: string) => void
}

export const MediaRecords = observer(
  ({ onUpdateSetting }: { onUpdateSetting: (type?: string) => void }) => {
    const {
      mediaStudio,
      toggleMediaSelectionId,
      updateDataOfStore,
      addInitStateVideoPreview,
      stateVideoPreviewId,
      updateVideoSessionList,
      videoSessionList,
    } = S.webrtc
    const [videoPlaying, setPlaying] = useState('')
    const onOpenRecord = (e: MouseEvent, item: TMediaItem) => {
      if (e.shiftKey) {
        toggleMediaSelectionId(item.id)
        return
      }
      const videoSelected = videoSessionList.find(v => v.mediaId === item.id)
      updateVideoSessionList({
        mediaId: item.id,
        name: item.name,
        repeat: item.isRepeat,
        paused: true,
        isSelected: true,
        currentTime: videoSelected?.currentTime ?? 0,
        duration: item.duration,
        url: item.url,
        volume: videoSelected?.volume ?? 1,
      })
      setPlaying(videoPlaying === item.id ? '' : item.id)
      addInitStateVideoPreview(item.id)
      updateDataOfStore({
        mediaSelectionId: [],
        stateVideoPreviewId: stateVideoPreviewId !== item.id ? item.id : '',
        newStateVideoPreviewId: item.id,
      })
    }
    if (mediaStudio.record.length === 0) {
      return <p className={css.MediaListNotFound}>There is no record yet</p>
    }
    return (
      <Records
        records={mediaStudio.record}
        onOpenRecord={onOpenRecord}
        idVideoPlaying={stateVideoPreviewId}
        onUpdateSetting={onUpdateSetting}
      />
    )
  },
)

const Records = observer(
  ({ records, onOpenRecord, idVideoPlaying, onUpdateSetting }: TRecords) => {
    const {
      deleteFileMedia,
      removeMediasOfLayoutSlot,
      mediaSelectionId,
      updateOrderMedia,
      backgroundVideoUrl,
      updateAndEmit,
      updateDataOfStore,
      selectedIndexLayout,
      typeDraggingItem,
      isOnAirItemOfLayout,
      layoutMedias,
      backgroundMediaData,
      newStateVideoPreviewId,
      videoPreview,
    } = S.webrtc
    const onDelete = async (item: TMediaItem) => {
      try {
        let isUpdateSettings = false
        deleteFileMedia(item.id, 'record')
        if (backgroundVideoUrl === item.value) {
          isUpdateSettings = true
          updateAndEmit({
            backgroundVideoUrl: '',
          })
        }
        const posList = getListPosOfIdValue(item.id, layoutMedias)
        if (posList.length > 0) {
          isUpdateSettings = true
          removeMediasOfLayoutSlot([item.id])
        }
        if (isUpdateSettings) {
          onUpdateSetting?.()
        }
        await reduxStore.context.gql.deleteResourceInSession({ ids: [item.id] })
      } catch (err) {
        ToastService.error({ content: 'Failed to delete record' })
        console.error(err)
      }
    }
    const onSortEnd = async (dropIndex: number, overIndex: number) => {
      if (mediaSelectionId.length <= 1) {
        updateOrderMedia('record', dropIndex, overIndex)
        await callApiUpdateResourceReOrder(
          S.webrtc.mediaStudio.record,
          dropIndex,
          overIndex,
        )
      }
    }
    const onDragEnd = () => {
      if (mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }
    const onTurnOffAir = (item: TMediaItem) => {
      removeMediasOfLayoutSlot([item.id], selectedIndexLayout)
      if (backgroundVideoUrl === item.value) {
        updateAndEmit({
          backgroundVideoUrl: '',
        })
      }
      onUpdateSetting?.()
    }

    const onAir =
      isOnAirItemOfLayout(layoutMedias, newStateVideoPreviewId) ||
      backgroundVideoUrl === videoPreview?.url ||
      backgroundMediaData.id === newStateVideoPreviewId

    return (
      <>
        {S.webrtc.newStateVideoPreviewId &&
          (onAir ? (
            <VideoPlayerControlOnline type={DRAG_MEDIA_RECORD} />
          ) : (
            <VideoPlayerControlOffline
              type={DRAG_MEDIA_RECORD}
              key={S.webrtc.newStateVideoPreviewId}
            />
          ))}
        {records.map((item, index) => (
          <Video
            type={DRAG_MEDIA_RECORD}
            media={item}
            onDelete={onDelete}
            key={index}
            onSelectMedia={(e: MouseEvent) => onOpenRecord(e, item)}
            index={index.toString()}
            onSortEnd={onSortEnd}
            onDragEnd={onDragEnd}
            dragSelected={mediaSelectionId.includes(item.id)}
            isDraggingSelect={mediaSelectionId.length > 1}
            idVideoPlaying={idVideoPlaying}
            draggingSelected={
              mediaSelectionId.includes(item.id) &&
              typeDraggingItem === DRAG_MEDIA_RECORD
            }
            onAir={
              isOnAirItemOfLayout(layoutMedias, item.id) ||
              (backgroundVideoUrl &&
              item.value &&
              item.value === backgroundVideoUrl
                ? true
                : false)
            }
            onTurnOffAir={() => onTurnOffAir(item)}
          />
        ))}

        {typeDraggingItem === DRAG_MEDIA_RECORD && (
          <VideoPreview totalItem={mediaSelectionId.length} />
        )}
      </>
    )
  },
)
