import hark from 'hark'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import css from './PlayerVolumeBar.module.scss'

import { volume0 } from '#rn-shared/mediasoup'

import { S } from '../../../context/store'

export const VolumeBar: FC<TVolumeBarProps> = observer(({ stream, volume }) => {
  const [level, setLevel] = useState(0)
  const { isLightTheme } = S.local
  useEffect(() => {
    const h = hark(stream, { play: false })
    h.on('volume_change', db => {
      const v = Math.pow(10, db / 85) * 10
      setLevel(v > 1 ? Math.round(v) : 0)
    })
    return () => h.stop()
  }, [stream])
  const grayColor = isLightTheme
    ? 'rgba(0, 0, 0, 0.12)'
    : 'rgba(255, 255, 255, 0.36)'
  const muted = volume <= volume0
  const primaryColor = isLightTheme ? '#2656C9' : '#4098FF'
  const bg = muted ? grayColor : primaryColor
  return (
    <div className={css.Volume}>
      <div
        className={css.Bar}
        style={{
          width: `${level * 10}%`,
          backgroundColor: bg,
        }}
      />
    </div>
  )
})

export type TVolumeBarProps = {
  stream: MediaStream
  volume: number
}
