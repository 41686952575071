import type { SearchTranscriptQueryVariables } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const loadmoreTranscript = async (
  params: SearchTranscriptQueryVariables,
) => {
  const $ = S.insightEngine
  const paramsTranscript = $.paramsTranscript
  if (paramsTranscript.moreTranscript) {
    return
  }
  paramsTranscript.offset += paramsTranscript?.limit ?? 100
  paramsTranscript.moreTranscript = true

  const { data } = await reduxStore.context.gql.searchTranscript(
    {
      ...params,
      page: {
        offset: paramsTranscript.offset,
        limit: paramsTranscript.limit,
      },
    },
    {
      requestPolicy: 'network-only',
    },
  )
  $.transcripts = [...$.transcripts, ...(data?.searchTranscript ?? [])]
  paramsTranscript.moreTranscript = false
}
