import type {
  SearchAmsQuery,
  SearchAmsResourceQuery,
  SearchResourceInAmsQuery,
} from '../../../context/gql/codegen'

export type TResourceInAms = SearchResourceInAmsQuery['searchResourceInAms'][0]
export type TMediaServer = SearchAmsQuery['searchAms'][0]
export type TResourcesInMediaServer = {
  [key: string]: TResourceInAms[]
}
export type TResource = SearchAmsResourceQuery['searchAmsResource'][0]
export enum EMediaKey {
  VIDEO = 'video',
}
export class AmsStore {
  mediaServers: TMediaServer[] = []
  isStartLive: boolean
  resourcesInAms: TResourcesInMediaServer = {}
  connectsBtnClicked: { [key: string]: boolean } = {}
  mediaList: {
    video: TResource[]
  } = {
    video: [],
  }

  isLocalMuted = false
}
