import { S } from '../../../context/store'
import { buildScreenshareMediaId } from '../../../context/store/studio/WebrtcStore'

export const handleUpdatePeerLayout = () => {
  const $ = S.webrtc
  for (const i in $.layoutPeers) {
    if ($.layoutPeers[i]) {
      for (const j in $.layoutPeers[i]) {
        if (
          !$.peers.find(
            p =>
              p.id === $.layoutPeers[i][j] ||
              buildScreenshareMediaId(p.id) === $.layoutPeers[i][j],
          )
        ) {
          delete $.layoutPeers[i][j]
        }
      }
    }
  }
}
