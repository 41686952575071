import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './MainComponent.module.scss'

import { S } from '../../../context/store'
import { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import { InsightEngineNote } from './InsightEngineNote'
import { InsightMedia } from './InsightMedia'
// import { SidebarRight } from './SidebarRight'
import { Transcript } from './Transcript'
import { VideoSection } from './VideoSection'

export const MainComponent = () => (
  <div className={clsx(css.Container)}>
    <TranscriptComponent />
    <div className={css.HorizontalDivider} />
    <div className={css.Note}>
      <InsightEngineNote />
    </div>
    {/* <SidebarRight /> */}
  </div>
)
export const TranscriptComponent = observer(() => {
  const { mode } = S.insightEngine
  return (
    <div className={css.Transcript}>
      <div className={css.MediaSelected}>
        <VideoSection
          type={InsightEngineMode.Cursor}
          key={InsightEngineMode.Cursor}
          playing={mode === InsightEngineMode.Cursor}
          muted={mode !== InsightEngineMode.Cursor}
        />
      </div>
      <div className={css.Divider} />
      <Transcript />
      <div className={css.Divider} />
      <InsightMedia />
    </div>
  )
})
