import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { cloneDeep, debounce, get, has, isEqual } from 'lodash'
import type { RtpCodecCapability } from 'mediasoup-client/lib/types'
import { action, runInAction } from 'mobx'
import type { MouseEvent } from 'react'
import { Platform } from 'react-native'
import { ulid } from 'ulidx'
import URLParse from 'url-parse'

import { arrToMap } from '##/shared/arrToMap'
import { volume0 } from '#rn-shared/mediasoup'
import { _addProtooHandlers } from '#rn-shared/mediasoup/lib/_addProtooHandlers'
import { _createProtoo } from '#rn-shared/mediasoup/lib/_createProtoo'
import { _disableScreenshare } from '#rn-shared/mediasoup/lib/_disableScreenshare'
import { _enableScreenshare } from '#rn-shared/mediasoup/lib/_enableScreenshare'
import { _onProtooOpen } from '#rn-shared/mediasoup/lib/_onProtooOpen'
import { _onRequestNewConsumer } from '#rn-shared/mediasoup/lib/_onRequestNewConsumer'
import { _requestJoin } from '#rn-shared/mediasoup/lib/_requestJoin'
import type {
  AmsClientAction,
  AmsClientData,
  AmsClientState,
  AmsClientVideo,
  AmsClientVideoStateWithPosition,
  MediaControl,
  MediaControlClientAction,
  PeerDataUpdate,
  ProtooClientNotifyHandler,
  ProtooClientNotifyHandlers,
  ServerToClientNotifyData,
  UpdateRemotePeerCommand,
  Viewmode,
} from '#rn-shared/mediasoup/type'
import type {
  Mediasoup,
  MediasoupState,
  MediaVolume,
  PeerUI,
} from '#rn-shared/mediasoup/type-ui'

import type { Input } from '../../../../../server-api/schema/session/input/Input.model'
import { ToastService } from '../../../components/widget/Toast'
import { reactServerOrigin } from '../../../config'
import { OutputInSessionStatus } from '../../../pages/Studio/components/DestinationAction/type'
import type { TOutputs } from '../../../pages/Studio/components/ModalDestination/type'
import { _getProtooUrl } from '../../actions/studio/_getProtooUrl'
import { getTrack } from '../../actions/studio/getTrack'
import type { TFLite } from '../../actions/studio/loadTFLiteModel'
import { loadTFLiteModel } from '../../actions/studio/loadTFLiteModel'
import type {
  OnStudioEnterQuery,
  Resource,
  SearchNoteQuery,
  SearchOutputInSessionQuery,
  SearchWaitingUserQuery,
  SessionPlanQuery,
  SessionRoleName,
} from '../../gql/codegen'
import { reduxStore } from '../../redux'
import type { RootStore, Sync } from '..'
import type { ILayoutItem } from '../shared/LayoutStore'
import { serverTime } from '../shared/serverTime'
import { TemplateDefault } from '../theme/default'
import { compareColors } from './color'
import {
  DRAG_CHAT,
  DRAG_GRAPHIC_COLOR,
  DRAG_GRAPHIC_LOGO,
  DRAG_GRAPHIC_OVERLAY,
  DRAG_MEDIA_AUDIO,
  DRAG_MEDIA_IMAGE,
  DRAG_MEDIA_RECORD,
  DRAG_MEDIA_VIDEO,
  DRAG_USER_MAIN,
  DRAG_USER_PARTICIPANT,
  DRAG_USER_SMALL,
} from './dragTypes'
import { getIdNumber, Helper } from './helper'
import {
  layoutNew1,
  layoutNew2,
  layoutNew3,
  layoutNew4,
  layoutNew8,
  layoutNewAll,
} from './layoutNew'
import { SHORT_CUTS_STREAM } from './shortcuts'
import {
  getOrderListMedia,
  removeAndGetNewLayout,
  renderPipeline,
  reOrderList,
  sortResourceOfSession,
  updateValuesForLayout,
} from './utils'
import {
  fullscreen,
  onKickout,
  showError,
  showInputReadyToast,
  showNotiAnswerSwitchRole,
  showNotiOnAddChat,
  showNotiSwitchRole,
  showRecordSuccessToast,
} from './utils2'

export type ExcludeNullish<T> = Exclude<T, null | undefined | void>
export type PickGraphql<Q, K extends keyof Q> = {} & Omit<
  ExcludeNullish<Required<Q>[K]>,
  '__typename'
>

export class WebrtcStore {
  static sync: Sync<WebrtcStore> = {
    micId: 'string',
    micMuted: 'boolean',
    camId: 'string',
    camDisabled: 'boolean',
    speakerId: 'string',
  }
  static getRootStore = () => null as any as RootStore

  role?: PickGraphql<OnStudioEnterQuery, 'sessionRole'> = undefined
  sourceChat: SourceChat = SourceChat.PUBLIC
  detail?: PickGraphql<OnStudioEnterQuery, 'detailSession'> = undefined
  plan?: SessionPlanQuery['sessionPlan'] = undefined
  note: SearchNoteQuery['searchNote'][0] = {
    content: '[]',
    id: '',
  }

  outputs: TOutputs[] = []
  outputInSession: WebrtcStoreOis[] = []
  devices: MediaDeviceInfo[] = []
  get mics() {
    return this.devices.filter(d => d.kind === 'audioinput')
  }
  get cams() {
    return this.devices.filter(d => d.kind === 'videoinput')
  }
  get speakers() {
    return this.devices.filter(d => d.kind === 'audiooutput')
  }
  customRatio = 16 / 9
  fraction: Fraction = {
    w: 1,
    h: 1,
  }
  micId?: string = undefined
  micMuted = false
  camId?: string = undefined
  camDisabled = false
  speakerId?: string = undefined
  isBlurSupported: boolean
  volumeBackground = 0
  camBlurred: boolean = false
  blurCleanUp: (() => void) | undefined = undefined
  tFLiteModel: TFLite | undefined
  preview?: {
    micTrack?: MediaStreamTrack
    camTrack?: MediaStreamTrack
  } = undefined
  state?: 'connecting' | 'connected' | 'error' = undefined
  mediasoup?: Mediasoup = undefined
  peers: PeerUI[] = [
    {
      id: ulid(),
      data: {
        viewmode: 'rparticipant',
      },
    },
  ]
  videosRecordSelected: string[] = []

  get isScreenshareSharing() {
    const local = this.peers[0]
    return !!local.screenshareVideo || !!local.screenshareAudio
  }

  isProducer: boolean
  isConsumer: boolean
  sessionId: string
  sessionMode: SessionMode = 'session'
  openTranscriptView = false
  viewmode: Viewmode
  socketInfo: {
    id?: string
    connected: boolean
  } = { connected: false }
  isViewmodeHost: boolean
  isViewmodeObserver: boolean
  isViewmodeParticipant: boolean

  isViewmodeMixer: boolean
  isMainMixer: boolean
  isViewmodeAms: boolean
  isViewmodeAmsClient: boolean
  isMediaControllerClient: boolean
  isSubtitleClient: boolean
  mixerData?: {
    userId?: string
    stream?: string
    peerId?: string
    isInput?: boolean
  }
  revAiKey: string

  mediaControlData: MediaControl[] = []
  mediaController: {
    [id: string]: HTMLVideoElement | null | undefined
  } = {}
  isViewmodeAmsClientController: boolean
  isInvited: boolean
  waitingUsers: SearchWaitingUserQuery['searchWaitingUser'] = []
  mixerGroup?: string = undefined
  hlsAudios?: HlsAudios
  get isObserverData() {
    return this.viewmode === 'robserver' || this.mixerGroup === 'mixerobserver'
  }
  constructor(q?: Record<string, string | undefined>, data?: PeerDataUpdate) {
    this.initFirstData(q, data)
  }
  initFirstData = (
    q?: Record<string, string | undefined>,
    data?: PeerDataUpdate,
  ) => {
    if (Platform.OS !== 'web') {
      return
    }

    q = { ...new URLParse(window.location?.href || '', true).query, ...q }

    this.sessionId = q.r || q.roomId || q.sessionId || ''

    if (q?.revAiKey) {
      this.revAiKey = q.revAiKey
    }

    const v = q.viewmode as TViewMode
    this.setViewmode(v, q)

    this.peers[0] = {
      id: ulid(),
      data: {
        ...data,
        viewmode: v,
        controlMedia: this.isMediaControllerClient,
        subtitle: this.isSubtitleClient,
      },
    }

    // mixer store
    this.selectedRightTabBarKey = ''
    this.userLogin = {
      id: this.peers[0].id,
      name: '',
      email: '',
      avatar: '',
    }
    this.reCalculateMainLayoutWidth()
    window.addEventListener('resize', this.reCalculateMainLayoutWidth)
    fullscreen?.on('change', () => {
      this.setFullscreenEnabled(!!fullscreen?.isFullscreen)
    })
    this.pingInterval =
      this.isViewmodeAms || this.isViewmodeHost
        ? window.setInterval(async () => {
            if (!this.amsData.ready) {
              return
            }
            const n = Date.now()
            await this.mediasoup?.protoo.request('ping', 0)
            this.last10Pings.push(Date.now() - n)
            if (this.last10Pings.length > 10) {
              this.last10Pings.shift()
            }
          }, amsPingInterval)
        : 0
  }
  setViewmode = (v: TViewMode, q?: Record<string, string | undefined>) => {
    this.viewmode = v
    this.isViewmodeHost = v === 'rhost'
    this.isViewmodeObserver = v === 'robserver'
    this.isViewmodeParticipant = v === 'rparticipant'

    this.isViewmodeMixer = v === 'mixer'
    if (this.isViewmodeMixer && q) {
      this.mixerGroup = q.mixer
      this.isMediaControllerClient = (q.controlMedia as any) ?? false
      this.isSubtitleClient = (q.subtitle as any) ?? false
      if (this.mixerGroup) {
        this.mixerData = {
          userId: q.userId,
          stream: q.stream,
          peerId: q.peerId,
          isInput: JSON.parse(q.isInput || 'false'),
        }
      }
    }
    if (this.isViewmodeMixer) {
      document.body.style.overflow = 'hidden'
    }
    this.isViewmodeAms = v === 'ams'
    this.isViewmodeAmsClient = v === 'ams-client'
    this.isViewmodeAmsClientController = v === 'ams-client-controller'
    this.isProducer =
      q?.produce !== 'false' &&
      !this.isViewmodeMixer &&
      !this.isViewmodeAms &&
      !this.isViewmodeAmsClientController
    this.isConsumer =
      q?.consume !== 'false' &&
      !this.isViewmodeAmsClient &&
      !this.isViewmodeAmsClientController
    if (this.peers[0]) {
      this.peers[0].data.viewmode = v
    }
  }
  get messages() {
    let chatInternal: TChatMessage[] = []
    let chatPublic: TChatMessage[] = []
    if (this.isViewmodeHost) {
      chatInternal = this.chatMessages.filter(c => {
        if (
          (c.user.role === 'Host' && c.message.type === SourceChat.INTERNAL) ||
          (c.user.role === 'Observer' && c.message.type === SourceChat.PUBLIC)
        ) {
          return true
        }
        return false
      })
      chatPublic = this.chatMessages.filter(
        c =>
          (c.user.role === 'Participant' || c.user.role === 'Host') &&
          c.message.type === SourceChat.PUBLIC,
      )
    }
    if (this.isViewmodeObserver) {
      chatInternal = this.chatMessages.filter(
        c =>
          c.user.role === 'Observer' && c.message.type === SourceChat.INTERNAL,
      )

      chatPublic = this.chatMessages.filter(c => {
        if (
          (c.user.role === 'Observer' &&
            c.message.type === SourceChat.PUBLIC) ||
          (c.user.role === 'Host' && c.message.type === SourceChat.INTERNAL)
        ) {
          return true
        }
        return false
      })
    }
    if (this.isViewmodeParticipant) {
      chatInternal = []
      chatPublic = this.chatMessages.filter(c =>
        (c.user.role === 'Participant' || c.user.role === 'Host') &&
        SourceChat.PUBLIC
          ? true
          : false,
      )
    }
    return {
      chatInternal,
      chatPublic,
    }
  }
  getPeer = (peerId: string = this.peers[0].id) =>
    this.peers.find(p => p.id === peerId)

  addPeer = (peer: PeerUI) => {
    if (!peer.id || peer.id === this.peers[0].id) {
      return
    }
    this.peers = this.peers.filter(p => p.id !== peer.id)
    peer.data = peer.data || {}
    this.peers.push(peer)

    if (this.state === 'connected') {
      this.mutePeerWhenJoinRoomOnObserver(peer)
    }
  }
  mutePeerWhenJoinRoomOnObserver = (peer: PeerUI) => {
    if (this.viewmode === 'robserver') {
      const vs = this.mediaVolumes[peer.id]
      if (
        !vs &&
        peer.data.viewmode !== 'robserver' &&
        peer.data.viewmode !== 'rcanvas'
      ) {
        this.updateMediaVolume(peer.id, { current: 0, prev: 1 })
      }
    }
  }
  updatePeer = (peer: Partial<Omit<PeerUI, TMediaKeys>>) => {
    const peerId = peer.id
    delete peer.id
    const p = this.getPeer(peerId)
    if (!p) {
      return
    }
    const { data, ...r } = peer
    Object.assign(p, r)
    if (!data) {
      return
    }
    Object.assign(p.data, data)
  }
  removePeer = (peerId: string) => {
    this.peers = this.peers.filter(p => p.id !== peerId)
  }
  changePeerData = (data: PeerDataUpdate) => {
    this.mediasoup?.protoo.request('changePeerData', data)
  }

  setMedia = <T extends TMediaKeys>(
    name: T,
    mediaWithPeerId: PeerUI[T] & {
      peerId?: string
      mediaId?: string
      to?: Viewmode
    },
  ) => {
    const { peerId, mediaId, to, ...media } = mediaWithPeerId
    const p = this.getPeer(peerId)
    if (p) {
      if (mediaId) {
        const m = { [mediaId]: { [name]: media, to } }
        p.medias = m
        return
      }
      Object.assign(p, { [name]: media, to })
    }
  }
  updateMedia = <T extends TMediaKeys>(
    name: T,
    mediaWithPeerId: Partial<
      PeerUI[T] & {
        peerId?: string
      }
    >,
  ) => {
    const { peerId, ...media } = mediaWithPeerId
    const m = this.getPeer(peerId)?.[name]
    if (m) {
      Object.assign(m, media)
    }
  }
  removeMedia = <T extends TMediaKeys>(
    name: T,
    peerId?: string,
    mediaId?: string,
    to?: Viewmode,
  ) => {
    const p = this.getPeer(peerId)
    if (p) {
      if (mediaId && p.medias) {
        for (const k in p.medias) {
          if (k === mediaId) {
            p.medias[k][name as 'audio' | 'video']?.track?.stop()
            Object.assign(p.medias[k], { [name]: undefined })
            if (!p.medias[k].audio && !p.medias[k].video) {
              delete p.medias[k]
            }
          }
        }
        return
      }
      p[name]?.track?.stop()
      Object.assign(p, { [name]: undefined })
    }
  }

  // Event 'notification' server -> client
  private onNewPeer: ProtooClientNotifyHandler<'newPeer'> = async d => {
    this.addPeer(d)
  }
  private onPeerClosed: ProtooClientNotifyHandler<'peerClosed'> = async d => {
    this.removePeer(d.peerId)
  }
  private onKickout: ProtooClientNotifyHandler<'kickout'> = async d => {
    onKickout()
  }
  private onActiveSpeaker: ProtooClientNotifyHandler<'activeSpeaker'> =
    async d => {
      // TODO:
    }
  private onProducerScore: ProtooClientNotifyHandler<'producerScore'> =
    async d => {
      // TODO:
    }
  private onConsumerPaused: ProtooClientNotifyHandler<'consumerPaused'> =
    async d => {
      const m = this.mediasoup
      if (!m) {
        return
      }
      const consumer = m.recv.consumers[d.consumerId]
      if (!consumer) {
        return
      }
      consumer.pause()
      const { peerId, share } = get(consumer, 'appData') as {
        peerId: string
        share: boolean
      }
      this.updateMedia(share ? 'screenshareAudio' : 'audio', {
        peerId,
        muted: true,
      })
    }
  private onConsumerResumed: ProtooClientNotifyHandler<'consumerResumed'> =
    async d => {
      const m = this.mediasoup
      if (!m) {
        return
      }
      const consumer = m.recv.consumers[d.consumerId]
      if (!consumer) {
        return
      }
      consumer.resume()
      const { peerId, share } = get(consumer, 'appData') as {
        peerId: string
        share: boolean
      }
      this.updateMedia(share ? 'screenshareAudio' : 'audio', {
        peerId,
        muted: false,
      })
    }
  private onConsumerScore: ProtooClientNotifyHandler<'consumerScore'> =
    async d => {
      // TODO:
    }
  private onConsumerLayersChanged: ProtooClientNotifyHandler<'consumerLayersChanged'> =
    async d => {
      // TODO:
    }
  onConsumerClosed: ProtooClientNotifyHandler<'consumerClosed'> = async d => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    const consumer = m.recv.consumers[d.consumerId]
    if (!consumer) {
      return
    }
    consumer.close()
    delete m.recv.consumers[d.consumerId]
    const { peerId, share } = get(consumer, 'appData') as {
      peerId: string
      share: boolean
      mediaId?: string
    }
    if (share) {
      this.removeMedia('screenshareAudio', peerId, d.mediaId, this.viewmode)
      this.removeMedia('screenshareVideo', peerId, d.mediaId, this.viewmode)
    } else {
      const k = consumer.track.kind === 'audio' ? 'audio' : 'video'
      this.removeMedia(k, peerId, d.mediaId, this.viewmode)
    }
  }
  private onDownlinkBwe: ProtooClientNotifyHandler<'downlinkBwe'> = async d => {
    // TODO:
  }
  private onPeerDataChanged: ProtooClientNotifyHandler<'peerDataChanged'> =
    async ({ id, data }) => {
      this.updatePeer({
        id,
        data,
      })
    }
  onMixerDataChanged: ProtooClientNotifyHandler<'mixerDataChanged'> =
    async d => {
      if (this.isSubtitleClient) {
        this.setSubData('host', d as typeof this)
      }
      if (this.isObserverData) {
        return
      }
      const enableSubOnSubClient = (d as typeof this).enableSubOnSubClient
      if (this.isViewmodeMixer && enableSubOnSubClient) {
        this.enableSubOnSubClient = {
          ...this.enableSubOnSubClient,
          ...enableSubOnSubClient,
        }
        return
      }
      runInAction(() => {
        Object.assign(this, d)
      })
    }
  private setSubData = (type: 'host' | 'observer', d: typeof this) => {
    if (d.mediaVolumes) {
      Object.assign(this.mediaVolumesSub[type], (d as typeof this).mediaVolumes)
    }
    if (d.isShowSubtitles) {
      this.enableSubOnSubClient[type] = d.isShowSubtitles
    }
    if (d.layoutMedias) {
      this.layoutMediasSub[type] = d.layoutMedias
    }
    if (d.selectedIndexLayout || d.selectedIndexLayout === 0) {
      this.selectedIndexLayoutSub[type] = d.selectedIndexLayout
    }
  }
  private onObserverDataChanged: ProtooClientNotifyHandler<'observerDataChanged'> =
    async d => {
      if (this.isSubtitleClient) {
        if (this.isSubtitleClient) {
          this.setSubData('observer', d as typeof this)
        }
      }

      if (!this.isObserverData) {
        return
      }
      runInAction(() => {
        Object.assign(this, d)
      })
    }
  private onExternalDataChanged: ProtooClientNotifyHandler<'externalDataChanged'> =
    async d => {
      runInAction(() => {
        Object.assign(this, d)
      })
    }
  private onMediaControlDataChanged: ProtooClientNotifyHandler<'mediaControlDataChanged'> =
    async d => {
      const ms = this.mediaControlData.find(m => m.id === d.id)
      if (!ms) {
        return
      }
      const vm: Viewmode =
        this.viewmode === 'mixer' && this.isObserverData
          ? 'robserver'
          : this.viewmode
      const shouldAction = checkViewModeSeeMediaControl(vm, ms.from)
      runInAction(() => {
        if (this.isMediaControllerClient || !shouldAction) {
          return
        }
        this.mediaControlData = this.mediaControlData.map(m =>
          m.id === d.id ? { ...m, ...d.data } : m,
        )
      })
    }
  private onAddMediaControlData: ProtooClientNotifyHandler<'addMediaControlData'> =
    async d => {
      runInAction(() => {
        this.mediaControlData = [...this.mediaControlData, d.data]
      })
    }
  private onRemoveMediaControlData: ProtooClientNotifyHandler<'removeMediaControlData'> =
    async d => {
      runInAction(() => {
        this.mediaControlData = this.mediaControlData.filter(m => m.id !== d.id)
      })
    }
  removeProducerMediaControl = async (media: MediaControl) => {
    const m = this.mediasoup

    if (!m) {
      return
    }
    // Remove camera
    const camProducer = Object.values(m.send.producers).find(
      p =>
        p.appData.to === media.from &&
        p.appData.mediaId === media.mediaId &&
        p.appData.type === 'cam',
    )
    if (camProducer) {
      delete m.send.producers[camProducer.id]
      camProducer.track?.stop()
      camProducer.close()
      const producerId = camProducer.id
      this.removeMedia('video', media.mediaId, media.from)
      await m.protoo.request('closeProducer', {
        producerId,
        mediaId: media.mediaId,
      })
    }
    // Remove mic
    const micProducer = Object.values(m.send.producers).find(
      p =>
        p.appData.type === 'mic' &&
        p.appData.mediaId === media.mediaId &&
        p.appData.to === media.from,
    )
    if (!micProducer) {
      return
    }
    delete m.send.producers[micProducer.id]
    micProducer.close()
    const producerId = micProducer.id
    this.removeMedia('audio', media.id, media.from)
    await m.protoo.request('closeProducer', {
      producerId,
    })
  }
  private onMediaControlOnAction: ProtooClientNotifyHandler<'mediaControlOnAction'> =
    async d => {
      runInAction(() => {
        const {
          id,
          action: { type, seek, volume, muted, repeat },
        } = d

        const m = this.mediaControlData.find(m1 => m1.id === id)
        const v = this.mediaController[id]
        const state: Partial<MediaControl> = {}
        if (!m || !v) {
          return
        }
        switch (type) {
          case 'seek':
            const ct = ((seek || 0) / 100) * v.duration
            state.currentTime = ct
            state.seeking = true
            v.currentTime = ct
            if (seek === 0 && m.paused) {
              v.play()
              setTimeout(() => {
                v.pause()
              }, 0)
            }
            break
          case 'pause':
          case 'play':
            state.paused = type === 'pause'
            v[type]()
            break
          case 'repeat':
            state.repeat = repeat
            this.mediaControlOnStateChange(id, {
              repeat,
            })
            break
          case 'volume':
            this.mediaControlOnStateChange(id, {
              volumeSeeking: true,
            })
            const isMutedAction = typeof muted === 'boolean'
            if (isMutedAction) {
              state.muted = muted
              if (!muted && m.volume <= 5) {
                state.volume = 30
              }
            } else {
              state.volume = volume ?? 0
              state.muted = false
            }
            v.volume = (state.volume ?? 100) / 100
            break
          default:
            break
        }
        this.mediaControlData = this.mediaControlData.map(i =>
          i.id === id ? { ...i, ...state } : i,
        )
      })
    }
  mediaControlOnAction = (id: string, data: MediaControlClientAction) => {
    if (data.type === 'seek') {
      this.mediaControlData = this.mediaControlData.map(m =>
        m.id === id ? { ...m, seeking: true } : m,
      )
    }
    this.mediasoup?.protoo.request('mediaControlOnAction', { id, action: data })
  }
  mediaControlOnStream = async (
    stream: MediaStream,
    mediaId: string,
    to?: Viewmode,
  ) => {
    await this.joinPromise?.promise
    const audioTracks = stream.getAudioTracks()
    const videoTracks = stream.getVideoTracks()
    // console.log(
    //  `mediaControlOnStream audioTracks=${audioTracks.length} videoTracks=${videoTracks.length}`,
    // )
    const m = this.mediasoup
    if (!m) {
      return
    }
    const arr = Object.values(m.send.producers)
    arr
      .find(
        p =>
          p.appData.type === 'mic' &&
          p.appData.mediaId === mediaId &&
          p.appData.to === to,
      )
      ?.close()
    arr
      .find(
        p =>
          p.appData.type === 'cam' &&
          p.appData.mediaId === mediaId &&
          p.appData.to === to,
      )
      ?.close()
    this.setMicrophoneTrack(audioTracks[0], mediaId, to)
    this.setCameraTrack(videoTracks[0], mediaId, to)
  }
  mediaControlOnStateChange = async (
    id: string,
    data: Partial<MediaControl>,
  ) => {
    await this.joinPromise?.promise
    this.mediasoup?.protoo.request('mediaControlDataChanged', { id, data })
  }
  removeMediaControlData = async (id: string) => {
    await this.joinPromise?.promise
    this.mediaControlData = this.mediaControlData.filter(m => m.id !== id)
    this.mediasoup?.protoo.request('removeMediaControlData', { id })
  }
  updateAmsClientVideo = (data: Partial<AmsClientVideo>) => {
    this.mediasoup?.protoo.request('amsVideoClientChanged', data)
  }

  addMediaControlData = (
    resourceId: string,
    url: string,
    type: 'video' | 'audio',
    from: 'background' | 'slot' = 'slot',
  ) => {
    const ms = this.mediaControlData.find(
      m => m.mediaId === resourceId && m.from === this.viewmode,
    )
    if (ms) {
      if (from === 'background') {
        return
      }
      if (ms.autoToBeginning) {
        this.mediaControlOnAction(ms.id ?? '', { type: 'seek', seek: 0 })
      }
      return
    }
    const data: MediaControl = {
      id: Helper.generateGuid(),
      currentTime: 0,
      duration: 0,
      from: this.viewmode,
      mediaId: resourceId,
      muted: false,
      volume: 100,
      type,
      url,
      repeat: true,
      autoToBeginning: true,
    }
    this.mediaControlData.push(data)
    this.mediasoup?.protoo?.request?.('addMediaControlData', {
      data,
    })
  }
  getMediaControlTracks = (mediaId: string) => {
    const media: {
      videoTrack: MediaStreamTrack | undefined
      audioTrack: MediaStreamTrack | undefined
    } = {
      videoTrack: undefined,
      audioTrack: undefined,
    }
    const peer = this.peers.find(
      p => p.data.viewmode === 'mixer' && p?.data.controlMedia,
    )
    if (!peer) {
      return media
    }
    const medias = Object.entries(peer.medias ?? {}).map(([key, value]) => ({
      key,
      value,
    }))
    const v =
      this.viewmode === 'mixer' && this.mixerGroup === 'mixerobserver'
        ? 'robserver'
        : this.viewmode === 'mixer' || this.viewmode === 'rparticipant'
          ? 'rhost'
          : this.viewmode
    const ms = medias.find(
      m =>
        m.key.replace(controlMediaPrefix[v], '') === mediaId &&
        m.value.to === v,
    )
    media.videoTrack = ms?.value.video?.track
    media.audioTrack = ms?.value.audio?.track
    return media
  }
  private onAmsDataChanged: ProtooClientNotifyHandler<'amsDataChanged'> =
    async d => {
      runInAction(() => {
        this.amsData.ready = true
        Object.assign(this.amsData, d)
        if (d.videos) {
          const map = arrToMap(d.videos, 'position')
          Object.keys(this.amsData.state)
            .filter(k => !map[k])
            .forEach(k => {
              delete this.amsData.state[k as any]
            })
        }
      })
      if (this.isViewmodeAmsClientController) {
        if (d.sync) {
          this.amsStartSyncCurrentTime()
        } else if (d.sync === false) {
          this.amsStopSyncCurrentTime()
        }
      }
    }
  private onAmsOnAction: ProtooClientNotifyHandler<'amsOnAction'> = async d => {
    // play/pause/seek all -> position = -1
    // The below share almost the same logic with server-mediasoup Room.ts
    const {
      position,
      action: { type, seek, volume, muted, repeat },
    } = d
    const {
      amsData: { sync, videos, state },
    } = this
    const v =
      position >= 0 && (!sync || type === 'volume')
        ? videos.filter(_ => _.position === position)
        : videos
    const s: AmsClientVideoStateWithPosition[] =
      position >= 0 && (!sync || type === 'volume')
        ? [{ ...state[d.position], position }]
        : Object.keys(state).map(k => ({
            ...state[k as any],
            position: Number(k),
          }))
    if (type === 'play' || type === 'pause') {
      const paused = type === 'pause'
      v.forEach(_ => {
        _.paused = paused
      })
      s.forEach(_ => {
        _.paused = paused
        this.amsControllerVideos[_.position]?.[type as 'play']()
      })
      if (paused && (position <= 0 || sync)) {
        this.v0HasPausedBetweenSync = true
      }
    } else if (type === 'seek') {
      const sr = (seek || 0) / 100
      let ct: number | undefined = undefined
      if (sync) {
        const s0 = s.find(_ => _.position === 0)
        if (s0) {
          ct = sr * s0.duration
        }
      }
      s.forEach(_ => {
        _.currentTime = ct !== undefined ? ct : sr * _.duration
        const video = this.amsControllerVideos[_.position]
        if (video) {
          video.currentTime = _.currentTime
        }
      })
    } else if (type === 'volume') {
      const isMutedAction = typeof muted === 'boolean'
      v.forEach(_ => {
        if (isMutedAction) {
          _.muted = muted
          if (!muted && _.volume <= 5) {
            _.volume = 30
          }
        } else {
          _.volume = volume || 0
          _.muted = false
        }
      })
    } else if (type === 'repeat') {
      v.forEach(_ => {
        _.repeat = typeof repeat === 'boolean' ? repeat : true
        const video = this.amsControllerVideos[_.position]
        if (video) {
          video.loop = _.repeat
        }
      })
    }
  }
  private onUpdateRemotePeer: ProtooClientNotifyHandler<'updateRemotePeer'> =
    async d => {
      this[d.command]?.()
    }

  inputs: FeInput[] = []
  private onInputCreated: ProtooClientNotifyHandler<'inputCreated'> =
    async d => {
      const i = this.inputs.find(_ => _.id === d.input.id)
      if (!i) {
        this.inputs.push(d.input)
      } else {
        Object.assign(i, d.input)
      }
    }
  private onInputUpdated: ProtooClientNotifyHandler<'inputUpdated'> =
    async d => {
      const i = this.inputs.find(_ => _.id === d.input.id)
      if (i?.status !== 'Ready' && d.input.status === 'Ready') {
        showInputReadyToast(d.input.name, d.input.url)
      }
      if (!i) {
        this.inputs.push(d.input)
      } else {
        Object.assign(i, d.input)
      }
    }
  private onInputDeleted: ProtooClientNotifyHandler<'inputDeleted'> =
    async d => {
      this.inputs = this.inputs.filter(i => i.id !== d.inputId)
    }
  requestSwitchRole = (
    peerId: string,
    role: SessionRoleName,
    name?: string,
  ) => {
    this.mediasoup?.protoo.request('requestSwitchRole', { peerId, role })
    ToastService.success({
      content: `Role change request sent. Waiting for ${name} to accept.`,
    })
  }

  answerSwitchRole = (isAccepted: boolean, userId: string) => {
    this.mediasoup?.protoo.request('answerSwitchRole', { isAccepted, userId })
  }
  private onRequestSwitchRole: ProtooClientNotifyHandler<'requestSwitchRole'> =
    async d => {
      showNotiSwitchRole(d.userId, d.name, d.avatarUrl, d.role)
    }
  private onAnswerSwitchRole: ProtooClientNotifyHandler<'answerSwitchRole'> =
    async d => {
      showNotiAnswerSwitchRole(d.name, d.avatarUrl, d.isAccepted)
    }
  private onResourceInSessionCreated: ProtooClientNotifyHandler<'mixer-ResourceInSession-created'> =
    async d => {
      if (d.type === 'chat' || d.type === 'avatar') {
        return
      }
      const formatItem = {
        ...d,
        id: d.id,
        name: d.resource.name,
        value: d.resource.url,
        size: d.resource.fileSize,
        position: d.position,
        isDefault: false,
        resourceId: d.resource.id,
        originalResourceId: d.resource.originalResourceId,
        originalResourceState: d.resource.originalResourceState,
      }
      switch (d.type) {
        case 'logo': {
          this.addGraphicLogo([{ ...formatItem, mediaType: 'image' }])
          break
        }
        case 'overlay': {
          this.addGraphicOverlay([{ ...formatItem, mediaType: 'image' }])
          break
        }
        case 'audio':
        case 'video':
        case 'image':
        case 'record': {
          this.addFileMedia({
            [d.type]: [{ ...formatItem, mediaType: d.type }],
          })
          break
        }
        default:
          break
      }
    }
  private onResourceInSessionUpdated: ProtooClientNotifyHandler<'mixer-ResourceInSession-updated'> =
    async d => {
      if (d.length === 0) {
        return
      }
      const type = this.getResourceTypeById(d[0].id + '')
      switch (type) {
        case 'logo': {
          const newList = this.renderNewListFromIds(
            this.graphicLogo,
            d,
          ) as TMediaItem[]
          this.graphicLogo = newList
          break
        }
        case 'overlay': {
          const newList = this.renderNewListFromIds(
            this.graphicLogo,
            d,
          ) as TMediaItem[]
          this.graphicOverlay = newList
          break
        }
        case 'audio':
        case 'video':
        case 'image':
        case 'record': {
          const newList = this.renderNewListFromIds(
            this.mediaStudio[type],
            d,
          ) as TMediaItem[]
          this.mediaStudio = {
            ...this.mediaStudio,
            [type]: newList,
          }
          break
        }
        default:
          break
      }
    }
  private onResourceInSessionDeleted: ProtooClientNotifyHandler<'mixer-ResourceInSession-deleted'> =
    async d => {
      this.deleteResourceFromWs(d as string[])
    }
  private onRecordingProgress: ProtooClientNotifyHandler<'mixer-recording-progress'> =
    async d => {
      const S = WebrtcStore.getRootStore()
      const ois = this.outputInSession.find(o => o.id === d.oisId)
      if (ois) {
        ois.recording = d
      } else {
        getOutputInSession(this.viewmode)
      }
      if (
        d.remainingStorage > 0 ||
        !(this.isViewmodeHost || this.isViewmodeObserver) ||
        S.profile.isEnterprise
      ) {
        return
      }
      // TODO
      // use modal instead of alert
      console.warn('exceed storage used')
    }
  private onRecordingFinish: ProtooClientNotifyHandler<'mixer-recording-finish'> =
    async d => {
      const ps = await Promise.all([
        reduxStore.context.gql.searchResourceInSession(
          {
            sessionId: this.sessionId,
            filter: {
              type: 'record',
            },
          },
          { requestPolicy: 'network-only' },
        ),
        getOutputInSession(this.viewmode),
      ])

      const resourceInSession = ps[0]
      if (!resourceInSession?.data?.searchResourceInSession.length) {
        return
      }
      const m = this._groupMediaOfResourceSession(
        resourceInSession.data.searchResourceInSession as any,
      )
      this.mediaStudio.record = m.record

      showRecordSuccessToast(this.onNavigateToRecord)
    }
  private onExceedMinutesUsed: ProtooClientNotifyHandler<'mixer-exceed-minutes-used'> =
    async d => {
      if (!(this.isViewmodeHost || this.isViewmodeObserver)) {
        return
      }
      // TODO
      // use modal instead of alert
      console.warn('exceed minutes used')
    }
  private onOisStatus: ProtooClientNotifyHandler<'mixer-ois-status'> =
    async d => {
      const map = arrToMap(this.outputInSession, 'id', o => o)
      const mapOutput = arrToMap(this.outputs, 'id', o => o)
      d.ids.forEach(id => {
        const o = map[id]
        if (o) {
          if (
            o.status === OutputInSessionStatus.Waiting &&
            d.status === OutputInSessionStatus.Failed
          ) {
            ToastService.error({
              content: `Destination ${o.output?.type} ${mapOutput[o.outputId].name || 'Untitled'} failed to start`,
            })
          }

          o.status = d.status
          o.publishingAt = d.publishingAt
        } else {
          getOutputInSession(this.viewmode)
        }
      })
    }
  private onSendChat: ProtooClientNotifyHandler<'addChat'> = async d => {
    const sendingPeer = this.peers.find(p => p.id === d.peerId)
    if (!sendingPeer) {
      // log?
      return
    }
    const data = {
      user: {
        id: d.userId,
        name: sendingPeer.data.name,
        role: d.role,
        avatar: d.avatar,
      },
      message: {
        chatId: d.chatId,
        content: d.content,
        attachments: d?.attachments || [],
        time: serverTime.date(),
        type: d.type,
      },
    }
    this.addChat(data)
  }
  private onDeleteChat: ProtooClientNotifyHandler<'deleteChat'> = async d => {
    const sendingPeer = this.peers.find(p => p.id === d.peerId)
    if (!sendingPeer) {
      // log?
      return
    }
    const { chatId } = d
    this.deleteChat(chatId)
  }

  private onDeleteAttachment: ProtooClientNotifyHandler<'deleteAttachment'> =
    async d => {
      const sendingPeer = this.peers.find(p => p.id === d.peerId)
      if (!sendingPeer) {
        // log?
        return
      }
      const { chatId, attachmentId } = d
      this.deleteAttachment(chatId, attachmentId)
    }

  private onSubtitleChanged: ProtooClientNotifyHandler<'subtitleChanged'> =
    async d => {
      this.updateDataOfStore({ subTitle: d.titles })
    }

  private notifyHandlers: ProtooClientNotifyHandlers = {
    newPeer: this.onNewPeer,
    peerClosed: this.onPeerClosed,
    kickout: this.onKickout,
    activeSpeaker: this.onActiveSpeaker,
    producerScore: this.onProducerScore,
    consumerPaused: this.onConsumerPaused,
    consumerResumed: this.onConsumerResumed,
    consumerScore: this.onConsumerScore,
    consumerLayersChanged: this.onConsumerLayersChanged,
    consumerClosed: this.onConsumerClosed,
    downlinkBwe: this.onDownlinkBwe,
    peerDataChanged: this.onPeerDataChanged,
    mixerDataChanged: this.onMixerDataChanged,
    observerDataChanged: this.onObserverDataChanged,
    externalDataChanged: this.onExternalDataChanged,
    amsDataChanged: this.onAmsDataChanged,
    amsOnAction: this.onAmsOnAction,
    mediaControlDataChanged: this.onMediaControlDataChanged,
    mediaControlOnAction: this.onMediaControlOnAction,
    addMediaControlData: this.onAddMediaControlData,
    removeMediaControlData: this.onRemoveMediaControlData,
    updateRemotePeer: this.onUpdateRemotePeer,
    'mixer-ResourceInSession-created': this.onResourceInSessionCreated,
    'mixer-ResourceInSession-updated': this.onResourceInSessionUpdated,
    'mixer-ResourceInSession-deleted': this.onResourceInSessionDeleted,
    'mixer-recording-progress': this.onRecordingProgress,
    'mixer-recording-finish': this.onRecordingFinish,
    'mixer-exceed-minutes-used': this.onExceedMinutesUsed,
    'mixer-ois-status': this.onOisStatus,
    addChat: this.onSendChat,
    deleteChat: this.onDeleteChat,
    deleteAttachment: this.onDeleteAttachment,
    inputCreated: this.onInputCreated,
    inputUpdated: this.onInputUpdated,
    inputDeleted: this.onInputDeleted,
    requestSwitchRole: this.onRequestSwitchRole,
    answerSwitchRole: this.onAnswerSwitchRole,
    subtitleChanged: this.onSubtitleChanged,
  }

  close = () => {
    this.state = undefined
    this.peers = [this.peers[0]]
    this._close()
  }
  _close = () => {
    this.joinPromise?.resolverFn?.()
    this.joinPromise = undefined
    if (this.pingInterval) {
      window.clearInterval(this.pingInterval)
      this.pingInterval = 0
    }
    const m = this.mediasoup
    if (!m) {
      return
    }
    this.disableBlurLoop()
    this.mediasoup = undefined
    Object.values(m.send.producers).forEach(p => p.track?.stop())
    m.send.transport?.close()
    m.recv.transport?.close()
    m.protoo.close()
  }

  joinPromise?: JoinPromise
  join = async () => {
    const S = WebrtcStore.getRootStore()
    if (this.joinPromise?.promise) {
      return this.joinPromise.promise
    }
    const preLocalPeerId = localStorage.getItem('preLocalPeerId')
    const jp: JoinPromise = {}
    jp.promise = new Promise(r => {
      jp.resolverFn = r
    })
    this.joinPromise = jp

    this.state = 'connecting'
    const peerId = this.peers[0].id
    const url = await _getProtooUrl(reduxStore.context.gql, {
      sessionId: this.sessionId,
      token: S.shared.authToken,
      peerId,
      viewmode: this.viewmode,
      mixer: this.mixerGroup,
    })
    if (!url) {
      //  console.log('!url')
      this.state = 'error'
      // TODO show modal error
      return
    }

    const r = await _createProtoo(url)

    if (!r) {
      console.log('!connected')
      this.state = 'error'
      // TODO show modal error
      return
    }
    const { protoo, device } = r

    const onProtooOpen = async () => {
      this.mediasoup = await _onProtooOpen(
        protoo,
        device,
        !this.isConsumer ? 'OnlySend' : undefined,
      )
      this.state = 'connecting'
      const requestHandlers = {
        newConsumer: _onRequestNewConsumer(this._get, this._set),
      }
      _addProtooHandlers(protoo, requestHandlers, this.notifyHandlers)
      //
      const d = await _requestJoin(this.mediasoup, {
        consumer: this.isConsumer,
        ...this.peers[0].data,
      })
      if (!d) {
        return
      }

      if (this.joinPromise) {
        this.joinPromise.resolverFn?.()
        this.joinPromise.resolverFn = undefined
      }
      const { name } = this.peers[0].data
      this.changePeerData({ name })
      d.peers.forEach(this.addPeer)

      Object.assign(this.amsData, d.amsData)

      this.mediaControlData = d.mediaControlData ?? []
      //
      const state = {
        ...((this.isObserverData
          ? d.observerData
          : d.mixerData) as WebrtcStore),
        ...d.externalData,
      }
      Object.assign(this, state)
      d.inputs?.forEach(input => this.onInputUpdated({ input }))
      this.state = 'connected'
      if (this.isSubtitleClient) {
        this.setSubData('host', d.mixerData as any)
        this.setSubData('host', d.observerData as any)
      }
      if (this.isViewmodeAmsClient) {
        if (this.amsData.sync) {
          this.amsStartSyncCurrentTime()
        } else if (this.amsData.sync === false) {
          this.amsStopSyncCurrentTime()
        }
      }
      if (this.viewmode.startsWith('ams')) {
        console.log(`Successfully join room viewmode=${this.viewmode}`)
        return
      }

      // automatically enable mic/cam for those roles:
      if (
        this.viewmode === 'rhost' ||
        this.viewmode === 'robserver' ||
        this.viewmode === 'rparticipant'
      ) {
        this.enableMicrophone().then(() => {
          if (this.micMuted) {
            this.muteMicrophone()
          }
        })
        if (!this.camDisabled) {
          this.enableCamera()
        }
        this.mediasoup.send.transport?.on(
          'connectionstatechange',
          (connectionState: string) => {
            if (connectionState === 'connected') {
              // Enable data producer here if we use DataChannel
            }
          },
        )
      }
      // reclaim owner role to this user
      let ownerPartial: Partial<TMixerState> | undefined = undefined
      const ownerRole: TViewMode = this.isObserverData ? 'robserver' : 'rhost'
      const isOwner = this.viewmode === ownerRole

      if (isOwner && !d.alreadyInitExternalData) {
        const externalState = await this.mediasoup.protoo.request(
          'initExternalData',
          {
            roomTitle: this.roomTitle,
            mediaVolumesOriginal: this.mediaVolumesOriginal,
            selectedChat: this.selectedChat,
            plan: this.plan,
          },
        )
        Object.assign(this, externalState)
      }

      const d2 = d.externalData as WebrtcStore
      if (
        this.viewmode === ownerRole &&
        d.alreadyInitExternalData &&
        d2 &&
        this.plan?.plan !== d2.plan?.plan
      ) {
        d2.plan = this.plan
        this.updateAndEmit({ plan: this.plan }, 'external')
      }

      // check if the mixerData on server is empty
      const alreadyInit = this.isObserverData
        ? d.alreadyInitObserverData
        : d.alreadyInitMixerData

      if (
        isOwner &&
        !alreadyInit &&
        !d.peers.find(p => p.data.viewmode === ownerRole)
      ) {
        ownerPartial = {
          ownerPeerId: this.peers[0].id,
        }
        // assign the camera of this first participant in the room
        const layoutPeers = this.selectedListLayouts.reduce(
          (map, _, index) => ({
            ...map,
            [index]: {
              1: this.isObserverData
                ? this.peers.find(p => p.data.viewmode === 'rcanvas')?.id ||
                  this.peers[0].id
                : this.peers[0].id,
            },
          }),
          {} as TMixerState['layoutPeers'],
        )
        ownerPartial.layoutPeers = layoutPeers
      } else {
        if (
          this.viewmode === 'rhost' ||
          this.viewmode === 'robserver' ||
          this.viewmode === 'rparticipant'
        ) {
          const newLayoutPeers = cloneDeep(state.layoutPeers ?? {})
          if (preLocalPeerId && preLocalPeerId !== this.peers[0].id) {
            Object.keys(newLayoutPeers).forEach(k1 => {
              Object.keys(newLayoutPeers[Number(k1)]).forEach(k2 => {
                if (newLayoutPeers[Number(k1)][Number(k2)] === preLocalPeerId) {
                  newLayoutPeers[Number(k1)][Number(k2)] = this.peers[0].id
                }
              })
            })
          }
          if (!isEqual(newLayoutPeers, state.layoutPeers)) {
            ownerPartial = {
              layoutPeers: newLayoutPeers,
            }
          }
        }
      }

      const mediaVolumesInit: { [key: string]: MediaVolume } = {}
      if (this.isObserverData) {
        d.peers.forEach(p => {
          if (
            p.data.viewmode !== 'robserver' &&
            p.data.viewmode !== 'rcanvas'
          ) {
            mediaVolumesInit[p.id] = {
              current: 0,
              prev: 100,
            }
          }
        })
        d.inputs.forEach(i => {
          mediaVolumesInit[i.id] = {
            current: 0,
            prev: 100,
          }
        })
      }
      const layoutFullStreamInit = this.isObserverData
        ? { 0: 1 }
        : this.layoutFullStream

      if (isOwner && !alreadyInit) {
        const initFunc = this.isObserverData
          ? 'initObserverData'
          : 'initMixerData'
        const mixerState2 = await this.mediasoup.protoo.request(initFunc, {
          onAir: true,
          audioUrl: this.audioUrl,
          backgroundColor: this.backgroundColor,
          backgroundUrl: this.backgroundUrl,
          backgroundVideoUrl: this.backgroundVideoUrl,
          backgroundPeerId: this.backgroundPeerId,
          chatMessages: this.chatMessages,
          graphicColor: this.graphicColor,
          isShowOverlayChat: this.isShowOverlayChat,
          layoutMedias: this.layoutMedias,
          layoutPeers: this.layoutPeers,
          layoutStyle: this.layoutStyle,
          layoutSlotsShow: this.layoutSlotsShow,
          layoutFullStream: layoutFullStreamInit,
          titles: this.titles,
          logoUrl: this.logoUrl,
          renderWatermark: this.renderWatermark,
          mediaPlaylists: this.mediaPlaylists,
          overlayUrl: this.overlayUrl,
          selectedIndexLayout: this.selectedIndexLayout,
          selectedIndexLayouts: this.selectedIndexLayouts,
          selectedListLayouts: this.selectedListLayouts,
          selectedTitles: this.selectedTitles,
          titlesOnAir: this.titlesOnAir,
          selectedStyleTitles: this.selectedStyleTitles,
          showParticipantName: this.showParticipantName,
          roomDescription: this.roomDescription,
          stateVideoPreview: this.stateVideoPreview,
          stateAudioPlaying: this.stateAudioPlaying,
          mediaVolumes: mediaVolumesInit,
          customRatio: this.customRatio,
          fraction: this.fraction,
        })
        Object.assign(this, mixerState2)
      }
      if (ownerPartial) {
        this.updateAndEmit(ownerPartial)
      }
      if (
        this.viewmode === 'rhost' ||
        this.viewmode === 'robserver' ||
        this.viewmode === 'rparticipant'
      ) {
        localStorage.setItem('preLocalPeerId', this.peers[0].id)
      }
    }

    this.updatePeer({
      id: this.peers[0].id,
      data: {
        ...this.peers[0].data,
        userId: S.profile?.profile?.id || '',
        avatar: S.profile?.profile?.avatarUrl || '',
        name: S.profile?.profile?.name || 'Unknown',
      },
    })
    // we need to add listener here to handle auto reconnect on disconnect
    // the listener will recreate the transports and rejoin the mediasoup session
    protoo.on('open', onProtooOpen)
    // TODO:
    // Ignore those events to let it reconnect itself
    //    without interupting the webrtc streams?
    // this.protoo.on('disconnected', this.closeTransports)
    // this.protoo.on('close', this.close)
    return onProtooOpen()
  }

  enableMicrophone = async () => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    let track: MediaStreamTrack | undefined
    try {
      let micProducer = Object.values(m.send.producers).find(
        p => p.appData.type === 'mic',
      )
      if (micProducer || !m.device.canProduce('audio')) {
        return
      }
      track =
        this.preview?.micTrack ||
        (await getTrack('audio', this.mics, this.micId))
      if (this.preview?.camTrack) {
        this.preview = undefined
      }
      micProducer = await this.setMicrophoneTrack(track)
      if (!micProducer) {
        return
      }
      micProducer.on('trackended', () => {
        this.disableMicrophone()
      })
    } catch (err: any) {
      track?.stop()
      showError('Failed to enable microphone', err)
    }
  }

  setMicrophoneTrack = async (
    track?: MediaStreamTrack,
    mediaId?: string,
    to?: Viewmode,
  ) => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    if (!m.send.transport || !track) {
      return
    }
    console.log('setMicrophoneTrack')
    const micProducer = await m.send.transport.produce({
      track,
      codecOptions: {
        opusStereo: true,
        opusDtx: true,
      },
      appData: {
        type: 'mic',
        mediaId,
        to,
      },
    })
    this.setMedia('audio', {
      id: micProducer.id,
      track,
      mediaId,
      to,
    })
    micProducer.on('transportclose', () => {
      delete m.send.producers[micProducer.id]
    })
    m.send.producers[micProducer.id] = micProducer
    return micProducer
  }
  disableMicrophone = async () => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    const micProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'mic',
    )
    if (!micProducer) {
      return
    }
    delete m.send.producers[micProducer.id]
    micProducer.close()
    const producerId = micProducer.id
    this.removeMedia('audio')
    await m.protoo.request('closeProducer', {
      producerId,
    })
  }

  onSelectedMicrophoneChange = async () => {
    try {
      const track = await getTrack('audio', this.mics, this.micId)
      if (!track) {
        return
      }
      const m = this.mediasoup
      if (!m) {
        return
      }
      const micProducer = Object.values(m.send.producers).find(
        p => p.appData.type === 'mic',
      )
      const old = micProducer?.track
      if (old && old !== track) {
        old.stop()
      }
      await micProducer?.replaceTrack({ track })
      this.updateMedia('audio', { track })
    } catch (err: any) {
      showError('Failed to change microphone', err)
    }
  }

  enableCamera = async (facingMode?: VideoFacingModeEnum) => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    let track: MediaStreamTrack | undefined = undefined
    const camProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'cam',
    )
    if (camProducer || !m.device.canProduce('video')) {
      return
    }
    track =
      this.preview?.camTrack ||
      (await getTrack('video', this.cams, this.camId, facingMode))
    if (this.preview?.micTrack) {
      this.preview = undefined
    }
    this.disableBlurLoop()
    if (this.isBlurSupported && this.camBlurred && track) {
      const { newTrack, cleanUpFunc } = await this.blurCamera(track)
      track = newTrack
      this.blurCleanUp = cleanUpFunc
    }
    const webcamProducer = await this.setCameraTrack(track)
    if (!webcamProducer) {
      return
    }
    webcamProducer.on('trackended', () => {
      this.disableCamera().catch((err: Error) => {
        console.error(err)
      })
    })
  }
  setCameraTrack = async (
    track?: MediaStreamTrack,
    mediaId?: string,
    to?: Viewmode,
  ) => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    if (!m.device || !m.send.transport || !track) {
      return
    }

    const encodings: undefined | TEncoding[] = undefined
    let codec: RtpCodecCapability | undefined
    const codecOptions = {
      videoGoogleStartBitrate: 1000,
    }
    const camProducer = await m.send.transport.produce({
      track,
      encodings,
      codecOptions,
      codec,
      appData: {
        type: 'cam',
        mediaId,
        to,
      },
    })
    this.setMedia('video', {
      id: camProducer.id,
      track,
      mediaId,
      to,
    })
    camProducer.on('transportclose', () => {
      delete m.send.producers[camProducer.id]
    })
    m.send.producers[camProducer.id] = camProducer
    return camProducer
  }
  disableCamera = async () => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    const camProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'cam',
    )
    if (!camProducer) {
      return
    }
    delete m.send.producers[camProducer.id]
    camProducer.track?.stop()
    camProducer.close()
    const producerId = camProducer.id
    this.removeMedia('video')
    this.disableBlurLoop()
    await m.protoo.request('closeProducer', {
      producerId,
    })
  }
  onSelectedCameraChange = async () => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    const camProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'cam',
    )
    let track = await getTrack('video', this.cams, this.camId)
    if (!track) {
      return
    }
    this.disableBlurLoop()
    if (this.isBlurSupported && this.camBlurred) {
      const { newTrack, cleanUpFunc } = await this.blurCamera(track)
      track = newTrack
      this.blurCleanUp = cleanUpFunc
    }
    const old = camProducer?.track
    if (old && old !== track) {
      old.stop()
    }
    await camProducer?.replaceTrack({ track })
    this.updateMedia('video', { track })
  }
  onToggleBlurCamera = async () => {
    this.camBlurred = !this.camBlurred

    const m = this.mediasoup
    if (!m) {
      return
    }
    const camProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'cam',
    )

    let track: MediaStreamTrack | undefined = undefined
    track =
      this.preview?.camTrack || (await getTrack('video', this.cams, this.camId))
    if (!track) {
      return
    }

    this.disableBlurLoop()
    if (this.isBlurSupported && this.camBlurred) {
      const { newTrack, cleanUpFunc } = await this.blurCamera(track)
      track = newTrack
      this.blurCleanUp = cleanUpFunc
    }

    const old = camProducer?.track
    if (old && old !== track) {
      old.stop()
    }

    await camProducer?.replaceTrack({ track })
    this.updateMedia('video', {
      track,
    })
  }
  onPreviewToggleBlurCamera = async () => {
    this.camBlurred = !this.camBlurred
    let track: MediaStreamTrack | undefined = undefined
    track =
      (this.camBlurred && this.preview?.camTrack) ||
      (await getTrack('video', this.cams, this.camId))
    if (!track) {
      return
    }
    this.disableBlurLoop()
    if (this.preview?.camTrack) {
      if (this.isBlurSupported && this.camBlurred) {
        const { newTrack, cleanUpFunc } = await this.blurCamera(track)
        track = newTrack
        this.blurCleanUp = cleanUpFunc
      }
      this.preview.camTrack = track
    }
  }

  blurCamera = async (track: MediaStreamTrack) => {
    if (!this.tFLiteModel) {
      try {
        this.tFLiteModel = await loadTFLiteModel()
      } catch (e) {
        console.log("Can't load TFLite")
        this.isBlurSupported = false
        return { newTrack: track, cleanUpFunc: () => {} }
      }
    }
    const stream = new MediaStream()
    const videoEl = document.createElement('video')
    stream.addTrack(track)
    videoEl.srcObject = stream
    videoEl.width = track.getSettings().width || 0
    videoEl.height = track.getSettings().height || 0
    await videoEl.play()

    const canvasEl = document.createElement('canvas')
    canvasEl.width = track.getSettings().width || 0
    canvasEl.height = track.getSettings().height || 0

    const newTrack = canvasEl.captureStream().getVideoTracks()[0]

    const { cleanUpFunc } = renderPipeline(videoEl, canvasEl, this.tFLiteModel)
    const cleanUpFuncAndStopCam = () => {
      cleanUpFunc()
      track.stop()
    }
    return { newTrack, cleanUpFunc: cleanUpFuncAndStopCam }
  }

  disableBlurLoop = () => {
    if (this.blurCleanUp) {
      this.blurCleanUp()
      this.blurCleanUp = undefined
    }
  }

  updateCustomRatio = async (ratio: number) => {
    if (this.viewmode === 'rhost' || this.viewmode === 'robserver') {
      const res = await reduxStore.context.gql.changeMixerRatio({
        sessionId: this.sessionId,
        ratio,
        viewmode: this.viewmode,
      })
      if (res.error) {
        ToastService.warning({
          content: 'Please turn off recording or live to continue.',
          duration: 2,
        })
        return
      }
      if (ratio) {
        this.customRatio = ratio
        this.reCalculateMainLayoutWidth()
        // this.updateEmitAndSaveDataStore({
        //  customRatio: ratio,
        //  fraction: this.fraction,
        // })

        this.updateEmitAndSaveSettings({
          customRatio: ratio,
          fraction: this.fraction,
        })
      }
    }
  }

  rnSwitchCamera = async (facingMode: VideoFacingModeEnum) => {
    try {
      const m = this.mediasoup
      if (!m) {
        return
      }
      const videoProducer = this.peers?.[0]?.video
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode },
      })
      const tracks = stream.getVideoTracks()
      const videoTrack = tracks[0]
      tracks.filter(t => t.id !== videoTrack.id).forEach(t => t.stop())
      if (!videoTrack) {
        return
      }
      if (videoProducer) {
        await this.disableCamera()
      }
      const webcamProducer = await this.setCameraTrack(videoTrack)
      if (!webcamProducer) {
        return
      }
      webcamProducer.on('trackended', () => {
        this.disableCamera().catch((err: Error) => {
          console.log('SwitchCamera.DisableCamera.Error: ', err)
        })
      })
    } catch (err) {
      console.log('SwitchCamera.Error: ', err)
    }
  }

  _get = () => this
  _set = (fn: (s: MediasoupState) => void) => {
    fn(this)
  }

  enableScreenshare = async () => {
    if (!this.mediasoup) {
      return
    }
    await _enableScreenshare(
      this.mediasoup,
      this._set,
      undefined,
      this.disableScreenshare,
    )
    this.onScreenshareEnable(this.autoShareScreen)
  }

  disableScreenshare = async () => {
    if (!this.mediasoup) {
      return
    }
    await _disableScreenshare(this.mediasoup, this._set)
    this.onScreenshareDisable()
  }

  callApiUpload = async (
    file: FormData,
    type: string,
    config?: AxiosRequestConfig<unknown>,
    headerConfig?: {
      [key: string]: string
    },
  ) => {
    const S = WebrtcStore.getRootStore()
    try {
      const result: UploadResponse = await axios({
        method: 'post',
        url: `${reactServerOrigin}/api/upload`,
        data: file,
        headers: {
          authorization: `Bearer ${S.shared.authToken}`,
          'x-session-id': this.sessionId,
          'x-resource-type': type,
          ...headerConfig,
        },
        ...config,
      })
      return result.data?.data
    } catch (err: any) {
      showError('Failed to upload file', err as Error)
      console.error(err)
      return err
    }
  }

  amsData: AmsClientData = {
    ready: false,
    sync: false,
    videos: [],
    state: {},
  }
  amsControllerVideos: {
    [position: number]: HTMLVideoElement | null | undefined
  } = {}
  amsOnStream = async (stream: MediaStream, mediaId: string) => {
    await this.joinPromise?.promise
    const audioTracks = stream.getAudioTracks()
    const videoTracks = stream.getVideoTracks()
    // console.log(
    //  `amsOnStream audioTracks=${audioTracks.length} videoTracks=${videoTracks.length}`,
    // )
    const m = this.mediasoup
    if (!m) {
      return
    }

    this.setCameraTrack(videoTracks[0], mediaId)
    this.setMicrophoneTrack(audioTracks[0], mediaId)
  }
  amsOffStream = async (mediaId: string) => {
    const m = this.mediasoup
    if (!m) {
      return
    }
    // Remove camera
    const camProducer = Object.values(m.send.producers).find(
      p => p.appData.mediaId === mediaId && p.appData.type === 'cam',
    )
    if (camProducer) {
      delete m.send.producers[camProducer.id]
      camProducer.track?.stop()
      camProducer.close()
      const producerId = camProducer.id

      await m.protoo.request('closeProducer', {
        producerId,
        mediaId,
      })
    }
    // Remove mic
    const micProducer = Object.values(m.send.producers).find(
      p => p.appData.type === 'mic' && p.appData.mediaId === mediaId,
    )
    if (!micProducer) {
      return
    }
    delete m.send.producers[micProducer.id]
    micProducer.close()
    const producerId = micProducer.id

    await m.protoo.request('closeProducer', {
      producerId,
    })
  }
  amsPeer = (): PeerUI | undefined =>
    this.peers.find(p => p.data.viewmode === 'ams-client')
  amsOnVideosChange = async (videos: AmsClientVideo[]) => {
    await this.joinPromise?.promise
    this.mediasoup?.protoo.request('amsDataChanged', { videos })
  }
  amsOnStateChange = debounce(
    async () => {
      await this.joinPromise?.promise
      const state: AmsClientState = {}
      this.amsData.videos.forEach(d => {
        const v = this.amsControllerVideos[d.position]
        if (!v) {
          return
        }
        state[d.position] = {
          duration: v.duration,
          currentTime: v.currentTime,
          paused: v.paused,
        }
      })
      this.mediasoup?.protoo.request('amsDataChanged', { state })
    },
    amsOnStateChangeDebounce,
    {
      maxWait: amsOnStateChangeDebounceMax,
      leading: true,
      trailing: true,
    },
  )
  private amsStartSyncCurrentTime = () => {
    if (this.amsSyncRAF) {
      return
    }
    this.amsSync()
  }
  private amsStopSyncCurrentTime = () => {
    if (!this.amsSyncRAF) {
      return
    }
    window.clearTimeout(this.amsSyncRAF)
    this.amsSyncRAF = 0
  }
  amsSyncRAF = 0
  amsLastSyncFrame1 = 0
  amsLastSyncFrame2 = 0
  amsLastSyncFrame3 = 0
  v0LastCurrentTime = 0
  v0HasPausedBetweenSync = false
  private amsSync = () => {
    this.amsSyncRAF = window.setTimeout(this.amsSync, amsSyncInterval)
    const v0 = this.amsControllerVideos[0]
    if (!v0) {
      return
    }
    // check for 3 kinds of interval
    const now = Date.now()
    const shouldSyncFrame1 = now - this.amsLastSyncFrame1 > 16000
    if (shouldSyncFrame1) {
      this.amsLastSyncFrame1 = now
    }
    const shouldSyncFrame2 = now - this.amsLastSyncFrame2 > 8000
    if (shouldSyncFrame2) {
      this.amsLastSyncFrame2 = now
    }
    const shouldSyncFrame3 = now - this.amsLastSyncFrame3 > 4000
    if (shouldSyncFrame3) {
      this.amsLastSyncFrame3 = now
    }
    // arr to later write all dom properties at the same time
    const diffs: { v: HTMLVideoElement; d: number }[] = []
    const syncs: HTMLVideoElement[] = []
    const pauseds: HTMLVideoElement[] = []
    // check fix bug v0 sometimes freeze
    const v0Paused = !!this.amsData.videos.find(v => !v.position)?.paused
    const { currentTime } = v0
    if (!this.v0HasPausedBetweenSync && !v0Paused) {
      const d = Math.abs(currentTime - this.v0LastCurrentTime) * 1000
      const maxD = amsSyncInterval / 2
      if (d < maxD) {
        // try to seek v0 a little and trigger play()
        syncs.push(v0)
        pauseds.push(v0)
      }
    }
    this.v0LastCurrentTime = currentTime
    this.v0HasPausedBetweenSync = false // reset
    // loop through dom and read
    Object.entries(this.amsControllerVideos)
      .filter(e => e[0] !== '0' && e[1])
      .map(e => e[1] as HTMLVideoElement)
      .forEach(v => {
        const diff = Math.abs(v.currentTime - currentTime)
        if (diff > 1) {
          syncs.push(v)
          return
        }
        if (
          (shouldSyncFrame1 && diff > 0.042) ||
          (shouldSyncFrame2 && diff > 0.125) ||
          (shouldSyncFrame3 && diff > 0.25)
        ) {
          diffs.push({ v, d: diff })
        }
        if (v.paused !== v0Paused) {
          pauseds.push(v)
        }
      })
    diffs
      .sort((a, b) => b.d - a.d)
      .filter((d, i) => !i)
      .forEach(d => syncs.push(d.v))
    const fn = v0Paused ? 'pause' : 'play'
    // write all dom properties at the same time
    // this is better than read/write interleavedly
    syncs.forEach((v, i) => {
      // add dom interaction latency
      const latency = 0.06 + i * 0.01
      v.currentTime = currentTime + latency
    })
    pauseds.forEach(v => v[fn]())
  }
  amsOnAction = async (position: number, a: AmsClientAction) => {
    await this.joinPromise?.promise
    // play/pause/rewind all -> position = -1
    this.mediasoup?.protoo.request('amsOnAction', {
      position,
      action: a,
    })
  }
  last10Pings: number[] = []
  pingInterval: number
  pingLatency = () => {
    const sum = this.last10Pings.reduce((n, v) => n + v, 0)
    const l = this.last10Pings.length
    return l && Math.round(sum / l)
  }

  // mixer store
  audioUrl = ''
  autoShareScreen: false
  backgroundUrl = '' // BackgroundDefault
  backgroundVideoUrl = ''
  backgroundPeerId = '' // peer , share screen on background
  backgroundColor = '#000000'
  chatMessages: TChatMessage[] = []
  chatLayoutWidth = chatW
  dimensionDragItem = {
    width: 0,
    height: 0,
  }
  fullscreenMediaId = ''
  graphicColor: TMediaItem[] = TemplateDefault.color
  graphicLogo: TMediaItem[] = []
  graphicOverlay: TMediaItem[] = []
  graphicSettings: TGraphicSettings = {
    theme: 'default',
    primaryColor: '#ff915a',
    defaultGraphics: true,
    showVideoPart: false,
    autoShareScreen: false,
    showPartName: false,
  }
  isAuthorAudioUrl = false
  isFullscreenEnabled = false
  isHoverTitleBottom = false
  isFullScreenSite = false
  isInDemo =
    true || // TODO
    window.location.hostname.indexOf('beam-demo') >= 0

  get isPublishing() {
    if (!(this.isViewmodeHost || this.isViewmodeObserver)) {
      return false
    }
    return this.outputInSession.some(
      o => o.output?.type !== 'Recording' && o.status === 'Publishing',
    )
  }
  // main canvas recording
  get mainRecordingOis() {
    if (!(this.isViewmodeHost || this.isViewmodeObserver)) {
      return
    }
    const q = this.isViewmodeObserver
      ? 'mixer=mixerobserver'
      : 'mixer=mixerhost'
    return this.outputInSession.find(
      o => o.output?.type === 'Recording' && o.mixerQuery === q,
    )
  }
  get isRecording() {
    return this.mainRecordingOis?.status === 'Publishing'
  }
  get peersForSubtitles() {
    return this.peers.filter(p => {
      const volume: number | undefined = this.mediaVolumes[p.id]?.current
      const isMuted = volume === 0 || volume <= volume0 || !!p.audio?.muted
      const noSound = !p.audio && !p.medias

      if ((isMuted && p.data.viewmode !== 'mixer') || noSound) {
        return false
      }
      if (this.isViewmodeHost) {
        return (
          p.data.viewmode !== 'robserver' &&
          p.data.viewmode !== 'ams' &&
          p.data.viewmode !== 'rcanvas'
        )
      }
      if (this.isViewmodeObserver) {
        return p.data.viewmode !== 'ams'
      }
      return false
    })
  }
  get multipleRecordings() {
    if (this.isViewmodeParticipant) {
      return []
    }
    const mainQ = this.isViewmodeObserver
      ? 'mixer=mixerobserver'
      : 'mixer=mixerhost'
    return this.outputInSession.filter(
      o =>
        o.output?.type === 'Recording' &&
        o.status === 'Publishing' &&
        o.mixerQuery.includes(mainQ),
    )
  }
  get isMultipleRecording() {
    return !!this.multipleRecordings.length
  }
  get multipleRecordingStartedAt() {
    if (this.isViewmodeParticipant) {
      return
    }
    const mainQ = this.isViewmodeObserver
      ? 'mixer=mixerobserver'
      : 'mixer=mixerhost'
    return this.outputInSession
      .filter(
        o =>
          o.output?.type === 'Recording' &&
          o.status === 'Publishing' &&
          o.publishingAt &&
          o.mixerQuery.includes(mainQ),
      )
      .map(o => o.publishingAt as Date)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]
  }

  isShowLogo = true
  isShowOverlayChat = false
  renderWatermark = false
  isVerifyLogin = false
  isShowSubtitles = false
  enableSubOnSubClient: { host?: boolean; observer?: boolean } = {
    host: false,
    observer: false,
  }
  lastSelectedIndexLayout = 0
  layoutFullStream: {
    [layoutIndex: number]: number // position
  } = { 0: 0 }
  layoutMedias: TLayoutItems = {}
  layoutMediasSub: {
    observer: TLayoutItems
    host: TLayoutItems
  } = {
    host: {},
    observer: {},
  }
  selectedIndexLayoutSub: {
    observer: number
    host: number
  } = {
    observer: 0,
    host: 0,
  }
  layoutPeers: {
    [layoutId: number]: {
      [position: number]: string // mediaId
    }
  } = {}
  layoutSlotsShow: {
    [layoutId: number]: ReadonlyArray<number> // list position is showed
  } = {
    0: [0],
    1: [0, 1],
    2: [0, 1, 2],
    3: [0, 1, 2, 3],
    4: [0, 1],
  }
  layoutStyle: TSettingLayout['layoutStyle'] = 'standard'
  titles: TitleItem[] = []
  subTitle?: string
  subtitleSlot: ILayoutItem
  recentFonts: string[] = ['Poppins']
  logoUrl = ''
  mainLayoutWidth = designW
  mediaPlaylists: TMediaPlaylist[] = []
  mediaVolumes: { [mediaId: string]: MediaVolume } = {}
  mediaVolumesSub: {
    observer: { [mediaId: string]: MediaVolume }
    host: { [mediaId: string]: MediaVolume }
  } = { observer: {}, host: {} }
  mediaVolumesOriginal: { [mediaId: string]: MediaVolume } = {}
  mediaSelectionId: string[] = []
  // this doesnt need to sync between participants
  // we can reload settings/resources from api
  mediaStudio: TMediaStudio = {
    audio: [],
    image: [],
    video: [],
    record: [],
  }
  onAir = true
  overlayUrl = ''
  ownerPeerId = ''
  recordingUrl = ''
  roomDescription = ''
  roomTitle = 'Unname Room'
  selectedChat = ''
  selectedListLayouts: TKeyString[] = [
    layoutNew1,
    layoutNew2,
    layoutNew3,
    layoutNew4,
    layoutNew8,
  ]
  layoutTemplate: TKeyString[] = layoutNewAll
  selectedIndexLayout = 0
  selectedIndexLayouts: { [key: number]: number } = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 8,
  }
  selectedTitles: TLayoutItems = {}
  titlesOnAir: { [k: number]: string[] } = {}
  selectedStyleTitles: Record<string, string> = {
    fontFamily: 'Roboto',
    textColor: '#ffffff',
    backgroundColor: '#0f0f0f',
  }
  showParticipantName: true
  stateAudioPlaying: TStateMediaPreview = {}
  stateVideoPreview: TStateMediaPreview[] = []
  stateVideoPreviewId: string
  newStateVideoPreviewId: string
  videoPreview: TStateMediaPreview | null = null
  videoSessionList: TVideoSessionList[] = []
  backgroundMediaData: TBackgroundMediaData = {
    id: '',
    url: '',
  }
  isVideoUploading: boolean = false
  statusUpdateSettings = {
    type: '',
    status: '',
  }
  streamQuality = '720'
  selectedMediaTab = 'video'
  selectedRightTabBarKey: string
  typeDraggingItem = ''
  userLogin: { id: string; name: string; email: string; avatar: string }
  videoPlaybackMediaIds: {
    [layoutIndex: number]: {
      [position: number]: string // mediaId
    }
  } = {}

  get computedIsNotProducing() {
    return this.isViewmodeMixer
  }

  get computedSelectedLayout() {
    return (!this.onAir && this.isViewmodeMixer) ||
      isScreenshareMedia(this.fullscreenMediaId)
      ? layoutNew1
      : this.selectedLayoutByIndex
  }

  get computedVideoPlaybackMediaIds() {
    if (!this.videoPlaybackMediaIds[this.selectedIndexLayout]) {
      this.videoPlaybackMediaIds[this.selectedIndexLayout] = {}
    }
    return this.videoPlaybackMediaIds[this.selectedIndexLayout]
  }

  get computedShouldPlayVideoPlayback() {
    return this.isViewmodeHost || (this.isViewmodeMixer && this.onAir)
  }

  get computedSelectedMediaIds(): TKeyString {
    if (!this.layoutPeers[this.selectedIndexLayout]) {
      const obj = {}
      // Fix bug mobx set in getter
      setTimeout(
        action(() => {
          this.layoutPeers[this.selectedIndexLayout] = obj
        }),
      )
      return obj
    }
    return this.layoutPeers[this.selectedIndexLayout]
  }

  get isOnlyViewStream() {
    return this.isViewmodeMixer
  }

  get isSelectedLayoutFullStream() {
    return (
      has(this.layoutFullStream, this.selectedIndexLayout) &&
      this.layoutFullStream[this.selectedIndexLayout] >= 0
    )
  }

  get getSettingsOfSession() {
    return {
      audioUrl: this.audioUrl,
      autoShareScreen: this.autoShareScreen,
      streamQuality: this.streamQuality,
      layoutStyle: this.layoutStyle,
      layoutSlotsShow: this.layoutSlotsShow,
      layoutFullStream: this.layoutFullStream,
      showParticipantName: this.showParticipantName,
      backgroundUrl: this.backgroundUrl,
      logoUrl: this.logoUrl,
      renderWatermark: this.renderWatermark,
      backgroundColor: this.backgroundColor,
      backgroundVideoUrl: this.backgroundVideoUrl,
      backgroundMediaData: this.backgroundMediaData,
      backgroundPeerId: this.backgroundPeerId,
      overlayUrl: this.overlayUrl,
      selectedIndexLayout: this.selectedIndexLayout,
      selectedListLayouts: this.selectedListLayouts,
      graphicColor: this.graphicColor,
      titles: this.titles,
      subtitleSlot: this.subtitleSlot,
      isShowSubtitles: this.isShowSubtitles,
      layoutMedias: this.layoutMedias,
      mediaPlaylists: this.mediaPlaylists,
      selectedChat: this.selectedChat,
      selectedTitles: this.selectedTitles,
      titlesOnAir: this.titlesOnAir,
      selectedStyleTitles: this.selectedStyleTitles,
      stateVideoPreview: this.stateVideoPreview,
      stateAudioPlaying: this.stateAudioPlaying,
      customRatio: this.customRatio,
      fraction: this.fraction,
    }
  }

  get selectedLayoutByIndex() {
    let backwardCompatibility = false
    let selectedListLayouts = [...this.selectedListLayouts]
    while (selectedListLayouts.length < 5) {
      selectedListLayouts.push(layoutNew8)
      backwardCompatibility = true
    }
    while (selectedListLayouts.length > 5) {
      selectedListLayouts.pop()
      backwardCompatibility = true
    }
    selectedListLayouts = selectedListLayouts.map((v, i) => {
      if (typeof v === 'number') {
        backwardCompatibility = true
        return this.layoutTemplate[v - 1]
      }
      if (!v) {
        backwardCompatibility = true
        return this.layoutTemplate[defaultLegacyLayouts[i] - 1]
      }
      return v
    })
    if (backwardCompatibility) {
      setTimeout(() => {
        this.updateAndEmit({
          selectedListLayouts,
        })
      }, 17)
    }
    const v = this.selectedListLayouts[this.selectedIndexLayout]
    return typeof v === 'number' || !v ? layoutNew1 : v
  }
  get ratioScaleLayout() {
    return this.mainLayoutWidth / designW
  }
  get totalMessageChat() {
    return this.chatMessages.length
  }
  private _removeVideoPlaybackMediaId = (mediaId: string) => {
    const i = Object.keys(this.computedVideoPlaybackMediaIds)
      .map(Number)
      .find(
        position =>
          getVideoUrlFromMediaId(
            this.computedVideoPlaybackMediaIds[position],
          ) === getVideoUrlFromMediaId(mediaId),
      )
    if (i !== undefined) {
      delete this.computedVideoPlaybackMediaIds[i]
      return true
    }
    return false
  }

  addChat = (message: TChatMessage) => {
    const S = WebrtcStore.getRootStore()
    showNotiOnAddChat(message, S.profile.profile?.id)
    this.chatMessages.push(message)
  }

  addGraphicColor = (colors: TMediaItem[]) => {
    const listGraphic = this.graphicColor
    colors.forEach(color => {
      if (color.value !== '') {
        listGraphic.push({
          ...color,
          isDefault: false,
          position:
            Number(listGraphic[listGraphic.length - 1]?.position || 0) + 1,
        })
      }
    })

    this.updateAndEmit({
      graphicColor: listGraphic,
    })
  }

  addGraphicLogo = (logos: TMediaItem[]) => {
    const listGraphic = this.graphicLogo
    const newLogos = logos.filter(
      item => !this.graphicLogo.some(g => g.id === item.id),
    )
    if (newLogos.length > 0) {
      newLogos.forEach(logo => {
        if (logo.value !== '') {
          listGraphic.push({ ...logo, isDefault: false })
        }
      })
      this.graphicLogo = listGraphic
    }
  }

  addGraphicOverlay = (overlays: TMediaItem[]) => {
    const listGraphic = this.graphicOverlay
    const newOverlays = overlays.filter(
      item => !this.graphicOverlay.some(g => g.id === item.id),
    )
    if (newOverlays.length > 0) {
      overlays.forEach(overlay => {
        if (overlay.value !== '') {
          listGraphic.push({ ...overlay, isDefault: false })
        }
      })
      this.graphicOverlay = listGraphic
    }
  }

  addFileMedia = (listFile: TMediaStudio) => {
    let newData: TMediaStudio = {}
    Object.keys(listFile).forEach((key: string) => {
      const listFilter = listFile[key].filter(
        l => !this.mediaStudio[key].some(m => m.id === l.id),
      )
      if (listFilter.length > 0) {
        const data = [...this.mediaStudio[key], ...listFile[key]]
        newData = {
          ...newData,
          [key]: data,
        }
      }
    })
    this.mediaStudio = {
      ...this.mediaStudio,
      ...newData,
    }
  }

  addInitStateVideoPreview = (stateVideoId: string) => {
    const newIndexId = this.stateVideoPreview.findIndex(
      item => item?.mediaId === stateVideoId,
    )

    if (newIndexId < 0) {
      const newState = [
        ...this.stateVideoPreview,
        {
          paused: true,
          repeat: false,
          volume: 1,
          mediaId: stateVideoId,
        },
      ]
      this.updateDataOfStore({ stateVideoPreview: newState })
    }

    const indexId = this.stateVideoPreview.findIndex(
      item => item.mediaId === stateVideoId,
    )
    if (!indexId || indexId < 0) {
      const newState = [
        ...this.stateVideoPreview,
        {
          paused: true,
          repeat: false,
          volume: 1,
          mediaId: stateVideoId,
        },
      ]
      this.updateDataOfStore({ stateVideoPreview: newState })
    }
  }

  addMediaToPlaylist = (mediaIds: string[]) => {
    const result: TMediaPlaylist[] = mediaIds.map((item: string) => ({
      id: ulid(),
      mediaId: item,
    }))
    this.updateAndEmit({
      mediaPlaylists: [...this.mediaPlaylists, ...result],
    })
  }

  addStateVideoPreview = (data: TStateMediaPreview) => {
    this.stateVideoPreview = [...this.stateVideoPreview, data]
  }

  createPin = (mediaId: string) => () => {
    const mediaIds = this.computedSelectedMediaIds
    const fr = {
      position: Object.keys(mediaIds)
        .map(Number)
        .find(k => mediaIds[k] === mediaId),
      mediaId,
    }
    const to = {
      position: 0,
      mediaId: mediaIds[0],
    }
    this.handleDragDrop(fr, to)
  }

  createDbClickPin = (mediaId: string) => () => {
    if (this.selectedLayoutByIndex.defaultId === 1) {
      this.createPin(mediaId)()
    } else {
      this.updateAndEmit({
        fullscreenMediaId: mediaId === this.fullscreenMediaId ? '' : mediaId,
      })
    }
  }

  deleteAttachment = (messageId: string, attachmentId: string) => {
    const newMessage = this.chatMessages.map(item => {
      let attachments = item.message.attachments
      if (item.message.chatId === messageId) {
        attachments = attachments?.filter(a => a.id !== attachmentId)
        return {
          ...item,
          message: {
            ...item.message,
            attachments,
          },
        }
      }
      return item
    })
    this.chatMessages = newMessage.filter(
      item =>
        item.message.content !== '' || item.message.attachments.length > 0,
    )
  }

  deleteChat = (messageId: string) => {
    this.chatMessages = this.chatMessages.filter(
      item => item.message.chatId !== messageId,
    )
  }

  deleteFileMedia = (id: string, type: string) => {
    const currentList = this.mediaStudio[type]
    const isHasId = currentList.find(item => item.id === id)
    if (isHasId) {
      const newList = [...currentList].filter(item => item.id !== id)
      this.mediaStudio = {
        ...this.mediaStudio,
        [type]: newList,
      }
    }
  }

  deleteResourceFromWs = (ids: string[]) => {
    if (ids.length === 0) {
      return
    }
    ids.forEach(id => {
      const type = this.getResourceTypeById(id)
      switch (type) {
        case 'logo': {
          this.removeGraphicLogo(id)
          break
        }
        case 'logo': {
          this.removeGraphicLogo(id)
          break
        }
        case 'audio':
        case 'video':
        case 'image':
        case 'record': {
          this.deleteFileMedia(id, type)
          break
        }
        default:
          break
      }
    })
  }

  duplicateMediaPlaylist = (duplicateId: string) => {
    const itemDuplicate = this.mediaPlaylists.find(
      item => item.id === duplicateId,
    )
    if (itemDuplicate) {
      this.updateAndEmit({
        mediaPlaylists: [
          ...this.mediaPlaylists,
          {
            ...itemDuplicate,
            id: ulid(),
          },
        ],
      })
    }
  }

  dropItemToLayout = (type: string, value: string) => {
    let layoutObject = {}
    switch (type) {
      case DRAG_GRAPHIC_OVERLAY: {
        layoutObject = {
          overlayUrl: value,
        }
        break
      }
      case DRAG_GRAPHIC_LOGO: {
        layoutObject = {
          logoUrl: value,
        }
        break
      }
      case DRAG_GRAPHIC_COLOR: {
        layoutObject = {
          backgroundColor: value,
        }
        break
      }
      case DRAG_CHAT: {
        layoutObject = {
          selectedChat: value,
        }
        break
      }
      case DRAG_MEDIA_AUDIO: {
        this.isAuthorAudioUrl = true

        layoutObject = {
          audioUrl: value,
          stateAudioPlaying: {
            ...this.stateAudioPlaying,
            paused: false,
            time: 0,
            volume: 1,
            repeat: true,
            duration: 0,
            nextTime: 0,
          },
        }
        break
      }
      case DRAG_MEDIA_IMAGE: {
        layoutObject = {
          backgroundUrl: value,
          backgroundVideoUrl: '',
          backgroundPeerId: '',
        }
        break
      }
      case DRAG_MEDIA_VIDEO:
      case DRAG_MEDIA_RECORD: {
        layoutObject = {
          backgroundVideoUrl: value,
          backgroundUrl: '',
          backgroundPeerId: '',
        }
        break
      }
      case DRAG_USER_PARTICIPANT:
      case DRAG_USER_SMALL:
      case DRAG_USER_MAIN: {
        layoutObject = {
          backgroundVideoUrl: '',
          audioUrl: '',
          backgroundPeerId: value,
        }
        break
      }
    }
    this.updateAndEmit(
      layoutObject,
      type === DRAG_CHAT ? 'external' : 'internal',
    )
  }

  getFullMediaPlaylists = () => {
    const playlist: TMediaItem[][] = Object.values(this.mediaStudio)
    let fullPlayList: TMediaItem[] = []
    playlist.forEach((item: TMediaItem[]) => {
      fullPlayList = [...fullPlayList, ...item]
    })
    const result: TMediaPlaylistDetail[] = []
    this.mediaPlaylists.forEach((item: TMediaPlaylist) => {
      const detailItem = fullPlayList.find(p => p.id === item.mediaId)
      if (detailItem) {
        result.push({
          ...detailItem,
          ...item,
        })
      }
    })
    return result
  }

  getResourceTypeById = (id: string) => {
    if (this.graphicLogo.find(item => item.id === id)) {
      return 'logo'
    } else if (this.graphicOverlay.find(item => item.id === id)) {
      return 'overlay'
    } else if (this.mediaStudio.audio.find(item => item.id === id)) {
      return 'audio'
    } else if (this.mediaStudio.video.find(item => item.id === id)) {
      return 'video'
    } else if (this.mediaStudio.record.find(item => item.id === id)) {
      return 'record'
    }
    return 'image'
  }

  _groupMediaOfResourceSession = (resources: TResourceInSession[]) => {
    const listResult: TMediaStudio = resources.reduce(
      (groupResult: TMediaStudio, item: TResourceInSession) => {
        const typeStudio = item?.type.replace('default-', '')
        const group: TMediaItem[] = groupResult[typeStudio] || []
        const isDefault = item?.type.includes('default')
        const isUrl = (item?.resource?.url || '').includes('http')
        group.push({
          ...item.resource,
          id: item.id,
          value: isUrl
            ? item?.resource?.url || ''
            : `${reactServerOrigin}${item?.resource?.url}`,
          name: item?.resource?.name || '',
          position: item.position,
          mediaType: typeStudio === 'record' ? 'video' : typeStudio,
          isDefault,
          resourceId: item?.resource?.id + '',
          originalResourceId: item?.resource?.originalResourceId
            ? item?.resource?.originalResourceId + ''
            : '',
          originalResourceState: item?.resource?.originalResourceState || {},
          thumbnailResource: item.resource?.thumbnailResource,
        })
        groupResult[typeStudio] = group
        return groupResult
      },
      {},
    )
    return listResult
  }
  groupMediaOfResourceSession = (resources: TResourceInSession[]) => {
    const listResult = this._groupMediaOfResourceSession(resources)
    this.mediaStudio = {
      audio: sortResourceOfSession(listResult?.audio || []),
      image: sortResourceOfSession(listResult?.image || []),
      video: sortResourceOfSession(listResult?.video || []),
      record: sortResourceOfSession(listResult?.record || []),
    }
    this.graphicLogo = sortResourceOfSession(listResult?.logo || [])
    this.graphicOverlay = sortResourceOfSession(listResult?.overlay || [])
  }

  isHaveColorOnGraphic = (color: string) => {
    const listGraphic = this.graphicColor
    return listGraphic.some(item => compareColors(color, item.value))
  }

  isOnAirItemOfLayout = (items: TLayoutItems, itemId: string) => {
    const listMediaOnAir = items[this.selectedIndexLayout]

    let itemIsOnAir = false
    if (listMediaOnAir && Object.keys(listMediaOnAir).length > 0) {
      Object.keys(listMediaOnAir).forEach(layout => {
        const id = get(listMediaOnAir[layout], ['id'])
        if (id === itemId) {
          itemIsOnAir = true
        }
      })
    }
    return itemIsOnAir
  }

  handleDragDrop = (fr: TMediaDragDropData, to: TMediaDragDropData) => {
    if (!fr.peerId || to.position === undefined) {
      return
    }
    let objectUpdate = {}

    if (has(this.layoutMedias, [this.selectedIndexLayout, to.position])) {
      delete this.layoutMedias[this.selectedIndexLayout][to.position]
      objectUpdate = {
        ...objectUpdate,
        layoutMedias: this.layoutMedias,
      }
    }

    delete this.videoPlaybackMediaIds[to.position]
    const mediaIds: { [position: number]: string } =
      this.computedSelectedMediaIds

    if (!mediaIds[to.position]) {
      mediaIds[to.position] = fr.peerId
    } else {
      // if (to.peerId && fr.position !== undefined) {
      //   mediaIds[fr.position] = to.peerId
      // }
      mediaIds[to.position] = fr.peerId
    }

    this.layoutPeers[this.selectedIndexLayout] = mediaIds
    objectUpdate = {
      ...objectUpdate,
      layoutPeers: this.layoutPeers,
      videoPlaybackMediaIds: this.videoPlaybackMediaIds,
    }

    if (Object.keys(objectUpdate).length > 0) {
      this.updateAndEmit(objectUpdate)
    }
  }

  handleDragDropMedia = (dropIndex: number, item: TMediaItem) => {
    // remove slot if drop to slot has peerId
    // this.layoutPeers[this.selectedIndexLayout][dropIndex] !== ''
    const isAmsMedia = item.id.includes(amsMediaPrefix)
    if (item && item.mediaType === 'video' && !isAmsMedia) {
      const videoSelected = this.videoSessionList.find(
        v => v.mediaId === item.id,
      )
      this.addMediaControlData(
        buildControlMediaId(this.viewmode, item.id ?? ''),
        item.value ?? '',
        'video',
      )
      this.updateVideoSessionList({
        mediaId: item.id,
        name: item.name,
        repeat: item.isRepeat,
        paused: true,
        isSelected: true,
        currentTime: videoSelected?.currentTime ?? 0,
        duration: item.duration,
        url: item.url,
        volume: videoSelected?.volume ?? 1,
      })
      this.newStateVideoPreviewId = item.id
    }
    if (
      has(this.layoutPeers, [this.selectedIndexLayout, dropIndex]) &&
      this.layoutPeers[this.selectedIndexLayout][dropIndex] !== ''
    ) {
      delete this.layoutPeers[this.selectedIndexLayout][dropIndex]
    }

    // check and remove if drop to slot has media (video / image )
    const newMedias = removeAndGetNewLayout(
      [item.id],
      this.layoutMedias,
      this.selectedIndexLayout,
      false,
    )
    const layoutMedias = {
      ...newMedias,
      [this.selectedIndexLayout]: {
        ...newMedias[this.selectedIndexLayout],
        [dropIndex]: item,
      },
    }
    this.updateAndEmit({ layoutMedias, layoutPeers: this.layoutPeers })
  }

  onAutoShareScreenshareToggle = (enable?: boolean) => {
    if (enable) {
      this.onScreenshareEnable(enable)
    } else {
      this.onScreenshareDisable()
    }
  }

  onScreenshareEnable = (autoShare: boolean) => {
    const local = this.peers[0]
    const shareProducer = local.screenshareVideo
    if (this.ownerPeerId === this.peers[0].id && autoShare && shareProducer) {
      let listScreenShare = {}
      const listLayoutSelected = [...this.selectedListLayouts]
      if (this.selectedLayoutByIndex.slots.length <= 1) {
        const layoutChange = { ...layoutNew8 }
        const idExit = this.selectedListLayouts.find(
          l => l.defaultId === layoutNew8.defaultId,
        )
        const ids = Array.from(Array(20).keys())
        layoutChange.defaultId = idExit
          ? getIdNumber(layoutNew8.defaultId, ids)
          : layoutNew8.defaultId
        listLayoutSelected[this.selectedIndexLayout] = layoutChange
      }

      listScreenShare = {
        ...listScreenShare,
        [this.selectedIndexLayout]: {
          ...this.layoutPeers[this.selectedIndexLayout],
          2: buildScreenshareMediaId(this.peers[0].id),
        },
      }

      this.updateAndEmit({
        layoutPeers: {
          ...this.layoutPeers,
          ...listScreenShare,
        },
        selectedListLayouts: listLayoutSelected,
      })
    }
  }
  onScreenshareDisable = () => {
    this.removeMediaId(buildScreenshareMediaId(this.peers[0].id))
  }

  onWindowKeyPress = (e: KeyboardEvent) => {
    if (this.isOnlyViewStream) {
      return
    }
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      return
    }
    let tagName: string = get(e.target, 'tagName') as string
    if (typeof tagName !== 'string') {
      tagName = ''
    }
    tagName = tagName.toLowerCase()
    if (['input', 'textarea'].includes(tagName)) {
      return
    }
    if (e.key === ' ' && ['select', 'button'].includes(tagName)) {
      return
    }
    const keyShortCuts = e.key.toLowerCase()
    if (!SHORT_CUTS_STREAM.includes(keyShortCuts)) {
      return
    }
    e.preventDefault()
    // if (e.key === '5' && this.selectedLayoutByIndex === layoutScreenshareId) {
    //   this.createDbClickPin(this.computedSelectedMediaIds[1] || '')()
    //   return
    // }
    switch (keyShortCuts) {
      case 'c': {
        this.toggleWebcam()
        return
      }
      case 'm': {
        this.toggleMicrophone()
        return
      }
      case 'p': {
        return
      }
      case 's': {
        this.toggleShareScreen()
        return
      }
      case 'f': {
        this.toggleFullScreenStream()
        return
      }
      default: {
        if (!this.isViewmodeParticipant) {
          this.updateAndEmit({
            selectedIndexLayout:
              e.key === ' ' ? this.lastSelectedIndexLayout : +e.key - 1,
          })
        }

        return
      }
    }
  }

  reCalculateMainLayoutWidth = () => {
    if (this.isFullscreenEnabled || this.isViewmodeMixer) {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight
      const rR = newWidth / newHeight

      this.mainLayoutWidth =
        rR > this.customRatio ? newHeight * this.customRatio : newWidth
      this.chatLayoutWidth = (chatW / designW) * window.innerWidth
      return
    }
    // const isScaleWidth =
    //   window.innerWidth - 40 - 44 - 65 < designW
    // // padding + headerTitle + smallPeer + listLayout + bottomControl
    // const isScaleHeight =
    //   window.innerHeight - (24 * 2 + (50 + 32) + (80 + 16) + (56 + 24) + 81) <
    //   designH

    // if (!isScaleWidth && !isScaleHeight) {
    //   this.mainLayoutWidth = designW
    //   this.chatLayoutWidth = chatW
    //   return
    // }
    const lSidebarW = this.openTranscriptView ? 448 : 0
    const rSidebarW = 63 + this.selectedRightTabBarKey !== '' ? 342 : 0
    const mainPaddingW = 40 * 2

    const mainPaddingH = 2 * 24
    const mainMarginH = 0
    const tContentH = 130
    const bContentH = 160 + 10 // 10 padding with bottom control
    const transcriptH = this.openTranscriptView ? 71 : 0
    const remainingW = window.innerWidth - lSidebarW - rSidebarW - mainPaddingW
    const remainingH =
      window.innerHeight -
      tContentH -
      bContentH -
      mainPaddingH -
      mainMarginH -
      transcriptH
    const rR = remainingW / remainingH

    const newRemainWidth =
      rR > this.customRatio ? remainingH * this.customRatio : remainingW
    const newMainWidth = newRemainWidth
    // const newMainWidth =
    //   newRemainWidth > designW
    //     ? designW
    //     : newRemainWidth
    this.chatLayoutWidth = (chatW / designW) * newMainWidth
    this.mainLayoutWidth = newMainWidth
  }

  removeAllItemPlaylist = (playlistId: string, mediaId: string) => {
    const listPlaylistId: string[] = []
    this.mediaPlaylists.forEach(item => {
      if (item.id === playlistId || item.mediaId === mediaId) {
        listPlaylistId.push(item.id)
      }
    })
    const newMedias = removeAndGetNewLayout(
      listPlaylistId,
      this.layoutMedias,
      this.selectedIndexLayout,
    )
    const newPlaylist = this.mediaPlaylists.filter(
      item => !listPlaylistId.includes(item.id),
    )
    this.updateAndEmit({
      layoutMedias: newMedias,
      mediaPlaylists: newPlaylist,
    })
  }

  removeGraphicColor = (id: string) => {
    let listGraphic = this.graphicColor
    listGraphic = listGraphic.filter(item => item.id !== id)

    this.updateAndEmit({
      graphicColor: listGraphic,
    })
  }

  removeGraphicLogo = (id: string) => {
    let listGraphic = this.graphicLogo
    listGraphic = listGraphic.filter(item => item.id !== id)
    this.graphicLogo = listGraphic
  }

  removeGraphicOverlay = (id: string) => {
    let listGraphic = this.graphicOverlay
    listGraphic = listGraphic.filter(item => item.id !== id)
    this.graphicOverlay = listGraphic
  }

  removeItemPlaylist = (playlistId: string[]) => {
    const newPlaylist = this.mediaPlaylists.filter(
      item => !playlistId.includes(item.id),
    )
    const newMedias = removeAndGetNewLayout(
      playlistId,
      this.layoutMedias,
      this.selectedIndexLayout,
    )
    this.updateAndEmit({
      layoutMedias: newMedias,
      mediaPlaylists: newPlaylist,
    })
  }

  removeMediaId = (mediaId: string) => {
    let hasChanged = false
    Object.values(this.layoutPeers).forEach(l => {
      Object.keys(l)
        .map(Number)
        .forEach(position => {
          if (l[position] === mediaId) {
            delete l[position]
            hasChanged = true
          }
        })
    })
    if (hasChanged) {
      this.updateAndEmit({
        layoutPeers: this.layoutPeers,
      })
    }
  }

  removeMediaFromCanvas = (mediaId: string, position: number) => {
    // Delete media in canvas
    if (!this.layoutMedias[this.selectedIndexLayout][position]) {
      return
    }
    delete this.layoutMedias[this.selectedIndexLayout][position]
    this.updateAndEmit({ layoutMedias: this.layoutMedias })
    const mediaCleared = this.checkMediaNotInLayout(mediaId)
    const backgroundCleared = this.backgroundMediaData.id !== mediaId

    if (mediaCleared && backgroundCleared) {
      const ms = this.mediaControlData.find(
        m =>
          m.mediaId === buildControlMediaId(this.viewmode, mediaId) &&
          m.from === this.viewmode,
      )
      if (!ms) {
        return
      }
      this.removeMediaControlData(ms.id)
    }
    // Delete video preview state
    for (const i in this.layoutMedias[this.selectedIndexLayout]) {
      const mediaItem = this.layoutMedias[this.selectedIndexLayout][i]
      if (mediaItem.id === mediaId) {
        return
      }
    }
    this.removeStateVideoPreview([mediaId])
  }
  checkMediaNotInLayout = (mediaId: string) => {
    let res = false
    for (const i in this.layoutMedias) {
      for (const j in this.layoutMedias[i]) {
        if (this.layoutMedias[i][j].id === mediaId) {
          res = true
          break
        }
      }
    }
    return !res
  }

  removeMediasOfLayoutSlot = (itemIds: string[], layout?: number) => {
    const newMedias = removeAndGetNewLayout(
      itemIds,
      this.layoutMedias,
      layout,
      true,
      this.removeMediaFromCanvas,
    )
    // this.removeStateVideoPreview(itemIds)

    this.updateAndEmit({ layoutMedias: newMedias })
  }

  removePeerOnLayout = (position: number, layout?: number) => {
    const indexLayout = layout || this.selectedIndexLayout
    const currentPeers = this.layoutPeers
    if (
      has(currentPeers, [indexLayout, position]) &&
      currentPeers[indexLayout][position] !== ''
    ) {
      delete currentPeers[indexLayout][position]

      this.updateAndEmit({
        layoutPeers: currentPeers,
      })
    }
  }

  updateVideoSessionList = (item: TVideoSessionList) => {
    if (!item.mediaId) {
      return
    }
    const videoSelected = this.videoSessionList.find(
      i => i.mediaId === item.mediaId,
    )
    if (!videoSelected) {
      this.videoSessionList.push(item)
    }

    this.videoSessionList = this.videoSessionList.map(i => {
      if (i.mediaId === item.mediaId) {
        return {
          ...i,
          ...item,
        }
      } else {
        return {
          ...i,
          isSelected: false,
        }
      }
    })
  }

  removeStateVideoPreview = (itemIds: string[]) => {
    const newState = [...this.stateVideoPreview].filter(
      item => item?.mediaId && !itemIds.includes(item.mediaId),
    )
    this.updateEmitAndSaveSettings({
      stateVideoPreview: newState,
      stateVideoPreviewId: itemIds.includes(this.stateVideoPreviewId)
        ? ''
        : this.stateVideoPreviewId,
      videoPreview: itemIds.includes(this.videoPreview?.mediaId ?? '')
        ? null
        : this.videoPreview,
    })
  }

  removeVideoPlaybackMediaId = (
    mediaId: string,
    isFullscreenPlayback?: boolean,
  ) => {
    if (isFullscreenPlayback) {
      this.updateAndEmit({
        fullscreenMediaId: '',
      })
    } else if (this._removeVideoPlaybackMediaId(mediaId)) {
      this.updateAndEmit({
        videoPlaybackMediaIds: this.videoPlaybackMediaIds,
      })
    }
  }

  renderNewListFromIds = (list: TMediaItem[], resource: TKeyAny[]) =>
    resource
      .map((item: TKeyAny) => {
        const detail = list.find(l => l.id === item.id)
        return {
          ...detail,
          ...item,
        }
      })
      .sort((a, b) => Number(a?.position || 0) - Number(b?.position || 0))

  setDimensionDragItem = (width: number, height: number) => {
    this.dimensionDragItem = {
      width,
      height,
    }
  }

  setFullscreenEnabled = (isFullscreenEnabled: boolean) => {
    this.isFullscreenEnabled = isFullscreenEnabled
    this.reCalculateMainLayoutWidth()
    document.body.style.overflow = isFullscreenEnabled ? 'hidden' : 'unset'
  }

  setVerifyLogin = (auth: TPropsUserLogin) => {
    this.isVerifyLogin = true
    if (!auth.id) {
      auth.id = this.peers[0].id
    }
    this.userLogin = auth
  }

  sortAndUpdateListTitle = (dropIndex: number, overIndex: number) => {
    if (dropIndex === overIndex || dropIndex === overIndex - 1) {
      return
    }
    let newOverIndex = overIndex
    if (dropIndex < overIndex) {
      newOverIndex = newOverIndex - 1
    }

    const currentList = this.titles

    const idsList = Array.from(currentList, (item, index) => index)
    const listSorted: number[] = reOrderList(idsList, dropIndex, newOverIndex)
    const result = listSorted.map(item => currentList[item])

    this.updateAndEmit({
      titles: result,
    })
  }

  toggleChat = () => {
    this.updateDataOfStore({
      selectedRightTabBarKey: this.selectedRightTabBarKey === '6' ? '' : '6',
    })
  }
  toggleFullScreenStream = () => {
    this.updateDataOfStore({
      isFullScreenSite: !this.isFullScreenSite,
    })
  }
  toggleMediaSelectionId = (id: string) => {
    if (this.mediaSelectionId.includes(id)) {
      this.mediaSelectionId = [...this.mediaSelectionId].filter(
        item => item !== id,
      )
    } else {
      this.mediaSelectionId.push(id)
    }
  }

  muteMicrophone = () => {
    const { id } = this.peers[0]
    const current = this.mediaVolumes[id]?.current ?? 1
    this.updateMediaVolume(id, { current: 0, prev: current })
  }
  unmuteMicrophone = () => {
    const { id } = this.peers[0]
    const prev = this.mediaVolumes[id]?.prev ?? 1
    this.updateMediaVolume(id, { current: prev })
  }

  toggleParticipant = () => {
    this.updateDataOfStore({
      selectedRightTabBarKey: this.selectedRightTabBarKey === '6' ? '' : '6',
    })
  }

  toggleShareScreen = () => {
    if (this.isScreenshareSharing) {
      this.disableScreenshare()
    } else {
      this.enableScreenshare()
    }
  }

  toggleShowOverlayChat = () => {
    this.updateAndEmit({
      isShowOverlayChat: !this.isShowOverlayChat,
    })
  }

  toggleShowSubtitle = (value: boolean) => {
    this.updateAndEmit({
      isShowSubtitles: value,
    })
  }

  toggleSlotFullStream = (position = 0) => {
    let newFullStream = this.layoutFullStream
    if (
      has(this.layoutFullStream, [this.selectedIndexLayout]) &&
      this.layoutFullStream[this.selectedIndexLayout] >= 0
    ) {
      delete newFullStream[this.selectedIndexLayout]
    } else {
      newFullStream = {
        ...newFullStream,
        [this.selectedIndexLayout]: position,
      }
    }
    this.updateAndEmit({
      layoutFullStream: newFullStream,
    })
  }

  toggleTypeDraggingItem = (type: string, dragging: boolean) => {
    if (dragging) {
      if (this.typeDraggingItem !== type) {
        this.typeDraggingItem = type
      }
    } else {
      if (this.typeDraggingItem !== '') {
        this.typeDraggingItem = ''
      }
    }
  }

  toggleWebcam = (facingMode?: VideoFacingModeEnum) => {
    const videoProducer = this.peers[0].video
    if (videoProducer) {
      this.camDisabled = true
      this.disableCamera()
    } else {
      this.camDisabled = false
      this.enableCamera(facingMode)
    }
  }

  updateAndEmit = (
    data: TMixerState,
    type: 'external' | 'internal' = 'internal',
  ) => {
    if (
      data.selectedIndexLayout !== undefined &&
      data.selectedIndexLayout !== this.selectedIndexLayout
    ) {
      data.fullscreenMediaId = ''
      data.lastSelectedIndexLayout = this.selectedIndexLayout
    }
    this.updateDataOfStore(data)
    const func =
      type === 'external'
        ? 'externalDataChanged'
        : this.isObserverData
          ? 'observerDataChanged'
          : 'mixerDataChanged'
    this.mediasoup?.protoo.request(func, data)
  }
  mixerEmitSubtitle = (titles: string, toViewMode: 'rhost' | 'robserver') => {
    this.mediasoup?.protoo.request('subtitleChanged', { titles, toViewMode })
  }
  updateAndEmitToMixer = (data: TMixerState) => {
    this.updateDataOfStore(data)
    this.mediasoup?.protoo.request('mixerDataChanged', data)
  }
  updateEmitAndSendData = (
    dataSettings: TKeyAny | undefined = {},
    dataStore: TKeyAny | undefined = {},
    typeUpdate: string | undefined = '',
    callback?: () => void,
  ) => {
    let sessionId = this.sessionId
    if (!sessionId) {
      sessionId = new URLParse(window.location.href, true).query.r || ''
      Object.assign(this, { sessionId })
    }
    if (!sessionId) {
      console.error('Empty session id from url query')
      return
    }
    if (true) {
      if (Object.keys(dataStore).length > 0) {
        this.updateAndEmit(
          this.mapDataSettingsToStore(dataStore),
          typeUpdate === 'header' ? 'external' : 'internal',
        )
      }
      if (Object.keys(dataSettings).length > 0) {
        const settings = {
          observer:
            this.viewmode === 'robserver'
              ? dataSettings
              : this.detail?.settings?.observer,
          mixer:
            this.viewmode !== 'robserver'
              ? dataSettings
              : this.detail?.settings?.mixer,
        }
        const dataUpdated =
          typeUpdate === 'header'
            ? {
                title: dataSettings?.title,
                description: dataSettings?.description,
              }
            : { settings }
        reduxStore.context.gql
          .updateSession({
            id: sessionId,
            data: dataUpdated,
          })
          ?.then(() => {
            if (typeUpdate !== '') {
              this.updateDataOfStore({
                statusUpdateSettings: {
                  type: typeUpdate,
                  status: 'success',
                },
              })
            }
            callback?.()
          })
          .catch(err => {
            if (typeUpdate !== '') {
              this.updateDataOfStore({
                statusUpdateSettings: {
                  type: typeUpdate,
                  status: 'error',
                },
              })
            }
          })
      }
    }
  }

  updateEmitAndSaveSettings = (
    data: TKeyAny | undefined = {},
    typeUpdate: string | undefined = '',
  ) => {
    const dataSettings = {
      ...this.getSettingsOfSession,
      ...data,
    }
    this.updateEmitAndSendData(dataSettings, data, typeUpdate)
  }

  updateEmitAndSaveDataStore = (
    data: TKeyAny,
    typeUpdate: string | undefined = '',
    callback?: () => void,
  ) => {
    if (Object.keys(data).length > 0) {
      this.updateEmitAndSendData(data, data, typeUpdate, callback)
    }
  }

  mapDataSettingsToStore = (data: TKeyAny) => {
    const newData = { ...data }
    if (data.description) {
      delete newData.description
      newData.roomDescription = data.description
    }
    if (data.title) {
      delete newData.title
      newData.roomTitle = data.title
    }
    return newData
  }

  updateDataOfStore = (data: TMixerState) => {
    Object.assign(this, data)
  }

  updateDataStoreFromSettings = (settings: TKeyAny) => {
    Object.keys(settings).forEach(key => {
      if (has(this, [key])) {
        Object.assign(this, { [key]: settings[key] })
      }
    })
  }

  updateStateVideoPreview = (
    stateVideoId: string,
    data: TStateMediaPreview,
  ) => {
    const indexId = this.stateVideoPreview.findIndex(
      item => item.mediaId === stateVideoId,
    )
    if (indexId >= 0) {
      const stateVideoPreview = [
        ...this.stateVideoPreview.slice(0, indexId),
        {
          ...this.stateVideoPreview[indexId],
          ...data,
        },
        ...this.stateVideoPreview.slice(indexId + 1),
      ]
      this.updateEmitAndSaveSettings({ stateVideoPreview }, 'stateVideoPreview')
    }
  }

  updateStateAudioPlaying = (data: TStateMediaPreview) => {
    this.updateEmitAndSaveSettings(
      { stateAudioPlaying: { ...this.stateAudioPlaying, ...data } },
      'stateAudioPlaying',
    )
  }

  updateGraphicColor = (id: string, value: string) => {
    const newGraphicColor = this.graphicColor.map(i => {
      if (i.id === id) {
        return {
          ...i,
          value,
        }
      } else {
        return i
      }
    })
    this.updateAndEmit({
      graphicColor: newGraphicColor,
    })
  }
  updateGraphicLogo = (id: string, value: Partial<TMediaItem>) => {
    const listGraphic = this.graphicLogo
    const indexItem = listGraphic.findIndex(item => item.id === id)
    if (indexItem >= 0) {
      Object.assign(listGraphic[indexItem], value)
      this.updateAndEmit({
        graphicLogo: listGraphic,
      })
    }
  }

  updateGraphicSettings = (graphicSettings: Partial<TGraphicSettings>) => {
    this.updateAndEmit({
      graphicSettings: {
        ...this.graphicSettings,
        ...graphicSettings,
      },
    })
  }

  updateFileMedia = (type: string, id: string, data: Partial<TMediaItem>) => {
    const indexMedia = this.mediaStudio[type].findIndex(item => item.id === id)
    if (indexMedia >= 0) {
      const currentMedias = this.mediaStudio[type]
      const newData = {
        ...this.mediaStudio,
        [type]: [
          ...currentMedias.slice(0, indexMedia),
          {
            ...currentMedias[indexMedia],
            ...data,
          },
          ...currentMedias.slice(indexMedia + 1),
        ],
      }
      this.mediaStudio = newData
    }
  }

  updateLayoutMedias = (id: string, newData: TKeyString) => {
    const listNewData = updateValuesForLayout(
      id,
      this.layoutMedias,
      newData,
      this.selectedIndexLayout,
    )
    this.updateAndEmit({
      layoutMedias: listNewData,
    })
  }

  updateListTitle = (data: Array<TitleItem>) => {
    this.updateAndEmit({
      titles: data,
    })
  }

  toggleMicrophone = () => {
    const { id } = this.peers[0]
    const current = this.mediaVolumes[id]?.current ?? 1
    const muted = current <= volume0
    if (muted) {
      let prev = this.mediaVolumes[id]?.prev ?? 1
      if (prev <= volume0) {
        prev = 1
      }
      this.micMuted = false
      this.updateMediaVolume(id, { current: prev })
    } else {
      this.micMuted = true
      this.updateMediaVolume(id, { current: 0, prev: current })
    }
  }
  updateMediaVolume = (mediaId: string, value: Partial<MediaVolume>) => {
    const isLocal = mediaId === this.peers[0].id
    this.updateAndEmit({
      mediaVolumes: {
        ...this.mediaVolumes,
        [mediaId]: {
          ...this.mediaVolumes[mediaId],
          ...value,
          isLocal,
        },
      },
    })
    if (this.viewmode !== 'robserver') {
      this.updateAndEmit(
        {
          mediaVolumesOriginal: {
            ...this.mediaVolumesOriginal,
            [mediaId]: {
              ...this.mediaVolumesOriginal[mediaId],
              ...value,
            },
          },
        },
        'external',
      )
    }
  }

  updateOrderGraphicColor = (dropIndex: number, overIndex: number) => {
    const result = getOrderListMedia(
      this.graphicColor,
      dropIndex,
      overIndex,
    ) as TMediaItem[]
    if (result) {
      this.updateAndEmit({
        graphicColor: result,
      })
    }
  }
  updateOrderGraphicLogo = (dropIndex: number, overIndex: number) => {
    const result = getOrderListMedia(
      this.graphicLogo,
      dropIndex,
      overIndex,
    ) as TMediaItem[]
    if (result) {
      this.graphicLogo = result
    }
  }

  updateOrderGraphicOverlay = (dropIndex: number, overIndex: number) => {
    const result = getOrderListMedia(
      this.graphicOverlay,
      dropIndex,
      overIndex,
    ) as TMediaItem[]
    if (result) {
      this.graphicOverlay = result
    }
  }
  removePeersOnSlot = (ids: number[]) => {
    ids.forEach(id => {
      delete this.layoutPeers[this.selectedIndexLayout][id]
    })
    this.updateAndEmit({
      layoutPeers: this.layoutPeers,
    })
  }

  updateOrderMedia = (type: string, dropIndex: number, overIndex: number) => {
    const result = getOrderListMedia(
      this.mediaStudio[type],
      dropIndex,
      overIndex,
    ) as TMediaItem[]
    if (result) {
      this.mediaStudio = {
        ...this.mediaStudio,
        [type]: result,
      }
    }
  }
  updateOrderPlaylist = (dropIndex: number, overIndex: number) => {
    const result = getOrderListMedia(
      this.mediaPlaylists,
      dropIndex,
      overIndex,
    ) as TMediaPlaylist[]
    if (result) {
      this.updateAndEmit({
        mediaPlaylists: result,
      })
    }
  }

  updateRemotePeer = (d: {
    peerId: string
    command: UpdateRemotePeerCommand
  }) => {
    this.mediasoup?.protoo.request('updateRemotePeer', d)
  }

  updateTitlesOfLayoutSlot = (
    id: string,
    newData: { content: string; author: string },
  ) => {
    const listNewData = updateValuesForLayout(id, this.selectedTitles, newData)
    this.updateAndEmit({
      selectedTitles: listNewData,
    })
  }

  amsVideoState: {
    [k: string]: Pick<
      AmsClientVideo,
      'volume' | 'muted' | 'enableOnRoom' | 'onAir'
    >
  } = {}

  onNavigateToRecord = () => {
    this.updateDataOfStore({
      selectedRightTabBarKey: '4',
      selectedMediaTab: 'record',
    })
  }

  updateLayoutTemplate = (layoutTemplate: typeof layoutNewAll) => {
    this.layoutTemplate = layoutTemplate
  }
  setSourceChat = (s: SourceChat) => {
    this.sourceChat = s
  }
  setVideosRecordSelected = (list: string[]) => {
    this.videosRecordSelected = list
  }
  setOutputInSession = (
    data: SearchOutputInSessionQuery['searchOutputInSession'][0][],
  ) => {
    // use spread to keep existing data such as .recording
    // if we reassign the whole array it will overwrite the existing data
    const map = arrToMap(this.outputInSession, 'id', o => o)
    this.outputInSession = data.map(o2 => ({
      ...map[0],
      ...o2,
    }))
  }

  addHlsAudio = (audio?: MediaStreamTrack | null) => {
    if (!audio) {
      return
    }

    this.hlsAudios = { ...(this.hlsAudios ?? {}), [audio.id]: audio }
  }
}

export type TEncoding = {
  scaleResolutionDownBy?: number
  maxBitrate?: number
  scalabilityMode?: string
  dtx?: boolean
}

export type TMediaKeys =
  | 'audio'
  | 'video'
  | 'screenshareAudio'
  | 'screenshareVideo'

type JoinPromise = {
  resolverFn?: () => void
  promise?: Promise<void>
}

const amsPingInterval = 5000
const amsSyncInterval = 1000
const amsOnStateChangeDebounce = 500
const amsOnStateChangeDebounceMax = 1000

export type UploadResponse = {
  data: {
    data?: {
      item?: {
        id: string
        filesize: number
        url: string
      }
    }
  }
}

export const vw = 1920
export const vh = 1080

// to generate scale ratio
export const designW = 963
export const chatW = 280 // in design

export const cropboxW = 313
export const cropboxH = 142

// Media can be:
// regular camera   mediaId=peerId
// screenshare      mediaId=screenshare--${peerId}
// video playback   mediaId=video-${videoUrlBase64}-${peerId}

const screenshareMediaPrefix = 'screenshare--'

export const amsMediaPrefix = 'amsclient--'
export const controlMediaPrefix: { [key: string]: string } = {
  rhost: 'mediaHost--',
  robserver: 'mediaObserver--',
}
export const buildScreenshareMediaId = (peerId: string) =>
  `${screenshareMediaPrefix}${peerId}`
export const buildControlMediaId = (v: Viewmode, mediaId: string) =>
  `${controlMediaPrefix[v]}${mediaId}`
export const buildAmsMediaId = (mediaId: string) =>
  `${amsMediaPrefix}${mediaId}`
export const isScreenshareMedia = (mediaId: string) =>
  mediaId.startsWith(screenshareMediaPrefix)

export const getVideoUrlFromMediaId = (mediaId: string) =>
  atob(mediaId.split('-')[1])
export const getNormalId = (peerId: string) => peerId.split('-')[1]
export const getMediaPeerId = (mediaId: string) =>
  isScreenshareMedia(mediaId) ? mediaId.replace(/^[^-]+\-[^-]*\-/, '') : mediaId

export const COLOR_SOLID_DEFAULT = '#1C4BD6'
export const COLOR_GRADIENT_DEFAULT = [
  { offset: 0, color: '#1C4BD6', id: 1 },
  { offset: 1, color: '#FF92FB', id: 2 },
]

type TMixerStore = InstanceType<typeof WebrtcStore>

export type TMediaItemDetail = {
  media: TMediaItem
  index: string
  dragSelected?: boolean
  draggingSelected?: boolean
  isDraggingSelect?: boolean
  onSortEnd?: (dropIndex: number, overIndex: number) => void
  onDragEnd?: () => void
  onDelete: (item: TMediaItem) => void
  onSelectMedia?: (e: MouseEvent) => void
  onAir?: boolean
  onTurnOffAir?: () => void
}
export type TMediaPlaylistDetail = {
  id: string
  mediaId: string
  value: string
  mediaType: string
  name?: string
}

export type TMediaPlaylist = {
  id: string
  mediaId: string
}

export type TMediaStudio = {
  [k: string]: TMediaItem[]
}
export type TMediaItem = {
  id: string
  value: string
  name?: string
  mediaType: string
  resourceId?: string
  originalResourceId?: string | null
  originalResourceState?: any
  isDefault?: boolean
  position?: number
  duration?: number
  selected?: boolean
  url?: string
  thumbnailResource?: {
    url?: string
  } | null
  isRepeat?: boolean
}
export type TMixerState = {
  [K in keyof TMixerStore]?: TMixerStore[K] extends Function
    ? never
    : TMixerStore[K]
}

export type TKeyString = {
  [k: string]: any
}

export type TTitle = {
  id: string
  content?: string
  author?: string
}
export type TItemGraphic = TKeyString

export type TDetailTemplate = {
  [k: string]: Array<TItemGraphic>
}

export type TGraphicTemplates = {
  [key: string]: TDetailTemplate
}
export type TGraphicSettings = {
  theme: string
  primaryColor: string
  defaultGraphics: boolean
  showVideoPart: boolean
  autoShareScreen: boolean
  showPartName: boolean
}

export type TListBrands = {
  top: string
  bottom: string
  right: string
  left: string
}
export type TAttachments = {
  id: string
  mediaType: string
  value: string
  name: string
  size: number
}
export type TChatMessage = {
  user: {
    id: string
    name?: string
    avatar?: string | undefined
    role?: string
  }
  message: {
    chatId: string
    time: Date
    attachments: ReadonlyArray<TAttachments>
    content: string
    type?: string
  }
}
export type TPropsUserLogin = {
  id: string
  name: string
  email: string
  avatar: string
  role: string
}

export type TItemColor = {
  item: TMediaItem
  selected: boolean
  index: string
  onSelectColor: (color: string) => void
  onSortEnd: (dropIndex: number, overIndex: number) => void
  onDeleteColor: (item: TMediaItem) => void
  onEditColor: (id: string, color: string) => void
  isLightTheme?: boolean
  onShowOption?: (id: string) => void
}
export type TGradientColors = {
  id: number
  color: string
  offset: number
}

export type TSettingLayout = {
  autoShareScreen: boolean
  showParticipantName: boolean
  quality: '720' | '1080' | '2160'
  layoutStyle: 'standard' | 'modern' | 'rounded'
}

export type TMediaDragDropData = {
  peerId?: string
  name?: string
  position?: number
  id?: string
  type?: string
  mediaType?: string
  small?: string
  selectedIndexLayout?: number
  selectedLayoutByIndex?: number
  value?: string
  content?: string
  author?: string
  disableDrag?: boolean
  onDropEnd?: (data?: any) => void
}

export type TLayoutPositionItem = {
  [position: string]: TKeyString
}
export type TLayoutItems = {
  [layoutIndex: string]: TLayoutPositionItem
}
export type TitleStyle = {
  font?: string
  fontWeight?: string
  fontSize?: number
  color?: string
  isBold?: boolean
  isItalic?: boolean
  backgroundColor?: string
  borderWidth?: number
  borderColor?: string
  autoHeight?: boolean
}

export type SubtitleItem = {
  id: string
  content: string
  style?: TitleStyle
}

export type TitleItem = {
  id: string
  content: string
  contentOnAir?: any
  style?: TitleStyle
  type?: 'title' | 'subtitle'
}
export type TTitleOnAirOnLayout = {
  [layoutIndex: number]: TitleItem[]
}
export type TKeyAny = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | Date
    | boolean
    | Object
    | any
}

export type TUpdateRoom = {
  title: string
  description?: string
}

export type TStateMediaPreview = {
  mediaId?: string
  url?: string
  time?: number
  nextTime?: number
  paused?: boolean
  volume?: number
  prevVolume?: number
  repeat?: boolean
  name?: string
  duration?: number
  currentTime?: number
}

export type TVideoSessionList = {
  id?: string
  mediaId?: string
  url?: string
  time?: number
  paused?: boolean
  volume?: number
  prevVolume?: number
  repeat?: boolean
  name?: string
  duration?: number
  currentTime?: number
  isSelected?: boolean
  muted?: boolean
  autoToBeginning?: boolean
}

export type TBackgroundMediaData = {
  id?: string
  url?: string
}

export type TResourceInSession = {
  id: string
  position: number
  type: string
  resource?: Resource | null
}

export enum SourceChat {
  INTERNAL = 'chatInternal',
  PUBLIC = 'chatPublic',
}

type TViewMode = Viewmode

const defaultLegacyLayouts = [1, 2, 3, 4, 8]

export type WebrtcStoreOis =
  SearchOutputInSessionQuery['searchOutputInSession'][0] & {
    recording?: ServerToClientNotifyData['mixer-recording-progress']
  }

const _getOutputInSession = async (roomType?: Viewmode) => {
  const S = WebrtcStore.getRootStore()
  const sessionId = S.webrtc.detail?.id
  if (!sessionId) {
    return
  }
  const data = await reduxStore.context.gql.searchOutputInSession(
    {
      sessionId,
      filter: {
        OR: [
          { output_some: { type: 'Recording' }, roomType },
          { output_some: { type_ne: 'Recording' } },
        ],
      },
    },
    { requestPolicy: 'network-only' },
  )
  const ois = data.data?.searchOutputInSession
  if (ois) {
    S.webrtc.setOutputInSession(ois)
  }
}
export const getOutputInSession = debounce(_getOutputInSession, 1000, {
  maxWait: 1000,
  leading: true,
  trailing: true,
})

export type FeInput = Input & {
  audioTrack?: MediaStreamTrack
  videoTrack?: MediaStreamTrack
  hasSlotSmall?: boolean
}
export const checkViewModeSeeMediaControl = (
  viewmode: Viewmode,
  to: Viewmode,
) =>
  to === 'rhost'
    ? viewModeSeeControlMediaHost.includes(viewmode)
    : viewmode === 'robserver'
export const viewModeSeeControlMediaHost: Viewmode[] = [
  'mixer',
  'rhost',
  'rparticipant',
]
export type SessionMode = 'upload' | 'session' | 'note'
export type HlsAudios = { [key: string]: MediaStreamTrack }
export type Fraction = {
  w: number
  h: number
}
