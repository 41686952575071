import clsx from 'clsx'
import { isNumber } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { Moment } from 'moment'
import moment from 'moment'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'

import css from './SessionTime.module.scss'

import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { BeamText } from '#HACK_FOR_RN_ONLY/src/components/base/Text'
import { BeamDropdownSelect } from '#HACK_FOR_RN_ONLY/src/components/DropdownSelect/DropdownSelect'

// import { PlanName } from '../../../context/gql/codegen/codegen'
import { BeamMenu } from '../../../components/base/Menu'
import { DatePicker } from '../../../components/CustomDatePicker/CustomDatePicker'
import { CalendarWithSession } from '../../../pages/SessionManager/components/CalendarWithSession'
import { S } from '../../store'
import { serverTime } from '../../store/shared/serverTime'
import { Helper } from '../../store/studio/helper'

interface ITimeStartOption {
  hours: number
  minutes: number
  value: number
}
// interface IDurationOption {
//   name: string
//   value: number
// }

export const getStartTimesDefault = () => {
  const startTimes: ITimeStartOption[] = []
  for (let i = 0; i < 24; i++) {
    startTimes.push(
      { hours: i, minutes: 0, value: i * 60 },
      { hours: i, minutes: 30, value: i * 60 + 30 },
    )
  }

  return startTimes
}
export const getStartTimes = (option: ITimeStartOption) => {
  let startTimes = getStartTimesDefault()

  const startTimeSelected = startTimes.find(t => {
    if (t.hours === option.hours && t.minutes === option.minutes) {
      return true
    } else {
      return false
    }
  })

  startTimes = startTimes.slice(
    startTimes.findIndex(s => s === startTimeSelected),
  )
  return startTimes
}
// const getDurations = () => {
//   const durations: IDurationOption[] = []
//   for (let i = 0; i <= 24; i++) {
//     if (i === 24) {
//       durations.push({
//         name: `${i ? (i > 1 ? i + ' hrs' : i + ' hr') : ''}`,
//         value: i * 60,
//       })
//     } else {
//       durations.push(
//         {
//           name: `${i ? (i > 1 ? i + ' hrs' : i + ' hr') : ''} 15 mins`,
//           value: i * 60 + 15,
//         },
//         {
//           name: `${i ? (i > 1 ? i + ' hrs' : i + ' hr') : ''} 30 mins`,
//           value: i * 60 + 30,
//         },
//         {
//           name: `${i ? (i > 1 ? i + ' hrs' : i + ' hr') : ''} 45 mins`,
//           value: i * 60 + 45,
//         },
//       )
//     }
//   }
//   return durations
// }
// const durations: IDurationOption[] = getDurations()
// const durationsForBaseSubscription: IDurationOption[] = [
//   {
//     name: '15 mins',
//     value: 15,
//   },
//   {
//     name: '30 mins',
//     value: 30,
//   },
//   {
//     name: '40 mins',
//     value: 40,
//   },
// ]

interface IScheduleTimeProps {
  startDate: Date
  endDate: Date
  startTimeError: string
}
interface IProps extends IScheduleTimeProps {
  setTime(value: Partial<IScheduleTimeProps>): void
}
export const SessionTime: FC<IProps> = observer(
  ({ startDate, endDate, setTime, startTimeError }) => {
    const nowM = serverTime.moment()
    const { isLightTheme } = S.local
    // const { currentPlan } = S.profile
    const [startValue, setStartValue] = useState('')
    const startDateMoment = moment(startDate)
    const isToday = startDateMoment.isSame(nowM, 'day')
    const duration = useMemo(
      () => Helper.diffMinutes(startDate, endDate),
      [startDate.getTime(), endDate.getTime()],
    )
    const now = nowM.toDate()
    // const durationsData =
    //   currentPlan?.plan !== PlanName.Freemium
    //     ? durations
    //     : durationsForBaseSubscription

    // const onChangeDuration = (d: IDurationOption) => {
    //   const hMore = Math.floor(d.value / 60)
    //   const mMore = d.value - hMore * 60
    //   const endD = new Date(
    //     startDate.getFullYear(),
    //     startDate.getMonth(),
    //     startDate.getDate(),
    //     startDate.getHours() + hMore,
    //     startDate.getMinutes() + mMore,
    //     0,
    //     0,
    //   )

    //   setTime({ endDate: endD })
    // }

    // const menuDuration = (
    //   <Menu className={isLightTheme ? 'menu-style-light' : 'menu-style'}>
    //     {durationsData.map(d => {
    //       return (
    //         <Menu.Item key={d.name} onClick={() => onChangeDuration(d)}>
    //           <div className={css.MenuDropdownItem}>
    //             <BeamText
    //               containerProps={{ classNameContainer: 'flex-1' }}
    //               color='#ffffff'
    //             >
    //               {d.name}
    //             </BeamText>{' '}
    //             {d.value === duration && <img src={IconCheck} />}
    //           </div>
    //         </Menu.Item>
    //       )
    //     })}
    //   </Menu>
    // )

    const onChangeDateSelected = (date: Moment | null) => {
      if (date) {
        const newDate = moment(date).toDate()
        let mins = 0
        let hours = 0
        if (date.isSame(nowM, 'day')) {
          mins = now.getMinutes()
          hours = now.getHours()
        }
        const sd = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          hours,
          mins,
          0,
          0,
        )
        const ed = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          hours,
          mins + duration,
          0,
          0,
        )
        setTime({ startDate: sd, endDate: ed })
      }
    }

    const getStartTimeInputValue = (valueInput: string) => {
      const valueTrim = valueInput.trim()
      if (valueTrim.length === 5) {
        const stringArray = valueTrim.split(':')
        const h = Number(stringArray[0])
        const m = Number(stringArray[1])
        if (
          stringArray.length === 2 &&
          stringArray[0].length === 2 &&
          isNumber(h) &&
          isNumber(m) &&
          Number(h) <= 23 &&
          Number(m) <= 59
        ) {
          return {
            hours: h,
            minutes: m,
            value: h * 60 + m,
          }
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    }
    const onChangeStartTimeValue = (value: string) => {
      setStartValue(value)
    }
    const startTimeValue = useMemo(() => {
      const hours = startDate.getHours()
      const minutes = startDate.getMinutes()
      return {
        hours,
        minutes,
        value: hours * 60 + minutes,
      }
    }, [startDate.getTime()])

    const dateOptionDefault = useMemo(() => {
      if (isToday) {
        const hour = now.getHours()
        const min = now.getMinutes()
        return {
          hours: min > 30 ? (hour + 1 === 24 ? 0 : hour + 1) : hour,
          minutes: min > 30 ? 0 : 30,
          value: hour * 60 + min > 30 ? 30 : 0,
        }
      }
      return {
        hours: 0,
        minutes: 0,
        value: 0,
      }
    }, [startDate.getTime()])
    const getStartTimeInput = (timeOption: ITimeStartOption) =>
      `${
        timeOption.hours >= 10 ? `${timeOption.hours}` : `0${timeOption.hours}`
      }:${
        timeOption.minutes > 10 ? timeOption.minutes : `0${timeOption.minutes}`
      }`

    useEffect(() => {
      setStartValue(getStartTimeInput(startTimeValue))
    }, [startDate.getTime()])

    const startTimes = getStartTimes(dateOptionDefault)
    const handleChangeStartDate = (startOption: ITimeStartOption) => {
      setTime({ startTimeError: '' })
      const start = startDate
      start.setHours(startOption.hours)
      start.setMinutes(startOption.minutes)
      const end = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        start.getMinutes() + duration,
        0,
        0,
      )
      setTime({ startDate: start, endDate: end })
    }

    const menu = () => (
      <BeamMenu className={isLightTheme ? 'menu-style-light' : 'menu-style'}>
        {startTimes.map((t, index) => (
          <BeamMenu.Item
            key={index + ''}
            onClick={() => handleChangeStartDate(t)}
          >
            <div className={css.MenuDropdownItem}>
              <BeamText
                containerProps={{ classNameContainer: 'flex-1' }}
                color={isLightTheme ? '#0F0F0F' : '#ffffff'}
              >
                {t.hours >= 10 ? `${t.hours}` : `0${t.hours}`}:
                {t.minutes ? t.minutes : '00'}&nbsp;
              </BeamText>
              {t.value === startTimeValue.value ? (
                <Icon
                  icon='icon_check'
                  size={14}
                  className={clsx(css.CheckedIcon, {
                    [css.Dark]: !isLightTheme,
                  })}
                />
              ) : null}
            </div>
          </BeamMenu.Item>
        ))}
      </BeamMenu>
    )

    const onChangeStartTimeFromInput = () => {
      setTime({ startTimeError: '' })
      const newValue: ITimeStartOption | undefined =
        getStartTimeInputValue(startValue)
      if (!newValue) {
        setTime({ startTimeError: 'Wrong time format' })

        return
      }
      const { hours, minutes } = newValue

      if (
        isToday &&
        (hours < now.getHours() ||
          (hours <= now.getHours() && minutes <= now.getMinutes()))
      ) {
        setTime({ startTimeError: 'Must be bigger current time' })
        return
      }

      const startD = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        hours,
        minutes,
        0,
        0,
      )
      const endD = new Date(
        startD.getFullYear(),
        startD.getMonth(),
        startD.getDate(),
        startD.getHours(),
        minutes + duration,
        0,
        0,
      )
      setTime({ startDate: startD, endDate: endD })
    }
    return (
      <div className={css.TimeSelect}>
        <div className={css.TimeSelectWrapper}>
          <DatePicker
            className={css.scheduleDatePicker}
            textDisable
            prefixInput={{
              content: isToday ? 'Today' : '',
            }}
            calenderProps={{
              onChange: date => {
                onChangeDateSelected(date)
              },
              onPanelChange: d => {
                onChangeDateSelected(d)
              },
              onSelect: d => {
                onChangeDateSelected(d)
              },
              value: startDateMoment ?? nowM,
            }}
            disabledDate={date => (nowM.diff(date, 'day') <= 0 ? false : true)}
            value={startDateMoment ?? nowM}
            format={'dddd, MMMM DD, YYYY'}
            inputReadOnly
            componentCalendar={CalendarWithSession}
          />
        </div>
        <div className={css.TimeSelectItem}>
          <BeamDropdownSelect
            overlay={menu}
            label='Start time: &nbsp;'
            Icon={<Icon icon='Clock' size={18} className={css.IconColor} />}
            useWarmness={false}
            inputProps={{
              onBlur: onChangeStartTimeFromInput,
              onFocus: () => setTime({ startTimeError: '' }),
            }}
            inputError={startTimeError}
            inputValue={startValue}
            onChangeInputValue={onChangeStartTimeValue}
            getPopupContainer={undefined}
          />
        </div>

        {/*
        //TODO: Working in future
         <div className={css.TimeSelectItem}>
          <BeamDropdownSelect
            overlay={menuDuration}
            label='Duration: &nbsp;'
            Icon={<Icon icon='Dropdown' size={18}  />}
            inputError={false}
            inputProps={{
              disabled: true,
            }}
            inputValue={
              durationsData.find(d => d.value === duration)?.name ?? ''
            }
            useWarmness={false}
            getPopupContainer={undefined}
          />
        </div> */}
      </div>
    )
  },
)
