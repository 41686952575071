import { memo } from 'react'
import type { CountdownProps } from 'react-countdown'
import RcCountdown from 'react-countdown'

import css from './CountDown.module.scss'

import { Button } from '../../../components/base/Button'

export type ICountDownProps = CountdownProps & {
  description: string
  onSubmit?: () => void
  buttonLabel?: string
  onClickButton?: () => void
  isLightTheme?: boolean
}

export const CountDown = memo((props: ICountDownProps) => {
  const {
    onSubmit,
    onClickButton,
    description,
    buttonLabel,
    ...countDownProps
  } = props

  return (
    <div className={css.CountDownWrapper}>
      <div className={css.CountDownContainer}>
        <div>
          <p className={css.desc}>{description}</p>
        </div>
        <RcCountdown
          {...countDownProps}
          renderer={
            countDownProps.renderer
              ? countDownProps.renderer
              : c => <p className={css['count-down-label']}>{c.seconds}</p>
          }
          onComplete={t => {
            countDownProps?.onComplete?.(t)
            onSubmit?.()
          }}
        />
        <div>
          <Button
            className={css.button}
            onClick={onClickButton}
            containerType='grey'
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
})
