declare function createTFLiteSIMDModule(): Promise<TFLite>

export interface TFLite {
  HEAPU8: Uint8Array
  HEAPF32: Float32Array
  _getModelBufferMemoryOffset(): number
  _getInputMemoryOffset(): number
  _getInputHeight(): number
  _getInputWidth(): number
  _getInputChannelCount(): number
  _getOutputMemoryOffset(): number
  _getOutputHeight(): number
  _getOutputWidth(): number
  _getOutputChannelCount(): number
  _loadModel(bufferSize: number): number
  _runInference(): number
}
export const loadTFLiteModel = async () => {
  const tfliteSIMD = await createTFLiteSIMDModule()

  if (!tfliteSIMD) {
    throw new Error('TFLite backend unavailable: wasmSIMD')
  }

  const modelFileName = 'segm_lite_v681'

  const modelResponse = await fetch(
    `${process.env.PUBLIC_URL}/models/${modelFileName}.tflite`,
  )
  const model = await modelResponse.arrayBuffer()

  const modelBufferOffset = tfliteSIMD._getModelBufferMemoryOffset()

  tfliteSIMD.HEAPU8.set(new Uint8Array(model), modelBufferOffset)
  tfliteSIMD._loadModel(model.byteLength)

  return tfliteSIMD
}
