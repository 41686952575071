export const SHORT_CUTS_STREAM = [
  ' ',
  '1',
  '2',
  '3',
  '4',
  '5',
  'c',
  'f',
  'm',
  'p',
  's',
]
