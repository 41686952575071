import clsx from 'clsx'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import css from './style.module.scss'

import type { IRouterLinkProps } from './type'

export const RouterLink: FC<IRouterLinkProps> = props => {
  const { className, ...propsRouterLinks } = props
  return (
    <Link {...propsRouterLinks} className={clsx(css.RouterLink, className)} />
  )
}
