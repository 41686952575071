import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './style.module.scss'

import { Icon } from '../../../../components/base/Icon'
import { Map } from '../../../../components/base/Map'
import { WarmnessGrayButton } from '../../../../components/widget/WarmnessGrayButton'
import { switchSessionMode } from '../../actions/switchSessionMode'
import { createComponent } from './logic'
import type { ButtonMode } from './type'

export const SessionModeButtons = observer(
  createComponent(({ modes, sessionMode }) => (
    <div className={css.ModeButtons}>
      <Map<ButtonMode>
        list={modes}
        renderItem={item => (
          <WarmnessGrayButton
            key={item.type}
            onClick={() => switchSessionMode(item.type)}
            className={clsx(css.Item, {
              [css.ItemSelected]: item.type === sessionMode,
            })}
          >
            <Icon icon={item.icon} className={css.Icon} size={16} />
          </WarmnessGrayButton>
        )}
      />
    </div>
  )),
)
