import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const replaceTranscriptUser = async (
  id: string,
  name: string,
  preName?: string,
  replaceAll?: boolean,
): Promise<boolean | undefined> => {
  const $ = S.insightEngine
  const res = await reduxStore.context.gql.replaceTranscriptUser({
    id,
    name,
    preName,
    replaceAll,
  })
  if (res.error) {
    ToastService.error({ content: 'Replace this transcript failed.' })
    return false
  }
  if (replaceAll) {
    $.transcripts = $.transcripts.map(t => {
      if (t.nameFromRevAi.trim() === preName?.trim()) {
        return { ...t, nameFromRevAi: name }
      }
      return t
    })
    ToastService.success({ content: 'Replace this transcript success.' })
    return true
  }
  $.transcripts = $.transcripts.map(t => {
    if (t.id === id) {
      return { ...t, nameFromRevAi: name }
    }
    return t
  })
  ToastService.success({ content: 'Replace this transcript success.' })
  return true
}
