import type { DropDownProps } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC, InputHTMLAttributes } from 'react'
import { useState } from 'react'

import css from './DropdownSelect.module.scss'

import { S } from '../../context/store'
import { BeamDropdown } from '../base/Dropdown'
import { Icon as BeamIcon } from '../base/Icon'

type BeamDropDownSelectProps = {
  label: string
  onChangeInputValue?: (value: string) => void
  inputValue?: string
  inputError?: boolean | string
  Icon: JSX.Element
  containerClass?: string
  useWarmness?: boolean
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerProps?: React.HTMLAttributes<HTMLDivElement>
} & DropDownProps

export const BeamDropdownSelect: FC<BeamDropDownSelectProps> = observer(
  props => {
    const {
      overlay,
      label,
      onChangeInputValue,
      inputValue,
      inputError,
      Icon,
      containerClass,
      useWarmness = true,
      inputProps,
      containerProps,
      ...dropDownProps
    } = props
    const { isLightTheme, warmnessColor } = S.local
    const [visible, setVisible] = useState(false)
    return (
      <div
        onClick={() => setVisible(true)}
        className={clsx(css.DropdownSelectWrapper, containerClass)}
      >
        <BeamDropdown
          overlay={overlay}
          trigger={['click']}
          visible={visible}
          onVisibleChange={value => {
            if (!value) {
              setVisible(value)
            }
          }}
          containerProps={containerProps}
          placement='top'
          {...dropDownProps}
        >
          <div
            style={{ background: useWarmness ? warmnessColor : undefined }}
            className={clsx(css.Select, {
              [css.selectLight]: isLightTheme,
            })}
            onClick={() => setVisible(false)}
          >
            <span
              className={clsx(css.Label, {
                [css.LabelLight]: isLightTheme,
              })}
            >
              {label}
            </span>
            <input
              onChange={e => {
                onChangeInputValue?.(e.target.value)
              }}
              onClick={() => setVisible(!visible)}
              className={clsx(css.Input, {
                [css.InputLight]: isLightTheme,
              })}
              value={inputValue}
              {...inputProps}
            />
            {inputError &&
              (isLightTheme ? (
                <BeamIcon icon='Shape1' size={14} className={css.ErrorIcon} />
              ) : (
                <BeamIcon icon='Shape' size={14} className={css.ErrorIcon} />
              ))}
            <span
              onClick={e => {
                e.stopPropagation()
                setVisible(!visible)
              }}
              className={clsx(css.IconWrapper, {
                [css.IconLight]: isLightTheme,
              })}
            >
              {Icon}
            </span>
          </div>
        </BeamDropdown>
        {inputError && (
          <span className={css.TextError}>
            {typeof inputError === 'string' ? inputError : 'Wrong time format'}
          </span>
        )}
      </div>
    )
  },
)
