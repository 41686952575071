import Hls from 'hls.js'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import URLParse from 'url-parse'

import { waitTimeout } from '##/shared/waitTimeout'
import { volume0 } from '#rn-shared/mediasoup'

import { reactRunningOnAlpha, reactRunningOnBeta } from '../../../config'
import { S } from '../../../context/store'
import type { FeInput } from '../../../context/store/studio/WebrtcStore'
import { isInputPeer } from '../utils/isInputPeer'
import type { TAudioProps } from './PlayerAudio'
import { Audio } from './PlayerAudio'
import type { TVideoProps } from './PlayerVideo'
import { Video } from './PlayerVideo'

type InputPlayerProps = {
  input: FeInput
  muted: boolean
  volume?: number
  style?: React.CSSProperties
}
export const InputPlayer = observer(
  ({ input, muted, volume, style }: InputPlayerProps) => {
    const r = useRef<HTMLVideoElement | null>(null)
    const [metadataLoaded, setMetadataLoaded] = useState(false)

    useEffect(() => {
      const v = r.current
      if (!input.hasSlotSmall || !v || !metadataLoaded) {
        return
      }
      const s = S.webrtc
      if (
        !s.isViewmodeHost &&
        !s.isViewmodeObserver &&
        !s.isViewmodeParticipant
      ) {
        return
      }
      const stream = v?.captureStream()
      S.webrtc.inputs = S.webrtc.inputs.map(i =>
        i.id === input.id
          ? {
              ...i,
              audioTrack: stream.getAudioTracks()[0],
              videoTrack: stream.getVideoTracks()[0],
            }
          : i,
      )
    }, [metadataLoaded, input.hasSlotSmall])

    useEffect(() => {
      const v = r.current
      let hls: Hls | null = null
      const reset = () => {
        setMetadataLoaded(false)
        hls?.detachMedia()
        hls?.stopLoad()
        input.audioTrack?.stop()
        input.audioTrack = undefined
        input.videoTrack?.stop()
        input.videoTrack = undefined
      }
      const cleanup = () => {
        reset()
        hls?.destroy()
      }
      if (!v) {
        return cleanup
      }
      if (Hls.isSupported()) {
        const h = new Hls({
          xhrSetup: (xhr, url) => {
            // rewrite hls url on alpha/beta for proxied request to lb instance
            if (reactRunningOnAlpha || reactRunningOnBeta) {
              const prefix = new URLParse(input.playUrl).pathname
                .split('/')
                .filter(p => p)
                .map(p => `/${p}`)[0]
              if (!prefix || !prefix.startsWith('/input-')) {
                throw new Error('Invalid hls proxy prefix')
              }
              const u2 = new URLParse(url)
              if (!u2.pathname.startsWith(prefix)) {
                url = u2.set('pathname', prefix + u2.pathname).toString()
              }
            }
            xhr.open('GET', url, true)
          },
        })
        hls = h
        h.on(Hls.Events.ERROR, (e, d) => {
          if (d.type === Hls.ErrorTypes.NETWORK_ERROR) {
            console.log('hls network error, will retry after 5s')
            waitTimeout(5000).then(() => {
              console.log('hls network error, retrying...')
              reset()
              h.loadSource(input.playUrl)
              h.attachMedia(v)
              h.startLoad()
            })
          }
        })
        h.loadSource(input.playUrl)
        h.attachMedia(v)
        h.startLoad()
      } else {
        console.error('can not play hls')
      }
      return cleanup
    }, [])

    useEffect(() => {
      const v = r.current
      if (!v) {
        return
      }
      if (typeof volume === 'number') {
        v.volume = volume
      }
      v.muted = muted
    }, [muted, volume])

    if (!input) {
      return null
    }
    return (
      <video
        ref={r}
        style={{
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          borderRadius: 'inherit',
          ...style,
        }}
        autoPlay
        playsInline
        onLoadedMetadata={() => setMetadataLoaded(true)}
      />
    )
  },
)

export const Player: FC<TPlayerProps> = observer(
  ({
    audioTrack,
    volume,
    showVolumeLevel,
    videoTrack,
    screenshare,
    onResolution,
    muted,
    videoStyle: style,
    id,
    isSlotSmall,
  }) => {
    useEffect(() => {
      if (!id || !isInputPeer(id)) {
        return
      }
      const inputId = id.replace('input-', '')
      const input = S.webrtc.inputs.find(i => i.id === inputId)
      if (!input) {
        return
      }
      if (isSlotSmall) {
        input.hasSlotSmall = true
      }
    }, [id, isSlotSmall])

    const renderVideo = (track?: MediaStreamTrack) => {
      if (!track) {
        return
      }
      return (
        <Video
          id={id}
          videoTrack={track}
          screenshare={screenshare}
          onResolution={onResolution}
          videoStyle={style}
        />
      )
    }
    const renderAudio = (muted2: boolean, track?: MediaStreamTrack) => {
      if (!track) {
        return null
      }
      const volume2 = volume ?? 1
      if (S.webrtc.isViewmodeMixer && (muted2 || volume2 <= volume0)) {
        return null
      }
      return (
        <Audio
          id={id}
          audioTrack={track}
          volume={volume ?? 1}
          muted={muted2}
          showVolumeLevel={showVolumeLevel}
        />
      )
    }

    if (isInputPeer(id)) {
      const inputId = id?.replace('input-', '')
      const s = S.webrtc
      const input = s.inputs.find(i => i.id === inputId)
      if (input?.status !== 'Publishing' || !input?.playUrl) {
        return null
      }
      const renderInput = () => (
        <InputPlayer
          input={input}
          muted={muted}
          volume={volume}
          style={style}
        />
      )
      if (
        !s.isViewmodeHost &&
        !s.isViewmodeObserver &&
        !s.isViewmodeParticipant
      ) {
        return renderInput()
      }
      if (isSlotSmall) {
        return (
          <>
            {renderInput()}
            {renderAudio(true, input.audioTrack)}
          </>
        )
      }
      return <>{renderVideo(input.videoTrack)}</>
    }
    return (
      <>
        {renderVideo(videoTrack)}
        {renderAudio(muted, audioTrack)}
      </>
    )
  },
)
export type TPlayerProps = Omit<Partial<TAudioProps & TVideoProps>, 'muted'> &
  Pick<TAudioProps, 'muted'> & {
    isSlotSmall?: boolean
  }
