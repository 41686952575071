import { observer } from 'mobx-react-lite'
import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { AppSpin } from '../../../components/base/Spin'
import {
  ToastService as NewToastService,
  ToastService,
} from '../../../components/widget/Toast'
import {
  GRADIENTS_WARMNESS_COLOR_GRAY,
  GRADIENTS_WARMNESS_COLOR_THUMBNAIL,
  GRADIENTS_WARMNESS_COLOR_WHITE,
  GRADIENTS_WARMNESS_HOVER,
  GRADIENTS_WARMNESS_PRESSED,
  WARMNESS_SLIDER_HEIGHT,
} from '../../../config'
import { useThemeDetector } from '../../../utils/useDetectTheme'
import { routerPaths } from '../../router/routerPaths'
import { S } from '../../store'
import { Helper } from '../../store/studio/helper'
import { toggleTheme } from '../actions/toggleTheme'
import { updateWarmnessColors } from '../actions/updateWarmnessColors'

export const ThemeProvider: FC<{ children: ReactNode; isRoute?: boolean }> =
  observer(({ children, isRoute = true }) => {
    const { isLightTheme, appLoading } = S.local
    const isDarkThemeBySystem = useThemeDetector()
    const { profile } = S.profile
    const location = isRoute ? useLocation() : undefined
    const isAdmin =
      location?.pathname &&
      (location.pathname.search(routerPaths.ADMIN) >= 0 ||
        location.pathname.search(routerPaths.CLIENT) >= 0)
    const isInsightEngine =
      location?.pathname &&
      location.pathname.search(routerPaths.INSIGHT_ENGINE_ORIGIN) >= 0
    const isOnlyDark = isAdmin
    const isOnlyLight = isInsightEngine ? true : false

    useEffect(() => {
      if (profile?.theme && profile.theme === 'light') {
        toggleTheme(true)
      } else if (profile.theme === 'dark') {
        toggleTheme(false)
      } else {
        if (isDarkThemeBySystem) {
          toggleTheme(false)
        } else {
          toggleTheme(true)
        }
      }
    }, [profile?.theme, isDarkThemeBySystem])
    useEffect(() => {
      if ((isLightTheme && !isOnlyDark && isRoute) || isOnlyLight) {
        ToastService.initIsLightTheme(true)
        NewToastService.initIsLightTheme(true)
        document.body.dataset.theme = 'light'
      } else {
        ToastService.initIsLightTheme(false)
        NewToastService.initIsLightTheme(false)
        document.body.dataset.theme = 'dark'
      }
    }, [isLightTheme, isOnlyDark, isOnlyLight])
    useEffect(() => {
      if ((isLightTheme && !isOnlyDark) || isOnlyLight) {
        const color = Helper.getWarmnessColor(
          profile?.themeWarmness ?? 0,
          GRADIENTS_WARMNESS_COLOR_WHITE,
          WARMNESS_SLIDER_HEIGHT,
        )
        const colorGray = Helper.getWarmnessColor(
          profile?.themeWarmness ?? 0,
          GRADIENTS_WARMNESS_COLOR_GRAY,
          WARMNESS_SLIDER_HEIGHT,
        )
        const colorThumbnail = Helper.getWarmnessColor(
          profile?.themeWarmness ?? 0,
          GRADIENTS_WARMNESS_COLOR_THUMBNAIL,
          WARMNESS_SLIDER_HEIGHT,
        )
        const colorPressed = Helper.getWarmnessColor(
          profile?.themeWarmness ?? 0,
          GRADIENTS_WARMNESS_PRESSED,
          WARMNESS_SLIDER_HEIGHT,
        )
        const colorHover = Helper.getWarmnessColor(
          profile?.themeWarmness ?? 0,
          GRADIENTS_WARMNESS_HOVER,
          WARMNESS_SLIDER_HEIGHT,
        )
        updateWarmnessColors(
          color,
          colorGray,
          colorThumbnail,
          colorPressed,
          colorHover,
        )
      } else {
        updateWarmnessColors(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        )
      }
    }, [profile?.themeWarmness, isLightTheme, isOnlyLight])

    return (
      <div className='app-provider'>
        {appLoading && (
          <div className='app-loading'>
            <AppSpin />
          </div>
        )}
        {children}
      </div>
    )
  })
