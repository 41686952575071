import type { FC } from 'react'
import { useState } from 'react'

import css from './ModalDeleteVideoIE.module.scss'

import { Button } from '../../../components/base/Button'
import { BeamText } from '../../../components/base/Text'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'

export interface IModalDeleteVideoIE {
  onDelete: () => Promise<void>
}

export const ModalDeleteVideoIE: FC<IModalDeleteVideoIE> = ({ onDelete }) => {
  const [loading, setLoading] = useState(false)
  const cancelHandler = () => {
    ModalService.hide()
  }
  const deleteHandler = async () => {
    setLoading(true)
    await onDelete()

    setLoading(false)

    ModalService.hide()
  }
  return (
    <ModalContent>
      <div className={css.Wrapper}>
        <BeamText size='large1' className={css.Title}>
          Delete Insight Engine Video
        </BeamText>
        <div className={css.Content}>
          <BeamText>Do you want to delete this video?</BeamText>
          <div className={css.Action}>
            <Button onClick={cancelHandler} containerType='grey'>
              Cancel
            </Button>
            <Button
              onClick={deleteHandler}
              containerType='danger'
              loading={loading}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </ModalContent>
  )
}
