import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import GoogleMeetImg from '../../../../assets/images/destination/GoogleMeet.png'
import MSTeamImg from '../../../../assets/images/destination/MicrosoftTeams.png'
import ZoomImg from '../../../../assets/images/destination/Zoom.png'
// eslint-disable-next-line css-modules/no-unused-class
import css from './style.module.scss'

import { Button } from '../../../../components/base/Button'
import { Form } from '../../../../components/base/Form'
import { FormItem } from '../../../../components/base/FormItem'
import { Icon } from '../../../../components/base/Icon'
import { BeamText } from '../../../../components/base/Text'
import { BeamInput } from '../../../../components/widget/Input'
import { createComponent } from './logic'

export const UploadTranscript = observer(
  createComponent(
    ({
      sessionMode,
      handleCheckUploadStorage,
      uploadFile,
      inputId,
      recordBotName,
      form,
      addHandler,
      loading,
      selectFile,
      statusUpload,
      percentProgress,
    }) => (
      <div
        className={clsx(css.UploadTranscript, {
          [css.UploadTranscriptHide]: sessionMode !== 'upload',
        })}
      >
        <div className={css.UploadVideo}>
          <div className={css.UploadVideoLeft}>
            <div className={css.Row}>
              <BeamText className={css.Title}>Upload a video</BeamText>{' '}
              {statusUpload && (
                <span className={css.Uploading}>
                  Uploading...{percentProgress}%
                </span>
              )}
            </div>
            <BeamText className={css.Description}>
              Upload videos and transcribe
            </BeamText>
          </div>
          <Button disabled={statusUpload} onClick={selectFile}>
            <div className={css.Row}>
              <Icon className={css.IconButton} icon='Upload' size={16} />
              Upload
            </div>
          </Button>
          <input
            id={inputId}
            accept={'video/mp4,video/x-m4v,video/*'}
            type='file'
            value=''
            onChange={handleCheckUploadStorage}
            ref={uploadFile}
            multiple
          />
        </div>
        <div className={css.divider} />
        <div className={css.UploadMeeting}>
          <div className={css.UploadVideo}>
            <div className={css.UploadVideoLeft}>
              <BeamText className={css.Title}>Record a meeting</BeamText>
              <div className={css.Row}>
                <BeamText
                  className={css.Description}
                  containerProps={{ classNameContainer: css.Flex1 }}
                >
                  Please admit {recordBotName} when they join meeting
                </BeamText>

                <img className={css.meetIcon} src={GoogleMeetImg} />
                <img className={css.meetIcon} src={MSTeamImg} />
                <img className={css.meetIcon} src={ZoomImg} />
              </div>
            </div>
          </div>
          <Form className={css.WidthContainer} form={form}>
            <FormItem
              name='meetingUrl'
              rules={[{ required: true, message: 'Please enter meeting link' }]}
            >
              <BeamInput placeholder='Enter meeting link' />
            </FormItem>
          </Form>
          <div className={clsx(css.ModalAction, css.WidthContainer)}>
            <Button
              className={css.confirmBtn}
              onClick={addHandler}
              loading={loading}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    ),
  ),
)
