import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'

import css from './BottomPopoverAudio.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { S } from '../../../context/store'

export const BottomPopoverAudio = observer(() => {
  const { isLightTheme } = S.local
  return (
    <WarmnessDiv
      onMouseDown={(e: MouseEvent) => e.stopPropagation()}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      className={css.WrapperPopoverContent}
    >
      <div
        className={clsx(css.WrapperMic, {
          [css.wrapperMicLight]: isLightTheme,
        })}
      >
        <h5
          className={clsx({
            [css.WrapperPopoverTitle]: true,
            [css.wrapperPopoverTitleDark]: !isLightTheme,
          })}
        >
          Select a Microphone
        </h5>
        <div className={css.WrapperPopoverList}>
          {S.webrtc.mics.map(c => (
            <div
              className={clsx({
                [css.WrapperPopoverItem]: true,
                [css.Selected]: S.webrtc.micId === c.deviceId,
                [css.Dark]: !isLightTheme,
              })}
              key={c.deviceId}
              onClick={() => {
                if (S.webrtc.micId === c.deviceId) {
                  return
                }
                S.webrtc.micId = c.deviceId
                S.webrtc.onSelectedMicrophoneChange()
              }}
            >
              {c.label || c.name || c.deviceId}
              <div className={css.IconCheck}>
                <Icon icon='icon_check' size={16} color='#085AE1' />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={css.WrapperSpeaker}>
        <h5
          className={clsx({
            [css.WrapperPopoverTitle]: true,
            [css.wrapperPopoverTitleDark]: !isLightTheme,
          })}
        >
          Select a Speaker
        </h5>
        <div className={css.WrapperPopoverList}>
          {S.webrtc.speakers.map(c => (
            <div
              className={clsx({
                [css.WrapperPopoverItem]: true,
                [css.Selected]: S.webrtc.speakerId === c.deviceId,
                [css.Dark]: !isLightTheme,
              })}
              key={c.deviceId}
              onClick={() => {
                if (S.webrtc.speakerId === c.deviceId) {
                  return
                }
                S.webrtc.speakerId = c.deviceId
              }}
            >
              {c.label || c.name || c.deviceId}
              <div className={css.IconCheck}>
                <Icon icon='icon_check' size={16} color='#085AE1' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </WarmnessDiv>
  )
})
