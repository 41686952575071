import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { IMuteItem } from '../../../context/store/insight-engine/InsightEngineStore'

export const updateMuteTimeline = (data: Partial<IMuteItem>) => {
  const $ = S.insightEngine
  $.muteTimelines = $.muteTimelines.map(m => {
    if (data.id === m.id) {
      return {
        ...m,
        ...data,
      }
    }
    return m
  })
}

export const updateMuteTimelineApi = (id: string) => {
  const { muteTimelines } = S.insightEngine

  const data = muteTimelines.find(item => item.id === id)
  if (data) {
    reduxStore.context.gql.updateMute({
      id: id ?? '',
      data: {
        startTime: data.startTime,
        endTime: data.endTime,
      },
    })
  }
}
