import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import css from './LiveStreamVideo.module.scss'

import { AppSpin } from '../../../components/base/Spin'
import { S } from '../../../context/store'
import {
  buildControlMediaId,
  controlMediaPrefix,
} from '../../../context/store/studio/WebrtcStore'
import { playTrack } from '../utils/playTrack'

export const LiveStreamVideo = observer(() => <VideoTag />)

const VideoTag = observer(() => {
  const {
    getMediaControlTracks,
    backgroundMediaData,
    mediaControlData,
    viewmode,
    addMediaControlData,
  } = S.webrtc
  const vref = useRef<HTMLVideoElement>(null)
  const videoControl = mediaControlData.find(
    item =>
      item.mediaId.replace(controlMediaPrefix[viewmode], '') ===
        backgroundMediaData?.id && item.from === viewmode,
  )
  const isLoading = Boolean(!videoControl)
  const isSeeking = Boolean(videoControl?.seeking)
  useEffect(() => {
    if (isLoading) {
      addMediaControlData(
        buildControlMediaId(viewmode, backgroundMediaData?.id ?? ''),
        backgroundMediaData?.url ?? '',
        'video',
      )
    }
  }, [isLoading, backgroundMediaData?.id])
  const videoTrack = getMediaControlTracks(
    backgroundMediaData?.id ?? '',
  ).videoTrack
  useEffect(() => {
    if (!videoTrack) {
      return
    }
    playTrack(vref.current, videoTrack)
  }, [videoTrack, backgroundMediaData.id])
  const video = <video className={css.VideoElement} ref={vref} />
  if (!backgroundMediaData.id) {
    return
  }

  return (
    <div className={css.ContainerVideo}>
      {(isLoading || isSeeking) && (
        <div className={css.Loading}>
          <AppSpin />
        </div>
      )}
      {video}
    </div>
  )
})
