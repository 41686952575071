import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './style.module.scss'

import { WarmnessDiv } from '../WarmnessDiv'
import { createComponent } from './logic'

export const PopoverContent = observer(
  createComponent(({ children, style, warmnessColor, className }) => (
    <div
      className={clsx(css.Wrapper, className)}
      style={{
        // @ts-ignore
        '--background-modal1': warmnessColor,
        ...style,
      }}
    >
      <WarmnessDiv className={css.Container}>{children}</WarmnessDiv>
    </div>
  )),
)
