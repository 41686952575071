export const CONSTANTS = {
  fontSize: {
    body1: '13px',
    body2: '12px',
    body3: '10px',
    body4: '14px',
    body6: '16px',
    body5: '20px',
    h1: 44,
    h2: 36,
    h3: 24,
    h4: 20,
    h5: 16,
    h6: 14,
  },
  text: {
    primary: '#4255C5',
    level1: '#E1E1E9',
    level2: '#E1E1E9',
    level3: 'rgba(225, 225, 233, 0.5)',
    level4: 'rgba(225, 225, 233, 0.25)',
    link: '#7589FF',
  },
  status: {
    error: '#FF6565',
    warning: '#FF915A',
    success: '#2FD87C',
  },
  background: {
    level1: 'rgba(23, 23, 29, 1)',
    level2: 'rgba(28, 28, 36, 1)',
    level3: 'rgba(28, 28, 36, 1)',
    popup: ' rgba(0, 4, 12, 1)',
    popupMask: 'rgba(23, 23, 29, 1)',
    darkerLevel1: 'rgba(0, 0, 0, 1)',
    dropdown:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(23, 23, 29, 0.85)',
  },
  card: {
    level2: 'rgba(23, 23, 29, 1)',
  },
  level: {
    lighter1: 'rgba(255, 255, 255, 0.24)',
    lighter2: 'rgba(255, 255, 255, 0.16)',
    lighter3: 'rgba(255, 255, 255, 0.08)',
    ligher4: 'rgba(255, 255, 255, 0.04)',
    dark1: 'rgba(0, 0, 0, 0.72)',
    dark3: 'rgba(0, 0, 0, 0.32)',
    dark4: 'rgba(0, 0, 0, 0.12)',
    dark5: 'rgba(0, 0, 0, 0.08)',
  },
  brandColor: {
    primary1: '#4255C5',
    primary2: '#7589FF',
  },
  basicColor: {
    white: '#FFFFFF',
    gray1: 'rgba(255, 255, 255, 0.08)',
    gray2: 'rgba(225, 225, 233, 0.1)',
    gray3: 'rgba(225, 225, 233, 0.5)',
    gray4: 'rgba(225, 225, 233, 0.75)',
    gray5:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(255, 255, 255, 0.1)',
    gray6:
      ' linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(23, 23, 29, 0.85)',
    gray7:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(23, 23, 29, 0.85)',
    gray8:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgb(23 23 29)',
    black:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), rgba(255, 255, 255, 0.1)',
    sidebar: 'rgba(23, 23, 29, 0.85)',
    backgroundMain: 'rgba(23, 23, 29, 0.85)',
    danger1: 'rgba(255, 101, 101, 1)',
    danger2:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FF6565',
    modalBackground: '#1c1d24',
    maskBackgroundModal: 'rgba(0, 4, 12, 0.9)',
  },
  border: {
    border1: '1px solid rgba(255, 255, 255, 0.16)',
    border2: '1px solid rgba(255, 255, 255, 0.1)',
    border3: '1px solid rgba(255, 255, 255, 0.25)',
    border4: '1px solid rgba(255, 255, 255, 0.08)',
  },
  statusColor: {
    success: 'rgba(47, 216, 124, 1)',
    warning: 'rgba(255, 145, 90, 1)',
    danger: 'rgba(255, 101, 101, 1)',
  },
}
