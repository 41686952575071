import clsx from 'clsx'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'

import circle from '../../../assets/images/rouned.png'
import square from '../../../assets/images/scale_square.svg'
import css from './CustomLayoutReszieOutside.module.scss'

import { Icon } from '../../../components/base/Icon'
import { routerPaths } from '../../router/routerPaths'
import { S } from '../../store'
import type { IActionOutsize, ISlotAction } from '../utils/CustomLayoutType'
import { EDirection } from '../utils/CustomLayoutType'
import { AlignmentIndicator } from './CustomLayoutAlignmentIndicatorLines'
import { RADIUS_RATIO } from './CustomLayoutConfig'
import { MeasureLayout } from './CustomLayoutMeasureLayout'
import {
  CropMode,
  ResizeBlurActionTop,
  ResizeOutSizeSingleActionRight,
  ResizeOutSizeSingleActionTop,
} from './CustomLayoutResizeOutsizeSingleActions'

const resizeActions: ISlotAction[] = [
  {
    className: css.ScaleButtonTopLeft,
    direction: EDirection.TOP_LEFT,
  },
  {
    className: css.ScaleButtonTopRight,
    direction: EDirection.TOP_RIGHT,
  },
  {
    className: css.ScaleButtonBottomLeft,
    direction: EDirection.BOTTOM_LEFT,
  },
  {
    className: css.ScaleButtonBottomRight,
    direction: EDirection.BOTTOM_RIGHT,
  },
]

const resizeEdges: ISlotAction[] = [
  {
    className: css.ScaleEdgeTop,
    direction: EDirection.TOP,
  },
  {
    className: css.ScaleEdgeLeft,
    direction: EDirection.LEFT,
  },
  {
    className: css.ScaleEdgeBottom,
    direction: EDirection.BOTTOM,
  },
  {
    className: css.ScaleEdgeRight,
    direction: EDirection.RIGHT,
  },
]
const roundedActions: ISlotAction[] = [
  {
    className: css.RoundedButtonTopLeft,
    direction: EDirection.TOP_LEFT,
  },
  {
    className: css.RoundedButtonTopRight,
    direction: EDirection.TOP_RIGHT,
  },
  {
    className: css.RoundedButtonBottomLeft,
    direction: EDirection.BOTTOM_LEFT,
  },
  {
    className: css.RoundedButtonBottomRight,
    direction: EDirection.BOTTOM_RIGHT,
  },
]

interface IOutSizeItemProps {
  id: string
  x: number
  y: number
  width: number
  height: number
  radius: number
  show?: boolean
  isMulti?: boolean
  actionsTop?: IActionOutsize[]
  customTopElement?: React.ReactElement
  actionsLeft?: IActionOutsize[]
  onChangeSize?: (width: number, height: number) => void
  onResizeDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onResizeDrag?: (e: React.DragEvent<HTMLDivElement>, d: EDirection) => void
  onResizeDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void
  onRoundedDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onRoundedDrag?: (e: React.DragEvent<HTMLDivElement>, d: EDirection) => void
  onRoundedDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void
  onMouseEnter?(e: React.MouseEvent): void
  onMouseLeave?(e: React.MouseEvent): void
  setRoundedHandling?: (d: EDirection | undefined) => void
  roundedHandling?: EDirection
  isDragging?: boolean
  isResizing?: boolean
  cropMode?: boolean
  disableDrag?: boolean
  isVideoOverlay?: boolean
  parentId?: number
  disableResizeInput?: boolean
  showAlignmentIndicator?: boolean
  showResizeBtn?: boolean
  showRoundedBtn?: boolean
  showResizeEdeges?: boolean
  showSizeInfo?: boolean
  isEdgesHorizontalOnly?: boolean
  alignTopAction?: 'left' | 'center'
}
type Ratio = {
  w: number
  h: number
}
export const ReSizeOutSize = () => {
  const { props, ratio } = useResizeOutsideHandler()
  return (
    <>
      {props.map(item => (
        <ReSizeOutSizeItem ratio={ratio} item={item} key={item.id} />
      ))}
    </>
  )
}

export const ReSizeOutSizeItem: FC<{ item: IOutSizeItemProps; ratio: Ratio }> =
  observer(({ item, ratio }) => {
    const [showChangeSize, setShowChangeSize] = useState(false)
    const [sizeInfo, setSizeInfo] = useState({ width: 0, height: 0 })
    const { pathname } = useLocation()
    const {
      height,
      radius,
      width,
      x,
      y,
      isMulti,
      onChangeSize,
      onResizeDrag,
      onResizeDragEnd,
      onResizeDragStart,
      onRoundedDrag,
      onRoundedDragEnd,
      onRoundedDragStart,
      roundedHandling,
      setRoundedHandling,
      actionsTop,
      customTopElement,
      actionsLeft,
      show,
      isResizing,
      isDragging,
      cropMode,
      disableDrag = false,
      isVideoOverlay,
      disableResizeInput,
      showAlignmentIndicator = true,
      showResizeBtn = true,
      showRoundedBtn = true,
      showResizeEdeges = true,
      showSizeInfo = true,
      isEdgesHorizontalOnly = false,
      alignTopAction,
    } = item
    const sizeInfoRef = useRef({ width: 0, height: 0 })
    const initSize = () => {
      const si = {
        width: width * ratio.w,
        height: height * ratio.h,
      }
      setSizeInfo(si)
      sizeInfoRef.current = si
    }
    useEffect(() => {
      initSize()
    }, [height, width])
    const handleSaveSizeInfo = () => {
      if (showChangeSize) {
        const size = sizeInfoRef.current
        onChangeSize?.(size.width / ratio.w, size.height / ratio.h)
        setShowChangeSize(false)
      }
    }

    const handleCloseSizeInfo = () => {
      if (showChangeSize) {
        initSize()
        setShowChangeSize(false)
      }
    }
    const handleKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Enter':
          handleSaveSizeInfo()
          break
        case 'Escape':
          handleCloseSizeInfo()
          break
        default:
          break
      }
    }
    useEffect(() => {
      if (onChangeSize && showChangeSize && show) {
        addEventListener('keydown', handleKeyPress)
      } else {
        removeEventListener('keydown', handleKeyPress)
      }
      return () => removeEventListener('keydown', handleKeyPress)
    }, [onChangeSize, showChangeSize, show])
    if (!show) {
      return null
    }

    const getRoundedButtonPosition = (direction: EDirection) => {
      const position: CSSProperties = {}
      const { width: cw, height: ch, radius: cr } = item
      const radioHeight = Math.ceil(ch * RADIUS_RATIO) / 50
      const radioWidth = Math.ceil(cw * RADIUS_RATIO) / 50
      const t = radioHeight * cr + 14 + y
      const l = radioWidth * cr + x + 14
      const r = cw + x - (radioWidth * cr + 14) - 16
      const b = ch + y - (radioHeight * cr + 14) - 20
      switch (direction) {
        case EDirection.TOP_LEFT:
          position.top = t
          position.left = l
          break
        case EDirection.TOP_RIGHT:
          position.top = t
          position.left = r
          break
        case EDirection.BOTTOM_LEFT:
          position.top = b
          position.left = l
          break
        case EDirection.BOTTOM_RIGHT:
          position.top = b
          position.left = r
          break
        default:
          break
      }
      return position
    }
    const renderRoundedButtons = () =>
      roundedActions.map(a => (
        <div
          key={a.direction}
          className={clsx(css.ScaleButton, a.className)}
          style={{
            top: getRoundedButtonPosition(a.direction).top,
            left: getRoundedButtonPosition(a.direction).left,
            bottom: getRoundedButtonPosition(a.direction).bottom,
            right: getRoundedButtonPosition(a.direction).right,
          }}
          draggable
          onDragStart={onRoundedDragStart}
          onDrag={e => {
            setRoundedHandling?.(a.direction)
            onRoundedDrag?.(e, a.direction)
          }}
          onMouseDown={e => e.stopPropagation()}
          onDragEnd={e => {
            setRoundedHandling?.(undefined)
            onRoundedDragEnd?.(e)
          }}
          onDragOver={e => e.preventDefault()}
        >
          <img src={circle} />
        </div>
      ))
    const renderResizeButtons = () => {
      const getStyle = (d: EDirection) => {
        switch (d) {
          case EDirection.TOP_LEFT:
            return {
              top: y - 11 - 8,
              left: x - 9 - 8,
            }
          case EDirection.TOP_RIGHT:
            return {
              top: y - 11 - 8,
              left: x - 7 - 8 + width,
            }
          case EDirection.BOTTOM_LEFT:
            return {
              top: y + height - 11 - 8,
              left: x - 9 - 8,
            }
          case EDirection.BOTTOM_RIGHT:
            return {
              top: y + height - 11 - 8,
              left: x + width - 7 - 8,
            }
          default:
            return undefined
        }
      }
      return resizeActions.map(a => (
        <div
          key={a.direction}
          id='SlotCustom'
          style={{ ...getStyle(a.direction), padding: 8 }}
          className={clsx(css.ScaleButton, a.className)}
          draggable={!isDragging}
          onDragStart={e => {
            e.stopPropagation()
            onResizeDragStart?.(e)
          }}
          onMouseDown={e => e.stopPropagation()}
          onDrag={e => onResizeDrag?.(e, a.direction)}
          onDragEnd={onResizeDragEnd}
          onDragOver={e => e.preventDefault()}
        >
          <img src={square} />
        </div>
      ))
    }
    const renderResizeEdeges = () => {
      const getStyle = (d: EDirection) => {
        switch (d) {
          case EDirection.TOP:
            return {
              top: y - 2 - 8,
              left: x - 2,
              width: width + 4,
              height: 12,
            }
          case EDirection.LEFT:
            return {
              top: y,
              left: x - 2 - 8,
              height,
            }
          case EDirection.BOTTOM:
            return {
              top: y + height - 8,
              left: x - 2,
              width: width + 4,
            }
          case EDirection.RIGHT:
            return {
              top: y,
              left: x + width - 8,
              height,
            }
          default:
            return undefined
        }
      }
      return resizeEdges.map(a =>
        isEdgesHorizontalOnly &&
        (a.direction === EDirection.BOTTOM ||
          a.direction === EDirection.TOP) ? null : (
          <div
            className={css.ScaleButton}
            key={a.direction}
            id='SlotCustom'
            onMouseDown={e => e.stopPropagation()}
            style={getStyle(a.direction)}
          >
            <div
              draggable={!isDragging}
              onDragStart={e => {
                e.stopPropagation()
                onResizeDragStart?.(e)
              }}
              onMouseDown={e => e.stopPropagation()}
              className={clsx(a.className, {
                [css.transparenEdge]: isEdgesHorizontalOnly,
              })}
              onDrag={e => {
                onResizeDrag?.(e, a.direction)
              }}
              onDragEnd={onResizeDragEnd}
              onDragOver={e => e.preventDefault()}
            ></div>
          </div>
        ),
      )
    }
    const rRatio = radius / 100
    const shouldShowRoundedButton = width > 145 && height > 165 && !isMulti
    const roundedInfoPosition = {
      top: roundedHandling
        ? (getRoundedButtonPosition(roundedHandling).top as number) - 32
        : undefined,
      left: roundedHandling
        ? (getRoundedButtonPosition(roundedHandling).left as number) - 32
        : undefined,
    }

    const calculateSize = () => {
      const { customRatio } = S.webrtc
      if (customRatio === 16 / 9) {
        return {
          width: 1920,
          height: 1080,
        }
      } else if (customRatio > 16 / 9) {
        return {
          width: 1920,
          height: Math.floor(1920 / customRatio),
        }
      } else if (customRatio < 16 / 9) {
        return {
          width: Math.floor(1080 * customRatio),
          height: 1080,
        }
      }
      return {
        width: 1920,
        height: 1080,
      }
    }
    return (
      <>
        {!isDragging && !isResizing && <MeasureLayout />}
        {(isDragging || isResizing) &&
          !isMulti &&
          !isVideoOverlay &&
          showAlignmentIndicator && <AlignmentIndicator />}
        {(!isDragging || isMulti) && (
          <>
            {!isVideoOverlay && showResizeEdeges && renderResizeEdeges()}
            {!isVideoOverlay && showResizeBtn && renderResizeButtons()}
            {!isVideoOverlay &&
              showRoundedBtn &&
              !disableDrag &&
              shouldShowRoundedButton &&
              renderRoundedButtons()}
            {!isVideoOverlay && roundedHandling && (
              <div className={css.roundedInfo} style={roundedInfoPosition}>
                Radius {Math.floor(rRatio * Math.min(width, height))}
              </div>
            )}

            {!cropMode && actionsTop?.length && !isResizing && (
              <div id={item.id}>
                {pathname.search(routerPaths.INSIGHT_ENGINE_ORIGIN) >= 0 ? (
                  <ResizeBlurActionTop
                    height={height}
                    width={width}
                    x={x}
                    y={y}
                    actions={actionsTop}
                  />
                ) : (
                  <ResizeOutSizeSingleActionTop
                    height={height}
                    align={alignTopAction}
                    width={width}
                    customElement={customTopElement}
                    x={x}
                    y={y}
                    actions={actionsTop}
                  />
                )}
              </div>
            )}
            {cropMode && !isDragging && !isVideoOverlay && (
              <CropMode
                height={height}
                width={width}
                x={x}
                y={y}
                actions={actionsTop}
              />
            )}

            {!cropMode &&
              actionsLeft?.length &&
              !isResizing &&
              !isVideoOverlay && (
                <ResizeOutSizeSingleActionRight
                  height={height}
                  width={width}
                  x={x}
                  y={y}
                  actions={actionsLeft}
                />
              )}

            {!isVideoOverlay && showSizeInfo && (
              <div
                id='SlotCustom'
                style={{
                  top: y + height,
                  width,
                  left: x,
                  marginTop: 12,
                  zIndex: 1005,
                }}
                className={css.SlotActionsOutSide}
              >
                {showChangeSize && !disableDrag ? (
                  <div className={css.EditSizeContainer}>
                    <div className={css.EditSizeLeft}>
                      <div className={css.EditRowInfo}>
                        <span className={css.EditSizeLeftTy}>W:</span>
                        <input
                          max={calculateSize().width}
                          min={0}
                          onMouseDown={e => e.stopPropagation()}
                          onChange={e => {
                            const w = Number(e.target.value)
                            const wv =
                              _.isNumber(w) && !isNaN(w)
                                ? w > calculateSize().width
                                  ? calculateSize().width
                                  : w
                                : sizeInfo.width
                            setSizeInfo({
                              ...sizeInfo,
                              width: wv,
                            })
                            sizeInfoRef.current.width = wv
                          }}
                          value={Math.round(sizeInfo.width)}
                        />
                      </div>
                      <div className={css.divider} />
                      <div className={css.EditRowInfo}>
                        <span className={css.EditSizeLeftTy}>H:</span>
                        <input
                          max={calculateSize().height}
                          min={0}
                          onMouseDown={e => e.stopPropagation()}
                          onChange={e => {
                            const h = Number(e.target.value)

                            const hv =
                              _.isNumber(h) && !isNaN(h)
                                ? h > calculateSize().height
                                  ? calculateSize().height
                                  : h
                                : sizeInfo.height
                            setSizeInfo({
                              ...sizeInfo,
                              height: hv,
                            })
                            sizeInfoRef.current.height = hv
                          }}
                          value={Math.round(sizeInfo.height)}
                        />
                      </div>
                    </div>
                    <div
                      onClick={handleSaveSizeInfo}
                      onMouseDown={e => e.stopPropagation()}
                      className={css.EditSizeButton}
                    >
                      <Icon
                        icon='icon_check'
                        size={10}
                        className={css.ResizeOutsideIcon}
                      />
                    </div>
                    <div
                      onClick={handleCloseSizeInfo}
                      onMouseDown={e => e.stopPropagation()}
                      className={css.EditSizeButton}
                    >
                      <Icon
                        icon='icon_close_popup'
                        size={10}
                        className={css.ResizeOutsideIcon}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      !disableResizeInput && setShowChangeSize(true)
                    }
                    className={css.SlotTopSizeInfo}
                    onMouseDown={e => e.stopPropagation()}
                  >
                    {Math.round(sizeInfo.width)}x{Math.round(sizeInfo.height)}
                  </div>
                )}
              </div>
            )}
            <div
              id='SlotCustom'
              style={{
                top: y,
                height,
                left: x + width,
                marginRight: -12,
                width: 12,
              }}
              className={css.SlotActionsOutSide}
            />
          </>
        )}
      </>
    )
  })
export const createOutSideHandler = () => {
  let subscriberSetProps: ((data: IOutSizeItemProps[]) => void) | undefined
  let subscriberSetRatioProps: ((data: Ratio) => void) | undefined
  let outSideProps: IOutSizeItemProps[] = []
  let ratioProp: Ratio = { w: 1, h: 1 }
  const setOutSideProps = (
    previousStateCallback: (data: IOutSizeItemProps[]) => IOutSizeItemProps[],
  ) => {
    outSideProps = previousStateCallback(outSideProps)
    subscriberSetProps && subscriberSetProps(outSideProps)
  }
  const setRatioProp = (previousStateCallback: (data: Ratio) => Ratio) => {
    ratioProp = previousStateCallback(ratioProp)
    subscriberSetRatioProps && subscriberSetRatioProps(ratioProp)
  }

  const changeRatioProp = (data: Ratio) => {
    setRatioProp(r => ({ ...r, ...data }))
  }

  const changeOutSideProps = (data: Partial<IOutSizeItemProps>) => {
    setOutSideProps((prev: IOutSizeItemProps[]) => {
      const isExit = prev.find(i => i.id === data.id)
      if (isExit) {
        const newProps = prev.map(p => {
          if (p.id === data.id) {
            return {
              ...p,
              ...data,
            }
          }
          return p
        })
        return newProps
      }
      return [...prev, data as IOutSizeItemProps]
    })
  }

  const removeOutSideItems = (ids?: string[]) => {
    setOutSideProps((prev: IOutSizeItemProps[]) =>
      ids?.length ? prev.filter(i => !ids?.some(id => id === i.id)) : [],
    )
  }
  const clearItems = (id?: number) => {
    setOutSideProps((prev: IOutSizeItemProps[]) =>
      id ? prev.filter(i => i.parentId === id) : [],
    )
  }
  const use = () => {
    const [props, setProps] = useState<IOutSizeItemProps[]>([])
    const [ratio, setRatio] = useState({ w: 1, h: 1 })
    const handleChangeProps = (data: IOutSizeItemProps[]) => {
      setProps(data)
    }
    const handleChangeRatio = (data: Ratio) => {
      setRatio(data)
    }
    useEffect(() => {
      subscriberSetProps = handleChangeProps
      subscriberSetRatioProps = handleChangeRatio
      return () => {
        subscriberSetProps = undefined
        subscriberSetRatioProps = undefined
      }
    }, [props])

    return { props, ratio }
  }

  return {
    api: {
      removeItemByIds: (ids?: string[]) => removeOutSideItems(ids),
      change: (data: Partial<IOutSizeItemProps>) => changeOutSideProps(data),
      changeRatio: (data: Ratio) => changeRatioProp(data),
      clearItems: (id?: number) => clearItems(id),
    },
    use,
  }
}
const OutSideHandler = createOutSideHandler()
export const ResizeOutsideApi = OutSideHandler.api
export const useResizeOutsideHandler = OutSideHandler.use
