import clsx from 'clsx'

import css from './CustomPopover.module.scss'

import { CONSTANTS } from '../../../styles/Constants'
import { Popover } from '../../base/Popover'
import type { TPopover } from './type'

export const CustomPopover = ({
  children = null,
  isShowArrow = false,
  isLightTheme = false,
  background,
  borderRadius = 8,
  ...p
}: TPopover) => (
  <Popover
    {...p}
    data-theme={isLightTheme ? 'light' : 'Dark'}
    trigger={p?.trigger || 'click'}
    content={
      <div
        style={{
          background: isLightTheme ? '#ffffff' : CONSTANTS.background.dropdown,
          borderRadius,
          boxShadow: isLightTheme
            ? '0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
            : '0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)',
          // linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(23, 23, 29, 0.85)
          color: isLightTheme ? '#0F0F0F' : '#E1E1E9',
          fontSize: 13,
          overflow: 'hidden',
          ...p.style,
        }}
      >
        {typeof p.content === 'function' ? p.content() : p.content}
      </div>
    }
    overlayInnerStyle={{
      padding: 0,
      background: isLightTheme ? '#FFFFFF' : '#000000',
      borderRadius,
      ...p.overlayInnerStyle,
    }}
    overlayClassName={clsx(`${!isShowArrow ? 'hide-arrow' : ''}`, css.Popover)}
  >
    {children}
  </Popover>
)
