import type { Device } from 'mediasoup-client'

import type { ProtooClientPeer } from '../type'
import type { TransportForceTyping } from '../type-ui'
import { iceServers } from './iceServers'

export const _createWebRtcTransport = async (
  protoo: ProtooClientPeer,
  device: Device,
  type: 'Send' | 'Recv',
): Promise<TransportForceTyping> => {
  const r = await protoo.request('createWebRtcTransport', {
    producing: type === 'Send',
    consuming: type === 'Recv',
  })
  const t = device[`create${type}Transport`]({
    ...r,
    proprietaryConstraints: {
      optional: [
        {
          googDscp: true,
          // enable these for higher call quality, but more cpu usage
          // googEchoCancellation: true,
          // googCpuOveruseDetection: false,
          // googCpuUnderuseThreshold: 98,
          // googCpuOverUseThreshold: 99,
          // googSuspendBelowMinBitrate: 0,
        },
      ],
    },
    iceServers,
  })
  t.on('connect', (d, c, e) =>
    protoo
      .request('connectWebRtcTransport', { ...d, transportId: t.id })
      .then(c)
      .catch(e),
  )
  if (type === 'Recv') {
    return t as TransportForceTyping
  }
  t.on('produce', (d: any, c, e) => {
    protoo
      .request('produce', { ...d, transportId: t.id })
      .then(c)
      .catch(e)
  })
  return t as TransportForceTyping
}
