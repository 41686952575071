export const getLocationData = () => {
  const currentUrl = location.href
  const url = new URL(currentUrl)
  const searchParams = url.searchParams
  const pathArray = url.pathname.split('/')
  const insightEngineId = pathArray[2]
  const mediaId = searchParams.get('mediaId')
  const pathName = location.pathname
  return { searchParams, insightEngineId, mediaId, url, pathName }
}
