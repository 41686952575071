export const featuresGroup = {
  Studio: {
    Participant: 'Participant',
    Chat: 'Chat',
    ScreenSharing: 'Screen sharing',
    DragDropMultiCameraMixing: 'Drag & Drop multi-camera mixing',
    OnScreenGraphicsTitlesAndMedia: 'On-screen graphics, titles & media',
    SessionTimeLimit: 'Session time limit',
    NoWatermark: 'No watermark',
    VideoUploads: 'Video uploads',
    SaveStudioSettings: 'Save Studio Settings',
    ProducerSupport: 'Producer Support',
    TechnicianSupport: 'Technician Support',
    CloudStorage: 'Cloud Storage (Media uploads, Records)',
    IndividualGuestCameraRecording: 'Individual guest-camera recording',
  },
  Destinations: {
    Destinations: 'Destinations',
    DestinationInputToZoomTeamsMeetWebex:
      'Destination input to Zoom, Teams, Meet, Webex',
    RTMPSource: 'RTMP-source',
    SRTSource: 'SRT-source',
  },
  VideoQuality: {
    FullHD1080p30fps: 'Full HD, 1080p, 30fps',
    FourKVideo: '4k Video',
  },
  AnalyticsAndSecurity: {
    AnalyticsDashboard: 'Analytics Dashboard',
    EnhanceSecurityAndPrivacy: 'Enhance Security & Privacy',
  },
}

export type IPlanName = 'Basic' | 'Premium' | 'Professional' | 'Enterprise'
export type IPlan = {
  name: IPlanName
  description: string
  price: string
  features: Array<{ [key: string]: any }>
  oldPrice?: string
}
export const plans: Array<IPlan> = [
  {
    name: 'Basic',
    description: 'Simple Livestream',
    price: 'Free',
    features: [
      {
        name: featuresGroup.Studio.Participant,
        value: '7',
      },
      {
        name: featuresGroup.Studio.Chat,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ScreenSharing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.DragDropMultiCameraMixing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.OnScreenGraphicsTitlesAndMedia,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.SessionTimeLimit,
        value: '40 mins',
      },
      {
        name: featuresGroup.Studio.NoWatermark,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.VideoUploads,
        value: '3',
      },
      {
        name: featuresGroup.Studio.SaveStudioSettings,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.ProducerSupport,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.TechnicianSupport,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.CloudStorage,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.IndividualGuestCameraRecording,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.Destinations,
        value: '1',
      },
      {
        name: featuresGroup.Destinations.DestinationInputToZoomTeamsMeetWebex,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.RTMPSource,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.SRTSource,
        value: 'no',
      },
      {
        name: featuresGroup.VideoQuality.FullHD1080p30fps,
        value: 'no',
      },
      {
        name: featuresGroup.VideoQuality.FourKVideo,
        value: 'no',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.AnalyticsDashboard,
        value: 'yes',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.EnhanceSecurityAndPrivacy,
        value: 'no',
      },
    ],
  },
  {
    name: 'Premium',
    description: 'Advanced Livestream with Cloud Storage',
    oldPrice: '£12/mo',
    price: '£10.2',
    features: [
      {
        name: featuresGroup.Studio.Participant,
        value: '15',
      },
      {
        name: featuresGroup.Studio.Chat,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ScreenSharing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.DragDropMultiCameraMixing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.OnScreenGraphicsTitlesAndMedia,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.SessionTimeLimit,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.NoWatermark,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.VideoUploads,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.SaveStudioSettings,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ProducerSupport,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.TechnicianSupport,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.CloudStorage,
        value: '5GB',
      },
      {
        name: featuresGroup.Studio.IndividualGuestCameraRecording,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.Destinations,
        value: '1',
      },
      {
        name: featuresGroup.Destinations.DestinationInputToZoomTeamsMeetWebex,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.RTMPSource,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.SRTSource,
        value: 'no',
      },
      {
        name: featuresGroup.VideoQuality.FullHD1080p30fps,
        value: 'no',
      },
      {
        name: featuresGroup.VideoQuality.FourKVideo,
        value: 'no',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.AnalyticsDashboard,
        value: 'yes',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.EnhanceSecurityAndPrivacy,
        value: 'no',
      },
    ],
  },
  {
    name: 'Professional',
    description: 'Unlimited Livestream & Full HD Video',
    oldPrice: '£35/mo',
    price: '£29.75',
    features: [
      {
        name: featuresGroup.Studio.Participant,
        value: '20',
      },
      {
        name: featuresGroup.Studio.Chat,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ScreenSharing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.DragDropMultiCameraMixing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.OnScreenGraphicsTitlesAndMedia,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.SessionTimeLimit,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.NoWatermark,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.VideoUploads,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.SaveStudioSettings,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ProducerSupport,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.TechnicianSupport,
        value: 'no',
      },
      {
        name: featuresGroup.Studio.CloudStorage,
        value: '15GB',
      },
      {
        name: featuresGroup.Studio.IndividualGuestCameraRecording,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.Destinations,
        value: '2',
      },
      {
        name: featuresGroup.Destinations.DestinationInputToZoomTeamsMeetWebex,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.RTMPSource,
        value: 'no',
      },
      {
        name: featuresGroup.Destinations.SRTSource,
        value: 'no',
      },
      {
        name: featuresGroup.VideoQuality.FullHD1080p30fps,
        value: 'yes',
      },
      {
        name: featuresGroup.VideoQuality.FourKVideo,
        value: 'no',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.AnalyticsDashboard,
        value: 'yes',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.EnhanceSecurityAndPrivacy,
        value: 'no',
      },
    ],
  },
  {
    name: 'Enterprise',
    description: 'For Large Business',
    price: '',
    features: [
      {
        name: featuresGroup.Studio.Participant,
        value: '20',
      },
      {
        name: featuresGroup.Studio.Chat,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ScreenSharing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.DragDropMultiCameraMixing,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.OnScreenGraphicsTitlesAndMedia,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.SessionTimeLimit,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.NoWatermark,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.VideoUploads,
        value: 'Unlimited',
      },
      {
        name: featuresGroup.Studio.SaveStudioSettings,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.ProducerSupport,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.TechnicianSupport,
        value: 'yes',
      },
      {
        name: featuresGroup.Studio.CloudStorage,
        value: 'Custom',
      },
      {
        name: featuresGroup.Studio.IndividualGuestCameraRecording,
        value: 'yes',
      },
      {
        name: featuresGroup.Destinations.Destinations,
        value: 'Custom',
      },
      {
        name: featuresGroup.Destinations.DestinationInputToZoomTeamsMeetWebex,
        value: 'yes',
      },
      {
        name: featuresGroup.Destinations.RTMPSource,
        value: 'yes',
      },
      {
        name: featuresGroup.Destinations.SRTSource,
        value: 'yes',
      },
      {
        name: featuresGroup.VideoQuality.FullHD1080p30fps,
        value: 'yes',
      },
      {
        name: featuresGroup.VideoQuality.FourKVideo,
        value: 'yes',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.AnalyticsDashboard,
        value: 'yes',
      },
      {
        name: featuresGroup.AnalyticsAndSecurity.EnhanceSecurityAndPrivacy,
        value: 'yes',
      },
    ],
  },
]
export const featureMap = (() => {
  const result: any[] = []
  for (const key in featuresGroup) {
    const tmp = {
      featureName: key,
      list: [] as any[],
    }
    const featureList = featuresGroup[key as keyof typeof featuresGroup]
    const basic = plans.find(p => p.name === 'Basic')
    const premium = plans.find(p => p.name === 'Premium')
    const professional = plans.find(p => p.name === 'Professional')
    const enterprise = plans.find(p => p.name === 'Enterprise')
    for (const key2 in featureList) {
      const data = {} as any
      const featureKey = key2 as keyof typeof featureList
      data.name = featureList[featureKey]
      data.basic = basic?.features.find(
        p => p.name === featureList[featureKey],
      )?.value
      data.premium = premium?.features.find(
        p => p.name === featureList[featureKey],
      )?.value
      data.professional = professional?.features.find(
        p => p.name === featureList[featureKey],
      )?.value
      data.enterprise = enterprise?.features.find(
        p => p.name === featureList[featureKey],
      )?.value
      tmp.list.push(data)
    }
    result.push(tmp)
  }
  return result
})()

export const getFeature = (
  planName: IPlanName | string = 'Basic',
  featureName: string,
): string | null => {
  const plan = plans.find(p => p.name.toLowerCase() === planName.toLowerCase())
  if (plan) {
    const feature = plan.features.find((f: any) => f.name === featureName)
    if (feature) {
      return feature.value
    }
  }
  return null
}

export const isOutOfStorage = (storageTotal: number, storageInUse: number) => {
  const storageAvailable = storageTotal - storageInUse
  return storageAvailable < storageTotal * 0.1
}
