import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

export const lazyUseSnapshot = <State extends object, Args extends unknown[]>(
  s: State,
  e?: {
    onInit?(...a: Args): void
    onCleanup?(...a: Args): void
  },
) => {
  let running = 0
  const onCleanup = e?.onCleanup && debounce(e.onCleanup, 1000)
  return (...a: Args) => {
    useEffect(() => {
      // @ts-ignore
      if (!running && !onCleanup?.pending?.()) {
        e?.onInit?.(...a)
      }
      onCleanup?.cancel()
      running++
      return () => {
        running--
        if (!running) {
          onCleanup?.(...a)
        }
      }
    }, [])
    return useSnapshot(s)
  }
}
