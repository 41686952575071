export enum EDirection {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}
export interface ISlotAction {
  className: string
  direction: EDirection
}
export enum EShapeType {
  CIRCLE = 0,
  ROUNDED_RECTANGLE = 1,
  SQUARE = 2,
}
export interface IActionBaseProps {
  x: number
  y: number
  width: number
  height: number
}
export interface IActionOutsize {
  onAction<T>(data?: T): void
  element: React.ReactNode
}
export interface IOptionChangeResize {
  switchTB: boolean
  switchLR: boolean
  direction: EDirection | undefined
}
export interface IActionOutsideBlur extends IActionOutsize {
  isInput?: boolean
}
