import { legacy_createStore } from 'redux'
import type { PersistConfig } from 'redux-persist'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import type { UseReduxUntyped } from '##/reactjs/redux'
import {
  configureStore,
  initReduxContext,
  useReduxUntyped,
} from '##/reactjs/redux'

import type { PersistedState } from '../../pages/shared/persisted'
import { persistedReducer } from '../../pages/shared/persisted'
import { extraReduxContext } from './reduxContext'

const persistConfig: PersistConfig<PersistedState> = {
  key: 'persist',
  storage: window.localStorage,
  stateReconciler: autoMergeLevel2,
}

// TODO rn is importing this and window.localStorage cause error
//    need to move rn compeletely out of react-app then we will enable this
void persistConfig
void persistReducer

const store = configureStore({
  reducer: {
    // persisted: persistReducer(
    //   persistConfig,
    //   persistedReducer,
    // ) as any as typeof persistedReducer,
    persisted: persistedReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
type Store = typeof store

export const reduxStore = store as ReduxStore
const reduxContext = initReduxContext(reduxStore, extraReduxContext)

export type ReduxStore = Store & {
  context: ReduxContext
}
export type ReduxContext = typeof reduxContext
export type ReduxState = ReturnType<typeof reduxStore.getState>

export const useRedux = useReduxUntyped as UseRedux
export type UseRedux = UseReduxUntyped<ReduxState>
export type GetRedux = typeof reduxStore.context.getRedux
export type SetRedux = typeof reduxStore.context.setState

let persistorCb: Function = () => {
  console.error('persistorCb should be the resolve fn in the below promise')
}
const persistorPromise = new Promise(resolve => {
  persistorCb = resolve
})
export const waitForReduxPersistor = () => persistorPromise

export const reduxPersistor = persistStore(
  store,
  // patch redux-persist, the repo is not in mantainance anymore
  { createStore: legacy_createStore } as any,
  persistorCb as any,
)
