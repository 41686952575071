export const iceServers = [
  {
    urls: 'stun:beam-dev.beings.com:3478',
  },
  {
    urls: [
      'turn:beam-dev.beings.com:3478?transport=udp',
      'turn:beam-dev.beings.com:3478?transport=tcp',
    ],
    username: 'beam',
    credential: 'coturn',
  },
]
