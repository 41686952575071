import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import css from './RightTabBar.module.scss'

import { WarmnessButton } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessButton'

import { Icon } from '../../../components/base/Icon'
import { Map } from '../../../components/base/Map'
import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { updateUserProfile } from '../../../context/shared/actions/updateUserProfile'
import { ChatBoxBtn } from '../../../context/shared/components/ChatBoxBtn'
import { S } from '../../../context/store'
import type { TResourceInSession } from '../../../context/store/studio/WebrtcStore'

export enum ERightTabBarItem {
  NOTES = 'notes',
  LIVE = 'live',
  GRAPHICS = 'graphics',
  MEDIA = 'media',
  TEXT = 'text',
  CHAT = 'chat',
}

export const RightTabBar = observer(() => {
  const [modeButtonHover, setModeButtonHover] = useState(false)
  const {
    webrtc: { selectedRightTabBarKey },
  } = S
  const {
    local: { isLightTheme, warmnessColor, warmnessColorHover },
    profile: { profile },
  } = S
  const onItemClick = (key: string) => {
    S.webrtc.updateDataOfStore({
      selectedRightTabBarKey: key === selectedRightTabBarKey ? '' : key,
    })
  }

  const getResourceInSession = async () => {
    const resourceInSession =
      await reduxStore.context.gql.searchResourceInSession(
        {
          sessionId: S.webrtc.sessionId,
        },
        { requestPolicy: 'network-only' },
      )
    if (
      resourceInSession?.data?.searchResourceInSession &&
      resourceInSession?.data?.searchResourceInSession.length > 0
    ) {
      const data = resourceInSession?.data
        ?.searchResourceInSession as TResourceInSession[]
      S.webrtc.groupMediaOfResourceSession(data)
    }
  }

  useEffect(() => {
    if (S.webrtc.state === 'connected') {
      getResourceInSession()
    }
  }, [S.webrtc.state])

  const listTabBar: ERightTabBarItem[] = Object.values(ERightTabBarItem)
  const switchTheme = async () => {
    try {
      const isLight = !S.local.isLightTheme
      const value = isLight ? 'light' : 'dark'
      updateUserProfile({ ...profile, theme: value })

      await reduxStore.context.gql.updateMyProfile({ data: { theme: value } })
    } catch (e) {
      ToastService.error({
        content: 'Failed to update theme',
      })
    }
  }
  const renderChatBadge = () => {
    const badgeNumber =
      S.webrtc.messages.chatInternal.length +
      S.webrtc.messages.chatPublic.length
    return badgeNumber > 0 ? (
      <span className={css.ChatBadge}>
        {badgeNumber > 99 ? '99+' : badgeNumber}
      </span>
    ) : null
  }
  const getIcon = (item: string, active: boolean): React.ReactElement => {
    switch (item) {
      case ERightTabBarItem.NOTES:
        return (
          <Icon
            icon='Note'
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            size={19}
          />
        )
      case ERightTabBarItem.LIVE:
        return (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='icon_menu_live'
            size={16}
          />
        )
      case ERightTabBarItem.GRAPHICS:
        return active ? (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='Vector'
            size={18}
          />
        ) : (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='Vector21'
            size={18}
          />
        )
      case ERightTabBarItem.MEDIA:
        return active ? (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='Vector1'
            size={19}
          />
        ) : (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='icon_sidebar_media'
            size={19}
          />
        )
      case ERightTabBarItem.TEXT:
        return (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='icon_sidebar_title'
            size={24}
          />
        )
      case ERightTabBarItem.CHAT:
        return (
          <Icon
            className={clsx(css.IconTab, { [css.iconTabActive]: active })}
            icon='ChatOutline'
            size={21}
          />
        )
      default:
        return <div></div>
    }
  }
  return (
    <div className={css.RightTabBarMain}>
      <div
        style={{
          background: isLightTheme ? warmnessColor : undefined,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // @ts-ignore
          '--background-menu-hover': warmnessColorHover,
        }}
      >
        <div className={css.rightTabBarList}>
          <Map<ERightTabBarItem>
            list={listTabBar}
            renderItem={(item, index) => {
              const no: string = (index + 1).toString()
              const active = selectedRightTabBarKey === no
              return (
                <div
                  onClick={() => onItemClick(no)}
                  key={index}
                  className={clsx({
                    [css.RightTabBarItem]: true,
                    [css.Active]: active,
                  })}
                >
                  {item === 'chat' && renderChatBadge()}
                  <span className={css.IconSidebar}>
                    {getIcon(item, active)}
                  </span>
                  <span
                    className={clsx({
                      [css.RightTabBarTitle]: true,
                      [css.Active]: active,
                    })}
                  >
                    {item}
                  </span>
                </div>
              )
            }}
          />
        </div>
        {isLightTheme && <WarmnessButton />}
        <div className={css.SwitchModeList}>
          <div
            onClick={switchTheme}
            onMouseOver={() => setModeButtonHover(true)}
            onMouseOut={() => setModeButtonHover(false)}
            className={css.SwitchModeButton}
          >
            <Icon
              icon={
                isLightTheme
                  ? modeButtonHover
                    ? 'Active'
                    : 'Passive'
                  : modeButtonHover
                    ? 'Active2'
                    : 'Passive1'
              }
              size={20}
            />
          </div>
        </div>
        {S.profile.isEnterprise && (
          <ChatBoxBtn
            className={css.ChatBot}
            style={{ height: 20, width: 20 }}
          />
        )}
      </div>
    </div>
  )
})
