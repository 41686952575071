import { S } from '../../store'

export const addSlotsToGroup = (
  slotIds: number[],
  layoutId: number,
  fromOverlay?: boolean,
) => {
  const $ = S.layout
  const $1 = S.webrtc
  let layout = $.slotsEdit[layoutId]
  if (layout) {
    layout = [...layout].map(i => {
      const isSelect = slotIds.find(s => s === i.id)
      if (isSelect) {
        const titleOnAir =
          i.titleId &&
          $1.titlesOnAir?.[$1.selectedIndexLayout]?.includes(i.titleId)
        if (titleOnAir) {
          return {
            ...i,
            inGroup: true,
          }
        } else if (i.titleId && !titleOnAir) {
          return {
            ...i,
            inGroup: false,
          }
        } else {
          return {
            ...i,
            inGroup: true,
          }
        }
      } else if (fromOverlay) {
        return {
          ...i,
          inGroup: false,
        }
      }

      return i
    })

    $.slotsEdit[layoutId] = layout
  }
}
