import { S } from '../../store'

export const updateWarmnessColors = (
  colorDefault?: string,
  gray?: string,
  thumbnail?: string,
  pressed?: string,
  hover?: string,
) => {
  const $ = S.local
  $.warmnessColor = colorDefault
  $.warmnessColorGray = gray
  $.warmnessColorThumbnail = thumbnail
  $.warmnessColorPressed = pressed
  $.warmnessColorHover = hover
}
