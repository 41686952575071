import { observer } from 'mobx-react-lite'

import { SubTitle } from '../../../components/composites/SubTitle'
import { LiveStreamAudio } from './LiveStreamAudio'
import {
  LiveStreamBackgroundColor,
  LiveStreamBackgroundImage,
} from './LiveStreamBackground'
import { LiveStreamChat } from './LiveStreamChat'
import { LiveStreamLogo } from './LiveStreamLogo'
import { LiveStreamOverlay } from './LiveStreamOverlay'
import { LiveStreamPeer } from './LiveStreamPeer'
import { LiveStreamSelectedChat } from './LiveStreamSelectedChat'
import { LiveStreamVideo } from './LiveStreamVideo'

export const LiveStreamVisualElement = observer(() => (
  <>
    <LiveStreamBackgroundColor />
    <LiveStreamBackgroundImage />
    <LiveStreamChat />
    <LiveStreamSelectedChat />
    <LiveStreamOverlay />
    <LiveStreamLogo />
    <LiveStreamAudio />
    <LiveStreamVideo />
    <LiveStreamPeer />
    <SubTitle />
  </>
))
