import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import { toArray } from 'lodash'
import type { CSSProperties } from 'react'

import { arrToMap } from '##/shared/arrToMap'

import { LIMIT_AVATAR_SIZE, SUPPORT_FILE_TYPE_AVATAR } from '../../../config'
import type { TFLite } from '../../actions/studio/loadTFLiteModel'
import {
  DRAG_MEDIA_AUDIO,
  DRAG_MEDIA_IMAGE,
  DRAG_MEDIA_VIDEO,
} from './dragTypes'
import { createTimerWorker } from './timerHelper'
import { buildWebGL2Pipeline } from './webgl2Pipeline'
import type {
  TKeyAny,
  TKeyString,
  TLayoutItems,
  TLayoutPositionItem,
  TMediaItem,
  TMediaStudio,
} from './WebrtcStore'

interface XYCoord {
  x: number
  y: number
}

type TUploadFile = {
  id: number
  file: File
  progress: number
  cancelSource: CancelTokenSource
  type: string
  status: number
}
type TModifyFiles = {
  [key: string]: TUploadFile
}

// Should be the same with api upload handler
const imageExts = arrToMap([
  'png',
  'jpg',
  'jpeg',
  'gif',
  'svg',
  //
])
const videoExts = arrToMap([
  'mp4',
  'mov',
  'mkv',
  'avi',
  'm4v',
  'flv',
  'webm',
  //
])
export const audioExts = arrToMap([
  'mp3',
  'ogg',
  'wav',
  //
])

export const reOrderList = (
  array: Array<number>,
  fromIndex: number,
  toIndex: number,
) => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex
    const [item] = array.splice(startIndex, 1)
    array.splice(endIndex, 0, item)
  }
  return array
}

export const getNewListResourceOrder = (
  listResoures: ReadonlyArray<TKeyString>,
  dropIndex: number,
  overIndex: number,
) => {
  if (dropIndex === overIndex || dropIndex === overIndex - 1) {
    return null
  }
  let newOverIndex = overIndex
  if (dropIndex < overIndex) {
    newOverIndex = newOverIndex - 1
  }
  const idsList = Array.from(listResoures, (_, index) => index)
  const listSorted: number[] = reOrderList(idsList, dropIndex, newOverIndex)
  return {
    idsList,
    listSorted,
  }
}

export const covertGBtoBytes = (size: number) => size * 1024 * 1024 * 1024
export const covertBytesToGB = (size: number) => size / (1024 * 1024 * 1024)

export const formatBytes = (bytes: number | undefined, decimals = 2) => {
  if (!bytes) {
    return '0B'
  }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export const getTypeOfFile = (file: File) => {
  const type = file.type
  const ext = getExtOfFile(file.name)
  if (type.includes('video') || videoExts[ext]) {
    return 'video'
  }
  if (type.includes('image') || imageExts[ext]) {
    if (type.includes('gif')) {
      return 'gif'
    }
    if (type.includes('svg')) {
      return 'svg'
    }
    return 'image'
  }
  if (type.includes('audio') || audioExts[ext]) {
    return 'audio'
  }
  return 'image'
}
export const formatFileName = (name: string, length = 18) => {
  if (name.length <= length) {
    return name
  }
  const splitName = name.split('.')
  const extName = splitName.pop()
  return `${name.slice(0, length)}....${extName}`
}
export const getTotalSizeOfFiles = (files: FileList) => {
  let total = 0
  for (let i = 0; i < files.length; i++) {
    total += files[i].size
  }
  return total
}
export const modifyFiles = (existingFiles: TModifyFiles, files: FileList) => {
  let fileToUpload = {}
  const lastFile = toArray(existingFiles).pop()
  const lastId = lastFile?.id || 0
  const fileArray = Array.from({ length: files.length })
    .map((f, idx) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      return {
        file: files[idx],
        type: getTypeOfFile(files[idx]),
        cancelSource: source,
        progress: 0,
        status: 0,
      }
    })
    .sort((a, b) => a.file.size - b.file.size)
  for (let i = 0; i < fileArray.length; i++) {
    const id = lastId + i + 1
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        ...fileArray[i],
      },
    }
  }
  return fileToUpload
}
export const getExtOfFile = (fileName: string) =>
  fileName.split('.').pop() || ''
export const getFileTypeOfStudio = (fileType: string) => {
  if (fileType === 'gif' || fileType === 'svg') {
    return 'image'
  }
  return fileType
}
export const groupFileByType = (listFilesUploaded: TMediaItem[]) => {
  let result: TMediaStudio = {}
  listFilesUploaded.forEach((item: TMediaItem) => {
    const detailType = result[item.mediaType] || []
    if (item.value !== '') {
      result = {
        ...result,
        [item.mediaType]: [...detailType, item],
      }
    }
  })
  return result
}

export const pluralText = (text: string, number: number) => {
  if (number < 2) {
    return text
  }
  return `${text}s`
}

const formatFullNumber = (number: number) => {
  if (number < 10) {
    return `0${number}`
  }
  return number.toString()
}
export const covertDurationToMinutes = (duration?: number) => {
  if (
    duration === undefined ||
    isNaN(duration) ||
    !isFinite(duration) ||
    duration < 0
  ) {
    return '--:--'
  }
  let seconds = Math.floor(duration)
  let minutes = 0
  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60)
    seconds = seconds - 60 * minutes
  }
  return `${formatFullNumber(minutes)}:${formatFullNumber(seconds)}`
}

export const getNameOfUrl = (url: string) => {
  const splitUrl = url.split('/')
  if (splitUrl.length === 0) {
    return url
  }
  return splitUrl[splitUrl.length - 1]
}

export const getItemPreviewStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
    display: 'block',
  }
}

export const cssConvert = (style: CSSProperties) => {
  const regex = new RegExp(/[A-Z]/g)
  const kebabCase = (str: string) =>
    str.replace(regex, v => `-${v.toLowerCase()}`)
  return Object.keys(style).reduce((accumulator, key) => {
    const cssKey = kebabCase(key)
    const cssValue = ((style as any)[key] + '').replace("'", '')
    return `${accumulator}${cssKey}:${cssValue};`
  }, '')
}

// export const isOnAirItemOfLayout = (
//   items: TLayoutItems,
//   selectedIndexLayout: number,
//   idOnAir: string,
// ) => {
//   const listMediaOnAir = items[selectedIndexLayout]
//   let itemIsOnAir = false
//   if (listMediaOnAir && Object.keys(listMediaOnAir).length > 0) {
//     Object.keys(listMediaOnAir).forEach(layout => {
//       const id = get(listMediaOnAir[layout], ['id'])
//       if (id === idOnAir) {
//         itemIsOnAir = true
//       }
//     })
//   }

//   return itemIsOnAir
// }
export const getDragTypeByMediaType = (mediaType: string) => {
  switch (mediaType) {
    case 'image':
    case 'gif':
      return DRAG_MEDIA_IMAGE
    case 'video':
      return DRAG_MEDIA_VIDEO
    case 'audio':
      return DRAG_MEDIA_AUDIO
    default:
      return ''
  }
}

const getPositionOfObject = (list: TLayoutPositionItem, id: string) => {
  const position: string[] = []
  const keyChildObject = Object.keys(list)
  keyChildObject.forEach((key: string) => {
    const itemValue = list[key]
    if (itemValue.id && itemValue.id === id) {
      position.push(key)
    }
  })
  return position
}
export const getListPosOfIdValue = (
  id: string,
  items: TLayoutItems,
  layout?: number,
) => {
  const keyItems = Object.keys(items)
  if (!keyItems || keyItems.length === 0) {
    return []
  }

  const position: TKeyString[] = []
  if (!layout) {
    Object.entries(items).forEach(([key, values]) => {
      const childPostion = getPositionOfObject(values, id)
      if (childPostion.length > 0) {
        childPostion.forEach(p => {
          position.push({
            position: p,
            layout: key,
          })
        })
      }
    })
  } else {
    if (!keyItems.includes(layout.toString())) {
      return []
    }

    const childObject = items[layout]
    const keyChildObject = Object.keys(childObject)
    keyChildObject.length > 0 &&
      keyChildObject.forEach((key: string) => {
        const itemValue = childObject[key]

        if (itemValue.id === id) {
          position.push({
            position: key,
            layout: layout.toString(),
          })
        }
      })
  }
  return position
}

export const removeAndGetNewLayout = (
  listIds: string[],
  layoutItems: TLayoutItems,
  layout?: number, // if not layout , remove id on all layout
  allowDelete = true,
  callback?: (mediaId: string, position: number) => void,
) => {
  const items = layoutItems
  listIds.forEach(id => {
    const listPositions: TKeyString[] = getListPosOfIdValue(id, items, layout)
    if (listPositions.length > 0) {
      listPositions.forEach(item => {
        const itemLayout = item?.layout || ''
        const itemPos = item?.position || ''

        if (itemLayout !== '' && item.position !== '' && allowDelete) {
          callback?.(id, item.position)

          delete items[itemLayout][itemPos]
        }
      })
    }
  })
  return items
}

export const updateValuesForLayout = (
  id: string,
  layoutItems: TLayoutItems,
  data: TKeyString,
  layout?: number, // if not layout , remove id on all layout
) => {
  const items = layoutItems
  const listPositions: TKeyString[] = getListPosOfIdValue(id, items, layout)
  if (listPositions.length > 0) {
    listPositions.forEach(item => {
      const itemLayout = item?.layout || ''
      const itemPos = item?.position || ''
      if (itemLayout !== '' && item.position !== '') {
        items[itemLayout][itemPos] = {
          id,
          ...data,
        }
      }
    })
  }
  return items
}

export const sortResourceOfSession = (resource: TMediaItem[]) => {
  if (resource.length === 0) {
    return []
  }
  return resource.sort(
    (a, b) => Number(a?.position || '0') - Number(b?.position || '0'),
  )
}

export const getOrderListMedia = (
  currentList: TKeyAny[],
  dropIndex: number,
  overIndex: number,
) => {
  if (dropIndex === overIndex || dropIndex === overIndex - 1) {
    return
  }
  let newOverIndex = overIndex
  if (dropIndex < overIndex) {
    newOverIndex = newOverIndex - 1
  }

  const idsList = Array.from(currentList, (_, index) => index)
  const listSorted: number[] = reOrderList(idsList, dropIndex, newOverIndex)
  const result = listSorted.map(item => currentList[item])
  return result
}

export const convertDurationToHours = (duration?: number) => {
  if (
    duration === undefined ||
    isNaN(duration) ||
    !isFinite(duration) ||
    duration < 0
  ) {
    return '-:--:--'
  }
  let hours = 0
  const minuteStr = covertDurationToMinutes(duration)
  const minutes = Number(minuteStr.split(':')[0])
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60)
    duration = duration - hours * 3600
  }

  return `${hours}:${covertDurationToMinutes(duration)}`
}

/**
 * Using MediaPipe Meet Segmentation model with WebAssembly SIMD backend and WebGL 2 pipeline.
 * [Reference](https://github.com/Volcomix/virtual-background/blob/main/README.md)
 */
const segmentationConfig = {
  backend: 'wasmSIMD',
  targetFps: 65, // 60 introduces fps drop and unstable fps on Chrome
}
export const renderPipeline = (
  videoEl: HTMLVideoElement,
  canvasEl: HTMLCanvasElement,
  tflite: TFLite,
) => {
  const targetTimerTimeoutMs = 1000 / segmentationConfig.targetFps

  let renderTimeoutId: number

  const timerWorker = createTimerWorker()

  const pipeline = buildWebGL2Pipeline(videoEl, canvasEl, tflite, timerWorker)

  const render = async () => {
    const startTime = performance.now()

    // console.log(startTime)
    await pipeline.render()
    // console.log(performance.now())
    renderTimeoutId = timerWorker.setTimeout(
      render,
      Math.max(0, targetTimerTimeoutMs - (performance.now() - startTime)),
    )
  }

  render()

  const cleanUpFunc = () => {
    timerWorker.clearTimeout(renderTimeoutId)
    timerWorker.terminate()
    pipeline.cleanUp()
  }

  return {
    pipeline,
    cleanUpFunc,
  }
}
export const readFile = (
  file: any,
  limit: number = LIMIT_AVATAR_SIZE,
): Promise<{ data: any; canUse: boolean }> =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        if (reader.result) {
          const sizeInMB = file.size / 1024 / 1024
          if (
            sizeInMB <= limit &&
            SUPPORT_FILE_TYPE_AVATAR.find(i => i === file.type)
          ) {
            resolve({ data: reader.result, canUse: true })
          } else {
            resolve({ data: reader.result, canUse: false })
          }
        }
        resolve({ data: null, canUse: false })
      },
      false,
    )
    reader.readAsDataURL(file)
  })
