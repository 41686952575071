import { useEffect, useRef, useState } from 'react'

import { separateLogic } from '../../../../../../#/reactjs/redux'
import { Form } from '../../../../components/base/Form'
import { pickFile } from '../../../../components/composites/ImageEditor/canvasUtils'
import { ModalService } from '../../../../components/Modal/Modal'
import { ToastService } from '../../../../components/widget/Toast'
import { SUPPORT_FILE_TYPE_AVATAR } from '../../../../config'
import {
  useGqlCreateOutputInSession,
  useGqlUpdateOutputInSession,
} from '../../../../context/gql/codegen'
import { ModalOutStorage } from '../../../../context/shared/components/ModalOutStorage'
import { S } from '../../../../context/store'
import {
  covertGBtoBytes,
  getTotalSizeOfFiles,
  readFile,
} from '../../../../context/store/studio/utils'
import type { CreateEventProps, DestinationInfo } from './type'

export const createComponent = separateLogic((p: CreateEventProps) => {
  const currentSub = S.webrtc.plan
  const isEnterprise = currentSub?.plan === 'Enterprise'
  const storageInUse = Number(currentSub?.totalStorageUsed) || 0
  const storageTotal = Number(currentSub?.totalStorage) || 0
  const fileUpload = useRef<any | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const { data, onSave, output } = p
  const [, createOutInSession] = useGqlCreateOutputInSession()
  const [, updateOutputInSession] = useGqlUpdateOutputInSession()
  const [form] = Form.useForm<DestinationInfo>()
  const handleUploadFile = async file => {
    const fileInfo = await readFile(file)
    if (fileInfo.data) {
      form.setFieldValue('thumbnail', fileInfo.data)

      if (fileInfo.canUse) {
      } else {
        ToastService.error({ content: 'File not supported', duration: 2 })
      }
    }
  }

  const titleWatch = Form.useWatch('title', form)
  const descriptionWatch = Form.useWatch('description', form)
  const thumbnailWatch = Form.useWatch('thumbnail', form)
  useEffect(() => {
    if (titleWatch?.length > 100) {
      form.setFieldValue('title', titleWatch.slice(0, 100))
    }
  }, [titleWatch])
  useEffect(() => {
    if (titleWatch?.length > 5000) {
      form.setFieldValue('title', titleWatch.slice(0, 5000))
    }
  }, [descriptionWatch])
  const handleSave = async () => {
    setLoading(true)
    if (!output) {
      return
    }
    let resourceId = ''
    if (fileUpload.current) {
      const formData = new FormData()
      formData.append('file', fileUpload.current)
      const result = await S.webrtc.callApiUpload(formData, 'image')
      resourceId = result.resourceId
    }
    if (data) {
      const result = await updateOutputInSession({
        id: data.id,
        data: {
          description: descriptionWatch,
          title: titleWatch,
          thumbnailId: resourceId,
        },
      })
      if (result.error) {
        ToastService.error({ content: 'Update event failed.' })
        setLoading(false)
        return
      }
      ModalService.hide()
      fileUpload.current = undefined
      onSave?.()
      return
    }
    const res = await createOutInSession({
      data: {
        outputId: output.id,
        sessionId: S.webrtc.sessionId,
        description: descriptionWatch,
        title: titleWatch,
        thumbnailId: resourceId,
      },
    })
    if (res.error) {
      ToastService.error({ content: 'Create event failed.' })
      setLoading(false)
      return
    }
    ModalService.hide()
    fileUpload.current = undefined
    onSave?.()
  }
  const handleRePickImage = async () => {
    const files: any = await pickFile(SUPPORT_FILE_TYPE_AVATAR.join(','))
    const storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
    const totalSize = getTotalSizeOfFiles(files)

    if (totalSize >= storageAvailable && !isEnterprise) {
      ModalService.show(ModalOutStorage)
      return
    }
    handleUploadFile(files[0])
    fileUpload.current = files[0]
  }
  useEffect(() => {
    form.setFieldsValue({
      description: data?.description ?? '',
      thumbnail: data?.thumbnail?.url ?? '',
      title: data?.title ?? '',
    })
  }, [])
  return {
    form,
    titleWatch,
    descriptionWatch,
    handleRePickImage,
    thumbnailWatch,
    loading,
    handleSave,
  }
})
