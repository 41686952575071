import { useState } from 'react'

import GoogleMeetImg from '../../../assets/images/destination/GoogleMeet.png'
import MSTeamImg from '../../../assets/images/destination/MicrosoftTeams.png'
import ZoomImg from '../../../assets/images/destination/Zoom.png'
import css from './ModalRecordMeeting.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { Button } from '../../../components/base/Button'
import { Form } from '../../../components/base/Form'
import { FormItem } from '../../../components/base/FormItem'
import { BeamText } from '../../../components/base/Text'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { BeamInput } from '../../../components/widget/Input'
import { ToastService } from '../../../components/widget/Toast'
import { S } from '../../../context/store'
import { addRecordMeeting } from '../actions/addRecordMeeting'
import { getLocationData } from '../actions/getLocationData'

export const ModalRecordMeeting = ({
  isWarmTheme = true,
}: {
  onCancel: () => void
  context: string
  isWarmTheme?: boolean
}) => {
  const [form] = Form.useForm<{ meetingUrl: string }>()
  const [loading, setLoading] = useState(false)

  const { recordBotName } = S.insightEngine

  const { insightEngineId } = getLocationData()
  const cancelHandler = () => {
    ModalService.hide()
  }
  const { warmnessColor } = S.local
  const ToastContent = (
    <div className={css.Toast}>
      <BeamText className={css.mainDescription}>
        Please admit {recordBotName} when they join your meeting
      </BeamText>
      <BeamText className={css.subDescription}>
        We will add the record of your meeting after its end
      </BeamText>
    </div>
  )
  const iedId = insightEngineId || S.webrtc.detail?.insightEngineId

  const addHandler = async () => {
    if (!iedId) {
      return
    }
    try {
      setLoading(true)
      const formRes = await form.validateFields()
      await addRecordMeeting(iedId, formRes.meetingUrl)
      ModalService.hide()
      ToastService.success({
        content: ToastContent,
      })
    } catch (e) {
      ToastService.error({
        content: 'Failed to record meeting',
      })
    }
    setLoading(false)
  }
  return (
    <ModalContent
      style={{
        // @ts-ignore
        '--background-modal': isWarmTheme ? warmnessColor : undefined,
      }}
      className={css.Wrapper}
    >
      <BeamText
        size='large1'
        className={css.boldText}
        containerProps={{ classNameContainer: css.Title }}
      >
        Record meeting
      </BeamText>
      <div className={css.ContentWrapper}>
        <BeamText className={css.boldText}>
          Aida will join & record your meeting
        </BeamText>
        <div className={css.DescriptionContainer}>
          <BeamText className={css.description}>
            Please admit {recordBotName} when they join your meeting
          </BeamText>
          <img className={css.meetIcon} src={GoogleMeetImg} />
          <img className={css.meetIcon} src={MSTeamImg} />
          <img className={css.meetIcon} src={ZoomImg} />
        </div>
        <Form form={form}>
          <FormItem
            name='meetingUrl'
            rules={[{ required: true, message: 'Please enter meeting link' }]}
          >
            <BeamInput placeholder='Enter meeting link' />
          </FormItem>
        </Form>
      </div>
      <div className={css.ModalAction}>
        <WarmnessGrayButton
          onClick={cancelHandler}
          className={css.ModalFormBtnCancel}
        >
          Cancel
        </WarmnessGrayButton>
        <Button
          className={css.confirmBtn}
          onClick={addHandler}
          loading={loading}
        >
          Add
        </Button>
      </div>
    </ModalContent>
  )
}
