import { S } from '../../store'
import type { ILayoutToEdit } from '../../store/shared/LayoutStore'

export const updateSlotsToEdit = (slots: ILayoutToEdit[], layoutId: string) => {
  const $ = S.layout
  const layout = $.slotsEdit[Number(layoutId)]
  if (layout) {
    $.slotsEdit[Number(layoutId)] = [...layout].map(item => {
      const itemSelected = slots.find(s => s?.id === item.id)

      if (itemSelected) {
        const itemUpdated: Omit<ILayoutToEdit, 'children'> = {
          ...itemSelected,
        }
        return {
          ...item,
          ...itemUpdated,
        }
      }
      return item
    })
  }
}
