import type { Output, OutputInSession } from '../../../../context/gql/codegen'
import type { EDestinationActionType } from '../DestinationEnum'

type TDestinationContainerPtops = {
  left?: number
  bottom?: number
}
export interface IDestinationActionProps {
  type?: EDestinationActionType
  destination?: Output
  destinationInSession?: OutputInSession
  containerProps?: TDestinationContainerPtops
  refreshDestination(): void
  sessionId: string
  isChecked?: boolean
  isPublishing?: boolean
  isExpired?: boolean
  isWaiting?: boolean
}

export enum OutputInSessionStatus {
  Stopped = 'Stopped',
  Publishing = 'Publishing',
  Waiting = 'Waiting',
  Expired = 'Expired',
  Failed = 'Failed',
}
