import type { MessageArgsProps } from 'antd'
import { message } from 'antd'
import { useEffect } from 'react'

import './style.scss'

import { Popover } from '../../base/Popover'
import { getIconByType } from './getIconByType'
import type { ToastPopoverProps, ToastProps } from './type'

const Toast = (props: ToastProps) => {
  const { type, content, icon } = props
  const iconToast = icon ?? getIconByType(type)

  return (
    <div className='custom-toast'>
      <div className='ant-message-notice-content'>
        <div className={`ant-message-custom-content ant-message-${type}`}>
          {iconToast}
          <span>{content}</span>
        </div>
      </div>
    </div>
  )
}

const ToastPopover = (props: ToastPopoverProps) => {
  const { children, popover, ...toastProps } = props
  const getToast = () => <Toast {...toastProps} />

  return (
    <Popover {...popover} content={getToast()} overlayClassName='popover-toast'>
      {children}
    </Popover>
  )
}

const ToastProvider = (props: any) => {
  useEffect(() => {
    message.config({
      // transitionName: 'move-down',
    })
  }, [])
  return props.children
}

class AppToastService {
  isLightTheme: boolean = false
  initIsLightTheme = (value: boolean) => {
    this.isLightTheme = value
  }
  info = (config: Omit<MessageArgsProps, 'type'>) => {
    config.duration = config.duration || 3
    message.open({
      ...config,
      type: 'info',
      icon: true,
      className: this.isLightTheme
        ? 'custom-toastLight-info'
        : 'custom-toast-info',
    })
  }
  success = (config: Omit<MessageArgsProps, 'type'>) => {
    const Icon = () => <div className=' toast-icon toast-icon-success' />
    config.duration = config.duration || 3
    message.open({
      ...config,
      type: 'success',
      icon: config.icon || <Icon />,
      className: this.isLightTheme ? 'custom-toastLight' : 'custom-toast',
    })
  }
  error = (config: Omit<MessageArgsProps, 'type'>) => {
    const Icon = () => <div className='toast-icon toast-icon-error' />
    config.duration = config.duration || 3
    message.open({
      ...config,
      type: 'error',
      icon: config.icon || <Icon />,
      className: this.isLightTheme ? 'custom-toastLight' : 'custom-toast',
    })
  }
  warning = (config: Omit<MessageArgsProps, 'type'>) => {
    const Icon = () => <div className='toast-icon toast-icon-warning' />
    config.duration = config.duration || 3
    message.open({
      ...config,
      type: 'warning',
      icon: config.icon || <Icon />,
      className: this.isLightTheme ? 'custom-toastLight' : 'custom-toast',
    })
  }
}
const ToastService = new AppToastService()
export { Toast, ToastPopover, ToastProvider, ToastService }
