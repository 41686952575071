import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { reactServerOrigin } from '../../../config'
import { S } from '../../../context/store'
import type { UploadResponse } from '../../../context/store/studio/WebrtcStore'
import { showError } from '../../../utils/showError'

export const insightEngineUploadFiles = async (
  file: FormData,
  insightEngineId: string,
  config?: AxiosRequestConfig<unknown>,
  headerConfig?: {
    [key: string]: string
  },
) => {
  try {
    const result: UploadResponse = await axios({
      method: 'post',
      url: `${reactServerOrigin}/api/upload`,
      data: file,
      headers: {
        authorization: `Bearer ${S.shared.authToken}`,
        'x-insight-engine-id': insightEngineId,
        'x-resource-type': 'insightEngine',
        ...headerConfig,
      },
      ...config,
    })
    return result.data?.data
  } catch (err: any) {
    showError('Failed to upload file', err as Error)
    console.error(err)
    return err
  }
}
