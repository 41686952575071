import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import css from './VideoPlayerControlOffline.module.scss'

import { S } from '../../../context/store'
import { controlMediaPrefix } from '../../../context/store/studio/WebrtcStore'
import { updateLocalMediaControlData } from '../actions/updateMediaControlDataClient'
import { playTrack } from '../utils/playTrack'
import { VideoPlayerControl } from './VideoPlayerControl'

export const VideoPlayerControlOnline = observer(
  ({ type }: { type: string }) => {
    const {
      mediaStudio,
      mediaControlData,
      mediaControlOnAction,
      mediaControlOnStateChange,
      viewmode,
      getMediaControlTracks,
      videoSessionList,
      selectedIndexLayout,
    } = S.webrtc
    const vref = useRef<HTMLVideoElement>(null)
    const videoSelected = videoSessionList.find(v => v.isSelected === true)
    const videoSelectedId = videoSelected?.mediaId ?? ''
    const videoTrack = getMediaControlTracks(videoSelectedId).videoTrack
    const videoControl = mediaControlData.find(
      item =>
        item.mediaId.replace(controlMediaPrefix[viewmode], '') ===
          videoSelectedId && item.from === viewmode,
    )

    const layoutIndexRef = useRef<number | undefined>(undefined)
    const [currentTime, setCurrentTime] = useState(videoControl?.currentTime)
    const [seeking, setSeeking] = useState(false)
    const [currentVolume, setCurrentVolume] = useState(videoControl?.volume)
    const [volumeSeeking, setVolumeSeeking] = useState(false)
    useEffect(() => {
      if (!videoTrack) {
        return
      }
      playTrack(vref.current, videoTrack)
    }, [videoTrack])
    useEffect(() => {
      if (
        selectedIndexLayout &&
        videoControl?.autoToBeginning &&
        layoutIndexRef.current !== undefined
      ) {
        setCurrentTime(0)
      }
      layoutIndexRef.current = selectedIndexLayout
    }, [selectedIndexLayout, videoControl?.autoToBeginning])
    const media = mediaStudio.video.find(item => item.id === videoSelectedId)

    const isLoadingCombine = Boolean(!videoControl) || videoControl?.seeking

    const data = {
      currentTime:
        videoControl?.seeking || seeking
          ? currentTime
          : videoControl?.currentTime,
      volume:
        videoControl?.volumeSeeking || volumeSeeking
          ? currentVolume
          : videoControl?.volume,
      name: media?.name,
      id: videoControl?.id,
      muted: videoControl?.muted,
      autoToBeginning: videoControl?.autoToBeginning,
      paused: videoControl?.paused,
      duration: videoControl?.duration,
      repeat: videoControl?.repeat,
      mediaType: 'video',
      value: videoControl?.url,
      mediaId: videoControl?.mediaId,
    }

    const video = <video ref={vref} className={css.MediaVideoItemPlayer} />
    const onChangeMuted = () => {
      if (data.id) {
        mediaControlOnAction(data.id, { type: 'volume', muted: !data.muted })
      }
    }

    const handleAutoRestartWhenSwitchLayout = () => {
      mediaControlOnStateChange(data.id ?? '', {
        autoToBeginning: !data.autoToBeginning,
      })
      updateLocalMediaControlData(data.id, {
        autoToBeginning: !data.autoToBeginning,
      })
    }
    const onChangePlay = () => {
      if (data.id) {
        mediaControlOnAction(data.id, { type: data.paused ? 'play' : 'pause' })
      }
    }

    const onDragEnd = () => {
      mediaControlOnAction(data.id ?? '', { type: 'play' })
    }
    const onChangeVolume = (v: number) => {
      setVolumeSeeking(true)
      setCurrentVolume(v)
      if (!data.id) {
        return
      }
      mediaControlOnStateChange(data.id, { volumeSeeking: true })
      updateLocalMediaControlData(data.id, {
        volumeSeeking: true,
        volume: v,
      })
    }

    const onChangeSeek = (v: number) => {
      setSeeking(true)
      setCurrentTime(v)
    }
    const handleAfterChangeVolume = (v: number) => {
      setVolumeSeeking(false)
      if (data.id) {
        mediaControlOnAction(data.id, { type: 'volume', volume: v })
      }
    }

    const handleAfterChangeSeek = (v: number) => {
      setSeeking(false)
      if (data.duration) {
        const newValuePercent = (v / data.duration) * 100
        mediaControlOnAction(data.id ?? '', {
          type: 'seek',
          seek: newValuePercent,
        })
      }
    }
    const onChangeRepeat = () => {
      const newRepeat = !data?.repeat

      S.webrtc.updateFileMedia('video', videoSelectedId, {
        isRepeat: newRepeat,
      })
      mediaControlOnAction(data.id ?? '', { type: 'repeat', repeat: newRepeat })
    }
    return (
      <VideoPlayerControl
        autoToBeginning={handleAutoRestartWhenSwitchLayout}
        isOnline={true}
        videoState={data}
        key={videoSelectedId}
        isLoading={isLoadingCombine}
        onChangeMuted={onChangeMuted}
        video={video}
        onDragEnd={onDragEnd}
        data={data}
        onChangeVolume={onChangeVolume}
        onChangePlay={onChangePlay}
        onChangeSeek={onChangeSeek}
        onAfterChangeSeek={handleAfterChangeSeek}
        handleAfterChangeVolumn={handleAfterChangeVolume}
        onChangeRepeat={onChangeRepeat}
        type={type}
      />
    )
  },
)
