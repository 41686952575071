import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import css from './BlurBlockItem.module.scss'

import { Resizeable } from '../../../context/shared/components/CustomLayoutResizeable'
import type { IActionOutsideBlur } from '../../../context/shared/utils/CustomLayoutType'
import { S } from '../../../context/store'
import type { IBlurItem } from '../../../context/store/insight-engine/InsightEngineStore'
import {
  InsightEngineEditType,
  InsightEngineMode,
} from '../../../context/store/insight-engine/InsightEngineStore'
import type { ILayoutItem } from '../../../context/store/shared/LayoutStore'
import { Helper } from '../../../context/store/studio/helper'
import { useDebounceValue } from '../../../utils/useDebounceValue'
import { addBlurBlock } from '../actions/addBlurBlock'
import { calculateRatio } from '../actions/calculateRatio'
import { calculateRealSizeVideo } from '../actions/calculateRealSizeVideo'
import { deleteBlurBlock } from '../actions/deleteBlurBlock'
import { getEndTime, getStartTime } from '../actions/getBlurBlockTime'
import { selectBlurBlock } from '../actions/selectBlurBlock'
import { updateBlurBlock, updateBlurBlockApi } from '../actions/updateBlock'

type Props = {
  item: IBlurItem
  containerProps: {
    width: number
    height: number
  }
  type: InsightEngineMode
}
export const BlurBlockItem: FC<Props> = observer(
  ({ item, containerProps, type }) => {
    const hasInit = useRef(false)
    const { isBlurMode, selectedBlockId, resourceSelected, mediaPlayer } =
      S.insightEngine
    const { videoRef } = mediaPlayer
    const [, setState, debouncedValue] = useDebounceValue<Partial<IBlurItem>>(
      1000,
      item,
    )
    const setBlockPartial = (state: IBlurItem) => {
      if (!videoRef) {
        return
      }
      const originVideoSize = {
        width: videoRef.videoWidth,
        height: videoRef.videoHeight,
      }
      const { heightR, widthR, topR, leftR } = calculateRealSizeVideo(
        originVideoSize,
        containerProps,
      )
      const { ratioW, ratioH } = calculateRatio(
        widthR,
        heightR,
        originVideoSize,
      )
      const w = state.width / ratioW
      const h = state.height / ratioH
      const data: Partial<IBlurItem> = {
        ...state,
        width: w > videoRef.videoWidth ? videoRef.videoWidth : w,
        height: h > videoRef.videoHeight ? videoRef.videoHeight : h,
        top: (state.top - topR) / ratioH,
        left: (state.left - leftR) / ratioW,
      }
      updateBlurBlock(data)
      setState(data)
    }

    useEffect(() => {
      if (hasInit.current) {
        updateBlurBlockApi(debouncedValue?.id ?? '')
      }
      hasInit.current = true
    }, [debouncedValue])

    const handleInputBlock = () => {}
    const handleDuplicateBlock = () => {
      const isMaxRight =
        containerProps.width - (item.left + item.width + 48) < 2
      const isMaxTop = containerProps.height - (item.top + item.height + 48) < 2
      addBlurBlock(
        {
          id: Helper.generateGuid(),
          height: item.height,
          width: item.width,
          top: isMaxTop ? item.top - 48 : item.top + 48,
          left: isMaxRight ? item.left - 48 : item.left + 48,
          zIndex: 1,
          radius: 3,
          startTime: 0,
          endTime: mediaPlayer.duration,
          type: InsightEngineEditType.Blur,
        },
        resourceSelected?.id ?? '',
      )
    }
    const handleDeleteBlock = () => {
      deleteBlurBlock(item.id)
    }
    const actionsTop: IActionOutsideBlur[] = [
      {
        onAction: handleInputBlock,
        element: <div className={css.BlurText}>Blur: 20</div>,
        isInput: true,
      },
      {
        onAction: handleDuplicateBlock,
        element: <span className={css.AddMoreSlot} />,
      },
      {
        onAction: handleDeleteBlock,
        element: <span className={css.RemoveMoreSlot} />,
      },
    ]
    const getLayoutSize = (): ILayoutItem => {
      const originVideoSize = {
        width: videoRef?.videoWidth ?? 0,
        height: videoRef?.videoHeight ?? 0,
      }
      const { heightR, widthR, topR, leftR } = calculateRealSizeVideo(
        originVideoSize,
        containerProps,
      )
      const { ratioW, ratioH } = calculateRatio(
        widthR,
        heightR,
        originVideoSize,
      )

      const width = item.width * ratioW
      const height = item.height * ratioH
      const top = topR + item.top * ratioH
      const left = leftR + item.left * ratioW
      return {
        width,
        height,
        top,
        left,
        radius: item.radius,
        id: item.id as any,
        zIndex: item.zIndex,
      }
    }

    const isEdit =
      isBlurMode &&
      selectedBlockId === item.id &&
      type === InsightEngineMode.Edit

    const handleLongPress = (e: any) => {
      e.stopPropagation()
      selectBlurBlock(item.id)
    }
    const isShow =
      (mediaPlayer.currentTime ?? 0) >= getStartTime(item) &&
      (mediaPlayer.currentTime ?? 0) <= getEndTime(item)

    if (!isShow) {
      return null
    }

    return (
      <Resizeable
        slotsOtherEditing={[]}
        layout={getLayoutSize()}
        key={item.id}
        onChange={setBlockPartial as any}
        id={item.id}
        index={item.zIndex}
        outSizeId={item.id + type}
        onLongPresLayout={handleLongPress}
        isEditMode={true}
        enableEditLayout={isBlurMode}
        isEdit={isEdit}
        disableResizeInput={true}
        containerProps={containerProps}
        actionsTop={actionsTop}
        showControl={isBlurMode && type === InsightEngineMode.Edit}
        forceHoverEffect={isBlurMode}
      >
        <canvas className={css.BlurBlock} />
      </Resizeable>
    )
  },
)
