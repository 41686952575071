import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { Transcript } from '../../../context/store/insight-engine/InsightEngineStore'

export const updateTranscript = async (
  id: string,
  data: Partial<Transcript>,
): Promise<boolean | undefined> => {
  const { transcripts } = S.insightEngine

  const res = await reduxStore.context.gql.updateTranscript({
    id,
    data: { content: data.content },
  })
  if (res.error) {
    ToastService.error({ content: 'Update failed.' })
    return false
  }

  S.insightEngine.transcripts = transcripts.map(t => {
    if (t.id === id) {
      return { ...t, ...data }
    }
    return t
  })
  ToastService.success({ content: 'Update success.' })
  return true
}
