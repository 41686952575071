import { REVAI_CONFIG } from '#HACK_FOR_RN_ONLY/src/config'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { S } from '../../store'
import type { HlsAudios } from '../../store/studio/WebrtcStore'

type SubtitleCleanupInfo = {
  initInterval: number | undefined
  responseAt: Date | null
}

type AudiosTracks = {
  mediaId: string
  audioTrack?: MediaStreamTrack
  volume: number
  willRender: boolean
}

type ProcessAudio = {
  peers?: PeerUI[]
  hlsAudios?: HlsAudios
  audios?: AudiosTracks[]
}

type SubtitleViewMode = 'rhost' | 'robserver'

export class Subtitle {
  private websocket: WebSocket
  private audioContext: AudioContext
  private scriptNode: ScriptProcessorNode
  private subtitleViewMode: SubtitleViewMode

  private mediaStreamAudio: {
    [key: string]: MediaStreamAudioSourceNode
  } = {}

  private subtitleCleanupInfo: SubtitleCleanupInfo = {
    initInterval: undefined,
    responseAt: null,
  }

  private cleanSubtitles = () => {
    if (!this.subtitleCleanupInfo.responseAt) {
      return
    }

    const interval =
      new Date().getTime() - this.subtitleCleanupInfo.responseAt.getTime()
    const clearTextAfterTime = 2000 // millisecond
    const shouldClean = interval >= clearTextAfterTime

    if (shouldClean) {
      S.webrtc.mixerEmitSubtitle('', this.subtitleViewMode)
      this.subtitleCleanupInfo.responseAt = null
    }

    if (
      ['suspended', 'closed'].includes(this.audioContext.state) &&
      !this.subtitleCleanupInfo.responseAt
    ) {
      window.clearInterval(this.subtitleCleanupInfo.initInterval)
      this.subtitleCleanupInfo.initInterval = undefined
    }
  }

  private parseResponse = response => {
    let message = ''

    for (let i = 0; i < response?.elements?.length; i++) {
      message +=
        response.type == 'final'
          ? response.elements[i].value
          : `${response.elements[i].value} `
    }

    return message.replace('<unk>', '')
  }

  private onMessage = (event: WebSocketMessageEvent) => {
    try {
      const data = JSON.parse(event.data)

      if (
        data.type === 'connected' ||
        !['partial', 'final'].includes(data.type)
      ) {
        return
      }

      const responseText = this.parseResponse(data)

      if (responseText) {
        S.webrtc.mixerEmitSubtitle(responseText, this.subtitleViewMode)

        if (this.subtitleCleanupInfo.initInterval === undefined) {
          this.subtitleCleanupInfo.initInterval = window.setInterval(() => {
            this.cleanSubtitles()
          }, 1000)
        }

        this.subtitleCleanupInfo.responseAt = new Date()
      }
    } catch (error) {}
  }

  private addTrackToMediaStreamAudio = (audioTrack: MediaStreamTrack) => {
    const mediaStream = new MediaStream()
    mediaStream.addTrack(audioTrack)
    this.mediaStreamAudio[audioTrack.id] =
      this.audioContext.createMediaStreamSource(mediaStream)
  }

  private processAudios = (params: ProcessAudio): boolean => {
    const { audios, hlsAudios, peers } = params
    const audioIds: string[] = []

    // Disconnect unused audio
    Object.keys(this.mediaStreamAudio).forEach(id => {
      this.mediaStreamAudio[id].disconnect()
      delete this.mediaStreamAudio[id]
    })

    const handleAudioTrack = (audioTrack: MediaStreamTrack) => {
      if (!this.audioContext) {
        return
      }

      this.addTrackToMediaStreamAudio(audioTrack)
      this.mediaStreamAudio[audioTrack.id].connect(this.scriptNode)
      audioIds.push(audioTrack.id)
    }

    if (hlsAudios) {
      Object.values(hlsAudios).forEach(audioTrack =>
        handleAudioTrack(audioTrack),
      )
    }

    audios?.forEach(a => a.audioTrack && handleAudioTrack(a.audioTrack))

    peers?.forEach(p => {
      if (p.audio?.track) {
        handleAudioTrack(p.audio.track)
      }

      // In case the peer is a mixer, it will handle the media
      for (const [id, value] of Object.entries(p.medias ?? {})) {
        const layouts = S.webrtc.layoutMedias
        const currentIndexLayout = S.webrtc.selectedIndexLayout

        const hasMediaInCurrentLayout = Object.values(
          layouts[currentIndexLayout] ?? {},
        ).some(mediaInfo => mediaInfo.id === id)

        if (!value.audio || !hasMediaInCurrentLayout) {
          continue
        }

        handleAudioTrack(value.audio.track)
      }
    })

    return !!audioIds.length
  }

  private processAudioEvent = (event: AudioProcessingEvent) => {
    if (
      !this.audioContext ||
      this.audioContext.state === 'suspended' ||
      this.audioContext.state === 'closed' ||
      this.websocket?.readyState !== 1
    ) {
      return
    }

    const inputData = event.inputBuffer.getChannelData(0)
    const output = new DataView(new ArrayBuffer(inputData.length * 2)) // The samples are floats in range [-1, 1]. Convert to PCM16le.

    for (let i = 0; i < inputData.length; i++) {
      const multiplier = inputData[i] < 0 ? 0x8000 : 0x7fff // 16-bit signed range is -32768 to 32767
      output.setInt16(i * 2, (inputData[i] * multiplier) | 0, true) // index, value, little edian
    }

    const intData = new Int16Array(output.buffer)
    let index = intData.length

    while (index-- && intData[index] === 0 && index > 0) {}
    const result = intData.slice(0, index + 1)
    this.websocket.send(result)
  }

  private initContext = () => {
    const access_token = S.webrtc.revAiKey

    if (!access_token) {
      console.log('revAiKey does not exist')
      return
    }

    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext ||
        (window as any)?.WebkitAudioContext)()

      this.scriptNode = this.audioContext.createScriptProcessor(4096, 1, 1)
      this.scriptNode.addEventListener('audioprocess', event =>
        this.processAudioEvent(event),
      )
      this.scriptNode.connect(this.audioContext.destination)
    }

    if (
      !this.websocket ||
      this.websocket.readyState === this.websocket.CLOSED ||
      this.websocket.readyState === this.websocket.CLOSING
    ) {
      const content_type = `audio/x-raw;layout=interleaved;rate=${this.audioContext.sampleRate};format=S16LE;channels=1`
      const query = `access_token=${access_token}&content_type=${content_type}&max_segment_duration_seconds=8`
      this.websocket = new WebSocket(`${REVAI_CONFIG.baseUrl}?${query}`)
      this.websocket.onmessage = this.onMessage
      this.websocket.CLOSED
    }
  }

  constructor(viewMode: SubtitleViewMode) {
    this.subtitleViewMode = viewMode
  }

  stopSubtitle = async () => {
    await this.audioContext?.suspend()
  }

  process = async (params: ProcessAudio) => {
    this.initContext()
    const hasAudio = this.processAudios(params)

    if (!hasAudio) {
      await this.stopSubtitle()
      return
    }

    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume()
    }
  }
}

export const subTitle = (status: boolean) => {
  const { isViewmodeHost, isViewmodeObserver } = S.webrtc

  const viewmode = isViewmodeHost
    ? 'host'
    : isViewmodeObserver
      ? 'observer'
      : ''

  if (viewmode) {
    S.webrtc.updateAndEmitToMixer({
      enableSubOnSubClient: { [viewmode]: status },
    })
  }
}
