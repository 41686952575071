import { Divider } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import type ReactQuill from 'react-quill'
import { Quill } from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import css from './NoteEditor.module.scss'

import { Icon } from '../../../components/base/Icon'
import { AppSpin } from '../../../components/base/Spin'
import { QuillEditor } from '../../../components/composites/Editor'
import { buildMixerQuery } from '../../../context/actions/mixerActions'
import {
  addNotes,
  updateNote,
} from '../../../context/actions/studio/updateNote'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { serverTime } from '../../../context/store/shared/serverTime'
import { Helper } from '../../../context/store/studio/helper'
import type {
  TMediaDragDropData,
  WebrtcStoreOis,
} from '../../../context/store/studio/WebrtcStore'
import {
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { formatDuration } from '../../../utils/formatDuration'
import { useDebounce } from '../../../utils/useDebounce'
import { useDropSmallSlot } from '../../../utils/useDrop'
import { isInputPeer } from '../utils/isInputPeer'

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'div',
  'script',
  'blockquote',
  'code',
  'formula',
  'image-blank',
  'addImage',
  'addImageCustom',
]
const modules = {
  toolbar: {
    container: '#quillToolbar',
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  addImageCustom: {},
}

export const CustomToolbar = observer(
  ({ isWarmTheme = true, id }: { isWarmTheme?: boolean; id?: string }) => {
    const { warmnessColorGray } = S.local
    const icons = Quill.import('ui/icons')
    icons['bold'] = null
    icons['italic'] = null
    icons['strike'] = null
    return (
      <div
        id={id ?? 'quillToolbar'}
        style={
          {
            '--grey8': isWarmTheme ? warmnessColorGray : undefined,
          } as React.CSSProperties
        }
      >
        <button className={clsx('ql-bold', css.ToolbarItemCustom)}>
          <Icon icon='TextStyle-Bold' size={16} />
        </button>
        <button className={clsx('ql-italic', css.ToolbarItemCustom)}>
          <Icon icon='TextStyle-Italic' size={16} />
        </button>
        <button className={clsx('ql-strike', css.ToolbarItemCustom)}>
          <Icon icon='TextStyle-StrikeThrough' size={16} />
        </button>
        <Divider type='vertical' className={css.divider} />
        <button className={clsx('ql-list', css.ToolbarItemCustom)}>
          <Icon icon='TextStyle-BulletList' size={16} />
        </button>
      </div>
    )
  },
)

export const NoteEditor = observer(() => {
  const { note, outputInSession, getPeer, multipleRecordingStartedAt } =
    S.webrtc
  const debouncedValue = useDebounce<string>(note.content, 1000)
  const quillRef = useRef<ReactQuill | null>(null)
  const [loading, setLoading] = useState(false)

  const noteChangedNumbers = useRef(0)
  const inited = useRef(false)
  const onDrop = async (data: TMediaDragDropData) => {
    setLoading(true)
    const peerId = data?.peerId ?? ''
    const videoElement = document.getElementById(
      data?.peerId ?? '',
    ) as HTMLVideoElement
    const mediaPeerId = getMediaPeerId(peerId)
    const peer = getPeer(mediaPeerId)
    const screenshare = peerId ? isScreenshareMedia(peerId) : false
    let rs: WebrtcStoreOis | undefined = undefined
    if (peer) {
      const thisMixerQuery = buildMixerQuery(
        {
          id: isInputPeer(peerId) ? getNormalId(peerId) : peer.id,
          userId: peer.data.userId,
          isInput: isInputPeer(peerId),
        },
        screenshare,
      )
      rs = outputInSession
        .filter(
          o =>
            o.output?.type === 'Recording' &&
            o.status === 'Publishing' &&
            o.mixerQuery,
        )
        .find(o2 => o2.mixerQuery === thisMixerQuery)
    }

    const quillObj = quillRef.current
    const addHtml = quillObj?.editor?.getModule('addImageCustom')
    if (!videoElement) {
      addHtml?.onAddHtml({
        id: Helper.generateGuid(),
        imgSrc: '',
        time: formatDuration(multipleRecordingStartedAt),
        title: S.webrtc.getPeer(data?.peerId || '')?.data.name,
        isOnlyImg: rs ? false : true,
      })
      setLoading(false)
      return
    }

    const canvas = document.createElement('canvas')
    canvas.width = 1920
    canvas.height = 1080
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
    canvas.toBlob(async blob => {
      if (!blob) {
        return
      }
      const formData = new FormData()
      const file = new File([blob], 'my_image.png', {
        type: 'image/png',
        lastModified: serverTime.now(),
      })
      formData.append('file', file)
      const res = await S.webrtc.callApiUpload(formData, 'note')

      if (!res || !res?.resource) {
        setLoading(false)
        return
      }
      const resource = res?.resource
      const d = {
        id: res.id,
        imgSrc: resource.url,
        time: formatDuration(multipleRecordingStartedAt),
        title: S.webrtc.getPeer(data?.peerId || '')?.data.name,
        isOnlyImg: rs ? false : true,
      }
      addHtml?.onAddHtml(d)
      setLoading(false)
    })
  }
  const sendNote = async () => {
    noteChangedNumbers.current = noteChangedNumbers.current + 1
    if (!S.webrtc.detail || noteChangedNumbers.current === 1) {
      return
    }
    if (!note.id && S.webrtc.detail?.id) {
      const data = await reduxStore.context.gql.createNote({
        data: { content: debouncedValue, sessionId: S.webrtc.detail?.id },
      })
      updateNote({ id: data.data?.createNote.id })
      return
    }
    reduxStore.context.gql.updateNote({
      id: note.id,
      data: { content: debouncedValue },
    })
  }
  useEffect(() => {
    sendNote()
  }, [debouncedValue, note.id])

  useEffect(() => {
    const deltas = JSON.parse(note.content)
    if (deltas.length > 0 && !inited.current && note.id) {
      quillRef.current?.editor?.setContents(deltas, 'user')
      inited.current = true
    }
  }, [note.content, note.id])

  const [{ isOver }, drop] = useDropSmallSlot(undefined, undefined, onDrop)

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.editor?.on('text-change', (delta, oldDelta, source) => {
        if (source === 'user' && delta) {
          addNotes(quillRef.current?.editor?.getContents().ops ?? [])
        }
      })
    }
  }, [])
  return (
    <div className={css.NoteEditor}>
      <CustomToolbar />

      <div ref={drop} className={css.EditorContent}>
        <QuillEditor
          formats={formats}
          modules={modules}
          ref={quillRef}
          placeholder={'Write your notes here...'}
          onKeyDown={e => {
            e.stopPropagation()
          }}
          className={css.QuillEditor}
          theme='snow'
        />
        <div
          className={clsx(css.EditorContentOverlay, {
            [css.editorContentOverlayShow]: isOver,
          })}
        />
        {loading && (
          <div className={css.Loading}>
            <AppSpin />
          </div>
        )}
      </div>
    </div>
  )
})
