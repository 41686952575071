import { S } from '../../../context/store'

export const toggleHideAllTitle = () => {
  const $ = S.webrtc
  const ts = $.titlesOnAir[$.selectedIndexLayout] ?? []
  if (ts.length) {
    $.titlesOnAir[$.selectedIndexLayout] = []
  } else {
    $.titlesOnAir[$.selectedIndexLayout] = $.selectedLayoutByIndex.slots
      .filter(s => s.titleId)
      .map(i => i.titleId)
  }
  $.updateEmitAndSaveSettings()
}
