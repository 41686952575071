import IconError from '../../../assets/icons/error.svg'
import IconSuccess from '../../../assets/icons/ic_checked.svg'
import IconWarning from '../../../assets/icons/icon_warning.svg'

import type { ToastProps } from './type'

export const getIconByType = (type: ToastProps['type']) => {
  if (type === 'error') {
    return <img src={IconError} />
  }
  if (type === 'success') {
    return <img src={IconSuccess} />
  }
  if (type === 'warning') {
    return <img src={IconWarning} />
  }
  return <img src={IconSuccess} />
}
