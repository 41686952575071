import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './MediaBottomPlayer.module.scss'

import { AppSpin } from '../../../components/base/Spin'
import { S } from '../../../context/store'
import { convertDurationToHours } from '../../../context/store/studio/utils'
import { getDownloadLink } from '../actions/getDownloadLink'

export const MediaBottomPlayer = observer(() => {
  const {
    mediaPlayer,
    setAllMediaPlayer,
    resources,
    resourceSelected,
    muteTimelines,
  } = S.insightEngine
  const { currentTime, isPlaying, duration, isMuted, mediaLoading } =
    mediaPlayer

  const selectedResource = resources.find(r => r.id === resourceSelected?.id)
  const isDisabled = !S.insightEngine.resourceSelected
  const togglePlayHandler = () => {
    if (mediaLoading || isDisabled) {
      return
    }

    setAllMediaPlayer({
      isPlaying: !isPlaying,
      currentTime: currentTime === duration ? 0 : currentTime,
    })
  }

  const toggleMuteHandler = () => {
    if (isDisabled) {
      return
    }

    setAllMediaPlayer({ isMuted: !isMuted })
  }

  const combineMute =
    muteTimelines.some(
      t =>
        (t.startTime ?? 0) <= (mediaPlayer.currentTime ?? 0) &&
        (t.endTime ?? 0) >= (mediaPlayer.currentTime ?? 0),
    ) || isMuted

  const downloadHandler = () => {
    if (!resourceSelected) {
      return
    }
    getDownloadLink(resourceSelected.id)
  }

  return (
    <div className={css.SeekBarFooter}>
      <div className={css.VideoTimerWrapper}>
        <span
          className={clsx({
            [css.volumeIcon]: true,
            [css.muteIcon]: combineMute,
            [css.iconDisabled]: isDisabled,
          })}
          onClick={toggleMuteHandler}
        />
        <span className={css.videoTimer}>
          {convertDurationToHours(currentTime)}/
          {convertDurationToHours(duration)}
        </span>
      </div>

      <span
        className={clsx({
          [css.PlayIcon]: true,
          [css.PauseIcon]: isPlaying,
          [css.iconDisabled]: isDisabled,
        })}
        onClick={togglePlayHandler}
      />

      <div className={css.ActionWrapper}>
        {selectedResource?.isDownloading ? (
          <div className={css.loadingIcon}>
            <AppSpin
              indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
            />
          </div>
        ) : (
          <span onClick={downloadHandler} className={clsx(css.downloadIcon)} />
        )}
        {/* <span
          className={clsx(css.settingsIcon, { [css.iconDisabled]: true })}
        /> */}
      </div>
    </div>
  )
})
