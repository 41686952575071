import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import css from './VideoPlayback.module.scss'

import { S } from '../../../context/store'
import { getVideoUrlFromMediaId } from '../../../context/store/studio/WebrtcStore'

type TProps = {
  peerId: string
  isFullscreenPlayback?: boolean
  muted?: boolean
}

export const VideoPlayback: FC<TProps> = observer(p => {
  const r = useRef<HTMLVideoElement>(null)
  const url = getVideoUrlFromMediaId(p.peerId)

  useEffect(() => {
    r.current?.play()
  }, [url])

  return (
    <video
      ref={r}
      src={url}
      id={p.peerId}
      autoPlay
      className={css.Video}
      playsInline
      onEnded={() =>
        S.webrtc.ownerPeerId === S.webrtc.peers[0].id &&
        S.webrtc.removeVideoPlaybackMediaId(p.peerId, p.isFullscreenPlayback)
      }
      onDoubleClick={() => {
        if (!S.webrtc.isViewmodeParticipant && !S.webrtc.isOnlyViewStream) {
          S.webrtc.removeVideoPlaybackMediaId(p.peerId, p.isFullscreenPlayback)
        }
      }}
      muted={p.muted}
    />
  )
})
