import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { SystemFlagKey } from '../../SystemFlags/components/SystemFlags'

export const searchRecordBotName = async () => {
  const res = await reduxStore.context.gql.searchSystemFlag({
    filter: { key_like: SystemFlagKey.MeetingBotName },
  })
  S.insightEngine.recordBotName = res.data?.searchSystemFlag[0].value ?? ''
}
