import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import css from './LiveStreamChat.module.scss'

import { S } from '../../../context/store'
import { cropboxH, vh } from '../../../context/store/studio/WebrtcStore'
import { getLayoutRatio } from '../actions/getLayoutRatio'
import { ItemChat } from './ChatItem'

export const LiveStreamChat = observer(() => {
  const contentChatRef = useRef<HTMLDivElement>(null)
  const containerChatRef = useRef<HTMLDivElement>(null)
  const { ratioScaleLayout, isViewmodeMixer, logoUrl, graphicLogo } = S.webrtc
  const { enableEditLayout } = S.layout
  const detailLogo = isViewmodeMixer
    ? logoUrl
    : graphicLogo.find(item => item.value === logoUrl)
  const { ratioTH } = getLayoutRatio()

  useEffect(() => {
    const top = contentChatRef.current?.scrollHeight || 0
    containerChatRef.current?.scrollTo({ top, behavior: 'smooth' })
  }, [S.webrtc.totalMessageChat])
  useEffect(() => {
    const containerChat = containerChatRef.current
    if (containerChat) {
      containerChat.scrollTop = containerChat.scrollHeight
    }
  }, [S.webrtc.messages.chatPublic.length, S.webrtc.isShowOverlayChat])
  if (!S.webrtc.isShowOverlayChat || enableEditLayout) {
    return null
  }
  const logoHeight = detailLogo ? ratioScaleLayout * cropboxH * 0.5 : 0

  return (
    <div
      className={css.LivestreamChatContainer}
      ref={containerChatRef}
      style={{
        right: S.webrtc.ratioScaleLayout,
        width: S.webrtc.chatLayoutWidth - 8,
        top: logoHeight,
        position: 'absolute',
        height: ratioTH * vh - logoHeight,
        zIndex: 9,
      }}
    >
      <div className={css.LiveStreamChatContent} ref={contentChatRef}>
        {S.webrtc.messages.chatPublic.map((item, index) => (
          <ItemChat chat={item} key={`${index}`} isOnLiveStream />
        ))}
      </div>
    </div>
  )
})
