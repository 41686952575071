import { observer } from 'mobx-react-lite'
import type { HTMLAttributes } from 'react'
import React from 'react'

import { S } from '../../../context/store'

export const WarmnessGrayDiv = observer(
  React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    (props, ref) => {
      const { warmnessColorGray } = S.local

      const { style, children, ...prop } = props
      return (
        <div
          ref={ref}
          style={{ background: warmnessColorGray, ...style }}
          {...prop}
        >
          {children}
        </div>
      )
    },
  ),
)
