import type { Moment } from 'moment'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

import { BeamCalendar } from '../../../components/composites/Calendar'
import type { BeamCalendarProps } from '../../../components/composites/Calendar/type'
import { useGqlSearchSession } from '../../../context/gql/codegen'
import { serverTime } from '../../../context/store/shared/serverTime'
import { Helper } from '../../../context/store/studio/helper'

const getRangeMonth = (date: Date) => {
  const firstDay = Helper.getFirstDayOfMonth(date)
  const lastDay = Helper.getLastDayOfMonth(date)
  return {
    firstDay,
    lastDay,
  }
}

type CalendarWithSessionProps = {
  onSelect?: (date: Moment, isHighlight: boolean) => void
  containerStyle?: React.CSSProperties
  warmnessColor?: string
  warmnessColorGray?: string
} & Omit<BeamCalendarProps, 'onSelect'>

export const CalendarWithSession = memo(
  forwardRef((props: CalendarWithSessionProps, ref) => {
    const value = props.value || serverTime.moment()
    const [state, setState] = useState(() => {
      const { firstDay, lastDay } = getRangeMonth(value.toDate())
      return {
        currentCalendar: value,
        oldValue: value,
        queryMonth: {
          filter: {
            startTime_lt: lastDay,
            startTime_gte: firstDay,
          },
        },
      }
    })
    const [resultOfMonths, refetch] = useGqlSearchSession({
      variables: state.queryMonth,
      requestPolicy: 'network-only',
    })

    useImperativeHandle(ref, () => ({
      refetch: () => {
        refetch()
      },
    }))

    const prevMonth = usePrevious(state.currentCalendar)

    if (props.value !== state.oldValue) {
      setState({
        ...state,
        currentCalendar: value,
        oldValue: value,
      })
    }

    useEffect(() => {
      if (state.currentCalendar.month() !== (prevMonth as any)?.month()) {
        const date = new Date(state.currentCalendar.format('YYYY-MM-DD'))
        const { firstDay, lastDay } = getRangeMonth(date)
        setState({
          ...state,
          currentCalendar: state.currentCalendar,
          queryMonth: {
            filter: {
              ...state.queryMonth.filter,
              startTime_lt: lastDay,
              startTime_gte: firstDay,
            },
          },
        })
      }
    }, [state.currentCalendar])

    const dateHighlight = useMemo(() => {
      const { data } = resultOfMonths
      const result: any[] = []
      const dayInMonth = Helper.getDaysInMonth(state.currentCalendar.toDate())
      for (let i = 1; i <= dayInMonth; ++i) {
        const dayOfSession = data?.searchSession.filter(
          p => new Date(p.startTime).getDate() === i,
        )
        if (dayOfSession?.length && dayOfSession.length > 0) {
          result.push({
            day: i,
            date: '',
            data: dayOfSession,
          })
        }
      }
      return result.map(p => {
        const newMoment = state.currentCalendar.clone().set('date', p.day)
        return newMoment
      })
    }, [resultOfMonths, state.currentCalendar])

    const onChangeDate = (date: Moment) => {
      const isHighlight = dateHighlight.find(d => date.isSame(d, 'day'))

      props.onSelect
        ? props.onSelect(date, isHighlight ? true : false)
        : undefined
    }
    return (
      <BeamCalendar
        containerProps={{ style: { width: '100%', ...props.containerStyle } }}
        fullscreen={false}
        {...props}
        onSelect={onChangeDate}
        dateHighlight={dateHighlight}
      />
    )
  }),
)
