import { reduxStore } from '../../../context/redux'

export const addRecordMeeting = async (id: string, url: string) => {
  const res = await reduxStore.context.gql.addRecordMeeting({
    insightEngineId: id,
    meetingUrl: url,
  })

  if (res.error) {
    throw new Error(res.error.message)
  }
}
