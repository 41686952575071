import { addSlot } from '../../../context/actions/layout/addSlot'
import { setEnableEditLayout } from '../../../context/actions/layout/setEnableEditLayout'
import { S } from '../../../context/store'
import type { ILayoutItem } from '../../../context/store/shared/LayoutStore'
import { getIdNumber } from '../../../context/store/studio/helper'
import { vh, vw } from '../../../context/store/studio/WebrtcStore'
import { calculateUpdateSlot } from './../components/UploadTranscript/calculateSlot'
import { getLayoutRatio } from './getLayoutRatio'

export const toggleOnAirTitle = (id: string, onAir: boolean) => {
  const $ = S.webrtc
  const { slotsEdit } = S.layout
  const ts = $.titlesOnAir[$.selectedIndexLayout] ?? []
  const { ratioTH, ratioLW } = getLayoutRatio()
  const layoutSelected = slotsEdit[$.selectedLayoutByIndex.defaultId] ?? []

  if (onAir) {
    const titleOnAir = $.selectedLayoutByIndex.slots.find(s => s.titleId === id)
    if (!titleOnAir) {
      const ids = layoutSelected.map(l => l.id)
      const newId = Math.floor(Math.random() * (layoutSelected.length + 2))
      const slot: ILayoutItem = {
        id: getIdNumber(newId, ids),
        ...calculateUpdateSlot({
          width: vw * ratioLW,
          height: 100,
          top: ratioTH * vh - 128,
          left: 0,
        }),
        radius: 0,
        zIndex: layoutSelected.length + 2,
        titleId: id,
        enableEdit: true,
        inGroup: false,
        parentId: $.selectedLayoutByIndex.defaultId,
        layoutIndex: $.selectedIndexLayout,
      }
      addSlot(slot, $.selectedLayoutByIndex.defaultId)
      const layoutsToEdit = $.selectedListLayouts.map(l => {
        const ls = slotsEdit[l.defaultId]
        if (ls) {
          return {
            ...l,
            slots: ls.map(s => ({
              id: s.id,
              height: s.height,
              width: s.width,
              left: s.left,
              top: s.top,
              radius: s.radius,
              zIndex: s.zIndex,
              cropMode: s.cropMode,
              cropData: s.cropData,
              titleId: s.titleId,
            })),
          }
        } else {
          return l
        }
      })
      setEnableEditLayout(true)
      $.selectedListLayouts = layoutsToEdit
      S.webrtc.updateEmitAndSaveSettings({ selectedListLayouts: layoutsToEdit })
    }
  }

  $.titlesOnAir[$.selectedIndexLayout] = onAir
    ? [...ts, id]
    : ts.filter(t => t !== id)
  S.webrtc.updateEmitAndSaveSettings({ titlesOnAir: $.titlesOnAir })
}
