import { Calendar } from 'antd'
import clsx from 'clsx'
import type { Moment } from 'moment'
import moment from 'moment'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import css from './style.module.scss'

import { serverTime } from '../../../context/store/shared/serverTime'
import { Icon } from '../../base/Icon'
import { BeamText } from '../../base/Text'
import type { BeamCalendarProps, TCalendarRef } from './type'

moment.updateLocale('en', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
})

export const BeamCalendar = forwardRef<TCalendarRef, BeamCalendarProps>(
  (props, ref) => {
    const {
      containerProps,
      onChange,
      warmnessColorGray,
      disabledDate,
      monthFormat,
      warmnessColor,
      value,
      ...calendarProps
    } = props

    const nextRef = useRef<HTMLSpanElement>(null)
    const prevRef = useRef<HTMLSpanElement>(null)
    const todayRef = useRef<HTMLSpanElement>(null)

    useImperativeHandle(ref, () => ({
      get nextBtnRef() {
        return nextRef.current
      },
      get prevBtnRef() {
        return prevRef.current
      },
      get todayBtnRef() {
        return todayRef.current
      },
    }))

    const checkDateEqual = (date: Moment, dateOrigin: Moment) => {
      if (
        date.date() === dateOrigin.date() &&
        date.month() === dateOrigin.month() &&
        date.year() === dateOrigin.year()
      ) {
        return true
      } else {
        return false
      }
    }

    const renderHeader = (
      headerValue: Moment,
      onHeaderChange: (d: Moment) => void,
    ): any => {
      const current = headerValue.clone()
      const localeData = headerValue.localeData()
      const year = headerValue.year()
      const month = headerValue.month()

      return (
        <div className={css.CalendarHeader}>
          <div>
            <span className='month-style'>
              {monthFormat === 'long'
                ? localeData.months(current)
                : localeData.monthsShort(current) + ' '}
            </span>
            <span className='year-style'>{String(year)}</span>
          </div>

          <div className={css.HeaderRight}>
            <span
              ref={prevRef}
              className={css.btnNav}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                const newValue = headerValue.clone()
                newValue.month(month - 1)
                onHeaderChange(newValue)
              }}
            >
              <Icon icon='icon_arrow_left' size={16} />
            </span>
            <span
              className={css.todayBtn}
              ref={todayRef}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onHeaderChange(moment())
              }}
            />
            <span
              ref={nextRef}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                const newValue = headerValue.clone()
                newValue.month(month + 1)
                onHeaderChange(newValue)
              }}
              className={css.btnNav}
            >
              <Icon icon='icon_arrow_right' size={16} />
            </span>
          </div>
        </div>
      )
    }
    const renderFullCell = (date: Moment) => {
      const now = serverTime.moment()
      const isHighlight = props?.dateHighlight?.find(d =>
        checkDateEqual(d, date),
      )

      const isToday = checkDateEqual(date, now)
      const isInMonth =
        date.month() === (value?.month() ?? now.month()) &&
        date.year() === (value?.year() ?? now.year())

      const isSelected = checkDateEqual(date, value ?? now)
      const today = now
      const isPastDay =
        date.clone().toDate().setHours(0, 0, 0, 0) <
        today.clone().toDate().setHours(0, 0, 0, 0)

      return (
        <div
          style={{
            // @ts-ignore
            '--background35': warmnessColor,
            '--background36': warmnessColorGray,
          }}
          // className={`date-item ${isToday ? 'today' : ''} ${
          //   !isInMonth && 'not-in-month'
          // } ${isSelected ? 'date-item-seleted' : ''}   ${
          //   isPastDay ? 'past-days' : ''
          // }`}
          className={clsx('date-item', {
            [css.today]: isToday,
            ['not-in-month']: !isInMonth,
            ['date-item-seleted']: isSelected,
            ['past-days']: isPastDay,
          })}
        >
          <div
            className={`date-value ${isHighlight ? 'date-value-selected' : ''}`}
          >
            <BeamText
              className={clsx({
                [css.beamText]: isSelected,
                [css.textToday]: !isSelected && isToday,
                [css.inMonth]: !isSelected && !isToday && isInMonth,
                [css.notInMonth]: !isSelected && !isToday && !isInMonth,
              })}
            >
              {date.date()}
            </BeamText>
          </div>
        </div>
      )
    }
    return (
      <div
        {...containerProps}
        className={clsx(css.CalendarContainer, containerProps?.className)}
      >
        <Calendar
          {...calendarProps}
          className={clsx(css.CalendarStyled, calendarProps.className)}
          disabledDate={disabledDate}
          onChange={date => {
            onChange ? onChange(date) : () => {}
          }}
          dateFullCellRender={date => renderFullCell(date)}
          headerRender={({ value: headerValue, onChange: onHeaderChange }) =>
            renderHeader(headerValue, onHeaderChange)
          }
        />
      </div>
    )
  },
)
