import { upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'

import css from './PreviewDeviceDropdown.module.scss'

import { BeamMenu } from '#HACK_FOR_RN_ONLY/src/components/base/Menu'

import { BeamDropdown } from '../../../components/base/Dropdown'
import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'

type Props = {
  type: 'mic' | 'cam' | 'speaker'
  onChange(id: string): void
}

export const PreviewDeviceDropdown = observer((p: Props) => {
  const $ = S.webrtc
  const id = $[`${p.type}Id`]
  const devices = $[`${p.type}s`]

  return (
    <BeamDropdown
      overlay={
        <BeamMenu className={css.Menu}>
          {devices.map(c => (
            <BeamMenu.Item
              className={css.MenuItem}
              key={c.deviceId}
              onClick={() => c.deviceId !== id && p.onChange(c.deviceId)}
            >
              {c.name || c.label || c.deviceId}
            </BeamMenu.Item>
          ))}
        </BeamMenu>
      }
      trigger={['click']}
      getPopupContainer={trigger => trigger.parentElement ?? trigger}
    >
      <div className={css.Dropdown}>
        <div className={css.Prefix}>{upperFirst(p.type)}: </div>
        {
          devices
            .filter(s => s.deviceId === id)
            .map(s => s.name || s.label || s.deviceId)[0]
        }
        <span className={css.IconArrow}>
          <Icon icon='Dropdown' size={16} />
        </span>
      </div>
    </BeamDropdown>
  )
})
