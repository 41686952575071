import type { RefObject } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { S } from '../context/store'
import {
  DRAG_USER_MAIN,
  DRAG_USER_SMALL,
} from '../context/store/studio/dragTypes'
import type { TMediaDragDropData } from '../context/store/studio/WebrtcStore'
import type { TDragSort } from '../pages/Studio/components/RightContent'

const SCROLL_OFFSET = 20
interface TDragDefault extends TDragSort {
  draggingType?: string
  disableDrag?: boolean
}
interface TDragSlotMedia extends TDragSort {
  refDrop: RefObject<HTMLDivElement>
  draggingType?: string
  disableDrag?: boolean
}
interface TDragSlotDefault extends TMediaDragDropData {
  refDrop: RefObject<HTMLDivElement>
  disableDrag?: boolean
}

export const useDragDefault = ({
  item,
  dragType,
  draggingType,
  onDragEnd,
  disableDrag,
}: TDragDefault) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: dragType,
    item: () => {
      document.body.classList.add('dragging')
      return item
    },
    end: () => {
      document.body.classList.remove('dragging')
      onDragEnd?.()
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  })

  useEffect(() => {
    S.webrtc.toggleTypeDraggingItem(draggingType || dragType, isDragging)
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false })
  }, [preview])
  return {
    isDragging,
    drag,
  }
}
export const useDragSlotMedia = ({
  item,
  dragType,
  refDrop,
  disableDrag,
}: TDragSlotMedia) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: dragType,
    item: () => {
      document.body.classList.add('dragging')
      return item
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !disableDrag,
  })
  useEffect(() => {
    const { dimensionDragItem, setDimensionDragItem, toggleTypeDraggingItem } =
      S.webrtc
    if (refDrop?.current) {
      const rect = refDrop.current.getBoundingClientRect()
      const width = rect?.width || 0
      const height = rect?.height || 0
      if (
        width !== dimensionDragItem.width ||
        height !== dimensionDragItem.height
      ) {
        setDimensionDragItem(width, height)
      }
    }
    toggleTypeDraggingItem(dragType, isDragging)
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false })
  }, [preview])
  return {
    isDragging,
    drag,
  }
}

export const useDragSlotMain = (fr: TDragSlotDefault) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: fr.type || DRAG_USER_MAIN,
    item: () => {
      document.body.classList.add('dragging')
      return fr
    },
    end: () => {
      document.body.classList.remove('dragging')
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !fr.disableDrag,
  })

  useEffect(() => {
    const { dimensionDragItem, setDimensionDragItem, toggleTypeDraggingItem } =
      S.webrtc
    if (fr.refDrop?.current) {
      const rect = fr.refDrop.current.getBoundingClientRect()
      const width = rect?.width || 0
      const height = rect?.height || 0
      if (
        width !== dimensionDragItem.width ||
        height !== dimensionDragItem.height
      ) {
        setDimensionDragItem(width, height)
      }
    }
    toggleTypeDraggingItem(fr.type || DRAG_USER_MAIN, isDragging)
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false })
  }, [preview])

  return {
    isDragging,
    drag,
  }
}

export const useDragSlotSmall = (fr: TMediaDragDropData) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: fr.type || DRAG_USER_SMALL,
    item: () => {
      document.body.classList.add('dragging')
      return fr
    },
    end: () => {
      document.body.classList.remove('dragging')
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !fr.disableDrag,
  })
  useEffect(() => {
    S.webrtc.toggleTypeDraggingItem(DRAG_USER_SMALL, isDragging)
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false })
  }, [preview])

  return {
    isDragging,
    drag,
  }
}

export const useDragScroll = (ref: RefObject<HTMLDivElement>) => {
  const [startScrolling, setScrolling] = useState(false)

  const scrollDown = () => {
    const nextScrollTop = (ref?.current?.scrollTop || 0) + SCROLL_OFFSET
    ref?.current?.scrollTo(0, nextScrollTop)
  }

  const scrollUp = () => {
    const nextScrollTop = (ref?.current?.scrollTop || 0) - SCROLL_OFFSET
    if (nextScrollTop >= 0) {
      ref?.current?.scrollTo(0, nextScrollTop)
    }
  }

  const smoothScroll = useCallback(
    (e: MouseEvent) => {
      const clientY = e.clientY
      if (clientY >= 0 && startScrolling) {
        if (clientY > (ref?.current?.offsetHeight || 0) - 50) {
          scrollDown()
        } else if (clientY <= (ref?.current?.offsetTop || 0) + 50) {
          scrollUp()
        }
      }
    },
    [startScrolling],
  )

  const onScrollStart = () => {
    setScrolling(true)
  }

  const onScrollStop = () => {
    setScrolling(false)
    window.removeEventListener('mousemove', smoothScroll)
  }

  useEffect(() => {
    if (startScrolling) {
      window.addEventListener('mousemove', smoothScroll)
    }
  }, [startScrolling])
  return {
    onScrollStart,
    onScrollStop,
  }
}
