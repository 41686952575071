import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import ReactAvatar from 'react-avatar'

import css from './TranscriptSingleUser.module.scss'

import { BeamAvatar } from '#HACK_FOR_RN_ONLY/src/components/base/Avatar'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Popover } from '../../../components/base/Popover'
import { S } from '../../../context/store'
import type { Transcript } from '../../../context/store/insight-engine/InsightEngineStore'
import {
  convertDurationToHours,
  covertDurationToMinutes,
} from '../../../context/store/studio/utils'
import { getAvatarColor } from '../../../utils/avatar'
import { ModalEditTranscript } from './ModalEditTranscript'
import { ModalReplaceSpeaker } from './ModalReplaceSpeaker'

export const TranscriptSingleUser = observer(
  ({ data }: { data: Transcript }) => {
    const { speaker, nameFromRevAi, startTime, content, id, endTime } = data
    const {
      setMediaPlayer,
      mediaPlayer: { videoRef, mediaLoading, currentTime },
    } = S.insightEngine
    const [showEdit, setShowEdit] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const handleVisibleChange = (v: boolean) => setIsVisible(v)

    const handleClickTranscript = () => {
      if (mediaLoading) {
        return
      }
      const time = Math.ceil(startTime * 100) / 100
      if (videoRef?.currentTime !== undefined) {
        videoRef.currentTime = time
      }
      setMediaPlayer({
        currentTime: time,
      })
    }
    const isShowHighlight = () => {
      if (!currentTime) {
        return false
      }
      return startTime <= currentTime && currentTime <= endTime
    }

    const name = speaker ? speaker.name : nameFromRevAi

    return (
      <div className={css.Wrapper} id={`trans-${id}`}>
        <div className={css.Header}>
          <div className={css.HeaderLeft}>
            {!!speaker?.avatarUrl ? (
              <BeamAvatar
                size={20}
                src={speaker?.avatarUrl}
                className={css.Avatar}
              />
            ) : (
              <ReactAvatar
                name={speaker?.name || nameFromRevAi || ''}
                round
                size='20'
                color={getAvatarColor(speaker?.name || nameFromRevAi || '')}
                className={css.Avatar}
              />
            )}
            <div className={css.Text}>{name}</div>
            <Popover
              content={
                <ModalReplaceSpeaker
                  onCancel={() => setIsVisible(false)}
                  name={name}
                  id={data.id}
                />
              }
              trigger='click'
              placement='right'
              showArrow={false}
              visible={isVisible}
              onVisibleChange={handleVisibleChange}
            >
              <span className={css.Dropdown}>
                <Icon icon='Dropdown' size={16} />
              </span>
            </Popover>

            <span className={css.Dot} />
            <div className={css.Text}>
              {startTime > 3600
                ? convertDurationToHours(startTime)
                : covertDurationToMinutes(startTime)}
            </div>
          </div>

          <Popover
            content={
              <ModalEditTranscript
                onCancel={() => setShowEdit(false)}
                transcript={data}
              />
            }
            trigger='click'
            placement='right'
            showArrow={false}
            visible={showEdit}
            onVisibleChange={v => setShowEdit(v)}
          >
            <Button
              className={clsx(css.EditButton, {
                [css.editButtonShow]: showEdit,
              })}
              containerType='grey'
            >
              <span className={css.EditIcon} />
            </Button>
          </Popover>
        </div>

        <div
          id={`trans-body-${id}`}
          onClick={handleClickTranscript}
          suppressContentEditableWarning={true}
          className={clsx(css.Body, { [css.Highlight]: isShowHighlight() })}
        >
          {content}
        </div>
      </div>
    )
  },
)
