import { addSlot } from '../../../context/actions/layout/addSlot'
import { S } from '../../../context/store'
import { getIdNumber, Helper } from '../../../context/store/studio/helper'

export const pasteSlot = () => {
  const { slotCopy: s, titlesOnEdit, enableEditLayout } = S.layout
  const {
    selectedLayoutByIndex,
    selectedListLayouts,
    selectedIndexLayout,
    titles,
  } = S.webrtc
  const ts = enableEditLayout ? titlesOnEdit : titles
  const titleSelected = ts.find(t => t.id === s?.titleId)
  if (!s || !titleSelected) {
    return
  }
  const layoutSelected =
    S.layout.slotsEdit[selectedLayoutByIndex.defaultId] ?? []
  const ids = layoutSelected.map(l => l.id)
  const newId = Math.floor(Math.random() * (layoutSelected.length + 2))
  const title = {
    ...titleSelected,
    id: Helper.generateGuid(),
  }
  const slot = {
    ...s,
    parentId: selectedLayoutByIndex.defaultId,
    layoutIndex: selectedIndexLayout,
    zIndex: layoutSelected.length + 2,
    id: getIdNumber(newId, ids),
    titleId: title.id,
  } as any
  addSlot(slot, selectedLayoutByIndex.defaultId)
  const layoutsToEdit = selectedListLayouts.map(l => {
    const ls = S.layout.slotsEdit[l.defaultId]
    if (ls) {
      return {
        ...l,
        slots: ls.map(s1 => ({
          id: s1.id,
          height: s1.height,
          width: s1.width,
          left: s1.left,
          top: s1.top,
          radius: s1.radius,
          zIndex: s1.zIndex,
          cropMode: s1.cropMode,
          cropData: s1.cropData,
          titleId: s1.titleId,
        })),
      }
    } else {
      return l
    }
  })
  const titlesByLayout =
    S.webrtc.titlesOnAir[S.webrtc.selectedIndexLayout] ?? []

  S.webrtc.titlesOnAir[S.webrtc.selectedIndexLayout] = [
    ...titlesByLayout,
    title.id,
  ]
  const listTitleData = S.layout.enableEditLayout
    ? S.layout.titlesOnEdit
    : S.webrtc.titles

  const newListData = [title, ...listTitleData]
  if (S.layout.enableEditLayout) {
    S.layout.titlesOnEdit = newListData as any
  }
  S.webrtc.updateEmitAndSaveSettings({
    selectedListLayouts: layoutsToEdit,
    titles: newListData,
    titlesOnAir: S.webrtc.titlesOnAir,
  })
}
