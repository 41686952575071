import type EventEmitter from 'events'
import { Device } from 'mediasoup-client'
import { Peer, WebSocketTransport } from 'protoo-client'

import type { ProtooClientPeer } from '../type'

export const _createProtoo = async (url: string) => {
  const protoo = new Peer(new WebSocketTransport(url)) as ProtooClientPeer
  const connected = await new Promise<boolean>(resolve => {
    const r = (v: boolean) => {
      resolve(v)
      return (protoo as any as EventEmitter).removeAllListeners()
    }
    protoo.on('open', () => r(true))
    protoo.on('close', () => r(false))
    protoo.on('disconnected', () => r(false))
    protoo.on('failed', () => r(false))
  })
  if (!connected) {
    protoo.close()
    return
  }
  const device = new Device()
  const routerRtpCapabilities = await protoo.request('getRouterRtpCapabilities')
  await device.load({ routerRtpCapabilities })
  return {
    protoo,
    device,
  }
}
