import { getTrack } from '../../../context/actions/studio/getTrack'
import { S } from '../../../context/store'

export const onPreviewDeviceChange = async (
  device: 'mic' | 'cam' | 'speaker',
  id: string,
) => {
  const $ = S.webrtc
  if (!$.preview) {
    return
  }

  if (device === 'speaker') {
    $.speakerId = id
  }

  if (device === 'mic') {
    $.micId = id
    $.preview.micTrack?.stop()
    $.preview.micTrack = await getTrack('audio', $.mics, id)
    return
  }

  $.camId = id
  $.preview.camTrack?.stop()
  if ($.camDisabled) {
    delete $.preview.camTrack
    return
  }
  $.preview.camTrack = await getTrack('video', $.cams, id)
}
