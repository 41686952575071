export const getDeviceId = (
  devices: MediaDeviceInfo[],
  track?: MediaStreamTrack,
) => {
  if (!devices.length || !track) {
    return
  }
  // try to get the deviceId from track
  const id =
    track.getSettings?.().deviceId || track.getCapabilities?.().deviceId
  if (devices.some(d => d.deviceId === id)) {
    return id
  }
  // fallback to the default first one
  return devices[0].deviceId
}
