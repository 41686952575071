import type { Mediasoup, SetMediasoupState } from '../type-ui'

export const _disableScreenshare = async (
  m: Mediasoup,
  set: SetMediasoupState,
) => {
  set(s => {
    const p = s.peers[0]
    p.screenshareAudio?.track.stop()
    p.screenshareVideo?.track.stop()
    delete p.screenshareAudio
    delete p.screenshareVideo
  })
  //
  const p1 = Object.values(m.send.producers).find(
    p => p.appData.type === 'shareVideo',
  )
  if (p1) {
    p1.track?.stop()
    p1.close()
    await m.protoo.request('closeProducer', {
      producerId: p1.id,
    })
    delete m.send.producers[p1.id]
  }
  //
  const p2 = Object.values(m.send.producers).find(
    p => p.appData.type === 'shareAudio',
  )
  if (p2) {
    p2.track?.stop()
    p2.close()
    await m.protoo.request('closeProducer', {
      producerId: p2.id,
    })
    delete m.send.producers[p2.id]
  }
}
