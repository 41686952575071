import { notification } from 'antd'

export const showError = (
  message: string,
  err?: any,
  isLightTheme?: boolean,
): void => {
  const errMessage = err
    ? (typeof err === 'string' ? err : err.message) || `${err}`
    : ''
  notification.error({
    message: 'Error',
    description: (
      <>
        {`${message}`}
        {errMessage && (
          <strong>
            <br />
            <small>{`${errMessage}`}</small>
          </strong>
        )}
      </>
    ),
    className: isLightTheme ? 'noti-error-light' : 'noti-error-dark',
  })
  // Call console.error to trigger error report like rollbar or something?
  console.error(message, err)
}
