import { observer } from 'mobx-react-lite'

import { S } from '../../../context/store'
import type { WrapperProps } from './type'

export const WarmnessDiv = observer((props: WrapperProps) => {
  const { warmnessColor } = S.local

  const { style, isWarmTheme, children, ...prop } = props
  return (
    <div
      style={{ background: isWarmTheme ? warmnessColor : undefined, ...style }}
      {...prop}
    >
      {children}
    </div>
  )
})
