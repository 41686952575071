export enum EStatusDestination {
  ACTIVE = 1,
  INACTIVE = 0,
}
export enum EDestinationActionType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}
export enum OutputType {
  // rtmp-like
  Custom = 'Custom',
  RTMP = 'RTMP',
  SRT = 'SRT',
  Youtube = 'Youtube',
  Linkedin = 'Linkedin',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  // puppeteer share screen
  Zoom = 'Zoom',
  Meet = 'Meet',
}
