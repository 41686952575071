import { Form as AntForm } from 'antd'
import type { PropsWithChildren } from 'react'

import css from './style.module.scss'

import type { FormItemProps } from './type'

export const FormItem = (props: PropsWithChildren<FormItemProps>) => {
  const { children, className, ...formItemProps } = props
  return (
    <AntForm.Item
      {...formItemProps}
      className={`${className} ${css.FormItemStyled} `}
    >
      {children}
    </AntForm.Item>
  )
}
