import { S } from '../../store'

export const changeSlotEditing = (id: number, layoutId: number) => {
  const $ = S.layout

  const isSubtitleEdit = $.subtitleSlot

  const layout = $.slotsEdit[layoutId] ?? []
  const slotEditExit = layout?.find(s => s.enableEdit)
  const sl = layout?.find(s => s.id === id)
  if (slotEditExit) {
    slotEditExit.enableEdit = false
    slotEditExit.isShowCropMode = false
  }
  if (isSubtitleEdit) {
    isSubtitleEdit.enableEdit = false
  }

  if (sl) {
    sl.enableEdit = true
  }
}
