import { Table as AntTable } from 'antd'
import clsx from 'clsx'

import css from './style.module.scss'

import type { TableProps } from './type'

export const Table = <RecordType extends object = any>(
  props: TableProps<RecordType>,
) => {
  const { containerClassName, className } = props
  return (
    <div className={clsx(css.TableWrapper, containerClassName)}>
      <AntTable {...props} className={clsx(css.TableStyled, className)} />
    </div>
  )
}
