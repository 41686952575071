import type { TMediaItem } from '../studio/WebrtcStore'

const color: Array<TMediaItem> = [
  {
    id: '5',
    value: '#FFFFFF',
    isDefault: true,
    mediaType: 'color',
    position: 1,
  },
  {
    id: '6',
    value: '#000000',
    isDefault: true,
    mediaType: 'color',
    position: 2,
    selected: true,
  },
  {
    id: '7',
    value: '#4285F4',
    isDefault: true,
    mediaType: 'color',
    position: 3,
  },
  {
    id: '8',
    value: '#34A853',
    isDefault: true,
    mediaType: 'color',
    position: 4,
  },
  {
    id: '9',
    value: '#FBBC05',
    isDefault: true,
    mediaType: 'color',
    position: 5,
  },
  {
    id: '10',
    value: '#EA4335',
    isDefault: true,
    mediaType: 'color',
    position: 6,
  },
  {
    id: '11',
    value: 'linear-gradient(135deg,#BBF7C6 0%,#FFC572 51%,#FF5890 100%)',
    isDefault: true,
    mediaType: 'color',
    position: 7,
  },
]

export const TemplateDefault = {
  color,
}
