import { S } from '#HACK_FOR_RN_ONLY/src/context/store'

import { getLayoutRatio } from '../../actions/getLayoutRatio'

type Position = {
  top: number
  left: number
  width: number
  height: number
}

export const calculateSlot = (slot: Position, type?: string) => {
  const newSlot: Position = slot
  const { top, left, width, height } = slot

  const { ratioLW, ratioTH } = getLayoutRatio()
  const { customRatio } = S.webrtc

  if (customRatio >= 1 || type === 'title') {
    Object.assign(newSlot, {
      top: top * ratioTH,
      left: left * ratioLW,
      width: width * ratioLW,
      height: height * ratioTH,
    })
  } else {
    Object.assign(newSlot, {
      left: ratioTH * top * customRatio,
      top: (ratioLW * left) / customRatio,
      width: ratioTH * height * customRatio,
      height: (ratioLW * width) / customRatio,
    })
  }
  return newSlot
}

export const calculateUpdateSlot = (slot: Position, type?: string) => {
  const newSlot: Position = slot
  const { top, left, width, height } = slot

  const { ratioLW, ratioTH } = getLayoutRatio()
  const { customRatio } = S.webrtc

  if (customRatio >= 1 || type) {
    Object.assign(newSlot, {
      top: top / ratioTH,
      left: left / ratioLW,
      width: width / ratioLW,
      height: height / ratioTH,
    })
  } else {
    Object.assign(newSlot, {
      left: (top / ratioLW) * customRatio,
      top: left / ratioTH / customRatio,
      width: (height / ratioLW) * customRatio,
      height: width / ratioTH / customRatio,
    })
  }
  return newSlot
}
