import { Dropdown } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import type { AppDropdownProps } from './type'

export const BeamDropdown: FC<AppDropdownProps> = props => {
  const { className, ...dropdownProps } = props

  return (
    <Dropdown
      getPopupContainer={trigger => trigger.parentElement ?? trigger}
      className={clsx(css.DropdownContainer, className)}
      {...dropdownProps}
    />
  )
}
