import { Button as AntdButton } from 'antd'
import clsx from 'clsx'
import type { CSSProperties } from 'react'

import css from './style.module.scss'

import { getStyled } from './getStyled'
import type { ButtonProps } from './type'

export const Button = (props: ButtonProps) => {
  const { containerType = 'primary', ...buttonProps } = props
  return (
    <AntdButton
      {...buttonProps}
      className={clsx(
        css.ButtonStyled,
        {
          [css.ButtonPrimary]: containerType === 'primary',
          [css.ButtonDark]: containerType === 'dark',
          [css.ButtonGrey]: containerType === 'grey',
          [css.ButtonDanger]: containerType === 'danger',
          [css.ButtonOnlyContent]: containerType === 'onlyContent',
          [css.ButtonWhite]: containerType === 'white',
        },
        buttonProps.className,
      )}
      style={
        {
          ...buttonProps.style,
          '--pButtonStyled': buttonProps.children
            ? getStyled(buttonProps.sizecustom, buttonProps.typeCustom).padding
            : '16px',
          '--borderRadiusButtonStyled': buttonProps.children
            ? getStyled(buttonProps.sizecustom, buttonProps.typeCustom)
                .borderRadius
            : '32px',
          '--fontSizeButtonStyled': buttonProps.children
            ? getStyled(buttonProps.sizecustom, buttonProps.typeCustom).fontSize
            : '13px',
        } as CSSProperties
      }
      type={'primary'}
    />
  )
}
