export const parseUnlimited = (
  value: any,
  outputFormat: string | number | null = null,
  except?: boolean,
) =>
  except
    ? 'Unlimited'
    : value === 'Infinity'
      ? 'Unlimited'
      : outputFormat ?? value
