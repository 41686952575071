import { observer } from 'mobx-react-lite'

import css from './SlotIconAction.module.scss'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../../context/store'
import { Helper } from '../../../context/store/studio/helper'
import { useCheckClick } from '../../../utils/useCheckClick'

type TSlotIcon = {
  position: number
  slotType: string
  slotMediaId?: string
  slotPeerId?: string
  isShowRemove?: boolean
  isShowFullScreen?: boolean
}

export const SlotIconAction = observer(
  ({
    position,
    slotType,
    slotMediaId = '',
    isShowRemove = true,
    isShowFullScreen = true,
    slotPeerId = '',
  }: TSlotIcon) => {
    const slotPosition = position || 0
    const closeBtnId = `closeSlotMedia${slotPosition}${Helper.generateGuid()}`
    const fullScreenId = `fullScreenMedia${slotPosition}`
    const {
      selectedIndexLayout,
      isSelectedLayoutFullStream,
      layoutFullStream,
      isOnlyViewStream,
      toggleSlotFullStream,
      removePeerOnLayout,
      updateEmitAndSaveSettings,
      isViewmodeParticipant,
      removeMediaFromCanvas,
    } = S.webrtc
    const { enableEditLayout, hasAnyModalOpen } = S.layout
    const isSlotFullStream =
      isSelectedLayoutFullStream &&
      layoutFullStream[selectedIndexLayout] === position
    useCheckClick([closeBtnId], isClicked => {
      if (isClicked) {
        handleRemoveSlot()
      }
    })
    useCheckClick([fullScreenId], isClicked => {
      if (isClicked) {
        handleToggleFullStream()
      }
    })
    const handleRemoveSlot = () => {
      // Remove media
      if (slotType !== 'peer' && slotMediaId) {
        removeMediaFromCanvas(slotMediaId, position)
        updateEmitAndSaveSettings()
        return
      }
      // Remove peer/share screen
      if (slotPeerId) {
        removePeerOnLayout(slotPosition)
        updateEmitAndSaveSettings()
        return
      }
      // Remove slot layout
    }

    const handleToggleFullStream = () => {
      toggleSlotFullStream(slotPosition)
      if (slotType !== 'peer') {
        updateEmitAndSaveSettings()
      }
    }

    if (isOnlyViewStream) {
      return null
    }
    if (!isShowRemove && !isShowFullScreen) {
      return null
    }

    const r = S.webrtc.ratioScaleLayout || 1
    const fullScreenStyle = {
      '--bSlotIconFullScreen': r * 8 + 'px',
      '--rSlotIconFullScreen': r * 8 + 'px',
      '--fSlotIconFullScreen': r * 14 + 'px',
    }
    const minScreenStyle = {
      '--bSlotIconMinimizeScreen': r * 8 + 'px',
      '--rSlotIconMinimizeScreen': r * 8 + 'px',
      '--fSlotIconMinimizeScreen': r * 14 + 'px',
    }

    return (
      <>
        {!isShowRemove ||
        isSlotFullStream ||
        enableEditLayout ||
        isViewmodeParticipant ||
        hasAnyModalOpen ||
        (!slotMediaId && !slotPeerId) ? null : (
          <span
            id={closeBtnId}
            className={css.SlotIconRemove}
            style={{
              top: r * 8,
              right: r * 8,
            }}
          >
            <Icon icon='icon_close_popup' size={r * 10} />
          </span>
        )}

        {!isViewmodeParticipant &&
          isShowFullScreen &&
          !enableEditLayout &&
          !hasAnyModalOpen && (
            <span
              className={
                isSlotFullStream
                  ? css.SlotIconMinimizeScreen
                  : css.SlotIconFullScreen
              }
              // @ts-ignore
              style={isSlotFullStream ? minScreenStyle : fullScreenStyle}
              id={fullScreenId}
            >
              <Icon
                icon={
                  isSlotFullStream ? 'icon_minimize' : 'icon_menu_fullscreen'
                }
                size={r * 14}
              />
            </span>
          )}
      </>
    )
  },
)
