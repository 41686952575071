import { separateLogic } from '../../../../../../#/reactjs/redux'
import { S } from '../../../../context/store'
import type { ButtonMode } from './type'

export const createComponent = separateLogic(() => {
  const { sessionMode } = S.webrtc
  const modes: ButtonMode[] = [
    {
      icon: 'Upload',
      type: 'upload',
    },
    {
      icon: 'Menu',
      type: 'session',
    },
    {
      icon: 'Edit',
      type: 'note',
    },
  ]
  return { modes, sessionMode }
})
