import type { FC, RefObject } from 'react'
import { useRef } from 'react'
import { useInterval } from 'react-use'

export const VideoResolution: FC<TVideoResolutionProps> = ({
  vref,
  onChange,
}) => {
  const cache = useRef([0, 0])
  useInterval(() => {
    const v = vref.current
    const r = v ? [v.videoWidth, v.videoHeight] : [0, 0]
    if (r[0] !== cache.current[0] || r[1] !== cache.current[1]) {
      cache.current = r
      onChange(r)
    }
  }, 300)

  return null
}

export type TVideoResolutionProps = {
  vref: RefObject<HTMLVideoElement>
  onChange(resolution: number[]): void
}
