import { calculateUpdateSlot } from '#HACK_FOR_RN_ONLY/src/pages/Studio/components/UploadTranscript/calculateSlot'

import type { ILayoutItem, IMeasureLine } from '../../store/shared/LayoutStore'
import { ECropMode, EMeasureLineType } from '../../store/shared/LayoutStore'
import { calculateCrop } from '../actions/calculateCrop'
import type { IOptionChangeResize } from './CustomLayoutType'

export const getSizeChildWhenResize = (
  s: ILayoutItem,
  coordinates: { cX: number; cY: number },
  options: IOptionChangeResize,
  scale: { sX: number; sY: number },
  ratio?: { rX: number; rY: number },
  layoutOrigin?: ILayoutItem,
  positionDiff: {
    top: number
    left: number
  } = {
    top: 0,
    left: 0,
  },
) => {
  const { cX, cY } = coordinates
  const { sY, sX } = scale
  const { switchLR, switchTB } = options
  const width = s.width * sX
  const height = s.height * sY
  let top = cY + (s.top - cY) * sY
  let left = cX + (s.left - cX) * sX
  if (switchTB) {
    top = cY - (s.top - cY + s.height) * sY
  }
  if (switchLR) {
    left = cX - (s.left - cX + s.width) * sX
  }
  if (s.cropMode === ECropMode.Crop) {
    const stateCrop = {
      ...(layoutOrigin as ILayoutItem),
      top,
      left,
      width,
      height,
    }
    return {
      ...s,
      ...calculateCrop(s, stateCrop),
    }
  }
  return {
    ...s,
    ...calculateUpdateSlot({
      top: top + positionDiff.top,
      left: left + positionDiff.left,
      width,
      height,
    }),
  }
}
export const getLinesMeasure = (
  l1: ILayoutItem,
  layoutParent: { top: number; left: number; width: number; height: number },
  l0: ILayoutItem,
): IMeasureLine[] => {
  let lines: IMeasureLine[] = []

  const { top: lT, left: lL, width: lW, height: lH } = layoutParent
  const top = l0.top
  const left = l0.left
  const width = l0.width
  const height = l0.height
  const { left: l, top: t, width: w, height: h } = l1
  const lineTop: IMeasureLine = {
    top: lT,
    left: left + width / 2 + lL,
    width: 2,
    height: top,
    typeLine: EMeasureLineType.TENSE,
    direction: 'vertical',
  }
  const lineLeft: IMeasureLine = {
    top: top + height / 2 + lT,
    left: lL,
    width: left,
    height: 2,
    typeLine: EMeasureLineType.TENSE,
    direction: 'horizontal',
  }
  const lineBottom: IMeasureLine = {
    top: top + height + lT,
    left: left + width / 2 + lL,
    width: 2,
    height: lH - (top + height),
    typeLine: EMeasureLineType.TENSE,
    direction: 'vertical',
  }
  const lineRight: IMeasureLine = {
    top: top + height / 2 + lT,
    left: left + width + lL,
    width: lW - (left + width),
    height: 2,
    typeLine: EMeasureLineType.TENSE,
    direction: 'horizontal',
  }

  if (l1.id !== l0.id) {
    if (
      Math.max(left, l) - Math.min(l + w, left + width) > 0 &&
      top + height / 2 < t + h &&
      top + height / 2 > t
    ) {
      const line: IMeasureLine = {
        top: (l0?.top ?? 0) + (l0?.height ?? 0) / 2 + lT,
        left: Math.min(l + w, left + width) + lL,
        width: Math.max(left - (l + w), l - (left + width)),
        height: 2,
        typeLine: EMeasureLineType.TENSE,
        direction: 'horizontal',
      }
      lines = [line]
      return lines
    }

    if (
      Math.max(top, t) - Math.min(t + h, top + height) > 0 &&
      left + width / 2 > l &&
      left + width / 2 < l + w
    ) {
      const line: IMeasureLine = {
        top: Math.min(t + h, top + height) + lT,
        left: left + width / 2 + lL,
        width: 2,
        height: Math.max(top - (t + h), t - (top + height)),
        typeLine: EMeasureLineType.TENSE,
        direction: 'vertical',
      }
      lines = [line]
      return lines
    }
    if (
      Math.min(l + w, left + w) - Math.max(left, l) > 0 &&
      Math.min(t + h, top + height) - Math.max(top, t) > 0
    ) {
      const iL = Math.min(left, l)
      const iT = Math.min(top, t)
      const line1: IMeasureLine = {
        top: top + (t + h - top) / 2 + lT,
        left: iL + lL,
        width: Math.max(left, l) - iL,
        height: 2,
        typeLine: EMeasureLineType.TENSE,
        direction: 'horizontal',
      }
      const line2: IMeasureLine = {
        top: iT + lT,
        left: left + (l + w - left) / 2 + lL,
        width: 2,
        height: Math.max(top, t) - iT,
        typeLine: EMeasureLineType.TENSE,
        direction: 'vertical',
      }
      const line3: IMeasureLine = {
        top: top + (t + h - top) / 2 + lT,
        left: Math.min(left + width, l + w) + lL,
        width: Math.max(left + width, l + w) - Math.min(left + width, l + w),
        height: 2,
        typeLine: EMeasureLineType.TENSE,
        direction: 'horizontal',
      }
      const line4: IMeasureLine = {
        top: Math.min(top + height, t + h) + lT,
        left: left + (l + w - left) / 2 + lL,
        width: 2,
        height: Math.max(top + height, t + h) - Math.min(top + height, t + h),
        typeLine: EMeasureLineType.TENSE,
        direction: 'vertical',
      }
      lines = [line1, line2, line3, line4]
      return lines
    }
    if (Math.max(left, l) - Math.min(l + w, left + width) > 0) {
      const w1 = Math.max(left, l) - Math.min(l + w, left + width)
      const t1 = top + height / 2
      const line1: IMeasureLine = {
        top: t1 + lT,
        left: (l - left > 0 ? left + width : left - w1) + lL,
        width: w1,
        height: 2,
        typeLine: EMeasureLineType.TENSE,
        direction: 'horizontal',
      }
      const isFromBottom = top - t > 0

      const h2 = isFromBottom
        ? Math.min(t1 - t, t1 - (t + h))
        : Math.min(t - t1, t + h - t1)

      const line2: IMeasureLine = {
        top: (isFromBottom ? t1 - h2 : t1) + lT,
        left: (l - left > 0 ? left + width + w1 : left - w1) + lL,
        width: 2,
        height: h2,
        typeLine: EMeasureLineType.DOT,
        direction: 'vertical',
      }
      lines = [line1, line2]
    }
    if (Math.max(top, t) - Math.min(t + h, top + height) > 0) {
      const h1 = Math.max(top, t) - Math.min(t + h, top + height)
      const left1 = left + width / 2
      const line1: IMeasureLine = {
        top: (t - top > 0 ? top + height : top - h1) + lT,
        left: left1 + lL,
        width: 2,
        height: h1,
        typeLine: EMeasureLineType.TENSE,
        direction: 'vertical',
      }
      const isFromRight = left - l > 0

      const w2 = isFromRight
        ? Math.min(left1 - l, left1 - (l + w))
        : Math.min(l - left1, l + w - left1)

      const line2: IMeasureLine = {
        top: (t - top > 0 ? top + height + h1 : top - h1) + lT,
        left: (isFromRight ? left1 - w2 : left1) + lL,
        width: w2,
        height: 2,
        typeLine: EMeasureLineType.DOT,
        direction: 'horizontal',
      }
      lines = [...lines, line1, line2]
    }
    return lines
  }
  lines = [lineBottom, lineLeft, lineRight, lineTop]
  return lines
}
