import { S } from '../../store'

export const forwardSlotInEditList = (
  id: number,
  newIndex: number,
  layoutId: number,
) => {
  const $ = S.layout
  const layout = $.slotsEdit[layoutId] ?? []
  const sl = layout.find(s => s.id === id)
  const slPre = layout.find(s => s.zIndex === newIndex)
  if (sl) {
    if (slPre) {
      slPre.zIndex = sl.zIndex
    }
    sl.zIndex = newIndex
  }
}
