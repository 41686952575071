import { ERadioSize } from './type'

export const getSizeRadio = (sizeType?: ERadioSize | string) => {
  switch (sizeType) {
    case ERadioSize.M:
      return '24px'
    case ERadioSize.S:
      return '20px'
    case ERadioSize.XS:
      return '16px'
    default:
      return '24px'
  }
}
