import { Form } from 'antd'
import { useEffect } from 'react'

import { separateLogic } from '##/reactjs/redux'
import { ModalService } from '#HACK_FOR_RN_ONLY/src/components/Modal/Modal'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'
import {
  useGqlCreateOutput,
  useGqlUpdateOutput,
} from '#HACK_FOR_RN_ONLY/src/context/gql/codegen'

import type { ContentProps } from './type'

export const createComponent = separateLogic(
  (
    props: ContentProps & {
      onRemoveItem?: () => void
      isUpdate?: boolean
    },
  ) => {
    const [form] = Form.useForm<{
      key: string
      InstagramName: string
      InstagramKey: string
    }>()
    const { isUpdate, sessionId, onRemoveItem, destination } = props

    useEffect(() => {
      if (destination) {
        const data = destination?.data

        form.setFieldsValue({
          InstagramName: destination?.name ?? '',
          InstagramKey: isUpdate ? data?.rtmp?.key ?? '' : '',
        })
      }
    }, [destination])

    const [, executeCreateDestination] = useGqlCreateOutput()
    const [, executeUpdateDestination] = useGqlUpdateOutput()
    const updateDestination = async () => {
      const des = {
        rtmp: {
          url: 'rtmps://edgetee-upload-xsp2-1.xx.fbcdn.net:443/rtmp/',
          key: form.getFieldValue('InstagramKey'),
        },
      }
      const response = await executeUpdateDestination({
        id: destination?.id || '',
        data: des,
        sessionId,
        name: form.getFieldValue('InstagramName'),
      })
      if (response.data?.updateOutput) {
        ToastService.success({ content: 'Update success' })
        ModalService.hide()
      } else if (response.error) {
        ToastService.error({ content: response.error?.message })
      }
    }

    const addDestination = async () => {
      const des = {
        rtmp: {
          url: 'rtmps://edgetee-upload-xsp2-1.xx.fbcdn.net:443/rtmp/',
          key: form.getFieldValue('InstagramKey'),
        },
      }
      const response = await executeCreateDestination({
        data: {
          data: { ...des, name: form.getFieldValue('InstagramName') },
          sessionId,
          type: 'Instagram',
        },
      })
      if (response.data?.createOutput) {
        ToastService.success({ content: 'Update success' })
        ModalService.hide()
      } else if (response.error) {
        ToastService.error({ content: response.error?.message })
      }
    }

    const onSubmit = async () => {
      try {
        await form.validateFields()
        if (isUpdate) {
          updateDestination()
        } else {
          if (onRemoveItem) {
            onRemoveItem()
          }
          addDestination()
        }
      } catch (e) {
        console.log(e)
      }
    }
    return {
      onSubmit,
      form,
    }
  },
)
