import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import css from './ParticipantJoinSession.module.scss'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Popover } from '../../../components/base/Popover'
import { S } from '../../../context/store'
import { onPreviewBlurCam } from '../actions/onBlurCam'
import { onPreviewDeviceChange } from '../actions/onPreviewDeviceChange'
import { onPreviewDeviceToggle } from '../actions/onPreviewDeviceToggle'
import { Player } from './Player'
import { PreviewDeviceDropdown } from './PreviewDeviceDropdown'

const $ = S.webrtc

export const ParticipantJoinSession = observer(({ handleFinish }: any) => (
  <div>
    <div className={css.Header}>
      <div className={css.Title}>Let's check your devices</div>
      <div className={css.Subtitle}>
        Make sure you connected the audio and video devices before joining.
        Select the needed one if you have multiple.
      </div>
    </div>
    <div className={css.Content}>
      <div className={css.Row}>
        <div className={css.Player}>
          {!$.cams.length ? (
            <div className={css.NoCam}>No camera found</div>
          ) : $.camDisabled ? (
            <div className={css.NoCam}>Your camera is off</div>
          ) : (
            <Player
              videoTrack={$.preview?.camTrack}
              audioTrack={$.preview?.micTrack}
              muted
              showVolumeLevel
            />
          )}
          <div className={css.Control}>
            <div className={css.ControlItem}>
              {$.mics.length ? (
                <div onClick={() => onPreviewDeviceToggle('mic')}>
                  <span className={css.PopoverIconButton}>
                    <Icon
                      icon={$.micMuted ? 'icon_mic_off' : 'icon_mic'}
                      size={15}
                      color='#e1e1e9'
                    />
                  </span>
                </div>
              ) : (
                <Popover
                  content={
                    <div className={`popover-content-a ${css.PopoverNotify}`}>
                      <div className={css.PopoverTitle}>
                        Can't find your microphone
                      </div>
                      <div className={css.PopoverSubtitle}>
                        Check your system settings to make sure that a
                        microphone is available/ If not plug one in. You might
                        then need to restart your browser
                      </div>
                    </div>
                  }
                >
                  <span
                    className={clsx({
                      [css.PopoverIconButton]: true,
                    })}
                  >
                    <Icon icon='icon_mic_off' size={15} color='#e1e1e9' />
                  </span>
                  <span className={css.PopoverImgError}>
                    <Icon icon='Shape' size={15} color='#e1e1e9' />
                  </span>
                </Popover>
              )}
            </div>
            <div className={css.ControlItem}>
              {$.cams.length ? (
                <div onClick={() => onPreviewDeviceToggle('cam')}>
                  <span className={css.PopoverIconButton}>
                    <Icon
                      icon={$.camDisabled ? 'icon_camera_off' : 'icon_camera'}
                      size={15}
                      color='#e1e1e9'
                    />
                  </span>
                </div>
              ) : (
                <Popover
                  content={
                    <div className={`popover-content-a ${css.PopoverNotify}`}>
                      <div className={css.PopoverTitle}>
                        Can't find your camera
                      </div>
                      <div className={css.PopoverSubtitle}>
                        Check your system settings to make sure that a camera is
                        available/ If not plug one in. You might then need to
                        restart your browser
                      </div>
                    </div>
                  }
                >
                  <span className={css.PopoverIconButton}>
                    <Icon icon='icon_camera_off' size={15} color='#e1e1e9' />
                  </span>
                  <span className={css.PopoverImgError}>
                    <Icon icon='Shape' size={15} color='#e1e1e9' />
                  </span>
                </Popover>
              )}
            </div>
          </div>
          <div className={css.Settings}>
            {$.isBlurSupported ? (
              <div onClick={onPreviewBlurCam}>
                <span
                  className={clsx(css.PopoverIconButton, {
                    [css.iconButtonSelected]: $.camBlurred,
                    [css.iconButtonDisabled]: !$.cams.length || $.camDisabled,
                  })}
                >
                  <Icon icon='Star-Filled' size={15} color='#e1e1e9' />
                </span>
              </div>
            ) : (
              <Popover
                content={
                  <div className={`popover-content-a ${css.PopoverNotify}`}>
                    <div className={css.PopoverTitle}>Blur not supported</div>
                    <div className={css.PopoverSubtitle}>
                      This feature is currently unavailable due to technical
                      limitations of your system. You might need to change your
                      browser or upgrade your system hardware.
                    </div>
                  </div>
                }
              >
                <span className={css.PopoverIconButton}>
                  <Icon icon='Star-Striked' size={15} color='#e1e1e9' />
                </span>
                <span className={css.PopoverImgError}>
                  <Icon icon='Shape' size={15} color='#e1e1e9' />
                </span>
              </Popover>
            )}
          </div>
        </div>
        <div className={css.Form}>
          <div className={css.FormItem}>
            <PreviewDeviceDropdown
              type='cam'
              onChange={id => onPreviewDeviceChange('cam', id)}
            />
          </div>
          <div className={css.FormItem}>
            <PreviewDeviceDropdown
              type='mic'
              onChange={id => onPreviewDeviceChange('mic', id)}
            />
          </div>
          <div className={css.FormItem}>
            <PreviewDeviceDropdown
              type='speaker'
              onChange={id => onPreviewDeviceChange('speaker', id)}
            />
          </div>
          <div>
            <Button
              onClick={handleFinish}
              className={css.BtnSubmit}
              sizecustom='M13'
            >
              Join Session
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
))
