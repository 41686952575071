import type { UPromise } from '##/shared/ts'

import { showError } from '../../store/studio/utils2'

export const getTrack = (
  k: 'audio' | 'video',
  devices: MediaDeviceInfo[],
  deviceId?: string,
  facingMode?: VideoFacingModeEnum,
): UPromise<MediaStreamTrack> => {
  const valid = deviceId && devices.some(d => d.deviceId === deviceId)
  let constraints: MediaStreamConstraints = {
    [k]: valid ? { deviceId: { exact: deviceId } } : true,
    [k !== 'audio' ? 'audio' : 'video']: false,
  }
  if (k === 'video' && facingMode) {
    constraints = {
      ...constraints,
      video: {
        ...(typeof constraints.video === 'object' ? constraints.video : {}),
        facingMode,
      },
    }
  }
  return navigator.mediaDevices
    .getUserMedia(constraints)
    .then(stream => {
      const tracks =
        stream[k === 'audio' ? 'getAudioTracks' : 'getVideoTracks']()
      const track = tracks[0]
      tracks.filter(t => t.id !== track.id).forEach(t => t.stop())
      return track
    })
    .catch(err => {
      if (!valid) {
        showError('Failed to access camera/microphone', err)
        return
      }
      return getTrack(k, devices)
    })
}
