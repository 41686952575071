import { Radio } from 'antd'
import type { FC } from 'react'

import css from './style.module.scss'

import { getSizeRadio } from './getSizeRadio'
import type { AppTextProps, TRadioContainerProps } from './type'
import { ERadioSize } from './type'

export const BeamRadio: FC<AppTextProps> = props => {
  const { containerProps, ...radioProps } = props
  const defaultContainerProps: TRadioContainerProps = {
    size: containerProps?.size ?? ERadioSize.M,
  }
  return (
    <div
      {...defaultContainerProps}
      style={{
        // @ts-ignore
        '--wAntRadioInner': getSizeRadio(defaultContainerProps.size),
        '--hAntRadioInner': getSizeRadio(defaultContainerProps.size),
        '--tAntRadioInnerAfter':
          defaultContainerProps.size === ERadioSize.S ||
          defaultContainerProps.size === ERadioSize.XS
            ? '11px'
            : '12px',
        '--lAntRadioInnerAfter':
          defaultContainerProps.size === ERadioSize.S ||
          defaultContainerProps.size === ERadioSize.XS
            ? '11px'
            : '12px',
        '--wAntRadioInnerAfter':
          defaultContainerProps.size === ERadioSize.S
            ? '11.67px'
            : defaultContainerProps.size === ERadioSize.XS
              ? '8px'
              : '14px',
        '--hAntRadioInnerAfter':
          defaultContainerProps.size === ERadioSize.S
            ? '11.67px'
            : defaultContainerProps.size === ERadioSize.XS
              ? '8px'
              : '14px',
      }}
      className={css.RadioContainer}
    >
      <Radio {...radioProps}></Radio>
    </div>
  )
}
