import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type {
  CSSProperties,
  DragEvent,
  FC,
  MouseEvent,
  MutableRefObject,
  ReactNode,
} from 'react'
import { useEffect, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import css from './CustomLayoutResizeable.module.scss'

import { useLongPress } from '../../../utils/useLongPress'
import type * as LayoutStore from '../../store/shared/LayoutStore'
import type {
  IActionOutsize,
  IOptionChangeResize,
} from '../utils/CustomLayoutType'
import { EDirection } from '../utils/CustomLayoutType'
import { ResizeOutsideApi } from './CustomLayoutReszieOutside'

const initialState = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  radius: 0,
}

interface IProps {
  layout: LayoutStore.ILayoutItem
  index: number
  slotsOtherEditing: LayoutStore.ILayoutItem[]
  containerProps: LayoutStore.ILayoutSize
  children?: ReactNode
  isEdit?: boolean
  isEditMode?: boolean
  isMulti?: boolean
  onChange?: (
    layoutInfo: LayoutStore.ILayoutItem,
    options?: IOptionChangeResize,
    fromKeyboard?: boolean,
  ) => void
  onLayoutClick?: (e: MouseEvent) => void
  onLongPresLayout?: (e: MouseEvent) => void
  onChangeSize?: (w: number, h: number) => void
  style?: CSSProperties
  isFullScreen?: boolean
  id?: string
  onMouseEnter?(e: MouseEvent): void
  onMouseLeave?(e: MouseEvent): void
  onKeyDown?(e: KeyboardEvent): void
  onKeyUp?(e: KeyboardEvent): void
  onDoubleClick?(e: MouseEvent): void
  changeDragging?: (value: boolean) => void
  changeResizing?: (value: boolean) => void
  handleOnResizeStart?: () => void
  ratio?: number
  enableEditLayout?: boolean
  actionsTop?: IActionOutsize[]
  customTopElement?: React.ReactElement
  actionsLeft?: IActionOutsize[]
  disableDrag?: boolean
  customWidth?: string | number
  customHeight?: string | number
  outSizeId?: string
  isVideoOverlay?: boolean
  showControl?: boolean
  forceHoverEffect?: boolean
  disableResizeInput?: boolean
  showAlignmentIndicator?: boolean
  showResizeBtn?: boolean
  showRoundedBtn?: boolean
  showResizeEdeges?: boolean
  showSizeInfo?: boolean
  isEdgesHorizontalOnly?: boolean
  alignTopAction?: 'left' | 'center'
  containerStyle?: React.CSSProperties
  onSizeAutoChange?: (width?: number, height?: number) => void
  canToOutside?: boolean
}
export const Resizeable: FC<IProps> = observer(
  ({
    layout,
    index,
    containerProps,
    isEdit,
    isEditMode,
    onChange,
    onLayoutClick,
    onLongPresLayout,
    isMulti,
    style,
    isFullScreen,
    id,
    onMouseEnter,
    onMouseLeave,
    changeDragging,
    changeResizing,
    onChangeSize,
    enableEditLayout,
    actionsTop,
    customTopElement,
    actionsLeft,
    onKeyDown,
    onKeyUp,
    ratio,
    slotsOtherEditing,
    children,
    disableDrag = false,
    outSizeId = '',
    isVideoOverlay,
    handleOnResizeStart,
    showControl = true,
    forceHoverEffect = false,
    disableResizeInput,
    showAlignmentIndicator,
    showResizeBtn,
    showRoundedBtn,
    showResizeEdeges,
    showSizeInfo,
    isEdgesHorizontalOnly,
    alignTopAction,
    customWidth,
    customHeight,
    containerStyle,
    onSizeAutoChange,
    onDoubleClick,
    canToOutside = false,
  }) => {
    const maxLeft = containerProps.width ?? 0
    const maxTop = containerProps.height ?? 0
    // for renders
    const setLayoutPartial = (
      newState: Partial<LayoutStore.ILayoutItem>,
      options?: IOptionChangeResize,
      fromKeyBoard?: boolean,
    ) => {
      onChange?.({ ...layout, ...newState }, options, fromKeyBoard)
    }
    const isEditRef = useRef(isEdit)
    const layoutRef = useRef<HTMLDivElement | null>(null)
    const { width, height } = useResizeObserver({ ref: layoutRef })
    // for handlers, hold initial value
    const initialPositionRef = useRef({ x: 0, y: 0 })
    const initialPositionRef2 = useRef({ x: 0, y: 0 })
    const initialLayoutRef = useRef({ ...initialState })
    const initialXRoundedRef = useRef(0)
    const [isDragging, setIsDragging] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    // on resize icon drag start
    // or on slot drag start (should not prevent default)

    useEffect(() => {
      onSizeAutoChange?.(width, height)
    }, [width, height])

    const resizeDragTransferCleanup = useRef<Function>()
    const onResizeDragStart = (e: DragEvent, shouldUpdateState = true) => {
      setTransferData(e, resizeDragTransferCleanup)
      const div = layoutRef.current
      if (!div) {
        return
      }

      initialPositionRef.current = {
        x: e.clientX,
        y: e.clientY,
      }
      initialPositionRef2.current = {
        x: e.clientX,
        y: e.clientY,
      }
      initialLayoutRef.current = {
        width: div.offsetWidth,
        height: div.offsetHeight,
        left: div.offsetLeft,
        top: div.offsetTop,
        radius: initialLayoutRef.current.radius,
      }
      if (shouldUpdateState) {
        setIsResizing(true)
        changeResizing?.(true)
      }
      handleOnResizeStart?.()
    }
    const [roundedHandling, setRoundedHandling] = useState<
      EDirection | undefined
    >(undefined)

    const handleArrowKey = (e: KeyboardEvent) => {
      onKeyDown?.(e)
      if (
        (e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight' &&
          e.key !== 'ArrowUp' &&
          e.key !== 'ArrowDown') ||
        !isEditRef.current
      ) {
        return
      }
      let moveValue = 1 * (ratio ?? 0)
      if (e.shiftKey) {
        moveValue = 10 * (ratio ?? 0)
      }
      const { width: w, height: h, top: t, left: l } = layout
      let top = t
      let left = l
      switch (e.key) {
        case 'ArrowLeft':
          left -= moveValue
          break
        case 'ArrowRight':
          left += moveValue
          break
        case 'ArrowUp':
          top -= moveValue
          break
        case 'ArrowDown':
          top += moveValue
          break
      }

      top = Math.max(Math.min(top, maxTop - h), 0)
      left = Math.max(Math.min(left, maxLeft - w), 0)
      if (top !== t || left !== l) {
        setLayoutPartial(
          {
            top,
            left,
          },
          undefined,
          true,
        )
      }
    }
    const handleKeyUp = (e: KeyboardEvent) => {
      onKeyUp?.(e)
    }
    useEffect(() => {
      isEditRef.current = isEdit
      if (onChange && onKeyDown) {
        addEventListener('keydown', handleArrowKey)
      } else {
        removeEventListener('keydown', handleArrowKey)
      }
      if (onKeyUp) {
        addEventListener('keyup', handleKeyUp)
      } else {
        removeEventListener('keyup', handleKeyUp)
      }
      return () => {
        removeEventListener('keydown', handleArrowKey)
        removeEventListener('keyup', handleKeyUp)
      }
    }, [isEdit, onChange, onKeyDown, onKeyUp])

    const onResizeDrag = (e: DragEvent, direction: EDirection) => {
      e.preventDefault()
      e.stopPropagation()
      let haveShiftKey = false
      if (!e.clientX && !e.clientY) {
        return
      }
      if (e.shiftKey) {
        haveShiftKey = true
      }

      const { width: w, height: h, top: t, left: l } = initialLayoutRef.current
      const aspectRatio = w / h
      const { x, y } = initialPositionRef.current
      let directionUsing = ' '

      const dx = e.clientX - x
      const dy = e.clientY - y

      let left = l
      let right = l + w
      let top = t
      let bottom = t + h
      let isFromRight = false
      let isFromBottom = false
      switch (direction) {
        case EDirection.TOP: {
          top += dy
          directionUsing = 'y'
          break
        }
        case EDirection.LEFT: {
          left += dx
          directionUsing = 'x'
          break
        }
        case EDirection.BOTTOM: {
          bottom += dy
          isFromBottom = true
          directionUsing = 'y'
          break
        }
        case EDirection.RIGHT: {
          right += dx
          isFromRight = true
          directionUsing = 'x'
          break
        }
        case EDirection.TOP_LEFT: {
          top += dy
          left += dx
          break
        }
        case EDirection.TOP_RIGHT: {
          top += dy
          right += dx
          isFromRight = true
          break
        }
        case EDirection.BOTTOM_RIGHT: {
          bottom += dy
          right += dx
          isFromRight = true
          isFromBottom = true
          break
        }
        case EDirection.BOTTOM_LEFT: {
          bottom += dy
          left += dx
          isFromBottom = true
          break
        }
      }
      const vW1 = Math.abs(right - left)
      const vH1 = Math.abs(bottom - top)

      if (haveShiftKey) {
        if (vW1 / vH1 !== aspectRatio) {
          const vH2 = vW1 / aspectRatio
          const vW2 = vH1 * aspectRatio
          switch (directionUsing) {
            case 'x':
              if (isFromBottom && top < bottom) {
                bottom = bottom + (vH2 - vH1)
              } else {
                if (top > bottom) {
                  top = top + (vH2 - vH1)
                } else {
                  top = top - (vH2 - vH1)
                }
              }
              break
            case 'y':
              if (isFromRight && left < right) {
                right = right + (vW2 - vW1)
              } else {
                left = left - (vW2 - vW1)
              }
              break
            default:
              if (isFromBottom && top < bottom) {
                bottom = bottom + (vH2 - vH1)
              } else {
                if (top > bottom) {
                  top = top + (vH2 - vH1)
                } else {
                  top = top - (vH2 - vH1)
                }
              }
              break
          }
        }
      }
      const switchLR = left > right
      if (switchLR) {
        const arr = [right, left]
        left = arr[0]
        right = arr[1]
      }
      const switchTB = top > bottom
      if (switchTB) {
        const arr = [bottom, top]
        top = arr[0]
        bottom = arr[1]
      }
      if (left < 0 && !canToOutside) {
        left = 0
        right = l + (switchLR ? 0 : w)
        const vW3 = Math.abs(right - left)
        const vH3 = Math.abs(bottom - top)
        if (haveShiftKey) {
          const vH4 = vW3 / aspectRatio
          if (isFromBottom && top < bottom) {
            bottom = bottom + (vH4 - vH3)
          } else {
            bottom = top - (vH4 - vH3)
          }
        }
      }
      if (top < 0 && !canToOutside) {
        top = 0
        bottom = t + (switchTB ? 0 : h)
        if (haveShiftKey) {
          const vW6 = Math.abs(right - left)
          const vH6 = Math.abs(bottom - top)
          const vW7 = vH6 * aspectRatio
          if (isFromRight && left < right) {
            right = right + (vW7 - vW6)
          } else {
            left = left - (vW7 - vW6)
          }
        }
      }

      if (right > maxLeft) {
        right = maxLeft
        const vW3 = Math.abs(right - left)
        const vH3 = Math.abs(bottom - top)
        if (haveShiftKey) {
          const vH4 = vW3 / aspectRatio
          if (isFromBottom && top < bottom) {
            bottom = bottom + (vH4 - vH3)
          } else {
            top = top - (vH4 - vH3)
          }
        }
      }
      if (bottom > maxTop) {
        bottom = maxTop
        if (haveShiftKey) {
          const vW6 = Math.abs(right - left)
          const vH6 = Math.abs(bottom - top)
          const vW7 = vH6 * aspectRatio
          if (isFromRight && left < right) {
            right = right + (vW7 - vW6)
          } else {
            left = left - (vW7 - vW6)
          }
        }
      }

      const newState = {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(right - left),
        height: Math.round(bottom - top),
      }

      initialPositionRef2.current = { x: e.clientX, y: e.clientY }
      setLayoutPartial(newState, { switchTB, switchLR, direction })
    }
    const onResizeDragEnd = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      changeResizing?.(false)
      setIsResizing(false)
      resizeDragTransferCleanup.current?.()
      resizeDragTransferCleanup.current = undefined
    }

    const roundedDragTransferCleanup = useRef<Function>()
    const onRoundedDragStart = (e: DragEvent) => {
      setTransferData(e, roundedDragTransferCleanup)
      changeResizing?.(true)
      setIsResizing(true)
      e.stopPropagation()
      const div = layoutRef.current
      if (!div) {
        return
      }
      initialXRoundedRef.current = e.clientX
      initialLayoutRef.current = {
        width: div.offsetWidth,
        height: div.offsetHeight,
        left: div.offsetLeft,
        top: div.offsetTop,
        radius: initialLayoutRef.current.radius,
      }
    }
    const onRoundedDrag = (e: DragEvent, direction: EDirection) => {
      e.preventDefault()
      e.stopPropagation()
      const dx = e.clientX - initialXRoundedRef.current
      initialXRoundedRef.current = e.clientX
      const shouldProceed = e.clientX && dx
      if (!shouldProceed) {
        return
      }
      const l =
        direction === EDirection.TOP_LEFT ||
        direction === EDirection.BOTTOM_LEFT
      const dr = (dx > 0 && l) || (dx < 0 && !l) ? 1 : -1
      const { radius: cr } = layout
      const radius = Math.max(Math.min(cr + dr, 50), 0)
      setLayoutPartial({ radius }) // TODO set item
    }
    const onRoundedDragEnd = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      changeResizing?.(false)
      setIsResizing(false)
      roundedDragTransferCleanup.current?.()
      roundedDragTransferCleanup.current = undefined
    }

    const onSlotDrag = (e: DragEvent) => {
      if ((!e.clientX && !e.clientY) || disableDrag || !isEdit) {
        return
      }

      // e.preventDefault()
      e.stopPropagation()

      const { width: w, height: h, top: t, left: l } = initialLayoutRef.current
      const { x, y } = initialPositionRef.current
      const dx = e.clientX - x
      const dy = e.clientY - y
      let top = t
      let left = l

      if (e.shiftKey) {
        if (Math.abs(dx) > Math.abs(dy)) {
          left += dx
        }
        if (Math.abs(dx) < Math.abs(dy)) {
          top += dy
        }
      } else {
        left += dx
        top += dy
      }
      const diffToCheck = 2
      let topV = Math[canToOutside && top < 0 ? 'min' : 'max'](
        Math.min(top, maxTop - h),
        0,
      )

      let leftV = Math[canToOutside && left < 0 ? 'min' : 'max'](
        Math.min(left, maxLeft - w),
        0,
      )
      const bottom = Math.round(topV + h)
      const right = Math.round(leftV + w)
      const centerTop = Math.round(topV + h / 2)
      const centerLeft = Math.round(leftV + w / 2)

      slotsOtherEditing.forEach(i => {
        const iTop = i.top
        const iLeft = i.left
        const iBottom = Math.round(iTop + i.height)
        const iRight = Math.round(iLeft + i.width)
        const iCenterTop = Math.round(iTop + i.height / 2)
        const iCenterLeft = Math.round(iLeft + i.width / 2)
        if (
          Math.round(topV - iTop) <= diffToCheck &&
          Math.round(topV - iTop) >= -diffToCheck
        ) {
          topV = iTop
        }
        if (
          Math.round(leftV - iLeft) <= diffToCheck &&
          Math.round(leftV - iLeft) >= -diffToCheck
        ) {
          leftV = iLeft
        }
        if (
          Math.round(bottom - iBottom) <= diffToCheck &&
          Math.round(bottom - iBottom) >= -diffToCheck
        ) {
          topV = iBottom - h
        }
        if (
          Math.round(right - iRight) <= diffToCheck &&
          Math.round(right - iRight) >= -diffToCheck
        ) {
          leftV = iRight - w
        }

        if (
          Math.round(centerTop - iCenterTop) <= diffToCheck &&
          Math.round(centerTop - iCenterTop) >= -diffToCheck
        ) {
          topV = iCenterTop - h / 2
        }
        if (
          Math.round(centerLeft - iCenterLeft) <= diffToCheck &&
          Math.round(centerLeft - iCenterLeft) >= -diffToCheck
        ) {
          leftV = iCenterLeft - w / 2
        }
      })
      if (
        Math.round(centerLeft - maxLeft / 2) <= diffToCheck &&
        Math.round(centerLeft - maxLeft / 2) >= -diffToCheck
      ) {
        leftV = maxLeft / 2 - w / 2
      }
      if (
        Math.round(centerTop - maxTop / 2) <= diffToCheck &&
        Math.round(centerTop - maxTop / 2) >= -diffToCheck
      ) {
        topV = maxTop / 2 - h / 2
      }
      setLayoutPartial({
        top: topV,
        left: leftV,
      })
    }
    const onDragStart = (e: DragEvent<HTMLDivElement>) => {
      if (disableDrag || !isEdit) {
        return
      }
      e.stopPropagation()
      onResizeDragStart(e, false)
      setIsDragging(true)
      changeDragging?.(true)
    }
    const onDragEnd = (e: DragEvent<HTMLDivElement>) => {
      if (disableDrag || !isEdit) {
        return
      }
      e.stopPropagation()
      onResizeDragEnd(e)
      setIsDragging(false)
      changeDragging?.(false)
    }
    useEffect(() => {
      if (showControl) {
        if (isEdit) {
          ResizeOutsideApi.change({
            id: outSizeId,
            height: layout.height ?? 0,
            width: layout.width ?? 0,
            x: layoutRef.current?.getBoundingClientRect().x ?? 0,
            y: layoutRef.current?.getBoundingClientRect().y ?? 0,
            radius: layout.radius,
            show: isEdit,
            isMulti,
            onChangeSize,
            onResizeDragStart,
            onResizeDrag,
            onResizeDragEnd,
            onRoundedDrag,
            onRoundedDragStart,
            onRoundedDragEnd,
            setRoundedHandling,
            roundedHandling,
            actionsTop,
            customTopElement,
            actionsLeft,
            onDragStart,
            onDrag: onSlotDrag,
            onDragEnd,
            onMouseEnter,
            onMouseLeave,
            isDragging,
            isResizing,
            disableDrag,
            isVideoOverlay,
            parentId: layout.layoutIndex,
            cropMode: layout.isShowCropMode,
            disableResizeInput,
            showAlignmentIndicator,
            showResizeBtn,
            showRoundedBtn,
            showResizeEdeges,
            showSizeInfo,
            isEdgesHorizontalOnly,
            alignTopAction,
          })
        } else {
          ResizeOutsideApi.removeItemByIds([outSizeId])
        }
      }
    }, [
      layout.width,
      layout.height,
      layout.radius,
      layout.top,
      layout.left,
      layout,
      isEdit,
      roundedHandling,
      isDragging,
      isResizing,
      outSizeId,
      showResizeBtn,
      showRoundedBtn,
      showResizeEdeges,
      showControl,
      alignTopAction,
      isEdgesHorizontalOnly,
      showAlignmentIndicator,
      disableResizeInput,
      isVideoOverlay,
      onMouseEnter,
      onMouseLeave,
      isDragging,
      isResizing,
      disableDrag,
      actionsLeft,
      actionsTop,
      outSizeId,
      customTopElement,
    ])
    useEffect(
      () => () => {
        ResizeOutsideApi.removeItemByIds([outSizeId])
      },
      [],
    )
    const rRatio = layout.radius / 100
    const onLongPress = (e: any) => {
      onLongPresLayout?.(e)
    }
    const longPressEvent = useLongPress(
      onLongPress,
      undefined,
      isDragging ? undefined : onMouseLeave,
      {
        delay: 1,
        stopPropagation: isEditMode || isEdit ? true : false,
      },
    )

    const draggable = !disableDrag && isEdit
    const w = customWidth
      ? customWidth
      : isFullScreen
        ? containerProps.width + 1
        : layout.width
    const h = customHeight
      ? customHeight
      : isFullScreen
        ? containerProps.height + 1
        : layout.height
    return (
      <div
        ref={layoutRef}
        onClick={e => {
          onLayoutClick?.(e)
        }}
        onMouseEnter={e =>
          !isDragging && !isEdit ? onMouseEnter?.(e) : undefined
        }
        onDoubleClick={onDoubleClick}
        id={id}
        className={clsx(css.Slot)}
        style={{
          width: w,
          height: h,
          zIndex: isDragging || isFullScreen ? 990 : index,
          top: isFullScreen ? 0 : layout.top,
          left: isFullScreen ? 0 : layout.left,
          background: disableDrag ? 'transparent' : undefined,
          pointerEvents: disableDrag ? 'none' : 'initial',
          ...style,
        }}
        {...longPressEvent}
      >
        {isEdit && !isEdgesHorizontalOnly && (
          <div className={css.BorderOnEdit} />
        )}
        <div
          draggable={draggable}
          style={{
            borderRadius: rRatio * Math.min(layout.width, layout.height),
            ...containerStyle,
          }}
          onDragOver={e => {
            e.stopPropagation()
          }}
          onDrag={onSlotDrag}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          className={clsx(css.SlotContainer, {
            [css.SlotContainerIsEdit]: isEdit && !disableDrag,
            [css.SlotContainerIsEditHover]:
              (enableEditLayout && showControl) || forceHoverEffect,
          })}
        >
          {children}
        </div>
      </div>
    )
  },
)

const setTransferData = (
  e: DragEvent,
  r: MutableRefObject<Function | undefined>,
) => {
  const t = e.target as HTMLDivElement
  const d = t.cloneNode(true) as HTMLDivElement
  d.style.backgroundColor = 'red'
  d.style.opacity = '0'
  document.body.appendChild(d)
  e.dataTransfer?.setDragImage(d, 0, 0)
  r.current?.()
  r.current = () => document.body.removeChild(d)
}
