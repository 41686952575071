import { S } from '../../store'
import type { ILayoutToEdit } from '../../store/shared/LayoutStore'

export const setSlotsForLayoutEdit = (
  slots: ILayoutToEdit[],
  layoutId: number,
) => {
  const $ = S.layout
  $.slotsEdit[layoutId] = slots
}

export const setSlotsEdit = (data: { [key: number]: ILayoutToEdit[] }) => {
  const $ = S.layout
  $.slotsEdit = data
}
