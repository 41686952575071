import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import buble from '../../../assets/images/AIda-bubble.svg'
import css from './ChatBoxContent.module.scss'

import { S } from '../../../context/store'
import { ChatItem, ChatItemLoading } from './ChatItem'

export const NewChatBox = () => (
  <div className={css.NewChatBox}>
    <img className={css.NewChatBoxAidaImage} src={buble} alt='' />
    <div className={css.NewChatBoxGreet}>Hi, I’m Aida your assistant</div>
    <div className={css.NewChatBoxAsk}>How can I help you today?</div>
  </div>
)

export const ChatBoxContent = observer(
  ({ isVisible, data }: { isVisible: boolean; data: any }) => {
    const ref = useRef<HTMLDivElement>(null)
    const { isAnswering } = S.insightEngine.searchChatbotMsg

    useEffect(() => {
      ref.current?.scrollIntoView(true)
    }, [isVisible, [...data]])

    return (
      <div className={css.MenuContent}>
        {data.map((item: any) => (
          <ChatItem key={item.id} item={item} />
        ))}
        {isAnswering && <ChatItemLoading />}
        <div ref={ref} />
      </div>
    )
  },
)
