import { observer } from 'mobx-react-lite'

import { S } from '../../../context/store'
import { CustomPopover } from '../CustomPopover'
import type { TPopover } from './type'

export const WarmnessCustomPopOver = observer((props: TPopover) => {
  const { warmnessColor } = S.local

  const { style, children, isLightTheme, ...prop } = props
  return (
    <CustomPopover
      style={{ background: isLightTheme ? warmnessColor : undefined, ...style }}
      {...prop}
    >
      {children}
    </CustomPopover>
  )
})
