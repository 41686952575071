import type { SessionRoleName } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const getSessionPlan = async (
  sessionId: string,
  roleName: SessionRoleName,
) => {
  const $ = S.webrtc
  if (roleName === 'Participant' || !sessionId) {
    return
  }
  const planRes = await reduxStore.context.gql.sessionPlan(
    { sessionId },
    { requestPolicy: 'network-only' },
  )
  $.plan = planRes.data?.sessionPlan
}
