import { useEffect, useState } from 'react'

import css from './style.module.scss'

import { getFullTime, getShortDate } from '../../../utils/date'

export const Clock = () => {
  const [time, setTime] = useState(getFullTime())

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getFullTime())
    }, 1000)
    return () => clearInterval(timer)
  }, [])
  return (
    <div className={css.Container}>
      <div className={css.Time}>{time}</div>
      <div className={css.TimeZone}>{getShortDate()}</div>
    </div>
  )
}
