import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const deleteResourceInInsightEngine = async (id: string) => {
  const $S = S.insightEngine
  const index = $S.resources.findIndex(r => r.id === id)
  const res = await reduxStore.context.gql.deleteResourceInInsightEngine({
    id: [id],
  })
  if (res.error) {
    ToastService.error({ content: 'Delete failed' })
    return
  }
  $S.resources = $S.resources.filter(r => r.id !== id)
  if (id === $S.resourceSelected?.id && index >= 0) {
    const nextResourceSelected = $S.resources.find((_, idx) => idx === index)
    $S.setAllMediaPlayer({ currentTime: 0, duration: 0 })
    $S.transcripts = []
    $S.resourceSelected = nextResourceSelected
  }
  ToastService.success({ content: 'Delete successfully' })
}
