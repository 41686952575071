import { filterSmallPeers } from '../../../../../rn-shared/mediasoup/lib/filterSmallPeers'
import type { Viewmode } from '../../../../../rn-shared/mediasoup/type'
import type { Medias, PeerUI } from '../../../../../rn-shared/mediasoup/type-ui'
import { S } from '../../../context/store'
import type { FeInput } from '../../../context/store/studio/WebrtcStore'
import { controlMediaPrefix } from '../../../context/store/studio/WebrtcStore'

export const getAudiosForSub = (
  mode: Viewmode,
  peers: PeerUI[],
  amsPeer?: PeerUI,
  medias?: Medias,
  inputs?: FeInput[],
  selectedIndexLayout?: number,
) => {
  const $ = S.webrtc
  const mediaVolumes =
    mode === 'rhost' ? $.mediaVolumesSub.host : $.mediaVolumesSub.observer

  const audiosTracks = filterSmallPeers(mode, peers).map(p1 => ({
    mediaId: p1.id,
    audioTrack: p1.audio?.track,
    volume: mediaVolumes[p1.id]?.current ?? 1,
    willRender: true,
  }))
  const layoutsMedias =
    mode === 'rhost'
      ? S.webrtc.layoutMediasSub.host
      : S.webrtc.layoutMediasSub.observer
  // Track of medias
  if (medias) {
    Object.entries(medias).forEach(v => {
      const isVisible = Object.values(
        layoutsMedias?.[selectedIndexLayout ?? ''] ?? {},
      ).some(media => media?.id === v[0].replace(controlMediaPrefix[mode], ''))
      if (v[1].to === mode && isVisible) {
        audiosTracks.push({
          mediaId: v[0],
          audioTrack: v[1].audio?.track,
          volume: mediaVolumes[v[0]]?.current ?? 1,
          willRender: true,
        })
      }
    })
  }
  // Track of ams
  if (amsPeer?.medias) {
    Object.entries(amsPeer.medias).forEach(v => {
      let volume = mediaVolumes[v[0]]?.current ?? 1
      const av = $.amsVideoState[v[0]]
      if (av) {
        if (av.muted) {
          volume = 0
        }
        volume = (volume * av.volume) / 100
      }
      audiosTracks.push({
        mediaId: v[0],
        audioTrack: v[1].audio?.track,
        volume,
        willRender: true,
      })
    })
  }
  // Tracks of input
  inputs?.forEach(i => {
    const id = `input-${i.id}`
    audiosTracks.push({
      mediaId: id,
      audioTrack: i.audioTrack,
      volume: mediaVolumes[id]?.current ?? 1,
      willRender: false,
    })
  })

  return audiosTracks.filter(a => a.audioTrack && a.volume)
}
