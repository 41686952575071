import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import css from './LiveStreamCustomTitle.module.scss'

import { Icon } from '../../../components/base/Icon'
import { addSlotsToGroup } from '../../../context/actions/layout/addSlotsToGroup'
import { changeSlotEditing } from '../../../context/actions/layout/changeSlotEditing'
import { clearGroupSlot } from '../../../context/actions/layout/clearGroupSlot'
import { updateSlotToEdit } from '../../../context/actions/layout/updateSlotToEdit'
import { Resizeable } from '../../../context/shared/components/CustomLayoutResizeable'
import type { IActionOutsize } from '../../../context/shared/utils/CustomLayoutType'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutSize,
} from '../../../context/store/shared/LayoutStore'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { addTitle } from '../actions/addTitle'
import { changeTitleItem } from '../actions/changeTitleItem'
import { copyTitle } from '../actions/copyTitle'
import { getLayoutRatio } from '../actions/getLayoutRatio'
import { toggleEnableEditTitle } from '../actions/toggleDisableEditTitle'
import { toggleOnAirTitle } from '../actions/toggleOnAirTitle'
import { updateSubtitle } from '../actions/updateSubtitle'
import {
  ActionsOnChangeText,
  ActionsOnEdit,
} from './LiveStreamCustomTitleActions'
import { LiveStreamTitle } from './LiveStreamTitle'
import { calculateUpdateSlot } from './UploadTranscript/calculateSlot'

type Props = {
  layout: ILayoutItem
  containerProps: ILayoutSize
  children?: React.ReactNode
  isEdit?: boolean
  isSmall?: boolean
  parentId: number
  slotsOtherEditing: ILayoutItem[]
  onChangeSize?: (w: number, h: number) => void
  isBelongEditing?: boolean
  handleMouseHover?(layout: ILayoutItem | null): void
}

export const LiveStreamCustomTitle: FC<Props> = observer(props => {
  const {
    containerProps,
    layout,
    parentId,
    slotsOtherEditing,
    isEdit,
    onChangeSize,
    isBelongEditing,
    handleMouseHover,
  } = props
  const { titlesOnAir, selectedIndexLayout, titles } = S.webrtc
  const {
    slotEditing,
    isDragging,
    enableEditLayout,
    slotsEdit,
    enableEditTitle,
    titlesOnEdit,
  } = S.layout
  const ts =
    enableEditLayout && layout.type !== 'subTitle' ? titlesOnEdit : titles
  const titleSelected = ts.find(t => t.id === layout.titleId) as TitleItem
  const [hasAnyModalOpen, setHasAnyModalOpen] = useState(false)
  const { ratioLW } = getLayoutRatio()
  const layoutSelected = slotsEdit[parentId] ?? []
  const matchEditing =
    `${layout.id}-${layout.parentId}-${layout.layoutIndex}` ===
    `${slotEditing?.id}-${slotEditing?.parentId}-${slotEditing?.layoutIndex}`

  const isSubtitleEdit =
    layout.type === 'subTitle' &&
    layout?.id === S.layout.subtitleSlot?.id &&
    S.layout.subtitleSlot.enableEdit
  const computedIsEdit = isEdit && (matchEditing || isSubtitleEdit)
  const computedIsEditRef = useRef(false)
  const isHover = useRef(false)
  const setSlotStatePartial = (state: ILayoutItem) => {
    if (
      !titleSelected.style?.autoHeight &&
      (state.width !== layout.width || state.height !== layout.height)
    ) {
      changeTitleItem(layout.titleId ?? '', {
        style: { ...titleSelected.style, autoHeight: true },
      })
    }
    if (enableEditTitle) {
      return
    }

    if (layout.type === 'subTitle') {
      updateSubtitle({
        ...layout,
        ...calculateUpdateSlot(
          {
            height: state.height,
            width: state.width,
            top: state.top,
            left: state.left,
          },
          'subtitle',
        ),
        radius: state.radius,
        zIndex: state.zIndex,
      })
    } else if (layout.type === 'title' || layout.titleId) {
      updateSlotToEdit(
        layout.id,
        {
          ...layout,
          ...calculateUpdateSlot(
            {
              height: state.height,
              width: state.width,
              top: state.top,
              left: state.left,
            },
            'title',
          ),
          radius: state.radius,
          zIndex: state.zIndex,
        },
        parentId,
      )
    } else {
      updateSlotToEdit(
        layout.id,
        {
          ...layout,
          ...calculateUpdateSlot({
            height: state.height,
            width: state.width,
            top: state.top,
            left: state.left,
          }),
          radius: state.radius,
          zIndex: state.zIndex,
        },
        parentId,
      )
    }
  }

  useEffect(() => {
    computedIsEditRef.current = computedIsEdit ?? false
  }, [computedIsEdit])

  const addSlotEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    const useMultiPleResize = layoutSelected.filter(i => i.inGroup).length > 1
    if (isEdit) {
      if (e.shiftKey) {
        if (slotEditing) {
          changeSlotEditing(-1, parentId)
          addSlotsToGroup([layout.id, slotEditing.id], parentId)
          return
        } else {
          if (!useMultiPleResize) {
            changeSlotEditing(layout.id, parentId)
          }

          addSlotsToGroup([layout.id], parentId)
          return
        }
      }

      clearGroupSlot(parentId + '')

      if (layout.id !== slotEditing?.id) {
        changeSlotEditing(layout.id, parentId)
      }
    }
  }
  const handleLongPress = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (isEdit) {
        addSlotEdit(e)
      }
    },
    [isEdit, isDragging, layoutSelected, enableEditLayout],
  )

  const actionsTop: IActionOutsize[] = useMemo(
    () =>
      !titleSelected
        ? []
        : [
            {
              onAction: () => {
                addTitle(titleSelected.id)
              },
              element: (
                <Icon icon='Plus' size={16} className={css.SlotTopIcon} />
              ),
            },
            {
              onAction: () => {
                toggleOnAirTitle(titleSelected.id, false)
              },
              element: (
                <Icon
                  icon='icon_close_popup'
                  size={13}
                  className={css.SlotTopIcon}
                />
              ),
            },
          ],
    [titleSelected],
  )
  const handelSizeAutoChange = (w?: number, h?: number) => {
    if (titleSelected.style?.autoHeight) {
      return
    }
    updateSlotToEdit(
      layout.id,
      {
        ...layout,
        ...calculateUpdateSlot({
          height: h ?? layout.height,
          width: w ?? layout.width,
          top: layout.top,
          left: layout.left,
        }),
      },
      parentId,
    )
  }
  useEffect(() => {
    if (!isEdit || !matchEditing) {
      toggleEnableEditTitle(false)
    }
  }, [isEdit, matchEditing])
  const customTopElement = useMemo(
    () =>
      enableEditTitle ? (
        <ActionsOnChangeText
          onFocus={setHasAnyModalOpen}
          title={titleSelected}
        />
      ) : (
        <ActionsOnEdit title={titleSelected} />
      ),
    [titleSelected, enableEditTitle],
  )
  const onAir = (titlesOnAir[selectedIndexLayout] ?? []).includes(
    titleSelected?.id,
  )
  const handelEventKeydown = (event: KeyboardEvent) => {
    if (
      (event.ctrlKey && event.keyCode === 67) ||
      (event.metaKey && event.key === 'c')
    ) {
      if (computedIsEditRef.current) {
        copyTitle(titleSelected.id)
      }
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handelEventKeydown)
    return () => document.removeEventListener('keydown', handelEventKeydown)
  }, [])
  if (
    !titleSelected ||
    ((!onAir || layout.layoutIndex !== selectedIndexLayout) &&
      layout.type !== 'subTitle')
  ) {
    return null
  }
  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (
      isHover.current &&
      e.key === 'Alt' &&
      enableEditLayout &&
      isBelongEditing
    ) {
      handleMouseHover?.(layout)
    }
  }
  const handleKeyUp = () => {
    handleMouseHover?.(null)
  }
  const handleOnMouseEnter = (e: React.MouseEvent) => {
    if (e.altKey && enableEditLayout && isBelongEditing) {
      handleMouseHover?.(layout)
      isHover.current = false
    } else {
      isHover.current = true
    }
  }
  const handleOnMouseLeave = (e: React.MouseEvent) => {
    isHover.current = false
  }
  return (
    <Resizeable
      containerProps={containerProps}
      index={layout.zIndex}
      onSizeAutoChange={handelSizeAutoChange}
      layout={layout}
      onDoubleClick={() => {
        if (isEdit && matchEditing) {
          toggleEnableEditTitle(true)
        }
      }}
      disableDrag={enableEditTitle}
      showSizeInfo={!enableEditTitle}
      onChangeSize={onChangeSize}
      showResizeBtn={!enableEditTitle}
      style={{ pointerEvents: 'initial' }}
      showResizeEdeges={!enableEditTitle}
      showRoundedBtn={!enableEditTitle}
      customWidth={titleSelected.style?.autoHeight ? undefined : 'auto'}
      customHeight={titleSelected.style?.autoHeight ? undefined : 'auto'}
      alignTopAction='center'
      actionsTop={layout.type === 'subTitle' ? undefined : actionsTop}
      customTopElement={
        layout.type === 'subTitle' ? undefined : customTopElement
      }
      onChange={setSlotStatePartial}
      showControl={layout.showControl}
      outSizeId={layout.key}
      forceHoverEffect
      ratio={ratioLW}
      isEditMode={isEdit}
      onLongPresLayout={handleLongPress}
      isEdit={computedIsEdit}
      containerStyle={{ background: 'transparent' }}
      slotsOtherEditing={slotsOtherEditing}
      onKeyDown={handleOnKeyDown}
      onKeyUp={handleKeyUp}
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
    >
      <LiveStreamTitle
        layoutId={layout.parentId}
        focus={!hasAnyModalOpen}
        readOnly={!enableEditTitle}
        item={titleSelected}
      />
    </Resizeable>
  )
})
