import clsx from 'clsx'
import IcoMoon from 'react-icomoon'

import css from './style.module.scss'

import iconSet from '../../../assets/fonts/selection.json'
import type { Props } from './type'

export const Icon = (props: Props) => {
  const { disableFill, ...iconProps } = props

  const disableFillValue = disableFill === false ? disableFill : true

  return (
    <IcoMoon
      className={clsx({ [css.ColorByTheme]: !iconProps.color })}
      disableFill={disableFillValue}
      iconSet={iconSet}
      {...iconProps}
    />
  )
}
