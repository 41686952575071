import clsx from 'clsx'
import ReactAvatar from 'react-avatar'

import css from './style.module.scss'

import { BeamAvatar } from '../../../../components/base/Avatar'
import { Button } from '../../../../components/base/Button'
import { BeamDropdown } from '../../../../components/base/Dropdown'
import { Icon } from '../../../../components/base/Icon'
import { Show } from '../../../../components/base/Show'
import { AppSpin } from '../../../../components/base/Spin'
import { BeamText } from '../../../../components/base/Text'
import { BeamTooltip } from '../../../../components/base/Tooltip'
import { WarmnessGrayButton } from '../../../../components/widget/WarmnessGrayButton'
import { getAvatarColor } from '../../../../utils/avatar'
import { rtmpsSupport } from '../DestinationData'
import { createComponent } from './logic'

export const DestinationItem = createComponent(props => {
  const {
    name,
    type,
    avatar,
    onClick,
    selected,
    actions,
    isPublishing,
    className,
    menuVisible,
    setMenuVisible,
    warning,
    isWaiting,
  } = props
  const icon = rtmpsSupport.find(d => d.type === type)?.icon ?? ''
  const _renderDestinationAvatar = () => (
    <div className={css.DestinationAvatar}>
      <Show>
        <Show.When isTrue={Boolean(avatar)}>
          <BeamAvatar style={{ width: 32, height: 32 }} src={avatar} />
        </Show.When>
        <Show.Else>
          <ReactAvatar
            name={name ? name[0] : '.'}
            round
            size='32'
            color={getAvatarColor(name ? name[0] : '.')}
          />
        </Show.Else>
      </Show>
      <div className={css.SocialIcon}>
        <img src={icon} />
      </div>
      {isWaiting && (
        <div className={css.Loading}>
          <AppSpin />
        </div>
      )}
    </div>
  )

  const _renderDestinationItem = () => (
    <BeamTooltip
      overlayClassName={css.ItemTooltip}
      trigger='hover'
      title={
        warning ? (
          <>
            Destination is expired <br /> Click to reauthorize
          </>
        ) : isWaiting ? (
          'Waiting to livestream'
        ) : (
          ''
        )
      }
    >
      <WarmnessGrayButton
        onClick={onClick}
        className={clsx(
          {
            [css.RecentItem]: true,
            [css.RecentItemSelected]: selected,
            [css.RecentItemPublishing]: isPublishing,
            [css.RecentItemWarning]: warning,
          },
          className,
        )}
      >
        {actions && (
          <div
            onClick={e => {
              e.stopPropagation()
            }}
            className={clsx({
              [css.RecentItemMenu]: true,
              [css.RecentItemMenuVisible]: true,
            })}
          >
            <BeamDropdown
              align={{
                points: ['tl', 'r'],
                offset: [-4, 0],
                targetOffset: [-35, 25],
              }}
              visible={menuVisible}
              onVisibleChange={setMenuVisible}
              overlay={actions}
              trigger={['click']}
              overlayStyle={{
                float: 'right',
              }}
            >
              <Button
                containerType='grey'
                className={clsx(css.BtnMore, {
                  [css.BtnMoreVisible]: menuVisible,
                })}
              >
                <Icon icon='icon_three_dots' size={16} />
              </Button>
            </BeamDropdown>
          </div>
        )}
        {icon ? (
          _renderDestinationAvatar()
        ) : (
          <span className={css.TypeText}>{type}</span>
        )}
        <BeamText
          containerProps={{
            classNameContainer: css.RecentItemNameContainer,
          }}
          className={css.RecentItemName}
        >
          {name}
        </BeamText>
        {warning && (
          <Icon
            icon='Shape1'
            className={css.WarningIcon}
            size={16}
            color='rgba(255, 145, 90)'
          />
        )}
      </WarmnessGrayButton>
    </BeamTooltip>
  )
  return _renderDestinationItem()
})
