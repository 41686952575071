import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useVideo } from 'react-use'

import css from './VideoPlayerControlOffline.module.scss'

import { getVolumePrev } from '#rn-shared/mediasoup'

import { S } from '../../../context/store'
import type { TStateMediaPreview } from '../../../context/store/studio/WebrtcStore'
import { VideoPlayerControl } from './VideoPlayerControl'

export const VideoPlayerControlOffline = observer(
  ({ type }: { type: string }) => {
    const {
      mediaStudio,
      updateDataOfStore,
      mediaSelectionId,
      updateVideoSessionList,
      videoSessionList,
    } = S.webrtc
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const [isDraggingVolume, setDraggingVolume] = useState(false)
    const inited = useRef(false)
    const videoSelected = videoSessionList.find(v => v.isSelected === true)
    const onDragEnd = () => {
      if (mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }

    const value = mediaStudio.video.find(
      item => item.id === videoSelected?.mediaId,
    )
    const data = {
      ...videoSelected,
      mediaType: value?.mediaType,
      value: value?.value,
    }
    const [video, state, controls] = useVideo(
      <video
        loop={Boolean(data?.repeat)}
        className={css.MediaVideoItemPlayer}
        src={data?.url || data?.value}
        onLoadStart={() => setIsLoading(true)}
        onSeeking={() => setIsLoading(true)}
        onLoadedData={() => {
          setIsLoaded(true)
          setIsLoading(false)
        }}
        onSeeked={() => setIsLoading(false)}
      />,
    )
    useEffect(() => {
      if (!inited.current) {
        return
      }
      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        currentTime: state.time,
      })

      if (state.time === state.duration && !data?.repeat) {
        updateVideoSessionList({
          mediaId: videoSelected?.mediaId,
          paused: true,
        })
      }
    }, [state.time])

    useEffect(() => {
      if (!isLoaded || inited.current) {
        return
      }

      controls.seek(data?.currentTime ?? 0)
      inited.current = true
      return () => {
        controls.pause()
      }
    }, [videoSelected?.mediaId, isLoaded])

    const handleToggleMute = () => {
      const newValue: TStateMediaPreview = !!data?.volume
        ? {
            volume: 0,
            prevVolume: data.volume,
          }
        : {
            volume: getVolumePrev(data?.prevVolume),
          }
      if (data?.volume !== undefined) {
        data?.volume !== 0 ? controls.mute() : controls.unmute()
      }
      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        ...newValue,
      })
    }

    const handleDragToChangeVolume = (v: number) => {
      controls.volume(v)
      if (!isDraggingVolume) {
        setDraggingVolume(true)

        updateVideoSessionList({
          mediaId: videoSelected?.mediaId,
          prevVolume: v,
        })
      }

      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        volume: v,
      })
    }

    const handleTogglePlaying = () => {
      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        paused: !state?.paused,
      })

      if (state?.paused) {
        controls.play()
      } else {
        controls.pause()
      }
    }
    const handleDragtoChangeVideo = (v: number) => {
      controls.seek(v)

      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        currentTime: v,
      })
    }

    const onAfterChangeSeek = (v: number) => {}

    const handleAfterChangeVolumn = (v: number) => {
      if (v !== 0) {
        updateVideoSessionList({
          mediaId: videoSelected?.mediaId,
          prevVolume: v,
        })
      }
      setDraggingVolume(false)
    }
    const handleToggleRepeat = () => {
      const newRepeat = !data?.repeat

      updateVideoSessionList({
        mediaId: videoSelected?.mediaId,
        repeat: newRepeat,
      })

      S.webrtc.updateFileMedia('video', videoSelected?.mediaId ?? '', {
        isRepeat: newRepeat,
      })
    }

    return (
      <VideoPlayerControl
        type={type}
        videoState={state}
        isLoading={isLoading}
        onChangeMuted={handleToggleMute}
        video={video}
        onDragEnd={onDragEnd}
        data={data}
        onChangeVolume={handleDragToChangeVolume}
        onChangePlay={handleTogglePlaying}
        onChangeSeek={handleDragtoChangeVideo}
        onAfterChangeSeek={onAfterChangeSeek}
        handleAfterChangeVolumn={handleAfterChangeVolumn}
        onChangeRepeat={handleToggleRepeat}
      />
    )
  },
)
