import { observer } from 'mobx-react-lite'

import css from './ToggleThemeButton.module.scss'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { reduxStore } from '../../../context/redux'
import { updateUserProfile } from '../../../context/shared/actions/updateUserProfile'
import { S } from '../../../context/store'

export const ToggleThemeButton = observer(() => {
  const { isLightTheme } = S.local
  const { profile } = S.profile
  const switchTheme = async () => {
    try {
      S.local.isLightTheme = !S.local.isLightTheme
      const theme = S.local.isLightTheme ? 'light' : 'dark'
      updateUserProfile({ ...profile, theme })
      await reduxStore.context.gql.updateMyProfile({
        data: { theme, name: profile.name },
      })
    } catch (e) {
      ToastService.error({
        content: 'Failed to update theme',
      })
    }
  }
  return (
    <WarmnessGrayButton onClick={switchTheme} className={css.ActionRounded}>
      <Icon icon={isLightTheme ? 'Active' : 'Passive'} size={14} />
    </WarmnessGrayButton>
  )
})
