import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './PlayerAudio.module.scss'

import { playTrack } from '../utils/playTrack'
import { VolumeBar } from './PlayerVolumeBar'

export const Audio: FC<TAudioProps> = ({
  audioTrack,
  muted,
  showVolumeLevel,
  volume,
  id,
}) => {
  const [stream, setStream] = useState<MediaStream>()
  const a = useRef<HTMLAudioElement>(null)
  useEffect(() => {
    setStream(playTrack(a.current, audioTrack))
  }, [audioTrack])

  useEffect(() => {
    if (!a.current) {
      return
    }
    a.current.volume = volume
  }, [volume])

  return (
    <>
      <audio
        className={css.audio}
        ref={a}
        autoPlay
        id={id}
        playsInline
        controls={false}
        muted={muted}
      />
      {showVolumeLevel && stream && (
        <VolumeBar volume={volume} stream={stream} />
      )}
    </>
  )
}

export type TAudioProps = {
  audioTrack: MediaStreamTrack
  muted: boolean
  volume: number
  showVolumeLevel?: boolean
  id?: string
}
