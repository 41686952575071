import { SyncOutlined } from '@ant-design/icons'

import css from './style.module.scss'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Table } from '../../../components/base/Table'
import { BeamInput } from '../../../components/widget/Input'
import { BeamPagination } from '../../../components/widget/Pagination'
import type { IProps } from './type'

export const AdminPanelListView: React.FC<IProps> = ({
  tableProps,
  onChangeSearch,
  onRefresh,
  title,
  refreshing,
  paginationProps,
  searchTerm,
  leftSideProps,
  isEmptyDataIndividual = '',
  showSearch = true,
  placeholder = 'Search by name, email, ID',
}) => (
  <>
    <div className={css.Wrapper}>
      <span className={css.title}>{title}</span>
      <div className={css.header}>
        <div className={css.leftElement}>{leftSideProps}</div>
        {showSearch && (
          <div className={css.rightElement}>
            <BeamInput
              className={css.search}
              placeholder={placeholder}
              maxLength={40}
              value={searchTerm}
              onChange={ev => onChangeSearch(ev.target.value)}
            />
            <Icon icon='icon_search' size={16} className={css.searchIcon} />
            <Button
              onClick={() => (refreshing ? undefined : onRefresh())}
              className={css.btnRefresh}
              containerType='grey'
            >
              <SyncOutlined
                className={refreshing ? 'animation-spin' : undefined}
              />
            </Button>
          </div>
        )}
      </div>
    </div>

    <div className={css.ContentWrapper}>
      {isEmptyDataIndividual ? (
        isEmptyDataIndividual
      ) : (
        <Table containerClassName={css.tableContainer} {...tableProps} />
      )}
      {paginationProps && <BeamPagination {...paginationProps} />}
    </div>
  </>
)
