export const playTrack = (
  node: HTMLMediaElement | null,
  track: MediaStreamTrack,
) => {
  if (!node) {
    return
  }
  if (!track) {
    node.srcObject = null
    return
  }
  const stream = new MediaStream()
  stream.addTrack(track)
  node.srcObject = stream
  let retry = 0
  const play = () =>
    node.play().catch(() => {
      retry += 1
      if (retry > 10) {
        return
      }
      setTimeout(play, 1000)
    })
  play()
  return stream
}
