import { observer } from 'mobx-react-lite'
import { useId, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { ulid } from 'ulidx'

import css from './InsightMedia.module.scss'

import { CustomPopover } from '#HACK_FOR_RN_ONLY/src/components/widget/CustomPopover'

import { Icon } from '../../../components/base/Icon'
import { AppSpin } from '../../../components/base/Spin'
import { BeamText } from '../../../components/base/Text'
import { WarmnessGraySpan } from '../../../components/composites/WarmnessGraySpan'
import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import { routerPaths } from '../../../context/router/routerPaths'
import { S } from '../../../context/store'
import type { Resource } from '../../../context/store/insight-engine/InsightEngineStore'
import type { TMediaItem } from '../../../context/store/studio/WebrtcStore'
import { showError } from '../../../utils/showError'
import { useUploadFile } from '../../../utils/useUploadFile'
import type { TUploadFile } from '../../Studio/components/RightContent'
import { getResourcesInsightEngine } from '../actions/getResourcesInsightEngine'
import { insightEngineUploadFiles } from '../actions/uploadFile'
import type { AddMenu } from '../type'
import { MediaVideoCard } from './MediaVideoCard'
import { ModalRecordMeeting } from './ModalRecordMeeting'

export const InsightMedia = observer(() => {
  const { id: insightEngineId } = useParams()
  const uploadFile = useRef<null | HTMLInputElement>(null)

  const ieId = insightEngineId || S.webrtc.detail?.insightEngineId
  const [visible, setVisible] = useState(false)
  const isLoading = S.insightEngine.mediaLoading
  const inputId = useId()
  const handleUploadSuccess = (filesUploaded: TMediaItem[] = []) => {
    ToastService.success({
      content: `File${filesUploaded.length > 1 ? 's' : ''} uploaded. `,
    })
    if (ieId) {
      getResourcesInsightEngine({ insightEngineId: ieId })
    }
  }

  const handleUploadFile = async (file: TUploadFile) => {
    if (!ieId) {
      return
    }
    try {
      const formData = new FormData()
      formData.append('file', file.file)
      const result = await insightEngineUploadFiles(formData, ieId, {
        onUploadProgress: (progress: ProgressEvent) =>
          countProgress(progress, file.id),
      })
      if (result) {
        return {
          id: result.id || ulid(),
          value:
            typeof result?.resource?.url === 'string'
              ? result?.resource?.url
              : '',
          mediaType: 'image',
          name: file.file.name,
          size: result?.resource?.fileSize || 0,
          position: Number(result?.position || 1000),
          resourceId: result.resourceId,
        } as TMediaItem
      }
      return null
    } catch (err: any) {
      setStatusUpload(false)
      showError('Failed to upload file', err as Error)
      return
    }
  }
  const {
    statusUpload,
    countProgress,
    setStatusUpload,
    onOpenUpload,
    percentProgress,
    handleCheckUploadStorage,
  } = useUploadFile({
    onHandleUploadFile: handleUploadFile,
    onUploadSuccess: handleUploadSuccess,
    ref: uploadFile,
  })

  const selectFile = () => {
    setVisible(false)
    onOpenUpload()
  }
  const recordMeetingHandler = () => {
    setVisible(false)
    ModalService.show(ModalRecordMeeting)
  }
  const menus: AddMenu[] = [
    {
      title: 'ADD FILES',
      items: [
        {
          iconName: 'icon_upload',
          iconSize: 16,
          name: 'Upload files',
          onClick: selectFile,
        },
      ],
    },
    {
      title: 'CAPTURE MEETING',
      items: [
        {
          iconName: 'icon_calendar_schedule',
          iconSize: 16,
          name: 'Record meeting',
          onClick: recordMeetingHandler,
        },
      ],
    },
  ]
  const { resources, selectResource } = S.insightEngine
  const renderMenus = () => (
    <div className={css.AddMediaMenus}>
      {menus.map(m => (
        <div key={m.title} className={css.AddMenu}>
          <span className={css.MenuTitle}>{m.title}</span>
          {m.items.map(i => (
            <div key={i.name} onClick={i.onClick} className={css.MenuItem}>
              <Icon
                icon={i.iconName}
                size={i.iconSize}
                className={i.iconClassName}
                disableFill={i.disableFill ?? true}
              />
              {i.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  )

  const handleSelectReource = (r: Resource) => {
    selectResource(r)
    if (location.pathname.includes(routerPaths.INSIGHT_ENGINE_ORIGIN)) {
      const currentUrl = new URL(location.href)
      currentUrl.searchParams.set('mediaId', r.resourceId)
      history.replaceState({}, document.title, currentUrl.href)
    }
  }

  return (
    <div className={css.InsightMediaWrapper}>
      <div className={css.InsightMediaHeader}>
        <BeamText
          size='large1'
          containerProps={{ classNameContainer: css.TitleWrapper }}
          className={css.Title}
        >
          Media
        </BeamText>
        {statusUpload && (
          <span className={css.Uploading}>Uploading...{percentProgress}%</span>
        )}
        <CustomPopover
          placement='right'
          visible={visible && !statusUpload}
          onVisibleChange={v => setVisible(v)}
          isLightTheme
          content={renderMenus()}
        >
          <WarmnessGraySpan className={css.PlusIcon}>
            <Icon icon='Plus' size={16} className={css.IconColor} />
          </WarmnessGraySpan>
        </CustomPopover>
      </div>
      <div className={css.VideoList}>
        {isLoading ? (
          <AppSpin />
        ) : (
          <div>
            {resources.length ? (
              resources.map(r => (
                <MediaVideoCard
                  key={r.id}
                  resource={r}
                  name={r.resource?.name}
                  thumbnailSrc={r.resource?.thumbnailResource}
                  duration={r.resource?.duration}
                  onClick={() => handleSelectReource(r)}
                  selected={S.insightEngine.resourceSelected?.id === r.id}
                  id={r.id}
                />
              ))
            ) : (
              <div className={css.NoMedia}>Media is empty</div>
            )}
          </div>
        )}
      </div>
      <input
        id={inputId}
        accept={'video/mp4,video/x-m4v,video/*,.mp3,.wav,.ogg'}
        type='file'
        value=''
        onChange={handleCheckUploadStorage}
        ref={uploadFile}
        multiple
      />
    </div>
  )
})
