import { ToastService } from '../../../components/widget/Toast'
import type { SearchInsightEngineOfResourceQueryVariables } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { RouterHistory } from '../../../context/router/RouterHistory'
import { routerPaths } from '../../../context/router/routerPaths'
import { S } from '../../../context/store'
import { createInsightEngine } from './createInsightEngine'

export const checkAndGotoInsightEngine = async (
  params: SearchInsightEngineOfResourceQueryVariables & { name?: string },
) => {
  S.local.appLoading = true
  const { data: { searchInsightEngineOfResource: resources } = {} } =
    await reduxStore.context.gql.searchInsightEngineOfResource(params)

  if (!resources?.length) {
    const { data: { createInsightEngine: result } = {} } =
      await createInsightEngine({
        name: params.name,
        resourceId: params.resourceId,
      })
    if (result) {
      RouterHistory.push(
        `${routerPaths.INSIGHT_ENGINE.replace(':id', result.id)}?mediaId=${
          params.resourceId
        }`,
      )
    } else {
      ToastService.error({ content: 'Cannot go to insight engine' })
    }
  } else {
    RouterHistory.push(
      `${routerPaths.INSIGHT_ENGINE.replace(
        ':id',
        resources[0].insightEngineId,
      )}?mediaId=${params.resourceId}`,
    )
  }
  S.local.appLoading = false
}

export const checkAndGotoInsightEngineInSessionManagement = async (
  params: SearchInsightEngineOfResourceQueryVariables & { name?: string },
) => {
  S.local.appLoading = true
  const { data: { searchInsightEngineOfResource: resources } = {} } =
    await reduxStore.context.gql.searchInsightEngineOfResource(params)

  if (!resources?.length) {
    const { data: { createInsightEngine: result } = {} } =
      await createInsightEngine({
        name: params.name ?? 'InsightEngine',
        resourceId: params.resourceId,
      })
    if (result) {
      RouterHistory.push(
        `${routerPaths.INSIGHT_ENGINE.replace(':id', params.resourceId)}`,
      )
    } else {
      ToastService.error({ content: 'Cannot go to insight engine' })
    }
  } else {
    RouterHistory.push(
      `${routerPaths.INSIGHT_ENGINE.replace(':id', params.resourceId)}`,
    )
  }
  S.local.appLoading = false
}
