import { observer } from 'mobx-react-lite'

import css from './style.module.scss'

import { Icon } from '../../../../components/base/Icon'
import { WarmnessGrayButton } from '../../../../components/widget/WarmnessGrayButton'
import { toggleTranscriptView } from '../../actions/toggleTranscriptView'
import { createComponent } from './logic'

export const TranscriptButton = observer(
  createComponent(({ openTranscriptView }) => (
    <WarmnessGrayButton
      onClick={toggleTranscriptView}
      className={css.ActionRounded}
    >
      <Icon
        icon={openTranscriptView ? 'icon_arrow_left' : 'Note'}
        size={openTranscriptView ? 12 : 18}
      />
    </WarmnessGrayButton>
  )),
)
