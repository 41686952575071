import css from './style.module.scss'

import { Icon } from '../../base/Icon'
import { BeamSlider } from '../../base/Slider'
import { createComponent } from './logic'

export const WarmnessButton = createComponent(props => {
  const { show, setShow, updateWarmnessValue, profile } = props
  return (
    <div className={css.WarmnessContainer}>
      {show && (
        <>
          <div className={css.WarmnessDividerLeft} />
          <div className={css.WarmnessDividerRight} />{' '}
          <BeamSlider
            min={-30}
            max={100}
            className={css.WarmnessSlider}
            vertical
            value={profile?.themeWarmness ?? 0}
            onChange={updateWarmnessValue}
            trackStyle={{
              background: 'transparent',
              borderRadius: 6,
            }}
            handleStyle={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 1px 4px rgba(1, 2, 11, 0.24)',
              border: ' 1px solid rgba(0, 0, 0, 0.12)',
              width: 20,
              height: 20,
            }}
          />
        </>
      )}
      <span onClick={() => setShow(!show)} className={css.WarmnessIcon}>
        <Icon icon='Passive11' size={19} />
      </span>
    </div>
  )
})
