import { size, toArray } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'

import type { TModifyFiles } from '../pages/Studio/components/RightContent'

export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export type TFileUploadHook = {
  totalFile: number
  totalSizeUploaded: number
  totalSize: number
  totalFileSuccess: number
  totalFileError: number
  totalFileCanceled: number
  totalPercentUploaded: number
  totalFileUploading: number
  isCompleteUpload?: boolean
}

export const useFileUpload = (filesUpload: TModifyFiles) => {
  const resultHook = useMemo(() => {
    const totalFile = size(filesUpload) || 0
    let totalSize = 0
    let totalFileSuccess = 0
    let totalFileError = 0
    let totalFileUploading = 0
    let totalProgress = 0
    let totalFileCanceled = 0
    if (totalFile > 0) {
      toArray(filesUpload).forEach(item => {
        totalProgress += item.progress
        totalSize += item.file.size
        if (item.progress === 100) {
          if (item.status === 1) {
            totalFileSuccess++
          } else if (item.status === -1) {
            totalFileCanceled++
          } else {
            totalFileError++
          }
        }
        if (item.progress < 100 && item.progress >= 0) {
          totalFileUploading++
        }
      })
    }
    const totalPercentUploaded =
      totalFile === 0 ? 0 : Math.floor(totalProgress / totalFile)
    const totalSizeUploaded =
      totalFile === 0 ? 0 : Math.floor((totalPercentUploaded * totalSize) / 100)

    return {
      totalFile,
      totalSizeUploaded,
      totalSize,
      totalFileSuccess,
      totalFileError,
      totalFileCanceled,
      totalPercentUploaded,
      totalFileUploading,
    }
  }, [filesUpload])

  return resultHook
}
