import type { WaitingUserFilter } from '../../../context/gql/codegen'
import { reduxStore } from '../../redux'
import { NotificationRoomApi } from '../../shared/components/NotificationHandler'
import { S } from '../../store'

export const getWaitingUsers = async (filter?: WaitingUserFilter) => {
  const $ = S.webrtc
  const params = new URLSearchParams(location.search)
  const id = params.get('r')
  const { data } = await reduxStore.context.gql.searchWaitingUser(
    {
      sessionId: id ?? '',
      order: ['createdAt_asc'],
      filter,
    },
    { requestPolicy: 'network-only' },
  )

  data?.searchWaitingUser.forEach(user => {
    if ($.waitingUsers.find(r => r.id === user.id)) {
      return
    }
    NotificationRoomApi.show({
      type: 'JOIN_REQUEST',
      name: user.user?.name ?? '',
      avatar: user.user?.avatarUrl ?? '',
      userId: user.id ?? '',
      role: user.sessionRole,
      id: user.id,
    })
  })
  $.waitingUsers = data?.searchWaitingUser ?? []
}
