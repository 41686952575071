import type { MediaVolumes } from '../type-ui'

export const volume0 = 0.001 // 0.1%

const volumePrevMin = 0.05 // 5%
export const getVolumePrev = (prev?: number) =>
  !prev || prev < volumePrevMin ? 1 : prev

export const isVolumeMuted = (volumes: MediaVolumes, mediaId: string) => {
  const v = volumes[mediaId]?.current ?? 1
  return v <= volume0
}
