import { createSlice } from '##/reactjs/redux'

export type PersistedState = {
  //
}
const initialState: PersistedState = {
  //
}

export const {
  //
  reducer: persistedReducer,
} = createSlice({
  name: 'persisted',
  initialState,
})
