import css from './Graphic.module.scss'

import { Divider } from '../../../context/shared/components/Divider'
import { Color } from './GraphicColor'
import { Logo } from './GraphicLogo'
import { Overlay } from './GraphicOverlay'
import { ItemAccordion } from './ItemAccordion'

export const Graphic = () => (
  <div className={css.GraphicContainer}>
    <div className={css.GraphicHeader}>
      <div className={css.GraphicHeaderTitle}>Graphics</div>
    </div>
    <ItemAccordion
      label='Logo'
      toolTip='Upload your custom logo. Recommended size 512 x 512 pixels'
    >
      <Logo />
    </ItemAccordion>
    <Divider />
    <ItemAccordion
      label='Overlay'
      toolTip='Use overlays to add images on top of your stream. Recommended size: 1920x1080px'
    >
      <Overlay />
    </ItemAccordion>
    <Divider />
    <ItemAccordion
      label='Background Color'
      toolTip='Background Color will appear behind your video'
    >
      <Color />
    </ItemAccordion>
  </div>
)
