import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'

export const useCheckHover = (elementCheck: string, eliminates: string[]) => {
  const [isHover, setIsHover] = useState<boolean>(false)

  const checkInElement = (name: string, event: MouseEvent) => {
    const elements = document?.querySelectorAll?.(`[id=${name}]`) ?? []

    let check = false
    if (elements.length > 0) {
      for (var i = 0; i < elements.length; i++) {
        const e = elements[i]
        const position = e.getBoundingClientRect()
        if (
          event.clientX >= position.left &&
          event.clientX <= position.right &&
          event.clientY >= position.top &&
          event.clientY <= position.bottom
        ) {
          check = true
          if (check) {
            break
          }
        }
      }
    }

    return check
  }
  const handleMouseMove = (event: any) => {
    const isInElement = checkInElement(elementCheck, event)
    let isInOtherElement = false
    for (let i = 0; i < eliminates.length; i++) {
      isInOtherElement = checkInElement(eliminates[i], event)

      if (isInOtherElement) {
        break
      }
    }
    setIsHover(isInElement && !isInOtherElement ? true : false)
  }
  useEffect(
    () => {
      addEventListener('mousemove', handleMouseMove)
      return () => {
        removeEventListener('mousemove', handleMouseMove)
      }
    },
    [elementCheck, eliminates], // Only re-call effect if value or delay changes
  )
  return isHover
}
