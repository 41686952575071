import moment from 'moment'

import { separateLogic } from '##/reactjs/redux'

import { useTimer } from '../../../context/shared/utils/timer'
import type { DurationPropsType as DurationProps } from './type'

export const createComponent = separateLogic((p: DurationProps) => {
  const { from } = p
  const s = useTimer()
  if (!from) {
    return {}
  }
  let ss = Math.floor(moment.duration(s.now.diff(from)).asSeconds())
  if (ss < 0) {
    return {}
  }
  const mm = Math.floor(ss / 60)
  ss = ss % 60
  const mmStr = (mm < 10 ? '0' : '') + `${mm}`
  const ssStr = (ss < 10 ? '0' : '') + `${ss}`
  return {
    mmStr,
    ssStr,
  }
})
