import qs from 'qs'

import type { GqlOperations } from '../../../context/gql/codegen'

type Q = {
  sessionId: string
  token?: string
  peerId: string
  viewmode?: string
  mixer?: string
}

export const _getProtooUrl = async (gql: GqlOperations, q: Q) => {
  const url = await gql
    .getProtooUrl({ sessionId: q.sessionId }, { requestPolicy: 'network-only' })
    .then(r => r.data?.lbMediasoupUrl)
    .catch(() => undefined)
  if (!url) {
    return ''
  }
  return url + `?${qs.stringify(q)}`
}
