import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import type ReactQuill from 'react-quill'

import css from './MentionInput.module.scss'

import { QuillEditor } from '#HACK_FOR_RN_ONLY/src/components/composites/Editor'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { S } from '../../../context/store'
import { SourceChat } from '../../../context/store/studio/WebrtcStore'
import { getAvatarColor } from '../../../utils/avatar'
import { useCheckClick } from '../../../utils/useCheckClick'

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'video',
  'code-block',
  'mention',
  'span',
]

interface IProps {
  onKeyDown(e: any): void
  value: string
  onChange(value: string): void
}

export const ChatMentionsInput: FC<IProps> = observer(
  ({ onChange, onKeyDown, value }) => {
    const mentionsOpenRef = useRef(false)
    const quillReft = useRef<ReactQuill | null>(null)
    const { sourceChat, role, peers, selectedRightTabBarKey } = S.webrtc
    useCheckClick(['mention-list'], isClick => {
      if (!isClick) {
        const mentionModule = quillReft.current?.editor?.getModule('mention')
        if (mentionModule) {
          mentionModule.hideMentionList()
        }
      }
    })
    const source = (
      searchTerm: any,
      renderList: any,
      mentionChar: any,
      data: any[],
    ) => {
      let values: any = []

      if (mentionChar === '@') {
        values = data
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches: any[] = []
        for (let i = 0; i < values.length; i++) {
          if (
            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            matches.push(values[i])
          }
        }
        renderList(matches, searchTerm)
      }
    }
    const modules = useMemo(
      () => ({
        toolbar: false,
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },

        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          positioningStrategy: 'fixed',
          onOpen: () => {
            mentionsOpenRef.current = true
          },
          renderItem: (item: any) => {
            const nameFirstChar = item.value
              ? item.value.length > 0
                ? item.value[0]
                : '.'
              : '.'

            if (item.id === '-1') {
              return `<span class="mention-all">@</span><span class="mention-all"> ${item.value}</span> <span class="mention-all-value">Mention every one</span>`
            }
            return `${
              item.avatar
                ? `<img src="${item.avatar}" />`
                : `<span style="background:${getAvatarColor(
                    nameFirstChar,
                  )}" class="mention-avatar">${nameFirstChar}</span>`
            } <span> ${item.value}</span>`
          },
          onClose: () => {
            setTimeout(() => {
              mentionsOpenRef.current = false
            }, 100)
          },
          defaultMenuOrientation: 'top',
          source,
        },
      }),
      [],
    )

    useEffect(() => {
      const mentionModule = quillReft.current?.editor?.getModule('mention')
      if (quillReft.current && S.webrtc.selectedRightTabBarKey === '5') {
        quillReft.current.editor?.focus()
      }
      return () => {
        mentionModule?.hideMentionList()
      }
    }, [selectedRightTabBarKey])
    const getMentionData = () => {
      let ps: PeerUI[] = peers.filter(
        p =>
          p.data.userId !== S.profile.profile.id &&
          p.data.viewmode !== 'rcanvas' &&
          p.data.viewmode !== 'rinput',
      )
      switch (sourceChat) {
        case SourceChat.INTERNAL:
          ps = ps.filter(p => {
            if (
              role?.name === 'Host' &&
              (p.data.viewmode === 'robserver' || p.data.viewmode === 'rhost')
            ) {
              return true
            }
            if (role?.name === 'Observer' && p.data.viewmode === 'robserver') {
              return true
            }
            return false
          })
          break
        case SourceChat.PUBLIC:
          ps = ps.filter(p => {
            if (
              (role?.name === 'Host' || role?.name === 'Participant') &&
              (p.data.viewmode === 'rparticipant' ||
                p.data.viewmode === 'rhost')
            ) {
              return true
            }
            if (
              role?.name === 'Observer' &&
              (p.data.viewmode === 'robserver' || p.data.viewmode === 'rhost')
            ) {
              return true
            }
            return false
          })
          break
        default:
          break
      }

      const data = ps.map(p1 => ({
        id: p1.data.userId,
        value: p1.data.name,
        avatar: p1.data.avatar ?? '',
      }))
      if (data.length > 0) {
        data.push({ id: '-1', value: 'All', avatar: '' })
      }

      return data
    }

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault()
      const text = e?.clipboardData?.getData('text/plain')
      document.execCommand('insertHTML', false, text)
    }
    useEffect(() => {
      const editorEle = document.getElementById('editor')
      editorEle?.addEventListener('paste', handlePaste)
      return () => editorEle?.removeEventListener('paste', handlePaste)
    }, [])

    useEffect(() => {
      const mentionModule = quillReft.current?.editor?.getModule('mention')
      if (mentionModule) {
        mentionModule.changeMentionData(getMentionData())
      }
    }, [peers.length, sourceChat, role?.name])

    return (
      <div id='mentionChat' className={css.Container}>
        <QuillEditor
          id='editor'
          ref={quillReft}
          onKeyDown={(e: MouseEvent) => {
            e.stopPropagation()
            if (!mentionsOpenRef.current && !e.altKey) {
              onKeyDown(e)
            }
          }}
          className={css.MentionInput}
          modules={modules}
          value={value}
          onChange={(data, delta, editor) => {
            onChange(data)
          }}
          formats={formats}
          placeholder='Write your comment here'
        />
      </div>
    )
  },
)
