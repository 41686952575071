import { observer } from 'mobx-react-lite'

import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_GRAPHIC_OVERLAY } from '../../../context/store/studio/dragTypes'
import type {
  TKeyAny,
  TMediaItem,
} from '../../../context/store/studio/WebrtcStore'
import { GraphicSortContainer } from './GraphicSort'

export const Overlay = observer(() => {
  const {
    overlayUrl,
    updateEmitAndSaveSettings,
    // addGraphicOverlay,
    removeGraphicOverlay,
    updateOrderGraphicOverlay,
    graphicOverlay,
    graphicSettings,
  } = S.webrtc

  const onToggleStatus = (url: string) => {
    handleSendData({ overlayUrl: url === overlayUrl ? '' : url })
  }
  const onUploadFile = (files: TMediaItem[]) => {
    if (!files || files.length === 0) {
      return
    }
    // addGraphicOverlay(files)
  }
  const onSortEnd = async (dropIndex: number, overIndex: number) => {
    updateOrderGraphicOverlay(dropIndex, overIndex)
    await callApiUpdateResourceReOrder(
      S.webrtc.graphicOverlay,
      dropIndex,
      overIndex,
    )
  }
  const onRemoveOverlay = async (item: TMediaItem) => {
    removeGraphicOverlay(item.id)
    await reduxStore.context.gql.deleteResourceInSession({ ids: [item.id] })
    // refetchSub({ requestPolicy: 'network-only' })
    if (overlayUrl === item.value) {
      handleSendData({ overlayUrl: '' })
    }
  }

  const handleSendData = (data: TKeyAny) => {
    updateEmitAndSaveSettings(data, 'overlay')
  }

  return (
    <GraphicSortContainer
      list={graphicOverlay}
      onUploadFile={onUploadFile}
      onToggleStatus={onToggleStatus}
      selectedItem={overlayUrl}
      isHideDefault={!graphicSettings.defaultGraphics}
      onRemove={onRemoveOverlay}
      dragType={DRAG_GRAPHIC_OVERLAY}
      onSortEnd={onSortEnd}
      large
      typeResource='overlay'
    />
  )
})
