import clsx from 'clsx'
import { isNumber } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import css from './LiveStreamCustomTitleActions.module.scss'

import { BeamInput } from '#HACK_FOR_RN_ONLY/src/components/widget/Input'

import { Icon } from '../../../components/base/Icon'
import type { IconName } from '../../../components/base/Icon/type'
import { Map } from '../../../components/base/Map'
import { Show } from '../../../components/base/Show'
import { ModalContent } from '../../../components/Modal/Modal'
import { CustomPopover } from '../../../components/widget/CustomPopover'
import { S } from '../../../context/store'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { changeTitleItem } from '../actions/changeTitleItem'
import { handleChangeFont } from '../actions/handleChangeFont'
import { ColorPicker } from './GraphicColorPicker'
import { allFonts } from './LiveStreamCustomTitleData'

type Props = {
  title: TitleItem
  onFocus?: (value: boolean) => void
}
export const ActionsOnEdit: FC<Props> = ({ title }) => {
  const changeColor = (color: string) => {
    changeTitleItem(title.id, {
      style: { ...title.style, backgroundColor: color },
    })
  }
  return (
    <div
      id='SlotCustom'
      onClick={e => e.stopPropagation()}
      className={css.Container}
    >
      <SelectColor
        icon={'icon_background'}
        key={'background'}
        onChange={changeColor}
        value={title.style?.backgroundColor}
      />
      <SettingsText title={title} />
      <BorderWidthButton title={title} />
    </div>
  )
}
const BorderWidthButton: FC<Props> = ({ title }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (!isNumber(value)) {
      return
    }
    changeTitleItem(title.id, {
      style: { ...title.style, borderWidth: value },
    })
  }
  return (
    <div className={clsx(css.Action, css.BorderNone)}>
      <Icon icon='Thickness' size={16} color='rgba(225, 225, 233, 1)' />
      <BeamInput
        value={title.style?.borderWidth ?? 0}
        onChange={handleChange}
        style={{
          // @ts-ignore
          '--text-primary1': 'rgba(225, 225, 233, 1)',
        }}
        className={css.Input}
      />
    </div>
  )
}
const SelectColor: FC<{
  icon: IconName
  value?: string
  onChange: (v) => void
  iconSize?: number
  onFocus?: (v: boolean) => void
}> = ({ icon, iconSize = 16, onChange, value, onFocus }) => {
  const [visible, setVisible] = useState(false)
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (visible) {
      addEventListener('keydown', handleKeydown)
    } else {
      removeEventListener('keydown', handleKeydown)
    }
    onFocus?.(visible)
    return () => removeEventListener('keydown', handleKeydown)
  }, [visible])
  return (
    <CustomPopover
      background='white'
      isLightTheme
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <ColorPicker
          id='customTitleColorPicker'
          isLightTheme
          onSelectColor={onChange}
          defaultColor={value}
        />
      }
    >
      <div className={clsx(css.Action, { [css.ActionSelected]: visible })}>
        <div className={css.SelectColor}>
          <Icon icon={icon} size={iconSize} color='rgba(225, 225, 233, 1)' />
          <div style={{ background: value }} className={css.ColorSelected} />
        </div>
      </div>
    </CustomPopover>
  )
}
const SettingsText: FC<Props> = observer(({ title }) => {
  const [hovered, setHovered] = useState({
    autoWidth: false,
    autoHeight: false,
  })
  const handleChange = (autoHeight: boolean) => {
    changeTitleItem(title.id, {
      style: { ...title.style, autoHeight },
    })
  }
  return (
    <div className={css.Action}>
      <div
        onMouseOver={() => setHovered(prev => ({ ...prev, autoWidth: true }))}
        onMouseOut={() => setHovered(prev => ({ ...prev, autoWidth: false }))}
        onClick={() => {
          handleChange(false)
        }}
        className={clsx(css.SettingTextItem, {
          [css.SettingTextItemSelected]: !title.style?.autoHeight,
        })}
      >
        <Icon
          icon='Left-Right'
          size={16}
          color={
            !title.style?.autoHeight || hovered.autoWidth
              ? 'rgba(10, 50, 95, 1)'
              : 'rgba(225, 225, 233, 1)'
          }
        />
      </div>
      <div
        onMouseOver={() => setHovered(prev => ({ ...prev, autoHeight: true }))}
        onMouseOut={() => setHovered(prev => ({ ...prev, autoHeight: false }))}
        onClick={() => {
          handleChange(true)
        }}
        className={clsx(css.SettingTextItem, {
          [css.SettingTextItemSelected]: title.style?.autoHeight,
        })}
      >
        <Icon
          icon='Text'
          size={16}
          color={
            title.style?.autoHeight || hovered.autoHeight
              ? 'rgba(10, 50, 95, 1)'
              : 'rgba(225, 225, 233, 1)'
          }
        />
      </div>
    </div>
  )
})
export const ActionsOnChangeText: FC<Props> = ({ title, onFocus }) => {
  const changeColor = (color: string) => {
    changeTitleItem(title.id, {
      style: { ...title.style, color },
    })
  }

  return (
    <div
      id='layoutToolbar'
      onClick={e => e.stopPropagation()}
      className={css.Container}
    >
      <SelectContent onFocus={onFocus} title={title} />
      <ChangeFontSize onFocus={onFocus} title={title} />
      <SelectColor
        icon={'Icon-A'}
        iconSize={18}
        onFocus={onFocus}
        key={'text'}
        onChange={changeColor}
        value={title.style?.color}
      />
      <ChangeTextStyle title={title} />
    </div>
  )
}
export const ChangeTextStyle: FC<Props> = ({ title, onFocus }) => (
  <>
    <div
      onBlur={() => {
        onFocus?.(false)
      }}
      onClick={() => {
        onFocus?.(true)
        changeTitleItem(title.id, {
          style: { ...title.style, isBold: !title.style?.isBold },
        })
      }}
      className={clsx(css.Action, css.BorderNone)}
    >
      <Icon
        className={clsx(css.TextStyleIcon, {
          [css.TextStyleIconSelected]: title.style?.isBold,
        })}
        icon={'TextStyle-Bold'}
        size={16}
      />
    </div>

    <div
      onBlur={() => {
        onFocus?.(false)
      }}
      onClick={() => {
        onFocus?.(true)
        changeTitleItem(title.id, {
          style: { ...title.style, isItalic: !title.style?.isItalic },
        })
      }}
      className={css.Action}
    >
      <Icon
        className={clsx(css.TextStyleIcon, {
          [css.TextStyleIconSelected]: title.style?.isItalic,
        })}
        icon={'TextStyle-Italic'}
        size={16}
      />
    </div>
  </>
)
export const ChangeFontSize: FC<Props> = ({ title, onFocus }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (!isNumber(value) || isNaN(value)) {
      return
    }
    changeTitleItem(title.id, {
      style: { ...title.style, fontSize: value },
    })
  }
  return (
    <div className={css.Action}>
      <BeamInput
        value={Math.round(title.style?.fontSize ?? 13)}
        onChange={handleChange}
        onFocus={() => onFocus?.(true)}
        onBlur={() => onFocus?.(false)}
        style={{
          // @ts-ignore
          '--text-primary1': 'rgba(225, 225, 233, 1)',
        }}
        className={css.Input}
      />
    </div>
  )
}

const SelectContent: FC<Props> = observer(({ title, onFocus }) => {
  const [visible, setVisible] = useState(false)
  const { recentFonts } = S.webrtc
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setVisible(false)
    }
  }
  useEffect(() => {
    addEventListener('keydown', handleKeydown)
    return () => removeEventListener('keydown', handleKeydown)
  }, [])
  const setFont = (f: string) => {
    handleChangeFont(title, f)
  }

  const renderContent = () => (
    <ModalContent
      id='customTitleSelectFont'
      useCloseDefault={false}
      className={css.SelectContent}
    >
      <div className={css.ContentContainer}>
        <Show>
          <Show.When isTrue={Boolean(recentFonts.length)}>
            <div>
              <span className={css.FontTitle}>RECENT</span>
              <div className={css.ListFont}>
                <Map<string>
                  list={recentFonts}
                  renderItem={f => (
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        setFont(f)
                      }}
                      key={f}
                      style={{ fontFamily: f }}
                      className={css.FontItem}
                    >
                      <span>{f}</span>
                      {title.style?.font === f && (
                        <Icon icon='icon_check' size={16} color={'#2656C9'} />
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </Show.When>
        </Show>
        <div>
          <span className={css.FontTitle}>{'ALL FONTS'}</span>
          <div className={css.ListFont}>
            <Map<string>
              list={allFonts.filter(i => !recentFonts.includes(i))}
              renderItem={f => (
                <div
                  onClick={e => {
                    e.stopPropagation()
                    setFont(f)
                  }}
                  key={f}
                  style={{ fontFamily: f }}
                  className={css.FontItem}
                >
                  <span>{f}</span>
                  {title.style?.font === f && (
                    <Icon icon='icon_check' size={16} color={'#2656C9'} />
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </ModalContent>
  )
  useEffect(() => {
    onFocus?.(visible)
  }, [visible])
  return (
    <CustomPopover
      background='white'
      isLightTheme
      key='customTitleSelectFont'
      id='customTitleSelectFont'
      visible={visible}
      onVisibleChange={v => {
        setVisible(v)
      }}
      content={renderContent()}
    >
      <div className={clsx(css.Action, { [css.ActionSelected]: visible })}>
        <span className={css.Text}>{title.style?.font}</span>
        <Icon icon='Vector2' size={12} color={'rgba(225, 225, 233, 1)'} />
      </div>
    </CustomPopover>
  )
})
