import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const stopDestination = async (id: string, cb?: () => void) => {
  S.local.appLoading = true
  const res = await reduxStore.context.gql.stopOutputs({
    sessionId: S.webrtc.sessionId,
    outputInSessionIds: [id],
  })
  if (res.error) {
    ToastService.error({ content: 'Stop failed.' })
    S.local.appLoading = false
    return
  }
  ToastService.success({ content: 'Stop success.' })
  cb?.()
  S.local.appLoading = false
}
