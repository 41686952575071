import type { RadioProps } from 'antd'

export type AppTextProps = {
  containerProps?: TRadioContainerProps
} & RadioProps
export enum ERadioSize {
  M = 'M',
  S = 'S',
  XS = 'XS',
}
export type TRadioContainerProps = {
  size?: ERadioSize | 'M' | 'S' | 'XS'
}
