import './blot'

import { Quill } from 'react-quill'

import './addImage.scss'

import { Sources } from '../../MentionEditorModule/mention'

export class AddImageCustom {
  quill: any
  options: any
  constructor(quill: any, options: any) {
    this.quill = quill
  }

  onAddHtml = (data: any) => {
    const range = this.quill.getSelection(true)
    this.quill.insertEmbed(range.index, 'addImage', data, Sources.USER)
    this.quill.insertText(range.index + 1, ' ', Sources.USER)
    this.quill.setSelection(range.index + 2, Sources.USER)
  }
}
Quill.register('modules/addImageCustom', AddImageCustom)
