import { EllipsisOutlined } from '@ant-design/icons'
import { Pagination } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import { LIMIT } from '../../../config'
import { CONSTANTS } from '../../../styles/Constants'
import { Icon } from '../../base/Icon'
import { Show } from '../../base/Show'
import { BeamText } from '../../base/Text'
import type { TPaginationProps } from './type'

export const BeamPagination: FC<TPaginationProps> = ({
  wrapperClassName,
  ...paginationProps
}) => {
  const limit = paginationProps.pageSize ?? LIMIT
  const totalPage = Math.ceil((paginationProps?.total ?? 0) / limit)
  const renderCondition = paginationProps.total && paginationProps.total > 0
  return (
    <div className={clsx(css.PaginationWrapper, wrapperClassName)}>
      {!!renderCondition && (
        <>
          <BeamText containerProps={{ classNameContainer: css.PageSizerText }}>
            Rows per page
          </BeamText>
          <Pagination
            showQuickJumper
            defaultPageSize={LIMIT}
            current={paginationProps.current}
            locale={{
              items_per_page: '',
              jump_to: 'Go to page',
              jump_to_confirm: '',
              page: '',
            }}
            totalBoundaryShowSizeChanger={-1}
            itemRender={(page, type) => (
              <Show>
                <Show.When isTrue={type === 'next'}>
                  <div
                    className={clsx({
                      [css.paginationItem]: true,
                      [css.disabled]: paginationProps.current === totalPage,
                    })}
                  >
                    <span className={css.PaginationIcon}>
                      <Icon icon='Right' size={16} />
                    </span>
                  </div>
                </Show.When>
                <Show.When isTrue={type === 'prev'}>
                  <div
                    className={clsx({
                      [css.paginationItem]: true,
                      [css.disabled]: paginationProps.current === 1,
                    })}
                  >
                    <span className={css.PaginationIcon}>
                      <Icon icon='Left' size={16} />
                    </span>
                  </div>
                </Show.When>
                <Show.When isTrue={type === 'page'}>
                  <div
                    className={clsx({
                      [css.paginationItem]: true,
                      [css.paginationItemSelected]:
                        page === paginationProps.current,
                    })}
                  >
                    <div
                      style={{
                        color: CONSTANTS.text.level1,
                      }}
                    >
                      {page}
                    </div>
                  </div>
                </Show.When>
                <Show.Else>
                  <div>
                    <EllipsisOutlined
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                      className={css.JumpButton}
                    />
                  </div>
                </Show.Else>
              </Show>
            )}
            {...paginationProps}
          />
        </>
      )}
    </div>
  )
}
