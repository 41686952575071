import { observer } from 'mobx-react-lite'

import style from './style.module.scss'

import { S } from '../../../context/store'

export const SubTitle = observer(() => {
  const { subTitle, isShowSubtitles, ratioScaleLayout } = S.webrtc
  if (!subTitle || !isShowSubtitles) {
    return null
  }
  return (
    <div
      style={{
        fontSize: 24 * ratioScaleLayout,
        padding: 24 * ratioScaleLayout,
      }}
      className={style.Subtitle}
    >
      {subTitle}
    </div>
  )
})
