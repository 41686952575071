import type { Store } from '@reduxjs/toolkit'
import { upperFirst } from 'lodash'

import type { GetReduxUntyped } from './useRedux'
import { createGetRedux } from './useRedux'

export type ReduxContextUntyped<
  S extends Store = any,
  C = any,
  R = ReturnType<S['getState']>,
> = {
  store: S
  getRedux: GetReduxUntyped<ReduxContextUntyped<S, C>>
  setState: <K extends string & keyof R>(k: K, f: (s: R[K]) => void) => void
} & C

export const initReduxContext = <S extends Store, C>(
  s: S,
  extra: (s: S) => C,
) => {
  const ctx = {
    store: s,
    setState: (k, payload) => {
      s.dispatch({ type: `${k}/set${upperFirst(k)}State`, payload })
    },
  } as ReduxContextUntyped<S, C>
  Object.assign(s, { context: ctx })
  ctx.getRedux = createGetRedux(ctx)
  const extraCtx = extra(s)
  Object.assign(ctx, extraCtx)
  return ctx as ReduxContextUntyped<S, C> & typeof extraCtx
}
