import { observer } from 'mobx-react-lite'
import type { FC } from 'react'

import { S } from '../../../context/store'
import type { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import { BlurBlockItem } from './BlurBlockItem'

type Props = {
  containerSize: {
    width: number
    height: number
  }
  type: InsightEngineMode
}
export const BlurBlocks: FC<Props> = observer(({ containerSize, type }) => {
  const { blurBlocks } = S.insightEngine
  return (
    <>
      {blurBlocks.map(b => (
        <BlurBlockItem
          containerProps={containerSize}
          key={b.id + type}
          item={b}
          type={type}
        />
      ))}
    </>
  )
})
