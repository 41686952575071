import { ToastService } from '../../../components/widget/Toast'
import { S } from '../../../context/store'

export const copyTitle = (titleId: string) => {
  const $ = S.layout
  const $1 = S.webrtc
  const sl = $1.selectedLayoutByIndex.slots.find(s => s.titleId === titleId)
  $.slotCopy = sl
  ToastService.success({ content: 'Copied', duration: 2 })
}
