import type { RefObject } from 'react'
import { useEffect, useState } from 'react'
import Selecto from 'react-selecto'

type Props = {
  refScroll?: RefObject<HTMLDivElement>
  dragContainerSelector: string
  selectableTargets: string[]
  onSelectionStart?: () => void
  onSelection?: (list: string[]) => void
  onSelectionEnd?: (list: string[]) => void
  isAllowSelect?: boolean
}

type DragScrollOptions = {
  container: HTMLElement
  threshold?: number
  getScrollPosition?: (param: {
    container: HTMLElement
    direction: number[]
  }) => number[]
  throttleTime?: number
}

export const DragSelection = ({
  refScroll,
  onSelection,
  dragContainerSelector,
  selectableTargets,
}: Props) => {
  const [scrollOptions, setScrollOptions] = useState<DragScrollOptions>({
    container: document.body,
  })

  useEffect(() => {
    if (refScroll?.current) {
      setScrollOptions({
        container: refScroll.current,
        throttleTime: 30,
        threshold: 0,
      })
    }
  }, [])

  return (
    <Selecto
      container={document.body}
      dragContainer={dragContainerSelector}
      selectableTargets={selectableTargets}
      selectByClick={false}
      continueSelect={false}
      selectFromInside={false}
      keyContainer={window}
      hitRate={0}
      preventDragFromInside={false}
      scrollOptions={scrollOptions}
      toggleContinueSelect='shift'
      onSelect={e => {
        const result: string[] = []
        e.selected.forEach(el => {
          result.push(el.getAttribute('data-selection') || '')
        })

        onSelection && onSelection(result)
      }}
      onScroll={e => {
        if (refScroll?.current) {
          refScroll.current.scrollBy(e.direction[0] * 10, e.direction[1] * 10)
        }
      }}
    />
  )
}
