import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import css from './BottomElement.module.scss'

import { AppSpin } from '#HACK_FOR_RN_ONLY/src/components/base/Spin'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Popover } from '../../../components/base/Popover'
import { BeamText } from '../../../components/base/Text'
import { ToastService } from '../../../components/widget/Toast'
import { RouterHistory } from '../../../context/router/RouterHistory'
import { ModalUpdateInfo } from '../../../context/shared/components/ModalUpdateRoom'
import { S } from '../../../context/store'
import { InsightEngineMode } from '../../../context/store/insight-engine/InsightEngineStore'
import type { TUpdateRoom } from '../../../context/store/studio/WebrtcStore'
import { downloadNote } from '../../../utils/downloadNote'
import { downloadTranscriptHandler } from '../actions/downloadTranscript'
import { getDownloadLink } from '../actions/getDownloadLink'
import { getLocationData } from '../actions/getLocationData'
import { updateNameInsightEngine } from '../actions/updateNameInsightEngine'
import type { IconItem, IOverlayItem, TBottomElLoadingState } from '../type'
import { ESharePopupList } from '../type'

export const BottomElement = observer(() => {
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [loading, setLoading] = useState<TBottomElLoadingState>({})
  const { insightEngineId } = getLocationData()
  const {
    transcripts = [],
    detailInsightEngine,
    resourceSelected,
    resources,
  } = S.insightEngine
  const insightEngineName = detailInsightEngine.name
  const handleCheckOut = () => {
    RouterHistory.goBack()
  }
  const riie = resources.find(r => r.id === resourceSelected?.id)
  const isDownloading = riie?.isDownloading

  useEffect(() => {
    if (isDownloading) {
      setLoading(prev => ({
        ...prev,
        [ESharePopupList.DOWNLOAD_VIDEO]: true,
      }))
      return
    }
    setLoading(prev => ({
      ...prev,
      [ESharePopupList.DOWNLOAD_VIDEO]: false,
    }))
  }, [isDownloading])

  const updateTitle = async ({ title }: TUpdateRoom) => {
    try {
      updateNameInsightEngine(insightEngineId ?? '', { name: title.trim() })
      ToastService.success({
        content: 'Successfully updated insight engine name',
      })
      setEditMode(false)
    } catch (err) {
      ToastService.error({ content: 'Failed to update insight engine name' })
      console.error(err)
    }
  }

  const downloadNoteHandler = async () => {
    setLoading(prev => ({ ...prev, [ESharePopupList.DOWNLOAD_NOTE]: true }))

    await downloadNote(
      resourceSelected?.note ?? '[]',
      `${resourceSelected?.resource?.name.split('.')[0] || 'beam'}-note.docx`,
    )
    setLoading(prev => ({ ...prev, [ESharePopupList.DOWNLOAD_NOTE]: false }))
  }

  const downloadTranscript = async () => {
    if (!Boolean(resourceSelected?.id)) {
      return
    }
    setLoading(prev => ({
      ...prev,
      [ESharePopupList.DOWNLOAD_TRANSCRIPT]: true,
    }))
    await downloadTranscriptHandler(
      resourceSelected?.id ?? '',
      resourceSelected?.resource?.name.split('.')[0] || 'transcript',
    )
    setLoading(prev => ({
      ...prev,
      [ESharePopupList.DOWNLOAD_TRANSCRIPT]: false,
    }))
  }

  const downloadVideo = () => {
    if (!resourceSelected) {
      return
    }

    getDownloadLink(resourceSelected.id)
  }

  const renderOverlayList = (list: IOverlayItem[]) => (
    <div className={css.OverlayWrapper}>
      {list.map(item => (
        <div className={css.SectionWrapper} key={item.title}>
          <BeamText
            size='large'
            containerProps={{ classNameContainer: css.sectionTitleWrapper }}
            className={css.sectionTitle}
          >
            {item.title}
          </BeamText>
          <div className={css.ContentContainer}>
            {item.contents.map(c => (
              <div
                onClick={loading[c.type] ? undefined : c.onClick}
                className={clsx(css.content, {
                  [css.contentDisabled]: c.disable,
                })}
                key={c.type}
              >
                {loading[c.type] ? (
                  <AppSpin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                ) : (
                  <Icon
                    icon={c.iconName}
                    size={16}
                    disableFill={c.iconName !== 'GoogleDocs'}
                    className={c.iconClassName}
                  />
                )}
                <BeamText>{c.label}</BeamText>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )

  const overlayContentList: IOverlayItem[] = [
    // {
    //   title: 'Share',
    //   contents: [
    //     {
    //       iconName: 'AddUser',
    //       iconClassName: css.addUserIcon,
    //       label: 'Manage Access',
    //       disable: true,
    //     },
    //   ],
    // },
    {
      title: 'Download',
      contents: [
        {
          type: ESharePopupList.DOWNLOAD_VIDEO,
          iconName: 'Download1',
          iconClassName: css.downloadIcon,
          label: 'Download video as mp4',
          onClick: downloadVideo,
          disable: !resourceSelected,
        },
        {
          type: ESharePopupList.DOWNLOAD_NOTE,
          iconName: 'Download1',
          iconClassName: css.downloadIcon,
          label: 'Download notes as docx',
          onClick: downloadNoteHandler,
          disable: !resourceSelected?.note,
        },
        {
          type: ESharePopupList.DOWNLOAD_TRANSCRIPT,
          iconName: 'Download1',
          iconClassName: css.downloadIcon,
          label: 'Download transcript as docx',
          onClick: downloadTranscript,
          disable: !transcripts.length,
        },
      ],
    },
    // {
    //   title: 'Export',
    //   contents: [
    //     {
    //       iconName: 'GoogleDocs',
    //       label: 'Export notes to Google docs',
    //       disable: true,
    //     },
    //   ],
    // },
  ]

  return (
    <div className={css.Wrapper}>
      <div className={css.Left}>
        <span className={css.ArrowLeft} onClick={handleCheckOut}>
          <Icon icon='Left' size={16} />
        </span>
        {insightEngineName}
        <span className={css.IconEdit} onClick={() => setEditMode(!isEditMode)}>
          <Icon icon='icon_pencil' size={12} />
        </span>
      </div>
      <InsightEngineButtonsList />
      <Popover
        _overlay={renderOverlayList(overlayContentList)}
        trigger={'click'}
      >
        <Button className={css.shareButton}>Share</Button>
      </Popover>

      <ModalUpdateInfo
        initValue={{
          title: insightEngineName,
          description: '',
        }}
        visible={isEditMode}
        onCancel={() => setEditMode(false)}
        onSave={updateTitle}
        context='Insight Engine'
        isWarmTheme={false}
      />
    </div>
  )
})
export const InsightEngineButtonsList = observer(() => {
  const { setMode, mode } = S.insightEngine

  const items: IconItem[] = [
    {
      icon: 'Cursor',
      name: 'Cursor',
    },
    {
      icon: 'AddNote',
      name: 'Mark',
    },
    {
      icon: 'Cut',
      name: 'Trim',
    },
    {
      icon: 'Edit',
      name: 'Edit',
    },
  ]

  const handleOnClick = (item: IconItem) => {
    if (
      item.name !== InsightEngineMode.Cursor &&
      item.name !== InsightEngineMode.Edit
    ) {
      return
    }
    setMode(item.name)
  }

  const renderItem = (item: IconItem) => {
    if (item.name === 'Mark' || item.name === 'Trim') {
      return null // temporarily hide
    }
    return (
      <div
        onClick={() =>
          item.name === InsightEngineMode.Edit &&
          !S.insightEngine.resourceSelected
            ? undefined
            : handleOnClick(item)
        }
        className={clsx(
          css.Item,
          { [css.ItemSelected]: item.name === mode },
          {
            [css.ItemDisabled]:
              item.name !== InsightEngineMode.Edit &&
              item.name !== InsightEngineMode.Cursor,
          },
        )}
      >
        <Icon icon={item.icon} size={20} className={css.Icon} />
        {item.name}
      </div>
    )
  }
  const Item = renderItem
  return (
    <div className={css.Middle}>
      {items.map(i => (
        <Item {...i} key={i.name} />
      ))}
    </div>
  )
})
