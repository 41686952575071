import { reduxStore } from '../../redux'
import { S } from '..'

export const reloadCurrentPlan = async () => {
  console.log(S.profile.profile.currentOrgId)
  const currentPlanRes = await reduxStore.context.gql.currentPlan(
    {
      orgId: S.profile.profile.currentOrgId,
    },
    { requestPolicy: 'network-only' },
  )
  const currPlan = currentPlanRes.data?.currentPlan
  S.profile.currentPlan = currPlan
  S.profile.isRecurrencePlan = currPlan?.subscription
    ? currPlan?.subscription.recurrence
    : true
  console.log(currentPlanRes.data?.currentPlan)
}
