import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import BackgroundDefault from '../../../assets/images/bg_livestream.png'
// eslint-disable-next-line css-modules/no-unused-class
import css from './LiveStreamPeers.module.scss'

import { arrToMap } from '##/shared/arrToMap'
import { filterSmallPeers } from '#rn-shared/mediasoup/lib/filterSmallPeers'

import { setSlotsEdit } from '../../../context/actions/layout/setSlotsToEdit'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import {
  DRAG_ALLOW_USER,
  DRAG_MEDIA_ADD_PLAYLIST,
  DRAG_MEDIA_SLOT,
  DRAG_TITLE,
  DRAG_USER_MAIN,
} from '../../../context/store/studio/dragTypes'
import type { TKeyString } from '../../../context/store/studio/WebrtcStore'
import { buildScreenshareMediaId } from '../../../context/store/studio/WebrtcStore'
import { useCheckHover } from '../../../utils/useCheckHover'
import { useDropBackgroundLayout } from '../../../utils/useDrop'
import { handleUpdatePeerLayout } from '../actions/handleUpdatePeerlayout'
import { CustomLayout } from './LiveStreamCustomLayout'
import { Slot, StyleSlotPreview } from './Slot'
import { StyleSlotMediaPreview } from './SlotMedia'
import { calculateSlot } from './UploadTranscript/calculateSlot'

interface LayoutItemOnCanvas extends ILayoutItem {
  peerId?: string
  mediaData?: TKeyString
}
export const Peers: FC = observer(() => {
  const {
    onAir,
    selectedIndexLayout,
    selectedLayoutByIndex,
    typeDraggingItem,
    layoutPeers,
    selectedListLayouts,
    layoutMedias,
    ownerPeerId,
    peers,
    isShowSubtitles,
    isViewmodeParticipant,
    mainLayoutWidth,
  } = S.webrtc
  const { isViewmodeMixer } = S.webrtc
  const { enableEditLayout, slotsEdit, subtitleSlot } = S.layout
  const [{ isOver }, drop] = useDropBackgroundLayout({})
  const layoutSelectedRef = useRef(selectedLayoutByIndex.defaultId)
  useEffect(() => {
    layoutSelectedRef.current = selectedLayoutByIndex.defaultId
  }, [selectedLayoutByIndex])
  useEffect(() => {
    if (selectedListLayouts.length) {
      const layoutsEdit: { [key: number]: ILayoutToEdit[] } = {}
      selectedListLayouts.forEach((i, index) => {
        layoutsEdit[i.defaultId] = (i.slots as ILayoutItem[]).map(s => ({
          ...s,
          layoutIndex: s.layoutIndex || index,
          parentId: i.defaultId,
          cropMode: s.cropMode || ECropMode.Fill,
          prevCropMode: s.cropMode || ECropMode.Fill,
        }))
      })
      setSlotsEdit(layoutsEdit)
    }
  }, [selectedListLayouts])
  if (!onAir && isViewmodeMixer) {
    return (
      <div
        style={{
          // @ts-ignore
          '--urlBackgroundImageStyle': `url(${BackgroundDefault})`,
        }}
        className={clsx({
          [css.LiveStreamLayout]: true,
          [css.BackgroundImageStyle]: true,
        })}
      >
        <div className={css.LiveStreamMixerDisable}>
          <div className={css.IconLoading}>
            <LoadingOutlined />
          </div>
          <p className={css.LiveStreamMixerDisable}>
            This stream is currently off air
          </p>
        </div>
      </div>
    )
  }

  const isHoverDndBg = useCheckHover('dndBg', ['SlotCustom'])
  const slots: LayoutItemOnCanvas[] = Object.keys(slotsEdit).reduce<
    LayoutItemOnCanvas[]
  >((acc, v, i) => {
    const sls = slotsEdit[Number(v)]
      .map((s): LayoutItemOnCanvas => {
        const isShow =
          s.layoutIndex === selectedIndexLayout &&
          Number(v) === selectedLayoutByIndex.defaultId

        return {
          ...s,
          layoutIndex: s.layoutIndex,
          invisible: !isShow,
          height: isShow ? s.height : 0,
          top: isShow ? s.top : 99999,
          left: isShow ? s.left : 99999,
          screenshare: isShow ? s.screenshare : false,
          peerId: layoutPeers?.[s.layoutIndex ?? -1]?.[s.id] ?? '',
          mediaData: layoutMedias?.[s.layoutIndex ?? -1]?.[s.id],
        }
      })
      .filter(s => s.peerId || !s.invisible || s.mediaData || s.titleId)

    return [...acc, ...sls]
  }, [])
  useEffect(() => {
    handleUpdatePeerLayout()
  }, [peers.length])
  const slotsWithMediaShowYet: LayoutItemOnCanvas[] = getMediaIdsShowYet(
    arrToMap(slots, 'peerId'),
  ).map((id, i) => ({
    id: i - 10000,
    layoutIndex: -1,
    height: 0,
    left: 0,
    top: 0,
    radius: 0,
    width: 0,
    invisible: false,
    zIndex: -1,
    peerId: id,
    parentId: -1,
  }))

  if (
    !slots.some(
      s => s.layoutIndex === selectedIndexLayout && s.peerId === ownerPeerId,
    )
  ) {
    slotsWithMediaShowYet.push({
      id: -999999,
      layoutIndex: -1,
      height: 0,
      left: 0,
      top: 0,
      radius: 0,
      width: 0,
      invisible: false,
      zIndex: -1,
      peerId: ownerPeerId,
      parentId: -1,
    })
  }
  const slotComputed = [...slots, ...slotsWithMediaShowYet]
  useEffect(() => {
    if (!subtitleSlot) {
      S.layout.subtitleSlot = { ...S.webrtc.subtitleSlot }
    }
  }, [])

  if (isShowSubtitles && !isViewmodeParticipant && subtitleSlot) {
    const slotSubtitle = {
      invisible: false,
      ...subtitleSlot,
    }
    slotComputed.push(slotSubtitle)
  } else if (!isShowSubtitles) {
    slotComputed.filter(i => i.type !== 'subTitle')
  }

  const slotsForceMuted: { [key in string]: boolean } = slotComputed.reduce(
    (acc, curr) => {
      const type = curr.type ?? curr.titleId ? 'title' : 'slot'
      const key = `key-${curr.id}-${curr.parentId}-${curr.layoutIndex}-${type}`
      const index = slotComputed
        .filter(
          s =>
            s.layoutIndex === curr.layoutIndex &&
            (curr.peerId
              ? s.peerId === curr.peerId
              : curr.mediaData?.id && curr.mediaData.id === s.mediaData?.id),
        )
        .findIndex(v => {
          const type1 = curr.type ?? curr.titleId ? 'title' : 'slot'
          const key1 = `key-${v.id}-${v.parentId}-${v.layoutIndex}-${type1}`
          return key1 === key
        })
      return index > 0
        ? Object.assign(acc, {
            [key]: true,
          })
        : acc
    },
    {},
  )
  return (
    <div
      className={clsx({
        [css.LiveStreamLayout]: true,
        [css.liveStreamLayoutFullStream]:
          selectedLayoutByIndex.defaultId === 1 &&
          S.webrtc.isSelectedLayoutFullStream,
        [css.liveStreamLayoutModern]: S.webrtc.layoutStyle === 'modern',
      })}
    >
      {S.webrtc.typeDraggingItem === DRAG_TITLE &&
        selectedLayoutByIndex.defaultId === 1 && (
          <div className={css.LiveOverlayTitle} />
        )}

      {!enableEditLayout && (
        <div
          id='dndBg'
          className={clsx({
            [css.LiveStreamOverlayDnD]: true,
            [css.liveStreamOverlayDnDDragging]:
              [...DRAG_MEDIA_ADD_PLAYLIST, ...DRAG_ALLOW_USER].includes(
                S.webrtc.typeDraggingItem,
              ) && isHoverDndBg,
            [css.liveStreamOverlayDnDHoverDrop]: isOver,
          })}
          ref={drop}
          // hoverDrop={isOver}
          // isDraggingMedia={DRAG_MEDIA_ADD_PLAYLIST.includes(
          //   store.webrtc.typeDraggingItem,
          // )}
        />
      )}
      <CustomLayout
        isEdit={enableEditLayout}
        layout={{
          id: selectedLayoutByIndex.defaultId + '',
          width: mainLayoutWidth,
          height: mainLayoutWidth / S.webrtc.customRatio,
          className: enableEditLayout ? css.EditLayoutBg : undefined,
          slots: slotComputed.map((s, i) => {
            const type = s.type ?? s.titleId ? 'title' : 'slot'
            const key = `key-${s.id}-${s.parentId}-${s.layoutIndex}-${type}`

            return {
              ...s,
              ...calculateSlot(
                {
                  top: s.top,
                  left: s.left,
                  width: s.width,
                  height: s.height,
                },
                type,
              ),
              inGroup: s?.inGroup ?? false,
              enableEdit: s?.enableEdit ?? false,
              parentId: selectedLayoutByIndex.defaultId,
              peerId: s.peerId,
              cropData: {
                ...calculateSlot({
                  top: s.cropData?.top ?? 0,
                  left: s.cropData?.left ?? 0,
                  width: s.cropData?.width ?? 0,
                  height: s.cropData?.height ?? 0,
                }),
              },
              key,
              showControl: true,
              children:
                type === 'title' ? undefined : (
                  <Slot
                    key={key}
                    layoutIndex={s.layoutIndex}
                    position={s.id}
                    totalSlots={slots as any[]}
                    isShow={!s.invisible}
                    peerId={s.peerId}
                    cropMode={s.cropMode}
                    mediaData={s.mediaData}
                    forceMuted={slotsForceMuted[key]}
                  />
                ),
            }
          }),
        }}
      ></CustomLayout>
      {typeDraggingItem === DRAG_USER_MAIN && <StyleSlotPreview />}
      {typeDraggingItem === DRAG_MEDIA_SLOT && <StyleSlotMediaPreview />}
    </div>
  )
})

export const getMediaIdsShowYet = (mediasShowed: {
  [key: string]: boolean
}): string[] => {
  const { peers, inputs } = S.webrtc
  const mediaIdsInvisible: string[] = []

  // Add media to play in background to smooth display when show up
  const connectedPeers = filterSmallPeers(peers[0].data.viewmode, peers)

  const connectedMediaIds = [
    ...connectedPeers.map(p => p.id),
    ...connectedPeers
      .filter(p => p.screenshareVideo)
      .map(p => buildScreenshareMediaId(p.id)),
    ...inputs.map(i => `input-${i.id}`),
  ]
  connectedMediaIds.forEach((mediaId, i) => {
    if (!mediasShowed[mediaId]) {
      mediaIdsInvisible.push(mediaId)
    }
  })
  return mediaIdsInvisible
}
