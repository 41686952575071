import { ModalService } from '../../../components/Modal/Modal'
import { RouterHistory } from '../../router/RouterHistory'
import { routerPaths } from '../../router/routerPaths'
import { ModalKickedOut } from '../../shared/components/ModalKickedOut'
import { NotificationRoomApi } from '../../shared/components/NotificationHandler'
import type { TChatMessage } from './WebrtcStore'

export { showRecordSuccessToast } from '../../../pages/Studio/components/BottomItemControl-showRecordToast'
export { fullscreen } from '../../../utils/fullscreen'
export { showError } from '../../../utils/showError'
export { showInputReadyToast } from '../../shared/components/ModalRMTShowReadyUrl'

export const onKickout = () => {
  ModalService.show(ModalKickedOut, {
    onClose: () => {
      RouterHistory.push(routerPaths.HOME)
      ModalService.hide()
    },
  })
}
export const showNotiSwitchRole = (
  userId: string,
  name: string,
  avatar: string,
  role: string,
) => {
  NotificationRoomApi.show({
    avatar,
    name,
    role,
    type: 'SWITCH_ROLE',
    userId,
  })
}
export const showNotiAnswerSwitchRole = (
  name: string,
  avatar: string,
  isAccepted: boolean,
) => {
  NotificationRoomApi.show({
    type: 'SWITCH_ROLE_RESPONSE',
    avatar,
    name,
    responseType: isAccepted ? 'ACCEPT' : 'REJECT',
  })
}
export const showNotiOnAddChat = (
  message: TChatMessage,
  currentUserId: string,
) => {
  const content = message.message.content
  if (content.search('mention') > -1) {
    const p = document.createElement('p')
    p.innerHTML = message.message.content
    const spans = p.getElementsByClassName('mention')
    const ids: string[] = []
    for (let i = 0; i <= spans.length; i++) {
      if (spans[i]) {
        const id = spans[i].getAttribute('data-id')
        ids.push((id as string) ?? '')
      }
    }

    const shouldShowNoti = ids.find(i => i === currentUserId)
    const notiAllId = ids.find(i => i === '-1')
    if (shouldShowNoti || (notiAllId && message.user.id !== currentUserId)) {
      NotificationRoomApi.show({
        name: message.user.name ?? '',
        message: message.message.content,
        avatar: message.user.avatar ?? '',
      })
    }
  }
}
