/* MEDIA */

export const DRAG_MEDIA_AUDIO = 'DRAG_MEDIA_AUDIO'
export const DRAG_MEDIA_VIDEO = 'DRAG_MEDIA_VIDEO'
export const DRAG_MEDIA_IMAGE = 'DRAG_MEDIA_IMAGE'
export const DRAG_MEDIA_RECORD = 'DRAG_MEDIA_RECORD'
export const DRAG_MEDIA_PLAYLIST = 'DRAG_MEDIA_PLAYLIST'
export const DRAG_MEDIA_ADD_PLAYLIST = [
  DRAG_MEDIA_AUDIO,
  DRAG_MEDIA_VIDEO,
  DRAG_MEDIA_IMAGE,
  DRAG_MEDIA_RECORD,
  DRAG_MEDIA_PLAYLIST,
]
export const DRAG_MEDIA_VIDEO_ADMIN = 'DRAG_MEDIA_VIDEO_ADMIN'
export const DRAG_MEDIA_ADD_MEDIA_SERVER = [DRAG_MEDIA_VIDEO_ADMIN]
/*  */

/* GRAPHIC */

export const DRAG_GRAPHIC_LOGO = 'DRAG_GRAPHIC_LOGO'
export const DRAG_GRAPHIC_OVERLAY = 'DRAG_GRAPHIC_OVERLAY'
export const DRAG_GRAPHIC_COLOR = 'DRAG_GRAPHIC_COLOR'

/*  */

/* TITLE */

export const DRAG_TITLE = 'DRAG_TITLE'

/*  */

/* CHAT */

export const DRAG_CHAT = 'DRAG_CHAT'

/*  */

/* CHAT */

export const DRAG_USER_PARTICIPANT = 'USER_PARTICIPANT'
export const DRAG_USER_VIEWER = 'USER_VIEWER'
export const DRAG_USER_SMALL = 'USER_SMALL'
export const DRAG_USER_MAIN = 'USER_MAIN'
export const DRAG_USER_LIST = [DRAG_USER_PARTICIPANT, DRAG_USER_VIEWER]

export const DRAG_ALLOW_USER = [
  DRAG_USER_PARTICIPANT,
  DRAG_USER_SMALL,
  DRAG_USER_MAIN,
]
/*  */

export const DRAG_ALLOW_ONLY_LAYOUT = [
  // drop to broadcast
  DRAG_GRAPHIC_LOGO,
  DRAG_GRAPHIC_OVERLAY,
  DRAG_GRAPHIC_COLOR,
  DRAG_CHAT,
  DRAG_MEDIA_AUDIO,
  DRAG_TITLE,
]

export const DRAG_ALLOW_BACKGROUND_LAYOUT = [
  // drop to exclude slot
  DRAG_MEDIA_IMAGE,
  DRAG_MEDIA_VIDEO,
  DRAG_MEDIA_RECORD,
  ...DRAG_ALLOW_USER,
  // DRAG_TITLE
]

export const DRAG_MEDIA_SLOT = 'DRAG_MEDIA_SLOT' // in case item on slot , dnd slots together

export const DRAG_MEDIA_ALLOW_SLOT = [
  // drop to each slot
  DRAG_MEDIA_VIDEO,
  DRAG_MEDIA_IMAGE,
  DRAG_MEDIA_RECORD,
  DRAG_MEDIA_SLOT,
  DRAG_MEDIA_PLAYLIST,
]

export const DRAG_ALLOW_SLOT = [
  ...DRAG_ALLOW_USER,
  ...DRAG_MEDIA_ALLOW_SLOT,
  DRAG_MEDIA_PLAYLIST,
  // DRAG_TITLE,
]
