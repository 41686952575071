import type { FC } from 'react'
import { useRef } from 'react'

import css from './PopoverBuyMoreMinute.module.scss'

import { BeamText } from '../../../components/base/Text'
import { S } from '../../../context/store'
import { formatCurrency } from '../../../utils/formatCurrency'

export const PopoverBuyMoreMinute: FC = () => {
  const plans = S.profile.plans
  const freemiumPlan = useRef<number | null | undefined>()
  const proPlan = useRef<number | null | undefined>()
  for (const item of plans) {
    for (const p of item.prices) {
      if (p.interval === 'Monthly' && p.currency === 'USD') {
        if (item.name === 'Freemium') {
          freemiumPlan.current = p.priceMinutes
        }
        if (item.name === 'Professional') {
          proPlan.current = p.priceMinutes
        }
      }
    }
  }
  return (
    <div className={css.OverlayWrapper}>
      <ArrowPopoverBuyMore />
      <div className={css.PopoverBuyMore}>
        <div className={css.PopoverBuyMoreItem}>
          <BeamText className={css.ItemTitle}>For Freemium users</BeamText>
          <BeamText
            containerProps={{ classNameContainer: css.ValueWrapper }}
            className={css.ValueText}
          >
            {formatCurrency(freemiumPlan.current)}/h
          </BeamText>
        </div>
        <div className={css.PopoverBuyMoreItem}>
          <BeamText className={css.ItemTitle}>For Professional users</BeamText>
          <BeamText
            containerProps={{ classNameContainer: css.ValueWrapper }}
            className={css.ValueText}
          >
            {formatCurrency(proPlan.current)}/h
          </BeamText>
        </div>
      </div>
    </div>
  )
}

export const ArrowPopoverBuyMore = () => (
  <div className={css.ArrowLefContainer}>
    <div className={css.ArrowLeft}>
      <span className={css.ArrowLeftIcon} />
    </div>
  </div>
)
