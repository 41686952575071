import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import { playTrack } from '../utils/playTrack'
import type { TVideoResolutionProps } from './PlayerVideoResolution'
import { VideoResolution } from './PlayerVideoResolution'

export const Video: FC<TVideoProps> = ({
  videoTrack,
  screenshare,
  onResolution,
  videoStyle: style,
  id,
}) => {
  const vref = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    playTrack(vref.current, videoTrack)
  }, [videoTrack])

  return (
    <>
      <video
        id={id}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          objectFit: `${screenshare ? 'contain' : 'cover'}`,
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          borderRadius: 'inherit',
          ...style,
        }}
        ref={vref}
        autoPlay
        playsInline
        controls={false}
        muted
      />
      {onResolution && <VideoResolution vref={vref} onChange={onResolution} />}
    </>
  )
}

export type TVideoProps = {
  videoTrack: MediaStreamTrack
  screenshare?: boolean
  onResolution?: TVideoResolutionProps['onChange']
  videoStyle?: React.CSSProperties
  id?: string
}
