import { observer } from 'mobx-react-lite'

import css from './CustomLayoutMeasureLayout.module.scss'

import { getLayoutRatio } from '#HACK_FOR_RN_ONLY/src/pages/Studio/actions/getLayoutRatio'

import { S } from '../../store'
import type { IMeasureLine } from '../../store/shared/LayoutStore'
import { EMeasureLineType } from '../../store/shared/LayoutStore'

export const MeasureLayout = observer(() => {
  const { measureLines } = S.layout
  const { ratioLW, ratioTH } = getLayoutRatio()

  return (
    <>
      {measureLines.map((l, i) => (
        <MeasureLine key={i + ''} l={l} ratioLW={ratioLW} ratioTH={ratioTH} />
      ))}
    </>
  )
})
const MeasureLine = ({
  l,
  ratioLW,
  ratioTH,
}: {
  l: IMeasureLine
  ratioTH: number
  ratioLW: number
}) => (
  <>
    {l.typeLine === EMeasureLineType.TENSE && (
      <div
        style={{
          position: 'absolute',
          zIndex: 1004,
          left: l.direction === 'vertical' ? l.left + 4 : l.left,
          top: l.direction === 'horizontal' ? l.top + 4 : l.top,
          height: l.direction === 'vertical' ? l.height : undefined,
          width: l.direction === 'horizontal' ? l.width : undefined,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: l.direction == 'vertical' ? 'column' : 'row',
        }}
      >
        <div className={css.MeasureLayoutBox}>
          <span className={css.MeasureLayoutSizeText}>
            {l.direction === 'horizontal'
              ? Math.round(l.width / ratioLW)
              : Math.round(l.height / ratioTH)}
            px
          </span>
        </div>
      </div>
    )}

    {l.typeLine === EMeasureLineType.TENSE ? (
      <div
        style={{
          position: 'absolute',
          zIndex: 1004,
          left: l.left,
          top: l.top,
          width: l.width,
          height: l.height,
          background: '#FF8FF4',
        }}
      ></div>
    ) : (
      <div
        style={{
          position: 'absolute',
          zIndex: 1004,
          left: l.left,
          top: l.top,
          width: l.width,
          height: l.height,
          border: '1px dashed #FF8FF4',
        }}
      ></div>
    )}
  </>
)
