import { useEffect } from 'react'

import css from './Live.module.scss'

import { S } from '../../../context/store'
import { LiveChat } from './LiveChat'
import { LiveMedia } from './LiveMedia'
import { LiveTitle } from './LiveTitle'

export const Live = () => {
  useEffect(() => {
    S.webrtc.updateDataOfStore({
      mediaSelectionId: [],
    })
  }, [])
  return (
    <div className={css.LiveContainer}>
      <div className={css.LiveHeader}>
        <div className={css.LiveHeaderTitle}>Live</div>
      </div>
      <div>
        <LiveTitle />
        <LiveMedia />
        <LiveChat />
      </div>
    </div>
  )
}
