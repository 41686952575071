import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'

import css from './TitleItem.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'

import { Icon } from '../../../components/base/Icon'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { S } from '../../../context/store'
import { DRAG_TITLE } from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { copyTitle } from '../actions/copyTitle'
import { toggleOnAirTitle } from '../actions/toggleOnAirTitle'
import type { TDragPreview } from './RightContent'

type Props = {
  title: TitleItem
  index: string
  onSortEnd?: (dropIndex: number, overIndex: number) => void
  onRemoveTitle?: (id: string) => void
}

type PropsAction = {
  onDuplicate: () => void
  onRemove: () => void
  onVisibleChange: () => void
  isOpeningMenu: boolean
}

export const Title = observer(
  ({ title, index, onSortEnd, onRemoveTitle }: Props) => {
    const dref = useRef<HTMLDivElement>(null)
    const { titlesOnAir, selectedIndexLayout, selectedLayoutByIndex } = S.webrtc
    const ts = selectedLayoutByIndex?.slots?.find(
      s => s.titleId === title.id,
    )?.title
    const [isOpeningMenu, setOpenMenu] = useState(false)
    const { isDragging, drag } = useDragDefault({
      item: {
        ...title,
        type: DRAG_TITLE,
        index,
      },
      dragType: DRAG_TITLE,
    })
    const [{ isOver, isBottom }, drop] = useDropSort({
      accept: DRAG_TITLE,
      index,
      dref,
      onSortEnd,
    })
    const onEdit = () => {
      copyTitle(title.id)
      setOpenMenu(false)
    }
    const onRemove = () => {
      setOpenMenu(false)
      onRemoveTitle && onRemoveTitle(title.id)
    }
    const onVisibleChange = () => {
      setOpenMenu(!isOpeningMenu)
    }
    const handleOnAir = onAir => {
      toggleOnAirTitle(title.id, onAir)
    }

    drag(drop(dref))
    const { warmnessColorGray } = S.local
    const onAir = (titlesOnAir[selectedIndexLayout] ?? []).includes(title?.id)

    return (
      <div className={css.TitleItem} ref={dref}>
        <div
          style={{ background: warmnessColorGray }}
          className={clsx({
            [css.TitleItemContainer]: true,
            [css.titleItemContainerOpeningMenu]: isOpeningMenu,
            [css.titleItemContainerOnAir]: onAir,
            [css.titleItemContainerDragging]: isDragging,
            [css.titleItemContainerDropover]: isOver,
            [css.titleItemContainerDropbottom]: isBottom,
          })}
        >
          <div
            className={clsx(css.ContentClick, {
              [css.ContentClickOffAir]: onAir,
              [css.ContentClickOnAir]: !onAir,
            })}
            onClick={() => (onAir ? handleOnAir(false) : handleOnAir(true))}
          >
            <Icon
              icon={onAir ? 'icon_off_air' : 'icon_on_air'}
              size='22'
              color='#fff'
              style={{ marginRight: 5 }}
            />
            {onAir ? 'Hide' : 'Show'}
          </div>

          <ContentItem title={ts ?? title} />

          <DropdownAction
            onDuplicate={onEdit}
            onRemove={onRemove}
            onVisibleChange={onVisibleChange}
            isOpeningMenu={isOpeningMenu}
          />

          {onAir && (
            <div className={css.TitleButtonOnAir}>
              <span className={css.TitleButtonOnAirLabel}>On Air</span>
            </div>
          )}
        </div>
      </div>
    )
  },
)

const DropdownAction = observer(
  ({
    onDuplicate: onEdit,
    onRemove,
    onVisibleChange,
    isOpeningMenu,
  }: PropsAction) => {
    const { isLightTheme, warmnessColorHover, warmnessColorPressed } = S.local
    const menu = () => (
      <div className={css.DropdownMenuAction}>
        <div
          className={clsx(
            css.DropdownMenuActionItem,
            isLightTheme
              ? css.titleItemOnChangeLight
              : css.titleItemOnChangeDark,
          )}
          key='item-edit'
          onClick={onEdit}
        >
          <Icon
            icon='Copy'
            size={13}
            className={clsx(
              css.DropdownMenuIcon,
              isLightTheme
                ? css.titleItemOnChangeLight
                : css.titleItemOnChangeDark,
            )}
          />{' '}
          Copy
        </div>
        <div
          className={clsx(
            css.DropdownMenuActionItem,
            css.titleItemDropdownMenuAction,
          )}
          key='item-remove'
          onClick={onRemove}
        >
          <Icon
            icon='icon_delete'
            size={13}
            className={clsx(
              css.DropdownMenuIcon,
              css.titleItemDropdownMenuAction,
            )}
          />{' '}
          Delete
        </div>
      </div>
    )
    return (
      <WarmnessCustomPopOver
        isLightTheme={isLightTheme}
        content={menu}
        placement='bottomLeft'
        onVisibleChange={onVisibleChange}
        visible={isOpeningMenu}
      >
        <Button
          style={{
            // @ts-ignore
            '--grey2': warmnessColorHover,
            '--background18': warmnessColorPressed,
            background: warmnessColorPressed,
          }}
          className={css.BtnEdit}
        >
          <Icon icon='icon_three_dots' size={16} />
        </Button>
      </WarmnessCustomPopOver>
    )
  },
)

export const TitlePreview = observer(() => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging || item.type !== DRAG_TITLE) {
    return null
  }
  const onAir = S.webrtc.isOnAirItemOfLayout(S.webrtc.selectedTitles, item.id)
  return (
    <div
      className={css.TitlePreviewContainer}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div className={css.TitleItem}>
        <div className={css.TitleItemContainer}>
          <ContentItem title={item as any} />

          {onAir && (
            <div className={css.TitleButtonOnAir}>
              <span className={css.TitleButtonOnAirLabel}>On Air</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const ContentItem = ({ title }: { title: TitleItem }) => (
  <span className={css.TitleItemContent}>{title.content}</span>
)
