import { observer } from 'mobx-react-lite'

import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import { DEBOUNCE_TIME } from '../../../config'
import type { ComputedPrice } from '../../../context/gql/codegen'
import {
  useGqlBuyMoreStorage,
  useGqlBuyMoreStorageCompute,
} from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { reloadCurrentPlan } from '../../../context/store/shared/reloadCurrentPlan'
import { catchPromiseError } from '../../../utils/catchPromiseError'
import { useDebounceValue } from '../../../utils/useDebounceValue'
import { BuyMore, BuyMoreType } from './BuyMore'
import { PopoverBuyMoreStorage } from './PopoverBuyMoreStorage'

export const ModalBuyMoreStorage = observer(() => {
  const {
    profile: { currentOrgId },
    currentPlan,
  } = S.profile
  const { subscription, totalStorage } = currentPlan || {}

  const [value, setValue, searchDebounce] = useDebounceValue<string>(
    DEBOUNCE_TIME,
    '',
  )

  const [{ fetching }, checkout] = useGqlBuyMoreStorage()
  const [{ data, fetching: fetchingComputed }] = useGqlBuyMoreStorageCompute({
    variables: {
      data: {
        gb: Number(searchDebounce),
        orgId: currentOrgId,
        interval: subscription?.interval ?? 'Monthly',
        currency: subscription?.currency ?? 'USD',
      },
    },
    requestPolicy: 'network-only',
    pause: !searchDebounce,
  })

  const handleCheckout = () => {
    catchPromiseError(
      checkout({
        data: {
          gb: Number(searchDebounce),
          orgId: currentOrgId,
          interval: subscription?.interval ?? 'Monthly',
          currency: subscription?.currency ?? 'USD',
        },
        at: data?.buyMoreStorageCompute?.at ?? 0,
      }),
      res => {
        if (res.data?.buyMoreStorage) {
          window.location.href = res.data?.buyMoreStorage
        } else {
          ToastService.success({ content: 'Purchased success' })
          ModalService.hide()
          reloadCurrentPlan()
        }
      },
    )
  }

  return (
    <BuyMore
      data={data?.buyMoreStorageCompute as ComputedPrice}
      value={value}
      setValue={setValue}
      type={BuyMoreType.Storage}
      onCheckout={handleCheckout}
      fetching={fetching}
      fetchingComputed={fetchingComputed}
      popoverContent={<PopoverBuyMoreStorage />}
      currentTotal={Number(totalStorage)}
      valueDebounce={searchDebounce}
      unitValue={
        searchDebounce
          ? (data?.buyMoreStorageCompute?.total ?? 0) / Number(searchDebounce)
          : 0
      }
    />
  )
})
