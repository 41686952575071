import type { User } from '../../../context/gql/codegen'
import { S } from '../../store'

export const updateUserProfile = (as: Partial<User>) => {
  const $ = S.profile
  $.profile = {
    ...$.profile,
    ...as,
  }
}
