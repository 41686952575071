import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'
import { ulid } from 'ulidx'

import css from './GraphicColor.module.scss'

import { Icon } from '../../../components/base/Icon'
import { CustomPopover } from '../../../components/widget/CustomPopover'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { S } from '../../../context/store'
import { DRAG_GRAPHIC_COLOR } from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type {
  TItemColor,
  TMediaItem,
} from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { ColorPicker } from './GraphicColorPicker'
import type { TDragPreview } from './RightContent'

export const Color = observer(() => {
  const {
    addGraphicColor,
    backgroundColor,
    updateEmitAndSaveSettings,
    updateOrderGraphicColor,
    removeGraphicColor,
    isHaveColorOnGraphic,
    updateGraphicColor,
    graphicColor,
    updateDataOfStore,
    typeDraggingItem,
  } = S.webrtc
  const { isLightTheme } = S.local
  const onSelectColor = (color: string) => {
    const newGraphicColor = graphicColor.map(c => {
      if (c.value === color) {
        return {
          ...c,
          selected: true,
        }
      } else {
        return {
          ...c,
          selected: false,
        }
      }
    })
    updateEmitAndSaveSettings({
      backgroundColor: color,
      backgroundVideoUrl: '',
      graphicColor: newGraphicColor,
    })
  }
  const onDeleteColor = (item: TMediaItem) => {
    removeGraphicColor(item.id)
    updateEmitAndSaveSettings(
      backgroundColor === item.value ? { backgroundColor: '' } : {},
    )
  }
  const onSortEnd = (dropIndex: number, overIndex: number) => {
    updateOrderGraphicColor(dropIndex, overIndex)
    updateEmitAndSaveSettings()
  }

  const onSelectColorPicker = (color: string) => {
    updateEmitAndSaveSettings({
      backgroundColor: color,
    })
  }
  const onAddColor = () => {
    if (!isHaveColorOnGraphic(backgroundColor)) {
      addGraphicColor([
        { id: ulid(), value: backgroundColor, mediaType: 'color' },
      ])
      updateEmitAndSaveSettings()
    }
  }
  const onEditColor = (id: string, color: string) => {
    onSelectColorPicker(color)
    updateGraphicColor(id, color)
    updateEmitAndSaveSettings()
  }
  const onShowEditColor = (id: string) => {
    const newGraphicColor = graphicColor.map(c => {
      if (c.id === id) {
        return {
          ...c,
          selected: true,
        }
      } else {
        return {
          ...c,
          selected: false,
        }
      }
    })
    updateEmitAndSaveSettings({
      graphicColor: newGraphicColor,
    })
  }
  return (
    <div>
      <p className={css.GraphicColorNote}>
        To use image, video, gif as a background{' '}
        <span
          onClick={() => updateDataOfStore({ selectedRightTabBarKey: '3' })}
        >
          Please go to Media tab
        </span>
      </p>
      <div className={css.GraphicColorList}>
        <div className={css.GraphicColorItem}>
          <WarmnessCustomPopOver
            placement='left'
            overlayClassName='popover-custom-arrow'
            isShowArrow
            isLightTheme={isLightTheme}
            content={
              <ColorPicker
                isLightTheme={isLightTheme}
                onSelectColor={onSelectColor}
                onAddColor={onAddColor}
                defaultColor={backgroundColor}
              />
            }
          >
            <div
              className={clsx(css.GraphicColorIconPicker, {
                [css.graphicColorWrapperSelected]: !graphicColor.some(
                  g => g.selected,
                ),
              })}
            >
              <Icon icon='icon_color_picker' size={15} />
            </div>
          </WarmnessCustomPopOver>
        </div>
        {graphicColor.map((item, index) => (
          <ItemColor
            key={index}
            onShowOption={onShowEditColor}
            index={index.toString()}
            item={item}
            isLightTheme={isLightTheme}
            onSelectColor={onSelectColor}
            selected={item.selected ? true : false}
            onSortEnd={onSortEnd}
            onDeleteColor={onDeleteColor}
            onEditColor={onEditColor}
          />
        ))}
      </div>
      {typeDraggingItem === DRAG_GRAPHIC_COLOR && <ItemColorPreview />}
    </div>
  )
})

const ItemColor = ({
  item,
  onSelectColor,
  selected,
  index,
  onSortEnd,
  onDeleteColor,
  onEditColor,
  isLightTheme,
  onShowOption,
}: TItemColor) => {
  const dref = useRef<HTMLDivElement>(null)
  const [togglePopover, setPopover] = useState(false)
  const [isToggleEdit, setPopoverEdit] = useState(false)
  const { isDragging, drag } = useDragDefault({
    item: { id: item.id, value: item.value, type: DRAG_GRAPHIC_COLOR, index },
    dragType: DRAG_GRAPHIC_COLOR,
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: DRAG_GRAPHIC_COLOR,
    index,
    dref,
    onSortEnd,
    direction: 'x',
  })

  const handleItemPopover = (type: string) => {
    setPopover(false)
    if (type === 'use') {
      onSelectColor(item.value)
    }

    if (type === 'delete') {
      onDeleteColor(item)
    }

    if (type === 'edit') {
      onShowOption?.(item.id)
      setPopoverEdit(true)
      // onEdit && onEdit(item.value)
    }
  }
  const contentPopover = () => (
    <div className={css.GraphicColorPopoverContainer}>
      <div
        className={css.GraphicColorPopoverItem}
        onClick={() => handleItemPopover('use')}
      >
        <Icon
          icon='icon_media_image'
          className={css.GraphicColorPopoverIcon}
          size={13}
        />
        Use as session background
      </div>
      <div
        className={css.GraphicColorPopoverItem}
        onClick={() => handleItemPopover('edit')}
      >
        <Icon
          icon='icon_pencil'
          className={css.GraphicColorPopoverIcon}
          size={13}
        />
        Edit
      </div>
      <div
        className={css.GraphicColorPopoverItem}
        onClick={() => handleItemPopover('delete')}
      >
        <Icon
          icon='icon_delete'
          className={css.GraphicColorPopoverIcon}
          size={13}
        />
        Delete
      </div>
    </div>
  )

  const onSelectColorEdit = (color: string) => {
    if (color !== item.value && isToggleEdit) {
      onEditColor(item.id, color)
    }
  }

  const handleClickColor = () => {
    onSelectColor(item.value)
  }
  const handleShowOption = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setPopover(true)
  }
  drag(drop(dref))

  return (
    <div className={css.GraphicColorItem} ref={dref}>
      <CustomPopover
        placement='left'
        isShowArrow
        content={
          <ColorPicker
            onSelectColor={onSelectColorEdit}
            defaultColor={item.value}
            isLightTheme={isLightTheme}
          />
        }
        visible={isToggleEdit}
        onVisibleChange={(visible: boolean) => {
          setPopoverEdit(visible)
        }}
        overlayClassName='popover-custom-arrow'
      >
        <div className={css.WrapperPopoverEdit} />
      </CustomPopover>
      <div
        className={clsx({
          [css.GraphicColorWrapper]: true,
          [css.graphicColorWrapperSelected]: selected,
          [css.graphicColorWrapperDragging]: isDragging,
          [css.graphicColorWrapperDropover]: isOver,
          [css.graphicColorWrapperDropbottom]: isOver && isBottom,
        })}
        onClick={handleClickColor}
        onContextMenu={handleShowOption}
        // dropover={isOver}
        // dropbottom={isBottom}
        // selected={selected}
        // dragging={isDragging}
      >
        <div
          className={clsx({
            [css.GraphicColorContent]: true,
            [css.graphicColorContentDragging]: isDragging,
          })}
          // dragging={isDragging}

          style={{ background: isDragging ? 'transparent' : item.value }}
        ></div>
      </div>
      <CustomPopover
        placement='bottomRight'
        content={contentPopover}
        visible={togglePopover}
        onVisibleChange={(visible: boolean) => setPopover(visible)}
      >
        <div className={css.WrapperPopoverAction} />
      </CustomPopover>
    </div>
  )
}
export const ItemColorPreview = () => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging || item.type !== DRAG_GRAPHIC_COLOR) {
    return null
  }
  return (
    <div
      className={css.GraphicColorPreview}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div className={css.GraphicColorItem}>
        <div className={css.GraphicColorWrapper}>
          <div
            className={css.GraphicColorContent}
            style={{ background: item.value }}
          ></div>
        </div>
      </div>
    </div>
  )
}
