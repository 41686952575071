import clsx from 'clsx'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'
import { useAudio, useVideo } from 'react-use'

import css from './MediaPlaylists.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessDiv } from '../../../components/composites/WarmnessDiv'
import { WarmnessGrayDiv } from '../../../components/composites/WarmnessGrayDiv'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { S } from '../../../context/store'
import {
  DRAG_MEDIA_ADD_PLAYLIST,
  DRAG_MEDIA_PLAYLIST,
} from '../../../context/store/studio/dragTypes'
import {
  covertDurationToMinutes,
  formatFileName,
  getDragTypeByMediaType,
  getItemPreviewStyles,
  getNameOfUrl,
} from '../../../context/store/studio/utils'
import type { TMediaPlaylistDetail } from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { AudioPlayer } from './AudioPlayer'
import type { TDragPreview } from './RightContent'

type TPlaylists = {
  playlists: TMediaPlaylistDetail[]
  onUpdateSetting?: () => void
}

type TPlaylist = {
  playlist: TMediaPlaylistDetail
  onDuplicate: () => void
  onRemoveAll: () => void
  onRemove: () => void
  onDownload: () => void
  onSelectMedia?: (e: MouseEvent) => void
  isOpenMedia: boolean
  index: string
  onSortEnd: (dropIndex: number, overIndex: number) => void
  onDragEnd: () => void
  dragSelected: boolean
  draggingSelected: boolean
  isDraggingSelect: boolean
  isOnAir: boolean
  onTurnOffAir?: (typeMedia: string) => void
  warmnessColorGray?: string
}
type TPlaylistType = {
  value: string
  name?: string
  mediaType: string
  duration?: number
}
type TPlaylistAction = {
  onDuplicate: () => void
  onRemoveAll: () => void
  onRemove: () => void
  onDownload: () => void
}

type TPlaylistItem = {
  item: TPlaylistType
  isOnAir: boolean
  isOpenMedia?: boolean
  isModeReview?: boolean
  onTurnOffAir?: (typeMedia: string) => void
}

export const MediaPlaylists = observer(
  ({ onUpdateSetting }: { onUpdateSetting: () => void }) => {
    const [playlists, setPlaylist] = useState<TMediaPlaylistDetail[]>([])

    useEffect(() => {
      const fullPlaylist = S.webrtc.getFullMediaPlaylists()
      setPlaylist(fullPlaylist)
    }, [S.webrtc.mediaPlaylists, S.webrtc.mediaStudio])

    useEffect(() => {
      // in case : remove all playlist but not change status drop playlist
      if (
        S.webrtc.mediaPlaylists.length === 0 &&
        S.webrtc.typeDraggingItem === DRAG_MEDIA_PLAYLIST
      ) {
        S.webrtc.updateDataOfStore({
          typeDraggingItem: '',
        })
      }
    }, [S.webrtc.mediaPlaylists, S.webrtc.typeDraggingItem])

    return (
      <>
        <div className={css.MediaPlaylistNote}>
          Media preview is played in off-air mode
        </div>
        <PlayLists playlists={playlists} onUpdateSetting={onUpdateSetting} />
      </>
    )
  },
)

export const PlayLists = observer(
  ({ playlists, onUpdateSetting }: TPlaylists) => {
    const [mediaIdOpen, setOpenMedia] = useState('')
    const {
      mediaSelectionId,
      backgroundUrl,
      audioUrl,
      selectedIndexLayout,
      typeDraggingItem,
      backgroundVideoUrl,
      layoutMedias,
      stateVideoPreviewId,
      addInitStateVideoPreview,
      isOnAirItemOfLayout,
      updateAndEmit,
      updateOrderPlaylist,
      removeMediasOfLayoutSlot,
      duplicateMediaPlaylist,
      removeAllItemPlaylist,
      removeItemPlaylist,
      toggleMediaSelectionId,
      updateDataOfStore,
    } = S.webrtc
    const { warmnessColorGray } = S.local
    const onDuplicate = (duplicateId: string) => {
      duplicateMediaPlaylist(duplicateId)
      onUpdateSetting?.()
    }

    const onRemoveAll = (playlistId: string, mediaId: string) => {
      removeAllItemPlaylist(playlistId, mediaId)
      // store.webrtc.removeMediasOfLayoutSlot([playlistId])
      onUpdateSetting?.()
    }

    const onRemove = (playlistId: string) => {
      removeItemPlaylist([playlistId])
      // store.webrtc.removeMediasOfLayoutSlot([playlistId])
      onUpdateSetting?.()
    }
    const onDownload = (url: string) => {
      saveAs(url)
    }
    const onSelectMedia = (e: MouseEvent, item: TMediaPlaylistDetail) => {
      if (e.shiftKey) {
        toggleMediaSelectionId(item.id)
        return
      }
      setOpenMedia(item.id)
      addInitStateVideoPreview(item.mediaId)
      updateDataOfStore({
        mediaSelectionId: [],
        stateVideoPreviewId:
          stateVideoPreviewId !== item.mediaId ? item.mediaId : '',
      })
    }

    const onSortEnd = (dropIndex: number, overIndex: number) => {
      if (
        S.webrtc.mediaSelectionId.length <= 1 &&
        S.webrtc.selectedRightTabBarKey !== '1'
      ) {
        updateOrderPlaylist(dropIndex, overIndex)
        onUpdateSetting?.()
      }
    }

    const onDragEnd = () => {
      if (S.webrtc.mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }

    const onTurnOffAir = (item: TMediaPlaylistDetail, typeMedia?: string) => {
      removeMediasOfLayoutSlot([item.mediaId], selectedIndexLayout)
      if (typeMedia === 'audio' && S.webrtc.audioUrl === item.value) {
        updateAndEmit({
          audioUrl: '',
        })
      }
      if (typeMedia === 'image' && S.webrtc.backgroundUrl === item.value) {
        updateAndEmit({
          backgroundUrl: '',
        })
      }
      if (typeMedia === 'video' && S.webrtc.backgroundVideoUrl === item.value) {
        updateAndEmit({
          backgroundVideoUrl: '',
        })
      }
      onUpdateSetting?.()
    }

    useEffect(() => {
      if (S.webrtc.stateVideoPreviewId !== '' && mediaIdOpen !== '') {
        setOpenMedia('')
      }
    }, [S.webrtc.stateVideoPreviewId])

    useEffect(() => {
      const detailId = playlists.find(item => item.value === S.webrtc.audioUrl)
      if (detailId) {
        setOpenMedia(detailId.id)
      } else {
        if (mediaIdOpen !== '') {
          setOpenMedia('')
          updateDataOfStore({
            isAuthorAudioUrl: false,
          })
        }
      }
    }, [audioUrl])
    return (
      <>
        <div className={css.MediaPlaylistContainer}>
          {playlists.map((item, index) => (
            <Fragment key={index}>
              <Playlist
                playlist={item}
                index={index.toString()}
                key={index}
                onDuplicate={() => onDuplicate(item.id)}
                onRemoveAll={() => onRemoveAll(item.id, item.mediaId)}
                onRemove={() => onRemove(item.id)}
                onDownload={() => onDownload(item.value)}
                isOpenMedia={mediaIdOpen === item.id}
                onSelectMedia={(e: MouseEvent) => onSelectMedia(e, item)}
                onSortEnd={onSortEnd}
                onDragEnd={onDragEnd}
                dragSelected={mediaSelectionId.includes(item.id)}
                isDraggingSelect={mediaSelectionId.length > 0}
                draggingSelected={
                  mediaSelectionId.includes(item.id) &&
                  typeDraggingItem === DRAG_MEDIA_PLAYLIST
                }
                warmnessColorGray={warmnessColorGray}
                onTurnOffAir={(typeMedia: string) =>
                  onTurnOffAir(item, typeMedia)
                }
                isOnAir={
                  isOnAirItemOfLayout(layoutMedias, item.mediaId) ||
                  audioUrl === item.value ||
                  backgroundVideoUrl === item.value ||
                  backgroundUrl === item.value
                }
              />
            </Fragment>
          ))}
        </div>
        {DRAG_MEDIA_PLAYLIST === S.webrtc.typeDraggingItem && (
          <PlaylistPreview
            mediaIdOpen={mediaIdOpen}
            totalItem={S.webrtc.mediaSelectionId.length}
          />
        )}
      </>
    )
  },
)

export const Playlist = ({
  playlist,
  onDuplicate,
  onRemoveAll,
  onRemove,
  onSelectMedia,
  onDownload,
  isOpenMedia,
  index,
  onSortEnd,
  dragSelected,
  draggingSelected,
  isDraggingSelect,
  isOnAir = false,
  onTurnOffAir,
  onDragEnd,
  warmnessColorGray,
}: TPlaylist) => {
  const dref = useRef<HTMLDivElement>(null)
  const { isDragging, drag } = useDragDefault({
    item: {
      id: playlist.mediaId,
      index,
      name: playlist?.name || '',
      value: playlist.value,
      mediaType: playlist.mediaType,
      playlistId: playlist.id,
    },
    dragType: getDragTypeByMediaType(playlist.mediaType),
    draggingType: DRAG_MEDIA_PLAYLIST,
    onDragEnd,
  })
  const [{ isOver, isBottom }, drop] = useDropSort({
    accept: DRAG_MEDIA_ADD_PLAYLIST,
    index,
    dref,
    onSortEnd,
  })

  const renderItem = () => {
    if (playlist.mediaType === 'audio') {
      return (
        <ItemAudio
          item={playlist}
          isOpenMedia={isOpenMedia}
          isOnAir={isOnAir}
          onTurnOffAir={onTurnOffAir}
        />
      )
    }
    if (playlist.mediaType === 'video') {
      return (
        <ItemVideo
          item={playlist}
          isOnAir={isOnAir}
          isOpenMedia={isOpenMedia}
          onTurnOffAir={onTurnOffAir}
        />
      )
    }
    return (
      <ItemImage
        item={playlist}
        isOnAir={isOnAir}
        onTurnOffAir={onTurnOffAir}
      />
    )
  }

  drag(drop(dref))

  return (
    <WarmnessGrayDiv
      ref={dref}
      data-selection={onSelectMedia ? playlist.id : ''}
      className={clsx({
        ['media']: onSelectMedia,
        [css.MediaPlaylistItem]: true,
        [css.mediaPlaylistItemDropbottom]: isBottom,
        [css.mediaItemVerticalContainerDragSelected]:
          (dragSelected && !draggingSelected) ||
          (playlist.mediaType === 'video' && isOpenMedia),
        [css.mediaItemVerticalContainerDrophover]: isOver && !isDraggingSelect,
        [css.mediaPlaylistItemOnAir]:
          isOnAir &&
          !(isDragging || draggingSelected) &&
          !(dragSelected && !draggingSelected),
      })}
    >
      <div className={css.MediaPlayItemWrapperClick} onClick={onSelectMedia} />
      {renderItem()}
      {(!isOpenMedia || (playlist.mediaType !== 'audio' && isOpenMedia)) && (
        <ItemAction
          onDuplicate={onDuplicate}
          onRemoveAll={onRemoveAll}
          onRemove={onRemove}
          onDownload={onDownload}
        />
      )}
      {(isDragging || draggingSelected) && (
        <WarmnessGrayButton
          isButton={false}
          className={css.MediaPlaylistDragging}
        />
      )}
    </WarmnessGrayDiv>
  )
}

const ItemImage = ({
  item: { value, name = '', mediaType },
  isOnAir,
  onTurnOffAir,
}: TPlaylistItem) => (
  <>
    <div className={css.MediaPlaylistItemLeft}>
      <img className={css.MediaPlaylistImage} src={value} />
      {mediaType === 'gif' && (
        <span className={css.MediaPlaylistIconGIF}>
          <Icon icon='icon_media_gif' size='8' />
        </span>
      )}
    </div>
    <div className={css.MediaPlaylistItemRight}>
      <div>
        {name ? formatFileName(name) : formatFileName(getNameOfUrl(value))}
      </div>
      {isOnAir && (
        <div
          className={css.MediaPlaylistImgButtonOnAir}
          onClick={() => onTurnOffAir?.('image')}
        >
          On Air
        </div>
      )}
    </div>
  </>
)

const ItemVideo = ({
  item: { value, name = '', duration },
  isOnAir,
  onTurnOffAir,
}: TPlaylistItem) => {
  const [video, state] = useVideo(
    <video className={css.MediaPlaylistTagVideo} src={value} />,
  )
  duration = duration || state.duration
  return (
    <>
      <div className={css.MediaPlaylistItemLeft}>
        {video}
        <span className={css.MediaPlaylistIconPlay}>
          <Icon icon='icon_play' size={12} />
        </span>
      </div>
      <div className={css.MediaPlaylistItemRight}>
        <div>
          {name !== ''
            ? formatFileName(name)
            : formatFileName(getNameOfUrl(value))}
        </div>
        <div className={css.MediaPlayListItemInfo}>
          {isOnAir && (
            <div
              className={css.MediaPlaylistVideoButtonOnAir}
              onClick={() => onTurnOffAir?.('video')}
            >
              On Air
            </div>
          )}
          <div className={css.MediaPlaylistItemDuration}>
            {covertDurationToMinutes(duration)}
          </div>
        </div>
      </div>
    </>
  )
}

const ItemAudio = ({
  item: { value, name = '', duration },
  isOpenMedia = false,
  isOnAir = false,
  isModeReview = false,
  onTurnOffAir,
}: TPlaylistItem) => {
  const [audio, state] = useAudio(<audio src={value} />)
  duration = duration || state.duration
  if (isOpenMedia) {
    return (
      <AudioPlayer
        url={value}
        onAir={isOnAir}
        autoPlay={!isModeReview}
        onTurnOffAir={() => onTurnOffAir?.('audio')}
      />
    )
  }
  return (
    <>
      <div className={css.MediaPlaylistItemLeft}>
        {audio}
        <span className={css.MediaPlaylistIconAudio}>
          <Icon icon='icon_media_music' size={22} />
        </span>
      </div>
      <div className={css.MediaPlaylistItemRight}>
        <div>
          {name !== ''
            ? formatFileName(name)
            : formatFileName(getNameOfUrl(value))}
        </div>
        <div className={css.MediaPlayListItemInfo}>
          {isOnAir && (
            <div
              className={css.MediaPlaylistVideoButtonOnAir}
              onClick={() => onTurnOffAir?.('audio')}
            >
              On Air
            </div>
          )}
          <div className={css.MediaPlaylistItemDuration}>
            {covertDurationToMinutes(duration)}
          </div>
        </div>
      </div>
    </>
  )
}

const ItemAction = observer(
  ({ onDuplicate, onRemoveAll, onRemove, onDownload }: TPlaylistAction) => {
    const [isVisible, setVisible] = useState(false)
    const { warmnessColorPressed, warmnessColorHover } = S.local
    const handelDuplicate = () => {
      setVisible(false)
      onDuplicate()
    }
    const handleRemoveAll = () => {
      setVisible(false)
      onRemoveAll()
    }

    const handleRemove = () => {
      setVisible(false)
      onRemove()
    }

    const handleDownload = () => {
      setVisible(false)
      onDownload()
    }
    const content = () => (
      <div className={css.MediaPlaylistDDContainer}>
        <WarmnessDiv className={css.MediaPlaylistDDContent}>
          {' '}
          <div
            className={css.MediaPlaylistDDItemClone}
            onClick={handelDuplicate}
          >
            <span className={css.MediaPlaylistDDIcon}>
              <Icon icon='icon_copy' size={13} />
            </span>
            <p className={css.MediaPlaylistDDName}>Duplicate Media</p>
            <span className={css.MediaPlaylistDDSub}>
              This action only duplicates the media in the playlist
            </span>
          </div>
          <div
            className={css.MediaPlaylistDLItemClone}
            onClick={handleDownload}
          >
            <span className={css.MediaPlaylistDDIcon}>
              <Icon icon='icon_download' size={13} />
            </span>
            <p className={css.MediaPlaylistDDName}>Download</p>
          </div>
          <div
            className={css.MediaPlaylistDDItemRemoveAll}
            onClick={handleRemoveAll}
          >
            <span className={css.MediaPlaylistDDIcon}>
              <Icon icon='icon_warning' size={13} />
            </span>
            <p className={css.MediaPlaylistDDName}>Remove all</p>
            <span className={css.MediaPlaylistDDSub}>
              Removing won't delete the media
            </span>
          </div>
          <div className={css.MediaPlaylistDDItemRemove} onClick={handleRemove}>
            <span className={css.MediaPlaylistDDIcon}>
              <Icon icon='icon_warning' size={13} />
            </span>
            <p className={css.MediaPlaylistDDName}>Remove this one only</p>
            <span className={css.MediaPlaylistDDSub}>
              Removing won't delete the media
            </span>
          </div>
        </WarmnessDiv>
      </div>
    )
    const onVisibleChange = (visible: boolean) => {
      setVisible(visible)
    }
    return (
      <WarmnessCustomPopOver
        content={content}
        visible={isVisible}
        onVisibleChange={onVisibleChange}
        placement='bottomLeft'
      >
        <span
          style={{
            // @ts-ignore
            '--grey19': warmnessColorPressed,
            '--background-hover-dark': warmnessColorHover,
            '--background-active-dark': warmnessColorPressed,
          }}
          className={clsx({
            [css.MediaPlaylistIconPopover]: true,
            [css.mediaPlaylistIconPopoverSelected]: isVisible,
          })}
        >
          <Icon icon='icon_three_dots' size={16} />
        </span>
      </WarmnessCustomPopOver>
    )
  },
)

const PlaylistPreview = observer(
  ({
    mediaIdOpen,
    totalItem = 0,
  }: {
    mediaIdOpen: string
    totalItem: number
  }) => {
    const { isDragging, item, initialOffset, currentOffset } =
      useDragLayer<TDragPreview>(monitor => ({
        item: monitor.getItem(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
      }))

    const isOnAir =
      S.webrtc.isOnAirItemOfLayout(
        S.webrtc.layoutMedias,
        item?.mediaId || '',
      ) || S.webrtc.audioUrl === (item?.value || '')
    if (!isDragging) {
      return null
    }

    const renderItem = () => {
      const dataItem = {
        value: item.value,
        name: item.name,
        mediaType: item.mediaType,
      }
      if (item.mediaType === 'audio') {
        return (
          <ItemAudio
            item={dataItem}
            isOnAir={isOnAir}
            isOpenMedia={mediaIdOpen === item.id}
            isModeReview
          />
        )
      }
      if (item.mediaType === 'video') {
        return <ItemVideo item={dataItem} isOnAir={isOnAir} />
      }
      return <ItemImage item={dataItem} isOnAir={isOnAir} />
    }

    return (
      <div
        className={css.MediaPlaylistPreview}
        style={getItemPreviewStyles(initialOffset, currentOffset)}
      >
        <WarmnessGrayButton
          isButton={false}
          className={clsx({
            [css.MediaPlaylistItem]: true,
            [css.mediaPlaylistItemOnAir]: isOnAir,
          })}
        >
          {renderItem()}
        </WarmnessGrayButton>
        {totalItem > 0 && (
          <div className={css.MediaPreviewTotalItem}>{totalItem}</div>
        )}
      </div>
    )
  },
)
