import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import ReactAvatar from 'react-avatar'

import css from './ModalInviteUserList.module.scss'

import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { Map } from '../../../components/base/Map'
import { getAvatarColor } from '../../../utils/avatar'
import { ModalInviteUserListAction } from './ModalInviteUserListAction'

type Props = {
  data: PeerUI[]
  userId: string
}
export const ModalInviteUserList: FC<Props> = observer(({ data, userId }) => {
  const renderItem = (item: PeerUI) => (
    <div className={css.User}>
      <div className={css.UserInfor}>
        {item.data.avatar ? (
          <img src={item.data.avatar} className={css.UserAvatar} />
        ) : (
          <ReactAvatar
            name={item.data.name ? item.data.name[0] : '.'}
            round
            className={css.UserAvatar}
            size='28'
            textSizeRatio={3}
            color={getAvatarColor(item.data.name ? item.data.name[0] : '.')}
          />
        )}

        <div className={css.UserName}>{item.data.name}</div>
        {userId === item.data.userId && (
          <div className={css.UserOnwer}>(you)</div>
        )}
      </div>
      <ModalInviteUserListAction
        item={item}
        isCurrentUser={userId === item.data.userId}
      />
    </div>
  )
  return (
    <div className={css.ModalUserList}>
      {data.length > 0 ? (
        <Map<PeerUI> list={data} renderItem={item => renderItem(item)} />
      ) : (
        <div className={css.NoUser}>There is no one in this room</div>
      )}
    </div>
  )
})
