import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Subtitle } from '#HACK_FOR_RN_ONLY/src/context/actions/layout/subtitle'

import { S } from '../../../../context/store'
import { getAudiosForSub } from '../../actions/getAudiosForSub'
import { Player } from '../Player'

const rHostSub = new Subtitle('rhost')
const rRobserverSub = new Subtitle('robserver')
export const SubtitleClient = observer(() => {
  const { enableSubOnSubClient, peers, inputs, selectedIndexLayoutSub } =
    S.webrtc
  const controlMediaPeer = peers.find(p => p.data.controlMedia)
  const amsPeer = peers.find(p => p.data.viewmode === 'ams-client')
  const hostAudios = getAudiosForSub(
    'rhost',
    peers,
    amsPeer,
    controlMediaPeer?.medias,
    inputs,
    selectedIndexLayoutSub?.host,
  )
  const observerAudios = getAudiosForSub(
    'robserver',
    peers,
    amsPeer,
    controlMediaPeer?.medias,
    inputs,
    selectedIndexLayoutSub?.observer,
  )

  const audios = [...hostAudios, ...observerAudios]
  const audiosRendered = audios.reduce((acc, item) => {
    if (!acc.some(obj => obj.mediaId === item.mediaId)) {
      acc.push(item)
    }
    return acc
  }, [] as any)
  useEffect(() => {
    if (enableSubOnSubClient.host) {
      rHostSub.process({ audios: hostAudios })
    } else {
      rHostSub.stopSubtitle()
    }

    if (enableSubOnSubClient.observer) {
      rRobserverSub.process({ audios: observerAudios })
    } else {
      rRobserverSub.stopSubtitle()
    }
  }, [audiosRendered, enableSubOnSubClient])
  return (
    <>
      {audiosRendered.map(a =>
        a.willRender ? (
          <Player
            key={a.mediaId}
            audioTrack={a.audioTrack as MediaStreamTrack}
            volume={1}
            muted={false}
          />
        ) : null,
      )}

      {S.webrtc.inputs?.map(i => (
        <Player
          muted={false}
          audioTrack={i.audioTrack}
          key={i.id}
          id={`input-${i.id}`}
        />
      ))}
    </>
  )
})
