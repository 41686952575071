import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { Resource } from '../../../context/store/insight-engine/InsightEngineStore'

export const updateResource = async (note: any) => {
  await reduxStore.context.gql.updateResourceInIe({
    id: S.insightEngine.resourceSelected?.id ?? '',
    data: { note },
  })
}
export const updateResourceSelected = (r: Partial<Resource>) => {
  const $ = S.insightEngine
  Object.assign($.resourceSelected ?? {}, r)
}
