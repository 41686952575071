import { observer } from 'mobx-react-lite'

// eslint-disable-next-line css-modules/no-unused-class
import css from './LiveItemCommon.module.scss'

import { S } from '../../../context/store'
import { DRAG_TITLE } from '../../../context/store/studio/dragTypes'
import { ItemAccordion } from './ItemAccordion'
import { Title, TitlePreview } from './TitleItem'

export const LiveTitle = observer(() => {
  const { titles, selectedTitles, isOnAirItemOfLayout, typeDraggingItem } =
    S.webrtc

  const listFilter = titles
    .filter(item => !isOnAirItemOfLayout(selectedTitles, item.id))
    .slice(0, 2)

  return (
    <>
      <div className={css.LiveItemContainer}>
        <ItemAccordion label='Title'>
          <div className={css.LiveItemAccordionContent}>
            <div className={css.ListItemLive}>
              {listFilter.length > 0 ? (
                listFilter.map((item, index) => (
                  <Title
                    title={item}
                    key={index}
                    index={index.toString()}
                    onSortEnd={() => {}}
                  />
                ))
              ) : (
                <span className={css.NotYetText}>There are no title yet</span>
              )}
            </div>
          </div>
        </ItemAccordion>
      </div>
      {typeDraggingItem === DRAG_TITLE && <TitlePreview />}
    </>
  )
})
