import { Tabs } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import type { AppTabsProps } from './type'

export const AppTabs: FC<AppTabsProps> = ({
  type = 'line',
  sizeLevel = 'S',
  children,
  className,
  ...tabProps
}) => (
  <Tabs
    className={clsx(
      css.TabBar,
      {
        [css.TabBarCard]: type === 'card',
        [css.TabBarLine]: type === 'line',
        [css.TabBarCardS]: sizeLevel === 'S',
        [css.TabBarCardM16]: sizeLevel === 'M16',
        [css.TabBarCardM13]: sizeLevel === 'M13',
      },
      className,
    )}
    tabBarGutter={0}
    {...tabProps}
  >
    {children}
  </Tabs>
)
