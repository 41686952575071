import clsx from 'clsx'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ulid } from 'ulidx'

import css from './Chat.module.scss'

import { BeamSwitch } from '../../../components/base/Switch'
import { S } from '../../../context/store'
import { serverTime } from '../../../context/store/shared/serverTime'
import { DRAG_CHAT } from '../../../context/store/studio/dragTypes'
import { SourceChat } from '../../../context/store/studio/WebrtcStore'
import { ItemChat, ItemChatPreview } from './ChatItem'
import { Composer } from './Composer'
import type { TUploadedFile } from './RightContent'

export const Chat = observer(() => {
  const [attachments, setAttachments] = useState<TUploadedFile[]>([])
  const [message, setMessage] = useState('')
  const [isSentMessage, setSentMessage] = useState(false)
  const listChatRef = useRef<HTMLDivElement>(null)
  const { role, messages, sourceChat, setSourceChat, isViewmodeParticipant } =
    S.webrtc
  const { profile } = S.profile
  const localPeer = S.webrtc.peers[0]
  const { isLightTheme, warmnessColorGray } = S.local
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const sendMessage = (value: string) => {
    if (
      value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      attachments.length === 0
    ) {
      return
    }

    // if (attachments.length === 0 && message.trim() === '') {
    //  return
    // }
    let formatMessage = ''
    if (value && value.trim() !== '') {
      formatMessage = value.replace(/^\s+|\s+$/g, '')
      formatMessage = value.replace('<p><br></p>', '')
    }
    const chatId = ulid()
    const data = {
      user: {
        name: localPeer.data.name + ' (You)',
        id: localPeer.data?.userId ?? '',
        avatar: localPeer.data?.avatar,
        role: role?.name,
      },
      message: {
        chatId,
        content: formatMessage,
        attachments,
        time: serverTime.date(),
        type: role?.name === 'Participant' ? SourceChat.PUBLIC : sourceChat,
      },
    }
    S.webrtc.addChat(data)
    setMessage('')
    setAttachments([])
    setSentMessage(true)
    setTimeout(() => {
      setSentMessage(false)
    }, 500)

    S.webrtc.mediasoup?.protoo?.request('addChat', {
      content: formatMessage,
      chatId,
      attachments,
      type: sourceChat,
      userId: profile.id,
      role: role?.name,
      avatar: profile.avatarUrl,
    })
  }

  const debounceSendMessage = useCallback(debounce(sendMessage, 50), [
    attachments,
    sourceChat,
    profile,
    role?.name,
    localPeer,
  ])

  const onAttachment = (files: TUploadedFile[]) => {
    if (files.length > 0) {
      setAttachments(files)
    }
  }
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [S.webrtc.totalMessageChat])

  return (
    <div className={css.ChatWrapper}>
      <div className={css.ChatContainer}>
        <div className={css.ChatHeader}>
          <div className={css.ChatHeaderTitle}>Chat</div>
          {!isViewmodeParticipant && sourceChat === SourceChat.PUBLIC && (
            <div className={css.ChatHeaderSwitch}>
              <BeamSwitch
                style={{ background: warmnessColorGray }}
                isLightTheme={isLightTheme}
                checked={S.webrtc.isShowOverlayChat}
                onChange={S.webrtc.toggleShowOverlayChat}
              />
              Chat overlay
            </div>
          )}
        </div>
        {!isViewmodeParticipant && (
          <div className={css.FilterContainer}>
            <div
              onClick={() => setSourceChat(SourceChat.INTERNAL)}
              className={css.FilterItem}
            >
              <div
                className={clsx(css.FilterItemTop, {
                  [css.FilterItemSelected]: sourceChat === SourceChat.INTERNAL,
                })}
              >
                Internal{' '}
                {messages.chatInternal.length > 0 && (
                  <span className={css.ChatCount}>
                    {messages.chatInternal.length}
                  </span>
                )}
              </div>
              {sourceChat === SourceChat.INTERNAL && (
                <div className={css.FilterItemBottom} />
              )}
            </div>
            <div
              onClick={() => setSourceChat(SourceChat.PUBLIC)}
              className={css.FilterItem}
            >
              <div
                className={clsx(css.FilterItemTop, {
                  [css.FilterItemSelected]: sourceChat === SourceChat.PUBLIC,
                })}
              >
                Public{' '}
                {messages.chatPublic.length > 0 && (
                  <span className={css.ChatCount}>
                    {messages.chatPublic.length}
                  </span>
                )}
              </div>
              {sourceChat === SourceChat.PUBLIC && (
                <div className={css.FilterItemBottom} />
              )}
            </div>
          </div>
        )}
        <div className={css.ChatContainerContent}>
          <div ref={listChatRef}>
            {messages[sourceChat].map((item, index) => (
              <ItemChat chat={item} key={index} />
            ))}
          </div>
          <div ref={messagesEndRef} />
        </div>
      </div>
      <Composer
        onChange={msg => setMessage(msg)}
        onSendClick={debounceSendMessage}
        onAttachment={onAttachment}
        value={message}
        isSentMessage={isSentMessage}
      />
      {S.webrtc.typeDraggingItem === DRAG_CHAT && <ItemChatPreview />}
    </div>
  )
})
