import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const getDownloadLink = async (riie: string) => {
  const { resources } = S.insightEngine

  S.insightEngine.resources = resources.map(r =>
    r.id === riie ? { ...r, isDownloading: true } : r,
  )

  const res = await reduxStore.context.gql.downloadVideoInInsightEngine({
    resourceInInsightEngineId: riie,
  })

  if (res.error) {
    S.insightEngine.resources = resources.map(r =>
      r.id === riie ? { ...r, isDownloading: false } : r,
    )
    ToastService.error({ content: res.error.message })
    return
  }
}
