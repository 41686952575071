import { observer } from 'mobx-react-lite'

import css from './CustomLayoutAlignmentIndicatorLines.module.scss'

import { Icon } from '../../../components/base/Icon'
import { S } from '../../store'
import type { IAlignmentIndicatorLine } from '../../store/shared/LayoutStore'
import { AlignmentLineType } from '../../store/shared/LayoutStore'

export const AlignmentIndicator = observer(() => {
  const { alignIndicatorLines } = S.layout
  let lines: IAlignmentIndicatorLine[] = []

  for (const index in alignIndicatorLines) {
    if (alignIndicatorLines[index]?.length) {
      lines = [...lines, ...(alignIndicatorLines[index] as any)]
    }
  }

  return (
    <>
      {lines.map((item, index) => (
        <Line line={item} key={index + ''} />
      ))}
    </>
  )
})
const Line: React.FC<{ line: IAlignmentIndicatorLine }> = ({ line }) => {
  const getPositionLine = (a: IAlignmentIndicatorLine) => {
    const customDiv = document.getElementById('customLayout')
    const pTop = customDiv?.getBoundingClientRect().top ?? 0
    const pLeft = customDiv?.getBoundingClientRect().left ?? 0
    const minLeft = a.positions[0].left
    const maxLeft = a.positions[a.positions.length - 1].left
    const minTop = a.positions[0].top
    const maxTop = a.positions[a.positions.length - 1].top
    let width = 2
    let height = 2
    let top = minTop
    let left = minLeft
    switch (a.type) {
      case AlignmentLineType.TOP:
      case AlignmentLineType.BOTTOM:
      case AlignmentLineType.CENTER_V:
      case AlignmentLineType.CENTER_AH:
        width = maxLeft - minLeft
        break
      case AlignmentLineType.LEFT:
      case AlignmentLineType.RIGHT:
      case AlignmentLineType.CENTER_H:
      case AlignmentLineType.CENTER_AV:
        height = maxTop - minTop
        break
      default:
        break
    }
    top = top + pTop
    left = left + pLeft
    return {
      width,
      height,
      top,
      left,
    }
  }

  const getPositionIcon = (
    ic: { top: number; left: number },
    type: AlignmentLineType,
  ) => {
    const customDiv = document.getElementById('customLayout')
    const pTop = customDiv?.getBoundingClientRect().top ?? 0
    const pLeft = customDiv?.getBoundingClientRect().left ?? 0
    let top = ic.top + pTop
    let left = ic.left + pLeft
    switch (type) {
      case AlignmentLineType.TOP:
      case AlignmentLineType.LEFT:
      case AlignmentLineType.RIGHT:
      case AlignmentLineType.CENTER_AV:
      case AlignmentLineType.CENTER_V:
        top = top - 6
        left = left - 4
        break
      case AlignmentLineType.CENTER_AH:
      case AlignmentLineType.CENTER_H:
        top = top - 7
        left = left - 4
        break
      case AlignmentLineType.BOTTOM:
        top = top - 6
        left = left - 6
        break
      default:
        break
    }
    return {
      top,
      left,
    }
  }
  const { height, left, top, width } = getPositionLine(line)
  return (
    <>
      <div
        style={{ width, height, top, left }}
        className={css.AlignIndicatorLine}
      />
      {line.positions.map((ic, i) => (
        <span
          key={i + ''}
          className={css.AlignIndicatorLineIcon}
          style={{
            top: getPositionIcon(ic, line.type).top,
            left: getPositionIcon(ic, line.type).left,
          }}
        >
          <Icon icon='Close' color='#FF8FF4' size={10} />
        </span>
      ))}
    </>
  )
}
