import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useState } from 'react'

import css from './BlurItemBottom.module.scss'

import { Icon } from '../../../components/base/Icon'
import { Popover } from '../../../components/base/Popover'
import { BeamText } from '../../../components/base/Text'
import { S } from '../../../context/store'
import type {
  IBlurItem,
  IMuteItem,
} from '../../../context/store/insight-engine/InsightEngineStore'
import { InsightEngineEditType } from '../../../context/store/insight-engine/InsightEngineStore'
import { Helper } from '../../../context/store/studio/helper'
import { audioExts } from '../../../context/store/studio/utils'
import { addBlurBlock } from '../actions/addBlurBlock'
import { createMute } from '../actions/createMute'
import { deleteBlurBlock } from '../actions/deleteBlurBlock'
import { deleteMuteTimeline } from '../actions/deleteMute'
import { selectBlurBlock } from '../actions/selectBlurBlock'
import { TimeLineItem } from './TimeLineItem'

type BaseBlurItemProps = {
  className?: string
  leftElement?: React.ReactElement
  rightElement?: React.ReactElement
  onClick?: () => void
  isSelected?: boolean
}
interface IBlurItemBottomProps {
  index: number
  block: IBlurItem | IMuteItem
}
export const BlurItemBottom: FC<IBlurItemBottomProps> = observer(
  ({ block, index }) => {
    const { selectedBlockId } = S.insightEngine
    const { id, type } = block
    const handleDelete = () => {
      if (type === InsightEngineEditType.Blur) {
        deleteBlurBlock(id)
      }
      if (type === InsightEngineEditType.Mute) {
        deleteMuteTimeline(id)
      }
    }

    const renderLeft = () => (
      <div className={css.BlurName}>
        {type === InsightEngineEditType.Blur ? 'Blur' : 'Mute'} {index + 1}
        <div onClick={handleDelete}>
          <Icon className={css.IconDelete} icon={'Delete'} size={15} />
        </div>
      </div>
    )
    const renderRight = () => <TimeLineItem block={block} />
    const itemClickHandler = () => {
      selectBlurBlock(id)
    }

    return (
      <BaseBlurItem
        className={clsx({
          [css.BaseBlurItemSelected]: selectedBlockId === id,
        })}
        leftElement={renderLeft()}
        rightElement={renderRight()}
        onClick={itemClickHandler}
      />
    )
  },
)

export const AddBlurItem = observer(() => {
  const { mediaPlayer, resourceSelected } = S.insightEngine
  if (!resourceSelected) {
    return
  }
  const { id, resource } = resourceSelected

  const [visible, setVisible] = useState(false)
  const closePopover = () => {
    setVisible(false)
  }
  const onVisibleChange = (state: boolean) => {
    setVisible(state)
  }
  const isAudio = audioExts[resource?.name.split('.').pop() || '']
  const renderPopoverSelectAction = () => (
    <div className={css.ActionPopOverWrapper}>
      {!isAudio && (
        <div className={css.ActionPopOverItem} onClick={addBlurBlockHandler}>
          <Icon icon={'Blur'} size={16} />
          <BeamText>Blur</BeamText>
        </div>
      )}
      <div onClick={addMuteHandler} className={css.ActionPopOverItem}>
        <Icon icon={'icon_volumn_silent'} size={14} />
        <BeamText>Mute</BeamText>
      </div>
    </div>
  )

  const addBlurBlockHandler = () => {
    addBlurBlock(
      {
        id: Helper.generateGuid(),
        height: 150,
        width: 150,
        top: mediaPlayer.videoRef ? mediaPlayer.videoRef.videoHeight / 2 : 0,
        left: mediaPlayer.videoRef ? mediaPlayer.videoRef.videoWidth / 2 : 0,
        zIndex: 1,
        radius: 3,
        startTime: 0,
        endTime: mediaPlayer.duration,
        type: InsightEngineEditType.Blur,
      },
      id ?? '',
    )
    closePopover()
  }

  const addMuteHandler = () => {
    createMute({
      resourceInInsightEngineId: id ?? '',
      startTime: 0,
      endTime: (mediaPlayer.duration ?? 0) / 2,
    })
    closePopover()
  }

  const _renderLeft = () => (
    <Popover
      visible={visible}
      onVisibleChange={onVisibleChange}
      _overlay={renderPopoverSelectAction()}
      trigger={'click'}
    >
      <span className={css.AddButton} />
    </Popover>
  )
  return (
    <BaseBlurItem className={css.addBlurElement} leftElement={_renderLeft()} />
  )
})

const BaseBlurItem: FC<BaseBlurItemProps> = ({
  className,
  leftElement,
  rightElement,
  onClick,
}) => (
  <div className={clsx(css.BlurItem, className)} onClick={onClick}>
    <div className={css.LeftElement}>{leftElement}</div>
    <div className={css.RightElement}>{rightElement}</div>
  </div>
)
