import { observer } from 'mobx-react-lite'

import css from './Note.module.scss'

import { NoteEditor } from './NoteEditor'

export const Note = observer(() => (
  <div className={css.NoteWrapper}>
    <div className={css.NoteContainer}>
      <div className={css.NoteHeader}>
        <div className={css.NoteHeaderTitle}>Notes</div>
      </div>
      <div className={css.NoteContent}>
        <NoteEditor />
      </div>
    </div>
  </div>
))
