import type { Device } from 'mediasoup-client/lib/types'

import type { ProtooClientPeer } from '../type'
import type { Mediasoup } from '../type-ui'
import { _createWebRtcTransport } from './_createWebRtcTransport'

export const _onProtooOpen = async (
  protoo: ProtooClientPeer,
  device: Device,
  only?: 'OnlySend' | 'OnlyRecv',
) => {
  const mediasoup: Mediasoup = {
    protoo,
    device,
    send: {
      producers: {},
    },
    recv: {
      consumers: {},
    },
  }

  if (!only || only === 'OnlySend') {
    mediasoup.send.transport = await _createWebRtcTransport(
      protoo,
      device,
      'Send',
    )
  }

  if (!only || only === 'OnlyRecv') {
    mediasoup.recv.transport = await _createWebRtcTransport(
      protoo,
      device,
      'Recv',
    )
  }

  return mediasoup
}
