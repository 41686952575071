import type { RadioChangeEvent } from 'antd'
import { useState } from 'react'

import css from './ModalReplaceSpeaker.module.scss'

import { Button } from '../../../components/base/Button'
import { BeamRadio } from '../../../components/base/Radio'
import { ERadioSize } from '../../../components/base/Radio/type'
import { BeamRadiGroup } from '../../../components/base/RadioGroup'
import { BeamText } from '../../../components/base/Text'
import { BeamInput } from '../../../components/widget/Input'
import { replaceTranscriptUser } from '../actions/replaceTranscriptUser'
import type { ModalReplaceSpeakerProps } from '../type'
import { EReplaceType } from '../type'

export const ModalReplaceSpeaker: React.FC<ModalReplaceSpeakerProps> = ({
  name,
  id,
  onCancel,
}) => {
  const [inputValue, setInputValue] = useState(name)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<EReplaceType>(EReplaceType.ReplaceOne)
  const radioChangeHandler = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  const handleSave = async () => {
    setLoading(true)
    await replaceTranscriptUser(
      id,
      inputValue ?? '',
      name,
      value === EReplaceType.ReplaceAll,
    )
    setLoading(false)
    onCancel()
  }

  return (
    <div className={css.Container}>
      <div className={css.WrapperReplace}>
        <BeamText className={css.Title} size='large'>
          Replace user
        </BeamText>
        <BeamRadiGroup
          onChange={radioChangeHandler}
          className={css.RadioGroup}
          value={value}
        >
          <BeamRadio
            containerProps={{ size: ERadioSize.S }}
            value={EReplaceType.ReplaceOne}
          >
            <BeamText>Replace this one only</BeamText>
          </BeamRadio>
          <BeamRadio
            containerProps={{ size: ERadioSize.S }}
            value={EReplaceType.ReplaceAll}
          >
            <BeamText>Replace all</BeamText>
          </BeamRadio>
        </BeamRadiGroup>
      </div>
      <div className={css.divider} />
      <BeamInput
        value={inputValue}
        onChange={ev => setInputValue(ev.target.value)}
        placeholder={'Type speaker name'}
      />
      <div className={css.Buttons}>
        <Button onClick={handleSave} loading={loading} disabled={loading}>
          Apply
        </Button>
        <Button onClick={onCancel} disabled={loading} containerType='grey'>
          Cancel
        </Button>
      </div>
    </div>
  )
}
