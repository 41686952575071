import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import css from './LiveStreamEditLayoutButton.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { setEnableEditLayout } from '../../../context/actions/layout/setEnableEditLayout'
import { setSlotsEdit } from '../../../context/actions/layout/setSlotsToEdit'
import { ResizeOutsideApi } from '../../../context/shared/components/CustomLayoutReszieOutside'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'

export const EditLayoutButton = observer(() => {
  const { enableEditLayout, slotsEdit, titlesOnEdit, subtitleSlot } = S.layout

  const { warmnessColorHover, warmnessColorPressed } = S.local
  const {
    selectedLayoutByIndex,
    selectedListLayouts,
    updateEmitAndSaveSettings,
    layoutMedias,
    selectedIndexLayout,
    removeMediasOfLayoutSlot,
    removePeersOnSlot,
    computedSelectedMediaIds,
    titles,
    titlesOnAir,
    recentFonts,
  } = S.webrtc
  const titlesOrigin = useRef(titles)
  useEffect(() => {
    if (enableEditLayout) {
      titlesOrigin.current = titles
    }
  }, [enableEditLayout])
  const onEnableEditLayout = () => {
    setEnableEditLayout(true)
  }
  const closeEditLayout = () => {
    const dataLayoutMedia = layoutMedias[selectedIndexLayout]
    const slots = selectedLayoutByIndex.slots
    S.layout.subtitleSlot = { ...S.webrtc.subtitleSlot }
    if (dataLayoutMedia) {
      const listMediaNotSave: string[] = []
      for (const p in dataLayoutMedia) {
        const mediaExit = slots.find((s: any) => s.id === Number(p))
        if (!mediaExit) {
          listMediaNotSave.push(dataLayoutMedia[p].id)
        }
      }
      removeMediasOfLayoutSlot(listMediaNotSave, selectedIndexLayout)
    }
    if (computedSelectedMediaIds) {
      const listPeerNotSave: number[] = []
      for (const p in computedSelectedMediaIds) {
        const mediaExit = slots.find((s: any) => s.id === Number(p))
        if (!mediaExit) {
          listPeerNotSave.push(Number(p))
        }
      }
      removePeersOnSlot(listPeerNotSave)
    }
    const layoutsEdit: { [key: number]: ILayoutToEdit[] } = {}
    selectedListLayouts.forEach((i, index) => {
      layoutsEdit[i.defaultId] = (i.slots as ILayoutItem[]).map(s => ({
        ...s,
        layoutIndex: index,
        parentId: i.defaultId,
      }))
    })
    setSlotsEdit(layoutsEdit)
    setEnableEditLayout(false)
    ResizeOutsideApi.removeItemByIds()
  }
  const saveLayout = () => {
    const layoutsToEdit = selectedListLayouts.map(l => {
      const ls = slotsEdit[l.defaultId]
      if (ls) {
        return {
          ...l,
          slots: ls.map(s => ({
            id: s.id,
            height: s.height,
            width: s.width,
            left: s.left,
            top: s.top,
            radius: s.radius,
            zIndex: s.zIndex,
            cropMode: s.cropMode,
            cropData: s.cropData,
            titleId: s.titleId,
          })),
        }
      } else {
        return l
      }
    })
    S.webrtc.subtitleSlot = { ...subtitleSlot }
    updateEmitAndSaveSettings(
      {
        selectedListLayouts: layoutsToEdit,
        titles: titlesOnEdit,
        subtitleSlot,
        recentFonts,
        titlesOnAir,
      },
      'livestreamLayout',
    )
    setEnableEditLayout(false)
  }
  return (
    <div className={css.EditLayoutButton}>
      {!enableEditLayout && (
        <span
          style={{
            // @ts-ignore
            '--background21': warmnessColorHover,
            '--background22': warmnessColorPressed,
          }}
          onClick={onEnableEditLayout}
          className={css.EditLayoutIcon}
        >
          <Icon icon='icon_pencil' size={12} />
        </span>
      )}
      {enableEditLayout && (
        <>
          <span onClick={saveLayout} className={css.SaveLayout}>
            <Icon
              icon='icon_check'
              color={enableEditLayout ? '#fff' : undefined}
              size={13}
            />
          </span>{' '}
          <WarmnessGrayButton
            onClick={closeEditLayout}
            className={css.CloseEditLayout}
          >
            <Icon icon='icon_close_popup' size={10} />
          </WarmnessGrayButton>
        </>
      )}
    </div>
  )
})
