import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import css from './CustomLayoutResizeOutsizeSingleActions.module.scss'

import { Popover } from '#HACK_FOR_RN_ONLY/src/components/base/Popover'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { Map } from '../../../components/base/Map'
import { changeCropModeDropdownShow } from '../../actions/layout/changeCropModeDropdownShow'
import { updateSlotToEdit } from '../../actions/layout/updateSlotToEdit'
import { S } from '../../store'
import type { ILayoutToEdit } from '../../store/shared/LayoutStore'
import { ECropMode } from '../../store/shared/LayoutStore'
import type {
  IActionBaseProps,
  IActionOutsideBlur,
  IActionOutsize,
} from '../utils/CustomLayoutType'

interface IActionTopProps extends IActionBaseProps {
  actions?: IActionOutsize[]
  customElement?: React.ReactElement
  align?: 'left' | 'center'
}
interface IActionRightProps extends IActionBaseProps {
  actions?: IActionOutsize[]
}
interface IActionTopBlurProps extends IActionBaseProps {
  actions?: IActionOutsideBlur[]
}
export const ResizeOutSizeSingleActionTop: FC<IActionTopProps> = props => {
  const { width, x, y, actions = [], align, customElement } = props
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { width: w } = useResizeObserver({ ref: containerRef })

  const renderDefaultElements = () => (
    <Map<IActionOutsize>
      list={actions}
      renderItem={(a, index) => (
        <div
          id='SlotCustom'
          key={index + ''}
          onClick={e => {
            e.stopPropagation()
            a.onAction()
          }}
          className={css.SlotTopButton}
        >
          {a.element}
        </div>
      )}
    />
  )

  const xCenter = x + width / 2 - (w ?? 346) / 2
  const xValue = align === 'center' ? xCenter : xCenter <= x ? x : xCenter
  return (
    <div
      ref={containerRef}
      style={{
        top: y,
        left: xValue,
        marginTop: customElement ? -40 : -32,
      }}
      id='SlotCustom'
      className={css.SlotActionsOutSide}
    >
      {customElement}
      {renderDefaultElements()}
    </div>
  )
}

export const CropMode: FC<IActionTopProps> = observer(props => {
  const { width, x, y, actions } = props
  const { selectedLayoutByIndex } = S.webrtc
  const { slotsEdit } = S.layout
  const maxOfW = (actions?.length ?? 1) * 32
  const [cropPopVisible, setCropPopVisible] = useState(false)

  const currentSlot = useMemo(
    () =>
      (slotsEdit[selectedLayoutByIndex.defaultId]?.find(
        s => s.enableEdit,
      ) as ILayoutToEdit) ?? {},
    [selectedLayoutByIndex.defaultId, slotsEdit],
  )

  const switchCropModeHandler = (v: ECropMode) => {
    updateSlotToEdit(
      currentSlot.id,
      {
        ...currentSlot,
        prevCropMode: currentSlot.cropMode,
        cropMode: v,
        isShowCropMode: v === ECropMode.Crop,
      },
      selectedLayoutByIndex.defaultId,
    )
    setCropPopVisible(false)
  }
  const menuContent = () => (
    <div id='SlotCustom' className={css.MenuList}>
      {Object.keys(ECropMode).map(v => (
        <div
          key={v}
          id={v}
          onClick={() => switchCropModeHandler(v as ECropMode)}
          className={css.MenuItem}
        >
          {v}
          {(currentSlot.cropMode || 'Fill') === v && (
            <Icon
              icon='icon_check'
              size={16}
              className={css.MenuItemIcon}
              color='#085AE1'
            />
          )}
        </div>
      ))}
    </div>
  )
  useEffect(() => {
    changeCropModeDropdownShow(cropPopVisible)
    return () => changeCropModeDropdownShow(false)
  }, [cropPopVisible])
  return (
    <div
      style={{
        top: y,
        width: Math.max(width, maxOfW + 32),
        left: x,
        marginTop: -56,
      }}
      className={css.ViewModeButtonWrapper}
      id='SlotCustom'
    >
      <Popover
        showArrow={false}
        trigger='click'
        placement='top'
        content={menuContent}
        visible={cropPopVisible}
        onVisibleChange={visible => {
          setCropPopVisible(visible)
        }}
      >
        <Button
          size='small'
          className={css.ViewModeButton}
          containerType='grey'
        >
          {currentSlot?.cropMode ?? 'Fill'}
          <Icon icon='Dropdown' size={16} className={css.DropdownIcon} />
        </Button>
      </Popover>
    </div>
  )
})

export const ResizeOutSizeSingleActionRight: FC<IActionRightProps> = props => {
  const { height, x, y, actions } = props
  const maxOfH = (actions?.length ?? 1) * 32
  return (
    <div
      style={{
        top: y,
        height: Math.max(height, maxOfH),
        left: x,
        marginLeft: -32,
      }}
      id='SlotCustom'
      className={css.SlotActionsOutSideLeft}
    >
      {actions?.map((a, index) => (
        <div
          id='SlotCustom'
          key={index + ''}
          onClick={a.onAction}
          className={css.SlotTopButton}
        >
          {a.element}
        </div>
      ))}
    </div>
  )
}

export const ResizeBlurActionTop: FC<IActionTopBlurProps> = props => {
  const { actions, x, y, width } = props
  const maxOfW = 162

  return (
    <div
      style={{
        top: y,
        width: Math.max(width, maxOfW),
        left: x,
        marginTop: -32,
      }}
      id='SlotCustom'
      className={css.SlotActionsBlur}
    >
      {actions?.map((a: IActionOutsideBlur, index) => (
        <div
          id='SlotCustom'
          key={index + ''}
          onClick={e => {
            e.stopPropagation()
            a.onAction()
          }}
          className={clsx(css.SlotTopButton, {
            [css.SlotTopInput]: a.isInput,
          })}
        >
          {a.element}
        </div>
      ))}
    </div>
  )
}
