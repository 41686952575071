import { Modal } from 'antd'

import ImgKickedOut from '../../../assets/images/icon_kicked_out.svg'
import css from './ModalKickedOut.module.scss'

import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'

import { CONSTANTS } from '../../../styles/Constants'

export const ModalKickedOut = ({ onClose }: { onClose: () => void }) => (
  <Modal
    className={css.WrapperModal}
    width={290}
    visible
    title={''}
    onCancel={onClose}
    closeIcon={
      <Icon icon='icon_close_popup' size={12} className={css.ModalIconClose} />
    }
    footer={null}
    centered
    maskStyle={{ background: CONSTANTS.basicColor.maskBackgroundModal }}
  >
    <div className={css.ModalContent}>
      <img className={css.ModalImg} src={ImgKickedOut} />
      <p className={css.ModalText}>
        The host has removed you from the session. Thank you for taking part.
      </p>
      <button className={css.ModalButton} onClick={onClose}>
        Close
      </button>
    </div>
  </Modal>
)
