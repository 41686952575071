import { chain, map } from 'lodash'

import type { PlanName } from '../../context/gql/codegen'

interface FeaturesGroup {
  name: string
  items: {
    key: string
    name: string
    description?: string
  }[]
}

interface Plans {
  id: PlanName | string
  name: string
  description: string
  oldPrice?: number | null
  price?: {
    usd: number | null | string
    gbp: number | null | string
  } | null
  yearlyPrice?: {
    usd: number | null | string
    gbp: number | null | string
  } | null
  isFreePlan?: boolean | null
  features?: {
    name: string
    value: any
  }[]
}

export const featuresGroups: FeaturesGroup[] = [
  {
    name: 'Studio',
    items: [
      {
        key: 'minutes',
        name: 'Minutes',
        description: '',
      },
      {
        key: 'storage',
        name: 'Storage',
        description: '',
      },
      {
        key: 'participant',
        name: 'Participant',
        description: '',
      },
      {
        key: 'chat',
        name: 'Chat',
        description: '',
      },
      {
        key: 'screen_sharing',
        name: 'Screen sharing',
        description: '',
      },
      {
        key: 'drag_and_drop_mul_camera_mixing',
        name: 'Drag & Drop multi-camera mixing',
        description: '',
      },
      {
        key: 'on_screen_graphics',
        name: 'On-screen graphics, titles & media',
        description: '',
      },
      {
        key: 'no_watermark',
        name: 'No watermark',
        description: '',
      },
      {
        key: 'save_studio_settings',
        name: 'Save Studio Settings',
        description: '',
      },
      {
        key: 'producer_support',
        name: 'Producer Support',
        description: '',
      },
      {
        key: 'technician_support',
        name: 'Technician Support',
        description: '',
      },
      {
        key: 'cloud_storage',
        name: 'Cloud Storage (Media uploads, Records)',
        description: '',
      },
      {
        key: 'individual_guest_camera_recording',
        name: 'Individual guest-camera recording',
        description: '',
      },
    ],
  },
  {
    name: 'Destinations',
    items: [
      {
        key: 'destinations',
        name: 'Destinations',
        description: '',
      },
      {
        key: 'destination_input',
        name: 'Destination input to Zoom, Teams, Meet, Webex',
        description: '',
      },
      {
        key: 'RTMP_source',
        name: 'RTMP-source',
        description: '',
      },
      {
        key: 'SRT_source',
        name: 'SRT-source',
        description: '',
      },
    ],
  },
  {
    name: 'Video Quality',
    items: [
      {
        key: 'full_hd',
        name: 'Full HD, 1080p, 30fps',
        description: '',
      },
      {
        key: '4k_video',
        name: '4k Video',
        description: '',
      },
    ],
  },
  {
    name: 'Analytics & Security',
    items: [
      {
        key: 'analytics_dashboard',
        name: 'Analytics Dashboard',
        description: '',
      },
      {
        key: 'enhance_security_and_privacy',
        name: 'Enhance Security & Privacy',
        description: '',
      },
    ],
  },
]

export const plans: Plans[] = [
  {
    id: 'Freemium',
    name: 'Freemium',
    description: 'Simple Livestream',
    price: { gbp: 'Free', usd: 'Free' },
    isFreePlan: false,
    features: [
      {
        name: 'minutes',
        value: '100 mins/mo',
      },
      {
        name: 'storage',
        value: '5GB',
      },
      {
        name: 'participant',
        value: '7',
      },
      {
        name: 'chat',
        value: 'yes',
      },
      {
        name: 'screen_sharing',
        value: 'yes',
      },
      {
        name: 'drag_and_drop_mul_camera_mixing',
        value: 'yes',
      },
      {
        name: 'on_screen_graphics',
        value: 'yes',
      },
      {
        name: 'no_watermark',
        value: 'no',
      },
      {
        name: 'save_studio_settings',
        value: 'no',
      },
      {
        name: 'producer_support',
        value: 'no',
      },
      {
        name: 'technician_support',
        value: 'no',
      },
      {
        name: 'cloud_storage',
        value: 'no',
      },
      {
        name: 'individual_guest_camera_recording',
        value: 'no',
      },
      {
        name: 'destinations',
        value: '1',
      },
      {
        name: 'destination_input',
        value: 'no',
      },
      {
        name: 'RTMP_source',
        value: 'no',
      },
      {
        name: 'SRT_source',
        value: 'no',
      },
      {
        name: 'full_hd',
        value: 'no',
      },
      {
        name: '4k_video',
        value: 'no',
      },
      {
        name: 'analytics_dashboard',
        value: 'yes',
      },
      {
        name: 'enhance_security_and_privacy',
        value: 'no',
      },
    ],
  },
  {
    id: 'Professional',
    name: 'Professional',
    description: 'Unlimited Livestream & Full HD Video',
    price: null,
    isFreePlan: false,
    features: [
      {
        name: 'minutes',
        value: '1,000 mins/mo',
      },
      {
        name: 'storage',
        value: '50GB',
      },
      {
        name: 'participant',
        value: '20',
      },
      {
        name: 'chat',
        value: 'yes',
      },
      {
        name: 'screen_sharing',
        value: 'yes',
      },
      {
        name: 'drag_and_drop_mul_camera_mixing',
        value: 'yes',
      },
      {
        name: 'on_screen_graphics',
        value: 'yes',
      },
      {
        name: 'no_watermark',
        value: 'yes',
      },
      {
        name: 'save_studio_settings',
        value: 'yes',
      },
      {
        name: 'producer_support',
        value: 'no',
      },
      {
        name: 'technician_support',
        value: 'yes',
      },
      {
        name: 'cloud_storage',
        value: '15GB',
      },
      {
        name: 'individual_guest_camera_recording',
        value: 'no',
      },
      {
        name: 'destinations',
        value: '2',
      },
      {
        name: 'destination_input',
        value: 'no',
      },
      {
        name: 'RTMP_source',
        value: 'no',
      },
      {
        name: 'SRT_source',
        value: 'no',
      },
      {
        name: 'full_hd',
        value: 'yes',
      },
      {
        name: '4k_video',
        value: 'no',
      },
      {
        name: 'analytics_dashboard',
        value: 'yes',
      },
      {
        name: 'enhance_security_and_privacy',
        value: 'no',
      },
    ],
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'For Large Business',
    price: null,
    isFreePlan: false,
    features: [
      {
        name: 'minutes',
        value: 'Unlimited',
      },
      {
        name: 'storage',
        value: 'Unlimited',
      },
      {
        name: 'participant',
        value: 'Custom',
      },
      {
        name: 'chat',
        value: 'yes',
      },
      {
        name: 'screen_sharing',
        value: 'yes',
      },
      {
        name: 'drag_and_drop_mul_camera_mixing',
        value: 'yes',
      },
      {
        name: 'on_screen_graphics',
        value: 'yes',
      },
      {
        name: 'no_watermark',
        value: 'yes',
      },
      {
        name: 'save_studio_settings',
        value: 'yes',
      },
      {
        name: 'producer_support',
        value: 'no',
      },
      {
        name: 'technician_support',
        value: 'yes',
      },
      {
        name: 'cloud_storage',
        value: 'Custom',
      },
      {
        name: 'individual_guest_camera_recording',
        value: 'yes',
      },
      {
        name: 'destinations',
        value: 'Custom',
      },
      {
        name: 'destination_input',
        value: 'yes',
      },
      {
        name: 'RTMP_source',
        value: 'yes',
      },
      {
        name: 'SRT_source',
        value: 'yes',
      },
      {
        name: 'full_hd',
        value: 'yes',
      },
      {
        name: '4k_video',
        value: 'yes',
      },
      {
        name: 'analytics_dashboard',
        value: 'yes',
      },
      {
        name: 'enhance_security_and_privacy',
        value: 'yes',
      },
    ],
  },
]

export const planFeatureByKey = map(plans, (plan: any) => {
  plan.features = chain(plan.features).keyBy('name').mapValues('value').value()
  return plan
})
