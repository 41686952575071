import { isNumber } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useVideo } from 'react-use'

import css from './VideoPlayerSlot.module.scss'

import { S } from '../../../context/store'
import type { TKeyString } from '../../../context/store/studio/WebrtcStore'

export const VideoPlayerSlot = observer(
  ({
    video: { value: url, id: mediaId, name = '', repeated = '1' },
    videoStyle,
    isShow,
  }: // startTime = 0,
  // onToggleRepeat,
  {
    video: TKeyString
    onToggleRepeat?: (status: boolean) => void
    videoStyle?: CSSProperties
    isShow?: boolean
  }) => {
    const {
      stateVideoPreviewId,
      selectedIndexLayout,
      overlayUrl,
      stateVideoPreview,
      addStateVideoPreview,
      isViewmodeMixer,
      updateDataOfStore,
      updateStateVideoPreview,
      selectedRightTabBarKey,
    } = S.webrtc
    const [isRepeat, setRepeat] = useState(repeated === '1')
    const ref = useRef<HTMLDivElement | null>(null)
    const [video, state, control] = useVideo(
      <video
        className={css.VideoPlayerTag}
        src={url}
        onLoadedMetadata={() => {
          control.play()?.catch(e => console.log(e))
        }}
        draggable={false}
        style={{ ...videoStyle }}
      />,
    )

    const { time, duration } = state
    const stateVideoPreviewDetail = stateVideoPreview.find(
      item => item.mediaId === mediaId,
    )
    const handleToggleVideo = () => {
      if (selectedRightTabBarKey !== '4') {
        return
      }
      if (isViewmodeMixer) {
        return
      }
      if (mediaId === stateVideoPreviewId) {
        updateDataOfStore({
          stateVideoPreviewId: '',
          videoPreview: null,
        })
      } else {
        updateDataOfStore({
          stateVideoPreviewId: mediaId,
          videoPreview: {
            mediaId,
            url,
          },
        })

        // WHY?
        // control.play()
        updateStateVideoPreview(mediaId, {
          url,
          mediaId,
          volume: state.volume,
          paused: true,
        })
      }
    }
    useEffect(() => {
      if (!isShow) {
        control.mute()
      } else {
        if (!stateVideoPreviewDetail?.paused) {
          control.play()
        }
        control.seek(0)
        control.unmute()
      }
    }, [isShow])
    useEffect(() => {
      if (time === duration && isRepeat) {
        control.play()
        control.seek(0)
      }
    }, [time, isRepeat, stateVideoPreviewId])

    useEffect(() => {
      const mixerVolume = stateVideoPreviewDetail?.volume
      if (isNumber(mixerVolume)) {
        control.volume(mixerVolume)
      }
    }, [stateVideoPreviewDetail?.volume])

    useEffect(() => {
      if (stateVideoPreviewDetail?.paused) {
        control.pause()
      } else {
        control.play()
      }
    }, [stateVideoPreviewDetail?.paused, mediaId, stateVideoPreviewId])

    useEffect(() => {
      const statusRepeat = stateVideoPreviewDetail?.repeat || false
      setRepeat(statusRepeat)
    }, [stateVideoPreviewDetail?.repeat])
    useEffect(() => {
      const detailVideoOnStore = stateVideoPreview.find(
        item => item.mediaId === mediaId,
      )

      if (!detailVideoOnStore) {
        addStateVideoPreview({
          url,
          mediaId,
          name,
          repeat: isRepeat,
          paused: false,
        })
      }
    }, [selectedIndexLayout])
    useEffect(() => {
      if (overlayUrl !== '' && state.playing) {
        control.pause()
      }
    }, [overlayUrl])
    useEffect(() => {
      if (isRepeat !== Boolean(repeated === '1') && S.webrtc.isViewmodeMixer) {
        setRepeat(!isRepeat)
      }
    }, [repeated])
    return (
      <div className={css.VideoPlayerWrapper} onClick={handleToggleVideo}>
        <div ref={ref} className={css.VideoPlayerContent}>
          {video}
          <div className={css.VideoPlayerOverlay} />
        </div>
      </div>
    )
  },
)
