import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../redux'
import { S } from '../../store'

export const handleResolveRequestRole = async (
  isAccepted: boolean,
  userId: string,
  role?: string,
) => {
  const $ = S.webrtc

  if (isAccepted && role) {
    const res = await reduxStore.context.gql.switchRole({
      sessionId: $.sessionId,
      role,
    })

    if (res.error) {
      ToastService.error({ content: 'Switch role failed.' })
      ModalService.hide()
      return
    }
    $.answerSwitchRole(isAccepted, userId)
    window.location.reload()
  } else {
    $.answerSwitchRole(isAccepted, userId)
  }
}
