import type { PropsWithChildren } from 'react'
import type React from 'react'
import { Children } from 'react'

export const Show = (props: PropsWithChildren) => {
  let when = null
  let otherwise = null
  Children.forEach(props.children, (children: any) => {
    if (children.props.isTrue === undefined) {
      otherwise = children
    } else if (!when && children.props.isTrue === true) {
      when = children
    }
  })
  return when || otherwise
}
Show.When = ({
  isTrue,
  children,
}: {
  isTrue: boolean
  children: React.ReactElement | null
}) => isTrue && children
Show.Else = ({
  render,
  children,
}: {
  render?: any
  children?: React.ReactElement | null
}) => render || children
