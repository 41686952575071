import { LoadingOutlined } from '@ant-design/icons'
import { getOrientation } from 'get-orientation/browser'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import Cropper from 'react-cropper'

import css from './imageEditor.module.scss'

import { SUPPORT_FILE_TYPE_AVATAR } from '../../config'
import { S } from '../../context/store'
import { readFile } from '../../context/store/studio/utils'
import { Button } from '../base/Button'
import { BeamSlider } from '../base/Slider'
import { AppSpin } from '../base/Spin'
import { ModalContent, ModalService } from '../Modal/Modal'
import { getRotatedImage, pickFile } from './canvasUtils'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
} as any
export interface ICropData {
  left?: number
  top?: number
  width?: number
  height?: number
}
export interface ICropState {
  cropData?: ICropData
  cropBoxData?: ICropData
  zoom?: number
  rotation?: number
}
export const ImageCropper: React.FC<{
  originalUrl: string
  onSaveImage: (
    imageFile: any,
    state: ICropState,
    originalUrl?: string,
  ) => Promise<boolean>
  originalState?: {
    cropData: ICropData
    cropBoxData: ICropData
    zoom?: number
    rotation?: number
  }
  showChangeFile: boolean
}> = observer(props => {
  const { isLightTheme } = S.local
  const {
    originalUrl,
    onSaveImage,
    originalState,
    showChangeFile = true,
  } = props
  const [showError, setShowError] = useState(false)
  const [imgUrl, setImgUrl] = useState(originalUrl)
  const [toggleReady, setToggleReady] = useState(false)
  const [rotation, setRotation] = useState(originalState?.rotation ?? 0.1)
  const [cropper, setCropper] = useState<Cropper>()
  const [zoom, setZoom] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loadingImg, setLoadingImg] = useState(true)

  const onFileChange = async (file: any) => {
    let imageDataUrl: any = null
    const fileInfo = await readFile(file)
    if (fileInfo.data) {
      imageDataUrl = fileInfo.data
      const orientation = await getOrientation(file)
      const rotation1 = ORIENTATION_TO_ANGLE[orientation as any]
      if (rotation1) {
        imageDataUrl = await getRotatedImage(fileInfo.data, rotation1)
      }
    }
    if (fileInfo.canUse) {
      setShowError(false)
      setImgUrl(imageDataUrl)
    } else {
      setShowError(true)
    }
  }

  const handleRePickImage = async () => {
    const files: any = await pickFile(SUPPORT_FILE_TYPE_AVATAR.join(','))
    onFileChange(files[0])
  }

  useEffect(() => {
    if (!cropper || !toggleReady) {
      return
    }
    if (originalState?.cropBoxData) {
      cropper.setCropBoxData(originalState?.cropBoxData)
    }
    if (originalState?.zoom) {
      setZoom(originalState?.zoom)
      cropper.zoomTo(originalState?.zoom)
    }
    if (originalState?.rotation) {
      setRotation(originalState?.rotation)
      cropper.rotateTo(originalState?.rotation)
    }
    const state = originalState?.cropData
    if (state) {
      cropper.setCanvasData(state)
    }
  }, [cropper, toggleReady])

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    if (typeof cropper === 'undefined') {
      return
    }
    setLoading(true)
    cropper.getCroppedCanvas().toBlob(async (blobFile: Blob | null) => {
      if (!blobFile) {
        setLoading(false)
        return
      }
      await onSaveImage(
        blobFile,
        {
          cropBoxData: cropper.getCropBoxData(),
          cropData: cropper.getCroppedCanvas(),
          rotation,
          zoom,
        },
        imgUrl,
      )
      setLoading(false)
    })
  }
  const handelChangeRotation = (r: number) => {
    if (cropper) {
      cropper.rotateTo(r)
    }

    setRotation(r)
  }
  return (
    <ModalContent
      isLightTheme={isLightTheme}
      containerClassName={css.ImageEditorWrapper}
    >
      <div className={css.Header}>
        <span className={css.HeaderTitle}>Edit photo</span>
      </div>
      <div className={css.Content}>
        {showError && (
          <span className={css.AvatarError}>
            Please use jpg, jpeg, png with maximum 5MB
          </span>
        )}
        {(loadingImg || loading) && (
          <div className={css.LoadImage}>
            <AppSpin indicator={<LoadingOutlined spin className='spinner' />} />
          </div>
        )}
        <Cropper
          onInitialized={instance => {
            setCropper(instance)
          }}
          className={css.CropCom}
          src={imgUrl}
          background={false}
          toggleDragModeOnDblclick={false}
          zoomTo={toggleReady ? zoom : undefined}
          ready={() => {
            setLoadingImg(false)
            setToggleReady(true)
          }}
          guides={true}
          checkOrientation={false}
          center
          zoom={e => setZoom(e.detail.ratio)}
        />
      </div>
      <div className={css.Actions}>
        <div className={css.ActionsItem}>
          <div className={css.ActionItemHeader}>
            <span className={css.ActionsItemText}>Zoom</span>
            <span>{Math.floor(zoom * 100) / 100}</span>
          </div>

          <BeamSlider
            step={0.01}
            min={0}
            max={3}
            value={zoom}
            onChange={setZoom}
          />
        </div>
        <div className={css.ActionsItem}>
          <div className={css.ActionItemHeader}>
            <span className={css.ActionsItemText}>Rotation</span>
            <span>{rotation}</span>
          </div>
          <BeamSlider
            min={-45}
            max={45}
            value={rotation}
            onChange={handelChangeRotation}
          />
        </div>
      </div>
      <div className={css.Footer}>
        <div className={css.ChangeButton}>
          {showChangeFile && (
            <Button onClick={handleRePickImage} containerType='grey'>
              Change photo
            </Button>
          )}
        </div>

        <Button
          onClick={() => {
            ModalService.hide()
          }}
          containerType='grey'
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={loading}>
          Save photo
        </Button>
      </div>
    </ModalContent>
  )
})
