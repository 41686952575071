import { SyncOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useAudio } from 'react-use'
import { saveSync } from 'save-file'

import css from './AudioPlayer.module.scss'

import { BeamSlider } from '#HACK_FOR_RN_ONLY/src/components/base/Slider'
import { getVolumePrev, volume0 } from '#rn-shared/mediasoup'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { S } from '../../../context/store'
import {
  covertDurationToMinutes,
  formatFileName,
  getNameOfUrl,
} from '../../../context/store/studio/utils'
import type { TStateMediaPreview } from '../../../context/store/studio/WebrtcStore'
import { CONSTANTS } from '../../../styles/Constants'

type TAudioPlayer = {
  url: string
  autoPlay?: boolean
  name?: string
  defaultVolume?: number
  onChangeVolume?: (volume: number) => void
  onDelete?: (e: React.MouseEvent<HTMLDivElement>) => void
  onAir?: boolean
  onTurnOffAir?: () => void
}
export const AudioPlayer = observer(
  ({
    url,
    autoPlay = false,
    defaultVolume = 1,
    onChangeVolume,
    onDelete,
    onAir = false,
    name = '',
    onTurnOffAir,
  }: TAudioPlayer) => {
    const [isDraggingVolume, setDraggingVolume] = useState(false)
    const [audio, state, controls] = useAudio(<audio src={url} />)
    const { stateAudioPlaying, updateStateAudioPlaying } = S.webrtc
    const { duration, time, volume, repeat, paused, prevVolume } =
      stateAudioPlaying
    const [isRepeat, setRepeat] = useState(false)
    const [preVolume, setPreVolume] = useState(0)

    const durationValue =
      useMemo(
        () => (onAir ? duration : state.duration),
        [onAir, state.duration, duration],
      ) ?? 0
    const timeValue =
      useMemo(() => (onAir ? time : state.time), [onAir, state.time, time]) ?? 0
    const volumeValue =
      useMemo(
        () => (onAir ? volume : state.volume),
        [onAir, state.volume, volume],
      ) ?? 0
    const repeatValue =
      useMemo(() => (onAir ? repeat : isRepeat), [onAir, isRepeat, repeat]) ??
      false
    const pauseValue =
      useMemo(
        () => (onAir ? paused : state.paused),
        [onAir, state.paused, paused],
      ) ?? false

    const preVolumeValue =
      useMemo(
        () => (onAir ? prevVolume : preVolume),
        [onAir, preVolume, prevVolume],
      ) ?? 0

    const [downloading, setDownloading] = useState(false)

    useEffect(() => {
      if (state.time === state.duration && isRepeat) {
        controls.seek(0)
        controls.play()
      }
    }, [state.time])

    useEffect(() => {
      if (audio) {
        if (autoPlay) {
          controls.play()
        }
        if (defaultVolume >= 0) {
          controls.volume(defaultVolume)
        }
      }
    }, [])

    const handleChangeVolume = (v: number) => {
      if (onAir) {
        let newValue: TStateMediaPreview = {
          volume: v,
        }
        if (v > 0) {
          newValue = {
            ...newValue,
            prevVolume: v,
          }
        }
        updateStateAudioPlaying(newValue)
      }
      setPreVolume(v)
      onChangeVolume && onChangeVolume(v)
    }

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
      onDelete && onDelete(e)
    }

    const handleDownload = async () => {
      try {
        setDownloading(true)
        await saveSync(url, `${name}.${url.split('.').pop()}`)
        setDownloading(false)
      } catch (e) {
        setDownloading(false)
        ToastService.error({ content: 'Save file failed', duration: 2 })
      }
    }
    const onToggleAudio = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()
      if (onAir) {
        updateStateAudioPlaying({ paused: !paused })
        return
      }

      if (state.paused) {
        controls.play()
      } else {
        controls.pause()
      }
    }

    const handleDragToChangeVolume = (v: number) => {
      if (!isDraggingVolume) {
        setDraggingVolume(true)
        updateStateAudioPlaying({ prevVolume: v })
      }
      if (!onAir) {
        controls.volume(v)
      }
      updateStateAudioPlaying({ volume: v })
      onChangeVolume && onChangeVolume(v)
    }

    const handleAfterChange = (v: number) => {
      if (v !== 0) {
        updateStateAudioPlaying({ prevVolume: v })
      }
      setDraggingVolume(false)
    }

    const handleToggleRepeat = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (onAir) {
        updateStateAudioPlaying({
          repeat: !repeat,
        })
        return
      }

      setRepeat(!isRepeat)
    }
    const onSeek = (v: number) => {
      if (onAir) {
        updateStateAudioPlaying({ nextTime: v })
      }
      controls.seek(v)
    }
    const audioIconType =
      volumeValue <= volume0
        ? 'icon_volumn_silent'
        : volumeValue < 0.5
          ? 'icon_volumn_medium'
          : 'icon_volumn_max'
    const { isLightTheme } = S.local
    return (
      <>
        {!onAir && audio}
        <div className={css.AudioPlayerItemLink} />
        <div className={css.AudioPlayerTop}>
          <span className={css.AudioPlayerName}>
            <Icon
              icon='icon_media_music'
              size={13}
              className={css.AudioPlayerTopIcon}
            />
            {name !== ''
              ? formatFileName(name, 15)
              : formatFileName(getNameOfUrl(url), 15)}
          </span>
          {!onAir && (
            <span className={css.AudioPlayerDuration}>
              {`${covertDurationToMinutes(
                state.time,
              )}/${covertDurationToMinutes(durationValue)}`}
            </span>
          )}
          {onAir && (
            <div
              className={css.ButtonOnAir}
              onClick={() => onTurnOffAir && onTurnOffAir()}
            >
              <Icon icon='icon_triangle' size={13} />
              On Air
            </div>
          )}
        </div>
        <div
          onClick={e => e.stopPropagation()}
          className={css.AudioPlayerMainProgress}
        >
          <BeamSlider
            className={clsx({
              [css.AudioPlayerProgress]: true,
              [css.audioPlayerProgressOnAir]: onAir,
            })}
            min={0}
            max={Math.floor(durationValue)}
            step={1}
            value={timeValue}
            tooltipVisible={false}
            onChange={onSeek}
            trackStyle={{
              backgroundColor: isLightTheme ? '#2656C9' : '#085AE1',
              borderRadius: 2,
            }}
            handleStyle={{
              backgroundColor: '#4098FF',
              boxShadow: isLightTheme
                ? '0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                : 'none',
              border: 'none',
              width: 16,
              height: 16,
            }}
            // onAir={onAir}
          />
          {onAir && (
            <span className={css.AudioPlayerDuration}>
              {`${covertDurationToMinutes(timeValue)}/${covertDurationToMinutes(
                durationValue,
              )}`}
            </span>
          )}
        </div>
        <div className={css.AudioPlayerBottom}>
          <div
            onClick={e => e.stopPropagation()}
            className={css.AudioPlayerVolumeInfo}
          >
            <Icon
              className={css.AudioPlayerMute}
              onClick={() => {
                handleChangeVolume(
                  volumeValue <= volume0 ? getVolumePrev(preVolumeValue) : 0,
                )
              }}
              icon={audioIconType}
              size={14}
            />
            <BeamSlider
              min={0}
              max={1}
              className={css.AudioPlayerVolumeSwitch}
              step={0.1}
              value={volumeValue}
              tooltipVisible={false}
              onChange={handleDragToChangeVolume}
              onAfterChange={handleAfterChange}
              trackStyle={{
                backgroundColor: S.local.isLightTheme
                  ? '#2656C9'
                  : CONSTANTS.level.lighter1,
                borderRadius: 4,
              }}
              handleStyle={{
                backgroundColor: S.local.isLightTheme
                  ? '#ffffff'
                  : CONSTANTS.text.level1,
                border: 'none',
                boxShadow: S.local.isLightTheme
                  ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                  : 'none',
                width: 14,
                height: 14,
              }}
            />
          </div>
          <span
            className={css.AudioPlayerPlay}
            // playing={!state.paused}
            onClick={onToggleAudio}
          >
            <Icon icon={pauseValue ? 'icon_play' : 'icon_pause'} size={18} />
          </span>
          <div className={css.AudioPlayerCustomAction}>
            <span className={css.AudioPlayerIcon} onClick={handleDelete}>
              <Icon icon='icon_delete' size={13} />
            </span>
            {downloading ? (
              <SyncOutlined
                className={clsx(['animation-spin', css.AudioPlayerIcon])}
              />
            ) : (
              <span className={css.AudioPlayerIcon} onClick={handleDownload}>
                <Icon icon='icon_download' size={13} />
              </span>
            )}
            <span
              className={clsx({
                [css.AudioPlayerRepeat]: true,
                [css.audioPlayerRepeated]: repeatValue,
              })}
              // repeated={isRepeat}
              onClick={handleToggleRepeat}
            >
              <Icon icon='icon_repeat' size={18} />
            </span>
          </div>
        </div>
      </>
    )
  },
)
