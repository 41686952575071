import clsx from 'clsx'
import ColorHash from 'color-hash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useState } from 'react'
import { useDragLayer } from 'react-dnd'

// eslint-disable-next-line css-modules/no-unused-class
import css from './ChatItem.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessGrayDiv } from '../../../components/composites/WarmnessGrayDiv'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { DRAG_CHAT } from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type { TChatMessage } from '../../../context/store/studio/WebrtcStore'
import { useDragDefault } from '../../../utils/useDrag'
import { ChatAttachments } from './ChatAttachments'
import type { TDragPreview } from './RightContent'

type TPropsChat = {
  chat: TChatMessage
  isOnLiveStream?: boolean
}
type TPropsChatAction = {
  onDelMessage: () => void
  onVisibleChange: (status: boolean) => void
  onToggleSession: () => void
  isLightTheme?: boolean
  warmnessColor?: string
  warmnessColorGray?: string
}
const colorHash = new ColorHash()

const getTimeFormat = (time: Date) => moment(time, 'HH:mm').format('HH:mm')

export const ItemChat = observer(
  ({ chat, isOnLiveStream = false }: TPropsChat) => {
    const { isLightTheme, warmnessColor, warmnessColorPressed } = S.local
    const { isViewmodeParticipant } = S.webrtc
    const [isShowAction, setToggleAction] = useState(false)
    const { isDragging, drag } = useDragDefault({
      item: {
        id: chat.message.chatId,
        userName: chat.user.name,
        userAvatar: chat.user?.avatar || '',
        userId: chat.user.id,
        contentChat: chat.message.content,
        contentTime: getTimeFormat(chat.message.time),
        type: DRAG_CHAT,
      },
      dragType: DRAG_CHAT,
    })
    const handleDelMessage = () => {
      const chatId = chat.message.chatId
      setToggleAction(false)
      S.webrtc.deleteChat(chatId)
      if (chatId === S.webrtc.selectedChat) {
        S.webrtc.updateAndEmit({
          selectedChat: '',
        })
      }
      S.webrtc.mediasoup?.protoo?.request('deleteChat', { chatId })
    }

    const handelDelAttachment = async (attachmentId: string) => {
      S.webrtc.deleteAttachment(chat.message.chatId, attachmentId)
      S.webrtc.mediasoup?.protoo?.request('deleteAttachment', {
        chatId: chat.message.chatId,
        attachmentId,
      })
      await reduxStore.context.gql.deleteResourceInSession({
        ids: [attachmentId],
      })
    }

    const handleToggleSession = () => {
      const sessionId =
        S.webrtc.selectedChat === chat.message.chatId ? '' : chat.message.chatId
      S.webrtc.dropItemToLayout(DRAG_CHAT, sessionId)
    }
    const handleVisibleChange = () => {
      setToggleAction(!isShowAction)
    }
    const handleOffAir = () => {
      if (isViewmodeParticipant || S.webrtc.isOnlyViewStream) {
        return
      }
      S.webrtc.updateAndEmit({
        selectedChat: '',
      })
    }

    const isOnAir = S.webrtc.selectedChat === chat.message.chatId

    const ratioScale = S.webrtc.ratioScaleLayout
    return (
      <div
        className={clsx({
          [css.ItemChatContainer]: true,
          [css.itemChatContainerDragging]: isDragging,
          [css.itemChatContainerOnliveStream]: isOnLiveStream,
        })}
        style={{
          // @ts-ignore
          '--mbItemChatContainerOnLiveStream': isOnLiveStream
            ? `${ratioScale * 12}px`
            : undefined,
          '--wIChatAvatar': isOnLiveStream ? `${ratioScale * 28}px` : undefined,
          '--hIChatAvatar': isOnLiveStream ? `${ratioScale * 28}px` : undefined,
          '--fIChatAvatar': isOnLiveStream ? `${ratioScale * 14}px` : undefined,
          '--mlItemAttachmentContent': isOnLiveStream
            ? `${ratioScale * 35}px`
            : undefined,
          '--wItemChatContent': isOnLiveStream
            ? `calc(100% - ${ratioScale * 35}px)`
            : undefined,
          '--mlItemChatContent': isOnLiveStream
            ? `${ratioScale * 7}px`
            : undefined,
          '--pItemChatContent': isOnLiveStream
            ? `${ratioScale * 12}px`
            : undefined,
          '--fItemChatContent': isOnLiveStream
            ? `${ratioScale * 13}px`
            : undefined,
          '--fItemChatTime': isOnLiveStream
            ? `${ratioScale * 10}px`
            : undefined,
          '--mtItemChatOnAir': isOnLiveStream
            ? `${ratioScale * 10}px`
            : undefined,
          '--pItemChatOnAir': isOnLiveStream
            ? `${2 * ratioScale}px ${4 * ratioScale}px`
            : undefined,
          '--mItemAttachmentList': isOnLiveStream
            ? `0 -${2 * ratioScale}px`
            : undefined,
          '--mbItemAttachmentDetailImage': isOnLiveStream
            ? `${14 * ratioScale}px`
            : undefined,
          '--pxItemAttachmentDetailImage': isOnLiveStream
            ? ` 0 ${2 * ratioScale}px`
            : undefined,
          '--hItemAttachmentDetailImage': isOnLiveStream
            ? `${66 * ratioScale}px`
            : undefined,
        }}
        ref={!isViewmodeParticipant && !isOnLiveStream ? drag : undefined}
        // dragging={isDragging}
        // isOnLiveStream={isOnLiveStream}
        // ratioScale={store.webrtc.ratioScaleLayout}
      >
        {renderAvatar(chat.user)}
        {(chat.message.content !== '' ||
          chat.message.attachments.length > 0) && (
          <div
            style={{
              background: isOnLiveStream
                ? 'rgba(0, 0, 0, 0.56)'
                : S.local.warmnessColorGray,
            }}
            className={clsx({
              [css.ItemChatContent]: true,
              [css.itemChatContentOnAir]: isOnAir,
              [css.itemChatContentShowAction]: isShowAction,
            })}
          >
            {!isViewmodeParticipant && !isOnLiveStream && (
              <ItemChatAction
                isLightTheme={isLightTheme}
                warmnessColor={warmnessColor}
                onVisibleChange={handleVisibleChange}
                onDelMessage={handleDelMessage}
                onToggleSession={handleToggleSession}
                warmnessColorGray={warmnessColorPressed}
              />
            )}
            <div className={css.ItemChatInfo}>
              <span
                className={clsx(
                  css.ItemChatName,
                  isOnLiveStream ? css.itemChatSpanIsOnLiveStream : undefined,
                )}
              >
                {chat.user.name}
              </span>

              <div
                className={clsx(
                  css.ItemChatTime,
                  isOnLiveStream ? css.itemChatSpanIsOnLiveStream : undefined,
                )}
              >
                {getTimeFormat(chat.message.time)}
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: chat.message.content }}
              style={{
                color: isOnLiveStream ? '#E1E1E9' : undefined,
              }}
              className={clsx(
                css.ItemChatMessage,
                isOnLiveStream ? css.ItemChatMessageIsOnLiveStream : undefined,
              )}
            />

            {chat.message.attachments.length > 0 && (
              <ChatAttachments
                attachments={chat.message.attachments}
                onDelete={handelDelAttachment}
              />
            )}
            {isOnAir && (
              <span onClick={handleOffAir} className={css.ItemChatOnAir}>
                On Air
              </span>
            )}
          </div>
        )}
      </div>
    )
  },
)
export type TUser = {
  id: string
  name?: string
  avatar?: string | undefined
}
export const renderAvatar = (u: TUser, style?: object) =>
  u.avatar ? (
    <div className={css.ItemChatAvatar} style={style}>
      <img
        draggable={false}
        className={css.ItemChatAvatarImage}
        src={u.avatar}
      />
    </div>
  ) : (
    <div
      className={css.ItemChatAvatar}
      style={{
        ...style,
        backgroundColor: colorHash.hex(u.id || u?.name || ''),
      }}
    >
      <label className={css.ItemChatAvatarLabel}>
        {u?.name?.length ? u.name[0] : ''}
      </label>
    </div>
  )

export const ItemChatPreview = observer(() => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  if (!isDragging || item.type !== DRAG_CHAT) {
    return null
  }
  const user = {
    id: item.userId,
    name: item.userName,
    avatar: item.userAvatar !== '' ? item.userAvatar : undefined,
  }

  return (
    <div
      className={css.ItemChatPreviewContainer}
      style={getItemPreviewStyles(initialOffset, currentOffset)}
    >
      <div className={css.ItemChatContainer}>
        {renderAvatar(user)}
        <WarmnessGrayDiv className={css.ItemChatContent}>
          <div className={css.ItemChatInfo}>
            <span className={css.ItemChatName}>{item.userName}</span>
            <span className={css.ItemChatTime}>{item.contentTime}</span>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: item.contentChat }}
            className={css.ItemChatMessage}
          />
        </WarmnessGrayDiv>
      </div>
    </div>
  )
})

const ItemChatAction = observer(
  ({
    onVisibleChange,
    onDelMessage,
    onToggleSession,
    isLightTheme,
    warmnessColor,
    warmnessColorGray,
  }: TPropsChatAction) => {
    const [visible, setVisible] = useState(false)
    const [toggleSession, setToggle] = useState(false)

    const handleToggleSession = () => {
      onVisibleChange(false)
      setVisible(false)
      onToggleSession()
      setTimeout(() => {
        setToggle(!toggleSession)
      }, 500)
    }

    const handleDelMessage = () => {
      setVisible(false)
      onVisibleChange(false)
      onDelMessage && onDelMessage()
    }
    const handleVisibleChange = (status: boolean) => {
      setVisible(status)
      onVisibleChange && onVisibleChange(status)
    }

    const contentAction = () => (
      <div className={css.ItemChatActionContent}>
        <div
          className={clsx(css.ItemChatActionSession, {
            [css.ItemChatActionDeleteLight]: isLightTheme,
          })}
          // hided={!toggleSession}
          onClick={handleToggleSession}
        >
          <span>
            <Icon
              icon={toggleSession ? 'icon_off_air' : 'icon_on_air'}
              size={16}
            />
          </span>
          {toggleSession ? 'Hide on session' : 'Show on session'}
        </div>
        <div
          className={clsx(css.ItemChatActionDelete, {
            [css.ItemChatActionDeleteLight]: isLightTheme,
          })}
          onClick={handleDelMessage}
        >
          <span>
            <Icon icon='icon_delete' size={13} />
          </span>
          Delete
        </div>
      </div>
    )
    return (
      <WarmnessCustomPopOver
        placement='leftTop'
        content={contentAction()}
        visible={visible}
        isLightTheme={isLightTheme}
        onVisibleChange={handleVisibleChange}
      >
        <span
          style={{
            // @ts-ignore
            '--background-active-dark': warmnessColorGray,
            background: warmnessColor,
          }}
          className={css.ItemChatActionIcon}
        >
          <Icon icon='icon_three_dots' size={12} />
        </span>
      </WarmnessCustomPopOver>
    )
  },
)
