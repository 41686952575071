import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './OnOffAirDropdown.module.scss'

import { BeamSlider } from '#HACK_FOR_RN_ONLY/src/components/base/Slider'
import { WarmnessCustomPopOver } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessCustomPopover'
import { getVolumePrev, volume0 } from '#rn-shared/mediasoup'
import type { MediaVolume } from '#rn-shared/mediasoup/type-ui'

import { Icon } from '../../../components/base/Icon'
import { Popover } from '../../../components/base/Popover'
import { ModalService } from '../../../components/Modal/Modal'
import { HIDDEN_FOR_NEXT_FEATURES } from '../../../config'
import { ModalUpdateInput } from '../../../context/shared/components/ModalUpdateInput'
import { S } from '../../../context/store'
import { isInputPeer } from '../utils/isInputPeer'
import { Divider } from './Divider'

type TDropdown = {
  volume: MediaVolume
  onVolumeChange(v: Partial<MediaVolume>): void
  isTriggerHover?: boolean
  videoEnabled: boolean
  onToggleVideo?: () => void
  onKickOut?: () => void
  isScreenShare?: boolean
  peerId?: string
  isMainCanvas?: boolean
}
export const OnOffAirDropdown: FC<TDropdown> = observer(
  ({
    onVolumeChange,
    volume,
    isTriggerHover,
    onToggleVideo,
    videoEnabled,
    isScreenShare,
    onKickOut,
    peerId,
    isMainCanvas,
  }) => {
    const { isViewmodeParticipant } = S.webrtc
    const { selectedRightTabBarKey, updateDataOfStore } = S.webrtc
    const { isLightTheme, warmnessColor } = S.local
    const [isVisible, setVisible] = useState(false)
    const [isVisibleRight, setVisibleVisibleRight] = useState(false)

    const currentVolume = volume.current
    const [isDraggingVolume, setDraggingVolume] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [isHoverRecord, setIsHoverRecord] = useState(false)
    const initVolume = useRef(false)
    const handleToggleVolume = () => {
      const newValue: Partial<MediaVolume> =
        currentVolume > 0
          ? {
              current: 0,
              prev: currentVolume,
            }
          : {
              current: getVolumePrev(volume?.prev),
            }

      onVolumeChange(newValue)
    }
    useEffect(() => {
      if (
        !initVolume.current &&
        volume.isLocal &&
        volume.current !== currentVolume
      ) {
        handleDragToChangeVolume(volume.current)
        initVolume.current = true
      }
    }, [volume])
    const handleDragToChangeVolume = (v: number) => {
      if (!isDraggingVolume) {
        setDraggingVolume(true)
        onVolumeChange({ prev: v })
      }
      onVolumeChange({ current: v })
    }
    const handleKickOut = () => {
      onKickOut && onKickOut()
    }
    const handleAfterChange = (v: number) => {
      if (v !== 0) {
        onVolumeChange({ prev: v })
      }
      setDraggingVolume(false)
    }
    const handleAddNotes = () => {
      setVisible(false)
      updateDataOfStore({
        selectedRightTabBarKey: selectedRightTabBarKey === '1' ? '' : '1',
      })
    }
    const handleRecord = () => {
      setIsRecording(prev => !prev)
      setIsHoverRecord(false)
    }
    // const handleAddNotesEvent = function (event: KeyboardEvent) {
    //   if (event.shiftKey && event.keyCode === 78) {
    //     handleAddNotes()
    //   }
    // }
    // useEffect(() => {
    //   document.addEventListener('keydown', handleAddNotesEvent)
    //   return () => {
    //     document.removeEventListener('keydown', handleAddNotesEvent)
    //   }
    // }, [selectedRightTabBarKey])
    const editInput = () => {
      setVisibleVisibleRight(false)
      ModalService.show(ModalUpdateInput, { peerId })
    }
    const renderHandlerDropdown = () => (
      <div className={css.DropdownHandler}>
        {!isMainCanvas && onToggleVideo && !isInputPeer(peerId) && (
          <div className={css.DropdownHandlerItem} onClick={onToggleVideo}>
            <Icon
              icon={
                videoEnabled
                  ? 'videocam_FILL1_wght400_GRAD0_opsz48-1'
                  : 'Vector11'
              }
              size={18}
            />
            {videoEnabled ? 'Turn off video' : 'Turn on video'}
          </div>
        )}

        {!isMainCanvas &&
          onKickOut &&
          !isScreenShare &&
          !isViewmodeParticipant &&
          !isInputPeer(peerId) && (
            <>
              <Divider />
              <div className={css.DropdownHandlerItem} onClick={handleKickOut}>
                <Icon
                  icon='icon_delete'
                  size={14}
                  className={css.DropdownVideoKickOut}
                />
                Kick out
              </div>
            </>
          )}
        {isInputPeer(peerId) && (
          <>
            <div className={css.DropdownHandlerItem} onClick={editInput}>
              <Icon icon='Edit' size={18} />
              Edit
            </div>
            <Divider />
            <div className={css.DropdownHandlerItem} onClick={handleKickOut}>
              <Icon
                icon='icon_delete'
                size={14}
                className={css.DropdownVideoKickOut}
              />
              Delete
            </div>
          </>
        )}
      </div>
    )

    const renderContent = () => (
      <div className={css.DropdownWrapper}>
        <span className={css.SliderAudioIcon} onClick={handleToggleVolume}>
          <Icon
            icon={currentVolume <= volume0 ? 'icon_mic_off' : 'icon_mic'}
            size={12}
          />
        </span>
        <div className={css.onOffAirDropDownSliderWrapper}>
          <BeamSlider
            className={clsx({
              [css.AudioPlayerProgress]: true,
            })}
            max={1}
            step={0.05}
            min={0}
            tooltipVisible={false}
            value={currentVolume}
            trackStyle={{
              backgroundColor: isLightTheme ? '#2656C9' : '#085AE1',
              borderRadius: 2,
            }}
            handleStyle={{
              backgroundColor: '#4098FF',
              boxShadow: isLightTheme
                ? '0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                : 'none',
              border: 'none',
              width: 16,
              height: 16,
            }}
            onChange={handleDragToChangeVolume}
            onAfterChange={handleAfterChange}
          />
          <span className={css.SliderStep}>
            {Number(currentVolume * 100).toFixed(0)}
          </span>
        </div>
        {!HIDDEN_FOR_NEXT_FEATURES && (
          <>
            <div
              className={clsx({ [css.DropdownRecordWrapper]: isRecording })}
              onClick={handleRecord}
              onMouseOver={() => {
                if (isRecording) {
                  setIsHoverRecord(true)
                }
              }}
              onMouseOut={() => {
                if (isRecording) {
                  setIsHoverRecord(false)
                }
              }}
            >
              {!isHoverRecord ? (
                <>
                  <div
                    className={clsx(css.DropdownVideoAction, {
                      [css.DropdownVideoRecording]: isRecording,
                    })}
                  >
                    <Icon icon='icon_menu_record' size={16} />
                  </div>
                  {isRecording && <span className={css.RecordTime}>1:30</span>}
                </>
              ) : (
                <span className={css.StopRecord}>Stop</span>
              )}
            </div>
            <div className={css.DropdownVideoAction} onClick={handleAddNotes}>
              <Icon icon='AddNote1' size={16} />
            </div>
          </>
        )}

        {/* {(isTriggerHover || isVisible) && (
            <WarmnessCustomPopOver
              trigger='click'
              placement='bottomRight'
              isLightTheme={isLightTheme}
              content={renderHandlerDropdown()}
              overlayClassName='hide-arrow'
              visible={isVisibleCameraDropdown}
              onVisibleChange={status => setVisibleCameraDropdown(status)}
            >
              <div
                className={clsx({
                  [css.DropdownVideoSettings]: true,
                })}
              />
            </WarmnessCustomPopOver>
          )} */}
      </div>
    )
    return (
      <div className={css.DropdownContainer}>
        <WarmnessCustomPopOver
          visible={isVisible}
          align={{ offset: [-40, 4], targetOffset: [0, 8, 0, 0] }}
          style={{ background: warmnessColor }}
          borderRadius={50}
          trigger='hover'
          isLightTheme={isLightTheme}
          content={renderContent()}
          overlayClassName='hide-arrow'
          onVisibleChange={status => setVisible(status)}
        >
          <Popover
            showArrow={false}
            visible={isVisibleRight}
            placement='rightBottom'
            trigger='click'
            onVisibleChange={status => setVisibleVisibleRight(status)}
            _overlay={renderHandlerDropdown()}
          >
            <div
              className={clsx({
                [css.DropdownVideoAction]: true,
              })}
            >
              <Icon icon='icon_three_dots' size={12} />
            </div>
          </Popover>
        </WarmnessCustomPopOver>
      </div>
    )
  },
)
