export const allFonts: string[] = [
  'Poppins',
  'Playfair Display',
  'Roboto',
  'Be Vietnam Pro',
  'Lato',
  'Open Sans',
  'Montserrat',
  'Oswald',
]
