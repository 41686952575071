import { ECheckboxSize } from './type'

export const getSizeCheckbox = (sizeType?: ECheckboxSize) => {
  switch (sizeType) {
    case ECheckboxSize.M:
      return '24px'
    case ECheckboxSize.S:
      return '20px'
    default:
      return '24px'
  }
}
