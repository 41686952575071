import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import ReactAvatar from 'react-avatar'

import Close from '../../../assets/icons/CloseNotiIcon.svg'
import StatusSign from '../../../assets/icons/Status@.svg'
import css from './NotificationRoom.module.scss'

import { Button } from '#HACK_FOR_RN_ONLY/src/components/base/Button'
import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import { Show } from '#HACK_FOR_RN_ONLY/src/components/base/Show'
import { ToastService } from '#HACK_FOR_RN_ONLY/src/components/widget/Toast'

import { Map } from '../../../components/base/Map'
import { getAvatarColor } from '../../../utils/avatar'
import { reduxStore } from '../../redux'
import { S } from '../../store'
import { handleResolveRequestRole } from '../actions/handleResolveSwitchRole'
import {
  NotificationRoomApi,
  useNotificationRoomHandler,
} from './NotificationHandler'

type NotificationCategory =
  | 'JOIN_REQUEST'
  | 'MESSAGE_MENTION'
  | 'SWITCH_ROLE'
  | 'SWITCH_ROLE_RESPONSE'

type SwitchRoleResponseType = 'ACCEPT' | 'REJECT'

export interface INotificationRoomProps {
  id?: string
  type?: NotificationCategory
  name: string
  message?: string
  avatar: string
  role?: string
  responseType?: SwitchRoleResponseType
  userId?: string
}
export const NotificationRoom = observer(() => {
  const { data } = useNotificationRoomHandler()

  return (
    <div
      className={clsx({
        [css.Wrapper]: true,
        [css.showNotification]: data.length > 0,
      })}
    >
      <Map<INotificationRoomProps>
        list={data}
        renderItem={(item, index) => (
          <Show>
            <Show.When isTrue={item.type === 'MESSAGE_MENTION'}>
              <ToastMessageNotifItem item={item} index={index} />
            </Show.When>
            <Show.When isTrue={item.type === 'JOIN_REQUEST'}>
              <ToastJoinRequestNotifItem item={item} index={index} />
            </Show.When>
            <Show.When isTrue={item.type === 'SWITCH_ROLE'}>
              <ToastSwitchRoleNotifItem item={item} index={index} />
            </Show.When>
            <Show.Else>
              <ToastSwitchRoleResponseNotifItem item={item} index={index} />
            </Show.Else>
          </Show>
        )}
      />
    </div>
  )
})

const ToastMessageNotifItem: FC<{
  item: INotificationRoomProps
  index: number
}> = observer(({ item, index }) => {
  const { avatar, message, name } = item
  const { isLightTheme, warmnessColor } = S.local
  useEffect(() => {
    setTimeout(() => {
      NotificationRoomApi.hide(index)
    }, 10000)
  }, [])
  const nameFirstChar = name ? (name.length > 0 ? name[0] : '.') : '.'
  return (
    <div
      style={{ background: warmnessColor }}
      className={clsx({
        [css.WrapperDarkItem]: !isLightTheme,
        [css.WrapperItem]: isLightTheme,
        [css.showNotification]: true,
      })}
    >
      <div
        style={{
          // @ts-ignore
          '--bgDarkContainer': isLightTheme
            ? undefined
            : 'linear-gradient(0deg,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)),rgba(23, 23, 29, 0.85)',
        }}
        className={css.Container}
      >
        <div className={css.Avatar}>
          {avatar ? (
            <img className={css.AvatarImage} src={avatar} />
          ) : (
            <ReactAvatar
              name={nameFirstChar}
              round
              size='42'
              textSizeRatio={3}
              color={getAvatarColor(nameFirstChar)}
            />
          )}
          <div className={css.StatusWrapperWhiteBorder}>
            <div className={css.StatusWrapper}>
              <img src={StatusSign} />
            </div>
          </div>
        </div>
        <div className={css.ContentContainer}>
          <div className={isLightTheme ? css.TextName : css.TextNameDark}>
            {name}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: message ?? '' }}
            className={isLightTheme ? css.TextMessage : css.TextMessageDark}
          />
        </div>

        <div
          onClick={() => NotificationRoomApi.hide(index)}
          className={css.CloseWrapper}
        >
          <img src={Close} />
        </div>
      </div>
    </div>
  )
})

const ToastJoinRequestNotifItem: FC<{
  item: INotificationRoomProps
  index: number
}> = observer(({ item, index }) => {
  const { avatar, name } = item
  const { isLightTheme, warmnessColor } = S.local
  const { waitingUsers } = S.webrtc
  const [isLoading, setIsLoading] = useState(false)

  const nameFirstChar = name ? (name.length > 0 ? name[0] : '.') : '.'

  const sessionId = new URLSearchParams(location.search).get('r') ?? ''
  useEffect(() => {
    if (!waitingUsers.some(u => u.id === item.id)) {
      NotificationRoomApi.hide(index)
    }
  }, [waitingUsers])
  const handleResponseJoinReq = async (accept: boolean) => {
    try {
      setIsLoading(true)
      const res = await reduxStore.context.gql.answerJoinRequest({
        sessionId,
        waitingUserId: item.userId ?? '',
        isAccepted: accept,
      })
      setIsLoading(false)
      if (res.error) {
        ToastService.error({
          content: `${accept ? 'Accept' : 'Decline'} join request failed`,
        })
        return
      }
      NotificationRoomApi.hide(index)
    } catch (e) {
      ToastService.error({
        content: `${accept ? 'Accept' : 'Decline'} join request failed`,
      })
    }
  }

  return (
    <div
      style={{ background: warmnessColor }}
      className={clsx({
        [css.WrapperDarkItem]: !isLightTheme,
        [css.WrapperItem]: isLightTheme,
        [css.showNotification]: true,
      })}
    >
      <div
        style={{
          // @ts-ignore
          '--bgDarkContainer': isLightTheme
            ? undefined
            : 'linear-gradient(0deg,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)),rgba(23, 23, 29, 0.85)',
        }}
        className={clsx(css.Container, css.ToastJRContainer)}
      >
        <div className={css.Header}>Join Request</div>
        <div className={css.Content}>
          <div className={clsx(css.JRAvatar)}>
            {avatar ? (
              <img className={css.JRAvatarImage} src={avatar} />
            ) : (
              <ReactAvatar
                name={nameFirstChar}
                round
                size='28'
                textSizeRatio={3}
                color={getAvatarColor(nameFirstChar)}
              />
            )}
          </div>
          <div
            className={clsx(
              isLightTheme ? css.TextMessage : css.TextMessageDark,
              css.TextContainer,
            )}
          >
            <span className={css.JRTextName}>{name}</span> is requesting to{' '}
            <span className={css.JRTextRole}>join as a {item.role}.</span>
          </div>
        </div>
        {/* <div className={css.PreventContainer}>
          <BeamCheckbox containerProps={{ size: ECheckboxSize.S }}>
            Prevent this user from joining again
          </BeamCheckbox>
        </div> */}
        <div className={css.ToastAction}>
          <Button
            loading={isLoading}
            containerType='grey'
            onClick={() => handleResponseJoinReq(false)}
          >
            Decline
          </Button>
          <Button
            loading={isLoading}
            className={css.AcceptBtn}
            onClick={() => handleResponseJoinReq(true)}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
})

const ToastSwitchRoleNotifItem: FC<{
  item: INotificationRoomProps
  index: number
}> = observer(({ item, index }) => {
  const { avatar, name } = item
  const { isLightTheme, warmnessColor } = S.local

  const [loading, setLoading] = useState(false)
  const nameFirstChar = name ? (name.length > 0 ? name[0] : '.') : '.'

  return (
    <div
      style={{ background: warmnessColor }}
      className={clsx({
        [css.WrapperDarkItem]: !isLightTheme,
        [css.WrapperItem]: isLightTheme,
        [css.showNotification]: true,
      })}
    >
      <div
        style={{
          // @ts-ignore
          '--bgDarkContainer': isLightTheme
            ? undefined
            : 'linear-gradient(0deg,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)),rgba(23, 23, 29, 0.85)',
        }}
        className={clsx(css.Container, css.ToastJRContainer)}
      >
        <div className={css.Content}>
          <div className={clsx(css.JRAvatar)}>
            {avatar ? (
              <img className={css.JRAvatarImage} src={avatar} />
            ) : (
              <ReactAvatar
                name={nameFirstChar}
                round
                size='28'
                textSizeRatio={3}
                color={getAvatarColor(nameFirstChar)}
              />
            )}
          </div>
          <div
            className={clsx(
              isLightTheme ? css.TextMessage : css.TextMessageDark,
              css.TextContainer,
              css.TextBold,
            )}
          >
            {name} would like to change you to{' '}
            {item.role === 'Host' ? 'a' : 'an'} {item.role}.
          </div>
        </div>
        <div className={css.SubText}>Do you accept?</div>
        <div className={css.ToastAction}>
          <Button
            containerType='grey'
            disabled={loading}
            className={css.DeclineBtn}
            onClick={() => {
              handleResolveRequestRole(false, item.userId ?? '')
              NotificationRoomApi.hide(index)
            }}
          >
            No
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              await handleResolveRequestRole(true, item.userId ?? '', item.role)
              setLoading(false)
            }}
            className={css.AcceptBtn}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  )
})

const ToastSwitchRoleResponseNotifItem: FC<{
  item: INotificationRoomProps
  index: number
}> = observer(({ item, index }) => {
  const { avatar, name, responseType } = item
  const { isLightTheme, warmnessColor } = S.local
  useEffect(() => {
    setTimeout(() => {
      NotificationRoomApi.hide(index)
    }, 10000)
  }, [])
  const nameFirstChar = name ? (name.length > 0 ? name[0] : '.') : '.'
  return (
    <div
      style={{ background: warmnessColor }}
      className={clsx({
        [css.WrapperDarkItem]: !isLightTheme,
        [css.WrapperItem]: isLightTheme,
        [css.showNotification]: true,
      })}
    >
      <div
        style={{
          // @ts-ignore
          '--bgDarkContainer': isLightTheme
            ? undefined
            : 'linear-gradient(0deg,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.1)),rgba(23, 23, 29, 0.85)',
        }}
        className={clsx(css.Container, css.ToastResContainer)}
      >
        <div className={css.Content}>
          <div className={clsx(css.JRResAvatar)}>
            {avatar ? (
              <img className={css.JRAvatarImage} src={avatar} />
            ) : (
              <ReactAvatar
                name={nameFirstChar}
                round
                size='28'
                textSizeRatio={3}
                color={getAvatarColor(nameFirstChar)}
              />
            )}
            <Show>
              <Show.When isTrue={responseType === 'ACCEPT'}>
                <div className={css.CheckIconWrapper}>
                  <Icon icon='Checked' disableFill={false} size={16} />
                </div>
              </Show.When>
              <Show.Else>
                <div className={css.WaitingIconWrapper}>
                  <Icon icon='Waiting' size={14} color='#481100' />
                </div>
              </Show.Else>
            </Show>
          </div>
          <div
            className={clsx(
              isLightTheme ? css.TextMessage : css.TextMessageDark,
              css.TextContainer,
              // css.TextBold,
            )}
          >
            {name} {responseType === 'REJECT' && "didn't"} accepted the role
            change
          </div>
        </div>
        <div
          onClick={() => NotificationRoomApi.hide(index)}
          className={css.CloseWrapper}
        >
          <img src={Close} />
        </div>
      </div>
    </div>
  )
})
