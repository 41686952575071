import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import { useEffect, useRef } from 'react'
import { useDragLayer } from 'react-dnd'

// eslint-disable-next-line css-modules/no-unused-class
import css from './SlotMedia.module.scss'

import type { Viewmode } from '#rn-shared/mediasoup/type'

import { AppSpin } from '../../../components/base/Spin'
import { S } from '../../../context/store'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import { DRAG_MEDIA_SLOT } from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type {
  TKeyString,
  TMediaDragDropData,
} from '../../../context/store/studio/WebrtcStore'
import {
  amsMediaPrefix,
  buildControlMediaId,
  controlMediaPrefix,
} from '../../../context/store/studio/WebrtcStore'
import { useCheckHover } from '../../../utils/useCheckHover'
import { useDragSlotMedia } from '../../../utils/useDrag'
import { useDropSlot } from '../../../utils/useDrop'
import { Player } from './Player'
import type { TDragPreview } from './RightContent'
import { SlotIconAction } from './SlotIconAction'
import { VideoPlayerSlot } from './VideoPlayerSlot'

type SlotMediaProps = {
  mediaData: TKeyString
  position?: number
  isShow?: boolean
  mediaStyle?: CSSProperties
  cropMode?: ECropMode
  layoutIndex?: number
  muted?: boolean
}

export const SlotMedia: FC<SlotMediaProps> = observer(
  ({
    position,
    mediaData,
    isShow,
    mediaStyle,
    cropMode,
    layoutIndex,
    muted,
  }) => {
    const {
      computedSelectedLayout,
      selectedIndexLayout,
      selectedLayoutByIndex,
      isSelectedLayoutFullStream,
      layoutFullStream,
      layoutStyle,
      toggleSlotFullStream,
      updateEmitAndSaveSettings,
      isOnlyViewStream,
      updateDataOfStore,
      isViewmodeMixer,
      isViewmodeParticipant,
      mediaControlData,
      addMediaControlData,
      viewmode,
      getMediaControlTracks,
      backgroundMediaData,
      mixerGroup,
      peers,
      amsVideoState,
    } = S.webrtc
    const { enableEditLayout } = S.layout
    const isHoverSlot = useCheckHover(`slot${position}-${layoutIndex}`, [
      'layoutTemplate',
    ])
    const isAms = mediaData?.id ? mediaData?.id.includes(amsMediaPrefix) : false
    const amsPeer = peers.find(p => p.data.viewmode === 'ams-client')
    const isSlotFullStream =
      isSelectedLayoutFullStream &&
      layoutFullStream[selectedIndexLayout] === position
    const refDrop = useRef<HTMLDivElement>(null)
    const dref = useRef<HTMLDivElement>(null)
    const { drag } = useDragSlotMedia({
      item: { ...mediaData, index: '0' },
      dragType: DRAG_MEDIA_SLOT,
      refDrop,
      disableDrag: enableEditLayout,
    })
    const v: Viewmode =
      viewmode === 'mixer' && mixerGroup === 'mixerobserver'
        ? 'robserver'
        : viewmode === 'mixer' || viewmode === 'rparticipant'
          ? 'rhost'
          : viewmode
    const videoControl = mediaControlData.find(
      item =>
        item.mediaId.replace(controlMediaPrefix[v], '') === mediaData.id &&
        item.from === v,
    )

    const isAudioInBackGround = backgroundMediaData.id === mediaData.id
    const isLoading = isAms ? false : Boolean(!videoControl)
    const isSeeking = isAms ? false : Boolean(videoControl?.seeking)
    useEffect(() => {
      if (isLoading) {
        addMediaControlData(
          buildControlMediaId(v, mediaData.id ?? ''),
          mediaData?.url ?? '',
          'video',
        )
      }
    }, [isLoading])

    const videoTrack = isAms
      ? amsPeer?.medias?.[mediaData.id].video?.track
      : getMediaControlTracks(mediaData.id).videoTrack
    const audioTrack = isAms
      ? amsPeer?.medias?.[mediaData.id].audio?.track
      : !isAudioInBackGround
        ? getMediaControlTracks(mediaData.id).audioTrack
        : undefined

    const handleToggleVideo = (media: TMediaDragDropData) => {
      const { value: url, id: mediaId } = media
      if (isViewmodeMixer || isAms) {
        return
      }

      updateDataOfStore({
        videoPreview: {
          mediaId,
          url,
        },
      })
    }

    const onDropEnd = (item: TMediaDragDropData) => {
      if (mediaData.mediaType === 'video' && mediaData.id) {
        handleToggleVideo(item)
      }
    }
    const [{ isOver }, drop] = useDropSlot({
      position,
      selectedLayoutByIndex: selectedLayoutByIndex.defaultId,
      onDropEnd,
    })

    const cssLayoutsChildren = [
      css.CssLayoutsChildren1,
      css.CssLayoutsChildren2,
      css.CssLayoutsChildren3,
      css.CssLayoutsChildren4,
      css.CssLayoutsChildren5,
      css.CssLayoutsChildren6,
      css.CssLayoutsChildren7,
      css.CssLayoutsChildren8,
      css.CssLayoutsChildren9,
      css.CssLayoutsChildren10,
    ]
    const computedMute = isAms
      ? isViewmodeMixer
        ? amsVideoState?.[mediaData.id].muted
        : true
      : (videoControl?.muted || muted) ?? false
    const computedVolume =
      (isAms
        ? isViewmodeMixer
          ? amsVideoState?.[mediaData.id].volume
          : 0
        : videoControl?.volume ?? 100) / 100
    return (
      <div
        id={`slot${position}-${layoutIndex}`}
        className={clsx({
          [css.StyledSlotOnLive]: true,
          [css.styleSlotInvisible]: !isShow,
          [css.styledSlotEmpty]: !isShow,
          [css.styledSlotWithLayoutOther1]:
            computedSelectedLayout.defaultId !== 1,
          [cssLayoutsChildren[computedSelectedLayout.defaultId - 1]]: true,
          [css.styledSlotLayoutStyleModern]: layoutStyle === 'modern',
          [css.styledSlotLayoutStyleRounded]: layoutStyle === 'rounded',
          [css.styledSlotFullStream]:
            isSelectedLayoutFullStream && isSlotFullStream && !enableEditLayout,
        })}
        ref={
          isOnlyViewStream || isViewmodeParticipant
            ? undefined
            : selectedLayoutByIndex.defaultId === 1 || isSlotFullStream
              ? drop
              : // type-coverage:ignore-next-line
                (drag(drop(dref)) as any)
        }
        onDoubleClick={() => {
          if (!isViewmodeParticipant && !isOnlyViewStream) {
            toggleSlotFullStream(position || 0)
            updateEmitAndSaveSettings()
          }
        }}
      >
        {(isLoading || isSeeking) && mediaData.mediaType === 'video' && (
          <div className={css.Loading}>
            <AppSpin />
          </div>
        )}
        <div
          className={clsx({
            [css.DropArea]: true,
            [css.dropAreaOver]: isOver,
            [css.DropAreaRounded]: S.webrtc.layoutStyle === 'rounded',
          })}
          ref={refDrop}
        />
        {mediaData.mediaType === 'image' ? (
          <div className={css.StyledSlotImageContainer}>
            <img draggable={false} src={mediaData.value} style={mediaStyle} />
          </div>
        ) : (
          <Player
            audioTrack={audioTrack}
            muted={computedMute}
            id={mediaData.id}
            showVolumeLevel={false}
            volume={computedVolume}
            videoTrack={videoTrack}
            screenshare={false}
            videoStyle={mediaStyle}
            isSlotSmall={false}
          />
        )}

        {isHoverSlot && isShow && cropMode !== ECropMode.Crop && (
          <SlotIconAction
            position={position || 0}
            slotType='media'
            slotMediaId={mediaData.id}
          />
        )}
      </div>
    )
  },
)

const StyleSlotMedia = observer(
  ({
    media,
    onToggleRepeat,
    mediaStyle,
    isShow,
  }: {
    media: TKeyString
    isLayoutFull?: boolean
    isFullScreen?: boolean
    isViewMode?: boolean
    onToggleRepeat?: (status: boolean) => void
    mediaStyle?: CSSProperties
    isShow?: boolean
  }) => {
    if (media.mediaType === 'video') {
      return (
        <div
          className={clsx({
            [css.StyledSlotVideoContainer]: true,
          })}
        >
          <VideoPlayerSlot
            video={media}
            isShow={isShow}
            onToggleRepeat={onToggleRepeat}
            videoStyle={mediaStyle}
          />
        </div>
      )
    }
    return (
      <div className={css.StyledSlotImageContainer}>
        <img draggable={false} src={media.value} style={mediaStyle} />
      </div>
    )
  },
)
export const StyleSlotMediaPreview = observer(() => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  if (!isDragging) {
    return null
  }

  return (
    <div
      className={css.SlotPreview}
      style={{
        ...getItemPreviewStyles(initialOffset, currentOffset),
        ...S.webrtc.dimensionDragItem,
      }}
    >
      <div
        className={clsx({
          [css.StyledSlot]: true,
          [css.styledSlotWithLayoutOther1]:
            S.webrtc.computedSelectedLayout.defaultId !== 1,
          [css.styledSlotLayoutStyleRounded]:
            S.webrtc.layoutStyle === 'rounded',
          [css.styledSlotLayoutStyleModern]: S.webrtc.layoutStyle === 'modern',
        })}
      >
        <StyleSlotMedia
          media={{
            id: item.id,
            value: item.value,
            mediaType: item.mediaType,
          }}
        />
      </div>
    </div>
  )
})
