import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'
import { useRef } from 'react'
import { useDragLayer } from 'react-dnd'

import css from './Slot.module.scss'

// import { Show } from '../../../components/base/Show'
import { S } from '../../../context/store'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import { DRAG_USER_MAIN } from '../../../context/store/studio/dragTypes'
import { getItemPreviewStyles } from '../../../context/store/studio/utils'
import type { TKeyString } from '../../../context/store/studio/WebrtcStore'
import {
  // getMediaPeerId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { useCheckHover } from '../../../utils/useCheckHover'
import { useDragSlotMain } from '../../../utils/useDrag'
import { useDropSlot } from '../../../utils/useDrop'
import { PeerView } from './MediaView'
import type { TDragPreview } from './RightContent'
// import { SlotIconAction } from './SlotIconAction'
import { SlotMedia } from './SlotMedia'
import { VideoPlayback } from './VideoPlayback'

export const Slot: FC<SmartSlotProps> = observer(
  ({
    peerId,
    position,
    fullscreen,
    totalSlots = [],
    isShow = true,
    cropMode,
    layoutIndex,
    mediaData,
    forceMuted,
  }) => {
    const {
      computedShouldPlayVideoPlayback,
      selectedIndexLayout,
      selectedLayoutByIndex,
      isSelectedLayoutFullStream,
      layoutFullStream,
      computedVideoPlaybackMediaIds,
      layoutStyle,
      fullscreenMediaId,
      isOnlyViewStream,
      typeDraggingItem,
      toggleSlotFullStream,
      updateEmitAndSaveSettings,
      isViewmodeParticipant,
    } = S.webrtc
    const { enableEditLayout } = S.layout
    const isHoverSlot = useCheckHover(`slot${position}-${layoutIndex}`, [
      'layoutTemplate',
    ])
    const refDrop = useRef<HTMLDivElement>(null)
    const screenshare = peerId ? isScreenshareMedia(peerId) : false
    const invisible = totalSlots.find(_ => _.id === position)
      ? false
      : true || !isShow
    const isSlotFullStream =
      isSelectedLayoutFullStream &&
      layoutFullStream[selectedIndexLayout] === position
    const videoPlaybackMediaId =
      position !== undefined && computedVideoPlaybackMediaIds[position]
    const isSlotShow = isShow
    // const mediaPeerId = getMediaPeerId(peerId ?? '')

    // const peer = S.webrtc.getPeer(mediaPeerId)
    // const videoTrack = screenshare
    //   ? peer?.screenshareVideo?.track
    //   : peer?.video?.track
    // const videoEnabled = !!videoTrack

    const videoObjectFit: () => CSSProperties = () => {
      switch (cropMode) {
        case ECropMode.Fit:
          return { objectFit: 'contain' }
        case ECropMode.Crop:
          return {
            objectFit: 'fill',
          }
        default:
          return { objectFit: 'cover' }
      }
    }
    const hasVideoFitMode = !!peerId && cropMode === ECropMode.Fit

    const mediaId2 = videoPlaybackMediaId || peerId
    const dref = useRef<HTMLDivElement>(null)
    const { drag } = useDragSlotMain({
      peerId: mediaId2,
      position,
      type: DRAG_USER_MAIN,
      selectedIndexLayout,
      refDrop,
      disableDrag: enableEditLayout,
    })
    const [{ isOver }, drop] = useDropSlot({
      peerId: mediaId2,
      position,
      selectedLayoutByIndex: selectedLayoutByIndex.defaultId,
      onDropEnd: () => {
        updateEmitAndSaveSettings()
      },
    })

    if (mediaData) {
      return (
        <SlotMedia
          key={mediaData.id}
          isShow={isShow}
          layoutIndex={layoutIndex}
          position={position}
          mediaData={mediaData}
          mediaStyle={{ ...videoObjectFit() }}
          cropMode={cropMode}
          muted={forceMuted || !isShow}
        />
      )
    }
    const transparent =
      screenshare ||
      !!fullscreen ||
      !!videoPlaybackMediaId ||
      isScreenshareMedia(fullscreenMediaId) ||
      hasVideoFitMode
    const isSlotEmpty = !isSlotShow
    const isDraggingItem = typeDraggingItem !== ''
    return (
      <div
        id={`slot${position}-${layoutIndex}`}
        className={clsx({
          [css.StyledSlotOnLive]: true,
          [css.styledSlotWithOtherTransparent]: !transparent && !screenshare,
          [css.styledSlotLayoutStyleRounded]:
            layoutStyle === 'rounded' && !screenshare,
          [css.styledSlotLayoutStyleModern]:
            !screenshare && !isSelectedLayoutFullStream,
          [css.styleSlotInvisible]: invisible,
          [css.styledSlotFullStream]:
            isSelectedLayoutFullStream && isSlotFullStream && !enableEditLayout,
          [css.styledSlotEmpty]: !isSlotShow,
          [css.styledSlotEmptyOtherViewAndDragging]:
            isSlotEmpty && !isDraggingItem && !isOnlyViewStream,
        })}
        ref={
          isViewmodeParticipant || isOnlyViewStream || !isSlotShow
            ? undefined
            : !mediaId2 || isSlotFullStream
              ? drop
              : !drop
                ? drag
                : // type-coverage:ignore-next-line
                  (drag(drop(dref)) as any)
        }
        onDoubleClick={e => {
          e.stopPropagation()
          e.preventDefault()
          if (!isViewmodeParticipant && mediaId2 && !isOnlyViewStream) {
            toggleSlotFullStream(position || 0)
          }
        }}
      >
        <div
          className={clsx({
            [css.DropArea]: true,
            [css.dropAreaOver]: isOver,
            [css.DropAreaRounded]: S.webrtc.layoutStyle === 'rounded',
          })}
          ref={refDrop}
        />
        {peerId ? (
          <PeerView
            isHoverSlot={isHoverSlot}
            peerId={peerId}
            position={position}
            layoutIndex={layoutIndex}
            videoStyle={{ ...videoObjectFit() }}
            cropMode={cropMode}
            isShow={isShow}
            forceMuted={forceMuted}
          />
        ) : (
          <div className={css.slotDivPosition} />
        )}
        {videoPlaybackMediaId && computedShouldPlayVideoPlayback && (
          <VideoPlayback peerId={videoPlaybackMediaId} />
        )}
        {/* <Show>
          <Show.When
            isTrue={Boolean(
              isSlotShow &&
                isHoverSlot &&
                !isViewmodeParticipant &&
                cropMode !== ECropMode.Crop &&
                videoEnabled,
            )}
          >
            <SlotIconAction
              position={position || 0}
              slotType='peer'
              slotPeerId={peerId}
              // isShowFullScreen={!isSlotFullStream}
            />
          </Show.When>
        </Show> */}
      </div>
    )
  },
)

export const StyleSlotPreview = observer(() => {
  const { item, isDragging, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  if (!isDragging) {
    return null
  }
  return (
    <div
      className={css.SlotPreview}
      style={{
        ...getItemPreviewStyles(initialOffset, currentOffset),
        ...S.webrtc.dimensionDragItem,
      }}
    >
      <div
        className={clsx({
          [css.StyledSlot]: true,
          [css.styledSlotWithLayoutOther1]:
            S.webrtc.computedSelectedLayout.defaultId !== 1,
          [css.styledSlotLayoutStyleRounded]:
            S.webrtc.layoutStyle === 'rounded',
          [css.styledSlotLayoutStyleModern]: S.webrtc.layoutStyle === 'modern',
        })}
      >
        {!item.peerId || item.invisible ? null : (
          <PeerView peerId={item.peerId} position={0} forceMuted />
        )}
      </div>
    </div>
  )
})

export type SmartSlotProps = {
  peerId?: string
  mediaData?: TKeyString
  position?: number
  fullscreen?: boolean
  totalSlots?: any[]
  isShow?: boolean
  cropMode?: ECropMode
  layoutIndex?: number
  forceMuted: boolean
}
