import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import type ReactQuill from 'react-quill'

import css from './InsightEngineNote.module.scss'

import { Icon } from '../../../components/base/Icon'
import { QuillEditor } from '../../../components/composites/Editor'
import { routerPaths } from '../../../context/router/routerPaths'
import { S } from '../../../context/store'
import { useDebounce } from '../../../utils/useDebounce'
import { CustomToolbar } from '../../Studio/components/NoteEditor'
import {
  updateResource,
  updateResourceSelected,
} from '../actions/updateResource'

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'div',
  'script',
  'blockquote',
  'code',
  'formula',
  'image-blank',
]
const toolbarId = 'insightEngineQuillToolbar'
const modules = {
  toolbar: {
    container: `#${toolbarId}`,
  },
  clipboard: {
    matchVisual: false,
  },
  addImageCustom: {},
}

export const InsightEngineNote = observer(() => {
  const { resourceSelected } = S.insightEngine

  const hasInit = useRef(false)

  const debouncedValue = useDebounce<string>(resourceSelected?.note ?? '', 1000)
  const quillRef = useRef<ReactQuill | null>(null)

  useEffect(() => {
    if (hasInit.current) {
      updateResource(debouncedValue)
    }
  }, [debouncedValue])

  useEffect(() => {
    const deltas = JSON.parse(resourceSelected?.note || '[]')
    hasInit.current = false
    if (deltas.length > 0 && resourceSelected?.id) {
      quillRef.current?.editor?.setContents(deltas, 'user')
    } else {
      quillRef.current?.getEditor().setText('')
    }
    hasInit.current = true
  }, [resourceSelected?.id])

  useEffect(() => {
    quillRef.current?.editor?.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user' && delta) {
        const note = JSON.stringify(
          quillRef.current?.editor?.getContents().ops ?? [],
        )
        updateResourceSelected({ note })
      }
    })
  }, [])

  return (
    <div className={css.Wrapper}>
      <div className={css.Header}>
        Smart notes
        <Icon
          icon='Up'
          size={16}
          className={clsx({ [css.IconDisable]: true })}
        />
      </div>
      <CustomToolbar
        id={toolbarId}
        key={toolbarId}
        isWarmTheme={location.pathname === routerPaths.STUDIO}
      />
      <div className={css.NoteEditor}>
        <div
          // ref={drop}
          className={css.EditorContent}
        >
          <QuillEditor
            readOnly={!resourceSelected}
            formats={formats}
            modules={modules}
            ref={quillRef}
            placeholder={'Write your notes here...'}
            onKeyDown={e => {
              e.stopPropagation()
            }}
            className={css.QuillEditor}
            theme='snow'
          />
          <div className={clsx(css.EditorContentOverlay)} />
        </div>
      </div>
    </div>
  )
})
