import { useState } from 'react'
import type { DropTargetMonitor, XYCoord } from 'react-dnd'
import { useDrop } from 'react-dnd'

import { S } from '../context/store'
import {
  DRAG_ALLOW_BACKGROUND_LAYOUT,
  DRAG_ALLOW_ONLY_LAYOUT,
  DRAG_ALLOW_SLOT,
  DRAG_ALLOW_USER,
  DRAG_CHAT,
  DRAG_MEDIA_ADD_PLAYLIST,
  DRAG_MEDIA_ALLOW_SLOT,
  DRAG_TITLE,
} from '../context/store/studio/dragTypes'
import type { TMediaDragDropData } from '../context/store/studio/WebrtcStore'
import { buildControlMediaId } from '../context/store/studio/WebrtcStore'
import { dropTitleToLayout } from '../pages/Studio/actions/dropTitleToLayout'
import type {
  TDropSort,
  TDropSortReturn,
  TItemSort,
} from '../pages/Studio/components/RightContent'

export const isSlotAllowDropVideo = (
  layout: number,
  position?: number | undefined,
) => {
  if (layout === 1 || layout === 2 || layout === 8) {
    return true
  }
  if (layout === 5) {
    return position === 0
  }
  return false
}

export const useDropLayout = ({
  onDropEnd,
}: {
  onDropEnd?: (data?: TItemSort) => void
}) =>
  useDrop({
    accept: [...DRAG_ALLOW_ONLY_LAYOUT, ...DRAG_ALLOW_SLOT],
    drop: (dropItem: TItemSort, monitor: DropTargetMonitor) => {
      const { addMediaControlData, viewmode } = S.webrtc
      if (dropItem) {
        addMediaControlData(
          buildControlMediaId(viewmode, dropItem.id ?? ''),
          dropItem.value ?? '',
          'audio',
        )
        S.webrtc.backgroundMediaData.id = dropItem.id
        S.webrtc.backgroundMediaData.url = dropItem.value
      }
      const itemType = monitor.getItemType()?.toString() || ''
      const clientOffset: XYCoord | null = monitor.getClientOffset() || null

      if (!DRAG_ALLOW_ONLY_LAYOUT.includes(itemType)) {
        return
      }
      let value = dropItem.value
      if (itemType === DRAG_CHAT) {
        value = dropItem.id
      }
      if (itemType === DRAG_TITLE) {
        dropTitleToLayout(dropItem.id, clientOffset)
      }
      S.webrtc.updateEmitAndSaveSettings({
        backgroundMediaData: { id: dropItem.id, url: dropItem.value },
      })
      S.webrtc.dropItemToLayout(itemType, value)
      onDropEnd?.(dropItem)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      itemType: monitor.getItemType()?.toString() || '',
    }),
  })

export const useDropBackgroundLayout = ({
  onDropEnd,
}: {
  onDropEnd?: () => void
}) =>
  useDrop({
    accept: DRAG_ALLOW_BACKGROUND_LAYOUT,
    drop: (dropItem: TItemSort, monitor: DropTargetMonitor) => {
      const { addMediaControlData } = S.webrtc
      if (dropItem) {
        addMediaControlData(
          dropItem.id,
          dropItem.value ?? '',
          'video',
          'background',
        )
        S.webrtc.backgroundMediaData.id = dropItem.id
        S.webrtc.backgroundMediaData.url = dropItem.value
      }
      const itemType = monitor.getItemType()?.toString() || ''
      let value = dropItem.value
      if (DRAG_ALLOW_USER.includes(itemType)) {
        value = dropItem.peerId
      }
      S.webrtc.dropItemToLayout(itemType, value)
      S.webrtc.updateEmitAndSaveSettings({
        backgroundMediaData: { id: dropItem.id, url: dropItem.value },
      })
      onDropEnd?.()
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      itemType: monitor.getItemType()?.toString() || '',
    }),
  })

export const useDropSort = ({
  accept,
  index,
  dref,
  onSortEnd,
  direction = 'y',
}: TDropSort): [TDropSortReturn, any] => {
  const [sortBottom, setBottom] = useState(false)
  return useDrop<TItemSort>({
    accept,
    drop: (dropItem, monitor: DropTargetMonitor) => {
      let isBottom = false
      const clientOffset: XYCoord | null = monitor.getClientOffset() || null
      const hoverBoundingRect: DOMRect | null =
        dref.current?.getBoundingClientRect() || null

      if (clientOffset && hoverBoundingRect) {
        if (direction === 'y') {
          const halfHeight = hoverBoundingRect.y + hoverBoundingRect.height / 2
          isBottom = clientOffset.y > halfHeight
        } else {
          const halfWidth = hoverBoundingRect.x + hoverBoundingRect.width / 2
          isBottom = clientOffset.x > halfWidth
        }
      }
      onSortEnd &&
        onSortEnd(parseInt(dropItem.index), isBottom ? +index + 1 : +index)
    },
    hover: (_, monitor: DropTargetMonitor) => {
      const clientOffset: XYCoord | null = monitor.getClientOffset() || null
      const hoverBoundingRect: DOMRect | null =
        dref.current?.getBoundingClientRect() || null
      if (clientOffset && hoverBoundingRect) {
        let onBottom = false
        if (direction === 'y') {
          const halfHeight = hoverBoundingRect.y + hoverBoundingRect.height / 2
          onBottom = clientOffset.y > halfHeight
        } else {
          const halfWidth = hoverBoundingRect.x + hoverBoundingRect.width / 2
          onBottom = clientOffset.x > halfWidth
        }
        if (sortBottom !== onBottom) {
          setBottom(onBottom)
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isBottom: sortBottom,
    }),
  }) as [TDropSortReturn, any]
}

export const useDropSlot = ({
  peerId,
  position,
  onDropEnd,
}: TMediaDragDropData) =>
  useDrop({
    accept: DRAG_ALLOW_SLOT,
    drop: (item: TMediaDragDropData, monitor: DropTargetMonitor) => {
      const to = { peerId, position }

      const itemType = monitor.getItemType()?.toString() || ''
      if (DRAG_MEDIA_ALLOW_SLOT.includes(itemType)) {
        if (item.id === peerId) {
          return
        }
        S.webrtc.handleDragDropMedia(position !== undefined ? position : -1, {
          id: item.id || '',
          mediaType: item.mediaType || '',
          value: item.value || '',
          name: item?.name || '',
        })
        onDropEnd?.(item)
      } else {
        let fromItem: TMediaDragDropData = item
        let toItem: TMediaDragDropData = { peerId, position }
        if (fromItem.peerId === peerId) {
          return
        }
        if (item.position === undefined) {
          fromItem = {
            peerId: item?.peerId || undefined,
            position: item.position,
          }
          toItem = { position: to.position }
        }

        S.webrtc.handleDragDrop(fromItem, toItem)
        onDropEnd?.(item)
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      itemType: monitor.getItemType()?.toString() || '',
    }),
  })

export const useDropSmallSlot = (
  to?: TMediaDragDropData,
  allowType?: string,
  onDrop?: (data: TMediaDragDropData) => void,
) =>
  useDrop({
    accept: allowType || DRAG_ALLOW_USER,
    drop: (fr: TMediaDragDropData) => {
      if (to) {
        if (fr.position) {
          S.webrtc.handleDragDrop(fr, to)
        } else {
          S.webrtc.handleDragDrop(
            { peerId: fr?.id || undefined },
            { position: to.position },
          )
        }
      }
      onDrop?.(fr)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

export const useDropNotAllowMulti = () =>
  useDrop({
    accept: DRAG_MEDIA_ADD_PLAYLIST,
    drop: () => {
      S.webrtc.updateDataOfStore({
        mediaSelectionId: [],
      })
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })
