import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { XYCoord } from 'react-dnd'
import { useDragLayer } from 'react-dnd'
import { useVideo } from 'react-use'

import css from './MediaVideos.module.scss'

import { BeamSlider } from '#HACK_FOR_RN_ONLY/src/components/base/Slider'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import { reactDeploymentEnv } from '#HACK_FOR_RN_ONLY/src/config'
import { volume0 } from '#rn-shared/mediasoup'

import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { callApiUpdateResourceReOrder } from '../../../context/service/callApi'
import { S } from '../../../context/store'
import { DRAG_MEDIA_VIDEO } from '../../../context/store/studio/dragTypes'
import {
  covertDurationToMinutes,
  getItemPreviewStyles,
  getListPosOfIdValue,
} from '../../../context/store/studio/utils'
import type {
  TMediaItem,
  TMediaItemDetail,
} from '../../../context/store/studio/WebrtcStore'
import { controlMediaPrefix } from '../../../context/store/studio/WebrtcStore'
import { CONSTANTS } from '../../../styles/Constants'
import { useDragDefault } from '../../../utils/useDrag'
import { useDropSort } from '../../../utils/useDrop'
import { checkAndGotoInsightEngine } from '../actions/checkAndGotoInsightEngine'
import { handleTranscribe } from '../actions/searchInsightEngineOfResource'
import { playTrack } from '../utils/playTrack'
import { PopoverActionMedia, PopoverDelete } from './MediaPopover'
import type { TDragPreview, TTitleSort } from './RightContent'
import { VideoPlayerControlOffline } from './VideoPlayerControlOffline'
import { VideoPlayerControlOnline } from './VideoPlayerControlOnline'

type TVideos = {
  videos: ReadonlyArray<TMediaItem>
  onSelectMedia: (e: MouseEvent, item: TMediaItem) => void
  onUpdateSetting: (type?: string) => void
  idVideoPlaying: string
}
type TVideo = TMediaItemDetail & {
  idVideoPlaying: string
  type: string
}

export const MediaVideos = observer(
  ({ onUpdateSetting }: { onUpdateSetting: (type?: string) => void }) => {
    const {
      toggleMediaSelectionId,
      updateDataOfStore,
      addInitStateVideoPreview,
      stateVideoPreviewId,
      newStateVideoPreviewId,
      mediaStudio,
      videoPreview,
      updateVideoSessionList,
      videoSessionList,
      isVideoUploading,
    } = S.webrtc

    const onSelectMedia = (e: MouseEvent, item: TMediaItem) => {
      if (isVideoUploading) {
        return
      }
      if (e) {
        if (e.shiftKey) {
          toggleMediaSelectionId(item.id)
          return
        }
      }
      const videoPlaying = videoSessionList.find(v => v.isSelected)

      if (videoPlaying?.mediaId === item.id) {
        return
      }

      const videoSelected = videoSessionList.find(v => v.mediaId === item.id)

      updateVideoSessionList({
        mediaId: item.id,
        name: item.name,
        repeat: item.isRepeat,
        paused: true,
        isSelected: true,
        currentTime: 0,
        duration: item.duration,
        url: item.url,
        volume: videoSelected?.volume ?? 1,
      })

      addInitStateVideoPreview(item.id)
      updateDataOfStore({
        mediaSelectionId: [],
        stateVideoPreviewId: stateVideoPreviewId !== item.id ? item.id : '',
        newStateVideoPreviewId: item.id,
        videoPreview:
          videoPreview?.mediaId !== item.id
            ? {
                mediaId: item.id,

                url: item.url,
              }
            : null,
      })
    }
    if (mediaStudio.video.length === 0) {
      return <p className={css.MediaListNotFound}>There is no video yet</p>
    }

    return (
      <Videos
        videos={mediaStudio.video}
        onSelectMedia={onSelectMedia}
        onUpdateSetting={onUpdateSetting}
        idVideoPlaying={newStateVideoPreviewId}
      />
    )
  },
)

const Videos = observer(
  ({ videos, onSelectMedia, idVideoPlaying, onUpdateSetting }: TVideos) => {
    const {
      deleteFileMedia,
      removeMediasOfLayoutSlot,
      mediaSelectionId,
      updateOrderMedia,
      updateDataOfStore,
      selectedIndexLayout,
      backgroundVideoUrl,
      updateAndEmit,
      typeDraggingItem,
      layoutMedias,
      isOnAirItemOfLayout,
      newStateVideoPreviewId,
      videoPreview,
      backgroundMediaData,
      videoSessionList,
      mediaControlData,
      viewmode,
      removeMediaControlData,
    } = S.webrtc
    const onDelete = async (item: TMediaItem) => {
      try {
        const videoDelete = videoSessionList?.find(v => v.mediaId === item.id)
        const videoBackGroundId = mediaControlData.find(
          m =>
            m.mediaId.replace(controlMediaPrefix[viewmode], '') === item.id &&
            m.type === 'video',
        )?.id
        if (videoBackGroundId) {
          removeMediaControlData(videoBackGroundId)
        }

        if (videoDelete && videoDelete?.isSelected) {
          updateDataOfStore({
            newStateVideoPreviewId: undefined,
          })
        }

        let isUpdateSettings = false
        deleteFileMedia(item.id, 'video')
        if (backgroundVideoUrl === item.value) {
          isUpdateSettings = true
          updateAndEmit({
            backgroundVideoUrl: '',
          })
        }
        const posList = getListPosOfIdValue(item.id, layoutMedias)
        if (posList.length > 0) {
          isUpdateSettings = true
          removeMediasOfLayoutSlot([item.id])
        }
        if (isUpdateSettings) {
          onUpdateSetting?.()
        }
        await reduxStore.context.gql.deleteResourceInSession({ ids: [item.id] })
      } catch (err) {
        ToastService.error({ content: 'Failed to delete record' })
        console.error(err)
      }
    }
    const onSortEnd = async (dropIndex: number, overIndex: number) => {
      if (mediaSelectionId.length <= 1) {
        updateOrderMedia('video', dropIndex, overIndex)
        await callApiUpdateResourceReOrder(
          S.webrtc.mediaStudio.video,
          dropIndex,
          overIndex,
        )
      }
    }
    const onDragEnd = () => {
      if (mediaSelectionId.length > 0) {
        updateDataOfStore({
          mediaSelectionId: [],
        })
      }
    }
    const onTurnOffAir = (item: TMediaItem) => {
      removeMediasOfLayoutSlot([item.id], selectedIndexLayout)
      if (backgroundVideoUrl === item.value) {
        updateAndEmit({
          backgroundVideoUrl: '',
        })
      }
      onUpdateSetting?.()
    }
    const onAir =
      isOnAirItemOfLayout(layoutMedias, newStateVideoPreviewId) ||
      backgroundVideoUrl === videoPreview?.url ||
      backgroundMediaData.id === newStateVideoPreviewId

    return (
      <>
        {newStateVideoPreviewId &&
          (onAir ? (
            <VideoPlayerControlOnline type={DRAG_MEDIA_VIDEO} />
          ) : (
            <VideoPlayerControlOffline
              type={DRAG_MEDIA_VIDEO}
              key={newStateVideoPreviewId}
            />
          ))}
        {videos.map((item, index) => (
          <Video
            type={DRAG_MEDIA_VIDEO}
            media={item}
            onDelete={onDelete}
            key={item.id}
            onSelectMedia={(e: MouseEvent) => onSelectMedia(e, item)}
            index={index.toString()}
            onSortEnd={onSortEnd}
            onDragEnd={onDragEnd}
            dragSelected={mediaSelectionId.includes(item.id)}
            isDraggingSelect={mediaSelectionId.length > 1}
            idVideoPlaying={idVideoPlaying}
            onTurnOffAir={() => onTurnOffAir(item)}
            draggingSelected={
              mediaSelectionId.includes(item.id) &&
              typeDraggingItem === DRAG_MEDIA_VIDEO
            }
            onAir={
              isOnAirItemOfLayout(layoutMedias, item.id) ||
              item.value === backgroundVideoUrl
            }
          />
        ))}

        {typeDraggingItem === DRAG_MEDIA_VIDEO && (
          <VideoPreview totalItem={mediaSelectionId.length} />
        )}
      </>
    )
  },
)

export const Video = observer(
  ({
    media: { value, id, mediaType, name = '', isDefault, duration, resourceId },
    onDelete,
    onSelectMedia,
    index,
    onSortEnd,
    dragSelected,
    draggingSelected,
    isDraggingSelect,
    onAir = false,
    onDragEnd,
    idVideoPlaying,
    type,
  }: TVideo) => {
    const { mediaControlData, videoSessionList, viewmode } = S.webrtc
    const { resources } = S.insightEngine
    const [video, state] = useVideo(
      <video className={css.MediaVideoItemPlayer} src={value} />,
    )

    const [visiblePopoverDel, setPopoverDel] = useState(false)
    const dref = useRef<HTMLDivElement>(null)

    const videoDetail = videoSessionList.find(item => item.mediaId === id)
    const videoControl = mediaControlData.find(
      item => item.mediaId === id && item.from === viewmode,
    )

    const isRepeat = onAir ? videoControl?.repeat : videoDetail?.repeat ?? false
    const [{ isOver, isBottom }, drop] = useDropSort({
      accept: type,
      index,
      dref,
      onSortEnd,
      direction: 'x',
    })
    const handleDelete = () => {
      if (!visiblePopoverDel) {
        setPopoverDel(true)
      }
    }
    duration = duration || state.duration

    const handleInsightEngine = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (!resourceId) {
        ToastService.error({ content: 'Resource not found' })
        return
      }
      if (isTranscribed) {
        ToastService.success({ content: 'Video transcribed.' })
        return
      }
      handleTranscribe(resourceId)
    }
    const handleGotoInsightEngine = () => {
      if (!resourceId) {
        ToastService.error({ content: 'Resource not found' })
        return
      }
      checkAndGotoInsightEngine({ resourceId })
    }

    const { isDragging, drag } = useDragDefault({
      item: { id, index, value, mediaType, name, duration },
      dragType: type,
      onDragEnd,
    })
    drag(drop(dref))
    const isTranscribed = resources.find(r => r.resourceId === resourceId)
    return (
      <div
        ref={dref}
        data-selection={id}
        className={clsx({
          ['media']: true,
          [css.MediaVideoItemContainer]: true,
          [css.MediaVideoItemContainerSelected]: idVideoPlaying == id,
          [css.mediaItemHorizontalContainerDrophover]:
            isOver && !isDraggingSelect,
          [css.mediaItemHorizontalContainerDropbottom]: isBottom,
          [css.mediaItemHorizontalContainerDragSelected]:
            dragSelected && !draggingSelected,
        })}
        onClick={onSelectMedia}
      >
        <PopoverDelete
          visible={visiblePopoverDel}
          onVisibleChange={(status: boolean) => setPopoverDel(status)}
          onCancel={e => {
            e.stopPropagation()
            setPopoverDel(false)
          }}
          onDelete={e => {
            e.stopPropagation()
            setPopoverDel(false)
            onDelete({ value, id, mediaType, isDefault })
          }}
        />
        <div
          className={clsx({
            [css.MediaVideoItemWrapper]: true,
            [css.mediaItemWrapperOnAir]: onAir,
            [css.mediaItemWrapperDragging]: isDragging,
          })}
        >
          {video}
          <Icon icon='icon_play' size={12} className={css.MediaVideoIconPlay} />
          {!duration && <LoadingOutlined className={css.LoadingSocial} />}
        </div>
        <div className={css.MediaVideoItemInfo}>
          <div className={css.MediaVideoItemInfoTitle}>{name}</div>
          <span className={css.MediaVideoItemDuration}>
            {covertDurationToMinutes(duration)}
          </span>
        </div>
        <div className={css.Column}>
          <PopoverActionMedia
            name={name}
            iconClassName={css.MoreIcon}
            url={value}
            onDownload={() => saveAs(value)}
            onDelete={handleDelete}
            onInsight={handleGotoInsightEngine}
          />
          {reactDeploymentEnv === 'dev' && (
            <WarmnessGrayButton
              className={clsx(css.InsightEngineIcon, {
                [css.VideoTranscribed]: isTranscribed,
                [css.DisableButton]: !duration,
              })}
              onClick={handleInsightEngine}
            >
              <Icon
                className={isTranscribed ? css.IconTranscribed : undefined}
                icon='Note'
                size={16}
              />
            </WarmnessGrayButton>
          )}
        </div>

        {onAir && <div className={css.MediaVideoButtonOnAir}>On Air</div>}
        {isRepeat && (
          <span className={css.VideoPlayIsRepeat}>
            <Icon icon='icon_repeat' size={12} />
          </span>
        )}
        {(isDragging || draggingSelected) && (
          <WarmnessGrayButton
            isButton={false}
            className={clsx(css.MediaVideoDragging, {
              [css.MediaItemWrapperPreview]: true,
            })}
          />
        )}
      </div>
    )
  },
)

export const VideoPreview = ({ totalItem = 0 }: { totalItem?: number }) => {
  const { isDragging, item, initialOffset, currentOffset } =
    useDragLayer<TDragPreview>(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))
  const [video] = useVideo(
    <video className={css.MediaVideoItemPlayer} src={item?.value || ''} />,
  )
  if (!isDragging) {
    return null
  }
  const onAir = S.webrtc.isOnAirItemOfLayout(S.webrtc.layoutMedias, item.id)
  if (item.isPreview) {
    return (
      <VideoPlayerControllPreview
        initialOffset={initialOffset}
        currentOffset={currentOffset}
        item={item}
      />
    )
  }
  return (
    <div
      style={getItemPreviewStyles(initialOffset, currentOffset)}
      className={clsx(css.MediaVideoPreview)}
    >
      <div
        className={clsx({
          [css.MediaVideoItemWrapper]: true,
          [css.mediaItemWrapperOnAir]: onAir,
        })}
      >
        {video}
        <Icon icon='icon_play' size={12} className={css.MediaVideoIconPlay} />
      </div>
      <div className={css.MediaVideoItemInfo}>
        <div className={css.MediaVideoItemInfoTitle}>{item.name}</div>
        <span className={css.MediaVideoItemDuration}>
          {covertDurationToMinutes(item.duration)}
        </span>
      </div>
      {onAir && <div className={css.MediaVideoButtonOnAir}>On Air</div>}
      {totalItem > 0 && (
        <div className={css.MediaPreviewTotalItem}>{totalItem}</div>
      )}
    </div>
  )
}

const VideoPlayerControllPreview = observer(
  ({
    item,
    initialOffset,
    currentOffset,
  }: {
    item: TTitleSort
    initialOffset: XYCoord | null
    currentOffset: XYCoord | null
  }) => {
    const { getMediaControlTracks, mediaControlData, videoSessionList } =
      S.webrtc
    const { isLightTheme } = S.local
    const stateVideoPreview = videoSessionList.find(
      itemVideo => itemVideo.isSelected,
    )

    const videoTrack = getMediaControlTracks(item.id).videoTrack
    const onAir = S.webrtc.isOnAirItemOfLayout(S.webrtc.layoutMedias, item.id)
    const media = onAir
      ? mediaControlData.find(
          m =>
            m.mediaId.replace(controlMediaPrefix[S.webrtc.viewmode], '') ===
            item.id,
        )
      : undefined
    const vref = useRef<HTMLVideoElement>(null)

    const volume = onAir ? media?.volume || 0 : stateVideoPreview?.volume || 0
    useEffect(() => {
      if (onAir && videoTrack) {
        playTrack(vref.current, videoTrack)
      }
    }, [onAir, videoTrack])

    const videoWithTrack = (
      <video ref={vref} className={css.MediaVideoItemPlayer} />
    )

    const [video, state, controls] = useVideo(
      <video className={css.MediaVideoItemPlayer} src={item?.value || ''} />,
    )
    useEffect(() => {
      controls.seek(stateVideoPreview?.currentTime ?? 0)
    }, [stateVideoPreview?.currentTime])

    return (
      <div
        style={getItemPreviewStyles(initialOffset, currentOffset)}
        className={css.VideoPreviewContainer}
      >
        {onAir ? videoWithTrack : video}
        <BeamSlider
          className={css.VideoPlayerSlider}
          min={0}
          max={onAir ? media?.duration : state.duration}
          step={0.1}
          value={onAir ? media?.currentTime : stateVideoPreview?.currentTime}
          tooltipVisible={false}
          trackStyle={{
            backgroundColor: isLightTheme
              ? '#2656C9'
              : CONSTANTS.level.lighter1,
            borderRadius: 4,
          }}
          handleStyle={{
            backgroundColor: isLightTheme
              ? CONSTANTS.basicColor.white
              : CONSTANTS.text.level1,
            border: 'none',
            boxShadow: isLightTheme
              ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
              : 'none',
            width: 14,
            height: 14,
          }}
        />
        <div className={css.VideoPlayerController}>
          <div className={css.VideoPlayerVolume}>
            <span className={css.VideoPlayerMute}>
              <Icon
                icon={
                  volume <= volume0
                    ? 'icon_volumn_silent'
                    : volume < 0.5
                      ? 'icon_volumn_medium'
                      : 'icon_volumn_max'
                }
                size={16}
              />
            </span>
            <BeamSlider
              className={css.VideoPlayerVolumeSwitch}
              min={0}
              max={100}
              step={1}
              value={volume}
              tooltipVisible={false}
              trackStyle={{
                backgroundColor: isLightTheme
                  ? '#2656C9'
                  : CONSTANTS.level.lighter1,
                borderRadius: 4,
              }}
              handleStyle={{
                backgroundColor: isLightTheme
                  ? CONSTANTS.basicColor.white
                  : CONSTANTS.text.level1,
                border: 'none',
                boxShadow: isLightTheme
                  ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                  : 'none',
                width: 14,
                height: 14,
              }}
            />
          </div>
          <span className={css.VideoPlayerIconPlay}>
            <Icon
              icon={
                (onAir ? media?.paused : stateVideoPreview?.paused)
                  ? 'icon_play'
                  : 'icon_pause'
              }
              size={24}
            />
          </span>
          <div className={css.VideoPlayerInfo}>
            <span
              className={clsx({
                [css.VideoPlayRepeat]: true,
              })}
            >
              <Icon icon='Fullscreen' size={12} />
            </span>
            <span
              className={clsx({
                [css.VideoPlayRepeat]: true,
                [css.VideoPlayIsRepeatPreViewControl]: Boolean(
                  stateVideoPreview?.repeat,
                ),
              })}
            >
              <Icon icon='icon_repeat' size={12} />
            </span>
            <span
              className={clsx({
                [css.VideoPlayRepeat]: true,
              })}
            >
              <Icon icon='icon_three_dots' size={12} />
            </span>
          </div>
        </div>
        <div className={css.VideoPlayerName}>{item.name}</div>
        <div className={css.VideoPlayerOtherInfo}>
          <div className={css.VideoPlayerDuration}>
            {covertDurationToMinutes(stateVideoPreview?.currentTime)} /{' '}
            {covertDurationToMinutes(item.duration || state.duration)}
          </div>
          {onAir && <div className={css.VideoPlayerOnAir}>On Air</div>}
        </div>
      </div>
    )
  },
)
