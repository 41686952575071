import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import css from './LiveStreamCustomSlot.module.scss'

import { Icon } from '../../../components/base/Icon'
import { addSlot } from '../../../context/actions/layout/addSlot'
import { addSlotsToGroup } from '../../../context/actions/layout/addSlotsToGroup'
import { changeSlotEditing } from '../../../context/actions/layout/changeSlotEditing'
import { clearGroupSlot } from '../../../context/actions/layout/clearGroupSlot'
import { forwardSlotInEditList } from '../../../context/actions/layout/forwardSlotInEditList'
import { removeSlot } from '../../../context/actions/layout/removeSlot'
import { setIsDragging } from '../../../context/actions/layout/setIsDragging'
import { updateSlotToEdit } from '../../../context/actions/layout/updateSlotToEdit'
import { Resizeable } from '../../../context/shared/components/CustomLayoutResizeable'
import type { IActionOutsize } from '../../../context/shared/utils/CustomLayoutType'
import { EShapeType } from '../../../context/shared/utils/CustomLayoutType'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutSize,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'
import { ECropMode } from '../../../context/store/shared/LayoutStore'
import { getIdNumber } from '../../../context/store/studio/helper'
import {
  getMediaPeerId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { getLayoutRatio } from '../actions/getLayoutRatio'
import { isInputPeer } from '../utils/isInputPeer'
import { CustomLayoutCropMode } from './LiveStreamCustomLayoutCrop'
import { calculateUpdateSlot } from './UploadTranscript/calculateSlot'

export const SlotCustom: FC<{
  layout: ILayoutItem
  index: number
  containerProps: ILayoutSize
  children?: React.ReactNode
  isEdit?: boolean
  isSmall?: boolean
  parentId: number
  isBelongEditing?: boolean
  isLayoutSelected?: boolean
  slotsOtherEditing: ILayoutItem[]
  onChangeSize?: (w: number, h: number) => void
  handleMouseHover?(layout: ILayoutItem | null): void
  peerId?: string
}> = observer(
  ({
    layout,
    handleMouseHover,
    containerProps,
    children,
    isEdit,
    isSmall,
    parentId,
    isBelongEditing,
    slotsOtherEditing,
    onChangeSize,
    peerId,
  }) => {
    const { slotsEdit, isDragging, enableEditLayout, slotEditing } = S.layout
    const {
      layoutFullStream,
      selectedIndexLayout,
      removePeersOnSlot,
      layoutMedias,
      removeMediasOfLayoutSlot,
      computedSelectedMediaIds,
    } = S.webrtc
    const isSlotFullStream =
      layoutFullStream[selectedIndexLayout] === layout.id &&
      layout.layoutIndex === selectedIndexLayout
    const layoutSelected = slotsEdit[parentId] ?? []
    const isCropMode = layout.cropMode === ECropMode.Crop
    const layoutRef = useRef(layout)
    const isHover = useRef(false)
    const dataLayoutMedia =
      layout.id !== undefined ? layoutMedias[selectedIndexLayout] : null
    const mediaData =
      dataLayoutMedia && layout.id !== undefined
        ? dataLayoutMedia[layout.id]
        : null
    const mediaPeerId = getMediaPeerId(layout?.peerId ?? '')
    const peer = S.webrtc.getPeer(mediaPeerId)
    const screenshare = isScreenshareMedia(layout?.peerId ?? '')
    const videoTrack = screenshare
      ? peer?.screenshareVideo?.track
      : peer?.video?.track
    const videoEnabled = !!videoTrack

    const { ratioLW } = getLayoutRatio()
    const zIndex = layout.zIndex
    const setSlotStatePartial = (state: ILayoutItem) => {
      updateSlotToEdit(
        layout.id,
        {
          ...layout,
          ...calculateUpdateSlot({
            height: state.height,
            width: state.width,
            top: state.top,
            left: state.left,
          }),
          radius: state.radius,
          zIndex: state.zIndex,
        },
        parentId,
      )
    }

    useEffect(() => {
      layoutRef.current = layout
    }, [layout])
    const addSlotEdit = (e: React.MouseEvent) => {
      e.stopPropagation()
      const useMultiPleResize = layoutSelected.filter(i => i.inGroup).length > 1
      if (isEdit) {
        if (e.shiftKey) {
          if (slotEditing) {
            changeSlotEditing(-1, parentId)
            addSlotsToGroup([layout.id, slotEditing.id], parentId)
            return
          } else {
            if (!useMultiPleResize) {
              changeSlotEditing(layout.id, parentId)
            }

            addSlotsToGroup([layout.id], parentId)
            return
          }
        }

        clearGroupSlot(parentId + '')

        if (layout.id !== slotEditing?.id) {
          changeSlotEditing(layout.id, parentId)
        }
      }
    }

    const onRemoveSlot = () => {
      const peerIds = computedSelectedMediaIds as any
      if (mediaData) {
        removeMediasOfLayoutSlot([mediaData.id], selectedIndexLayout)
      }
      if (peerIds[layout.id]) {
        removePeersOnSlot([layout.id])
      }

      removeSlot(layout.id, parentId)
    }
    const changeIndex = useCallback(
      (i: number) => {
        const newIndex = zIndex + i
        if (newIndex > 0 && newIndex <= layoutSelected.length + 1) {
          forwardSlotInEditList(layout.id, newIndex, parentId)
        }
      },
      [layoutSelected, enableEditLayout, zIndex],
    )

    const addNewSlot = useCallback(() => {
      if (layout) {
        const ids = layoutSelected.map(l => l.id)
        const newId = Math.floor(Math.random() * (layoutSelected.length + 2))
        const isMaxRight =
          containerProps.width - (layout.left + layout.width + 48) < 2
        const isMaxTop =
          containerProps.height - (layout.top + layout.height + 48) < 2

        const newSlot: ILayoutToEdit = {
          id: getIdNumber(newId, ids),
          ...calculateUpdateSlot({
            height: layout.height,
            left: isMaxRight ? layout.left - 48 : layout.left + 48,
            top: isMaxTop ? layout.top - 48 : layout.top + 48,
            width: layout.width,
          }),
          radius: layout.radius,
          zIndex: layoutSelected.length + 1,
          enableEdit: true,
          inGroup: false,
          parentId,
          cropMode: ECropMode.Fill,
          layoutIndex: layout.layoutIndex,
        }
        addSlot(newSlot, parentId)
      }
    }, [layoutSelected, enableEditLayout])

    const handleLongPress = useCallback(
      (e: any) => {
        e.stopPropagation()
        if (isEdit) {
          addSlotEdit(e)
        }
      },
      [isEdit, isDragging, layoutSelected, enableEditLayout, slotEditing],
    )

    const handleShapeChange = useCallback(
      (shape: EShapeType) => {
        const maxLeft = containerProps.width
        const maxTop = containerProps.height
        const calculatorDiameter = () => {
          let diameter = (layout.width + layout.height) / 2
          const left = layout.left
          let right = layout.left + diameter
          const top = layout.top
          let bottom = layout.top + diameter
          if (right > maxLeft) {
            right = maxLeft
            diameter = right - left
          }
          if (bottom > maxTop) {
            bottom = maxTop
            diameter = bottom - top
          }
          return diameter
        }

        const calculate169 = (w: number, h: number) => {
          let top = layout.top
          const bottom = top + h
          if (bottom > maxTop) {
            if (h <= containerProps.height) {
              top = containerProps.height - h
            }
          }

          return { top }
        }

        const calculatorRectangle = () => {
          if (layout.width === (9 * layout.height) / 16) {
            return {
              newWidth: layout.width,
              newHeight: layout.height,
              top: layout.top,
            }
          }
          const newWidth = layout.width
          const newHeight = (newWidth * 9) / 16
          const { top } = calculate169(newWidth, newHeight)
          return { top, newWidth, newHeight }
        }

        switch (shape) {
          case EShapeType.CIRCLE:
            updateSlotToEdit(
              layout.id,
              {
                ...layout,
                ...calculateUpdateSlot({
                  height: calculatorDiameter(),
                  width: calculatorDiameter(),
                  top: layout.top,
                  left: layout.left,
                }),
                radius: 50,
                // cropMode: layout.cropMode === ECropMode.Fit ? ECropMode.Fit : ECropMode.Fill,
                cropData: {
                  ...calculateUpdateSlot({
                    top: layout.cropData?.top ?? 0,
                    left: layout.cropData?.left ?? 0,
                    height: layout.cropData?.height ?? 0,
                    width: layout.cropData?.width ?? 0,
                  }),
                },
              },
              parentId,
            )
            break
          case EShapeType.ROUNDED_RECTANGLE:
            const { top, newWidth, newHeight } = calculatorRectangle()
            const radius =
              ((newWidth + newHeight) / 16 / (newWidth + newHeight)) * 100

            updateSlotToEdit(
              layout.id,
              {
                ...layout,
                ...calculateUpdateSlot({
                  height: newHeight,
                  width: newWidth,
                  top,
                  left: layout.left,
                }),
                radius,
                // cropMode: layout.cropMode === ECropMode.Fit ? ECropMode.Fit : ECropMode.Fill,
                cropData: {
                  ...calculateUpdateSlot({
                    top: top ?? 0,
                    left: layout?.left ?? 0,
                    height: newHeight ?? 0,
                    width: newWidth ?? 0,
                  }),
                },
              },
              parentId,
            )
            break
          case EShapeType.SQUARE:
            updateSlotToEdit(
              layout.id,
              {
                ...layout,
                ...calculateUpdateSlot({
                  height: calculatorDiameter(),
                  width: calculatorDiameter(),
                  top: layout.top,
                  left: layout.left,
                }),
                radius: 0,
                // cropMode: layout.cropMode === ECropMode.Fit ? ECropMode.Fit : ECropMode.Fill,
                cropData: {
                  ...calculateUpdateSlot({
                    top: layout.cropData?.top ?? 0,
                    left: layout.cropData?.left ?? 0,
                    height: layout.cropData?.height ?? 0,
                    width: layout.cropData?.width ?? 0,
                  }),
                },
              },
              parentId,
            )
            break
          default:
            break
        }
      },
      [containerProps.width, containerProps.height, layout, enableEditLayout],
    )
    const defaultSize = 16
    const actionsTop: IActionOutsize[] = [
      ...(peerId || !!mediaData
        ? [
            {
              onAction: () => {
                updateSlotToEdit(
                  layout.id,
                  {
                    isShowCropMode: true,
                  },
                  parentId,
                )
              },
              element: (
                <Icon icon='icon_crop' size={18} className={css.SlotTopIcon} />
              ),
            },
          ]
        : []),
      {
        onAction: () => changeIndex(1),
        element: (
          <Icon
            icon='BringForward'
            size={defaultSize}
            className={css.SlotTopIcon}
          />
        ),
      },
      {
        onAction: () => changeIndex(-1),
        element: (
          <Icon
            icon='BringBackward'
            size={defaultSize}
            className={css.SlotTopIcon}
          />
        ),
      },
      {
        onAction: addNewSlot,
        element: (
          <Icon icon='Plus' size={defaultSize} className={css.SlotTopIcon} />
        ),
      },
      {
        onAction: onRemoveSlot,
        element: (
          <Icon icon='icon_close_popup' size={13} className={css.SlotTopIcon} />
        ),
      },
    ]
    const actionsLeft: IActionOutsize[] = [
      {
        onAction: () => handleShapeChange(EShapeType.CIRCLE),
        element: (
          <Icon icon='Circle' size={defaultSize} className={css.SlotTopIcon} />
        ),
      },
      {
        onAction: () => handleShapeChange(EShapeType.ROUNDED_RECTANGLE),
        element: (
          <Icon
            icon='RoundedRectangle'
            size={defaultSize}
            className={css.SlotTopIcon}
          />
        ),
      },
      {
        onAction: () => handleShapeChange(EShapeType.SQUARE),
        element: (
          <Icon icon='Square' size={defaultSize} className={css.SlotTopIcon} />
        ),
      },
    ]
    const handleOnMouseEnter = (e: React.MouseEvent) => {
      isHover.current = true
      if (e.altKey && enableEditLayout && isBelongEditing) {
        handleMouseHover?.(layout)
      }
    }
    const handleOnMouseLeave = (e: React.MouseEvent) => {
      isHover.current = false
    }

    const handleOnKeyDown = (e: KeyboardEvent) => {
      if (e.repeat) {
        return
      }
      if (
        isHover.current &&
        e.key === 'Alt' &&
        enableEditLayout &&
        isBelongEditing
      ) {
        handleMouseHover?.(layout)
      }
    }
    const handleKeyUp = () => {
      handleMouseHover?.(null)
    }
    const matchEditing =
      `${layout.id}-${layout.parentId}-${layout.layoutIndex}` ===
      `${slotEditing?.id}-${slotEditing?.parentId}-${slotEditing?.layoutIndex}`
    return isCropMode &&
      ((!!peerId && (videoEnabled || isInputPeer(peerId))) || mediaData) ? (
      <CustomLayoutCropMode
        layout={layout}
        index={zIndex}
        containerProps={containerProps}
        parentId={parentId}
        onLongPressLayout={handleLongPress}
        actionsTop={actionsTop}
        actionsLeft={actionsLeft}
        isFullScreen={isSlotFullStream && !isSmall && !isEdit}
        isEdit={isEdit && matchEditing}
        mediaData={mediaData}
        onKeyDown={handleOnKeyDown}
        onKeyUp={handleKeyUp}
        onMouseLeave={handleOnMouseLeave}
        onMouseEnter={handleOnMouseEnter}
        ratio={ratioLW}
      />
    ) : (
      <Resizeable
        containerProps={containerProps}
        layout={layout}
        key={layout.key}
        showControl={layout.showControl}
        outSizeId={layout.key}
        index={zIndex}
        isEditMode={isEdit}
        isEdit={isEdit && matchEditing}
        onLongPresLayout={handleLongPress}
        isFullScreen={isSlotFullStream && !isSmall && !isEdit}
        onChange={setSlotStatePartial}
        changeDragging={setIsDragging}
        onChangeSize={onChangeSize}
        enableEditLayout={enableEditLayout}
        actionsTop={actionsTop}
        actionsLeft={actionsLeft}
        onKeyDown={handleOnKeyDown}
        slotsOtherEditing={slotsOtherEditing}
        onKeyUp={handleKeyUp}
        ratio={ratioLW}
        onMouseLeave={handleOnMouseLeave}
        onMouseEnter={handleOnMouseEnter}
        id='SlotCustom'
      >
        {layout.children}
      </Resizeable>
    )
  },
)
