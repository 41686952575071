import moment from 'moment'

import { serverTime } from '../context/store/shared/serverTime'

export const formatDuration = (time?: Date | number) => {
  const now = serverTime.moment()
  if (!time) {
    return '00:00'
  }
  let ss = Math.floor(moment.duration(now.diff(time)).asSeconds())
  const mm = Math.floor(ss / 60)
  ss = ss % 60
  const mmStr = (mm < 10 ? '0' : '') + `${mm}`
  const ssStr = (ss < 10 ? '0' : '') + `${ss}`
  return `${mmStr}:${ssStr}`
}
