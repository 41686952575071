import { ulid } from 'ulidx'

import { addSlot } from '../../../context/actions/layout/addSlot'
import { setEnableEditLayout } from '../../../context/actions/layout/setEnableEditLayout'
import { S } from '../../../context/store'
import type {
  ILayoutItem,
  ILayoutToEdit,
} from '../../../context/store/shared/LayoutStore'
import { getIdNumber } from '../../../context/store/studio/helper'
import type { TitleItem } from '../../../context/store/studio/WebrtcStore'
import { vh, vw } from '../../../context/store/studio/WebrtcStore'
import { getLayoutRatio } from './getLayoutRatio'

export const addTitle = (titleId?: string) => {
  const { slotsEdit, enableEditLayout, titlesOnEdit } = S.layout
  const {
    selectedLayoutByIndex,
    selectedIndexLayout,
    selectedListLayouts,
    titles,
  } = S.webrtc
  const { ratioLW, ratioTH } = getLayoutRatio()
  const maxLeft = vw * ratioLW
  const maxTop = vh * ratioTH
  const layoutSelected = slotsEdit[selectedLayoutByIndex.defaultId] ?? []
  const $ = S.webrtc
  const $1 = S.layout
  let item: TitleItem | undefined = {
    id: ulid(),
    content: 'Line One Text Line Two Text ',
    contentOnAir: {
      ops: [
        {
          insert: 'Line One Text',
          attributes: {
            color: '#ffffff',
            size: '24px',
            font: 'Poppins',
          },
        },
        {
          attributes: {
            indent: 3,
          },
          insert: '\n',
        },
        {
          insert: 'Line Two Text',
          attributes: {
            color: '#ffffff',
            size: '18px',
            font: 'Poppins',
          },
        },
        {
          attributes: {
            indent: 3,
          },
          insert: '\n',
        },
      ],
    },
    style: {
      autoHeight: true,
      font: 'Poppins',
      fontSize: 18,
      color: '#ffffff',
      backgroundColor: '#006dcc',
    },
    type: 'title',
  }
  const listTitleData = enableEditLayout ? titlesOnEdit : titles

  if (titleId) {
    const ts = listTitleData.find(t => t.id === titleId)
    if (!ts) {
      return
    }
    item = { ...ts, id: ulid() }
  }
  if (!item) {
    return
  }
  const newListData = [item, ...listTitleData]
  const ids = layoutSelected.map(l => l.id)
  const newId = Math.floor(Math.random() * (layoutSelected.length + 2))
  const sls = enableEditLayout
    ? slotsEdit[selectedLayoutByIndex.defaultId]
    : selectedListLayouts.reduce(
        (acc, curr) => [...(acc as any), ...curr.slots] as any,
        [],
      )
  const position = {
    width: maxLeft / ratioLW,
    height: 200,
    top: (maxTop - 138) / ratioTH,
    left: 0,
  }

  let slot: ILayoutItem = {
    id: getIdNumber(newId, ids),
    ...position,
    radius: 0,
    zIndex: layoutSelected.length + 2,
    titleId: item.id,
    enableEdit: true,
    inGroup: false,
    parentId: selectedLayoutByIndex.defaultId,
    layoutIndex: selectedIndexLayout,
  }

  if (titleId) {
    const sl: ILayoutToEdit = sls.find(s => s.titleId === titleId)
    let top = sl.top + 48 / ratioTH
    let left = sl.left + 48 / ratioLW
    if (top * ratioTH + sl.height * ratioTH >= maxTop) {
      top = sl.top - 48 / ratioTH
    }
    if (left * ratioLW + sl.width * ratioLW >= maxLeft) {
      left = sl.left - 48 / ratioLW
    }
    if (sl.width * ratioLW === maxLeft) {
      left = sl.left
    }
    if (sl.top * ratioTH === maxTop) {
      top = sl.top
    }
    slot = {
      ...sl,
      id: getIdNumber(newId, ids),
      parentId: selectedLayoutByIndex.defaultId,
      layoutIndex: selectedIndexLayout,
      zIndex: layoutSelected.length + 2,
      enableEdit: true,
      titleId: item.id,
      top,
      left,
    }
  }

  addSlot(slot, selectedLayoutByIndex.defaultId)
  const layoutsToEdit = selectedListLayouts.map(l => {
    const ls = $1.slotsEdit[l.defaultId]
    if (ls) {
      return {
        ...l,
        slots: ls.map(s => ({
          id: s.id,
          height: s.height,
          width: s.width,
          left: s.left,
          top: s.top,
          radius: s.radius,
          zIndex: s.zIndex,
          cropMode: s.cropMode,
          cropData: s.cropData,
          titleId: s.titleId,
        })),
      }
    } else {
      return l
    }
  })

  const titlesByLayout = $.titlesOnAir[selectedIndexLayout] ?? []
  $.titlesOnAir[selectedIndexLayout] = [...titlesByLayout, item.id]
  S.webrtc.updateEmitAndSaveSettings({
    selectedListLayouts: layoutsToEdit,
    titles: newListData,
    titlesOnAir: $.titlesOnAir,
  })
  setEnableEditLayout(true)
}
