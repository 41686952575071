import clsx from 'clsx'
import type { CSSProperties, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import css from './InviteButton.module.scss'

import { ToastService } from '../../../components/widget/Toast'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { searchUserInSection } from '../../../pages/Studio/actions/searchUserInSession'
import { S } from '../../store'
import { modalObserver } from '../../store/shared/ModalStore'
import { ModalInviteNew } from './ModalInviteUserNew'

type Props = {
  children: ReactNode
  type?: string
  onMouseMove?: () => void
  onMouseOut?: () => void
  style?: CSSProperties
}
export const InviteButton = ({ children, style, ...p }: Props) => {
  const [isShowModal, setShowModal] = useState(false)
  const [userRole, setUserRole] = useState('')
  const { id: userId } = S.profile.profile
  const sessionId = S.webrtc.sessionId

  const onClick = () => {
    setShowModal(true)
  }

  const onSuccessInvite = (total: number) => {
    setShowModal(false)
    ToastService.success({
      content: `Great! ${total} invitation${total > 0 ? 's' : ''} sent`,
    })
  }

  useEffect(() => {
    searchUserInSection(sessionId, undefined, userId).then(res => {
      if (res.data?.searchUserInSession) {
        setUserRole(res.data.searchUserInSession[0].role)
      }
    })
  }, [])
  useEffect(() => {
    modalObserver.hasAnyModal = isShowModal
  }, [isShowModal])
  return (
    <>
      <WarmnessGrayButton
        className={clsx({
          [css.LiveControlInvite]: !p.type,
          [css.liveControlInviteIcon]: p.type === 'icon',
        })}
        onClick={onClick}
        style={style}
      >
        {children}
      </WarmnessGrayButton>
      {isShowModal && (
        <ModalInviteNew
          visible={isShowModal}
          handleClose={() => setShowModal(false)}
          onSuccessInvite={onSuccessInvite}
          role={userRole}
        />
      )}
    </>
  )
}
