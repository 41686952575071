import { observer } from 'mobx-react-lite'

import css from './BlurListBottom.module.scss'

import { Map } from '../../../components/base/Map'
import { AppSpin } from '../../../components/base/Spin'
import { S } from '../../../context/store'
import type {
  IBlurItem,
  IMuteItem,
} from '../../../context/store/insight-engine/InsightEngineStore'
import { AddBlurItem, BlurItemBottom } from './BlurItemBottom'

export const BlurListBottom = observer(() => {
  const {
    mediaPlayer: { duration },
    blurBlocks,
    muteTimelines,
  } = S.insightEngine
  return (
    <div className={css.Container}>
      <AddBlurItem />

      {duration === undefined ? (
        <div className={css.Loading}>
          <AppSpin />
        </div>
      ) : (
        <>
          <Map<IBlurItem>
            list={blurBlocks}
            renderItem={(block, index) => (
              <BlurItemBottom block={block} key={block.id} index={index} />
            )}
          />
          <Map<IMuteItem>
            list={muteTimelines}
            renderItem={(block, index) => (
              <BlurItemBottom block={block} key={block.id} index={index} />
            )}
          />
        </>
      )}
    </div>
  )
})
