import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import css from './GraphicColorPicker.module.scss'

import { WarmnessDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessDiv'

import { Icon } from '../../../components/base/Icon'
import { Show } from '../../../components/base/Show'
import { S } from '../../../context/store'
import { COLOR_SOLID_DEFAULT } from '../../../context/store/studio/WebrtcStore'
import { GrandientPickerMain } from './GraphicGradientPicker'
import { SolidPicker } from './GraphicSolidPicker'

type TContentPicker = {
  defaultColor?: string
  onSelectColor: (color: string) => void
  onAddColor?: () => void
  isLightTheme?: boolean
  id?: string
}

export const ColorPicker = observer(
  ({
    onSelectColor,
    onAddColor,
    defaultColor = '',
    isLightTheme,
    id,
  }: TContentPicker) => {
    const { warmnessColor } = S.local
    const isGradientColor = defaultColor.includes('gradient')
    const [tabPicker, setTabPicker] = useState(isGradientColor ? 2 : 1)
    const [selectedColor, setColor] = useState(
      defaultColor !== '' && !isGradientColor
        ? defaultColor
        : COLOR_SOLID_DEFAULT,
    )
    const onSelectType = (tab: number) => {
      if (tab === tabPicker) {
        return
      }
      if (tab === 1) {
        setColor(COLOR_SOLID_DEFAULT)
        onSelectColor(COLOR_SOLID_DEFAULT)
      }
      setTabPicker(tab)
    }
    const onChangeColor = (color: string) => {
      setColor(color)
      if (tabPicker === 1) {
        onSelectColor(color)
      }
    }

    return (
      <div
        id={id || 'colorPicker'}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
      >
        <div className={css.ColorPickerHeader}>
          <WarmnessDiv
            className={clsx({
              [css.ColorPickerTab]: true,
              [css.ColorPickerTabLight]: isLightTheme,
            })}
          >
            <div
              style={{
                background: tabPicker === 1 ? warmnessColor : undefined,
              }}
              className={clsx({
                [css.ColorPickerType]: true,
                [css.colorPickerTypeActive]: tabPicker === 1,
                [css.colorPickerTypeActiveLight]:
                  tabPicker === 1 && isLightTheme,
              })}
              // active={tabPicker === 1}
              onClick={() => onSelectType(1)}
            >
              Solid
            </div>
            <div
              style={{
                background: tabPicker === 2 ? warmnessColor : undefined,
              }}
              className={clsx({
                [css.ColorPickerType]: true,
                [css.colorPickerTypeActive]: tabPicker === 2,
                [css.colorPickerTypeActiveLight]:
                  tabPicker === 2 && isLightTheme,
              })}
              // active={tabPicker === 2}
              onClick={() => onSelectType(2)}
            >
              Gradient
            </div>
          </WarmnessDiv>
          <Show>
            <Show.When isTrue={Boolean(onAddColor)}>
              <span
                className={clsx({
                  [css.ColorPickerAdd]: true,
                  [css.ColorPickerAddLight]: isLightTheme,
                })}
                onClick={() => onAddColor && onAddColor()}
              >
                <Icon icon='Plus' size={13} />
              </span>
            </Show.When>
          </Show>
        </div>
        {tabPicker === 1 ? (
          <SolidPicker
            isLightTheme={isLightTheme}
            selectedColor={selectedColor}
            onChangeColor={onChangeColor}
          />
        ) : (
          <GrandientPickerMain
            isLightTheme={isLightTheme}
            onSelectColor={onSelectColor}
            defaultColor={defaultColor}
          />
        )}
      </div>
    )
  },
)
