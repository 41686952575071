import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { getLocationData } from './getLocationData'

export const searchChatbotMsg = async () => {
  const $ = S.insightEngine
  const { insightEngineId } = getLocationData()

  const type =
    $.chatbotContexts.find(r => r.name === 'insight-engine')?.name || 'main'
  const { data } = await reduxStore.context.gql.searchChatbotMsg(
    {
      type,
      order: ['createdAt_asc'],
      filter: { insightEngineId },
      page: { limit: 1000 },
    },
    { requestPolicy: 'network-only' },
  )

  $.searchChatbotMsg.chatbotMessages = data?.searchChatbotMsg
}
