import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { CSSProperties, FC } from 'react'

import css from './style.module.scss'

import { S } from '../../../context/store'
import type { IProps } from './type'

export const WarmnessGrayButton: FC<IProps> = observer(
  ({ className, children, isButton = true, useGray = true, ...divProps }) => {
    const { warmnessColorGray, warmnessColorHover, warmnessColorPressed } =
      S.local
    const customStyle = (
      useGray
        ? {
            // @ts-ignore
            '--grey8': warmnessColorGray,
            '--background12': warmnessColorHover,
            '--background19': warmnessColorPressed,
            '--button-grey': warmnessColorGray,
            '--button-grey-hover': warmnessColorHover,
            '--button-grey-active': warmnessColorPressed,
          }
        : undefined
    ) as CSSProperties
    return (
      <div
        {...divProps}
        style={{
          ...divProps.style,
          ...customStyle,
        }}
        className={clsx([
          css.Wrapper,
          { [css.WrapperNotButton]: !isButton },
          className,
        ])}
      >
        {children}
      </div>
    )
  },
)
