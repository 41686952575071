import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import css from './SessionTitle.module.scss'

import { BeamTooltip } from '#HACK_FOR_RN_ONLY/src/components/base/Tooltip'

import { BeamDropdown } from '../../../components/base/Dropdown'
import { Icon } from '../../../components/base/Icon'
import { ToastService } from '../../../components/widget/Toast'
import { RouterHistory } from '../../router/RouterHistory'
import { routerPaths } from '../../router/routerPaths'
import { S } from '../../store'
import type { TUpdateRoom } from '../../store/studio/WebrtcStore'
import { ModalUpdateInfo } from './ModalUpdateRoom'
import { QrScannerModal } from './QrScannerModal'

interface IProps {
  backHref: string
  titleContainerClassName?: string
  titleClass?: string
  showEditTitle?: boolean
}

export const SessionTitle: FC<IProps> = observer(
  ({ backHref, titleClass, titleContainerClassName, showEditTitle = true }) => {
    const {
      webrtc: { statusUpdateSettings, roomDescription, roomTitle },
    } = S
    const {
      local: { warmnessColorGray },
    } = S
    const [visible, setVisible] = useState(false)

    const [isEditMode, setEditMode] = useState<boolean>(false)
    const updateTitle = async ({ title, description }: TUpdateRoom) => {
      try {
        S.webrtc.updateEmitAndSaveDataStore(
          {
            title,
            description,
          },
          'header',
          () =>
            ToastService.success({
              content: 'Successfully updated room information',
            }),
        )
      } catch (err) {
        ToastService.error({ content: 'Failed to update session title' })
        console.error(err)
      }
    }

    useEffect(() => {
      if (
        statusUpdateSettings.type === 'header' &&
        statusUpdateSettings.status === 'success' &&
        isEditMode
      ) {
        setEditMode(false)
      }
    }, [S.webrtc.statusUpdateSettings])

    const navigateHomepage = () => {
      RouterHistory.push(routerPaths.HOME)
    }
    return (
      <div className={clsx(css.Container, titleContainerClassName)}>
        <div className={css.BlockTitle}>
          <span
            onClick={navigateHomepage}
            style={{ background: warmnessColorGray }}
            className={css.IconBack}
          >
            <Icon icon='icon_arrow_left' size={12} />
          </span>
          <div className={css.TextTitleWrapper}>
            <h1 className={clsx(css.Title, titleClass)}>
              {roomTitle || 'Unname Room'}
            </h1>
            {showEditTitle && (
              <span
                className={css.IconEdit}
                onClick={() => setEditMode(!isEditMode)}
              >
                <Icon icon='icon_pencil' size={12} />
              </span>
            )}
            <BeamTooltip
              trigger='hover'
              title={visible ? '' : 'Join with your device'}
            >
              <span className={css.ContentSubtitle}>
                <BeamDropdown
                  placement='top'
                  overlay={<QrScannerModal />}
                  trigger={['click']}
                  visible={visible}
                  onVisibleChange={setVisible}
                  getPopupContainer={trigger =>
                    trigger.parentElement ?? trigger
                  }
                >
                  <Icon icon='Scan' size={16} />
                </BeamDropdown>
              </span>
            </BeamTooltip>
          </div>
        </div>

        <ModalUpdateInfo
          initValue={{
            title: roomTitle || 'Unname Room',
            description: roomDescription,
          }}
          visible={isEditMode}
          onCancel={() => setEditMode(false)}
          onSave={updateTitle}
          context='Session'
        />
      </div>
    )
  },
)
