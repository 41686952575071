import { useState } from 'react'

import { separateLogic } from '##/reactjs/redux'

import type { DestinationProps } from './type'

export const createComponent = separateLogic((p: DestinationProps) => {
  const [menuVisible, setMenuVisible] = useState(false)
  return { menuVisible, setMenuVisible }
})
