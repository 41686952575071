import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useState } from 'react'

import css from './ToggleRoleConfirmPopup.module.scss'

import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'

import { Button } from '../../../components/base/Button'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { S } from '../../../context/store'
import { handleChangeRole } from '../actions/handleChangeRole'

export const ToggleRoleConfirmPopup = observer(
  ({
    isWarmTheme = true,
  }: {
    onCancel: () => void
    context: string
    isWarmTheme?: boolean
  }) => {
    const [loading, setLoading] = useState(false)
    const roleName = S.webrtc.role?.name === 'Observer' ? 'Host' : 'Observer'
    const handleChange = async () => {
      setLoading(true)
      await handleChangeRole(roleName)
      setLoading(false)
    }

    const { warmnessColor } = S.local
    return (
      <div onMouseDown={(e: MouseEvent) => e.stopPropagation()}>
        <ModalContent
          style={{
            // @ts-ignore
            '--background-modal': isWarmTheme ? warmnessColor : undefined,
          }}
          containerClassName={css.ModalContainer}
        >
          <span className={css.ConfirmTitle}>
            {' '}
            Change to the {roleName} role?{' '}
          </span>
          <span className={css.ConfirmContent}>
            You will {S.webrtc.role?.name === 'Observer' ? '' : 'not'} be see by
            the participants. Are you sure?
          </span>
          <div className={css.BtnList}>
            <Button
              loading={loading}
              className={css.BtnSubmit}
              onClick={handleChange}
            >
              {roleName === 'Host' ? 'Become a Host' : 'Become an Observer'}
            </Button>
            <WarmnessGrayButton
              onClick={() => ModalService.hide()}
              className={css.BtnClose}
            >
              Cancel
            </WarmnessGrayButton>
          </div>
        </ModalContent>
      </div>
    )
  },
)
