import type { PeerData } from '../type'
import type { Mediasoup } from '../type-ui'

export const _requestJoin = (
  m: Pick<Mediasoup, 'protoo' | 'device'>,
  d: PeerData & { consumer: boolean },
) => {
  const { consumer, ...data } = d
  return m.protoo.request('join', {
    rtpCapabilities: consumer ? m.device.rtpCapabilities : undefined,
    sctpCapabilities: undefined, // data channel
    data,
  })
}
