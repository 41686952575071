import css from './BottomItemControl-showRecordToast.module.scss'

import { ToastService } from '../../../components/widget/Toast'

export const showRecordSuccessToast = (onNavigateToRecord: () => void) => {
  ToastService.success({
    content: (
      <div>
        Record saved.{' '}
        <span onClick={onNavigateToRecord} className={css.linkToRecord}>
          View record in Media {'>'} Record
        </span>
      </div>
    ),
  })
}

export const showRecordStopToast = (
  outOfStorage: boolean,
  onNavigateToRecord: () => void,
) => {
  if (!outOfStorage) {
    showRecordSuccessToast(onNavigateToRecord)
    return
  }
  ToastService.warning({
    content: (
      <div>
        You're out of storage, please download your recording, it will be
        deleted after you exit this session.{' '}
        <span className={css.linkToRecord} onClick={onNavigateToRecord}>
          View record in Media {'>'} Record
        </span>
      </div>
    ),
  })
}
