import { makeAutoObservable } from 'mobx'
import moment from 'moment'

import { reactServerOrigin } from '../../../config'

class ServerTime {
  diff = 0

  constructor() {
    makeAutoObservable(this)
    this.refetch()
  }
  refetch = async () => {
    const before = Date.now()
    const serverTime: number = await fetch(
      reactServerOrigin + '/api/server-time',
    )
      .then(r => r.json())
      .catch(() => 0)
    if (!serverTime || typeof serverTime !== 'number') {
      console.error(`Invalid server time response ${serverTime}`)
      return
    }
    const localTime = (Date.now() + before) / 2
    this.diff = serverTime - localTime
    console.log(`server=${serverTime} local=${localTime} diff=${this.diff}`)
  }

  now = () => Date.now() + this.diff
  date = () => new Date(this.now())
  moment = () => moment(this.now())
}

export const serverTime = new ServerTime()
