import clsx from 'clsx'
import type { FC } from 'react'

import css from './PopoverBuyMoreStorage.module.scss'

import { BeamText } from '../../../components/base/Text'
import type { PlanName } from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { formatCurrency } from '../../../utils/formatCurrency'
import { ArrowPopoverBuyMore } from './PopoverBuyMoreMinute'

interface PriceItem {
  type?: 'header' | 'data'
  amount?: string
  monthly?: string
  annual?: string
}
interface Table {
  name: PlanName
  items: PriceItem[]
}

const Divider = () => <div className={css.Divider} />

const Row: FC<PriceItem> = ({ type = 'header', amount, annual, monthly }) => {
  const isHeader = type === 'header'
  return (
    <div className={clsx(css.Row, css.ItemTitle)}>
      <BeamText
        containerProps={{ classNameContainer: css.Amount }}
        className={clsx(css.ItemTitle, { [css.Data]: !isHeader })}
      >
        {isHeader ? 'Amount' : amount}
      </BeamText>
      <BeamText
        containerProps={{ classNameContainer: css.Monthly }}
        className={clsx(css.ItemTitle, { [css.ValueText]: !isHeader })}
      >
        {isHeader ? 'Monthly' : monthly}
      </BeamText>
      <BeamText
        containerProps={{ classNameContainer: css.Annual }}
        className={clsx(css.ItemTitle, { [css.ValueText]: !isHeader })}
      >
        {isHeader ? 'Annual' : annual}
      </BeamText>
      <BeamText
        containerProps={{ classNameContainer: css.Unit }}
        className={clsx(css.ItemTitle, { [css.Data]: !isHeader })}
      >
        {isHeader ? 'Unit' : 'GB/month'}
      </BeamText>
    </div>
  )
}

export const PopoverBuyMoreStorage: FC = () => {
  const plans = S.profile.plans

  const getCheckPriceInfo = () => {
    const tables: Table[] = plans.map((p): Table => {
      const items: PriceItem[] = []
      if (p.name === 'Freemium') {
        const pMonthly = p.prices.find(
          i => i.currency === 'USD' && i.interval === 'Monthly',
        )
        const pYearly = p.prices.find(
          i => i.currency === 'USD' && i.interval === 'Yearly',
        )
        const monthly = formatCurrency(pMonthly?.priceStorage)
        const annual = formatCurrency(
          pYearly?.priceStorage ? pYearly.priceStorage / 12 : 0,
        )
        items.push({
          amount: 'Any',
          monthly,
          annual,
        })
      } else {
        const tiersMonthly =
          p.prices.find(
            t =>
              t.currency === 'USD' &&
              t.priceStorageTiers &&
              t.interval === 'Monthly',
          )?.priceStorageTiers ?? []
        const tiersYearly =
          p.prices.find(
            t =>
              t.currency === 'USD' &&
              t.priceStorageTiers &&
              t.interval === 'Yearly',
          )?.priceStorageTiers ?? []

        tiersMonthly.forEach(t => {
          const amount = t.to ? `${t.from} - ${t.to} GB` : `${t.from} or more`
          const monthly = formatCurrency(t.price)
          const priceByYearly = tiersYearly.find(
            ty => ty.from === t.from && ty.to === t.to,
          )?.price
          const annual = formatCurrency(
            (priceByYearly ?? 0) / 12,
            '$',
            3,
            false,
          )
          items.push({
            amount,
            monthly,
            annual,
          })
        })
      }

      return {
        name: p.name,
        items,
      }
    })
    return tables
  }

  const _renderTable = (table: Table) => (
    <div key={table.name} className={css.PopoverBuyMoreItem}>
      <BeamText className={css.ItemTitle}>For {table.name} users</BeamText>
      <div className={css.StorageValueWrapper}>
        <Row />
        <Divider />
        {table.items.map(t => (
          <Row key={t.amount} type='data' {...t} />
        ))}
      </div>
    </div>
  )
  return (
    <div className={css.OverlayWrapper}>
      <ArrowPopoverBuyMore />
      <div className={css.PopoverBuyMore}>
        {getCheckPriceInfo().map(i => _renderTable(i))}
      </div>
    </div>
  )
}
