import type { Session } from '../../../context/gql/codegen'
import { S } from '../../../context/store'

const $ = S.webrtc
export const setSessionIsRunning = (session: Partial<Session>) => {
  if (!$.detail) {
    return
  }
  Object.assign($.detail, session)
}
