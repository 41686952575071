import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'

import './App.scss'

import { ThemeProvider } from './context/shared/components/ThemeProvider'
import { S } from './context/store'
import { AmsClient } from './pages/Studio/components/AmsClient'
import { Mixer } from './pages/Studio/components/Room'

export const AppBundleMixer = () => (
  <DndProvider
    backend={TouchBackend}
    options={{
      enableMouseEvents: true,
    }}
  >
    <ThemeProvider isRoute={false}>
      {S.webrtc.isViewmodeAmsClient ? <AmsClient /> : <Mixer />}
    </ThemeProvider>
  </DndProvider>
)
