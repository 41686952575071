import { separateLogic } from '##/reactjs/redux'

import { ToastService } from '../../../../components/widget/Toast'
import {
  FACEBOOK_AUTH_CLIENT_ID,
  FACEBOOK_AUTH_STATE_CODE,
  FACEBOOK_AUTH_STATE_SCOPE,
  GOOGLE_CLIENT_ID,
  LINKED_LIVE_STATE_SCOPE,
  LINKEDIN_LIVE_CLIENT_ID,
  LINKEDIN_LIVE_STATE_CODE,
} from '../../../../config'
import { useGqlCreateOutput } from '../../../../context/gql/codegen'
import { S } from '../../../../context/store'
import { catchPromiseError } from '../../../../utils/catchPromiseError'
import { useLoadGsiScript } from '../../../../utils/googleLogin/useGoogleLoadScript'
import { useGoogleLogin } from '../../../../utils/googleLogin/useGoogleLogin'
import { useFacebookLogin } from '../../../../utils/useFacebookLogin'
import { useLinkedInLogin } from '../../../../utils/useLinkedInLogin'
import { OutputType } from '../DestinationEnum'
import type { SelectDestinationProps } from './type'

export const createComponent = separateLogic(
  ({ onClick, refreshDestination }: SelectDestinationProps) => {
    const [, executeCreateDestination] = useGqlCreateOutput()
    const loadScriptSuccess = useLoadGsiScript({
      onScriptLoadError: () => {
        ToastService.error({ content: 'Cookie is required', duration: 2 })
      },
    })
    const loginHandler = (code: string, type: OutputType) => {
      const redirectUrl =
        location.origin +
        (type === OutputType.Linkedin || type === OutputType.Facebook
          ? '/studio'
          : '')
      const data = executeCreateDestination({
        data: {
          type,
          sessionId: S.webrtc.sessionId,
          data: { code, redirectUrl },
        },
      })
      catchPromiseError(data, () => {
        refreshDestination()
      })
    }
    const loginGoogle: any = useGoogleLogin({
      onSuccess: (tokenResponse: any) => {
        loginHandler(tokenResponse.code, OutputType.Youtube)
      },
      onError: error => {
        ToastService.error({ content: 'Login failed', duration: 2 })
      },
      scope:
        'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtubepartner-channel-audit',
      clientId: GOOGLE_CLIENT_ID,
      scriptLoadedSuccessfully: loadScriptSuccess,
      flow: 'auth-code',
      redirect_uri: `${location.origin}`,
    })

    const loginLinkedIn = useLinkedInLogin({
      redirectUri: `${location.origin}/studio`,
      loginHandler: async code => {
        loginHandler(code, OutputType.Linkedin)
      },
      scope: LINKED_LIVE_STATE_SCOPE,
      clientId: LINKEDIN_LIVE_CLIENT_ID,
      stateCode: LINKEDIN_LIVE_STATE_CODE,
    })
    const loginFacebook = useFacebookLogin({
      redirect_uri: `${location.origin}/studio`,
      loginHandler: async code => {
        loginHandler(code, OutputType.Facebook)
      },
      scope: FACEBOOK_AUTH_STATE_SCOPE,
      client_id: FACEBOOK_AUTH_CLIENT_ID,
      state: FACEBOOK_AUTH_STATE_CODE,
    })
    const onItemClick = (type: OutputType) => {
      switch (type) {
        case OutputType.Youtube:
          loginGoogle()
          onClick?.(type)
          break
        case OutputType.Custom:
          onClick?.(type)
          break
        case OutputType.Linkedin:
          loginLinkedIn()
          onClick?.(type)
          break
        case OutputType.Facebook:
          loginFacebook()
          onClick?.(type)
          break
        case OutputType.Instagram:
          onClick?.(type)
          break
        default:
          break
      }
    }
    return { onItemClick }
  },
)
