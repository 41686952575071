import type { SearchTranscriptQueryVariables } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import { TranscriptStatus } from '../../../context/store/insight-engine/InsightEngineStore'

export const getTranscript = async (params: SearchTranscriptQueryVariables) => {
  const $ = S.insightEngine
  $.paramsTranscript.loadingTranscript = true
  $.paramsTranscript.offset = 0

  const { data } = await reduxStore.context.gql.searchTranscript(
    { ...params, page: { ...params.page, limit: params.page?.limit ?? 1000 } },
    {
      requestPolicy: 'network-only',
    },
  )
  if ($.resourceSelected?.status === TranscriptStatus.Complete) {
    const { data: cData } = await reduxStore.context.gql.countTranscript({
      resourceInInsightEngineId: params.resourceInInsightEngineId,
      filter: params.filter,
    })
    $.transcriptCount = cData?.countTranscript ?? 0
  }
  $.transcripts = data?.searchTranscript ?? []
  $.paramsTranscript.loadingTranscript = false
  $.paramsTranscript.offset = (params.page?.limit ?? 1000) - 100
}
