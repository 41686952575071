import css from './ModalRMTShowReadyUrl.module.scss'

import { ToastService } from '../../../components/widget/Toast'

export const showInputReadyToast = (inputName: string, url: string) => {
  const copy = () => {
    navigator.clipboard.writeText(url)
    ToastService.success({ content: 'Copied!' })
  }

  ToastService.success({
    content: (
      <div>
        "{inputName}" link is ready to{' '}
        <span onClick={copy} className={css.Button}>
          Copy
        </span>
      </div>
    ),
  })
}
