import { Menu } from 'antd'
import type { FC } from 'react'

import css from './ModalInviteUserListAction.module.scss'

import { Icon } from '#HACK_FOR_RN_ONLY/src/components/base/Icon'
import type { PeerUI } from '#rn-shared/mediasoup/type-ui'

import { BeamDropdown } from '../../../components/base/Dropdown'
import { ToastService } from '../../../components/widget/Toast'
import { handleChangeRole } from '../../../pages/Studio/actions/handleChangeRole'
import { reduxStore } from '../../redux'
import { S } from '../../store'

export enum ActionRoleType {
  CHANGE_TO_PP,
  CHANGE_TO_OBSERVER,
  CHANGE_TO_HOST,
  REMOVE,
}

export interface IMenuItem {
  type: ActionRoleType
  onClick: (e: any) => void
}
type Props = {
  item: PeerUI
  isCurrentUser: boolean
}
export const ModalInviteUserListAction: FC<Props> = ({
  item,
  isCurrentUser,
}) => {
  let menuItems: IMenuItem[] = []
  const { requestSwitchRole } = S.webrtc
  const handelRemoveUser = async () => {
    try {
      const result = await reduxStore.context.gql.deleteUserInSession({
        userId: item.data.userId || '',
        sessionId: S.webrtc.sessionId,
      })
      if (result.error) {
        ToastService.error({ content: 'Failed to remove user' })
        return
      }
      S.webrtc.removePeer(item.id)
      S.webrtc.mediasoup?.protoo.request('kickout', { peerId: item.id })
      ToastService.success({
        content: 'User has been removed out of session',
      })
    } catch (err) {
      ToastService.error({ content: 'Failed to remove user' })
      console.error(err)
    } finally {
    }
  }
  const checkRenderMenuItem = () => {
    if (isCurrentUser && item.data.viewmode === 'rhost') {
      return [
        {
          type: ActionRoleType.CHANGE_TO_PP,
          onClick: () => {
            handleChangeRole('Participant')
          },
        },
        {
          type: ActionRoleType.CHANGE_TO_OBSERVER,
          onClick: () => {
            handleChangeRole('Observer')
          },
        },
      ]
    } else if (isCurrentUser && item.data.viewmode === 'robserver') {
      return [
        {
          type: ActionRoleType.CHANGE_TO_HOST,
          onClick: () => {
            handleChangeRole('Host')
          },
        },
      ]
    } else if (!isCurrentUser && item.data.viewmode === 'rhost') {
      return [
        {
          type: ActionRoleType.CHANGE_TO_PP,
          onClick: () => {
            requestSwitchRole(item.id, 'Participant', item.data.name)
          },
        },
        {
          type: ActionRoleType.CHANGE_TO_OBSERVER,
          onClick: () => {
            requestSwitchRole(item.id, 'Observer', item.data.name)
          },
        },
        {
          type: ActionRoleType.REMOVE,
          onClick: () => {
            handelRemoveUser()
          },
        },
      ]
    } else if (!isCurrentUser && item.data.viewmode === 'robserver') {
      return [
        {
          type: ActionRoleType.CHANGE_TO_HOST,
          onClick: () => {
            requestSwitchRole(item.id, 'Host', item.data.name)
          },
        },
        {
          type: ActionRoleType.REMOVE,
          onClick: () => {
            handelRemoveUser()
          },
        },
      ]
    } else if (!isCurrentUser && item.data.viewmode === 'rparticipant') {
      return [
        {
          type: ActionRoleType.CHANGE_TO_HOST,
          onClick: () => {
            requestSwitchRole(item.id, 'Host', item.data.name)
          },
        },
        {
          type: ActionRoleType.CHANGE_TO_OBSERVER,
          onClick: () => {
            requestSwitchRole(item.id, 'Observer', item.data.name)
          },
        },
        {
          type: ActionRoleType.REMOVE,
          onClick: () => {
            handelRemoveUser()
          },
        },
      ]
    }
    return []
  }
  menuItems = checkRenderMenuItem()

  const menu = () => (
    <Menu className={css.ActionMenu}>
      {menuItems.map(menuItem => {
        switch (menuItem.type) {
          case ActionRoleType.CHANGE_TO_HOST:
            return (
              <Menu.Item
                className={css.ActionMenuItem}
                key={menuItem.type}
                onClick={menuItem.onClick}
              >
                <Icon size={16} icon='icon_switch' />
                Change to host
              </Menu.Item>
            )
          case ActionRoleType.CHANGE_TO_OBSERVER:
            return (
              <Menu.Item
                className={css.ActionMenuItem}
                key={menuItem.type}
                onClick={menuItem.onClick}
              >
                <Icon size={16} icon='icon_switch' />
                Change to observer
              </Menu.Item>
            )
          case ActionRoleType.CHANGE_TO_PP:
            return (
              <Menu.Item
                className={css.ActionMenuItem}
                key={menuItem.type}
                onClick={menuItem.onClick}
              >
                <Icon size={16} icon='icon_switch' />
                Change to participant
              </Menu.Item>
            )
          case ActionRoleType.REMOVE:
            return (
              <Menu.Item
                key={menuItem.type}
                className={css.ActionRemove}
                onClick={menuItem.onClick}
              >
                <Icon size={16} icon='icon_delete' />
                Remove
              </Menu.Item>
            )
          default:
            return null
        }
      })}
    </Menu>
  )
  return (
    <BeamDropdown
      className={css.Dropdown}
      overlay={menu}
      trigger={['click']}
      placement='top'
      getPopupContainer={undefined}
    >
      <div className={css.UserRoleButton}>
        <div className={css.UserRole}>
          {item.data.viewmode === 'rhost'
            ? 'Host'
            : item.data.viewmode === 'robserver'
              ? 'Observer'
              : 'Participant'}
        </div>
        <Icon className={css.IconDropdown} icon='Dropdown-Down' size={16} />
      </div>
    </BeamDropdown>
  )
}
