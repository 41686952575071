import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import css from './VideoPlayerControl.module.scss'

import { WarmnessCustomPopOver } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessCustomPopover'
import { WarmnessGrayButton } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessGrayButton'
import { volume0 } from '#rn-shared/mediasoup'

import { Icon } from '../../../components/base/Icon'
import { BeamSlider } from '../../../components/base/Slider'
import { AppSpin } from '../../../components/base/Spin'
import { S } from '../../../context/store'
import { covertDurationToMinutes } from '../../../context/store/studio/utils'
import type { TVideoSessionList } from '../../../context/store/studio/WebrtcStore'
import { CONSTANTS } from '../../../styles/Constants'
import { useDragDefault } from '../../../utils/useDrag'

type TData = TVideoSessionList & {
  mediaType?: string
  value?: string
}
interface IProps {
  video?: React.ReactElement
  data: TData
  onChangePlay?: () => void
  onChangeSeek?: (v: number) => void
  onAfterChangeSeek?: (v: number) => void
  onChangeRepeat: () => void
  onChangeVolume: (v: number) => void
  handleAfterChangeVolumn: (v: number) => void
  onChangeMuted: () => void
  onDragEnd: () => void
  autoToBeginning?: () => void
  isOnline?: boolean
  isLoading?: boolean
  videoState: any
  type: string
}

export const VideoPlayerControl: FC<IProps> = observer(
  ({
    onDragEnd,
    onChangeMuted,
    onChangeVolume,
    handleAfterChangeVolumn,
    onChangeSeek,
    onAfterChangeSeek,
    onChangePlay,
    onChangeRepeat,
    isLoading,
    data,
    video,
    videoState,
    isOnline,
    autoToBeginning,
    type,
  }) => {
    const [visible, setVisible] = useState(false)

    const {
      newStateVideoPreviewId,
      backgroundVideoUrl,
      removeMediasOfLayoutSlot,
      selectedIndexLayout,
      updateAndEmit,
      backgroundMediaData,
    } = S.webrtc
    const ref = useRef<HTMLDivElement>(null)
    const [isShowVolume, setIsShowVolume] = useState(false)
    const { isLightTheme } = S.local
    const { mediaType, name, duration, value: src, mediaId } = data
    const { isDragging, drag } = useDragDefault({
      item: {
        id: mediaId,
        value: src ?? data.url,
        mediaType: mediaType ?? 'video',
        name,
        duration,
        isPreview: true,
      },
      dragType: type,
      onDragEnd,
    })
    drag(ref)
    const onAir =
      S.webrtc.isOnAirItemOfLayout(
        S.webrtc.layoutMedias,
        newStateVideoPreviewId,
      ) ||
      data.value === backgroundVideoUrl ||
      backgroundMediaData.id === newStateVideoPreviewId
    useEffect(() => {
      const haveVideOnLayout = S.webrtc.stateVideoPreview.map(
        item => item.mediaId === newStateVideoPreviewId,
      )
      if (
        haveVideOnLayout &&
        S.webrtc.newStateVideoPreviewId === newStateVideoPreviewId
      ) {
        S.webrtc.updateStateVideoPreview(newStateVideoPreviewId, {
          volume: data?.volume || 1,
        })
      }
    }, [S.webrtc.selectedIndexLayout])

    const handleUpdateSettings = (argType?: string) => {
      S.webrtc.updateEmitAndSaveSettings(undefined, argType)
    }

    const handleAutoRestartWhenSwitchLayout = () => {
      if (isOnline && autoToBeginning) {
        autoToBeginning()
      }
    }

    const onTurnOffAir = (item: TData) => {
      removeMediasOfLayoutSlot([item.mediaId ?? ''], selectedIndexLayout)
      if (backgroundVideoUrl === item.value) {
        updateAndEmit({
          backgroundVideoUrl: '',
        })
      }
      handleUpdateSettings?.()
    }
    const content = () => {
      if (!isOnline) {
        return
      }
      return (
        <div className={css.PopoverActionWrapper}>
          <WarmnessGrayButton
            onClick={() => {
              handleAutoRestartWhenSwitchLayout()
              setVisible(false)
            }}
            className={css.PopoverActionItem}
          >
            <Icon
              icon='icon_repeat'
              size={16}
              className={css.PopOverActionIcon}
            />{' '}
            Always play from start
            <Icon
              icon='icon_check'
              size={16}
              className={clsx({
                [css.TickedIcon]: true,
                [css.TickedIconShow]: data.autoToBeginning,
              })}
            />
          </WarmnessGrayButton>
        </div>
      )
    }

    const volume = data.muted ? 0 : data?.volume || 0

    return (
      <div
        ref={ref}
        className={clsx({
          [css.VideoPreviewContainer]: true,
          [css.VideoPreviewContainerShow]:
            S.webrtc.newStateVideoPreviewId === newStateVideoPreviewId,
        })}
      >
        {isLoading && (
          <div className={css.Loading}>
            <AppSpin />
          </div>
        )}
        {video}
        <div
          onClickCapture={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          className={css.VideoPayerSliceContainer}
        >
          <BeamSlider
            className={css.VideoPlayerSlider}
            min={0}
            max={videoState.duration}
            step={0.1}
            value={data?.currentTime}
            tooltipVisible={false}
            onChange={onChangeSeek}
            onAfterChange={onAfterChangeSeek}
            trackStyle={{
              backgroundColor: isLightTheme
                ? '#2656C9'
                : CONSTANTS.level.lighter1,
              borderRadius: 4,
            }}
            handleStyle={{
              backgroundColor: isLightTheme
                ? CONSTANTS.basicColor.white
                : CONSTANTS.text.level1,
              border: 'none',
              boxShadow: isLightTheme
                ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                : 'none',
              width: 14,
              height: 14,
            }}
          />
        </div>
        <div
          onMouseDown={e => e.stopPropagation()}
          className={css.VideoPlayerController}
        >
          <div
            onMouseLeave={() => setIsShowVolume(false)}
            className={css.VideoPlayerVolume}
          >
            <span
              onMouseEnter={() => setIsShowVolume(true)}
              onClick={onChangeMuted}
              className={css.VideoPlayerMute}
            >
              <Icon
                icon={
                  volume <= volume0 || data.muted
                    ? 'icon_volumn_silent'
                    : volume < 0.5
                      ? 'icon_volumn_medium'
                      : 'icon_volumn_max'
                }
                size={16}
              />
            </span>
            <BeamSlider
              autoFocus
              className={clsx({
                [css.VideoPlayerVolumeSwitch]: true,
                [css.VideoPlayerVolumeSwitchShow]: isShowVolume,
              })}
              min={0}
              max={100}
              step={1}
              value={volume}
              tooltipVisible={false}
              onChange={onChangeVolume}
              onAfterChange={handleAfterChangeVolumn}
              trackStyle={{
                backgroundColor: isLightTheme
                  ? '#2656C9'
                  : CONSTANTS.level.lighter1,
                borderRadius: 4,
              }}
              handleStyle={{
                backgroundColor: isLightTheme
                  ? CONSTANTS.basicColor.white
                  : CONSTANTS.text.level1,
                border: 'none',
                boxShadow: isLightTheme
                  ? ' 0px 4px 8px rgba(1, 2, 11, 0.12), 0px 4px 12px rgba(1, 2, 11, 0.08), 0px 1px 4px rgba(1, 2, 11, 0.24)'
                  : 'none',
                width: 14,
                height: 14,
              }}
            />
          </div>
          <span className={css.VideoPlayerIconPlay} onClick={onChangePlay}>
            <Icon
              icon={videoState?.paused ? 'icon_play' : 'icon_pause'}
              size={24}
            />
          </span>
          <div className={css.VideoPlayerInfo}>
            {/* {onAir && (
              <span
                className={clsx({
                  [css.VideoPlayRepeat]: true,
                })}
              >
                <Icon icon='Fullscreen' size={12} />
              </span>
            )} */}
            <span
              className={clsx({
                [css.VideoPlayRepeat]: true,
                [css.videoPlayIsRepeat]: Boolean(data?.repeat),
              })}
              onClick={onChangeRepeat}
            >
              <Icon icon='icon_repeat' size={12} />
            </span>
            {onAir && (
              <WarmnessCustomPopOver
                placement='leftTop'
                content={content}
                isLightTheme={isLightTheme}
                visible={visible}
                onVisibleChange={(status: boolean) => setVisible(status)}
              >
                <span className={clsx(css.VideoPlayRepeat)}>
                  <Icon icon='icon_three_dots' size={12} />
                </span>
              </WarmnessCustomPopOver>
            )}
          </div>
        </div>
        <div className={css.VideoPlayerName}>{data.name}</div>
        <div className={css.VideoPlayerOtherInfo}>
          <div className={css.VideoPlayerDuration}>
            {covertDurationToMinutes(videoState.time ?? data.currentTime)} /{' '}
            {covertDurationToMinutes(videoState.duration)}
          </div>
          {onAir && (
            <div
              onClick={() => onTurnOffAir(data)}
              className={css.VideoPlayerOnAir}
            >
              On Air
            </div>
          )}
        </div>
        {isDragging && <div className={css.VideoPlayerControllLeftOver} />}
      </div>
    )
  },
)
