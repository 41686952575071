import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import css from './TimeLineItem.module.scss'

import { BeamTooltip } from '#HACK_FOR_RN_ONLY/src/components/base/Tooltip'

import { BeamText } from '../../../components/base/Text'
import { Resizeable } from '../../../context/shared/components/CustomLayoutResizeable'
import { S } from '../../../context/store'
import type {
  IBlurItem,
  IMuteItem,
} from '../../../context/store/insight-engine/InsightEngineStore'
import { InsightEngineEditType } from '../../../context/store/insight-engine/InsightEngineStore'
import type { ILayoutItem } from '../../../context/store/shared/LayoutStore'
import { covertDurationToMinutes } from '../../../context/store/studio/utils'
import { useContainerDimensions } from '../../../utils/useContainerDimension'
import { useDebounceValue } from '../../../utils/useDebounceValue'
import { updateBlurBlock, updateBlurBlockApi } from '../actions/updateBlock'
import {
  updateMuteTimeline,
  updateMuteTimelineApi,
} from '../actions/updateMuteTimeline'
import type { ITimeLineItemProps } from '../type'

export const TimeLineItem: FC<ITimeLineItemProps> = observer(({ block }) => {
  const { selectedBlockId, mediaPlayer } = S.insightEngine
  const { duration } = mediaPlayer
  const { id, startTime, endTime, type } = block
  const containerRef = useRef<HTMLDivElement>(null)
  const { clientWidth, clientHeight } = useContainerDimensions(containerRef)
  const hasInit = useRef(false)
  const [, setState, debouncedValue] = useDebounceValue<
    Partial<IBlurItem | IMuteItem>
  >(1000, block)
  useEffect(() => {
    if (hasInit.current) {
      if (type === InsightEngineEditType.Blur) {
        updateBlurBlockApi(debouncedValue?.id ?? '')
      } else if (type === InsightEngineEditType.Mute) {
        updateMuteTimelineApi(debouncedValue?.id ?? '')
      }
    }
    hasInit.current = true
  }, [debouncedValue])

  const containerProps = {
    width: clientWidth,
    height: clientHeight,
  }

  const getTimeLineSize = (): ILayoutItem => {
    const ratioStart = (startTime ?? 0) / (duration ?? 1)
    const ratioW = Math.abs((endTime ?? 0) / (duration ?? 1) - ratioStart)

    const left = clientWidth * ratioStart
    const width = clientWidth * ratioW

    return {
      width,
      height: clientHeight,
      top: 0,
      left,
      radius: 0,
      id: id as any,
      zIndex: 1,
    }
  }

  const setBlockPartial = (state: ILayoutItem) => {
    const ratioTimeWidth = state.width / clientWidth
    const ratioTimeStart = state.left / clientWidth
    const newStartTime = ratioTimeStart * (duration ?? 1)
    const newEndTime = newStartTime + ratioTimeWidth * (duration ?? 1)
    const data: Partial<IBlurItem> = {
      id,
      startTime: newStartTime,
      endTime: newEndTime,
    }
    if (InsightEngineEditType.Blur === type) {
      updateBlurBlock(data)
    } else if (InsightEngineEditType.Mute === type) {
      updateMuteTimeline(data)
    }
    setState(data)
  }

  const tooltipContent = () => (
    <div className={css.TooltipWrapper}>
      <BeamText>
        Start time: {covertDurationToMinutes(Math.round(block.startTime ?? 0))}s
      </BeamText>
      <BeamText>
        End time: {covertDurationToMinutes(Math.round(block.endTime ?? 0))}s
      </BeamText>
    </div>
  )

  return (
    <div ref={containerRef} className={css.TimeLineWrapper}>
      <Resizeable
        slotsOtherEditing={[]}
        layout={getTimeLineSize()}
        key={id}
        onChange={setBlockPartial}
        id={id}
        index={1}
        outSizeId={`timeLine-${id}`}
        isEditMode={selectedBlockId === id}
        enableEditLayout={selectedBlockId === id}
        isEdit={selectedBlockId === id}
        disableResizeInput
        containerProps={containerProps}
        showControl
        showAlignmentIndicator={false}
        showResizeBtn={false}
        showRoundedBtn={false}
        showSizeInfo={false}
        isEdgesHorizontalOnly
      >
        <BeamTooltip
          style={{ borderRadius: '8px' }}
          color='#fff'
          title={tooltipContent}
          trigger={'hover'}
        >
          <div
            className={clsx({
              [css.LineTime]: true,
              [css.LineTimeBlurSelected]:
                selectedBlockId === id && type === InsightEngineEditType.Blur,
              [css.LineTimeMute]: type === InsightEngineEditType.Mute,
              [css.LineTimeMuteSelected]:
                selectedBlockId === id && type === InsightEngineEditType.Mute,
            })}
          />
        </BeamTooltip>
      </Resizeable>
    </div>
  )
})
