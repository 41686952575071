import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'

import css from './BuyMore.module.scss'

import { AppSpin } from '#HACK_FOR_RN_ONLY/src/components/base/Spin'

import { Button } from '../../../components/base/Button'
import { Icon } from '../../../components/base/Icon'
import { IconButton } from '../../../components/base/IconButton'
import { Popover } from '../../../components/base/Popover'
import { ModalContent, ModalService } from '../../../components/Modal/Modal'
import { BeamInput } from '../../../components/widget/Input'
import { BUY_MORE_MIN } from '../../../config'
import type { ComputedPrice } from '../../../context/gql/codegen'
import { S } from '../../../context/store'
import { formatCurrency } from '../../../utils/formatCurrency'

export enum BuyMoreType {
  Storage = 'storage',
  Minutes = 'minutes',
}
let interval: number
let timeout: NodeJS.Timeout
let superInterval: number
let superTimeout: NodeJS.Timeout

const ContentBuyMore = {
  [BuyMoreType.Minutes]: {
    title: 'Get more minutes',
    value: 'mins',
    summary: 'Minute',
    unit: 'h',
    ques: 'How many',
  },
  [BuyMoreType.Storage]: {
    title: 'Get more storage',
    value: 'GB',
    summary: 'Storage',
    unit: 'GB',
    ques: 'How much',
  },
}

type Props = {
  data: ComputedPrice
  valueDebounce: string
  value: string
  setValue: (value: string) => void
  onCheckout?(): void
  popoverContent?: JSX.Element
  type: BuyMoreType
  fetching: boolean
  fetchingComputed: boolean
  currentTotal?: number
  unitValue: number
}

export const BuyMore = observer(
  ({
    data,
    type,
    onCheckout,
    setValue,
    value,
    popoverContent,
    fetching,
    fetchingComputed,
    currentTotal,
    unitValue,
  }: Props) => {
    const isStorage = type === BuyMoreType.Storage
    const dataBuyMore = useMemo(() => ContentBuyMore[type], [type])
    const { currentPlan, plans } = S.profile
    const [currentValue, setCurrentValue] = useState(value)

    const changeValue = (e: any) => {
      const newValue = e.target.value
      const checkValue = new RegExp(/^(\s*|\d+)$/)
      if (checkValue.test(newValue)) {
        setValue(newValue)
      }
    }

    const convertValue = () => {
      const final = value || 0
      return isStorage ? final : Number(final) / 60
    }
    const increaseValue = () => {
      setCurrentValue(prev => (Number(prev) + 60).toString())
    }
    const onMouseDownIncreaseValue = () => {
      increaseValue()
      timeout = setTimeout(() => {
        interval = window.setInterval(() => {
          increaseValue()
        }, 100)
      }, 500)
      superTimeout = setTimeout(() => {
        clearTimeout(timeout)
        window.clearInterval(interval)
        superInterval = window.setInterval(() => {
          increaseValue()
        }, 50)
      }, 3000)
    }
    const onMouseUpIncreaseValue = () => {
      setValue(currentValue)
      clearTimeout(timeout)
      clearInterval(interval)
      clearTimeout(superTimeout)
      clearInterval(superInterval)
    }

    const onMouseLeaveIncreaseValue = () => {
      setValue(currentValue)
      clearTimeout(timeout)
      clearInterval(interval)
      clearTimeout(superTimeout)
      clearInterval(superInterval)
    }

    const decreaseValue = () => {
      setCurrentValue(prev =>
        Number(prev) - 60 > 0 ? (Number(prev) - 60).toString() : '',
      )
    }

    const onMouseDownDecreaseValue = () => {
      decreaseValue()
      timeout = setTimeout(() => {
        interval = window.setInterval(() => {
          decreaseValue()
        }, 100)
      }, 500)
      superTimeout = setTimeout(() => {
        clearTimeout(timeout)
        clearInterval(interval)
        interval = window.setInterval(() => {
          decreaseValue()
        }, 50)
      }, 3000)
    }
    const onMouseUpDecreaseValue = () => {
      setValue(currentValue)
      clearTimeout(timeout)
      clearInterval(interval)
      clearTimeout(superTimeout)
      clearInterval(superInterval)
    }
    const onMouseLeaveDecreaseValue = () => {
      setValue(currentValue)
      clearTimeout(timeout)
      clearInterval(interval)
      clearTimeout(superTimeout)
      clearInterval(superInterval)
    }

    const totalQuantity = Number(value) + Number(currentTotal ?? 0)
    const prices = plans.find(p => p.name === currentPlan?.plan)?.prices ?? []

    const getPriceByTires = () =>
      prices
        .find(p => p.interval === currentPlan?.subscription?.interval)
        ?.priceStorageTiers?.find(t =>
          t.to
            ? totalQuantity >= t.from && totalQuantity <= t.to
            : totalQuantity >= t.from,
        )
    const totalPrice =
      type === BuyMoreType.Minutes
        ? 0
        : currentPlan?.plan === 'Freemium'
          ? prices?.find(
              p => p.interval === currentPlan?.subscription?.interval,
            )?.priceStorage
          : getPriceByTires()?.price
    const totalValid = (data?.subtotal ?? 0) / 100 >= BUY_MORE_MIN
    const handleCheckOut = () => {
      if (!totalValid) {
        return
      }
      onCheckout?.()
    }
    const priceNextBill =
      (totalPrice ?? 0) *
      (Number(value) + Number(currentPlan?.boughtStorage ?? 0))
    const formatText = text => {
      if (text.length > 14) {
        return `${text.slice(0, 13)}x${text.length - 13}`
      }
      return text
    }
    return (
      <ModalContent containerClassName={css.BuyMore} useCloseDefault={false}>
        <div className={css.Main}>
          <div className={css.Header}>
            <div className={css.Title}>{dataBuyMore?.title}</div>
            <span className={css.CloseIcon} onClick={() => ModalService.hide()}>
              <Icon icon='Close1' size={16} />
            </span>
          </div>
          <div className={css.BuyTo}>
            <span className={css.Decs}>
              {dataBuyMore.ques} do you want to buy?
            </span>
            <div className={css.FormBuy}>
              <BeamInput
                containerProps={{
                  postfix: dataBuyMore.value,
                  className: css.InputValue,
                  postfixClassName: css.InputPostfix,
                }}
                className={css.Value}
                placeholder='0'
                value={isStorage ? value : currentValue}
                onChange={changeValue}
                autoFocus
                disabled={!isStorage}
              />
              {!isStorage && (
                <>
                  <IconButton
                    icon={
                      <Icon icon='Minus' size={16} className={css.PlusIcon} />
                    }
                    className={css.MarginAction}
                    variant='grey'
                    onMouseDown={onMouseDownDecreaseValue}
                    onMouseUp={onMouseUpDecreaseValue}
                    onMouseLeave={onMouseLeaveDecreaseValue}
                  />
                  <IconButton
                    icon={
                      <Icon icon='Plus' size={16} className={css.MinusIcon} />
                    }
                    className={css.MarginAction}
                    variant='grey'
                    onMouseDown={onMouseDownIncreaseValue}
                    onMouseUp={onMouseUpIncreaseValue}
                    onMouseLeave={onMouseLeaveIncreaseValue}
                  />
                </>
              )}
            </div>
          </div>
          {!totalValid && (
            <span className={css.TextWarning}>
              Our payment provider has a minimum transaction value of $
              {BUY_MORE_MIN}. Please purchase $0.50 or more to proceed.
            </span>
          )}
          {isStorage && !!value && (
            <span className={css.TotalCount}>
              You will have {totalQuantity}GB in total
            </span>
          )}
          <div className={css.Summary}>
            <div className={css.SumTitle}>
              <span className={css.Title}>Price summary</span>
              <Popover
                content={popoverContent}
                trigger='hover'
                placement='right'
                align={{ offset: [-20, 0] }}
                zIndex={1300}
              >
                <span className={css.CheckPrice}>Check Price</span>
              </Popover>
            </div>

            <div className={css.Items}>
              {fetchingComputed && (
                <div className={css.Spin}>
                  <AppSpin size='small' />
                </div>
              )}
              <div className={css.PriceItem}>
                <span className={clsx(css.Title, css.Text)}>
                  {dataBuyMore.summary}
                </span>
                <span className={css.Text}>
                  {formatText(convertValue())} {dataBuyMore.unit}{' '}
                  <span className={css.Multi}>x</span>
                </span>
                <span className={css.Minute}>{formatCurrency(unitValue)}</span>
              </div>
              <div className={css.Divider} />
              {/* {!!data?.discount && !!value && (
                <>
                  <div className={css.PriceItem}>
                    <span className={clsx(css.Title, css.Text)}>Discount</span>
                    <span className={css.Discount}>
                      <span className={css.Prefix}>-</span>
                      {formatCurrency(data?.discount)}
                    </span>
                  </div>
                  <div className={css.Divider} />
                </>
              )} */}
              {!!data?.proration && !!value && (
                <>
                  <div className={css.PriceItem}>
                    <span className={clsx(css.Title, css.Text)}>Proration</span>
                    <span className={css.Discount}>
                      <span className={css.Prefix}>-</span>
                      {formatCurrency(data?.proration)}
                    </span>
                  </div>
                  <div className={css.Divider} />
                </>
              )}
              <div className={css.PriceItem}>
                <span className={clsx(css.Title, css.Text)}>Total</span>
                <span className={css.Total}>
                  {formatCurrency(value ? data?.subtotal : 0)}
                </span>
              </div>
            </div>
          </div>
          {isStorage && !!value && !!data?.proration && (
            <div className={css.BillingCircle}>
              <div className={css.BillingIcon}>
                <Icon icon='Info' size={14} />
              </div>
              <span className={css.PayBilling}>
                From the next billing cycle, you will pay{' '}
                <span className={css.Value}>
                  {formatCurrency(priceNextBill)}/month
                </span>
              </span>
            </div>
          )}
          <div className={css.Actions}>
            <Button onClick={() => ModalService.hide()} containerType='grey'>
              Cancel
            </Button>
            <Button
              className={clsx(css.ButtonCheckOut, {
                [css.DisabledCheckout]: !value || fetchingComputed,
              })}
              disabled={!totalValid}
              onClick={handleCheckOut}
              loading={fetching}
            >
              Check out
            </Button>
          </div>
        </div>
      </ModalContent>
    )
  },
)
