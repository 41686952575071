import { S } from '../../store'

export const clearGroupSlot = (layoutId: string) => {
  const $ = S.layout
  const layout = $.slotsEdit[Number(layoutId)]
  if (layout) {
    $.slotsEdit[Number(layoutId)] = layout.map(l => ({
      ...l,
      inGroup: false,
    }))
  }
}
