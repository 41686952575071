import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import type { SessionRoleName } from '../../../context/gql/codegen'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const handleChangeRole = async (role: SessionRoleName) => {
  const $ = S.webrtc
  const res = await reduxStore.context.gql.switchRole({
    sessionId: $.sessionId,
    role,
  })
  if (res.error) {
    ToastService.error({ content: 'Switch role failed.' })
    ModalService.hide()
    return
  }
  window.location.reload()
}
