import type { ModalProps } from 'antd'
import { Modal } from 'antd'
import clsx from 'clsx'
import type { ElementType, PropsWithChildren } from 'react'
import { Component, Fragment } from 'react'

import './modal.scss'

import { modalObserver } from '../../context/store/shared/ModalStore'
import { CONSTANTS } from '../../styles/Constants'
import { Icon } from '../base/Icon'

type TModalOptions = {
  name?: string
  fullscreen?: boolean
}
type TDialogState = {
  modals: Array<{
    name: string | number
    component: ElementType
    props: any
    options: TModalOptions & ModalProps
    visible: boolean
  }>
}
export class ModalService {
  static _globalModal: {
    show?(
      component: ElementType,
      props?: any,
      options?: TModalOptions & ModalProps,
    ): void
    hide?(name?: string | number): void
  } = {}
  static setGlobal = (g: any) => {
    ModalService._globalModal = g
  }
  static show = <T = any,>(
    component: ElementType,
    props?: T,
    options?: TModalOptions & ModalProps,
  ) => {
    if (ModalService._globalModal?.show) {
      ModalService._globalModal.show(component, props, options)
    }
  }
  static hide = (name?: string | number) => {
    if (ModalService._globalModal?.hide) {
      ModalService._globalModal.hide(name)
    }
  }
}

export const ModalContent = (
  props: PropsWithChildren<{
    id?: string
    style?: React.CSSProperties
    className?: string
    onClose?: () => void
    isLightTheme?: boolean
    isFullScreen?: boolean
    containerClassName?: string
    useCloseDefault?: boolean
  }>,
) => {
  const {
    useCloseDefault = true,
    id,
    style,
    className,
    isFullScreen,
    containerClassName,
    children,
  } = props
  return (
    <div
      id={id}
      style={{ ...style }}
      className={clsx(['custom-modal-content', className])}
      // onMouseDown={(e: MouseEvent) => e.stopPropagation()}
    >
      {useCloseDefault && (
        <button
          onClick={() => {
            ModalService.hide()
            props?.onClose?.()
          }}
          type='button'
          aria-label='Close'
          className={clsx('modal-close', {
            ['fullscreen']: isFullScreen,
          })}
        >
          <span className='modal-close-x'>
            <Icon icon='Close1' size={isFullScreen ? 24 : 16} />
          </span>
        </button>
      )}
      <div className={clsx('modal-container', containerClassName)}>
        {children}
      </div>
    </div>
  )
}

export class ModalModule extends Component<any, TDialogState> {
  eventOverlay!: any
  constructor(props: any) {
    super(props)
    this.state = {
      modals: [],
    }
    this.eventOverlay = null
  }
  hide = (name = null) => {
    // TODO use this.state.modals.length
    const { modals } = this.state
    modalObserver.hasAnyModal = false
    if (name !== null) {
      this.setState(
        {
          ...this.state,
          modals: modals.map(d => {
            if (d.name !== name) {
              return d
            } else {
              return {
                ...d,
                visible: false,
              }
            }
          }),
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              modals: modals.filter(d => d.name !== name),
            })
          }, 200)
        },
      )
    } else {
      this.setState(
        {
          ...this.state,
          modals: modals.map((d, index) => {
            if (index !== modals.length - 1) {
              return d
            } else {
              return {
                ...d,
                visible: false,
              }
            }
          }),
        },
        () => {
          setTimeout(() => {
            this.setState({
              ...this.state,
              modals: [...modals.slice(0, modals.length - 1)],
            })
          }, 200)
        },
      )
    }
  }
  show = (
    component: ElementType,
    props = null,
    options: TModalOptions & ModalProps,
  ) => {
    // TODO usemodals.length
    const { modals } = this.state
    modalObserver.hasAnyModal = true
    this.setState({
      ...this.state,
      modals: [
        ...modals,
        {
          name: options?.name ?? modals.length,
          component,
          props,
          options,
          visible: true,
        },
      ],
    })
  }
  componentDidMount = () => {
    ModalService.setGlobal(this)
  }
  render = () => {
    const { modals: components } = this.state
    if (components.length > 0) {
      return components.map((d, index) => {
        const { props, options, visible } = d
        const ComponentRender = d.component
        return (
          <Fragment key={index}>
            <div className={'ModalContainer'} />
            <Modal
              visible={visible}
              bodyStyle={{
                background: 'transparent',
                borderRadius: 8,
                padding: 16,
                overflow: 'hidden',
              }}
              footer={null}
              closeIcon={' '}
              onCancel={e =>
                options?.onCancel ? options?.onCancel(e) : this.hide()
              }
              centered
              width='auto'
              maskStyle={{
                background: CONSTANTS.basicColor.maskBackgroundModal,
                zIndex: props?.zIndex,
              }}
              {...options}
              className={`custom-modal ModalStyled ${options?.className} ${
                options?.fullscreen ? 'fullscreen' : ''
              }`}
            >
              <div
                className='overlay'
                onClick={e => {
                  if (options?.maskClosable) {
                    this.hide()
                  }
                  e.stopPropagation()
                  e.preventDefault()
                  e.nativeEvent.stopImmediatePropagation()
                  e.nativeEvent.preventDefault()
                  e.nativeEvent.stopPropagation()
                }}
              ></div>
              <ComponentRender {...props} />
            </Modal>
          </Fragment>
        )
      })
    } else {
      return null
    }
  }
}
