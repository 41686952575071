import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import clsx from 'clsx'

import css from './style.module.scss'

export const BeamMenu = (props: MenuProps) => {
  const { className } = props
  return <Menu rootClassName={clsx(css.Menu, className)} {...props} />
}

BeamMenu.Item = Menu.Item
