import moment from 'moment'

import { serverTime } from '../context/store/shared/serverTime'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getTypeClock = () => {
  const fullHours = serverTime.date().getHours()
  return fullHours > 12 ? 'PM' : 'AM'
}
export const getDate = () => {
  const fullDate = serverTime.date().getDate()
  return fullDate > 9 ? fullDate : `0${fullDate}`
}
export const getNameMonth = () => {
  const fullMonth = serverTime.date().getMonth()
  return monthNames[fullMonth]
}
export const getFullYear = () => serverTime.date().getFullYear()

export const getHour = () => {
  let fullHours = serverTime.date().getHours()
  fullHours = fullHours > 12 ? fullHours - 12 : fullHours
  return fullHours > 9 ? fullHours : `0${fullHours}`
}

export const getMinutes = () => {
  const fullMin = serverTime.date().getMinutes()
  return fullMin > 9 ? fullMin : `0${fullMin}`
}
export const getSecond = () => {
  const fullSec = serverTime.date().getSeconds()
  return fullSec > 9 ? fullSec : `0${fullSec}`
}
export const getFullTime = () => {
  const hours = getHour()
  const min = getMinutes()
  const sec = getSecond()
  const type = getTypeClock()

  return `${hours}:${min}:${sec} ${type}`
}
export const getTimezone = () => {
  const stringDate = serverTime.date().toString()
  return stringDate?.match(/([A-Z]+[\+-][0-9]+)/)?.[1].slice(0, -2)
}

export const getShortDate = () =>
  `${getDate()} ${getNameMonth()} ${getFullYear()}`

export const formatDate = (date: any) => moment(date).format('MMM DD, YYYY')

export const formatDateTime = (date: any, format = 'MMM DD, YYYY hh:mm A') =>
  moment(date).format(format)
