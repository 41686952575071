import { ToastService } from '../../../components/widget/Toast'
import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'

export const onPreviewBlurCam = async () => {
  const $ = S.webrtc
  const { studioSettings } = S.profile.profile
  if (!$.cams.length || $.camDisabled) {
    return
  }
  await $.onPreviewToggleBlurCamera()
  await reduxStore.context.gql.updateMyProfile({
    data: {
      studioSettings: {
        ...studioSettings,
        camBlurred: $.camBlurred,
      },
    },
  })
}

export const onStudioBlurCam = async () => {
  const $ = S.webrtc
  const { studioSettings } = S.profile.profile
  if (!$.isBlurSupported) {
    ToastService.error({
      content:
        'Your system or browser is not able to support blur background. Please upgrade it or change browser.',
    })
    return
  }
  await $.onToggleBlurCamera()
  await reduxStore.context.gql.updateMyProfile({
    data: {
      studioSettings: {
        ...studioSettings,
        camBlurred: $.camBlurred,
      },
    },
  })
}
