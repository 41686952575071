import './polyfill/process'

import { configure } from 'mobx'
import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as UrqlProvider } from 'urql'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js';

import { Provider } from '##/reactjs/redux'

import { AppBundleMixer } from './AppBundleMixer'
import { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY, reactDeploymentEnv } from './config'
import { gqlCtx } from './context/gql'
import { reduxStore } from './context/redux'
import { S, syncLocalStorage } from './context/store'

configure({ enforceActions: 'never' })

const App =
  S.webrtc.isViewmodeAmsClient || S.webrtc.isViewmodeMixer
    ? AppBundleMixer
    : location.pathname === '/mobile-builds' && reactDeploymentEnv === 'dev'
      ? lazy(() => import('./AppBundleMobileBuilds'))
      : lazy(() => import('./AppBundleStudio'))

syncLocalStorage(localStorage as any)

posthog.init(
  REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  }
);



const div = document.getElementById('root')
if (div) {
  const root = createRoot(div)
  root.render(
    <PostHogProvider
      client={posthog}
    >
      <Suspense fallback={null}>
        <Provider store={reduxStore}>
          <UrqlProvider value={gqlCtx.gqlUrqlClient}>
            <App />
          </UrqlProvider>
        </Provider>
      </Suspense>
    </PostHogProvider>,
  )
}
