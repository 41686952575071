import { S } from '../../../context/store'
import type { MessageRes } from '../type'
import { ListenerEvent } from '../type'

export const listenInsightEngineEvent = () => {
  const $ = S.profile.socketInstance
  const { updateMessagesChatbot, searchChatbotMsg, editMessageChatbox } =
    S.insightEngine

  $?.on(ListenerEvent.MESSAGE, (data: MessageRes) => {
    searchChatbotMsg.isAnswering = false
    updateMessagesChatbot({ ...data.chatbot, isSocket: true } as any)
    editMessageChatbox(data.user)
  })
}
