import { Modal } from 'antd'
import clsx from 'clsx'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import css from './ChatAttachments.module.scss'

import { Icon } from '../../../components/base/Icon'
import { WarmnessCustomPopOver } from '../../../components/widget/WarmnessCustomPopover'
import { S } from '../../../context/store'
import {
  formatBytes,
  formatFileName,
} from '../../../context/store/studio/utils'
import type { TAttachments } from '../../../context/store/studio/WebrtcStore'
import { CONSTANTS } from '../../../styles/Constants'

export const ChatAttachments = observer(
  ({
    attachments,
    onDelete = undefined,
  }: {
    attachments: ReadonlyArray<TAttachments>
    onDelete?: (fileId: string) => void
  }) => {
    const onlyImage = attachments.every(a => a.mediaType === 'image')
    const large = attachments.length === 1

    if (onlyImage) {
      return (
        <div
          className={clsx(
            css.ItemAttachmentContent,
            css.chatAttachmentsWrapper,
          )}
        >
          <div
            className={clsx({
              [css.ItemAttachmentList]: true,
              [css.itemAttachmentListLarge]: large,
              [css.itemAttachmentOnlyImage]: !onlyImage,
            })}
            // onlyImage={onlyImage}
            // large={attachments.length === 1}
          >
            {attachments.map((item, index) => (
              <ItemAttachment item={item} key={index} onDelete={onDelete} />
            ))}
          </div>
        </div>
      )
    }
    return (
      <>
        {attachments.map((item, index) => (
          <ItemAttachment
            item={item}
            horizontal={false}
            key={index}
            onDelete={onDelete}
          />
        ))}
      </>
    )
  },
)

const ItemAttachment = observer(
  ({
    item,
    horizontal = true,
    onDelete,
  }: {
    item: TAttachments
    horizontal?: boolean
    onDelete?: (id: string) => void
  }) => {
    const { isLightTheme } = S.local
    const { isViewmodeParticipant } = S.webrtc
    const [isModal, setModal] = useState(false)
    const [isOpenPopover, setOpenPopover] = useState(false)
    const contentAction = () => (
      <div className={css.ItemChatActionContent}>
        <div
          className={css.ItemChatActionDelete}
          onClick={() => {
            setOpenPopover(false)
            onDelete && onDelete(item.id)
          }}
        >
          <Icon icon='icon_delete' size={13} />
          Delete
        </div>
      </div>
    )

    const listAction = () => (
      <div
        className={clsx({
          [css.ItemAttachOtherAction]: true,
          [css.itemAttachOtherActionShow]: isOpenPopover,
        })}
        // isShow={isOpenPopover}
      >
        {!isViewmodeParticipant && !S.webrtc.isOnlyViewStream && (
          <WarmnessCustomPopOver
            placement='leftTop'
            isLightTheme={isLightTheme}
            content={contentAction()}
            visible={isOpenPopover}
            onVisibleChange={(status: boolean) => setOpenPopover(status)}
          >
            <span className={css.ItemAttachOtherDel}>
              <Icon icon='icon_three_dots' size={12} />
            </span>
          </WarmnessCustomPopOver>
        )}
        <span
          className={css.ItemAttachOtherDownload}
          onClick={() => saveAs(item.value, item.name)}
        >
          <Icon icon='icon_download' size={10} />
        </span>
      </div>
    )

    if (item.mediaType === 'image' && horizontal) {
      return (
        <div className={css.ItemAttachmentDetailImage}>
          <img src={item.value} onClick={() => setModal(true)} />
          {onDelete && (
            <>
              <ItemModalAttachment
                visible={isModal}
                item={item}
                onCancel={() => setModal(false)}
                onDelete={() => onDelete && onDelete(item.id)}
              />
              {listAction()}
            </>
          )}
        </div>
      )
    }
    return (
      <div className={css.ItemAttachmentFileContent}>
        <Icon
          className={css.ItemAttachmentIcon}
          icon='icon_attachment'
          size={13}
        />
        <div className={css.ItemAttachmentDetail}>
          <span className={css.ItemAttachOtherName}>
            {formatFileName(item.name)}
          </span>
          <span className={css.ItemAttachOtherSize}>
            {formatBytes(item.size)}
          </span>
          {listAction()}
        </div>
      </div>
    )
  },
)

export const ItemModalAttachment = observer(
  ({
    item,
    visible,
    onCancel,
    onDelete,
  }: {
    item: TAttachments
    visible: boolean
    onCancel: () => void
    onDelete: () => void
  }) => {
    const { isViewmodeParticipant } = S.webrtc
    return (
      <Modal
        className={css.WapperModalAttachment}
        visible={visible}
        centered
        width='100vw'
        footer={null}
        onCancel={onCancel}
        closable={false}
        maskStyle={{ background: CONSTANTS.basicColor.maskBackgroundModal }}
      >
        <div className={css.ContainerModalAttachment}>
          <div className={css.WrapperAttachmentClickable} onClick={onCancel} />
          <div className={css.AttachmentModalHeader}>
            <span
              className={css.AttachmentDownload}
              onClick={() => saveAs(item.value, item.name)}
            >
              <Icon icon='icon_download' size={12} color='#E1E1E9' />
              Download
            </span>
            {!isViewmodeParticipant && !S.webrtc.isOnlyViewStream && (
              <span
                className={css.AttachmentDelete}
                onClick={() => {
                  onCancel()
                  onDelete()
                }}
              >
                <Icon icon='icon_delete' size={11} color='#E1E1E9' />
                Delete
              </span>
            )}
            <span className={css.AttachmentClose} onClick={onCancel}>
              <Icon icon='icon_close_popup' size={14} color='#E1E1E9' />
            </span>
          </div>
          <div className={css.AttachmentModalContent}>
            <img src={item.value} />
            <span>{item.name}</span>
          </div>
        </div>
      </Modal>
    )
  },
)
