import { reduxStore } from '../../../context/redux'
import { S } from '../../../context/store'
import type { IBlurItem } from '../../../context/store/insight-engine/InsightEngineStore'
import { selectBlurBlock } from './selectBlurBlock'

export const addBlurBlock = async (
  newBlock: IBlurItem,
  resourceInInsightEngineId: string,
) => {
  const res = await reduxStore.context.gql.createBlurBlock({
    data: {
      blockHeight: newBlock.height,
      blockWidth: newBlock.width,
      top: newBlock.top,
      left: newBlock.left,
      resourceInInsightEngineId,
    },
  })
  if (res.data?.createBlurBlock.id) {
    newBlock.id = res.data?.createBlurBlock.id
    const $ = S.insightEngine
    $.blurBlocks = [...$.blurBlocks, newBlock]
    selectBlurBlock(newBlock.id)
  }
}
