import clsx from 'clsx'

import css from './style.module.scss'

import { BeamText } from '../../../../components/base/Text'
import { PopoverContent } from '../../../../components/composites/PopoverContent'
import { WarmnessGrayButton } from '../../../../components/widget/WarmnessGrayButton'
import { rtmpsSupport } from '../DestinationData'
import { createComponent } from './logic'

export const SelectDestination = createComponent(({ onItemClick }) => (
  <PopoverContent className={css.SelectDestination}>
    <BeamText className={css.DesTitle}>
      Select a destination to connect to
    </BeamText>
    <div className={css.Destinations}>
      {rtmpsSupport.map(r => (
        <WarmnessGrayButton
          key={r.name}
          onClick={() => onItemClick(r.type)}
          className={clsx({
            [css.RecentItem]: true,
          })}
        >
          <img className={css.DestinationIcon} src={r.icon} />
          <BeamText
            containerProps={{
              classNameContainer: css.RecentItemNameContainer,
            }}
            className={css.RecentItemName}
          >
            {r.name}
          </BeamText>
        </WarmnessGrayButton>
      ))}
    </div>
  </PopoverContent>
))
