import { S } from '../../store'

export const onPreviewFinish = () => {
  const $ = S.webrtc

  $.preview?.camTrack?.stop()
  $.peers[0] = {
    ...$.peers[0],
    data: {
      ...$.peers[0].data,
      userId: S.profile.profile?.id,
      avatar: S.profile.profile?.avatarUrl,
      name: S.profile.profile?.name,
      controlMedia: $.isMediaControllerClient,
    },
  }

  S.webrtc.join()
}
