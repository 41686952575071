import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import css from './MediaPopover.module.scss'

import { CustomPopover } from '#HACK_FOR_RN_ONLY/src/components/widget/CustomPopover'
import { WarmnessCustomPopOver } from '#HACK_FOR_RN_ONLY/src/components/widget/WarmnessCustomPopover'

import { Icon } from '../../../components/base/Icon'
import { reactDeploymentEnv } from '../../../config'
import { S } from '../../../context/store'

type TPopover = {
  visible: boolean
  onVisibleChange: (status: boolean) => void
}
type TPopoverDelete = TPopover & {
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void
  useTheme?: boolean
}

type TPopoverAction = {
  onDownload: () => void
  onDelete?: () => void
  url?: string
  name?: string
  useTheme?: boolean
  iconClassName?: string
  onInsight?: () => void
}

export const PopoverDelete = observer(
  ({
    visible,
    onCancel,
    onDelete,
    onVisibleChange,
    useTheme = true,
  }: TPopoverDelete) => {
    const { isLightTheme } = S.local
    const content = () => (
      <>
        <div className={css.PopoverDeleteWrapper}>
          <p
            className={clsx(css.PopoverDeleteTitle, {
              [css.PopoverDeleteTitleLight]: isLightTheme && useTheme,
            })}
          >
            Are you sure you want to delete this media?
          </p>
          <span
            className={clsx(css.PopoverDeleteText, {
              [css.PopoverDeleteTextLight]: isLightTheme && useTheme,
            })}
          >
            Action can't be undone
          </span>
          <div className={css.PopoverDeleteAction}>
            <button
              className={clsx(css.PopoverDeleteNo, {
                [css.PopoverDeleteNoLight]: isLightTheme && useTheme,
              })}
              onClick={onCancel}
            >
              No
            </button>
            <button className={css.PopoverDeleteOk} onClick={onDelete}>
              Delete
            </button>
          </div>
        </div>
        <span
          className={clsx(css.PopoverDeleteArrow, {
            [css.PopoverDeleteArrowLight]: isLightTheme && useTheme,
          })}
        />
      </>
    )
    return (
      <CustomPopover
        placement='leftTop'
        content={content}
        isLightTheme={isLightTheme && useTheme}
        visible={visible}
        onVisibleChange={onVisibleChange}
      />
    )
  },
)

export const PopoverActionMedia = observer(
  ({
    onDownload,
    onDelete,
    url,
    name,
    useTheme = true,
    iconClassName,
    onInsight,
  }: TPopoverAction) => {
    const { isLightTheme } = S.local
    const { isEnterprise } = S.profile
    const [visible, setVisible] = useState(false)
    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
      setVisible(false)
      onDelete?.()
      e.stopPropagation()
    }
    const handleDownload = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setVisible(false)
      onDownload()
    }
    const handleRedirectInsight = () => {
      setVisible(false)
      onInsight?.()
    }
    const content = () => (
      <div className={css.PopoverActionWrapper}>
        <a href={url} download={name}>
          <div
            className={clsx({
              [css.PopoverActionDownload]: true,
              [css.PopoverActionDownloadHasBorder]: onDelete,
              [css.PopoverActionDownloadLight]: isLightTheme && useTheme,
            })}
            onClick={handleDownload}
          >
            <Icon
              icon='Download1'
              size={16}
              className={css.PopOverActionIcon}
            />
            Download
          </div>
        </a>
        {isEnterprise && onInsight && reactDeploymentEnv !== 'dev' && (
          <div
            className={clsx({
              [css.PopoverActionDownload]: true,
              [css.PopoverActionDownloadHasBorder]: true,
              [css.PopoverActionDownloadLight]: isLightTheme && useTheme,
            })}
            onClick={handleRedirectInsight}
          >
            <Icon
              icon='Star-Filled'
              size={16}
              className={css.PopOverActionIcon}
            />
            Open in Insight engine
          </div>
        )}
        {onDelete && (
          <div className={css.PopoverActionDelete} onClick={handleDelete}>
            <Icon icon='Delete1' size={16} className={css.PopOverActionIcon} />
            Delete
          </div>
        )}
      </div>
    )
    return (
      <WarmnessCustomPopOver
        placement='leftTop'
        content={content}
        isLightTheme={isLightTheme && useTheme}
        visible={visible}
        onVisibleChange={(status: boolean) => setVisible(status)}
      >
        <span
          onClick={e => e.stopPropagation()}
          className={clsx(css.PopoverActionMediaIcon, iconClassName)}
        >
          <Icon icon='icon_three_dots' size={16} />
        </span>
      </WarmnessCustomPopOver>
    )
  },
)
