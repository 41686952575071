import css from './ToggleRoleButton.module.scss'

import { Icon } from '../../../components/base/Icon'
import { ModalService } from '../../../components/Modal/Modal'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { ToggleRoleConfirmPopup } from './ToggleRoleConfirmPopup'

export const ToggleRoleButton = () => {
  const openConfirm = () => {
    ModalService.show(ToggleRoleConfirmPopup)
  }
  return (
    <WarmnessGrayButton onClick={openConfirm} className={css.ActionRounded}>
      <Icon icon='icon_user_livestream' size={14} />
    </WarmnessGrayButton>
  )
}
