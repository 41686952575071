import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'

import css from './SystemFlagValueItem.module.scss'

import { Button } from '../../../components/base/Button'
import { BeamInput } from '../../../components/widget/Input'
import { ToastService } from '../../../components/widget/Toast'
import type { UpdateSystemFlag } from '../../../context/gql/codegen'
import { useGqlUpdateSystemFlags } from '../../../context/gql/codegen'
import { catchPromiseError } from '../../../utils/catchPromiseError'

interface IProps {
  value: string
  item: any
  submitCallback: () => void
}

export const SystemFlagValueItem: React.FC<IProps> = ({
  value,
  item,
  submitCallback,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [showBtns, setShowBtns] = useState(false)
  const inputRef = useRef<any>()

  const [rUpdateSystemFeature, exUpdateSystemFeature] =
    useGqlUpdateSystemFlags()
  const onSubmit = () => {
    const data: UpdateSystemFlag[] = [{ key: item.key, value: inputValue }]
    if (!inputValue) {
      ToastService.warning({ content: 'Please enter the value' })
      return
    }
    const p: Promise<unknown> = exUpdateSystemFeature({ data })
    catchPromiseError(p, r => {
      submitCallback()
    })
  }
  useEffect(() => {
    if (inputValue !== value) {
      setShowBtns(true)
    } else {
      setShowBtns(false)
    }
  }, [value, inputValue])
  return (
    <div className={css.wrapper}>
      <BeamInput
        ref={ref => (inputRef.current = ref)}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        className={css.input}
      />
      {showBtns ? (
        <>
          <Button
            onClick={() => {
              setInputValue(value)
              inputRef?.current?.focus()
            }}
            containerType='grey'
            className={css.btnItem}
          >
            <CloseOutlined />
          </Button>
          <Button
            disabled={rUpdateSystemFeature.fetching}
            onClick={onSubmit}
            className={css.btnItem}
          >
            <CheckOutlined />
          </Button>
        </>
      ) : null}
    </div>
  )
}
