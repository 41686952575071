import { useState } from 'react'

import css from './ModalEditTranscript.module.scss'

import { Button } from '../../../components/base/Button'
import { BeamText } from '../../../components/base/Text'
import { ModalContent } from '../../../components/Modal/Modal'
import type { Transcript } from '../../../context/store/insight-engine/InsightEngineStore'
import { updateTranscript } from '../actions/updateTranscript'

type Props = {
  transcript: Transcript
  onCancel: () => void
}
export const ModalEditTranscript: React.FC<Props> = ({
  transcript,
  onCancel,
}) => {
  const [value, setValue] = useState(transcript.content)
  const [loading, setLoading] = useState(false)
  const handleSaveContent = async () => {
    setLoading(true)
    await updateTranscript(transcript.id, { content: value })
    setLoading(false)
    onCancel()
  }
  return (
    <ModalContent useCloseDefault={false} className={css.EditTranscript}>
      <BeamText size='large' className={css.Title}>
        Edit transcript
      </BeamText>
      <textarea
        value={value}
        onChange={e => setValue(e.target.value)}
        className={css.EditInput}
      />
      <div className={css.Buttons}>
        <Button
          loading={loading}
          disabled={loading}
          onClick={handleSaveContent}
        >
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading} containerType='grey'>
          Cancel
        </Button>
      </div>
    </ModalContent>
  )
}
