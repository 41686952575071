import { CONSTANTS } from '#HACK_FOR_RN_ONLY/src/styles/Constants'

import { EFontSizeText } from './type'

export const getFontSizeBySize = (size: EFontSizeText | undefined | string) => {
  if (size === EFontSizeText.SMALL1) {
    return CONSTANTS.fontSize.body3
  }
  if (size === EFontSizeText.SMALL) {
    return CONSTANTS.fontSize.body2
  }
  if (size === EFontSizeText.MEDIUM) {
    return CONSTANTS.fontSize.body1
  }
  if (size === EFontSizeText.LARGE) {
    return CONSTANTS.fontSize.body4
  }
  if (size === EFontSizeText.LARGE1) {
    return CONSTANTS.fontSize.body6
  }
  if (size === EFontSizeText.LARGE2) {
    return CONSTANTS.fontSize.body5
  }
  return CONSTANTS.fontSize.body1
}
